import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CiEdit, CiTrash } from 'react-icons/ci';
import { useData } from '../../../ContextProvider/Context';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BiCategory } from 'react-icons/bi';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function AddCustomFields() {
    const params = useParams()
    const { storeCustomFields, accountCom, getAccountCom, getBudgetData, budgetData, deleteBudgets } = useData()
    useEffect(() => {
        getBudgetData()
        getAccountCom()
    }, [])
    const [selectedCompany, setSelectedCompany] = useState(null);
    const filteredCompanies = accountCom?.company?.filter(item => item.deleteStatus === 0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj({
            table: 'invoices',
            field: '',
            type: 'string'
        });
        setOpen(false)
    }
    const [obj, setObj] = useState({
        table: 'invoices',
        field: '',
        type: 'string'
    })
    console.log(obj)
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = params.id
        ? budgetData?.data?.filter(item => item?.company_id == params.id) || []
        : budgetData?.data || [];

    const totalPages = Math.ceil(currentItems?.length / itemsPerPage);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const handleSubmit = () => {
        storeCustomFields(obj)
        handleClose()
    }
    return (
        <>
            <div className='employeeData'>
                <div classname="row " style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Custom Fields</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='d-flex justify-content-end mb-2 gap-2'>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                        </div>
                        <div>
                            <button
                                id="basic-button"
                                className="btn btn-primary"
                                onClick={handleOpen}
                            >
                                Add field
                            </button>
                            <Modal
                                open={open}
                                aria-labelledby="modal-modal-title"
                                onClose={handleClose}
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Add
                                    </Typography>
                                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                                        <div className='row'>
                                            <div className='col-12 mb-3'>
                                                <label style={{ fontWeight: "bold" }}>Name</label>
                                                <input value={obj?.field} type='text' className='form-control border' placeholder='Category Name' onChange={(e) => setObj({ ...obj, field: e.target.value })} />
                                            </div>
                                            <div className='col-6'>
                                                <label style={{ fontWeight: "bold" }}>Add in</label>
                                                <div style={{ display: "flex", alignItems: "center", display: "flex", alignItems: "center" }}>
                                                    <input style={{ width: "23px", height: "23px" }} value={obj?.field} type='checkbox' onChange={(e) => setObj({ ...obj, field: e.target.value })} />
                                                    <p className='m-0' style={{ marginLeft: "10px !important" }}>Do you want to show custom field in </p>
                                                </div>
                                                <ul>
                                                    <li>Invoices</li>
                                                    <li>Estimate</li>
                                                    <li>Sale Recipt</li>
                                                    <li>Payment</li>
                                                    <li>Delayed Charges</li>
                                                    <li>Credit Memo</li>
                                                    <li>Statement</li>
                                                </ul>
                                            </div>
                                            <div className='col-6'>
                                                <div style={{ display: "flex", alignItems: "center", display: "flex", alignItems: "center" }}>
                                                    <input style={{ width: "15px", height: "15px" }} value={obj?.field} type='checkbox' onChange={(e) => setObj({ ...obj, field: e.target.value })} />
                                                    <p style={{ marginBottom: "4px !important", fontSize: "10px" }}>print on forms of sales recipt, estimate, invoice </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-4'>
                                            <div className='modalBtns d-flex justify-content-end gap-2'>
                                                <button className="btn btn-primary" onClick={() => {
                                                    handleSubmit()
                                                }}>
                                                    submit
                                                </button>
                                                <button className="btn btn-secondary" onClick={handleClose}>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div>
                    <div className='card'>
                        <div className="card-body recruitment table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Period</th>
                                        <th scope="col">Budget</th>
                                        <th scope="col">Created On</th>
                                        <th scope="col">Last Updated</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {params.id ? (
                                        currentItems
                                            .slice(indexOfFirstItem, indexOfLastItem)
                                            .map((item, i) => (
                                                <>
                                                    {item?.company_id == params.id && (
                                                        <tr key={i}>
                                                            <th scope="row">{indexOfFirstItem + i + 1}</th>
                                                            <td>
                                                                <Link
                                                                    to={`/admin/${item.interval}_budget/${item.id}`}
                                                                    style={{ textDecoration: "none", color: "inherit" }}
                                                                    className="link_hover"
                                                                >
                                                                    {item.name}
                                                                </Link>
                                                            </td>
                                                            <td>{item.company?.name}</td>
                                                            <td>{item.interval}</td>
                                                            <td>{parseFloat(item.total_amount).toLocaleString()}</td>
                                                            <td>{item.created_at?.split("T")[0]}</td>
                                                            <td>{item.updated_at?.split("T")[0]}</td>
                                                            <td>
                                                                <button className="btn btn-primary p-1 mx-2">
                                                                    <Link to={`/admin/${item.interval}/${item.id}`} style={{ textDecoration: "none", color: "white" }}>
                                                                        <CiEdit style={{ fontSize: "20px" }} />
                                                                    </Link>
                                                                </button>
                                                                <button className="btn btn-danger p-1" onClick={() => deleteBudgets(item.interval, item.id)}>
                                                                    <Link style={{ textDecoration: "none", color: "white" }}>
                                                                        <CiTrash style={{ fontSize: "20px" }} />
                                                                    </Link>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            ))
                                    ) : (
                                        currentItems
                                            .slice(indexOfFirstItem, indexOfLastItem)
                                            .map((item, i) => (
                                                <tr key={i}>
                                                    <th scope="row">{indexOfFirstItem + i + 1}</th>
                                                    <td>
                                                        <Link to={`/admin/${item.interval}_budget/${item.id}`} style={{ color: "inherit" }}>
                                                            {item.name}
                                                        </Link>
                                                    </td>
                                                    <td>{item.company?.name}</td>
                                                    <td>{item.interval}</td>
                                                    <td>{parseFloat(item.total_amount).toLocaleString()}</td>
                                                    <td>{item.created_at?.split("T")[0]}</td>
                                                    <td>{item.updated_at?.split("T")[0]}</td>
                                                    <td>
                                                        <button className="btn btn-primary p-1 mx-2">
                                                            <Link to={`/admin/${item.interval}/${item.id}`} style={{ textDecoration: "none", color: "white" }}>
                                                                <CiEdit style={{ fontSize: "20px" }} />
                                                            </Link>
                                                        </button>
                                                        <button className="btn btn-danger p-1" onClick={() => deleteBudgets(item.interval, item.id)}>
                                                            <Link style={{ textDecoration: "none", color: "white" }}>
                                                                <CiTrash style={{ fontSize: "20px" }} />
                                                            </Link>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                    )}

                                    {(!params.id && currentItems.length === 0) && (
                                        <tr>
                                            <td colSpan="8" style={{ textAlign: "center" }}>
                                                No Data
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <div className="pagination-controls">{paginationControls}</div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default AddCustomFields;
