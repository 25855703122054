import React from 'react'
import logo from '../../Images/pngwing.com.png'
import { useData } from '../../ContextProvider/Context';
function Template4({ salesReceipt, fontSize, visibilityOfStamp, logoPlacement, getLogoStyle, stumpPlacement, getStumpStyle, bgColor, font, invoiceData, visibilityOfDate, visibilityOfActivity, visibilityOfAmount, visibilityOfDescription, visibilityOfQty, visibilityOfTax, visibilityOfRate, visibilityOfTaxSummary }) {
    const { img_url } = useData()
    const hexToRgb = (hex) => {
        hex = hex.replace(/^#/, '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
    };
    const textStyle = {
        fontFamily: font,
        fontSize: fontSize,
    };
    const totalExtax = invoiceData?.service?.reduce(
        (acc, item) => acc + parseFloat(item.extax ? item.extax : item.saletax?.rate || 0),
        0
    );

    const totalTax = invoiceData?.total_amount * totalExtax / 100;
    const totalAmountWithTax = parseFloat(invoiceData?.total_amount) + totalTax;
    return (
        <>
            <div className='TemplateData'>
                <div style={{ background: "white", padding: "1rem" }}>
                    <div style={{ border: "1px solid", padding: "2rem" }}>
                        <div className='row'>
                            <div className='header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {logoPlacement === 'left' && (
                                    <>
                                        <div className='details'>
                                            <div>
                                                <h3 style={{ fontWeight: 'bold', ...textStyle }}>{invoiceData?.name}</h3>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.address}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.phone}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.email}</p>
                                            </div>
                                        </div>
                                        <div className='logo'>
                                            <div style={getLogoStyle()}>
                                                <img alt='' src={img_url + invoiceData?.logo} style={getLogoStyle()} />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {logoPlacement === 'right' && (
                                    <>
                                        <div className='logo'>
                                            <div style={getLogoStyle()}>
                                                <img alt='' src={img_url + invoiceData?.logo} style={getLogoStyle()} />
                                            </div>
                                        </div>
                                        <div className='details'>
                                            <div>
                                                <h3 style={{ fontWeight: 'bold', ...textStyle }}>{invoiceData?.name}</h3>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.address}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.phone}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.email}</p>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {logoPlacement === 'middle' && (
                                    <>
                                        <div className='details' style={{ textAlign: 'left' }}>
                                            <div>
                                                <h3 style={{ fontWeight: 'bold', ...textStyle }}>{invoiceData?.name}</h3>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.address}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.phone}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.email}</p>
                                            </div>
                                        </div>
                                        <div className='logo' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <div style={{ height: '100px', width: '100px' }}>
                                                <img
                                                    alt=''
                                                    src={img_url + invoiceData?.logo}
                                                    style={{ height: '100%', width: '100%', borderRadius: '50%', border: '1px solid lightgray' }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='row mt-5 mb-3'>
                            <div className='row p-0 m-0'>
                                <div className='col-md-6' style={{ borderBottom: bgColor ? `6px solid ${bgColor}` : '6px solid #000' }}>
                                    <div>
                                        <p style={{ color: "#737272", margin: "0", ...textStyle, fontWeight: "bold" }}> BILL TO</p>
                                        <p style={{ margin: "0", ...textStyle }}>{invoiceData?.clientName}</p>
                                        <p style={{ margin: "0", ...textStyle }}>{invoiceData?.clientPhone}</p>
                                        <p style={{ margin: "0", ...textStyle }}>{invoiceData?.clientAddress}</p>
                                        <p style={{ margin: "0", ...textStyle }}>{invoiceData?.vat}</p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div style={{ display: "flex", gap: "20px", background: bgColor ? bgColor : '#000', width: "100%", alignItems: "end", padding: "4px", paddingLeft: "1rem" }}>
                                        <span style={{ color: '#fff', fontSize: "17px", ...textStyle }}>Tax Invoice</span>
                                        <span style={{ color: '#fff', fontSize: "17px", ...textStyle }}>{invoiceData?.invoice_no}</span>
                                    </div>
                                    <div className='my-2' style={{ display: "flex", gap: "10px", background: bgColor ? bgColor : '#000', width: "100%", alignItems: "end", }}>
                                        <div style={{ display: "flex", gap: "20px", width: "100%", alignItems: "end", padding: "4px", paddingLeft: "1rem" }}>
                                            <span style={{ color: '#fff', fontSize: "17px", ...textStyle }}>Date</span>
                                            <span style={{ color: '#fff', fontSize: "17px", ...textStyle }}>{invoiceData?.invoice_date}</span>
                                        </div>
                                        {!salesReceipt && (
                                            <>
                                                <div style={{ display: "flex", gap: "10px", width: "100%", alignItems: "end", padding: "4px", }}>
                                                    <span style={{ color: '#fff', fontSize: "17px", ...textStyle }}>Terms</span>
                                                    <span style={{ color: '#fff', fontSize: "17px", ...textStyle }}>{invoiceData?.term}</span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {salesReceipt && (
                                        <div style={{ display: "flex", gap: "20px", background: bgColor ? bgColor : '#000', width: "100%", alignItems: "end", padding: "4px", paddingLeft: "1rem" }}>
                                            <span style={{ color: '#fff', fontSize: "17px", ...textStyle }}>Payment method</span>
                                            <span style={{ color: '#fff', fontSize: "17px", ...textStyle }}>{invoiceData?.payment_method}</span>
                                        </div>
                                    )}
                                    {!salesReceipt && (
                                        <>
                                            <div style={{ display: "flex", gap: "20px", background: bgColor ? bgColor : '#000', width: "100%", alignItems: "end", padding: "4px", paddingLeft: "1rem" }}>
                                                <span style={{ color: '#fff', fontSize: "17px", ...textStyle }}>Due Date</span>
                                                <span style={{ color: '#fff', fontSize: "17px", ...textStyle }}>{invoiceData?.due_date}</span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <table className="table theme-dark" style={{ borderLeft: "0", borderRight: "0", borderBottom: "1px solid lightgray" }}>
                                <thead style={{ background: bgColor ? `rgba(${hexToRgb(bgColor)}, 0.3)` : '#000' }}>
                                    <tr>
                                        {visibilityOfDate && <th scope="col" style={{ border: '0', ...textStyle, padding: '8px', fontWeight: "bold", color: '#fff' }} >DATE</th>}
                                        {visibilityOfActivity && <th scope="col" style={{ border: '0', ...textStyle, padding: '8px', fontWeight: "bold", color: '#fff' }} >ACTIVITY</th>}
                                        {visibilityOfDescription && <th scope="col" style={{ border: '0', ...textStyle, padding: '8px', fontWeight: "bold", color: '#fff' }} >DESCRIPTION</th>}
                                        {visibilityOfTax && <th scope="col" style={{ border: '0', ...textStyle, padding: '8px', fontWeight: "bold", color: '#fff' }} >TAX</th>}
                                        {visibilityOfRate && <th scope="col" style={{ border: '0', ...textStyle, padding: '8px', fontWeight: "bold", color: '#fff' }} >QTY</th>}
                                        {visibilityOfQty && <th scope="col" style={{ border: '0', ...textStyle, padding: '8px', fontWeight: "bold", color: '#fff' }} >RATE</th>}
                                        {visibilityOfAmount && <th scope="col" style={{ border: '0', ...textStyle, padding: '8px', fontWeight: "bold", color: '#fff' }} >AMOUNT</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoiceData?.service?.map((item) => {
                                        return (
                                            <tr key={item.id} style={{ border: "0" }}>
                                                {visibilityOfDate && <td style={{ border: "0", ...textStyle, borderLeft: '0', borderRight: '0', padding: '8px' }}>{item.invoice_id}</td>}
                                                {visibilityOfActivity && <td style={{ border: "0", ...textStyle, borderLeft: '0', borderRight: '0', padding: '8px' }}>{item.serviceName}</td>}
                                                {visibilityOfDescription && <td style={{ border: "0", ...textStyle, borderLeft: '0', borderRight: '0', padding: '8px' }}>{item.sale_description}</td>}
                                                {visibilityOfTax && <td style={{ border: "0", ...textStyle, borderLeft: '0', borderRight: '0', padding: '8px' }}>{item.extax ? item.extax : item.saletax?.rate}%</td>}
                                                {visibilityOfRate && <td style={{ border: "0", ...textStyle, borderLeft: '0', borderRight: '0', padding: '8px' }}>{item.quantity}</td>}
                                                {visibilityOfQty && <td style={{ border: "0", ...textStyle, borderLeft: '0', borderRight: '0', padding: '8px' }}>{item?.sales_price}</td>}
                                                {visibilityOfAmount && <td style={{ border: "0", ...textStyle, borderLeft: '0', borderRight: '0', padding: '8px' }}>{item?.total_price}</td>}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='row'>
                            <div className='col-6'></div>
                            <div className='col-6'>
                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                                    <div>
                                        <p style={{ margin: "5px", color: bgColor ? bgColor : "#737272", ...textStyle }}>SUBTOTAL</p>
                                        <p style={{ margin: "5px", color: bgColor ? bgColor : "#737272", ...textStyle }}>TOTAL TAX(%)</p>
                                        <p style={{ margin: "5px", color: bgColor ? bgColor : "#737272", ...textStyle }}>TOTAL</p>
                                    </div>
                                    <div>
                                        <p style={{ margin: "5px", ...textStyle }}>PRs {invoiceData?.total_amount}</p>
                                        <p style={{ margin: "5px", ...textStyle }}>{totalExtax}</p>
                                        <p style={{ margin: "5px", ...textStyle }}>PRs {totalAmountWithTax.toFixed(2)}</p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", background: bgColor ? bgColor : '#000', padding: ".5rem" }}>
                                    <div>
                                        <p style={{ margin: "0", ...textStyle, color: '#fff', fontWeight: "bold", ...textStyle }}>BALANCE DUE</p>
                                    </div>
                                    <div>
                                        <p style={{ margin: "0", ...textStyle, fontWeight: "bold", color: '#fff', ...textStyle }}>PRs {Math.round(totalAmountWithTax.toFixed(2))}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {visibilityOfTaxSummary && <div className='row mt-3'>
                            <h6 style={{ fontWeight: "bold", margin: "0", ...textStyle }}>Tax Summary</h6>
                            <table className="table">
                                <thead style={{ background: bgColor ? `rgba(${hexToRgb(bgColor)}, 0.3)` : '#000' }}>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col" style={{ border: '0', ...textStyle, padding: '8px', textAlign: "end", fontWeight: "bold", color: '#fff' }}>Rate</th>
                                        <th scope="col" style={{ border: '0', ...textStyle, padding: '8px', textAlign: "end", fontWeight: "bold", color: '#fff' }}>VAT</th>
                                        <th scope="col" style={{ border: '0', ...textStyle, padding: '8px', textAlign: "end", fontWeight: "bold", color: '#fff' }}>Net</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoiceData?.service?.map((item) => {
                                        return (
                                            <tr key={item.id} style={{ border: "0" }}>
                                                <th style={{ textAlign: "end", ...textStyle, borderBottom: "1px solid lightgray", borderLeft: '0', borderRight: '0', padding: '8px' }}></th>
                                                <td style={{ textAlign: "end", ...textStyle, borderBottom: "1px solid lightgray", borderLeft: '0', borderRight: '0', padding: '8px' }}>{item.extax ? item.extax : item.saletax?.rate}%</td>
                                                <td style={{ textAlign: "end", ...textStyle, borderBottom: "1px solid lightgray", borderLeft: '0', borderRight: '0', padding: '8px' }}>{item?.sales_price * item.extax ? item.extax : item.saletax?.rate / 100}</td>
                                                <td style={{ textAlign: "end", ...textStyle, borderBottom: "1px solid lightgray", borderLeft: '0', borderRight: '0', padding: '8px' }}>{item?.total_price}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>}
                        {visibilityOfStamp && <div className='row'>
                            <div className='footer' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {stumpPlacement === 'left' && (
                                    <>
                                        <div className='details' style={{ color: "transparent" }}>

                                        </div>
                                        <div className='logo'>
                                            <div style={getStumpStyle()}>
                                                <img alt='' src={img_url + invoiceData?.stamp} style={getStumpStyle()} />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {stumpPlacement === 'right' && (
                                    <>
                                        <div className='logo'>
                                            <div style={getStumpStyle()}>
                                                <img alt='' src={img_url + invoiceData?.stamp} style={getStumpStyle()} />
                                            </div>
                                        </div>
                                        <div className='details' style={{ color: "transparent" }}>

                                        </div>
                                    </>
                                )}
                                {stumpPlacement === 'middle' && (
                                    <>
                                        <div className='details' style={{ textAlign: 'left', color: "transparent" }}>

                                        </div>
                                        <div className='logo' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <div style={{ height: '100px', width: '100px' }}>
                                                <img
                                                    alt=''
                                                    src={img_url + invoiceData?.stamp}
                                                    style={{ height: '100%', width: '100%', borderRadius: '50%', border: '1px solid lightgray' }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Template4
