import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context';
import { CiEdit, CiTrash } from 'react-icons/ci'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
function AccountData() {
    const params = useParams()

    const { getAllCrudData, allCrudData, handleClick, setSnackbarValue, getAccountData, accountData, storeAcountData, updateAccountData, deleteAcountData, getAccountIndiData, getAccountFilter, accountIndiData } = useData()
    useEffect(() => {
        getAccountData()
        getAllCrudData()
    }, [])
    const [selectedCompany, setSelectedCompany] = useState(null);
    const filteredCompanies = allCrudData?.companies?.filter(item => item.deleteStatus === 0);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    const [obj, setObj] = useState({
        company_id: params.id ? params.id : selectedCompany,
        bank_holder_name: '',
        bank_name: '',
        account_number: '',
        contact_number: '',
        bank_address: '',
        iban: '',
    })
    const isFormValid = () => {
        for (const key in obj) {
            if (key !== "bank_address" && key !== "iban" && obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = (e) => {
        if (isFormValid()) {
            e.preventDefault();
            handleClose()
            storeAcountData(obj)
            setObj({
                bank_holder_name: '',
                bank_name: '',
                account_number: '',
                contact_number: '',
                bank_address: '',
                iban: '',
            })
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const handleSubmit1 = (e) => {
        handleClose1()
        e.preventDefault();
        updateAccountData(obj)
    }

    const hndleDeleteCategory = (id) => {
        deleteAcountData(id);
    }
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = params.id
        ? accountData?.data?.filter(item => item?.company?.id == params.id) || []
        : accountData?.data || [];

    const totalPages = Math.ceil(currentItems?.length / itemsPerPage);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    return (
        <div>
            <div classname="row " style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Acounts</li>
                        </ol>
                    </nav>
                </div>
                <div className='d-flex justify-content-end mb-2 gap-2' style={{ alignItems: "end" }}>
                    <div>
                        <label style={{ fontWeight: "bold", fontSize: "12px" }}>Company</label> <br />
                        <select disabled={params.id ? true : false} style={{ height: "36px" }} className='form-select' onChange={(e) => {
                            getAccountFilter(e.target.value)
                            handlePageChange(1)
                        }}>
                            <option
                                value="">Select a company</option>
                            {filteredCompanies?.map((item) => (
                                <option key={item.id} value={item.name}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <button className='btn btn-primary' style={{ padding: "8px 16px" }}>
                            <Link to='/admin/accounts/banking/transfer' style={{ textDecoration: "none", color: "white" }}>
                                Go to Transfer
                            </Link>
                        </button>
                    </div>
                    <div>
                        <button className='btn btn-primary' onClick={handleOpen} style={{ padding: "8px 16px" }}>Add</button>
                    </div>
                </div>
            </div>

            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleClose}
            >
                <Box sx={style} style={{ width: "80%", height: "80%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ width: "100%", textAlign: "left", marginBottom: "10px" }}>
                        Add
                    </Typography>
                    <div>
                        <form>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <label>Select Company</label>
                                    <select
                                        disabled={params.id ? true : false}

                                        className='form-select' onChange={(e) => {
                                            setSelectedCompany(parseInt(e.target.value));
                                            setObj({
                                                ...obj,
                                                company_id: parseInt(e.target.value)
                                            });
                                        }}>
                                        <option value="">Select a company</option>
                                        {filteredCompanies?.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label>Bank Name</label>
                                        <input type="text" className="form-control" placeholder="Enter Bank Name" onChange={(e) => setObj({ ...obj, bank_name: e.target.value })} />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label>Bank Holder Name</label>
                                        <input type="text" className="form-control" placeholder="Enter Bank Holder Name" onChange={(e) => setObj({ ...obj, bank_holder_name: e.target.value })} />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label>Bank Account Number</label>
                                        <input type="number" className="form-control" placeholder="Enter Bank Holder Name" onChange={(e) => setObj({ ...obj, account_number: e.target.value })} />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label>Contact Number</label>
                                        <input type="number" className="form-control" placeholder="Enter Your Contact Number" onChange={(e) => setObj({ ...obj, contact_number: e.target.value })} />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label>Bank Address</label>
                                        <input type="text" className="form-control" placeholder="Enter Bank Address" onChange={(e) => setObj({ ...obj, bank_address: e.target.value })} />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label>I-BAN Number</label>
                                        <input type="number" className="form-control" placeholder="Enter I-BAN Number" onChange={(e) => setObj({ ...obj, iban: e.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div>
                            </div>
                        </form>
                    </div>
                    <div style={{ width: "100%", textAlign: "end" }}>
                        <button className='btn btn-primary mx-2' type='submit' onClick={handleSubmit}>Submit</button>
                        <button className='btn btn-danger' onClick={handleClose}>Close</button>
                    </div>
                </Box >

            </Modal >
            <hr />
            <div className='row'>
                <div>
                    <div className='card'>
                        <div className="card-body recruitment table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Bank Name</th>
                                        <th scope="col">Account Number</th>
                                        <th scope="col">Opening Balance</th>
                                        <th scope="col">Contact Number</th>
                                        <th scope="col">Bank Address</th>
                                        <th scope="col">I-BAN</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {params.id ? currentItems
                                        .slice(indexOfFirstItem, indexOfLastItem)?.map((items, indx) => {
                                            const truncatedDescription = items.bank_address ? items.bank_address.slice(0, 20) : '';
                                            const isDescriptionTruncated = items.bank_address?.length > 20;
                                            return (
                                                <>{items?.company?.id == params.id && <tr key={indx}>
                                                    <th scope="row">{indexOfFirstItem + indx + 1}</th>
                                                    <td scope="col">{items.company?.name}</td>
                                                    <td scope="col">
                                                        <Link to={`/admin/accounts/banking/account/${items.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                                                            {items.bank_name}
                                                        </Link>
                                                    </td>
                                                    <td scope="col">{items.account_number}</td>
                                                    <td scope="col">{items.opening_balance}</td>
                                                    <td scope="col">{items.contact_number ? items.contact_number : '-'}</td>
                                                    <td>
                                                        {isDescriptionTruncated ? (
                                                            <span
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setSelectedDescription(items.bank_address);
                                                                    setShowDescriptionOverlay(true);
                                                                }}
                                                            >
                                                                {truncatedDescription}...
                                                            </span>
                                                        ) : (
                                                            items.bank_address ? items.bank_address : '-'
                                                        )}
                                                    </td>
                                                    {showDescriptionOverlay && (
                                                        <div className="overlayy" style={{ background: "rgba(0,0,0,0)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                            <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                                <p style={{ color: "white" }}>{selectedDescription}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <td scope="col">{items.iban ? items.iban : '-'}</td>
                                                    <td scope="col">
                                                        <button className='btn btn-primary p-1 m-2' onClick={() => {
                                                            setObj(items)
                                                            handleOpen1()
                                                        }}>
                                                            <CiEdit style={{ fontSize: "20px" }} />
                                                        </button>
                                                        <button className="btn btn-danger p-1 m-2" onClick={() => hndleDeleteCategory(items.id)}>
                                                            <CiTrash style={{ fontSize: "20px" }} />
                                                        </button>

                                                    </td>
                                                </tr>}
                                                </>

                                            )
                                        }) : currentItems
                                            .slice(indexOfFirstItem, indexOfLastItem)?.map((items, indx) => {
                                                const truncatedDescription = items.bank_address ? items.bank_address.slice(0, 20) : '';
                                                const isDescriptionTruncated = items.bank_address?.length > 20;
                                                return (
                                                    <tr key={indx}>
                                                        <th scope="row">{indexOfFirstItem + indx + 1}</th>
                                                        <td scope="col">{items.company?.name}</td>
                                                        <td scope="col">
                                                            <Link to={`/admin/accounts/banking/account/${items.id}`} style={{ textDecoration: "none", color: "black" }}>
                                                                {items.bank_name}
                                                            </Link>
                                                        </td>
                                                        <td scope="col">{items.account_number}</td>
                                                        <td scope="col">{items.opening_balance}</td>
                                                        <td scope="col">{items.contact_number ? items.contact_number : '-'}</td>
                                                        <td>
                                                            {isDescriptionTruncated ? (
                                                                <span
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSelectedDescription(items.bank_address);
                                                                        setShowDescriptionOverlay(true);
                                                                    }}
                                                                >
                                                                    {truncatedDescription}...
                                                                </span>
                                                            ) : (
                                                                items.bank_address ? items.bank_address : '-'
                                                            )}
                                                        </td>
                                                        {showDescriptionOverlay && (
                                                            <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                                <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                                    <p style={{ color: "white" }}>{selectedDescription}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <td scope="col">{items.iban ? items.iban : '-'}</td>
                                                        <td scope="col">
                                                            <button className='btn btn-primary p-1 m-2' onClick={() => {
                                                                setObj(items)
                                                                handleOpen1()
                                                            }}>
                                                                <CiEdit style={{ fontSize: "20px" }} />
                                                            </button>
                                                            <button className="btn btn-danger p-1 m-2" onClick={() => hndleDeleteCategory(items.id)}>
                                                                <CiTrash style={{ fontSize: "20px" }} />
                                                            </button>

                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                </tbody>
                            </table>
                            <div className="pagination-controls">{paginationControls}</div>
                        </div>
                        <Modal
                            onClose={handleClose1}
                            open={open1}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} style={{ width: "80%", height: "80%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ width: "100%", textAlign: "left", marginBottom: "10px" }}>
                                    Add
                                </Typography>
                                <div>
                                    <form>
                                        <div className='row'>
                                            <div className='col-md-6 mb-3'>
                                                <label>Select Company</label>
                                                <select disabled={params.id ? true : false} value={obj?.company_id} className='form-select' onChange={(e) => {
                                                    setSelectedCompany(parseInt(e.target.value));
                                                    setObj({
                                                        ...obj,
                                                        company_id: parseInt(e.target.value)
                                                    });
                                                }}>
                                                    <option value="">Select a company</option>
                                                    {filteredCompanies?.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>

                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <div className="form-group">
                                                    <label>Bank Name</label>
                                                    <input type="text" className="form-control" placeholder="Enter Bank Name" value={obj.bank_name} onChange={(e) => setObj({ ...obj, bank_name: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <div className="form-group">
                                                    <label>Bank Holder Name</label>
                                                    <input type="text" className="form-control" placeholder="Enter Bank Holder Name" value={obj.bank_holder_name} onChange={(e) => setObj({ ...obj, bank_holder_name: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <div className="form-group">
                                                    <label>Bank Account Number</label>
                                                    <input type="number" className="form-control" placeholder="Enter Bank Holder Name" value={obj.account_number} onChange={(e) => setObj({ ...obj, account_number: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <div className="form-group">
                                                    <label>Contact Number</label>
                                                    <input type="number" className="form-control" placeholder="Enter Your Contact Number" value={obj.contact_number} onChange={(e) => setObj({ ...obj, contact_number: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <div className="form-group">
                                                    <label>Bank Address</label>
                                                    <input type="text" className="form-control" placeholder="Enter Bank Address" value={obj.bank_address} onChange={(e) => setObj({ ...obj, bank_address: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <div className="form-group">
                                                    <label>I-BAN Number</label>
                                                    <input type="number" className="form-control" placeholder="Enter I-BAN Number" value={obj.iban} onChange={(e) => setObj({ ...obj, iban: e.target.value })} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div style={{ width: "100%", textAlign: "end", gap: "10px" }}>
                                    <button className='btn btn-primary' style={{ marginRight: "10px" }} type='submit' onClick={handleSubmit1}>Submit</button>
                                    <button className='btn btn-danger' onClick={handleClose1}>Close</button>
                                </div>
                            </Box>

                        </Modal>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AccountData
