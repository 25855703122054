import React, { useEffect, useState } from "react";
import { useData } from "../../ContextProvider/Context";
import { Link, useParams } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import { Modal, Form } from "antd";
import {
  CiBank,
  CiCalendarDate,
  CiClock2,
  CiCreditCard2,
  CiDollar,
  CiGlobe,
  CiMap,
  CiMoneyCheck1,
  CiRoute,
  CiStar,
  CiUser,
} from "react-icons/ci";
import { PiGraduationCapThin } from "react-icons/pi";
import { MdCastForEducation } from "react-icons/md";
import filter from "../../Images/filtericon.png";
import cross from "../../Images/filtercross.png";
import vector from "../../Images/Vector.png";
import { Drawer } from "@mui/material";
import jobicon from "../../Images/jobicon.png";
import downarrow from "../../Images/chevron-down.png";
import { Dropdown } from "react-bootstrap";
import { CiMenuKebab } from "react-icons/ci";
import candi from "../../Images/candidate.png";
import jobloc from "../../Images/jobloc.png";
import jobexp from "../../Images/jobexp.png";
import jobsal from "../../Images/jobsalary.png";
import jobtime from "../../Images/jobtime.png";
function JobList() {
  const {
    handleClick,
    setSnackbarValue,
    getCompnayCrud,
    jobData,
    getAllJob,
    createJob,
    img_url,
    getFilterCompany,
    company,
    branch,
    base_url,
  } = useData();
  const params = useParams();

  const editjob = () => {
    fetch(`${base_url}job/${dataJob.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(dataJob),
    })
      .then((response) => response.json())
      .then((data) => {
        getAllJob();
        handleClick();
        handleClearFields();
        setSnackbarValue({
          message: "Roles Update successfully",
          color: "#2ECC71",
        });
      });
  };
  const deletejob = (id) => {
    fetch(`${base_url}job/${id}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        getAllJob();
        handleClick();
        setSnackbarValue({
          message: "Roles Delete successfully",
          color: "#2ECC71",
        });
      });
  };
  const state = {
    open: false,
    vertical: "bottom",
    horizontal: "right",
  };
  const { vertical, horizontal } = state;
  const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const submitHandle = (e) => {
    if (dataJob?.title?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Title is required.");
    } else if (dataJob?.state?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("State is required.");
    } else if (dataJob?.city?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("City is required.");
    } else if (dataJob?.country?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Country is required.");
    } else if (dataJob?.experience?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Experience is required.");
    } else if (dataJob?.salary?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Salary is required.");
    } else if (dataJob?.skills?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Skill is required.");
    } else if (dataJob?.industry?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Industry is required.");
    } else if (dataJob?.functional_area?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Functional Area is required.");
    } else if (dataJob?.total_positions?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Total Position is required.");
    } else if (dataJob?.job_sift?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Job Shift is required.");
    } else if (dataJob?.job_type?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Job Type is required.");
    } else if (dataJob?.gender?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Gender is required.");
    } else if (dataJob?.education?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Education is required.");
    } else if (dataJob?.career_level?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Career Level is required.");
    } else if (dataJob?.apply_before?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Apply Before is required.");
    } else if (dataJob?.posting_date?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Posting Date is required.");
    } else if (dataJob?.description?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Description is required.");
    } else if (dataJob) {
      const longText = dataJob?.city;
      const words = longText.split("");
      console.log("splited words", words);
      const truncatedText = words.slice(0, 8);
      console.log("truncatedText text", truncatedText);
      const finaltext = truncatedText.join("");
      console.log("finaltext text", finaltext);
      setCreateJob({ ...dataJob, city: finaltext });
      createJob(dataJob);
      console.log("datajobs",dataJob)
      handleTransferCancel();
      handleClearFields();
    }
  };
  const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
  };

  const [isCreateAccount, setIsCreateAccount] = useState(false);
  const [isCreateTransfer, setIsCreateTransfer] = useState(false);
  const [dataJob, setCreateJob] = useState({
    company_id:params.id? params.id:"",
    title: "",
    description: "",
    state: "",
    city: "",
    country: "",
    experience: "",
    salary: "",
    skills: "",
    industry: "",
    functional_area: "",
    total_positions: "",
    job_sift: "",
    job_type: "",
    gender: "",
    logo: null,
    education: "",
    career_level: "",
    apply_before: new Date().toISOString().split("T")[0],
    posting_date: new Date().toISOString().split("T")[0],
  });
  useEffect(() => {
    getCompnayCrud();
    getAllJob();
  }, []);
  const handleCancel = () => {
    setIsCreateAccount(false);
  };
  const showCreateTransfer = () => {
    setIsCreateTransfer(true);
  };
  const handleTransferCancel = () => {
    setIsCreateTransfer(false);
    handleClearFields();
  };
  const isFormValid = () => {
    for (const key in dataJob) {
      if (dataJob[key] === "") {
        return false;
      }
    }
    return true;
  };

  const handleClearFields = () => {
    setCreateJob({
      title: "",
      description: "",
      state: "",
      city: "",
      country: "",
      experience: "",
      salary: "",
      skills: "",
      industry: "",
      functional_area: "",
      total_positions: "",
      job_sift: "",
      job_type: "",
      gender: "",
      logo: null,
      education: "",
      career_level: "",
      apply_before: new Date().toISOString().split("T")[0],
      posting_date: new Date().toISOString().split("T")[0],
    });
    setSelectedCoverImage("");
  };
  const [selectedCoverImage, setSelectedCoverImage] = useState("");
  const handleChangeForCover = (field, value) => {
    if (field === "logo") {
      if (value) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setCreateJob({ ...dataJob, [field]: reader.result });
          setSelectedCoverImage(reader.result);
        };
        reader.readAsDataURL(value);
      }
    } else {
      setCreateJob({ ...dataJob, [field]: value });
    }
  };
  console.log(dataJob);
  async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
  const [currentDate1, setCurrentDate1] = useState(new Date());

  const formattedDate = currentDate1.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentJobs = jobData?.slice(indexOfFirstItem, indexOfLastItem) || [];

  const totalPages = Math.ceil((jobData?.length || 0) / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }
  console.log("job data",jobData);

  return (
    <>
      <div className="employeeData">
        <div
          classname="row "
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <nav>
              <ul style={{ paddingLeft: "3px" }}>
                <li
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Jobs
                </li>
                <li>{formattedDate}</li>
              </ul>
            </nav>
          </div>
          <div className="d-flex justify-content-end mb-2 gap-2">
            <div>
              <button
                className="btn btn-primary"
                onClick={showCreateTransfer}
                style={{
                  padding: "8px 16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img alt="" src={jobicon} />
                <span>Add job</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card" style={{ padding: "10px" }}>
        <div className="card-body recruitment table-responsive">
          <div
            className="headtable"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          ></div>

          <div className="row">
            {params.id
              ? currentJobs?.map((item, i) => (
                  <div className="col-lg-3 col-md-4 mb-4">
                    <div
                      className="jobcard"
                      style={{
                        borderRadius: "10px",
                        boxShadow: "0 2px 15px -3px rgba(0, 0, 0, 0.07), 0 10px 20px -2px rgba(0, 0, 0, 0.04)",
                        padding: "15px",
                        maxHeight: "268px",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="cardhead"
                          style={{
                            height: "80px",
                            width: "80px",
                            overflow: "hidden",
                            borderRadius: "50%",
                          }}
                        >
                          <img
                            alt=""
                            src={img_url + item?.logo}
                            // src="https://w1.pngwing.com/pngs/691/417/png-transparent-marketing-internship-program-job-training-recruitment-ajira-job-description-employment-agency.png"
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "50%",
                            }}
                          />{" "}
                        </div>

                        {/* <Dropdown>
                      <Dropdown.Toggle
                        className="mydrop"
                        variant="success"
                        id="dropdown-basic"
                        style={{
                          background: "unset",
                          border: "0",
                          padding: "0",
                          boxShadow: "unset",
                        }}
                      >
                        <CiMenuKebab
                          style={{
                            fontSize: "24px",
                            color: "black",
                          }}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item 
                       
                         
                        >Edit</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Link to={`/admin/dashboard/chat/applicant/${item.id}`}>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            {item.title}
                          </span>
                        </Link>
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            fontStyle: "normal",
                            color: "#919EAB",
                          }}
                        >
                          posted date:{item.posting_date}
                        </span>
                        <span
                          style={{
                            color: "#00A76F",
                            fontSize: "12px",
                            fontWeight: "400",
                            fontStyle: "normal",
                          }}
                        >
                          <img alt="" src={candi} />
                          {item.total_positions} candidates
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          borderTop: "1px solid darkgray",
                          marginTop: "20px",
                          width: "100%",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            <img
                              alt=""
                              src={jobexp}
                              style={{ padding: "4px" }}
                            />
                            {item.experience}
                          </span>
                          <span>
                            {" "}
                            <img alt="" src={jobtime} />
                            {item.job_sift}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            {" "}
                            <img alt="" src={jobsal} />
                            {item.salary}
                          </span>
                          <span>
                            <img alt="" src={jobloc} />
                            {item.city}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : currentJobs?.map((item, i) => (
                  <div className="col-lg-3 col-md-4 mb-4">
                    <div
                      className="jobcard"
                      style={{
                        borderRadius: "10px",
                        boxShadow: "0 2px 15px -3px rgba(0, 0, 0, 0.07), 0 10px 20px -2px rgba(0, 0, 0, 0.04)",
                        padding: "15px",
                        maxHeight: "268px",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="cardhead"
                          style={{
                            height: "80px",
                            width: "80px",
                            overflow: "hidden",
                            borderRadius: "50%",
                          }}
                        >
                          <img
                            alt=""
                            src={
                              item?.logo
                                ? img_url + item?.logo
                                : "https://w1.pngwing.com/pngs/691/417/png-transparent-marketing-internship-program-job-training-recruitment-ajira-job-description-employment-agency.png"
                            }
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "50%",
                            }}
                          />{" "}
                        </div>

                        <Dropdown>
                          <Dropdown.Toggle
                            className="mydrop"
                            variant="success"
                            id="dropdown-basic"
                            style={{
                              background: "unset",
                              border: "0",
                              padding: "0",
                              boxShadow: "unset",
                            }}
                          >
                            <CiMenuKebab
                              style={{
                                fontSize: "24px",
                                color: "black",
                              }}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                showCreateTransfer();
                                setCreateJob(item);
                              }}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                deletejob(item.id);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Link to={`/admin/dashboard/chat/applicant/${item.id}`}>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            {item.title}
                          </span>
                        </Link>
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            fontStyle: "normal",
                            color: "#919EAB",
                          }}
                        >
                          posted date:{item.posting_date}
                        </span>
                        <span
                          style={{
                            color: "#00A76F",
                            fontSize: "12px",
                            fontWeight: "400",
                            fontStyle: "normal",
                          }}
                        >
                          <img alt="" src={candi} />
                          {item.total_positions} candidates
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          borderTop: "1px solid darkgray",
                          marginTop: "20px",
                          width: "100%",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            <img
                              alt=""
                              src={jobexp}
                              style={{ padding: "4px" }}
                            />
                            {item.experience}
                          </span>
                          <span>
                            {" "}
                            <img
                              alt=""
                              src={jobtime}
                              style={{ padding: "4px" }}
                            />
                            {item.job_sift}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            {" "}
                            <img
                              alt=""
                              src={jobsal}
                              style={{ padding: "4px" }}
                            />
                            {item.salary}
                          </span>
                          <span>
                            <img
                              alt=""
                              src={jobloc}
                              style={{ padding: "4px" }}
                            />
                            {item.city?.slice(0, 6)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          <div style={{ marginTop: "10px" }}>
            {paginationControls.map((button, index) => (
              <React.Fragment key={index}>{button}</React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <Drawer
        className="mypolicy-drawer"
        anchor="right"
        open={isCreateTransfer}
        onClose={handleTransferCancel}
      >
        <div>
          <h4>Add Job</h4>
        </div>
        <Form
          name="create_transfer"
          className="scrollBar"
          style={{
            background: "white",
            padding: "12px",
            borderRadius: "12px",
            overflowY: "scroll",
          }}
        >
          <div className="row create_job">
          <div
            className="form-group"
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label style={{ marginBottom: "5px" }} htmlFor="company1">
              Company <span style={{ color: "red" }}>*</span>
            </label>
            <img alt=""
              style={{ position: "absolute", right: "16px", top: "40px" }}
              src={downarrow}
            />
            <select
              id="company1"
              style={{ width: "100%", borderRadius: "10px", padding: "7px" }}
              className="form-control"
              disabled={params.id ? true : false}
              onChange={(e) =>
                setCreateJob({ ...dataJob, company_id: e.target.value })
              }
              value={dataJob.company_id}
           
            >
              <option hidden> Company</option>
              {company?.data?.map((item,id) => {
                return (
                  <>
                    {item?.deleteStatus == 0 && (
                      <option value={item.id} key={id}>
                        {item.name}
                      </option>
                    )}
                  </>
                );
              })}
            </select>
          </div>
            <div className="col-4">
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Title
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiMoneyCheck1 style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, title: e.target.value })
                  }
                  value={dataJob.title}
                  type="text"
                  placeholder="Title"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                State
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiMap style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, state: e.target.value })
                  }
                  value={dataJob.state}
                  type="text"
                  placeholder="State"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                City
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiMap style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) => {
                    setCreateJob({ ...dataJob, city: e.target.value });
                  }}
                  value={dataJob.city}
                  type="text"
                  placeholder="City"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Country
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiGlobe style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, country: e.target.value })
                  }
                  value={dataJob.country}
                  type="text"
                  placeholder="Country"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Experience
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiStar style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, experience: e.target.value })
                  }
                  value={dataJob.experience}
                  type="text"
                  placeholder="Experience"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Salary
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiDollar style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, salary: e.target.value })
                  }
                  value={dataJob.salary}
                  type="number"
                  placeholder="Salary"
                />
              </div>
            </div>
            <div className="col-4">
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Skills
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiRoute style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, skills: e.target.value })
                  }
                  value={dataJob.skills}
                  type="text"
                  placeholder="Skills"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Industry
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiBank style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, industry: e.target.value })
                  }
                  value={dataJob.industry}
                  type="text"
                  placeholder="Industry"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Functional Area
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiMap style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({
                      ...dataJob,
                      functional_area: e.target.value,
                    })
                  }
                  value={dataJob.functional_area}
                  type="text"
                  placeholder="Functional Area"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Total Positions
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiUser style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({
                      ...dataJob,
                      total_positions: e.target.value,
                    })
                  }
                  value={dataJob.total_positions}
                  type="number"
                  placeholder="Total Position"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Job Shift
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiClock2 style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, job_sift: e.target.value })
                  }
                  value={dataJob.job_sift}
                  type="text"
                  placeholder="Job Shift"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Job Type
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiCreditCard2 style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, job_type: e.target.value })
                  }
                  value={dataJob.job_type}
                  type="text"
                  placeholder="Job Type"
                />
              </div>
            </div>
            <div className="col-4">
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Gender
              </label>
              <span style={{ color: "red" }}>*</span>
              <div
                className="inp_box mt-0 mb-3"
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <label>
                  <CiUser style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <select
                  className="form-control border-0"
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, gender: e.target.value })
                  }
                  value={dataJob.gender}
                >
                  <option hidden>Select Gender</option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                  <option value={"both"}>Both</option>
                </select>
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Education
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <PiGraduationCapThin
                    style={{ fontSize: "25px", color: "gray" }}
                  />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, education: e.target.value })
                  }
                  value={dataJob.education}
                  type="text"
                  placeholder="Education"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Career Level
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <MdCastForEducation
                    style={{ fontSize: "25px", color: "gray" }}
                  />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, career_level: e.target.value })
                  }
                  value={dataJob.career_level}
                  type="text"
                  placeholder="Career Level"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Before Apply
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiCalendarDate style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, apply_before: e.target.value })
                  }
                  value={dataJob.apply_before}
                  type="date"
                  placeholder="Apply Before"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Posting date
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <label>
                  <CiCalendarDate style={{ fontSize: "25px", color: "gray" }} />
                </label>
                <input
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, posting_date: e.target.value })
                  }
                  value={dataJob.posting_date}
                  type="date"
                  placeholder="Posting Date"
                />
              </div>
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                cover Image
              </label>
              <div className="inp_box mt-0 mb-3">
                <div
                  className="border"
                  style={{
                    height: "48px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <label style={{ padding: "9px" }} htmlFor="cp1Cover">
                      Select
                    </label>
                  </div>
                  <div style={{ height: "100%", width: "10%" }}>
                    <img
                      alt=""
                      style={{ height: "100%", width: "100%" }}
                      src={selectedCoverImage || img_url + jobData?.logo}
                    />
                  </div>
                </div>
              </div>
              <input
                type="file"
                id="cp1Cover"
                name="companyCoverImage"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) =>
                  handleChangeForCover("logo", e.target.files[0])
                }
              />
            </div>
            <div className="col-12">
              <label style={{ fontWeight: "bold", fontSize: "15px" }}>
                Description
              </label>
              <span style={{ color: "red" }}>*</span>
              <div className="inp_box mt-0 mb-3">
                <textarea
                  style={{
                    border: "none",
                    background: "none",
                    outline: "none",
                  }}
                  onChange={(e) =>
                    setCreateJob({ ...dataJob, description: e.target.value })
                  }
                  value={dataJob.description}
                  type="text"
                  placeholder="Discription"
                />
              </div>
            </div>
          </div>
          <div
            className="row1"
            style={{
              justifyContent: "right",
              alignItems: "center",
              display: "flex",
              gap: "10px",
            }}
          >
            <button className="btn btn-primary" onClick={handleTransferCancel}>
              Cancel{" "}
            </button>
            <button
              className="btn btn-light"
              onClick={() => {
                dataJob?.id ? editjob() : submitHandle();
              }}
            >
              {dataJob?.id ? "update" : "submit"}
            </button>
          </div>
        </Form>
      </Drawer>
      <Drawer anchor="right" open={openDrawer} onClose={handleDrawerClose}>
        <div
          className="filterparent"
          style={{
            background: "white",
            padding: "18px",
            zIndex: "99",
            display: "felx",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <div
            className="fpchild1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "160px",
            }}
          >
            <span style={{ fontSize: "22px", fontSize: "bold" }}>Filter</span>
            <button
              onClick={handleDrawerClose}
              style={{ background: "none", border: "none" }}
            >
              <img alt="" src={cross} />
            </button>
          </div>
          <div
            className="form-group"
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label style={{ marginBottom: "5px" }} htmlFor="company">
              Company
            </label>
            <img
              style={{ position: "absolute", right: "16px", top: "40px" }}
              src={downarrow}
            />
            <select
              id="company"
              style={{
                width: "100%",
                padding: "7px",
              }}
              className="form-control"
              //   disabled={params.id ? true : false}
              onChange={(e) => {
                getFilterCompany(e.target.value);
                // storeRecentCompany({
                //   name: e.target.value,
                // });
              }}
            >
              <option hidden> Company</option>
              <option value={""}>All Company</option>
              {company?.data?.map((item) => {
                return (
                  <>
                    {item?.deleteStatus == 0 && (
                      <option value={item.name} key={item.id}>
                        {item.name}
                      </option>
                    )}
                  </>
                );
              })}
            </select>
          </div>
          <div
            className="form-group"
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label htmlFor="brancName">Branch</label>
            <select
              style={{ borderRadius: "10px", width: "100%" }}
              placeholder="Select Branch"
              disabled={!branch}
              className="form-select"
            >
              <option hidden>Name</option>
            </select>
          </div>
        </div>
      </Drawer>
      <Snackbar
        open={anyFieldEmpty}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          severity="error"
          style={{
            backgroundColor: "#8f2018",
            color: "white",
          }}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default JobList;
