import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BiCategory } from 'react-icons/bi'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function TaxSection() {
    const params = useParams()
    const { company, getCompnayCrud, handleClick, setSnackbarValue, base_url, getTaxAgencyData, taxAgencyData } = useData()
    useEffect(() => {
        getTaxAgencyData()
        getCompnayCrud()
    }, [])

    const [obj, setObj] = useState({
        company_id: '',
        name: '',
        period: '',
        frequency: '',
        reporting_method: '',
        buisnessId: '',
    })
    useEffect(() => {
        setObj({
            company_id: taxAgencyData?.agency?.company_id,
            name: taxAgencyData?.agency?.name,
            period: taxAgencyData?.agency?.period,
            frequency: taxAgencyData?.agency?.frequency,
            reporting_method: taxAgencyData?.agency?.reporting_method,
            buisnessId: taxAgencyData?.agency?.buisnessId,
        })
    }, [taxAgencyData])
    const handleUpdate = () => {
        const requiredFields = ['company_id', 'name', 'period', 'frequency', 'reporting_method', 'buisnessId'];

        const isValid = requiredFields.every(field => obj[field] !== '');

        if (!isValid) {
            handleClick();
            setSnackbarValue({ message: "Please fill out all the required fields.", color: "#DC4C64" });
            return;
        }
        fetch(`${base_url}update_agencies`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((data) => {
                getTaxAgencyData();
                handleClick();
                setSnackbarValue({ message: "Agency update successfully", color: "#2ECC71" });
                handleClose();
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        handleClose1()
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    return (
        <div>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <div className='inp-buttons d-flex gap-2 w-100' style={{ alignItems: "end", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px", justifyContent: "space-between", width: "100%" }}>
                            <div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Tax & Agency</li>
                                    </ol>
                                </nav>

                            </div>
                            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                                <div>
                                    <button
                                        id="basic-button"
                                        className="btn btn-primary"
                                        aria-controls={open1 ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open1 ? 'true' : undefined}
                                        onClick={handleClick1}
                                    >
                                        Agency
                                    </button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open1}
                                        onClose={handleClose1}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={handleOpen}>Edit Egency</MenuItem>
                                        <MenuItem>
                                            <Link to={params?.id ? `/admin/taxesView/${params?.id}` : "/admin/taxesView"} style={{ color: "inherit", textDecoration: "none" }}>View Taxs</Link>
                                        </MenuItem>
                                    </Menu>
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                Add
                                            </Typography>
                                            <div id="modal-modal-description" sx={{ mt: 2 }}>
                                                <div className='row'>
                                                    <div className='col-6 mb-3'>
                                                        <label>Company</label>
                                                        <div className='selectBox'>
                                                            <select className='form-select'
                                                                value={obj?.company_id}
                                                                onChange={(e) => setObj({ ...obj, company_id: e.target.value })}
                                                            >
                                                                <option hidden>Select Company</option>
                                                                {company?.data?.map((item) => {
                                                                    return (
                                                                        <>{item?.deleteStatus == 0 &&
                                                                            <option value={item.id} key={item.name}>
                                                                                {item.name}
                                                                            </option>
                                                                        }
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mb-3'>
                                                        <label>Agency Name</label>
                                                        <div className='inputBox'>
                                                            <input type="text" value={obj?.name} onChange={(e) => setObj({ ...obj, name: e.target.value })} placeholder='Agency Name' />
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mb-3'>
                                                        <label>Bussiness ID No.</label>
                                                        <div className='inputBox'>
                                                            <input value={obj?.buisnessId} onChange={(e) => setObj({ ...obj, buisnessId: e.target.value })} type="number" placeholder='Bussiness ID' />
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mb-3'>
                                                        <label>Tax Period</label>
                                                        <div className='selectBox'>
                                                            <select className='form-select' value={obj?.period} onChange={(e) => setObj({ ...obj, period: e.target.value })}>
                                                                <option value="">Select </option>
                                                                <option value="January">January</option>
                                                                <option value="Febuary">Febuary</option>
                                                                <option value="March">March</option>
                                                                <option value="April">April</option>
                                                                <option value="May">May</option>
                                                                <option value="June">June</option>
                                                                <option value="July">July</option>
                                                                <option value="Augest">August</option>
                                                                <option value="September">September</option>
                                                                <option value="Octuber">Octuber</option>
                                                                <option value="November">November</option>
                                                                <option value="December">December</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mb-3'>
                                                        <label>Tax Frequency</label>
                                                        <div className='selectBox'>
                                                            <select className='form-select' value={obj?.frequency} onChange={(e) => setObj({ ...obj, frequency: e.target.value })}>
                                                                <option value="">Select</option>
                                                                <option value="Monthly">Monthly</option>
                                                                <option value="Quarterly">Quarterly</option>
                                                                <option value="Yearly">Yearly</option>
                                                                <option value="Half-Yearly">Half-Yearly</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mb-3'>
                                                        <label>Reporting Method</label>
                                                        <div className='selectBox'>
                                                            <select className='form-select' value={obj?.reporting_method} onChange={(e) => setObj({ ...obj, reporting_method: e.target.value })}>
                                                                <option value="">Select</option>
                                                                <option value="Accrual">Accrual</option>
                                                                <option value="Cash">Cash</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-4'>
                                                    <div className='modalBtns d-flex justify-content-end gap-2'>
                                                        <button className='btn btn-primary' onClick={handleUpdate}>Submit</button>
                                                        <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Box>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <hr />
                <div className='row'>
                    <div className='header'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <div>
                                    <h3 style={{ lineHeight: ".5", fontWeight: "bold" }}>PRs-0.00</h3>
                                    <p style={{ color: "blue", fontSize: "14px" }}>01/01/2023 - 01/01/2023</p>
                                </div>
                                <div style={{ display: "flex", gap: "20px", justifyContent: "center", marginTop: "1rem" }}>
                                    <div>
                                        <h3 style={{ lineHeight: ".5", fontWeight: "bold" }}>PRs-0.00</h3>
                                        <p style={{ color: "blue", fontSize: "14px" }}>Collected on Sales</p>
                                    </div>
                                    <div>
                                        <h3 style={{ lineHeight: ".5", fontWeight: "bold" }}>PRs-0.00</h3>
                                        <p style={{ color: "blue", fontSize: "14px" }}>Paid on Purchases</p>
                                    </div>
                                    <div>
                                        <h3 style={{ lineHeight: ".5", fontWeight: "bold" }}>PRs-0.00</h3>
                                        <p style={{ color: "blue", fontSize: "14px" }}>Adjustments</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='card'>
                            <div className='card-body table-responsive recruitment'>
                                <div className="table-responsive">
                                    <table className="table accordion">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Tax Period</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Memo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr data-bs-toggle="collapse" data-bs-target="#r1">
                                                <th scope="row">1 <i className="bi bi-chevron-down" /></th>
                                                <td>date</td>
                                                <td>type</td>
                                                <td>period</td>
                                                <td>2000</td>
                                                <td>file</td>
                                            </tr>
                                            <tr className="collapse accordion-collapse" id="r1" data-bs-parent=".table">
                                                <td colSpan={6}> Item 1 detail .. This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow. </td>
                                            </tr>
                                            <tr data-bs-toggle="collapse" data-bs-target="#r2">
                                                <th scope="row">2 <i className="bi bi-chevron-down" /></th>
                                                <td>date</td>
                                                <td>type</td>
                                                <td>period</td>
                                                <td>2000</td>
                                                <td>file</td>
                                            </tr>
                                            <tr className="collapse accordion-collapse" id="r2" data-bs-parent=".table">
                                                <td colSpan={6}> Item 1 detail .. This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow. </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </div >
    )
}

export default TaxSection
