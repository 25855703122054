import React, { useEffect, useState } from 'react'
import { BiCategory, BiUser, BiMessageDetail } from 'react-icons/bi'
import { TbCategory2 } from 'react-icons/tb'
import { MdAirplanemodeActive, MdAirplanemodeInactive, MdPayment } from 'react-icons/md'
import { RiSecurePaymentLine, RiAccountPinCircleLine } from 'react-icons/ri'
import { BsCashCoin, BsFillImageFill } from 'react-icons/bs'
import { VscReferences, VscGroupByRefType } from 'react-icons/vsc'
import { GrServices } from 'react-icons/gr'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import { CiBoxList, CiCalendar, CiEdit, CiMoneyBill, CiPhone, CiTrash } from 'react-icons/ci'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function Services() {
    const params = useParams()
    const { makeInActiveService, makeActiveService, settingDat, getDat, getSupplierData, supplierData, updateServiceData, getSubcateAC, subcateAC, accountsDataC, getAccountsDataC, getsalesPurchase, salesPurchase, getServicesFIlterCom, accountsData, getAccountsData, deleteServiceData, handleClick, setSnackbarValue, getServicesData, servicesData, img_url, storeServiceData, getServicesCategory, servicecategory, getFilterServices } = useData()
    useEffect(() => {
        getServicesData()
        getServicesCategory()
        getAccountsData()
        getAccountsDataC()
        getsalesPurchase()
        getSupplierData()
        getDat()
    }, [])
    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedCate, setSelectedCate] = useState(params?.id || null)
    const [category, setcategory] = useState([])
    const filteredCompanies = accountsData?.company?.filter(item => item.deleteStatus === 0);
    const categoryCompny = (selectedCompany) => {
        const filteredCate = accountsData?.servicecategory?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));
        setcategory(filteredCate)
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj({
            company_id: params.id ? params.id : null,
            name: '',
            sku: '',
            stock_date: new Date().toISOString().split('T')[0],
            quantity: '',
            reorder_point: '',
            type: '',
            sale_price: '',
            product_type: '',
            sale_description: '',
            purchase_description: '',
            cost: '',
            expense_account_id: '',
            purchase_tax_id: '',
            income_account_id: '',
            sale_tax_id: '',
            supplier_id: '',
            product_subcategory_id: '',
            description: '',
            image: '',
            category_id: ''
        });
        setIsPurchasingInfoChecked(false)
        setIsSalesInfoChecked(false)
        setSelectedImage(null)
        const imgBox = document.getElementById('image-box');
        imgBox.src = '';
        setOpen(false)
    };
    const [obj, setObj] = useState({
        company_id: params.id ? params.id : null,
        name: '',
        sku: '',
        stock_date: new Date().toISOString().split('T')[0],
        quantity: '',
        reorder_point: '',
        type: '',
        sale_price: '',
        product_type: '',
        sale_description: '',
        purchase_description: '',
        cost: '',
        expense_account_id: '',
        purchase_tax_id: '',
        income_account_id: '',
        sale_tax_id: '',
        supplier_id: '',
        product_subcategory_id: '',
        description: '',
        image: '',
        category_id: ''
    })

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64URL = event.target.result;
                const base64ImageData = base64URL;
                setObj({
                    ...obj,
                    image: base64ImageData,
                });
                const imgBox = document.getElementById('image-box');
                imgBox.src = base64URL;
            };
            reader.readAsDataURL(file);
        }
    };
    const clearFields = () => {
        setObj({
            company_id: params.id ? params.id : null,
            name: '',
            sku: '',
            stock_date: new Date().toISOString().split('T')[0],
            quantity: '',
            reorder_point: '',
            type: '',
            sale_price: '',
            product_type: '',
            sale_description: '',
            purchase_description: '',
            cost: '',
            expense_account_id: '',
            purchase_tax_id: '',
            income_account_id: '',
            sale_tax_id: '',
            supplier_id: '',
            product_subcategory_id: '',
            description: '',
            image: '',
            category_id: ''
        });

        setSelectedImage(null);
        const imgBox = document.getElementById('image-box');
        imgBox.src = '';
    };

    const isFormValid = () => {
        for (const key in obj) {
            if (key !== "sale_price" && key !== "sku" && key !== "product_type" && key !== "sale_description" && key !== "purchase_description" && key !== "cost" && key !== "expense_account_id" && key !== "purchase_tax_id" && key !== "income_account_id" && key !== "sale_tax_id" && key !== "supplier_id" && key !== "product_subcategory_id" && obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = () => {
        if (isFormValid()) {
            handleClose()
            storeServiceData(obj);
            clearFields();
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const handleUpdate = () => {
        if (isFormValid()) {
            handleClose()
            updateServiceData(obj);
            clearFields();
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const [data, setData] = useState({
        name: ''
    })
    const [showImageOverlay, setShowImageOverlay] = useState(false);
    const [selectedImageURL, setSelectedImageURL] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleServices = params.id
        ? servicesData?.services?.filter(item => item?.company?.id == params.id) || []
        : servicesData?.services || [];

    const currentServices = visibleServices?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleServices.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const [isPurchasingInfoChecked, setIsPurchasingInfoChecked] = useState(false);
    const [isSalesInfoChecked, setIsSalesInfoChecked] = useState(false);

    const handlePurchasingInfoCheck = () => {
        setIsPurchasingInfoChecked((prevIsChecked) => !prevIsChecked);
    };
    const handleSalesInfoCheck = () => {
        setIsSalesInfoChecked((e) => !e);
    };

    console.log(obj);
    return (
        <>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Service</li>
                        </ol>
                    </nav>
                    <div className='inp-buttons d-flex gap-2' style={{ alignItems: "end" }}>
                        <div>
                            <select disabled={params.id ? true : false} className='form-select' onChange={(e) => {
                                handlePageChange(1)
                                getServicesFIlterCom(e.target.value)
                            }}>
                                <option value="">Select a company</option>
                                {filteredCompanies?.map((item) => (
                                    <option key={item.id} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* <div className='clientName'>
                            <select className='form-select' onChange={(e) => {
                                const selectedCategory = e.target.value;
                                setData({ ...data, name: selectedCategory });
                                getFilterServices(selectedCategory);
                                handlePageChange(1)
                            }}>
                                <option hidden>All Category</option>
                                <option value=''>All Category</option>
                                {filteredCate?.map((item) => {
                                    return (
                                        <option value={item.name} key={item.id}>{item.name}</option>
                                    )
                                })}
                            </select>

                        </div> */}
                        <button type="button" className="btn btn-primary" onClick={handleOpen}>Add Single</button>
                        <button type="button" className="btn btn-primary">
                            <Link to={'/admin/accounts/banking/bundlesofServices'} style={{ color: "inherit", textDecoration: "none" }}>
                                Add Bundles
                            </Link>
                        </button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} style={{ height: "600px", overflowY: "scroll" }} className='scrollBar'>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Add
                                </Typography>
                                <div id="modal-modal-description" sx={{ mt: 2 }}>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label>Company</label>
                                                <div className='selectBox'>
                                                    <select disabled={params.id ? true : false} value={obj?.company_id} onChange={(e) => {
                                                        categoryCompny(parseInt(e.target.value));
                                                        setObj({
                                                            ...obj,
                                                            company_id: parseInt(e.target.value)
                                                        });
                                                    }}>
                                                        <option value="">Select a company</option>
                                                        {filteredCompanies?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <label>Name</label>
                                                <div className='inputBox'>
                                                    <label><GrServices /></label>
                                                    <input type='text' value={obj?.name} placeholder='Name' onChange={(e) => setObj({ ...obj, name: e.target.value })}></input>
                                                </div>
                                                {settingDat?.data?.account_shipping_account === 1 && <>
                                                    <label>SKU</label>
                                                    <div className='inputBox'>
                                                        <label><CiBoxList /></label>
                                                        <input type='text' value={obj?.sku} placeholder='Sku' onChange={(e) => setObj({ ...obj, sku: e.target.value })}></input>
                                                    </div>
                                                </>}
                                                <label>Product</label>
                                                <div className='inputBox'>
                                                    <div className='image-upload' style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                                        <label for='inp'>
                                                            <BsFillImageFill /> <span style={{ fontSize: '14px' }}>Screenshot</span>
                                                        </label>
                                                        <input
                                                            id='inp'
                                                            style={{ display: 'none' }}
                                                            type='file'
                                                            onChange={handleImageChange}
                                                        />
                                                        <img id="image-box" style={{ width: '30px', height: '30px' }} src={selectedImage ? URL.createObjectURL(selectedImage) : ''} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='inputFields'>

                                                <label>Category</label>
                                                <div className='selectBox'>
                                                    <label><BiCategory /></label>
                                                    <select value={obj?.category_id} onChange={(e) => {
                                                        getSubcateAC(e.target.value)
                                                        setObj({ ...obj, category_id: e.target.value })
                                                    }}>
                                                        <option hidden>Select</option>
                                                        <option value={''}>Select</option>
                                                        {category?.map((item) => {
                                                            return (
                                                                <option value={item.id}>{item.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <label>Subcategory</label>
                                                <div className='selectBox'>
                                                    <label><BiCategory /></label>
                                                    <select value={obj?.product_subcategory_id} onChange={(e) => setObj({ ...obj, product_subcategory_id: e.target.value })}>
                                                        <option hidden>Select</option>
                                                        <option value={''}>Select</option>
                                                        {subcateAC?.subcategory?.map((item) => {
                                                            return (
                                                                <option value={`${item.id}`}>{item.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <label>Type</label>
                                                <div className='selectBox'>
                                                    <label><VscGroupByRefType /></label>
                                                    <select value={obj?.type} onChange={(e) => setObj({ ...obj, type: e.target.value })} >
                                                        <option hidden>Type</option>
                                                        <option value={''}>Select</option>
                                                        <option value="Service">Service</option>
                                                        <option value="Product">Product</option>
                                                    </select>
                                                </div>
                                                <label>Product Type</label>
                                                <div className='selectBox'>
                                                    <label><BiCategory /></label>
                                                    <select disabled={obj.type !== "Product"} value={obj?.product_type} onChange={(e) => setObj({ ...obj, product_type: e.target.value })}>
                                                        <option hidden>Type</option>
                                                        <option value="Inventory">Inventory</option>
                                                        <option value="Non Inventory">Non Inventory</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {obj?.product_type === 'Inventory' && <>
                                            <div className='col-12'>
                                                <label>Quantity</label>
                                                <div className='inputBox'>
                                                    <label><CiBoxList /></label>
                                                    <input type='number' value={obj?.quantity} placeholder='quanitity' onChange={(e) => setObj({ ...obj, quantity: e.target.value })}></input>
                                                </div>
                                            </div>
                                        </>}
                                        {obj?.product_type === 'Inventory' && <>
                                            <div className='col-12'>
                                                <label>Date</label>
                                                <div className='inputBox'>
                                                    <label><CiBoxList /></label>
                                                    <input type='date' value={obj?.stock_date} placeholder='date' onChange={(e) => setObj({ ...obj, stock_date: e.target.value })}></input>
                                                </div>
                                            </div>
                                        </>}
                                        {obj?.product_type === 'Inventory' && <>
                                            <div className='col-12'>
                                                <label>Re order Point</label>
                                                <div className='inputBox'>
                                                    <label><CiBoxList /></label>
                                                    <input type='number' value={obj?.reorder_point} placeholder='reorder point' onChange={(e) => setObj({ ...obj, reorder_point: e.target.value })}></input>
                                                </div>
                                            </div>
                                        </>}
                                        <div className='col-12 mb-3'>
                                            <label>Description</label>
                                            <div className='textarea'>
                                                <label><BiMessageDetail /></label>
                                                <textarea placeholder='Description' value={obj?.description} onChange={(e) => setObj({ ...obj, description: e.target.value })}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <h3 style={{ fontWeight: "bold", fontSize: "16px" }}>Purchasing information</h3>
                                                <input onChange={handlePurchasingInfoCheck} type='checkbox' style={{ width: "20px", height: "20px", marginBottom: "8px", marginLeft: "10px" }} />
                                            </div>
                                            {isPurchasingInfoChecked && (
                                                <div className='row'>
                                                    <div className='col-12 mb-3'>
                                                        <label>Description</label>
                                                        <div className='textarea'>
                                                            <label><BiMessageDetail /></label>
                                                            <textarea placeholder='Description' value={obj?.purchase_description} onChange={(e) => setObj({ ...obj, purchase_description: e.target.value })}></textarea>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mb-3'>
                                                        <div className='inputFields'>
                                                            <label>Cost</label>
                                                            <div className='inputBox'>
                                                                <label><GrServices /></label>
                                                                <input type='number' value={obj?.cost} placeholder='Cost' onChange={(e) => setObj({ ...obj, cost: e.target.value })}></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mb-3'>
                                                        <div className='inputFields'>
                                                            <label>Expense Account</label>
                                                            <div className='selectBox'>
                                                                <label><BiCategory /></label>
                                                                <select value={obj?.expense_account_id} onChange={(e) => setObj({ ...obj, expense_account_id: e.target.value })}>
                                                                    <option hidden>Type</option>
                                                                    {accountsDataC?.data?.map((item) => {
                                                                        return (
                                                                            <option value={item.id}>
                                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                    <div>
                                                                                        {item.name}
                                                                                    </div>
                                                                                    <div>
                                                                                        {item?.account_type?.name}
                                                                                    </div>
                                                                                </div>
                                                                            </option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mb-3'>
                                                        <div className='inputFields'>
                                                            <label>Purchase Tax</label>
                                                            <div className='selectBox'>
                                                                <label><BiCategory /></label>
                                                                <select value={obj?.purchase_tax_id} onChange={(e) => setObj({ ...obj, purchase_tax_id: e.target.value })}>
                                                                    <option hidden>Select</option>
                                                                    {salesPurchase?.purchase_tax?.map((item) => {
                                                                        return (
                                                                            <option value={item.id}>{item.name}({item?.rate})%</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mb-3'>
                                                        <div className='inputFields'>
                                                            <label>Prefferd Supplier</label>
                                                            <div className='selectBox'>
                                                                <label><BiCategory /></label>
                                                                <select value={obj?.supplier_id} onChange={(e) => setObj({ ...obj, supplier_id: e.target.value })}>
                                                                    <option hidden>Supplier</option>
                                                                    {supplierData?.map((item) => {
                                                                        return (
                                                                            <option value={item.id}>{item.name} {item?.account_type?.name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='row'>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <h3 style={{ fontWeight: "bold", fontSize: "16px" }}>Sales information</h3>
                                                <input onChange={handleSalesInfoCheck} type='checkbox' style={{ width: "20px", height: "20px", marginBottom: "8px", marginLeft: "10px" }} />
                                            </div>
                                            {isSalesInfoChecked && (
                                                <div className='row'>
                                                    <div className='col-12 mb-3'>
                                                        <label>Description</label>
                                                        <div className='textarea'>
                                                            <label><BiMessageDetail /></label>
                                                            <textarea value={obj?.sale_description} placeholder='Description' onChange={(e) => setObj({ ...obj, sale_description: e.target.value })}></textarea>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mb-3'>
                                                        <div className='inputFields'>
                                                            <label>Cost</label>
                                                            <div className='inputBox'>
                                                                <label><GrServices /></label>
                                                                <input value={obj?.sale_price} type='number' placeholder='Sales Price' onChange={(e) => setObj({ ...obj, sale_price: e.target.value })}></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mb-3'>
                                                        <div className='inputFields'>
                                                            <label>Income Account</label>
                                                            <div className='selectBox'>
                                                                <label><BiCategory /></label>
                                                                <select value={obj?.income_account_id} onChange={(e) => setObj({ ...obj, income_account_id: e.target.value })}>
                                                                    <option hidden>Type</option>
                                                                    {accountsDataC?.data?.map((item) => {
                                                                        return (
                                                                            <option value={item.id}>{item.name} {item?.account_type?.name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6 mb-3'>
                                                        <div className='inputFields'>
                                                            <label>Sales Tax</label>
                                                            <div className='selectBox'>
                                                                <label><BiCategory /></label>
                                                                <select value={obj?.sale_tax_id} onChange={(e) => setObj({ ...obj, sale_tax_id: e.target.value })}>
                                                                    <option hidden>Select</option>
                                                                    {salesPurchase?.sale_tax?.map((item) => {
                                                                        return (
                                                                            <option value={item.id}>{item.name} ({item?.rate})%</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='modalBtns d-flex justify-content-end gap-2'>
                                            <button className='btn btn-primary' onClick={() => {
                                                obj?.id ? handleUpdate() : handleSubmit()
                                            }}>{obj?.id ? 'Update' : 'Submit'}</button>
                                            <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table w-100">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Subcategory</th>
                                    <th scope="col">SKU</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Product Date</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Reorder Point</th>
                                    <th scope="col">Sales Description</th>
                                    <th scope="col">PurchaseDescription</th>
                                    <th scope="col">Screenshot</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Sale Price</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {params?.id ? currentServices?.map((item, idx) => {
                                    const truncatedDescriptionP = item.purchase_description?.slice(0, 20);
                                    const truncatedDescriptionS = item.sale_description?.slice(0, 20);
                                    const isDescriptionTruncatedP = item.purchase_description?.length > 20;
                                    const isDescriptionTruncatedS = item.sale_description?.length > 20;

                                    return (
                                        <>
                                            {item?.company?.id == params?.id && <tr>
                                                <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                <td>{item.company?.name}</td>
                                                <td>{item.name}</td>
                                                <td>{item?.category_id?.name}</td>
                                                <td>{item?.subcategory?.name}</td>
                                                <td>{item.sku ? item.sku : '-'}</td>
                                                <td>{item.type}</td>
                                                <td>{item.stock_date ? item.stock_date : '-'}</td>
                                                <td>{item.quantity ? item.quantity : '-'}</td>
                                                <td>{item.reorder_point ? item.reorder_point : '-'}</td>
                                                <td>
                                                    {
                                                        isDescriptionTruncatedS ? (
                                                            <div
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setSelectedDescription(item.sale_description);
                                                                    setShowDescriptionOverlay(true);
                                                                }}
                                                            >
                                                                {truncatedDescriptionS}...
                                                            </div>
                                                        ) : (
                                                            <div>{item.sale_description ? item.sale_description : '-'}</div>
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        isDescriptionTruncatedP ? (
                                                            <div
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setSelectedDescription(item.purchase_description);
                                                                    setShowDescriptionOverlay(true);
                                                                }}
                                                            >
                                                                {truncatedDescriptionP}...
                                                            </div>
                                                        ) : (
                                                            <div>{item.purchase_description ? item.purchase_description : '-'}</div>
                                                        )
                                                    }
                                                </td>
                                                <td style={{ display: "flex", justifyContent: "center", marginTop: "13px" }}>
                                                    <div style={{ width: '30px', height: '30px' }}>
                                                        <img style={{ width: '100%', height: '100%', cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedImageURL(img_url + item.image);
                                                                setShowImageOverlay(true);
                                                            }}
                                                            src={img_url + item.image} alt='' />
                                                    </div>
                                                </td>
                                                {showDescriptionOverlay && (
                                                    <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                        <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                            <p style={{ color: "white" }}>{selectedDescription}</p>
                                                        </div>
                                                    </div>
                                                )}
                                                <td>{item.cost ? item.cost : '-'}</td>
                                                <td>{item.sale_price ? item.sale_price : '-'}</td>
                                                <td>
                                                    {item.deleteStatus === 1 ? (
                                                        ''
                                                    ) : (
                                                        <>
                                                            <button
                                                                className="btn p-1 m-2 btn-primary"
                                                                onClick={() => {
                                                                    handleOpen();
                                                                    getAccountsData()
                                                                    categoryCompny(item?.company?.id)
                                                                    getSubcateAC(item?.category_id?.id)
                                                                    setObj({
                                                                        id: item?.id,
                                                                        company_id: item?.company?.id,
                                                                        name: item?.name,
                                                                        sku: item?.sku,
                                                                        description: item?.description,
                                                                        type: item?.type,
                                                                        image: item?.image,
                                                                        category_id: item?.category_id?.id,
                                                                        product_type: item?.product_type,
                                                                        sale_price: item?.sale_price,
                                                                        sale_description: item?.sale_description,
                                                                        purchase_description: item?.purchase_description,
                                                                        cost: item?.cost,
                                                                        expense_account_id: item?.expense_account_id,
                                                                        purchase_tax_id: item?.purchase_tax_id,
                                                                        income_account_id: item?.income_account_id,
                                                                        sale_tax_id: item?.sale_tax_id,
                                                                        supplier_id: item?.supplier_id,
                                                                        product_subcategory_id: item?.product_subcategory_id,
                                                                        stock_date: item?.stock_date,
                                                                        quantity: item?.quantity,
                                                                        reorder_point: item?.reorder_point,
                                                                    });
                                                                }}
                                                            >
                                                                <CiEdit style={{ fontSize: "20px" }} />
                                                            </button>
                                                            <button onClick={() => deleteServiceData(item.id)} className='btn btn-danger p-1 m-2'>
                                                                <CiTrash style={{ fontSize: "20px" }} />
                                                            </button>
                                                        </>
                                                    )}
                                                    {item.deleteStatus === 1 ? (
                                                        <button
                                                            className="btn p-1 m-2 btn-success"
                                                            onClick={() => makeActiveService(item.id)}
                                                        >
                                                            <MdAirplanemodeActive style={{ fontSize: "20px" }} />
                                                        </button>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {item.deleteStatus === 0 ? (
                                                        <button
                                                            className="btn p-1 m-2 btn-warning"
                                                            onClick={() => makeInActiveService(item.id)}
                                                        >
                                                            <MdAirplanemodeInactive style={{ fontSize: "20px" }} />
                                                        </button>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>}
                                        </>
                                    )
                                }) : currentServices?.length > 0 ? currentServices?.map((item, idx) => {
                                    const truncatedDescriptionP = item.purchase_description?.slice(0, 20);
                                    const truncatedDescriptionS = item.sale_description?.slice(0, 20);
                                    const isDescriptionTruncatedP = item.purchase_description?.length > 20;
                                    const isDescriptionTruncatedS = item.sale_description?.length > 20;

                                    return (
                                        <tr>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{item.company?.name}</td>
                                            <td>{item.name}</td>
                                            <td>{item?.category_id?.name}</td>
                                            <td>{item?.subcategory?.name}</td>
                                            <td>{item.sku ? item.sku : '-'}</td>
                                            <td>{item.type}</td>
                                            <td>{item.stock_date ? item.stock_date : '-'}</td>
                                            <td style={{ color: item?.quantity <= item?.reorder_point ? "red" : "black" }}>
                                                {item.quantity ? item.quantity : "-"}
                                            </td>
                                            <td>{item.reorder_point ? item.reorder_point : '-'}</td>
                                            <td>
                                                {
                                                    isDescriptionTruncatedS ? (
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedDescription(item.sale_description);
                                                                setShowDescriptionOverlay(true);
                                                            }}
                                                        >
                                                            {truncatedDescriptionS}...
                                                        </div>
                                                    ) : (
                                                        <div>{item.sale_description ? item.sale_description : '-'}</div>
                                                    )
                                                }
                                            </td>
                                            <td>
                                                {
                                                    isDescriptionTruncatedP ? (
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedDescription(item.purchase_description);
                                                                setShowDescriptionOverlay(true);
                                                            }}
                                                        >
                                                            {truncatedDescriptionP}...
                                                        </div>
                                                    ) : (
                                                        <div>{item.purchase_description ? item.purchase_description : '-'}</div>
                                                    )
                                                }
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "center", marginTop: "13px" }}>
                                                <div style={{ width: '30px', height: '30px' }}>
                                                    <img style={{ width: '100%', height: '100%', cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedImageURL(img_url + item.image);
                                                            setShowImageOverlay(true);
                                                        }}
                                                        src={img_url + item.image} alt='' />
                                                </div>
                                            </td>
                                            {showDescriptionOverlay && (
                                                <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                    <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                        <p style={{ color: "white" }}>{selectedDescription}</p>
                                                    </div>
                                                </div>
                                            )}
                                            <td>{item.cost ? item.cost : '-'}</td>
                                            <td>{item.sale_price ? item.sale_price : '-'}</td>
                                            <td>
                                                {item.deleteStatus === 1 ? (
                                                    ''
                                                ) : (
                                                    <>
                                                        <button
                                                            className="btn p-1 m-2 btn-primary"
                                                            onClick={() => {
                                                                handleOpen();
                                                                getAccountsData()
                                                                categoryCompny(item?.company?.id)
                                                                getSubcateAC(item?.category_id?.id)
                                                                setObj({
                                                                    id: item?.id,
                                                                    company_id: item?.company?.id,
                                                                    name: item?.name,
                                                                    sku: item?.sku,
                                                                    type: item?.type,
                                                                    image: item?.image,
                                                                    description: item?.description,
                                                                    category_id: item?.category_id?.id,
                                                                    product_type: item?.product_type,
                                                                    sale_price: item?.sale_price,
                                                                    sale_description: item?.sale_description,
                                                                    purchase_description: item?.purchase_description,
                                                                    cost: item?.cost,
                                                                    expense_account_id: item?.expense_account_id,
                                                                    purchase_tax_id: item?.purchase_tax_id,
                                                                    income_account_id: item?.income_account_id,
                                                                    sale_tax_id: item?.sale_tax_id,
                                                                    supplier_id: item?.supplier_id,
                                                                    product_subcategory_id: item?.product_subcategory_id,
                                                                    stock_date: item?.stock_date,
                                                                    quantity: item?.quantity,
                                                                    reorder_point: item?.reorder_point,
                                                                });
                                                            }}
                                                        >
                                                            <CiEdit style={{ fontSize: "20px" }} />
                                                        </button>
                                                        <button onClick={() => deleteServiceData(item.id)} className='btn btn-danger p-1 m-2'>
                                                            <CiTrash style={{ fontSize: "20px" }} />
                                                        </button>
                                                    </>
                                                )}
                                                {item.deleteStatus === 1 ? (
                                                    <button
                                                        className="btn p-1 m-2 btn-success"
                                                        onClick={() => makeActiveService(item.id)}
                                                    >
                                                        <MdAirplanemodeActive style={{ fontSize: "20px" }} />
                                                    </button>
                                                ) : (
                                                    ''
                                                )}
                                                {item.deleteStatus === 0 ? (
                                                    <button
                                                        className="btn p-1 m-2 btn-warning"
                                                        onClick={() => makeInActiveService(item.id)}
                                                    >
                                                        <MdAirplanemodeInactive style={{ fontSize: "20px" }} />
                                                    </button>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                    )
                                }) : (
                                    <tr>
                                        <td colSpan="13" className="text-center">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                        </table >
                        {showImageOverlay && (
                            <div className="overlayy" style={{ background: "rgba(0,0,0,0.38)" }} onClick={() => setShowImageOverlay(false)}>
                                <img
                                    src={selectedImageURL}
                                    alt="Full-Screen Image"
                                    style={{ maxHeight: '100%', maxWidth: '100%' }}
                                />
                            </div>
                        )
                        }
                        <div className="pagination-controls">{paginationControls}</div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Services
