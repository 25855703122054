import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa6";
import CreateYearlyBudget from './CreateYearlyBudget';
import CreateQuartlyBudget from './CreateQuartlyBudget';
function CreateBudget() {
    const { getClientData, clientData, company, getCompnayCrud, storeBudgetMontly, getBudgetAcData, budgetAcData, storeBudgetYearly } = useData()
    useEffect(() => {
        getBudgetAcData()
        getCompnayCrud()
        getClientData()
    }, [])
    const [showNestedTables, setShowNestedTables] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
    });

    const toggleNestedTable = (rowNumber) => {
        setShowNestedTables((prev) => ({
            ...prev,
            [rowNumber]: !prev[rowNumber],
        }));
    };

    const [monthlyBudget, setMonthlyBudget] = useState({
        company_id: '',
        name: '',
        finencial_year: '',
        interval: 'Monthly',
        pre_fill: '',
        subdivided: '',
        total_amount: '',
        client_id: '',
        monthly_budget: {
            income: budgetAcData?.income?.map((item) => ({
                account_id: item.id,
                january: '',
                february: '',
                march: '',
                april: '',
                may: '',
                june: '',
                july: '',
                august: '',
                september: '',
                october: '',
                november: '',
                december: '',
                total_price: '',
            })),
            other_income: budgetAcData?.other_income?.map((item) => ({
                account_id: item.id,
                january: '',
                february: '',
                march: '',
                april: '',
                may: '',
                june: '',
                july: '',
                august: '',
                september: '',
                october: '',
                november: '',
                december: '',
                total_price: '',
            })),
            cost_of_sale: budgetAcData?.cost_of_sale?.map((item) => ({
                account_id: item.id,
                january: '',
                february: '',
                march: '',
                april: '',
                may: '',
                june: '',
                july: '',
                august: '',
                september: '',
                october: '',
                november: '',
                december: '',
                total_price: '',
            })),
            expenses: budgetAcData?.expenses?.map((item) => ({
                account_id: item.id,
                january: '',
                february: '',
                march: '',
                april: '',
                may: '',
                june: '',
                july: '',
                august: '',
                september: '',
                october: '',
                november: '',
                december: '',
                total_price: '',
            })),
            other_expenses: budgetAcData?.other_expenses?.map((item) => ({
                account_id: item.id,
                january: '',
                february: '',
                march: '',
                april: '',
                may: '',
                june: '',
                july: '',
                august: '',
                september: '',
                october: '',
                november: '',
                december: '',
                total_price: '',
            })),
        }
    });
    const calculateTotalIncome = (month) => {
        const total = monthlyBudget?.monthly_budget?.income?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalIncome1 = (month) => {
        const total = monthlyBudget?.monthly_budget?.other_income?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum = () => {
        const totalSum = monthlyBudget?.monthly_budget?.income?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalSum1 = () => {
        const totalSum = monthlyBudget?.monthly_budget?.other_income?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome2 = (month) => {
        const total = monthlyBudget?.monthly_budget?.expenses?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum2 = () => {
        const totalSum = monthlyBudget?.monthly_budget?.expenses?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome3 = (month) => {
        const total = monthlyBudget?.monthly_budget?.cost_of_sale?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum3 = () => {
        const totalSum = monthlyBudget?.monthly_budget?.cost_of_sale?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome4 = (month) => {
        const total = monthlyBudget?.monthly_budget?.other_expenses?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum4 = () => {
        const totalSum = monthlyBudget?.monthly_budget?.other_expenses?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const updateTotalAmount = () => {
        const totalSum =
            parseFloat(calculateTotalSum() || 0) +
            parseFloat(calculateTotalSum1() || 0) +
            parseFloat(calculateTotalSum2() || 0) +
            parseFloat(calculateTotalSum3() || 0) +
            parseFloat(calculateTotalSum4() || 0);

        setMonthlyBudget(prevState => ({
            ...prevState,
            total_amount: totalSum?.toFixed(0),
        }));
    };
    useEffect(() => {
        updateTotalAmount();
    }, [
        monthlyBudget?.monthly_budget?.income,
        monthlyBudget?.monthly_budget?.other_income,
        monthlyBudget?.monthly_budget?.expenses,
        monthlyBudget?.monthly_budget?.cost_of_sale,
        monthlyBudget?.monthly_budget?.other_expenses,
    ]);
    console.log(monthlyBudget)
    const handleSubmit = () => {
        storeBudgetMontly(monthlyBudget)
    }
    return (
        <>
            <div className='createBudgetData'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Name</label>
                        <input type="text" className='form-control' value={monthlyBudget?.name} onChange={(e) => setMonthlyBudget({ ...monthlyBudget, name: e.target.value })} placeholder='Name' />
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Company</label>
                        <select className='form-control' value={monthlyBudget?.company_id} onChange={(e) => setMonthlyBudget({ ...monthlyBudget, company_id: e.target.value })}>
                            <option hidden>Select</option>
                            {company?.data?.map((item) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Financial Year</label>
                        <select className='form-control' value={monthlyBudget?.finencial_year} onChange={(e) => setMonthlyBudget({ ...monthlyBudget, finencial_year: e.target.value })}>
                            <option hidden>Select</option>
                            <option value='FY2023(jan 2023 - Dec 2023)'>FY2023(jan 2023 - Dec 2023)</option>
                            <option value='FY2022(jan 2022 - Dec 2022)'>FY2022(jan 2022 - Dec 2022)</option>
                            <option value='FY2021(jan 2021 - Dec 2021)'>FY2021(jan 2021 - Dec 2021)</option>
                            <option value='FY2020(jan 2020 - Dec 2020)'>FY2020(jan 2020 - Dec 2020)</option>
                        </select>
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Pre-fill data?</label>
                        <select className='form-control' value={monthlyBudget?.pre_fill} onChange={(e) => setMonthlyBudget({ ...monthlyBudget, pre_fill: e.target.value })}>
                            <option hidden>Select</option>
                            <option value='No'>No</option>
                            <option value='Actual Data 2022'>Actual Data 2022</option>
                            <option value='Actual Data 2023'>Actual Data 2023</option>
                        </select>
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Subdivide by</label>
                        <select className='form-control' value={monthlyBudget?.subdivided} onChange={(e) => setMonthlyBudget({ ...monthlyBudget, subdivided: e.target.value })}>
                            <option hidden>Select</option>
                            <option value="Don't subdivide">Don't subdivide</option>
                            <option value="Customer">Customer</option>
                        </select>
                    </div>
                    {monthlyBudget?.subdivided === 'Customer' && <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Customer</label>
                        <select className='form-control' value={monthlyBudget?.client_id} onChange={(e) => setMonthlyBudget({ ...monthlyBudget, client_id: e.target.value })}>
                            <option hidden>Select</option>
                            {clientData?.map((item) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>}
                </div>
                <hr />
                <button onClick={handleSubmit}>Submit</button>
                <div className='row'>
                    <div className='card'>
                        <div className='card-body table-responsive recruitment'>
                            <table className='table' style={{ width: "90rem" }}>
                                <thead>
                                    <tr>
                                        <th>Account</th>
                                        <th>Jan</th>
                                        <th>Feb</th>
                                        <th>Mar</th>
                                        <th>Apr</th>
                                        <th>May</th>
                                        <th>Jun</th>
                                        <th>Jul</th>
                                        <th>Aug</th>
                                        <th>Sep</th>
                                        <th>Oct</th>
                                        <th>Nov</th>
                                        <th>Dec</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ background: "lightgray", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Income</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(1)}>
                                                {showNestedTables[1] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>

                                        </td>
                                    </tr>
                                    {showNestedTables[1] && (
                                        <>
                                            {budgetAcData?.income?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.income?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.january || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        january: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.february || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        february: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.march || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        march: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.april || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        april: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.may || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        may: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.june || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        june: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.july || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.august || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        august: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.september || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        september: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.october || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        october: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.november || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        november: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.december || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        december: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={totalIncome}
                                                                className='form-control'
                                                                readOnly
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Income</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    value={calculateTotalIncome(month)}
                                                    readOnly
                                                />
                                            </td>
                                        ))}
                                        <td>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={calculateTotalSum()}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                    <tr style={{ background: "lightgray", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Other Income</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(2)}>
                                                {showNestedTables[2] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>

                                        </td>
                                    </tr>
                                    {showNestedTables[2] && (
                                        <>
                                            {budgetAcData?.other_income?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.other_income?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.january || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        january: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.february || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        february: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.march || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        march: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.april || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        april: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.may || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        may: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.june || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        june: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.july || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.august || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        august: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.september || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        september: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.october || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        october: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.november || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        november: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.december || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        december: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={totalIncome}
                                                                className='form-control'
                                                                readOnly
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Income</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    value={calculateTotalIncome1(month)}
                                                    readOnly
                                                />
                                            </td>
                                        ))}
                                        <td>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={calculateTotalSum1()}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                    <tr style={{ background: "lightgray", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Expenses</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(3)}>
                                                {showNestedTables[3] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>

                                        </td>
                                    </tr>
                                    {showNestedTables[3] && (
                                        <>
                                            {budgetAcData?.expenses?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.expenses?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.january || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        january: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.february || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        february: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.march || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        march: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.april || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        april: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.may || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        may: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.june || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        june: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.july || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.august || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        august: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.september || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        september: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.october || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        october: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.november || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        november: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.december || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        december: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={totalIncome}
                                                                className='form-control'
                                                                readOnly
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Expenses</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    value={calculateTotalIncome2(month)}
                                                    readOnly
                                                />
                                            </td>
                                        ))}
                                        <td>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={calculateTotalSum2()}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                    <tr style={{ background: "lightgray", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Other Expenses</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(4)}>
                                                {showNestedTables[4] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>

                                        </td>
                                    </tr>
                                    {showNestedTables[4] && (
                                        <>
                                            {budgetAcData?.other_expenses?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.other_expenses?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.january || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        january: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.february || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        february: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.march || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        march: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.april || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        april: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.may || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        may: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.june || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        june: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.july || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.august || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        august: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.september || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        september: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.october || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        october: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.november || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        november: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.december || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        december: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={totalIncome}
                                                                className='form-control'
                                                                readOnly
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Expenses</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    value={calculateTotalIncome4(month)}
                                                    readOnly
                                                />
                                            </td>
                                        ))}
                                        <td>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={calculateTotalSum4()}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                    <tr style={{ background: "lightgray", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Cost of Sales</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(5)}>
                                                {showNestedTables[5] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>

                                        </td>
                                    </tr>
                                    {showNestedTables[5] && (
                                        <>
                                            {budgetAcData?.cost_of_sale?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.cost_of_sale?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.january || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        january: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.february || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        february: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.march || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        march: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.april || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        april: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.may || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        may: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.june || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        june: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.july || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.august || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        august: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.september || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        september: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.october || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        october: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.november || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        november: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.december || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        december: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control'
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={totalIncome}
                                                                className='form-control'
                                                                readOnly
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total COS</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    value={calculateTotalIncome3(month)}
                                                    readOnly
                                                />
                                            </td>
                                        ))}
                                        <td>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={calculateTotalSum3()}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateBudget
