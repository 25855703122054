import React, { useEffect, useState } from "react";
import { useData } from "../ContextProvider/Context";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
// import Modal from '@mui/material/Modal';
// import { Form } from 'antd';
import { CiTrash, CiEdit } from "react-icons/ci";
import filter from "../Images/filtericon.png";
import cross from "../Images/filtercross.png";
import vector from "../Images/Vector.png";
import { Drawer, IconButton, Card, CardContent, Snackbar, Alert } from "@mui/material";
import desigicon from "../Images/desigicon.png";
import editicon from "../Images/editicon.png";
import deleteicon from "../Images/deleteicon.png";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import desigicon1 from "../Images/desigicon1.png";
import downarrow from "../Images/chevron-down.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Designation() {
  const params = useParams();

  const {
    handleClick,
    setSnackbarValue,
    company,
    deleteDesignation,
    editDesignation,
    designationData,
    departmentval,
    getdepartmetById,
    branch,
    getBarnchById,
    getdesignation,
    getFilterCompany,
    getCompnayCrud,
    storeDesignationData,
    permission,
  } = useData();

  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => {
  //   setOpen(false);
  //   setdesignationData({
  //     companyId: params.id ? params.id : "",
  //     branchId: "",
  //     departmentId: "",
  //     designation: "",
  //   });
  // };
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
    setdesignationData({
      companyId: params.id ? params.id : "",
      branchId: "",
      departmentId: "",
      designation: "",
    });
  };
  useEffect(() => {
    getCompnayCrud();
    getdesignation();
  }, []);
  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(true);
  const handleClose01 = () => setShow(false);
  const handleClearFields = () => {
    setdesignationData({
      companyId: params.id ? params.id : "",
      branchId: "",
      departmentId: "",
      designation: "",
    });
  };
  const [designation, setdesignationData] = useState({
    companyId: params.id ? params.id : "",
    branchId: "",
    departmentId: "",
    designation: "",
  });
  console.log(designation)
  const state = {
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
  }
  const { vertical, horizontal } = state;
  const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleOk = () => {
    if (designation?.companyId?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Company is required.');
    } else if (designation?.branchId?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Branch is required.');
    } else if (designation?.departmentId?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Department is required.');
    } else if (designation?.designation?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Designation is required.');
    } else {
      storeDesignationData(designation)
      handleClearFields()
      handleClose01()
      handleCloseSnackbar()

    }
  }
  const handleUpdate = () => {
    if (designation?.designation?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Designation is required.');
    } else {
      editDesignation(designation, designation?.id)
      handleClearFields()
      handleClose01()
      handleCloseSnackbar()

    }
  }

  const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
  };

  const [is, setid] = useState("");
  const openEditModal = (item) => {
    var obj = {
      companyId: item?.company?.id,
      branchId: item?.branch?.id,
      departmentId: item?.department?.id,
      designation: item?.title,
    };
    setdesignationData(obj);
    getBarnchById(item?.company?.id);
    getdepartmetById(item?.branch?.id);
    setid(item.id);
    handleOpen1();
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = params.id
    ? designationData?.filter((item) => item?.company?.id == params.id) || []
    : designationData || [];

  const totalPages = Math.ceil(currentItems.length / itemsPerPage);

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const [currentDate, setCurrentDate] = useState(new Date());


  const formattedDate = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }
  return (
    <>
      <div className="designationData">
        {/* Filter box content */}
        <Drawer anchor="right" open={openDrawer} onClose={handleDrawerClose}>
          <div
            className="filterparent"
            style={{
              // position: "absolute",
              // right: "0",
              // top: "0",
              background: "white",
              padding: "18px",
              zIndex: "99",
              display: "felx",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div
              className="fpchild1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "160px",
              }}
            >
              <span style={{ fontSize: "22px", fontSize: "bold" }}>Filter</span>
              <button
                onClick={handleDrawerClose}
                style={{ background: "none", border: "none" }}
              >
                <img alt='' src={cross} />
              </button>
            </div>
            <div
              className="form-group"
              style={{
                marginBottom: "20px",
                textAlign: "left",
                position: "relative",
              }}
            >
              <label style={{ marginBottom: "5px" }} htmlFor="company">
                Company
              </label>
              <img
                style={{ position: "absolute", right: "16px", top: "40px" }}
                src={downarrow}
              />
              <select
                id="company"
                style={{
                  width: "100%",
                  padding: "7px",
                }}
                className="form-control"
                disabled={params.id ? true : false}
                onChange={(e) => {
                  getFilterCompany(e.target.value);
                  // storeRecentCompany({
                  //   name: e.target.value,
                  // });
                }}
              >
                <option hidden> Company</option>
                <option value={""}>All Company</option>
                {company?.data?.map((item) => {
                  return (
                    <>
                      {item?.deleteStatus == 0 && (
                        <option value={item.name} key={item.id}>
                          {item.name}
                        </option>
                      )}
                    </>
                  );
                })}
              </select>
            </div>

          </div>
        </Drawer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <nav >
              <ul style={{ paddingLeft: "3px" }}>
                <li
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Company Assets
                </li>
                <li>{formattedDate}</li>
              </ul>
            </nav>
          </div>
          <div className="d-flex justify-content-end mb-2 gap-2">
            <div className="text-end">
              {permission.includes("Designation Create") && (
                <button
                  className="btn btn-primary"
                  onClick={handleOpen}
                  style={{
                    padding: ".5rem 1rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <img alt='' src={desigicon} />
                  Add Designation
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div>
            <div className="card">
              <div className="card-body">
                <div
                  className="headtable"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div>
                    <h5>All Designation</h5>
                  </div>
                  {/* <div>
                    <button
                      onClick={handleDrawerOpen}
                      style={{
                        background: "transparent",
                        borderRadius: "10px",
                        border: "1px solid darkgray",
                        padding: "2px 5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Filter
                      <img
                        style={{ width: "20%", marginLeft: "2px" }}
                        src={filter}
                      />
                    </button>
                  </div> */}
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Dept ID</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Department</th>
                      <th scope="col">Branch</th>
                      <th scope="col">Company</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {params.id
                      ? currentItems
                        .slice(indexOfFirstItem, indexOfLastItem)
                        ?.map((item, idx) => {
                          return (
                            <>
                              {item?.company?.id == params.id && (
                                <tr>
                                  <th scope="row">
                                    {indexOfFirstItem + idx + 1}
                                  </th>
                                  <td>
                                    <Link className="link linkk">
                                      <span
                                        style={{
                                          background: "blue",
                                          padding: "2px",
                                          color: "white",
                                        }}
                                      >
                                        {item.title}
                                      </span>
                                    </Link>
                                  </td>
                                  <td>
                                    <span
                                      style={{
                                        background: "blueviolet",
                                        padding: "4px",
                                        borderRadius: "12px",
                                        color: "white",
                                      }}
                                    >
                                      {item?.department?.department}
                                    </span>
                                  </td>
                                  <td>{item?.branch?.branch}</td>
                                  <td>{item?.company?.name}</td>
                                  <td>
                                    {permission.includes("Designation Edit") && (
                                      <button
                                        style={{
                                          background: "transparent",
                                          border: "none",
                                        }}
                                        className=""
                                        onClick={() => {
                                          handleOpen()
                                          setdesignationData({
                                            id: item.id,
                                            companyId: item.companyId,
                                            branchId: item.branchId,
                                            departmentId: item.department?.id,
                                            designation: item.title,
                                          })
                                        }}
                                      >
                                        <img alt='' src={editicon} />
                                      </button>
                                    )}

                                    {permission.includes(
                                      "Designation Delete"
                                    ) && (
                                        <button
                                          className=""
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                          }}
                                          onClick={() => deleteDesignation(item.id)}
                                        >
                                          <img alt='' src={deleteicon} />
                                        </button>
                                      )}
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })
                      : currentItems
                        .slice(indexOfFirstItem, indexOfLastItem)
                        ?.map((item, idx) => {
                          return (
                            <tr>
                              <th scope="row">
                                {indexOfFirstItem + idx + 1}
                              </th>
                              <td>
                                <Link className="link linkk">
                                  <span
                                    style={{
                                      background: "blue",
                                      padding: "2px",
                                      color: "white",
                                    }}
                                  >
                                    {item.title}
                                  </span>
                                </Link>
                              </td>
                              <td>
                                <span
                                  style={{
                                    background: "blueviolet",
                                    padding: "4px",
                                    borderRadius: "12px",
                                    color: "white",
                                  }}
                                >
                                  {item?.department?.department}
                                </span>
                              </td>
                              <td>{item?.branch?.branch}</td>
                              <td>{item?.company?.name}</td>
                              <td>
                                {permission.includes("Designation Edit") && (
                                  <button
                                    style={{
                                      background: "transparent",
                                      border: "none",
                                    }}
                                    className=""
                                    onClick={() => {
                                      handleOpen()
                                      setdesignationData({
                                        id: item.id,
                                        companyId: item.companyId,
                                        branchId: item.branchId,
                                        departmentId: departmentval[0]?.id,
                                        designation: item.title,

                                      })
                                      getdepartmetById(item.branchId)
                                    }}
                                  >
                                    <img alt='' src={editicon} />
                                  </button>
                                )}

                                {permission.includes(
                                  "Designation Delete"
                                ) && (
                                    <button
                                      className=""
                                      style={{
                                        background: "transparent",
                                        border: "none",
                                      }}
                                      onClick={() => deleteDesignation(item.id)}
                                    >
                                      <img alt='' src={deleteicon} />
                                    </button>
                                  )}
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
                <div className="pagination-controls">{paginationControls}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal style={{ zIndex: "9999", marginTop: "20px important" }} show={show} onHide={handleClose01}>
        <Modal.Header
          closeButton
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <img
            style={{
              width: "70px",
              background: "gainsboro",
              borderRadius: "50%",
            }}
            src={desigicon1}
          />
          <span style={{ fontSize: "24px", color: "#667085" }}>
            {designation?.id ? "Edit Designation" : 'Add Designation'}
          </span>
        </Modal.Header>
        <Modal.Body>
          <div
            className="form-group"
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label style={{ marginBottom: "5px" }} htmlFor="company">
              Company<span style={{ color: "red" }}>*</span>
            </label>
            {/* <img
              style={{ position: "absolute", right: "16px", top: "40px" }}
              src={downarrow}
            /> */}
            <select
              placeholder="Select Company"
              style={{ width: "100%", borderRadius: "10px" }}
              value={designation.companyId}
              disabled={params.id ? true : false}
              onChange={(e) => {
                setdesignationData({
                  ...designation,
                  companyId: e.target.value,
                });
                getBarnchById(e.target.value);
              }}
              className="form-select"
            >
              <option hidden>Select Company<span style={{ color: "red" }}>*</span></option>
              {company?.data?.map((item) => {
                return (
                  <>
                    {item?.deleteStatus == 0 && (
                      <option value={item.id} key={item.name}>
                        {item.name}
                      </option>
                    )}
                  </>
                );
              })}
            </select>
          </div>

          <div
            className="form-group"
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label htmlFor="brancName">Branch<span style={{ color: "red" }}>*</span></label>
            <select
              placeholder="Select Branch"
              style={{ width: "100%", borderRadius: "10px" }}
              value={designation.branchId}
              onChange={(e) => {
                setdesignationData({
                  ...designation,
                  branchId: e.target.value,
                });
                getdepartmetById(e.target.value);
              }}
              className="form-select"
              disabled={!branch}
            >
              <option hidden>Branch</option>

              {branch?.data?.map((b) => (
                <option key={b.id} value={b.id}>
                  {b.branch}
                </option>
              ))}
            </select>
          </div>

          <div
            className="form-group"
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label> Department<span style={{ color: "red" }}>*</span></label>
            <select
              placeholder="Select Branch"
              style={{ width: "100%", borderRadius: "10px" }}
              value={designation.departmentId}
              onChange={(e) => {
                setdesignationData({
                  ...designation,
                  departmentId: e.target.value,
                });
              }}
              disabled={!branch}
              className="form-select"
            >
              <option hidden> Department</option>

              {departmentval?.map((b) => (
                <option key={b.id} value={b.id}>
                  {b.department}
                </option>
              ))}
            </select>
          </div>

          <InputGroup className="mb-3">
            <Form.Group
              className="mb-3"
              controlId="formGroupName"
              style={{ width: "100%", textAlign: "left" }}
            >
              <Form.Label>Designation<span style={{ color: "red" }}>*</span></Form.Label>
              <Form.Control
                style={{ borderRadius: "10px", padding: "7px" }}
                type="text"
                placeholder="Designation"
                value={designation.designation}
                onChange={(e) =>
                  setdesignationData({
                    ...designation,
                    designation: e.target.value,
                  })
                }
              />
            </Form.Group>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              width: "38%",
              background: "white",
              color: "black",
              boxShadow: "2px 3px 3px black",
            }}
            onClick={() => {
              handleClose01();
              handleClearFields()
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ width: "38%" }}
            variant="primary"
            onClick={() => {
              designation?.id ? handleUpdate() : handleOk();
            }}
          >
            {designation?.id ? "Update Designation" : 'Create Designation'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Snackbar
        open={anyFieldEmpty}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          severity="error"
          style={{
            backgroundColor: '#8f2018',
            color: "white",
          }}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
export default Designation;
