import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Signin from "./Pages/Signin";
import Signup from "./Pages/Signup";
import ResetPassword from "./Pages/ResetPassword";
import AdminPannel from "./Pages/AdminPannel";
import EmployeeProfile from "./Components/EmployeeProfile";
// import ProjectDetail from "./Components/ProjectDetail";
import EmployeeLogin from "./Components/EmployeeLogin";
import UserLogin from "./Components/UserLogin";
import UserSignUp from "./Components/UserSignUp";
// import ProjectDetail from "./Components/ProjectDetail";
import Ewizard from "./Components/Ewizard";
import Dashboard from "./Components/Dashboard";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/signin" element={<Signin />} />
          {/* <Route path="/projectdetail" element={<ProjectDetail />} /> */}
          <Route path="/ewizard" element={<Ewizard />} />
          <Route path="/employee-signin" element={<EmployeeLogin />} />
          <Route path="/user-login/:id" element={<UserLogin />} />
          <Route path="/user-signUp/:id" element={<UserSignUp />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/profile" element={<EmployeeProfile />} />
          <Route path="/dashbord" element={<Dashboard />} />
          <Route path="/admin/*" element={<AdminPannel />}></Route>
          {/* <Route path="/task" element={<ProjectDetail />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
