import React, { useEffect, useState } from 'react'
import { BiCategory } from 'react-icons/bi'
import { TbCategory2 } from 'react-icons/tb'
import { CiEdit, CiTrash } from 'react-icons/ci'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function Assets() {
    const params = useParams()
    const { getFilterAssetsComp, getAccountsData, accountsData, handleClick, setSnackbarValue, getAssetsSubCate, assetSubCate, storeAssetsSubCate, getAssetsCate, assetCate, updateAsset, deleteAssetSubCategory, getFilterAssets } = useData()
    useEffect(() => {
        getAssetsSubCate()
        getAssetsCate()
        getAccountsData()
    }, [])
    const [selectedCompany, setSelectedCompany] = useState(params.id || null)
    const filteredCompanies = accountsData?.company?.filter(item => item.deleteStatus === 0);
    const filteredAssetCate = accountsData?.assetcategory?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));

    const handleDeleteCategory = (id) => {
        deleteAssetSubCategory(id);
    }
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj({
            company_id: params.id ? params.id : selectedCompany,
            name: '',
            asset_category_id: '',
        })
        setOpen(false)
    };
    const [obj, setObj] = useState({
        company_id: params.id ? params.id : selectedCompany,
        name: '',
        asset_category_id: '',
    })
    const isFormValid = () => {
        for (const key in obj) {
            if (obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = () => {
        if (isFormValid()) {
            storeAssetsSubCate(obj)
            setObj({
                company_id: params.id ? params.id : selectedCompany,
                name: '',
                asset_category_id: '',
            })
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const handleUpdateSubmit = () => {
        if (isFormValid()) {
            updateAsset(obj?.id, {
                company_id: obj?.company_id,
                asset_category_id: obj?.asset_category_id?.id,
                name: obj?.name
            })
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const [assCate, setAssCate] = useState({
        name: ''
    })
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleAsset = params.id
        ? assetSubCate?.assets?.filter(item => item?.company?.id == params.id) || []
        : assetSubCate?.assets || [];

    const currentAssetsCate = visibleAsset?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleAsset.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }

    return (
        <>
            <div className='expenseSubCategory'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>

                    <div className='inp-buttons d-flex gap-2 w-100' style={{ alignItems: "end", justifyContent: "space-between" }}>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Assets</li>
                                </ol>
                            </nav>
                        </div>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <div>
                                <label style={{ fontSize: "10px", fontWeight: "bold" }}>Company</label>
                                <select disabled={params.id ? true : false} style={{ height: "36px" }} className='form-select' onChange={(e) => getFilterAssetsComp(e.target.value)} >
                                    <option value="">Select a company</option>
                                    {filteredCompanies?.map((item) => (
                                        <option key={item.id} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label style={{ fontSize: "10px", fontWeight: "bold" }}>Category</label>
                                <select onChange={(e) => {
                                    const selectedCategory = e.target.value;
                                    setAssCate({ ...assCate, name: selectedCategory });
                                    getFilterAssets(selectedCategory);
                                    handlePageChange(1)
                                }}
                                    className='form-select'
                                >
                                    <option hidden>All Category</option>
                                    <option value={''}>All Category</option>
                                    {assetCate?.data?.map((item) => {
                                        return (
                                            <option value={item.name} key={item.id}>{item.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={handleOpen}>Add</button>
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Add
                                </Typography>
                                <div id="modal-modal-description" sx={{ mt: 2 }}>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='inputFields'>
                                                <label>Select Company</label>
                                                <div className='selectBox'>
                                                    <label><BiCategory /></label>
                                                    <select disabled={params.id ? true : false} value={obj?.company_id} onChange={(e) => {
                                                        setSelectedCompany(parseInt(e.target.value));
                                                        setObj({
                                                            ...obj,
                                                            company_id: parseInt(e.target.value)
                                                        });
                                                    }}>
                                                        <option value="">Select a company</option>
                                                        {filteredCompanies?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='inputFields'>
                                                <div className='col-12'>
                                                    <label>Category</label>
                                                    <div className='selectBox'>
                                                        <label><BiCategory /></label>
                                                        <select onChange={(e) => setObj({ ...obj, asset_category_id: e.target.value })} value={obj?.asset_category_id?.id}>
                                                            <option value="">Select Asset Category</option>
                                                            {filteredAssetCate?.map((items) => {
                                                                return (
                                                                    <option value={`${items.id}`}>{items.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='inputFields'>
                                                <label>Asset</label>
                                                <div className='inputBox'>
                                                    <label><TbCategory2 /></label>
                                                    <input placeholder='Assets Name' onChange={(e) => setObj({ ...obj, name: e.target.value })} value={obj?.name}></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='modalBtns d-flex justify-content-end gap-2'>
                                            <button className='btn btn-primary' onClick={() => {
                                                obj?.id ? handleUpdateSubmit() : handleSubmit()
                                            }}>{obj?.id ? 'Update' : 'Submit'}</button>
                                            <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>

                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table mx-auto w-100">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Assets</th>
                                    <th scope="col">Assets Category</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {params.id ? currentAssetsCate?.map((asset, idx) => {
                                    return (
                                        <>
                                            {asset?.company?.id == params.id && <tr key={asset.id}>
                                                <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                <td>{asset.company?.name}</td>
                                                <td>{asset.name}</td>
                                                <td>{asset.asset_category_id?.name}</td>
                                                <td>
                                                    <button
                                                        className='btn btn-primary p-1 m-2'
                                                        onClick={() => {
                                                            handleOpen()
                                                            setObj(asset)
                                                        }}
                                                    >
                                                        <CiEdit style={{ fontSize: "20px" }} />
                                                    </button>
                                                    <button className='btn btn-danger p-1 m-2' onClick={() => handleDeleteCategory(asset.id)}>
                                                        <CiTrash style={{ fontSize: "20px" }} />
                                                    </button>
                                                </td>
                                            </tr>}
                                        </>

                                    );
                                }) : currentAssetsCate?.map((asset, idx) => {
                                    return (
                                        <tr key={asset.id}>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{asset.company?.name}</td>
                                            <td>{asset.name}</td>
                                            <td>{asset.asset_category_id?.name}</td>
                                            <td>
                                                <button
                                                    className='btn btn-primary p-1 m-2'
                                                    onClick={() => {
                                                        handleOpen()
                                                        setObj(asset)
                                                    }}
                                                >
                                                    <CiEdit style={{ fontSize: "20px" }} />
                                                </button>
                                                <button className='btn btn-danger p-1 m-2' onClick={() => handleDeleteCategory(asset.id)}>
                                                    <CiTrash style={{ fontSize: "20px" }} />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="pagination-controls">{paginationControls}</div>
                    </div>
                </div>


            </div >
        </>
    )
}
export default Assets
