import React, { useEffect, useState } from "react";
import { useData } from "../ContextProvider/Context";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import editbrnch from "../Images/edtibranch.png";
import AdminUserForm from "../Components/AdminUserForm";
import { CiRead } from "react-icons/ci";
import { CiMenuKebab } from "react-icons/ci";
import emplyicon from "../Images/emplyicon.png";
import filter from "../Images/filtericon.png";
import cross from "../Images/filtercross.png";
import vector from "../Images/Vector.png";
import { Drawer } from "@mui/material";
import downarrow from "../Images/chevron-down.png";
import wbackimg from "../Images/wbackimg.png";
import wclogo from "../Images/wclogo.png";
import wcvector from "../Images/wcvector.png";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Button, Dropdown } from "react-bootstrap";
import { Snackbar, Alert } from "@mui/material";
import profilebg from "../Images/profilebg.png";
import profilelyr from "../Images/profilelyr.png";
import companylogo from "../Images/companylogo.png";
import proimg from "../Images/profileimg.png";
import formpen from "../Images/formpen.png";
import formcnt from "../Images/formcontact.png";
import formadrs from "../Images/formadrs.png";
import formgndr from "../Images/formgendr.png";
import formid from "../Images/formid.png";
import formmail from "../Images/formmail.png";
import formpswrd from "../Images/formpswrd.png";
import formdb from "../Images/work.png";
import formnat from "../Images/formnat.png";
import formbank from "../Images/formbank.png";
import formtitle from "../Images/formtitle.png";
import formacc from "../Images/formacc.png";
import formcmp from "../Images/formcmp.png";
import formbrnch from "../Images/branchv1.png";
import formdep from "../Images/depv1.png";
import formdes from "../Images/desigv1.png";
import formrole from "../Images/rollv1.png";
import formsal from "../Images/formsalary.png";
import formdate from "../Images/work.png";
import formlink from "../Images/formlink.png";
import formbld from "../Images/bloodgrp.png";
import formissue from "../Images/formissue.png";
import formmeal from "../Images/formmeal.png";
import formallnc from "../Images/formallnc.png";
import formtravel from "../Images/formtravel.png";

import {
  CiBank,
  CiCalendarDate,
  CiCircleMinus,
  CiFileOn,
  CiForkAndKnife,
  CiGlobe,
  CiHome,
  CiLink,
  CiMedicalCross,
} from "react-icons/ci";
import { VscSymbolField } from "react-icons/vsc";
import { FaGraduationCap, FaPhoneAlt } from "react-icons/fa";
import { LiaIndustrySolid } from "react-icons/lia";
import { SiCodesignal } from "react-icons/si";
import { GiSkills } from "react-icons/gi";
import { AiOutlineIdcard, AiOutlinePercentage } from "react-icons/ai";
import { BiMessageDetail } from "react-icons/bi";
import { LuImagePlus } from "react-icons/lu";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  boxShadow: 24,
};
function Employee() {
  const params = useParams();
  const {
    getEmployees,
    employees,
    handleClick,
    base_url,
    img_url,
    setSnackbarValue,
    emplyeeResponse,
    createEmploye,
    company,
    getCompnayCrud,
    getBranch,
    branch,
    department,
    getDepartments,
    getFilterEmployees,
    designationData,
    getdesignation,
    currentDate,
    departmentval,
    permission,
    employeeIndividual,
    getEmployeesIndividual,
    getBarnchById,
    getdepartmetById,
    getdseignationById,
    designarion,
  } = useData();
  useEffect(() => {
    getEmployees();
    getCompnayCrud();
    getBranch();
    getDepartments();
    getdesignation();
  }, []);
  const [addBrachCompany, setBrachCompany] = useState({
    companyId: params.id ? params.id : "",
    branch: "",
  });
  const [departmentData, setDepartments] = useState({
    companyId: params.id ? params.id : "",
    branchId: "",
    department: "",
  });
  const [designation, setdesignationData] = useState({
    companyId: params.id ? params.id : "",
    branchId: "",
    departmentId: "",
    designation: "",
  });
  const [employeeData, setEmployees] = useState({
    companyId: params.id ? params.id : "",
    branchId: "",
    name: "",
    phone: "",
    address: "",
    gender: "",
    maritalStatus: "",
    cnic: "",
    dob: "",
    email: "",
    departmentId: "",
    designationId: "",
    doj: new Date().toISOString().split("T")[0],
    password: "",
    salary: "",
    permanent_address: "",
    kin_address: "",
    blood_grp: "",
    emergency_contact: "",
    medical_history: "",
    next_to_kin_name: "",
    account_holder: "",
    bank_name: "",
    account_number: "",
    travel_allowance: "",
    IBAN: "",
    profile: "",
    meal_allowance: "",
    travel_condition: "",
    house_rent_allowance: "",
    nationality: "",
    cv: "",
    java_id: "",
    note: "",
    kin_name: "",
    kin_phone: "",
    next_to_kin_phone: "",
    next_to_kin_address: "",
    experience: [
      {
        company: "",
        post: "",
        period: "",
      },
    ],
    desc: "",
    link_data: [
      {
        title: "Linkedin",
        link: "",
      },
      {
        title: "Facebook",
        link: "",
      },
      {
        title: "Twitter",
        link: "",
      },
    ],
    education_data: [
      {
        field: "",
        education: "",
        document: "",
      },
    ],
    skills: [
      {
        name: "",
        percentage: "",
      },
    ],
    role_id: "",
  });

  const editEmplyee = () => {
    fetch(`${base_url}employeeupdate/${employeeData?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(employeeData),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
        getEmployeesIndividual(employeeData.id);
        getEmployees();
      });
  };
  const deleteEmployee = (id) => {
    fetch(`${base_url}employeeDelete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // navigate("/admin/employees")
      });
  };

  const handleClose01 = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dropdownStates, setDropdownStates] = useState({});
  const toggleList = (companyId) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [companyId]: !prevState[companyId],
    }));
  };


  const [date, setDate] = useState({
    company: "",
    branch: "",
    department: "",
    designation: "",
    startDate: "",
    endDate: "",
    status: "",
  });

  const handleCompanyChange = (e) => {
    const newCompany = e.target.value;
    setDate((prevDate) => ({ ...prevDate, company: newCompany }));
    if (newCompany === "") {
      filterResults(
        "",
        date.branch,
        date.department,
        date.designation,
        date.startDate,
        date.endDate
      );
    } else {
      filterResults(
        newCompany,
        date.branch,
        date.department,
        date.designation,
        date.startDate,
        date.endDate
      );
    }
  };

  const handelBranchChange = (e) => {
    const newBranch = e.target.value;
    setDate((prevDate) => ({ ...prevDate, branch: newBranch }));
    filterResults(
      date.company,
      newBranch,
      date.department,
      date.designation,
      date.startDate,
      date.endDate
    );
  };

  const handelDepartmentChange = (e) => {
    const newDepartment = e.target.value;
    setDate((prevDate) => ({ ...prevDate, department: newDepartment }));
    filterResults(
      date.company,
      date.branch,
      newDepartment,
      date.designation,
      date.startDate,
      date.endDate
    );
  };

  const handelDesignationChange = (e) => {
    const newDesignation = e.target.value;
    setDate((prevDate) => ({ ...prevDate, designation: newDesignation }));
    filterResults(
      date.company,
      date.branch,
      date.department,
      newDesignation,
      date.startDate,
      date.endDate
    );
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setDate((prevDate) => ({ ...prevDate, startDate: newStartDate }));
    filterResults(
      date.company,
      date.branch,
      date.department,
      date.designation,
      newStartDate,
      date.endDate
    );
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setDate((prevDate) => ({ ...prevDate, endDate: newEndDate }));
    filterResults(
      date.company,
      date.branch,
      date.department,
      date.designation,
      date.startDate,
      newEndDate
    );
  };
  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setDate((prevDate) => ({ ...prevDate, status: newStatus }));
    filterResults(
      date.company,
      date.branch,
      date.department,
      date.designation,
      date.startDate,
      date.endDate,
      newStatus
    );
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = params.id
    ? employees?.data?.filter((item) => item?.company?.id == params.id) || []
    : employees?.data || [];

  const totalPages = Math.ceil(currentItems?.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const [currentDate1, setCurrentDate1] = useState(new Date());

  const formattedDate = currentDate1.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }
  const filterResults = (
    company,
    branch,
    department,
    designation,
    startDate,
    endDate,
    status
  ) => {
    getFilterEmployees(
      company,
      branch,
      department,
      designation,
      startDate,
      endDate,
      status
    );
    handlePageChange(1);
  };
  // 7modals functions
  const [divColor, setDivColor] = React.useState("blue");
  const [openDrawer1, setOpenDrawer1] = React.useState(false);
  const [openDrawer2, setOpenDrawer2] = React.useState(false);
  const [openDrawer3, setOpenDrawer3] = React.useState(false);
  const [openDrawer4, setOpenDrawer4] = React.useState(false);
  const [openDrawer5, setOpenDrawer5] = React.useState(false);
  const [openDrawer6, setOpenDrawer6] = React.useState(false);
  const [openDrawer7, setOpenDrawer7] = React.useState(false);
  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const [totalTime, setTotalTime] = React.useState("");
  const backgroundStyle = {
    backgroundImage: 'url("../Images/wbackimg.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100vh", // Adjust the height as needed
  };

  const handleDrawerOpen1 = () => {
    setOpenDrawer1(true);
    setOpenDrawer2(false);
  };
  const getJavaId = () => {
    fetch(`${base_url}next-java-id`)
      .then((res) => res.json())
      .then((data) => {
        setEmployees({ ...employeeData, java_id: data?.java_id });
        handleDrawerOpen1();
      });
  };
  const setcolor = () => {
    setDivColor("blue");
  };
  const handleclearfields = () => {
    setEmployees({
      companyId: params.id ? params.id : "",
      branchId: "",
      name: "",
      phone: "",
      address: "",
      gender: "",
      maritalStatus: "",
      cnic: "",
      dob: "",
      email: "",
      departmentId: "",
      designationId: "",
      doj: new Date().toISOString().split("T")[0],
      password: "",
      salary: "",
      permanent_address: "",
      kin_address: "",
      blood_grp: "",
      emergency_contact: "",
      medical_history: "",
      next_to_kin_name: "",
      account_holder: "",
      bank_name: "",
      account_number: "",
      travel_allowance: "",
      IBAN: "",
      profile: "",
      meal_allowance: "",
      travel_condition: "",
      house_rent_allowance: "",
      nationality: "",
      cv: "",
      java_id: "",
      note: "",
      kin_name: "",
      kin_phone: "",
      next_to_kin_phone: "",
      next_to_kin_address: "",
      experience: [
        {
          company: "",
          post: "",
          period: "",
        },
      ],
      desc: "",
      link_data: [
        {
          title: "Linkedin",
          link: "",
        },
        {
          title: "Facebook",
          link: "",
        },
        {
          title: "Twitter",
          link: "",
        },
      ],
      education_data: [
        {
          field: "",
          education: "",
          document: "",
        },
      ],
      skills: [
        {
          name: "",
          percentage: "",
        },
      ],
      role_id: "",
    });
  };

  const handleDrawerClose1 = () => {
    setOpenDrawer1(false);
    handleclearfields();
  };
  const handleDrawerOpen2 = () => {
    setOpenDrawer2(true);
    setOpenDrawer1(false);
    setOpenDrawer3(false);
  };

  const handleDrawerClose2 = () => {
    setOpenDrawer2(false);
    handleclearfields();
  };

  const handleDrawerOpen3 = () => {
    setOpenDrawer3(true);
    setOpenDrawer2(false);
    setOpenDrawer4(false);
  };

  const handleDrawerClose3 = () => {
    setOpenDrawer3(false);
    handleclearfields();
  };
  const handleDrawerClose4 = () => {
    setOpenDrawer4(false);
    handleclearfields();
  };
  const handleDrawerOpen4 = () => {
    setOpenDrawer4(true);
    setOpenDrawer3(false);
    setOpenDrawer5(false);
  };
  const handleDrawerClose5 = () => {
    setOpenDrawer5(false);
    handleclearfields();
  };
  const handleDrawerOpen5 = () => {
    setOpenDrawer5(true);
    setOpenDrawer4(false);
    setOpenDrawer6(false);
  };
  const handleDrawerClose6 = () => {
    setOpenDrawer6(false);
    handleclearfields();
  };
  const handleDrawerOpen6 = () => {
    setOpenDrawer6(true);
    setOpenDrawer5(false);
    setOpenDrawer7(false);
  };

  const handleDrawerClose7 = () => {
    setOpenDrawer7(false);
    handleclearfields();
  };
  const handleDrawerOpen7 = () => {
    setOpenDrawer7(true);
    setOpenDrawer6(false);
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const handleAllCloseTabs = () => {
    handleDrawerClose1();
    handleDrawerClose2();
    handleDrawerClose3();
    handleDrawerClose4();
    handleDrawerClose5();
    handleDrawerClose6();
    handleDrawerClose7();
    handleclearfields()
    setSelectedImage('')
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        setSelectedImage(URL.createObjectURL(file));
        setEmployees({ ...employeeData, profile: base64String });
      };
      reader.readAsDataURL(file);
    }
  };
  const handlefileChange = (file, i) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        const data = [...employeeData.education_data];
        data[i].document = base64String;
        setEmployees({ ...employeeData, education_data: data });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCv = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        setEmployees({ ...employeeData, cv: base64String });
      };
      reader.readAsDataURL(file);
    }
  };
  const addexperience = () => {
    var obj = {
      company: "",
      post: "",
      period: "",
    };
    setEmployees({
      ...employeeData,
      experience: [...employeeData.experience, obj],
    });
  };
  const remove = (idx) => {
    var array = employeeData?.experience?.filter((item, i) => {
      if (idx !== i) {
        return item;
      }
    });
    setEmployees({ ...employeeData, experience: array });
  };
  const addlink = () => {
    var obj = {
      title: "",
      link: "",
    };
    setEmployees({
      ...employeeData,
      link_data: [...employeeData.link_data, obj],
    });
  };
  const removelink = (idx) => {
    var array = employeeData?.link_data?.filter((item, i) => {
      if (idx !== i) {
        return item;
      }
    });
    setEmployees({ ...employeeData, link_data: array });
  };
  const addeducation = () => {
    var obj = {
      field: "",
      education: "",
      document: "",
    };
    setEmployees({
      ...employeeData,
      education_data: [...employeeData.education_data, obj],
    });
  };
  const removeeducation = (idx) => {
    var array = employeeData?.education_data?.filter((item, i) => {
      if (idx !== i) {
        return item;
      }
    });
    setEmployees({ ...employeeData, education_data: array });
  };
  const addskill = () => {
    var obj = {
      name: "",
      percentage: "",
    };
    setEmployees({ ...employeeData, skills: [...employeeData.skills, obj] });
  };
  const removeskill = (idx) => {
    var array = employeeData?.skills?.filter((item, i) => {
      if (idx !== i) {
        return item;
      }
    });
    setEmployees({ ...employeeData, skills: array });
  };
  const [allRoles, setallRoles] = useState([]);
  const getRoles = () => {
    fetch(`${base_url}roles`)
      .then((response) => response.json())
      .then((data) => {
        setallRoles(data);
      });
  };
  useEffect(() => {
    getRoles()
  }, [])
  const state = {
    open: false,
    vertical: "bottom",
    horizontal: "right",
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const { vertical, horizontal } = state;
  const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
  };

  // Form Validation

  const handleSubmit = () => {
    if (employeeData.profile.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Profile is required.");
    } else if (employeeData.name.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Name field is required.");
    } else if (employeeData.email.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Email field is required.");
    } else if (!emailRegex.test(employeeData.email.trim())) {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Please enter a valid email address.");
    } else if (employeeData.phone.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Phone field is required.");
    } else if (employeeData.address.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Temporary Address field is required.");
    } else if (employeeData.password.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Password field is required.");
    } else if (employeeData.password.trim().length < 8) {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Password should be at least 8 characters.");
    } else if (employeeData.permanent_address.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Permanent Address field is required.");
    } else if (employeeData.dob.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Date of Birth field is required.");
    } else if (employeeData.gender.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Gender field is required.");
    } else if (employeeData.cnic.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("CNIC ID field is required.");
    } else if (employeeData.nationality.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Nationality field is required.");
    } else if (employeeData.maritalStatus.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Marital Status field is required.");
    } else if (employeeData.account_holder.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Account Holder field is required.");
    } else if (employeeData.account_number.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Account Number field is required.");
    } else if (employeeData.IBAN.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("IBAN field is required.");
    } else if (employeeData.bank_name.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Bank Name field is required.");
    } else if (employeeData.companyId.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Company field is required.");
    } else if (employeeData.branchId.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Branch field is required.");
    } else if (employeeData.departmentId.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Department field is required.");
    } else if (employeeData.designationId.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Designation field is required.");
    }
    else if (employeeData.role_id.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Role field is required.");
    }
    else if (employeeData.salary.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Salary field is required.");
    } else if (employeeData.doj.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Date of Joining field is required.");
    } else if (
      employeeData.education_data.some(
        (education) =>
          education.field.trim() === "" ||
          education.education.trim() === "" ||
          education.document.trim() === ""
      )
    ) {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Education fields are required.");
    } else if (
      employeeData.skills.some(
        (skill) => skill.name.trim() === "" || skill.percentage.trim() === ""
      )
    ) {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Skills fields are required.");
    } else {
      setAnyFieldEmpty(false);
      createEmploye(employeeData);
      setSnackbarValue({
        message: "Employee Created successfully",
        color: "#2ECC71",
      });
      handleDrawerClose1();
      handleDrawerClose2();
      handleDrawerClose3();
      handleDrawerClose4();
      handleDrawerClose5();
      handleDrawerClose6();
      handleDrawerClose7();
    }
  };
  const handleUpdate = () => {
    if (employeeData.name.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Name field is required.");
    } else if (employeeData.email.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Email field is required.");
    } else if (!emailRegex.test(employeeData.email.trim())) {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Please enter a valid email address.");
    } else if (employeeData.phone.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Phone field is required.");
    } else if (employeeData.address.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Temporary Address field is required.");
    } else if (employeeData.password.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Password field is required.");
    } else if (employeeData.password.trim().length < 8) {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Password should be at least 8 characters.");
    } else if (employeeData.permanent_address.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Permanent Address field is required.");
    } else if (employeeData.dob.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Date of Birth field is required.");
    } else if (employeeData.gender.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Gender field is required.");
    } else if (employeeData.cnic.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("CNIC ID field is required.");
    } else if (employeeData.nationality.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Nationality field is required.");
    } else if (employeeData.maritalStatus.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Marital Status field is required.");
    } else if (employeeData.account_holder.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Account Holder field is required.");
    } else if (employeeData.account_number.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Account Number field is required.");
    } else if (employeeData.IBAN.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("IBAN field is required.");
    } else if (employeeData.bank_name.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Bank Name field is required.");
    } else if (employeeData.salary.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Salary field is required.");
    } else if (employeeData.doj.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Date of Joining field is required.");
    } else if (
      employeeData.education_data.some(
        (education) =>
          education.field.trim() === "" ||
          education.education.trim() === "" ||
          education.document.trim() === ""
      )
    ) {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Education fields are required.");
    } else if (
      employeeData.skills.some(
        (skill) => skill.name.trim() === "" || skill.percentage.trim() === ""
      )
    ) {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Skills fields are required.");
    } else {
      setAnyFieldEmpty(false);
      editEmplyee(employeeData);
      setSnackbarValue({
        message: "Employee Created successfully",
        color: "#2ECC71",
      });
      handleDrawerClose1();
      handleDrawerClose2();
      handleDrawerClose3();
      handleDrawerClose4();
      handleDrawerClose5();
      handleDrawerClose6();
      handleDrawerClose7();
    }
  };
  return (
    <>
      <div className="employeeData">
        {/* filter sidebar content */}
        <Drawer anchor="right" open={openDrawer} onClose={handleDrawerClose}>
          <div
            className="filterparent"
            style={{
              background: "white",
              padding: "18px",
              zIndex: "99",
              display: "felx",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div
              className="fpchild1"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "22px", fontSize: "bold" }}>Filter</span>
              <button
                onClick={handleDrawerClose}
                style={{ background: "none", border: "none" }}
              >
                <img alt="" src={cross} />
              </button>
            </div>
            <div className="fpchild2">
              <div
                className="form-group"
                style={{
                  marginBottom: "20px",
                  textAlign: "left",
                  position: "relative",
                }}
              >
                <label style={{ marginBottom: "5px" }} htmlFor="company">
                  Company
                </label>
                <img
                  alt=""
                  style={{ position: "absolute", right: "16px", top: "40px" }}
                  src={downarrow}
                />
                <select
                  id="company"
                  style={{
                    width: "100%",
                    // borderRadius: "10px",
                    padding: "7px",
                  }}
                  className="form-control"
                  disabled={params.id ? true : false}
                  value={date.company}
                  onChange={handleCompanyChange}
                >
                  <option hidden> Company</option>
                  <option value={""}>All Company</option>
                  {company?.data?.map((item) => {
                    return (
                      <>
                        {item?.deleteStatus == 0 && (
                          <option value={item.name} key={item.id}>
                            {item.name}
                          </option>
                        )}
                      </>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="fpchild2">
              <div
                className="form-group"
                style={{
                  marginBottom: "20px",
                  textAlign: "left",
                  position: "relative",
                }}
              >
                <label style={{ marginBottom: "5px" }} htmlFor="company">
                  Branch
                </label>
                <img
                  alt=""
                  style={{ position: "absolute", right: "16px", top: "40px" }}
                  src={downarrow}
                />
                <select
                  id="designation"
                  style={{
                    width: "100%",
                    // borderRadius: "10px",
                    padding: "7px",
                  }}
                  className="form-control"
                  value={date.designation}
                  onChange={handelDesignationChange}
                >
                  <option hidden> Branch</option>
                  <option value={""}>All Branch</option>
                  {designationData?.map((item) => {
                    return (
                      <>
                        {item?.deleteStatus == 0 && (
                          <option value={item.title} key={item.id}>
                            {item.title}
                          </option>
                        )}
                      </>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="fpchild2">
              <div
                className="form-group"
                style={{
                  marginBottom: "20px",
                  textAlign: "left",
                  position: "relative",
                }}
              >
                <label style={{ marginBottom: "5px" }} htmlFor="company">
                  Department
                </label>
                <img
                  alt=""
                  style={{ position: "absolute", right: "16px", top: "40px" }}
                  src={downarrow}
                />
                <select
                  id="branch"
                  style={{
                    width: "100%",
                    // borderRadius: "10px",
                    padding: "7px",
                  }}
                  className="form-control"
                  value={date.branch}
                  onChange={handelBranchChange}
                >
                  <option hidden> Department</option>
                  <option value={""}>All Department</option>
                  {branch?.data?.map((item) => {
                    return (
                      <>
                        {item?.deleteStatus == 0 && (
                          <option value={item.branch} key={item.id}>
                            {item.branch}
                          </option>
                        )}
                      </>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="fpchild2">
              <div
                className="form-group"
                style={{
                  marginBottom: "20px",
                  textAlign: "left",
                  position: "relative",
                }}
              >
                <label style={{ marginBottom: "5px" }} htmlFor="company">
                  Designation
                </label>
                <img
                  alt=""
                  style={{ position: "absolute", right: "16px", top: "40px" }}
                  src={downarrow}
                />
                <select
                  id="department"
                  style={{
                    width: "100%",
                    // borderRadius: "10px",
                    padding: "7px",
                  }}
                  className="form-control"
                  value={date.department}
                  onChange={handelDepartmentChange}
                >
                  <option hidden> Designation</option>
                  <option value={""}>All Designation</option>
                  {department?.data?.map((item) => {
                    return (
                      <>
                        {item?.deleteStatus == 0 && (
                          <option value={item.department} key={item.id}>
                            {item.department}
                          </option>
                        )}
                      </>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </Drawer>

        <div
          className="empheader"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <nav>
              <ul style={{ paddingLeft: "3px" }}>
                <li
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Employees
                </li>
                <li>{formattedDate}</li>
              </ul>
            </nav>
          </div>
          <div className="d-flex justify-content-end mb-2 gap-2">
            <div>
              <button
                className="btn btn-primary"
                onClick={getJavaId}
                style={{
                  padding: "8px 16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img alt="" src={emplyicon} />
                <span>Add Employee</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div>
          <div className="card">
            <div className="card-body recruitment table-responsive">
              <div
                className="headtable"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "100px",
                  }}
                >
                  <h5>All Employees</h5>
                </div>
                <div>
                  <button
                    onClick={handleDrawerOpen}
                    style={{
                      background: "transparent",
                      borderRadius: "10px",
                      border: "1px solid darkgray",
                      padding: "2px 5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Filter
                    <img
                      alt=""
                      style={{ width: "20%", marginLeft: "2px" }}
                      src={filter}
                    />
                  </button>
                </div>
              </div>
              <table className="table compnaytable paddingFortable">
                <thead>
                  <tr style={{ background: "#F5F6FA" }}>
                    <th scope="col">Emp ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Company</th>
                    <th scope="col">Department</th>
                    <th scope="col">Designation</th>
                    <th scope="col">Branch</th>
                    <th scope="col">Salary</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {params.id
                    ? currentItems
                      .slice(indexOfFirstItem, indexOfLastItem)
                      ?.map((item, i) => {
                        return (
                          <>
                            {item?.company?.id == params.id && (
                              <tr>
                                <th scope="row">{item.java_id}</th>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "50%",
                                      }}
                                    >
                                      <img
                                        alt=""
                                        style={{ borderRadius: "50%" }}
                                        src={
                                          item.profile !== null
                                            ? img_url + item.profile
                                            : "https://newvintagewi.org/wp-content/uploads/2021/07/final-resized-male-image.jpg"
                                        }
                                        width={"100%"}
                                        height={"100%"}
                                      />
                                    </div>

                                    <div>
                                      <Link to={`/admin/employees/profile/${item.id}`}>
                                        {item.name}
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                                <td>{item.email}</td>
                                <td>{item?.company.name}</td>
                                <td>{item.department?.department}</td>
                                <td>{item.designation?.title}</td>
                                <td>{item.branch?.branch}</td>
                                <td>{item.salary?.toLocaleString()}</td>
                              </tr>
                            )}
                          </>
                        );
                      })
                    : currentItems
                      .slice(indexOfFirstItem, indexOfLastItem)
                      ?.map((item, i) => {
                        return (
                          <tr>
                            <th scope="row">{item.java_id}</th>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "left",
                                  gap: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <img
                                    alt=""
                                    style={{ borderRadius: "50%" }}
                                    src={img_url + item.profile}
                                    width={"100%"}
                                    height={"100%"}
                                  />
                                </div>
                                <Link to={`/admin/employees/profile/${item.id}`}>
                                  <div>{item.name}</div>
                                </Link>
                              </div>
                            </td>
                            <td>{item.email}</td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "left",
                                  gap: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <img
                                    alt=""
                                    style={{ borderRadius: "50%" }}
                                    src={
                                      item.profile !== null
                                        ? img_url + item?.company?.logo
                                        : "https://newvintagewi.org/wp-content/uploads/2021/07/final-resized-male-image.jpg"
                                    }
                                    width={"100%"}
                                    height={"100%"}
                                  />
                                </div>
                                <div>{item?.company.name}</div>
                              </div>
                            </td>
                            <td>{item.branch?.branch}</td>
                            <td>{item.department?.department}</td>
                            <td>{item.designation?.title}</td>

                            <td>{item.salary?.toLocaleString()}</td>

                            <td style={{ position: "relative" }}>
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="mydrop"
                                  variant="success"
                                  id="dropdown-basic"
                                  style={{
                                    background: "unset",
                                    border: "0",
                                    padding: "0",
                                    boxShadow: "unset",
                                  }}
                                >
                                  <CiMenuKebab
                                    style={{
                                      fontSize: "24px",
                                      color: "black",
                                    }}
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setEmployees(item);
                                      handleDrawerOpen1();
                                    }}
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      getEmployeesIndividual(item?.id);
                                      deleteEmployee(item?.id);
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      getEmployeesIndividual(item?.id);
                                      handleShow();
                                    }}
                                  >
                                    View Profile
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link to={`/admin/employee/${item.id}`}>
                                      <span style={{ color: "black" }}>
                                        {" "}
                                        Screenshots
                                      </span>
                                    </Link>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
              <div className="pagination-controls">{paginationControls}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Addemployee 7modals set */}
      <Drawer
        className="mycmpny-drawer"
        anchor="right"
        open={openDrawer1}
        onClose={handleDrawerClose1}
      >
        {/* Drawer 1 content */}

        <div
          className="wizradparent1"
          style={{ padding: "22px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                    background: "blue",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9 scrollBar "
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
                height: "500px",
                overflowY: "scroll",
                padding: "0px",
              }}
            >
              <div
                className="headerimg"
                style={{
                  backgroundImage: `url(${wbackimg})`,
                  height: "146px",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img
                  alt=""
                  style={{
                    position: "absolute",
                    top: "94%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  src={wcvector}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    padding: "14px",
                    borderRadius: "50%",
                    background: "darkgray",
                  }}
                >
                  <div style={{ border: "none" }} className="row">
                    <div className="col-12">
                      <div
                        style={{
                          border: "2px solid",
                          height: "70px",
                          borderRadius: "50%",
                          width: "70px",
                          position: "relative",
                        }}
                      >
                        <label
                          style={{
                            border: "1px solid",
                            borderRadius: "50%",
                            width: "25px",
                            height: "25px",
                            background: "lightgray",
                            color: "gray",
                            position: "absolute",
                            right: "0%",
                            paddingLeft: ".2rem",
                            paddingBottom: ".2rem",
                          }}
                          htmlFor="EmployeeLogo"
                        >
                          <LuImagePlus />
                        </label>
                        <img
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                          src={
                            selectedImage
                              ? selectedImage
                              : img_url + employeeData?.profile
                          }
                        />
                      </div>
                      <input
                        type="file"
                        id="EmployeeLogo"
                        name="EmployeeLogo"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="inputsection" style={{ marginTop: "60px" }}>
                <Form>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label> Name</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "45px",
                          left: "17px",
                        }}
                        src={formpen}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="text"
                        id="emp1"
                        placeholder="Name"
                        value={employeeData?.name}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            name: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label> E-mail</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "50px",
                          left: "52%",
                        }}
                        src={formmail}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "28px",
                        }}
                        type="email"
                        id="emp2"
                        placeholder="Email"
                        value={employeeData?.email}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            email: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label> Contact Number</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "45px",
                          left: "17px",
                        }}
                        src={formcnt}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="number"
                        id="emp3"
                        placeholder="+92"
                        value={employeeData?.phone}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            phone: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Password</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "46px",
                          left: "52%",
                        }}
                        src={formpswrd}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "28px",
                        }}
                        type="text"
                        id="emp4"
                        placeholder="Password"
                        value={employeeData?.password}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            password: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label>Temporary Address</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "45px",
                          left: "17px",
                        }}
                        src={formadrs}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="text"
                        id="emp5"
                        placeholder="Temporary Address"
                        value={employeeData?.address}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            address: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Permanent Address</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "44px",
                          left: "52%",
                        }}
                        src={formadrs}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "28px",
                        }}
                        type="text"
                        id="emp6"
                        placeholder="Permanent Address"
                        value={employeeData?.permanent_address}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            permanent_address: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Gender</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "45px",
                          left: "17px",
                        }}
                        src={formgndr}
                      />
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        id="emp7"
                        value={employeeData?.gender}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            gender: e.target.value,
                          })
                        }
                      >
                        <option hidden>Gender</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Date of Birth</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "47px",
                          left: "52%",
                        }}
                        src={formdb}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "28px",
                        }}
                        type="date"
                        id="emp8"
                        placeholder="DD-MM-YYYY"
                        value={employeeData?.dob}
                        onChange={(e) =>
                          setEmployees({ ...employeeData, dob: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label>National ID</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "50px",
                          left: "17px",
                        }}
                        src={formid}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "35px",
                        }}
                        type="number"
                        id="emp9"
                        placeholder="xxxxx-xxxxxx-x"
                        value={employeeData?.cnic}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            cnic: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Nationality</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "48px",
                          left: "53%",
                        }}
                        src={formnat}
                      />
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "28px",
                        }}
                        id="emp10"
                        value={employeeData?.nationality}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            nationality: e.target.value,
                          })
                        }
                      >
                        <option hidden>Nationality</option>
                        <option>Pakistani</option>
                        <option>Overseas</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Martial Status</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "50px",
                          left: "17px",
                        }}
                        src={formid}
                      />
                      <Form.Select
                        style={{
                          width: "49%",
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "30px",
                        }}
                        id="emp11"
                        value={employeeData?.maritalStatus}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            maritalStatus: e.target.value,
                          })
                        }
                      >
                        <option hidden>Martial Status</option>
                        <option>Single</option>
                        <option>Married</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleAllCloseTabs}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  employeeData?.id
                    ? editEmplyee(employeeData)
                    : handleSubmit(employeeData);
                }}
                variant="danger"
              >
                {" "}
                {employeeData?.id ? "Update" : "Save&Exit"}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleDrawerOpen2();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        className="mycmpny-drawer"
        anchor="right"
        open={openDrawer2}
        onClose={handleDrawerClose2}
      >
        {/* Drawer 2 content */}

        <div
          className="wizradparent1"
          style={{ padding: "22px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                    background: divColor,

                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9 scrollBar "
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
                height: "500px",
                overflowY: "scroll",
              }}
            >
              <div className="inputsection" style={{ marginTop: "20px" }}>
                <h3>Bank Account Information</h3>

                <Form>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Bank Name</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        src={formbank}
                        style={{
                          position: "absolute",
                          top: "45px",
                          left: "18px",
                        }}
                      />
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        id="emp12"
                        value={employeeData?.bank_name}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            bank_name: e.target.value,
                          })
                        }
                      >
                        <option hidden>Bank</option>
                        <option>HBL</option>
                        <option>Allied</option>
                        <option>Alflah</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Account Tittle</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        src={formtitle}
                        style={{
                          position: "absolute",
                          top: "48px",
                          left: "52%",
                        }}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        id="emp13"
                        type="text"
                        placeholder="Title"
                        value={employeeData?.account_holder}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            account_holder: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label>Account Number</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        src={formacc}
                        style={{
                          position: "absolute",
                          top: "48px",
                          left: "18px",
                        }}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        id="emp14"
                        type="number"
                        placeholder="Account Number"
                        value={employeeData?.account_number}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            account_number: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>IBAN</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        src={formacc}
                        style={{
                          position: "absolute",
                          top: "47px",
                          left: "52%",
                        }}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        id="emp15"
                        type="text"
                        placeholder="IBAN Number"
                        value={employeeData?.IBAN}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            IBAN: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleAllCloseTabs}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen1}
              >
                Back
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  employeeData?.id
                    ? handleUpdate(employeeData)
                    : handleSubmit(employeeData);
                }}
                variant="danger"
              >
                {" "}
                {employeeData?.id ? "Update" : "Save&Exit"}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleDrawerOpen3();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        className="mycmpny-drawer"
        anchor="right"
        open={openDrawer3}
        onClose={handleDrawerClose3}
      >
        {/* Drawer 3 content */}

        <div
          className="wizradparent1"
          style={{ padding: "22px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                    background: divColor,
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9 scrollBar"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
                height: "500px",
                overflowY: "scroll",
              }}
            >
              <div className="inputsection" style={{ marginTop: "20px" }}>
                <h3>Designation Information</h3>
                <Form>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Company</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        src={formcmp}
                        style={{
                          position: "absolute",
                          top: "47px",
                          left: "18px",
                        }}
                      />
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        value={employeeData?.companyId}
                        id="emp16"
                        onChange={(e) => {
                          getBarnchById(e.target.value);
                          setEmployees({
                            ...employeeData,
                            companyId: e.target.value,
                          });
                        }}
                      >
                        <option hidden>Company</option>
                        {company?.data?.map((item) => {
                          return (
                            <>
                              {item?.deleteStatus == 0 && (
                                <option value={item.id}>{item.name}</option>
                              )}
                            </>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Branch</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        src={formbrnch}
                        style={{
                          position: "absolute",
                          top: "47px",
                          left: "52%",
                        }}
                      />
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        value={employeeData?.branchId}
                        id="emp17"
                        onChange={(e) => {
                          getdepartmetById(e.target.value);
                          setEmployees({
                            ...employeeData,
                            branchId: e.target.value,
                          });
                        }}
                      >
                        <option hidden>Branch</option>
                        {branch?.data?.map((item) => {
                          return <option value={item.id}>{item.branch}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Department</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        src={formdep}
                        style={{
                          position: "absolute",
                          top: "47px",
                          left: "18px",
                        }}
                      />
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "28px",
                        }}
                        id="emp18"
                        value={employeeData?.departmentId}
                        onChange={(e) => {
                          getdseignationById(e.target.value);
                          setEmployees({
                            ...employeeData,
                            departmentId: e.target.value,
                          });
                        }}
                      >
                        <option hidden>Department</option>
                        {departmentval?.map((item) => {
                          return (
                            <option value={item.id}>{item.department}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Designation</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        src={formdes}
                        style={{
                          position: "absolute",
                          top: "47px",
                          left: "52%",
                        }}
                      />
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        value={employeeData?.designationId}
                        id="emp19"
                        onChange={(e) => {
                          setEmployees({
                            ...employeeData,
                            designationId: e.target.value,
                          });
                        }}
                      >
                        <option hidden>Designation</option>
                        {designarion?.map((item) => {
                          return <option value={item.id}>{item.title}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Role</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        src={formrole}
                        style={{
                          position: "absolute",
                          top: "50px",
                          left: "18px",
                        }}
                      />
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        id="emp01"
                        value={employeeData?.role_id}
                        onChange={(e) => {
                          setEmployees({
                            ...employeeData,
                            role_id: e.target.value,
                          });
                        }}
                      >
                        <option hidden>Role</option>
                        {allRoles?.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Salary</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        src={formsal}
                        style={{
                          position: "absolute",
                          top: "47px",
                          left: "52%",
                        }}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "28px",
                        }}
                        type="number"
                        id="emp02"
                        placeholder="Salary"
                        value={employeeData?.salary}
                        onChange={(e) => {
                          setEmployees({
                            ...employeeData,
                            salary: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Row>

                  <Row style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label>Date of joining</Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <img
                        alt=""
                        src={formsal}
                        style={{
                          position: "absolute",
                          top: "47px",
                          left: "18px",
                        }}
                      />
                      <Form.Control
                        style={{
                          width: "49%",
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "28px",
                        }}
                        type="date"
                        id="emp03"
                        placeholder="DD-MM-YYYY"
                        value={employeeData?.doj}
                        onChange={(e) =>
                          setEmployees({ ...employeeData, doj: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleAllCloseTabs}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen2}
              >
                Back
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  employeeData?.id
                    ? editEmplyee(employeeData)
                    : handleSubmit(employeeData);
                }}
                variant="danger"
              >
                {" "}
                {employeeData?.id ? "Update" : "Save&Exit"}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleDrawerOpen4();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        className="mycmpny-drawer"
        anchor="right"
        open={openDrawer4}
        onClose={handleDrawerClose4}
      >
        {/* Drawer 4 content */}

        <div
          className="wizradparent1"
          style={{ padding: "22px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                    background: divColor,
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9 scrollBar"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
                height: "500px",
                overflowY: "scroll",
              }}
            >
              <div className="inputsection" style={{ marginTop: "20px" }}>
                <h5>Education</h5>
                {employeeData?.education_data?.map((item, i) => {
                  return (
                    <>
                      {i > 0 && (
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <button
                            className="btn btn-primary"
                            style={{
                              padding: "8px 16px",
                              marginRight: "1rem",
                              marginTop: "10px",
                            }}
                            onClick={() => removeeducation(i)}
                          >
                            <CiCircleMinus />
                          </button>
                        </div>
                      )}

                      <div className="row" style={{ position: "relative" }}>
                        <div
                          className="col-6"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label htmlFor="EmployeeName21">
                            Education: <span style={{ color: "red" }}>*</span>
                          </label>

                          <img
                            alt=""
                            src={formdes}
                            style={{
                              position: "absolute",
                              top: "34px",
                              left: "16px",
                            }}
                          />
                          <input
                            style={{
                              padding: "5px",
                              borderRadius: "8px",
                              border: "1px solid lightgray",
                              paddingLeft: "28px",
                            }}
                            type="text"
                            id="EmployeeName21"
                            name="EmployeeName21"
                            placeholder="Education"
                            value={item?.education}
                            onChange={(e) => {
                              const data = [...employeeData.education_data];
                              data[i].education = e.target.value;
                              setEmployees({
                                ...employeeData,
                                education_data: data,
                              });
                            }}
                          />
                        </div>
                        <div
                          className="col-6"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label>
                            Field <span style={{ color: "red" }}>*</span>
                          </label>

                          <img
                            alt=""
                            src={formdes}
                            style={{
                              position: "absolute",
                              top: "34px",
                              left: "52%",
                            }}
                          />
                          <input
                            style={{
                              padding: "5px",
                              borderRadius: "8px",
                              border: "1px solid lightgray",
                              paddingLeft: "28px",
                            }}
                            type="text"
                            id="EmployeeName12"
                            name="EmployeeName12"
                            placeholder="Field"
                            value={item?.field}
                            onChange={(e) => {
                              const data = [...employeeData.education_data];
                              data[i].field = e.target.value;
                              setEmployees({
                                ...employeeData,
                                education_data: data,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "10px" }}>
                        <div
                          className="col-12"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2px",
                          }}
                        >
                          <label htmlFor="education">
                            Educational Documents{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <input
                            style={{
                              padding: "5px",
                              borderRadius: "8px",
                              border: "1px solid lightgray",
                            }}
                            type="file"
                            id="education"

                            name="EmployeeName"
                            placeholder="Education"
                            onChange={(e) =>
                              handlefileChange(e.target.files[0], i)
                            }
                          />
                        </div>
                      </div>
                    </>
                  );
                })}

                <div
                  className="row"
                  style={{ justifyContent: "right", marginTop: "10px" }}
                >
                  <Button
                    style={{ width: "15%" }}
                    type="submit"
                    variant="primary"
                    onClick={addeducation}
                  >
                    Add More
                  </Button>
                </div>
              </div>
              <div className="inputsection" style={{ marginTop: "20px" }}>
                <h5>Certificate & Diploma</h5>

                <div className="step-content row">
                  {employeeData?.experience?.map((item, i) => {
                    return (
                      <>
                        {i > 0 && (
                          <div
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <button
                              className="btn btn-primary"
                              style={{
                                padding: "8px 16px",
                                marginRight: "1rem",
                                marginTop: "10px",
                              }}
                              onClick={() => remove(i)}
                            >
                              <CiCircleMinus />
                            </button>
                          </div>
                        )}
                        <div className="col-4">
                          <label
                            style={{
                              fontSize: "14px",
                              margin: "15px 0 0 15px",
                            }}
                          >
                            Company Name
                          </label>

                          <div className="row" style={{ borderRadius: "10px" }}>
                            <div className="col-12">
                              <label>
                                <LiaIndustrySolid />
                              </label>
                              <input
                                type="text"
                                id="EmployeeName13"
                                name="EmployeeName13"
                                placeholder="Company Name"
                                value={item?.company}
                                onChange={(e) => {
                                  const data = [...employeeData.experience];
                                  data[i].company = e.target.value;
                                  setEmployees({
                                    ...employeeData,
                                    experience: data,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <label
                            style={{
                              fontSize: "14px",
                              margin: "15px 0 0 15px",
                            }}
                          >
                            Designation
                          </label>

                          <div className="row" style={{ borderRadius: "10px" }}>
                            <div className="col-12">
                              <label>
                                <SiCodesignal />
                              </label>
                              <input
                                type="text"
                                id="EmployeeName31"
                                name="EmployeeName31"
                                placeholder="Designation"
                                value={item?.post}
                                onChange={(e) => {
                                  const data = [...employeeData.experience];
                                  data[i].post = e.target.value;
                                  setEmployees({
                                    ...employeeData,
                                    experience: data,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <label
                            style={{
                              fontSize: "14px",
                              margin: "15px 0 0 15px",
                            }}
                          >
                            Time Period
                          </label>

                          <div className="row" style={{ borderRadius: "10px" }}>
                            <div className="col-12">
                              <label>
                                <CiCalendarDate />
                              </label>
                              <input
                                type="number"
                                id="EmployeeName14"
                                name="EmployeeName14"
                                placeholder="Period"
                                value={item?.period}
                                onChange={(e) => {
                                  const data = [...employeeData.experience];
                                  data[i].period = e.target.value;
                                  setEmployees({
                                    ...employeeData,
                                    experience: data,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <button
                            className="btn btn-primary mt-3"
                            style={{
                              padding: "8px 16px",
                              marginRight: "1rem",
                              marginTop: "10px",
                            }}
                            onClick={addexperience}
                          >
                            Add More
                          </button>
                        </div>
                      </>
                    );
                  })}
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <button
                      className="btn btn-primary mt-3"
                      style={{
                        padding: "8px 16px",
                        marginRight: "1rem",
                        marginTop: "10px",
                      }}
                      onClick={addskill}
                    >
                      Add Skills
                    </button>
                  </div>
                  {employeeData?.skills?.map((item, i) => {
                    return (
                      <>
                        <div
                          className="mt-2"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <button
                            className="btn btn-primary"
                            style={{
                              padding: "8px 16px",
                              marginRight: "1rem",
                              marginTop: "10px",
                            }}
                            onClick={() => removeskill(i)}
                          >
                            <CiCircleMinus />
                          </button>
                        </div>
                        <div className="col-6">
                          <label
                            style={{
                              fontSize: "14px",
                              margin: "15px 0 0 15px",
                            }}
                          >
                            Skill Name: <span style={{ color: "red" }}>*</span>
                          </label>

                          <div className="row mt-1">
                            <div className="col-12">
                              <label>
                                <GiSkills />
                              </label>
                              <input
                                type="text"
                                id="EmployeeName41"
                                name="EmployeeName41"
                                placeholder="Name"
                                value={item?.name}
                                onChange={(e) => {
                                  const data = [...employeeData.skills];
                                  data[i].name = e.target.value;
                                  setEmployees({
                                    ...employeeData,
                                    skills: data,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <label
                            style={{
                              fontSize: "14px",
                              margin: "15px 0 0 15px",
                            }}
                          >
                            Progress:<span style={{ color: "red" }}>*</span>
                          </label>

                          <div className="row mt-1">
                            <div className="col-12">
                              <label>
                                <AiOutlinePercentage />
                              </label>
                              <input
                                type="number"
                                id="EmployeeName01"
                                name="EmployeeName01"
                                placeholder="Percentage"
                                value={item?.percentage}
                                onChange={(e) => {
                                  const data = [...employeeData.skills];
                                  data[i].percentage = e.target.value;
                                  setEmployees({
                                    ...employeeData,
                                    skills: data,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className="col-6">
                    <label
                      style={{ fontSize: "14px", margin: "15px 0 0 15px" }}
                    >
                      Documents
                    </label>

                    <div className="row mt-1">
                      <div className="col-12">
                        <label>
                          <CiHome />
                        </label>
                        <input id="emp04" onChange={handleCv} type="file" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-5">
                    <label
                      style={{ fontSize: "14px", margin: "15px 0 0 15px" }}
                    >
                      Description
                    </label>
                    <div className="row">
                      <div className="col-12 d-flex align-items-baseline gap-2">
                        <label>
                          <BiMessageDetail />
                        </label>
                        <textarea
                          style={{
                            height: "120px",
                            border: "none",
                            fontSize: "13px",
                            width: "100%",
                          }}
                          type="text"
                          id="EmployeeName02"
                          name="EmployeeName02"
                          placeholder="Description"
                          value={employeeData?.desc}
                          onChange={(e) =>
                            setEmployees({
                              ...employeeData,
                              desc: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleAllCloseTabs}
              >
                {" "}
                Discard
              </Button>

              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen3}
              >
                Back
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  employeeData?.id
                    ? editEmplyee(employeeData)
                    : handleSubmit(employeeData);
                }}
                variant="danger"
              >
                {" "}
                {employeeData?.id ? "Update" : "Save&Exit"}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleDrawerOpen5();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        className="mycmpny-drawer"
        anchor="right"
        open={openDrawer5}
        onClose={handleDrawerClose5}
        style={{ width: "800px" }}
      >
        {/* Drawer 5 content */}

        <div
          className="wizradparent1"
          style={{ padding: "22px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                    background: divColor,
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9 scrollBar"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
                height: "500px",
                overflowY: "scroll",
              }}
            >
              <div className="inputsection" style={{ marginTop: "20px" }}>
                <h5>KIN Information</h5>
                <Form>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label>Kin Name</Form.Label>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "45px",
                          left: "17px",
                        }}
                        src={formpen}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="text"
                        id="emp05"
                        placeholder="Name"
                        value={employeeData?.kin_name}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            kin_name: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label> Contact Number</Form.Label>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "48px",
                          left: "52%",
                        }}
                        src={formcnt}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="number"
                        id="emp06"
                        placeholder="+92"
                        value={employeeData?.kin_phone}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            kin_phone: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label>Address</Form.Label>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "48px",
                          left: "18px",
                        }}
                        src={formadrs}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "28px",
                        }}
                        type="text"
                        id="emp07"
                        placeholder="Temporary Address"
                        value={employeeData?.kin_address}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            kin_address: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </div>

              <div className="inputsection" style={{ marginTop: "20px" }}>
                <Form>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label>Next to Kin Name</Form.Label>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "45px",
                          left: "17px",
                        }}
                        src={formpen}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="text"
                        id="emp08"
                        placeholder="Name"
                        value={employeeData?.next_to_kin_name}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            next_to_kin_name: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Next to Contact Number</Form.Label>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "48px",
                          left: "52%",
                        }}
                        src={formcnt}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="number"
                        id="emp09"
                        placeholder="+92"
                        value={employeeData?.next_to_kin_phone}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            next_to_kin_phone: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label>Next to Address</Form.Label>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "48px",
                          left: "18px",
                        }}
                        src={formadrs}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="text"
                        id="emp011"
                        placeholder="Temporary Address"
                        value={employeeData?.next_to_kin_address}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            next_to_kin_address: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </div>
              <div className="inputsection" style={{ marginTop: "20px" }}>
                <Form>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label> Emergency Contact Name</Form.Label>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "45px",
                          left: "18px",
                        }}
                        src={formpen}
                      />

                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="text"
                        id="emp012"
                        placeholder="Name"
                        value={employeeData?.emergency_contact}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            emergency_contact: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Emergency Contact Number</Form.Label>
                      <img
                        alt=""
                        style={{
                          position: "absolute",
                          top: "48px",
                          left: "52%",
                        }}
                        src={formcnt}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="number"
                        id="emp013"
                        placeholder="+92"
                        value={employeeData?.emergency_contact_name}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            emergency_contact_name: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleAllCloseTabs}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen4}
              >
                Back
              </Button>

              <Button
                type="submit"
                onClick={() => {
                  employeeData?.id
                    ? editEmplyee(employeeData)
                    : handleSubmit(employeeData);
                }}
                variant="danger"
              >
                {" "}
                {employeeData?.id ? "Update" : "Save&Exit"}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleDrawerOpen6();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        className="mycmpny-drawer"
        anchor="right"
        open={openDrawer6}
        onClose={handleDrawerClose6}
        style={{ width: "800px" }}
      >
        {/* Drawer 6 content */}

        <div
          className="wizradparent1"
          style={{ padding: "22px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                    background: divColor,
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9 scrollBar"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
                height: "500px",
                overflowY: "scroll",
              }}
            >
              <div className="inputsection" style={{ marginTop: "20px" }}>
                <h5>Social Links</h5>
                {employeeData?.link_data?.map((item, i) => {
                  return (
                    <>
                      {i > 2 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            height: "40px",
                          }}
                          className="col-12"
                        >
                          <button
                            className="btn btn-primary"
                            style={{
                              padding: "8px 16px",
                              marginRight: "1rem",
                              marginTop: "10px",
                            }}
                            onClick={() => removelink(i)}
                          >
                            <CiCircleMinus />
                          </button>
                        </div>
                      )}
                      <Form>
                        <Row className="mb-3">
                          <Form.Group as={Col}>
                            <Form.Label>Channel</Form.Label>

                            <Form.Control
                              style={{
                                padding: "10px",
                                borderRadius: "10px",
                                paddingLeft: "25px",
                              }}
                              type="text"
                              id="emp014"
                              placeholder="URL"
                              value={item.title}
                              onChange={(e) => {
                                const data = [...employeeData.link_data];
                                data[i].title = e.target.value;
                                setEmployees({
                                  ...employeeData,
                                  link_data: data,
                                });
                              }}
                            />
                          </Form.Group>
                          <Form.Group as={Col} style={{ position: "relative" }}>
                            <Form.Label>Add URL</Form.Label>
                            <img
                              alt=""
                              src={formlink}
                              style={{
                                position: "absolute",
                                top: "50px",
                                left: "18px",
                              }}
                            />
                            <Form.Control
                              style={{
                                padding: "10px",
                                borderRadius: "10px",
                                paddingLeft: "25px",
                              }}
                              type="text"
                              id="emp014"
                              placeholder="URL"
                              value={item.link}
                              onChange={(e) => {
                                const data = [...employeeData.link_data];
                                data[i].link = e.target.value;
                                setEmployees({
                                  ...employeeData,
                                  link_data: data,
                                });
                              }}
                            />
                          </Form.Group>
                        </Row>
                      </Form>
                    </>
                  );
                })}
              </div>

              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  className="btn btn-primary"
                  style={{
                    padding: "8px 16px",
                    marginRight: "1rem",
                    marginTop: "10px",
                  }}
                  onClick={addlink}
                >
                  Add More
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleAllCloseTabs}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen5}
              >
                Back
              </Button>

              <Button
                type="submit"
                onClick={() => {
                  employeeData?.id
                    ? editEmplyee(employeeData)
                    : handleSubmit(employeeData);
                }}
                variant="danger"
              >
                {" "}
                {employeeData?.id ? "Update" : "Save&Exit"}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleDrawerOpen7();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        className="mycmpny-drawer"
        anchor="right"
        open={openDrawer7}
        onClose={handleDrawerClose7}
      >
        {/* Drawer 7 content */}

        <div
          className="wizradparent1"
          style={{ padding: "22px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                    background: divColor,
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9 scrollBar"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
                height: "500px",
                overflowY: "scroll",
              }}
            >
              <div className="inputsection" style={{ marginTop: "20px" }}>
                <h5>Others</h5>
                <Form>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label>Blood Group</Form.Label>
                      <img
                        alt=""
                        src={formbld}
                        style={{
                          position: "absolute",
                          top: "50px",
                          left: "18px",
                        }}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        id="emp015"
                        type="text"
                        placeholder="Blood Group"
                        value={employeeData?.blood_grp}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            blood_grp: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Medical History</Form.Label>
                      <img
                        alt=""
                        src={formissue}
                        style={{
                          position: "absolute",
                          top: "48px",
                          left: "52%",
                        }}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "28px",
                        }}
                        type="text"
                        id="emp016"
                        placeholder="Any Issue"
                        value={employeeData?.medical_history}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            medical_history: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row
                    className="mb-3"
                    style={{ marginTop: "20px", position: "relative" }}
                  >
                    <Form.Group as={Col}>
                      <Form.Label>Meal Allowance</Form.Label>
                      <img
                        alt=""
                        src={formmeal}
                        style={{
                          position: "absolute",
                          top: "50px",
                          left: "18px",
                        }}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="number"
                        id="emp017"
                        placeholder="Allowance"
                        value={employeeData?.meal_allowance}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            meal_allowance: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Home Allowance</Form.Label>
                      <img
                        alt=""
                        src={formallnc}
                        style={{
                          position: "absolute",
                          top: "47px",
                          left: "52%",
                        }}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="number"
                        id="emp018"
                        placeholder="Allownace"
                        value={employeeData?.house_rent_allowance}
                        onChange={(e) =>
                          setEmployees({
                            ...employeeData,
                            house_rent_allowance: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3" style={{ position: "relative" }}>
                    <Form.Group as={Col}>
                      <Form.Label>Travel Allowance</Form.Label>
                      <img
                        alt=""
                        src={formtravel}
                        style={{
                          position: "absolute",
                          top: "42px",
                          left: "18px",
                        }}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "25px",
                        }}
                        type="number"
                        id="emp019"
                        placeholder="Allowance"
                        value={employeeData?.travel_allowance}
                        onChange={(e) => {
                          setEmployees({
                            ...employeeData,
                            travel_allowance: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Other Allowance</Form.Label>
                      <img
                        alt=""
                        src={formsal}
                        style={{
                          position: "absolute",
                          top: "47px",
                          left: "52%",
                        }}
                      />
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          paddingLeft: "28px",
                        }}
                        type="number"
                        id="emp020"
                        placeholder="Allowance"
                        value={employeeData?.travel_condition}
                        onChange={(e) => {
                          setEmployees({
                            ...employeeData,
                            travel_condition: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleAllCloseTabs}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen6}
              >
                Back
              </Button>

              <Button
                type="submit"
                onClick={() => {
                  employeeData?.id
                    ? handleUpdate()
                    : handleSubmit(employeeData);
                }}
                variant="danger"
              >
                {" "}
                {employeeData?.id ? "Update" : "Save&Exit"}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
      {/* VIew Profile Modal */}

      <Modal
        className="profilecard"
        style={{ zIndex: "9999" }}
        show={show}
        onHide={handleClose01}
      >
        <Modal.Header style={{ padding: "0px" }}>
          <div
            className="profheader"
            style={{
              backgroundImage: `url(${profilebg})`,
              height: "260px",
              width: "100%",
              position: "relative",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: "10px",
            }}
          >
            <div
              className="row"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <div className="col-12">
                <div className="cmplogo">
                  <h5>{employeeIndividual?.data?.company?.name}</h5>
                  {/* <img alt='' src={companylogo} /> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body style={{ top: "-26px" }}>
          <div className="row" style={{ position: "relative" }}>
            <img
              alt=""
              style={{
                position: "absolute",
                top: "-50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                width: "70%",
              }}
              src={profilelyr}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <img
                alt=""
                style={{ width: "70px", height: "70px", borderRadius: "50%" }}
                src={img_url + employeeIndividual?.data?.profile}
              // onClick={() => {
              //   setEmployees({
              //     companyId: employeeIndividual?.data?.companyId,
              //     branchId: employeeIndividual?.data?.branchId,
              //     name: employeeIndividual?.data?.name,
              //     phone: employeeIndividual?.data?.phone,
              //     address: employeeIndividual?.data?.address,
              //     gender: employeeIndividual?.data?.gender,
              //     maritalStatus: employeeIndividual?.data?.maritalStatus,
              //     cnic: employeeIndividual?.data?.cnic,
              //     dob: employeeIndividual?.data?.dob,
              //     email: employeeIndividual?.data?.email,
              //     departmentId: employeeIndividual?.data?.departmentId,
              //     designationId: employeeIndividual?.data?.designationId,
              //     doj: new Date(employeeIndividual?.data?.doj).toISOString().split("T")[0],
              //     password: "",
              //     salary: employeeIndividual?.data?.salary,
              //     permanent_address: employeeIndividual?.data?.permanent_address,
              //     kin_address: employeeIndividual?.data?.kin_address,
              //     blood_grp: employeeIndividual?.data?.blood_grp,
              //     emergency_contact: employeeIndividual?.data?.emergency_contact,
              //     medical_history: employeeIndividual?.data?.medical_history,
              //     next_to_kin_name: employeeIndividual?.data?.next_to_kin_name,
              //     account_holder: employeeIndividual?.data?.account_holder,
              //     bank_name: employeeIndividual?.data?.bank_name,
              //     account_number: employeeIndividual?.data?.account_number,
              //     travel_allowance: employeeIndividual?.data?.travel_allowance,
              //     IBAN: employeeIndividual?.data?.IBAN,
              //     profile: employeeIndividual?.data?.profile,
              //     meal_allowance: employeeIndividual?.data?.meal_allowance,
              //     travel_condition: employeeIndividual?.data?.travel_condition,
              //     house_rent_allowance: employeeIndividual?.data?.house_rent_allowance,
              //     nationality: employeeIndividual?.data?.nationality,
              //     cv: employeeIndividual?.data?.cv,
              //     java_id: employeeIndividual?.data?.java_id,
              //     note: employeeIndividual?.data?.note,
              //     kin_name: employeeIndividual?.data?.kin_name,
              //     kin_phone: employeeIndividual?.data?.kin_phone,
              //     next_to_kin_phone: employeeIndividual?.data?.next_to_kin_phone,
              //     next_to_kin_address: employeeIndividual?.data?.next_to_kin_address,
              //     desc: employeeIndividual?.data?.desc,
              //     link_data: employeeIndividual?.data?.link_data.map(link => ({
              //       title: link.title,
              //       link: link.link,
              //     })),
              //     education_data: employeeIndividual?.data?.education_data.map(education => ({
              //       field: education.field,
              //       education: education.education,
              //       document: education.document,
              //     })),
              //     skills: employeeIndividual?.data?.skills.map(skill => ({
              //       name: skill.name,
              //       percentage: skill.percentage,
              //     })),
              //     experience: employeeIndividual?.data?.experience.map(exp => ({
              //       company: exp.company,
              //       post: exp.post,
              //       period: exp.period,
              //     }))
              //   });
              //   handleClose01()
              //   handleDrawerOpen1();
              // }}
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              flexDirection: "column",
              gap: "10px",
              position: "relative",
              top: "71px",
            }}
          >
            <h5 style={{ color: "black", fontWeight: "bold" }}>
              {employeeIndividual?.data?.name} ID:
              {employeeIndividual?.data?.java_id}
            </h5>
            <p>{employeeIndividual?.data?.designation?.title}</p>
            {/* <span>03067660011</span> */}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ top: "50px" }}>
          <h6>Employee Status:</h6>
        </Modal.Footer>
      </Modal>
      <Snackbar
        open={anyFieldEmpty}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          severity="error"
          style={{
            backgroundColor: "#8f2018",
            color: "white",
          }}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Employee;
