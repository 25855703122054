import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
// import img1 from '../Images/user/avatar-2.jpg'
import { CloudUploadOutlined, UserOutlined } from '@ant-design/icons';
import { Tabs, Menu } from 'antd';
import { CiSearch, CiBookmarkCheck, CiCalendarDate, CiBadgeDollar, CiCreditCard1, CiHardDrive, CiLocationOn, CiMail, CiMemoPad, CiMoneyBill, CiPassport1, CiPhone, CiSettings, CiEdit, CiTrash } from 'react-icons/ci';
import { LuActivitySquare } from 'react-icons/lu';
import Papa from 'papaparse';
import { useData } from '../../../ContextProvider/Context'
import { saveAs } from 'file-saver';
function CustomerProfile() {
    const params = useParams()
    const { getClientIndiLatestData, clientIndiLatestData, getClientIndiData, clientIndiData, img_url } = useData()
    useEffect(() => {
        getClientIndiData(params.id)
        getClientIndiLatestData()
    }, [params?.id])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentClientDetails = clientIndiData?.transactionOfClient?.slice(indexOfFirstItem, indexOfLastItem) || [];

    const totalPages = Math.ceil((clientIndiData?.transactionOfClient?.length || 0) / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const billingAddress = clientIndiData?.data?.bill_address + ',' + clientIndiData?.data?.bill_city + ',' + clientIndiData?.data?.bill_province + ',' + clientIndiData?.data?.bill_country + ',' + clientIndiData?.data?.bill_postal
    const shippingAddress = clientIndiData?.data?.ship_address + ',' + clientIndiData?.data?.ship_city + ',' + clientIndiData?.data?.ship_province + ',' + clientIndiData?.data?.ship_country + ',' + clientIndiData?.data?.ship_postal
    return (
        <>
            <div className="pcoded-main-container employeeProfileData">
                <div className="pcoded-content">
                    <div className="user-profile user-card">
                        <div className="card-body py-0">
                            <div className="user-about-block m-0">
                                <div className="row align-items-center" style={{ padding: "1rem" }}>
                                    <div className='col-2'>
                                        <div style={{ height: "150px", width: "150px", borderRadius: "5px", position: "relative", overflow: "hidden" }}>
                                            <img src={clientIndiData?.profile ? img_url + clientIndiData?.profile : "https://newvintagewi.org/wp-content/uploads/2021/07/final-resized-male-image.jpg"} style={{ position: "absolute", width: "100%", height: "100%" }} alt='...' />
                                        </div>
                                    </div>
                                    <div className='col-6' style={{ padding: "0 1rem" }}>
                                        <div style={{ borderBottom: "1px solid lightgray" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <h5 style={{ color: "#1677FF", fontWeight: "bold" }}>{clientIndiData?.data?.name}</h5>

                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <h6 style={{ color: "gray", fontSize: "13px", padding: ".3rem 1rem" }}> <CiBookmarkCheck style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{clientIndiData?.data?.company?.name}</h6>
                                                <h6 style={{ color: "gray", fontSize: "13px", padding: ".3rem 1rem" }}> <CiMemoPad style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{clientIndiData?.data?.website}</h6>
                                                <h6 style={{ display: "flex", alignItems: "center", color: "gray", fontSize: "13px", padding: ".3rem 1rem" }}><CiCalendarDate style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{clientIndiData?.data?.term}</h6>
                                                <h6 style={{ display: "flex", alignItems: "center", color: "gray", fontSize: "13px", padding: ".3rem 1rem" }}><CiMoneyBill style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{clientIndiData?.data?.language}</h6>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                                            <h6 style={{ color: "gray", fontSize: "13px" }}> <CiPhone style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{clientIndiData?.data?.phone}</h6>
                                            <h6 style={{ color: "gray", fontSize: "13px" }}><CiLocationOn style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{billingAddress}</h6>
                                            <h6 style={{ color: "gray", fontSize: "13px" }}><CiMail style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{clientIndiData?.data?.email}</h6>
                                        </div>

                                    </div>
                                    <div className='col-4'>
                                        <div className='card p-5' >
                                            <div className='row'>
                                                <div className='col-12 text-center'>
                                                    <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>Summary</h3>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-6 text-center'>
                                                    <h6 style={{ fontWeight: "bold", fontSize: "16" }}>Opening Balance</h6>
                                                    <p style={{ marginTop: "0" }}>{clientIndiData?.data?.opening_balance?.toLocaleString() ? clientIndiData?.data?.opening_balance?.toLocaleString() : '0'}</p>
                                                </div>
                                                <div className='col-6 text-center'>
                                                    <h6 style={{ fontWeight: "bold", fontSize: "16" }}>Overdue Balance</h6>
                                                    <p style={{ marginTop: "0" }}> {clientIndiData?.data?.balance?.toLocaleString() ? clientIndiData?.data?.balance?.toLocaleString() : '0'} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row employeeBody">
                        <div className="col-md-9 order-md-2">
                            <Tabs
                                defaultActiveKey="1"
                                items={[
                                    {
                                        label: (
                                            <span className='tabLabel'>
                                                <UserOutlined />
                                                Profile
                                            </span>
                                        ),
                                        key: "1",
                                        children: (
                                            <section>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className='col-md-6'>
                                                            <div className='row mb-3'>
                                                                <div className="col-12">
                                                                    <div className="card mb-4">
                                                                        <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                            <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Contact details</h5></div>
                                                                        </div>
                                                                        <div className="card-body table-responsive recruitment">
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Full Name</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <p className="text-muted mb-0">{clientIndiData?.data?.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Email</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <p className="text-muted mb-0">{clientIndiData?.data?.email}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Mobile</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <p className="text-muted mb-0">{clientIndiData?.data?.mobile}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Fax</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <p className="text-muted mb-0">{clientIndiData?.data?.fax}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Other</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <p className="text-muted mb-0">{clientIndiData?.data?.other}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Website</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <p className="text-muted mb-0">{clientIndiData?.data?.website}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Notes</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <p className="text-muted mb-0">{clientIndiData?.data?.note}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='row mb-3'>
                                                                <div className="col-12">
                                                                    <div className="card mb-4">
                                                                        <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                            <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Additional details</h5></div>
                                                                        </div>
                                                                        <div className="card-body table-responsive recruitment">
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Billing Address</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <p className="text-muted mb-0">{billingAddress}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Shipping Address</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <p className="text-muted mb-0">{shippingAddress}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Terms</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <p className="text-muted mb-0">{clientIndiData?.data?.term}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Pyment method</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <p className="text-muted mb-0">{clientIndiData?.data?.payment_method}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Customer Language</p>
                                                                                </div>
                                                                                <div className="col-sm-6">
                                                                                    <p className="text-muted mb-0">{clientIndiData?.data?.language}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='row mb-3'>
                                                                <div className="col-12">
                                                                    <div className="card mb-4">
                                                                        <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                            <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Attachments</h5></div>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <p className="text-muted mb-0">Attachments</p>
                                                                            </div>
                                                                            <hr />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                        )
                                    },
                                    {
                                        label: (
                                            <span className='tabLabel'>
                                                <CiBadgeDollar />
                                                Transactions
                                            </span>
                                        ),
                                        key: "2",
                                        children: (
                                            <section>
                                                <div className="container">
                                                    <div className='row'>
                                                        <div className='card'>
                                                            <div className='card-body table-responsive recruitment'>
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Invoice#</th>
                                                                            <th scope="col">Date</th>
                                                                            <th scope="col">Amount</th>
                                                                            <th scope="col">Status</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {currentClientDetails?.length > 0 ?
                                                                            (
                                                                                currentClientDetails?.map((item, idx) => {
                                                                                    return (
                                                                                        <tr key={idx}>
                                                                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                                                            <td>
                                                                                                <Link className='link_hover' style={{ color: "inherit" }} to={`/admin/Invoice/${item?.invoice?.id}`}>
                                                                                                    Invoice {item?.invoice?.invoice_no}
                                                                                                </Link>
                                                                                            </td>
                                                                                            <td>{item?.date?.split(" ")[0]}</td>
                                                                                            <td>{item.amount.toLocaleString() ? item.amount.toLocaleString() : '-'}</td>
                                                                                            <td>
                                                                                                {item.status == '1' ? 'Unpaid' : 'Paid'}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan="5" style={{ textAlign: "center" }}>
                                                                                        No Data
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                    </tbody>
                                                                </table>
                                                                <div>
                                                                    {paginationControls.map((button, index) => (
                                                                        <React.Fragment key={index}>{button}</React.Fragment>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div>
                                            </section>

                                        )
                                    }
                                ]}
                            />

                        </div>
                        <div className="col-md-3 order-md-1 css-dev-only">
                            <div className='headrow'>
                                <h1 className='heh1'> <LuActivitySquare /> Customers</h1>
                            </div>
                            {/* <div className='row p-3'>
                                <div style={{ border: "1px solid lightgray", display: "flex", gap: "10px", alignItems: "center", borderRadius: "5px", background: "white" }}>
                                    <label for='employee'><CiSearch /></label>
                                    <input
                                        id='employee'
                                        type='text'
                                        placeholder='Search'
                                        style={{
                                            border: 'none',
                                            padding: '5px',
                                            width: '100%',
                                            borderRadius: '4px',
                                            background: "white"
                                        }}
                                    />
                                </div>
                            </div> */}
                            <div className='scrollBar' style={{ height: "400px", overflowY: "scroll", overflowX: "hidden" }}>
                                {clientIndiLatestData?.data?.map((item) => {
                                    return (
                                        <div className='row mb-3 border-bottom'>
                                            <Link style={{ textDecoration: "none", color: "inherit" }} to={`/admin/accounts/banking/client/${item.id}`} className='link_hover'>
                                                <div style={{ display: "flex", gap: "10px" }}>
                                                    <div>
                                                        <div style={{ height: "30px", width: "30px", borderRadius: "50%", position: "relative", overflow: "hidden" }}>
                                                            <img src={item?.profile ? img_url + item?.profile : "https://newvintagewi.org/wp-content/uploads/2021/07/final-resized-male-image.jpg"} style={{ position: "absolute", width: "100%", height: "100%" }} alt='...' />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h6 style={{ fontWeight: "bold" }}>{item?.name}</h6>
                                                        <small>PKR {item?.opening_balance?.toLocaleString()}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default CustomerProfile
