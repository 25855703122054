import React, { useState, useEffect, useRef } from 'react';
import { CiMicrophoneOn, CiSearch } from 'react-icons/ci';
import { MdAttachFile } from 'react-icons/md';
import { SlOptionsVertical } from 'react-icons/sl'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { BiChevronDown, BiSearchAlt2, BiSolidMicrophone } from 'react-icons/bi';
import { useData } from '../ContextProvider/Context';
import Pusher from 'pusher-js';
import { FaUserGroup } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoVideocam } from "react-icons/io5";
import { Close } from '@mui/icons-material';
import { Drawer } from "@mui/material";
import cross from "../Images/filtercross.png";
import { IoLocationSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import "@fontsource/open-sans";
import { isNull } from 'lodash';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
};

const ChatApp = () => {
  const [message, setMessage] = useState('');
  const chatContainerRef = useRef();
  const [messageList, setMessageList] = useState([])

  const { getChateList, lestChat, img_url, base_url, token } = useData()
  const [chatLog, setChatLog] = useState([]);
  const [activeChatRoom, setActiveChatRoom] = useState(null);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaStream = useRef(null);
  const recordRTC = useRef(null);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const open4 = Boolean(anchorEl4);
  const open5 = Boolean(anchorEl5);
  const [file, setFile] = useState(null);
  const [chatId, setChatId] = useState()
  const [userID, setuserid] = useState()
  const [conversationId, setconversetionID] = useState()
  const chatWrap = useRef(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose4 = () => {
    setAnchorEl4(null);
  };
  const handleClose5 = () => {
    setAnchorEl5(null);
  };

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        // setSelectedImage(URL.createObjectURL(file)); // Display the selected image
        setFile(base64String);
        // fileSent=base64String
      }
      reader.readAsDataURL(selectedFile);
    }

    handleOpen3()

  };

  // const socket = new WebSocket(`ws://192.168.137.1:6001/app/local?token=${token}`);
  const [userData, setUserData] = useState()

  useEffect(() => {
    // Pusher.logToConsole = true;
    const pusher = new Pusher('local', {
      cluster: 'mt1',
      wsHost: 'wholesaleshopping.tv',
      wsPort: 6001,
      disableStats: true,
      forceTLS: false,
      authEndpoint: `${base_url}pusher/auth`,
      encrypted: false,
      logToConsole: true // Enable debugging
    });
    const channel = pusher.subscribe('private-conversation.' + conversationId);

    channel.bind('App\\Events\\NewMessage', function (data) {
      // Access the message property within the received data
      const message = data.message;
      // Check if the message ID already exists in messageList
      const isDuplicate = messageList.some(existingMessage => existingMessage.id === message.id);

      if (!isDuplicate) {
        setMessageList(prevMessageList => [...prevMessageList, message]);

      } else {
        // Handle the case where the message is a duplicate
        console.log(`Message with ID ${message.id} already exists.`);
      }

      // Now you can access specific properties within the message object, e.g., message.id, message.body, etc.
    });
    // Cleanup function to close the Pusher connection
    return () => {
      pusher.disconnect();
    };
  }, [conversationId])
  const openModal = () => {
    handleClose()
    handleOpen2()
  }
  const audioPlayerRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        audioChunksRef.current = [];

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: "audio/mp3" });
          // const audioUrl = URL.createObjectURL(audioBlob);

          // audioPlayerRef.current.src = audioUrl;
          // audioPlayerRef.current.style.display = "block";

          convertAudioToBase64(audioBlob); // Call the function to convert audio to Base64

          setRecording(false);
        };

        mediaRecorder.start();
        setRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const stopRecording = () => {
    const mediaRecorder = mediaRecorderRef.current;
    if (mediaRecorder && recording) {
      mediaRecorder.stop();
    }
  };
  const convertAudioToBase64 = (audioBlob) => {
    const reader = new FileReader();
    reader.onload = function () {
      const base64String = reader.result;
      console.log("Base64 encoded audio:", base64String);
      sendvoice(base64String)
    };
    reader.readAsDataURL(audioBlob);
  };
  // const stopRecording = () => {
  //   if (recordRTC.current) {
  //     recordRTC.current.stopRecording(() => {
  //       const blob = recordRTC.current.getBlob();
  //       setAudioBlob(blob);
  //       console.log(blob);
  //       setRecording(false);
  //       mediaStream.current.getTracks().forEach((track) => track.stop());

  //       // Convert the blob to base64
  //       const reader = new FileReader();
  //       reader.onload = function() {
  //         const base64Data = reader.result; // Extract base64 data from the result
  //         sendvoice(base64Data);
  //       };
  //       reader.readAsDataURL(blob);
  //     });
  //   }
  // };
  const sendMessage = async (data) => {

    // const data ={
    //   event:"NewMessage",
    //   data:};
    console.log("message data",data)
    await fetch(`${base_url}store-message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((resp) => {
        if (resp) {
          // console.log(response);
          setMessage("")
          // setMessageList([data])
          getChat(data?.conversation_id)
         
          console.log('Message sent successfully.', resp);
        } else {
          console.error('Failed to send message.');
        }
      })


      .catch((error) => {
        console.error('Error sending message:', error);
      });
    // socket.send(JSON.stringify(data));
  };
  // const [fileSent,setBase64Image]=useState()
  const sendfile = () => {


    console.log(file);
    const data = {
      file: file,
      conversation_id: conversationId,
      messageable_id: BrID?.employee?.id,

    };

    fetch(`${base_url}store-message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((resp) => {
        if (resp) {
          // console.log(response);
          setMessage("")
          console.log('Message sent successfully.', resp);
        } else {
          console.error('Failed to send message.');
        }
      })
      .catch((error) => {
        console.error('Error sending message:', error);
      });

  };
  const sendvoice = async (voice) => {
    // console.log(voice);
    const data = {

      file: voice,
      conversation_id: conversationId,
      messageable_id: BrID?.employee?.id,

    };

    await fetch(`${base_url}store-message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((resp) => {
        if (resp) {
          // console.log(response);
          setMessage("")
          console.log('Message sent successfully.', resp);
        } else {
          console.error('Failed to send message.');
        }
      })
      .catch((error) => {
        console.error('Error sending message:', error);
      });

  };


  const handleSend = (data) => {
    if (messageList) {
      sendMessage(data);
      console.log("message data", data)
    }
  };

  const handleSendFile = () => {
    if (activeChatRoom && file) {
      sendMessage('', activeChatRoom.roomId, activeChatRoom.toUserId, 'file');
      handleClose3()
    }
  };

  let BrID = JSON.parse(localStorage.getItem("user_auth"))
  useEffect(() => {
    var id = BrID?.employee?.id
    getChateList(id)

  }, [])
  console.log("brid form chat", BrID)
  console.log("least chat  data", lestChat)
  console.log("user data",userData)
  console.log("mesg list is ", messageList)

  const createNewConvesation = (value) => {
    console.log("value is",value)
    setuserid(value[0].id)
    value?.push({
      id: BrID?.employee?.id,
      type: "employee"
    })
    fetch(`${base_url}conversations-store`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        participants: value,
        data: [],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setChatId(data);
       setconversetionID(data?.conversation_id)
        console.log("data is",data);
        setMessageList([]);
        // getChat(BrID?.employee?.id)
      });
      
     
  }
  const getChat = (id) => {
    setconversetionID(id)
    fetch(`${base_url}conversation/${id}/messages`)
      .then((res) => res.json())
      .then((data) => {
        console.log("get chat data",data)
        setMessageList(data);
      });
  }
  console.log(messageList);
  useEffect(() => {
    if (chatWrap.current) {
      chatWrap.current.scrollTo(0, chatWrap.current.scrollHeight);
    }
  }, [messageList]); // Scroll when new messages are added
  const [messagesReply, setmessageReply] = useState(null)
  const reply = (value) => {
    setmessageReply(value)
    handleClose4()
    handleClose5()

  }
  const sendReply = () => {

  }
  const deleteMessage = (id) => {
    // console.log(id);
    var array = [id]
    fetch(`${base_url}messages`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        message_ids: array
      })
    })
      .then((resp) => {
        getChat(conversationId)
      })
      .catch((error) => {
        console.error('Error sending message:', error);
      });

  }
  const handleDownload = async (url, name) => {
    // Replace the URL and file name with the actual file URL and desired file name.
    const fileURL = img_url + "storage/" + url
    const fileName = name;
    const mimeType = 'image/jpeg'

    try {
      const response = await fetch(fileURL, { mode: 'no-cors' });
      const blob = await response.blob();

      // Create a temporary URL for the Blob and trigger download
      const blobURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobURL;
      link.setAttribute('download', fileName);

      link.setAttribute('type', mimeType);

      // Append the link to the document and trigger click event
      document.body.appendChild(link);
      link.click();

      // Clean up: remove the link and revoke the Blob URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const [groupName, setgroupName] = useState("")
  // const [perticepent,setParticepent]=useState([])
  const [selectedItems, setSelectedItems] = useState([]);

  const handleRowClick = (itemId) => {
    if (selectedItems.includes(itemId)) {
      // If the item is already selected, remove it from the array
      setSelectedItems(selectedItems.filter(item => item !== itemId));


    } else {
      // If the item is not selected, add it to the array
      setSelectedItems([...selectedItems, itemId]);
    }

  };
  console.log(selectedItems);
  // {id: item.employee_id, type: "employee"}
  // const updatedItems = lestChat.map((item) =>
  //     item.id === itemId.id ? { ...item, selected: !item.selected } : item
  //   );
  //   // data
  //   // : 
  //   // []
  //   // participants
  //   // : 
  //   // [{id: 32, type: "employee"}, {id: 21, type: "employee"}]
  //   // setItems(updatedItems);

  //   const updatedSelectedItems = lestChat.filter((item) => item.selected);
  //   setSelectedItems(updatedSelectedItems);
  const createGroup = () => {
    // setuserid(value[0].id)
    var array = []
    selectedItems?.map((item) => {
      var obj = {
        id: item,
        type: "employee"
      }
      array.push(obj)
    })
    array.push({
      id: BrID?.employee?.id,
      type: "employee"
    })
    fetch(`${base_url}conversations-store`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        participants: array,
        data: groupName,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setChatId(data);
        setconversetionID(data.conversation_id)
        setMessageList([])
        setSelectedItems([])
        handleClose2()
        var id = BrID?.employee?.id
        getChateList(id)
        // getChat(data.id)
      });
  }
  const leaveGroup = (obj) => {
    fetch(`${base_url}remove-employee-from-conversation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        setUserData()
        var id = BrID?.employee?.id
        getChateList(id)
        // getChat(data.id)
      });
  }
  return (
    <div style={{ height: "85vh", overflow: "hidden" }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-white">
              <div className="panel-body chat">
                <div className="row chat-wrapper">
                  <div style={{ padding: "10px", background: "ghostwhite" }} className="col-md-3">
                    {/* <div className="compose-area"> 
                <a href="javascript:void(0);" className="btn btn-default"><i className="fa fa-edit" /> New Chat</a>
              </div> */}
                    <div>
                      <div className="slimScrollDiv" style={{ position: 'relative', overflow: 'hidden', width: 'auto', height: '80vh' }}>
                        <div style={{ height: "auto" }}>
                          <div className="compose-box">
                            <div className='row' style={{ marginBottom: "10px" }}>
                              <div className='col-md-12'>
                                <img style={{ width: "40px", height: "40px", borderRadius: "50%" }} src={img_url + BrID?.employee?.profile} /> {BrID?.employee?.name}
                              </div>
                            </div>
                            <div style={{ display: "flex", gap: "5px", marginBottom: "10px" }}>
                              <div style={{ background: "#3498DB", width: "40px", height: "40px", borderRadius: "50%", padding: "6px", textAlign: "center" }}> <FaUserGroup style={{ color: "white" }} /></div>
                              <button
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                                style={{ border: "none", background: "none" }}>Create Group</button>
                            </div>
                            <div className="row">

                              <div style={{ display: "flex", justifyContent: "space-between" }} className="col-xs-12 mg-btm-10">
                                <div style={{ width: "100%" }}>

                                  <input style={{ border: "1px solid gray", width: "100%", background: "white" }} id="btn-input" className="form-control input-sm" placeholder="Search Employee here..." defaultValue={""} />
                                </div>

                                {/* <button
                                 >
                                  <SlOptionsVertical style={{ fontSize: "20px", color: "#2ECC71" }} />
                                </button> */}
                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: {
                                      width: '20ch',
                                    },
                                  }}
                                >

                                  <MenuItem onClick={openModal}>
                                    Create Group
                                  </MenuItem>

                                </Menu>
                                <Modal
                                  open={open2}
                                  onClose={handleClose2}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box sx={style}>
                                    <div className='chat'>
                                      <div className='row chat-wrapper'>

                                        <div style={{ padding: "0" }} className="col-12">

                                          <div>
                                            <div className="slimScrollDiv" style={{ position: 'relative', overflow: 'hidden', width: 'auto', height: '80vh' }}>
                                              <div style={{ height: "20vh", borderRight: "1px solid lightgray" }}>
                                                <div className="compose-box">
                                                  <div className="row">
                                                    <div style={{ display: "flex", justifyContent: "space-between", padding: ".5rem 1rem" }} className="col-xs-12 mg-btm-10">
                                                      <div style={{ width: "100%", border: "1px solid lightgray", borderRadius: "50px", display: "flex" }}>

                                                        <input style={{ border: "none", border: "none", width: "80%", background: "none" }} id="btn-input" className="form-control input-sm" placeholder="Search Employee here..." defaultValue={""} />
                                                        <button
                                                          style={{ background: "none", border: "none", width: "20%", backgroundColor: "#2ECC71", borderRadius: "50px" }}>
                                                          <BiSearchAlt2 style={{ fontSize: "20px", color: "white" }} />
                                                        </button>
                                                      </div>



                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div style={{ display: "flex", justifyContent: "space-between", padding: ".5rem 1rem" }} className="col-xs-12 mg-btm-10">
                                                      <div style={{ width: "100%", border: "1px solid lightgray", borderRadius: "50px", display: "flex" }}>

                                                        <input onChange={(e) => setgroupName(e.target.value)} style={{ border: "none", border: "none", width: "80%", background: "none" }} id="btn-input" className="form-control input-sm" placeholder="Enter Group Name here..." defaultValue={""} />

                                                      </div>



                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="chat-list-wrapper" style={{ overflowY: 'auto', width: 'auto', height: '60vh' }}>
                                                <ul className="chat-list">
                                                  {lestChat?.map((item) => {
                                                    return (
                                                      <>{BrID?.employee?.id !== item.employee_id &&
                                                        <li onClick={() => handleRowClick(item.employee_id)} className="new">
                                                          <div className='row'>
                                                            <div className='col-1'>

                                                              <input
                                                                type="checkbox"
                                                                // name={`item${item.id}`}
                                                                checked={selectedItems.includes(item.employee_id) ? true : false}

                                                              />
                                                            </div>
                                                            <div className='col-11'>
                                                              <span className="avatar available">
                                                                <img style={{ width: "40px", height: "40px", borderRadius: "50%", border: "none" }} src={item.employee_image ? img_url + item.employee_image : "https://bootdey.com/img/Content/avatar/avatar1.png"} alt="" className="img-circle" />
                                                              </span>
                                                              <div className="body">
                                                                <div className="header">
                                                                  <span className="username">{item?.employee_name}</span>
                                                                  <small className="timestamp text-muted">
                                                                    <i className="fa fa-clock-o" />1 secs ago
                                                                  </small>
                                                                </div>
                                                                <p>
                                                                  {item.last_message ? item?.last_message : "Start Chat"}
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>

                                                        </li>
                                                      }
                                                      </>

                                                    )
                                                  })}




                                                </ul>
                                              </div>
                                              <div className="slimScrollBar" style={{ width: '7px', position: 'absolute', top: '0px', opacity: '0.4', display: 'none', borderRadius: '7px', zIndex: 99, right: '1px', height: '478.639px', background: 'rgb(0, 0, 0)' }} />
                                              <div className="slimScrollRail" style={{ width: '7px', height: '100%', position: 'absolute', top: '0px', display: 'none', borderRadius: '7px', opacity: '0.2', zIndex: 90, right: '1px', background: 'rgb(51, 51, 51)' }} />
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "end", padding: "1rem", borderTop: "1px solid lightgray" }}>
                                          <button style={{ padding: ".5rem 1rem", border: "none", background: "#2ecc71", color: "white", borderRadius: "5px", marginLeft: ".5rem" }} onClick={createGroup}>Create</button>
                                          <button style={{ padding: ".5rem 1rem", border: "none", background: "#2ecc71", color: "white", borderRadius: "5px", marginLeft: ".5rem" }} onClick={handleClose2}>Cancel</button>
                                        </div>

                                      </div>
                                    </div>
                                  </Box>
                                </Modal>

                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="chat-list-wrapper" ref={chatContainerRef} style={{ overflowY: 'auto', width: 'auto', height: '70vh' }}>
                          <ul className="chat-list">
                            {lestChat?.map((item) => {
                              return (
                                <>{BrID?.employee?.id !== item.employee_id &&
                                  <li onClick={() => {
                                    if (item.conversation_id) {
                                      getChat(item.conversation_id)
                                      setUserData(item)

                                    }
                                    else {

                                      createNewConvesation([{
                                        id: item.employee_id,
                                        type: "employee"
                                      }])
                                      setUserData(item)
                                     
                                    

                                    }

                                  }

                                  } className="new">
                                    <span className="avatar available">
                                      <img style={{ width: "40px", height: "40px", borderRadius: "50%", border: "none" }} src={item.employee_image ? img_url + item.employee_image : "https://bootdey.com/img/Content/avatar/avatar1.png"} alt="" className="img-circle" />
                                    </span>
                                    <div className="body">
                                      <div className="header">
                                        <span className="username">{item?.employee_name}</span>
                                        <small className="timestamp text-muted">
                                          <i className="fa fa-clock-o" />1 secs ago
                                        </small>
                                      </div>
                                      <p>
                                        {item.last_message ? item?.last_message : "Start Chat"}
                                      </p>
                                    </div>
                                  </li>
                                }
                                  {item.group_id &&
                                    <li onClick={() => {

                                      getChat(item.group_id)


                                    }

                                    } className="new">
                                      <span className="avatar available">
                                        <img style={{ width: "40px", height: "40px", borderRadius: "50%", border: "none" }} src={item.employee_image ? img_url + item.employee_image : "https://bootdey.com/img/Content/avatar/avatar1.png"} alt="" className="img-circle" />
                                      </span>
                                      <div className="body">
                                        <div className="header">
                                          <span className="username">{JSON.parse(item?.group_name)}</span>
                                          <small className="timestamp text-muted">
                                            <i className="fa fa-clock-o" />1 secs ago
                                          </small>
                                        </div>
                                        <p>
                                          {item.last_message ? item?.last_message : "Start Chat"}
                                        </p>
                                      </div>
                                    </li>
                                  }
                                </>

                              )
                            })}


                          </ul>
                        </div><div className="slimScrollBar" style={{ width: '7px', position: 'absolute', top: '0px', opacity: '0.4', display: 'none', borderRadius: '7px', zIndex: 99, right: '1px', height: '478.639px', background: 'rgb(0, 0, 0)' }} /><div className="slimScrollRail" style={{ width: '7px', height: '100%', position: 'absolute', top: '0px', display: 'none', borderRadius: '7px', opacity: '0.2', zIndex: 90, right: '1px', background: 'rgb(51, 51, 51)' }} /></div>
                    </div>
                  </div>
                  {userData ?
                    <div style={{ padding: "0", background: "white" }} className="col-md-9">
                      <div style={{ height: "10vh", borderBottom: "1px solid lightgray" }}>
                        <div className="compose-box">
                          <div className="row">
                            <div style={{ display: "flex" }} className="col-xs-12 mg-btm-10">
                              <div style={{ width: "80%" }}>
                                <div style={{ display: "flex", alignItems: "center", paddingLeft: "1rem" }} >
                                  <div style={{ height: "50px", width: "50px", borderRadius: "50%", overflow: "hidden" }} >
                                    <img style={{ height: "50px", width: "50px", borderRadius: "50%" }} src={userData.employee_image ? img_url + userData.employee_image : "https://bootdey.com/img/Content/avatar/avatar1.png"} />
                                  </div>
                                  <div style={{ marginLeft: "1rem" }}>
                                    <button onClick={handleDrawerOpen} style={{ border: "none", background: "none" }}>
                                      <h6 style={{ padding: "0", margin: "0", fontWeight: "bold" }}>{userData?.employee_name ? userData?.employee_name : userData?.group_name}</h6>
                                    </button>
                                    <span style={{ fontSize: "11px", marginLeft:"10px",color: userData?.employee_id ? "green" : "black"} }>Online</span>
                                  </div>
                                </div>


                              </div>
                              <div style={{ width: "40px", height: "40px", marginRight: "10px", borderRadius: "50%", padding: "6px", textAlign: "center", background: "rgb(52, 152, 219)" }}>
                                <button style={{ border: "none", background: "none" }}>
                                  <FaPhoneVolume style={{ color: "white" }} />
                                </button>
                              </div>
                              <div style={{ width: "40px", height: "40px", marginRight: "10px", borderRadius: "50%", padding: "6px", textAlign: "center", background: "rgb(52, 152, 219)" }}>
                                <button style={{ border: "none", background: "none" }}>
                                  <IoVideocam style={{ color: "white" }} />
                                </button>

                              </div>
                              {userData?.group_id && <button
                                aria-label="more"
                                id="long-button"
                                aria-controls={open1 ? 'long-menu' : undefined}
                                aria-expanded={open1 ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick1}
                                style={{ background: "none", border: "none", margin: "0 .5rem" }}>
                                <SlOptionsVertical style={{ fontSize: "20px", color: "#2ECC71" }} />
                              </button>}
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl1}
                                open={open1}
                                onClose={handleClose1}
                                PaperProps={{
                                  style: {
                                    width: '20ch',
                                  },
                                }}
                              >

                                <MenuItem onClick={() => leaveGroup({

                                  employee_id: BrID?.employee?.id,
                                  conversation_id: conversationId

                                })}>
                                  Leave Group
                                </MenuItem>

                              </Menu>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="slimScrollDiv" style={{ position: 'relative', overflowY: 'hidden', width: 'auto', height: '66vh' }}>
                          <div className="message-list-wrapper scrollBar" ref={chatWrap} style={{ overflow: 'scroll', width: 'auto', height: '100%' }}>
                            <ul className="message-list">
                              {messageList?.map((item) => {
                                return (
                                  <>
                                    {item?.sender?.id == BrID?.employee?.id ?
                                      <li className="right" style={{ position: "relative" }}>
                                        <span className="username">Kevin Mckoy</span>
                                        <small className="timestamp">
                                          <i className="fa fa-clock-o" />5 mins ago
                                        </small>
                                        <span className="avatar tooltips" data-toggle="tooltip " data-placement="left" data-original-title="Kevin Mckoy">
                                          <img src={item?.sender?.profile ? img_url + item?.sender?.profile : "https://bootdey.com/img/Content/avatar/avatar1.png"} alt="avatar" className="img-circle" />
                                        </span>
                                        <div className="body">

                                          {item.type == "text" ? <>
                                            <a className="dropdown-toggle d-flex align-items-center hidden-arrow" href="#" id="navbarDropdownMenuAvatar" role="button" data-mdb-toggle="dropdown" aria-expanded="false" style={{ background: "none", border: "none", position: "absolute", left: "-30px", top: "0" }}><BiChevronDown style={{ fontSize: "25px", color: "lightgray" }} />
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuAvatar">
                                              <li onClick={() => reply(item)} style={{ margin: "0", padding: "5px 10px" }}>
                                                <a style={{ margin: "0" }} className="dropdown-item" href="#">Reply</a>
                                              </li>
                                              <li onClick={() => deleteMessage(item.id)} style={{ margin: "0", padding: "5px 10px" }}>
                                                <a style={{ margin: "0" }} className="dropdown-item" href="#">Delete</a>
                                              </li>

                                            </ul>
                                          </> :
                                            <>
                                              <a className="dropdown-toggle d-flex align-items-center hidden-arrow" href="#" id="navbarDropdownMenuAvatar" role="button" data-mdb-toggle="dropdown" aria-expanded="false" style={{ background: "none", border: "none", position: "absolute", left: "-30px", top: "0" }}><BiChevronDown style={{ fontSize: "25px", color: "lightgray" }} />
                                              </a>
                                              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuAvatar">
                                                <li onClick={() => reply(item)} style={{ margin: "0", padding: "5px 10px" }}>
                                                  <a style={{ margin: "0" }} className="dropdown-item" href="#">Reply</a>
                                                </li>
                                                <li onClick={() => deleteMessage(item.id)} style={{ margin: "0", padding: "5px 10px" }}>
                                                  <a style={{ margin: "0" }} className="dropdown-item" href="#">Delete</a>
                                                </li>
                                                <li onClick={() => handleDownload(item.body, item.type)} style={{ margin: "0", padding: "5px 10px" }}>
                                                  <a style={{ margin: "0" }} className="dropdown-item" download>Download</a>
                                                </li>

                                              </ul>
                                            </>
                                          }
                                          {item.parent_message &&
                                            <div style={{ display: "block", width: "200px", borderBottom: "1px solid lightgray" }} className="message well well-sm">
                                              {item.type == "text" &&
                                                <span style={{ fontWeight: "bold" }}>{item?.parent_message?.body}</span>
                                              }
                                              {item.type == "image" &&
                                                <img src={img_url + "storage/" + item?.parent_message?.body} />

                                              }
                                              {item.type == "voice_note" &&

                                                <audio src={img_url + "storage/" + item?.parent_message?.body} controls />
                                                //  <ReactPlayer url={img_url+"storage/"+item.body} controls playing />
                                                // <audio controls src=/>


                                              }

                                            </div>}
                                          <div className="message well well-sm">
                                            {item.type == "text" &&
                                              <span>{item.body}</span>

                                            }
                                            {item.type == "image" &&
                                              <img src={img_url + "storage/" + item.body} />

                                            }
                                            {item.type == "voice_note" &&

                                              <audio src={img_url + "storage/" + item.body} controls download />
                                              //  <ReactPlayer url={img_url+"storage/"+item.body} controls playing />
                                              // <audio controls src=/>


                                            }

                                          </div>

                                        </div>
                                      </li> :
                                      <li className="left">
                                        <span className="username">Yanique Robinson</span>
                                        <small className="timestamp">
                                          <i className="fa fa-clock-o" />9 mins ago
                                        </small>
                                        <span className="avatar available tooltips" data-toggle="tooltip " data-placement="right" data-original-title="Yanique Robinson">
                                          <img src={item?.sender?.profile ? img_url + item?.sender?.profile : "https://bootdey.com/img/Content/avatar/avatar1.png"} alt="avatar" className="img-circle" />
                                        </span>
                                        <div className="body">
                                          {item.type == "text" ? <>
                                            <a className="dropdown-toggle d-flex align-items-center hidden-arrow" href="#" id="navbarDropdownMenuAvatar" role="button" data-mdb-toggle="dropdown" aria-expanded="false" style={{ background: "none", border: "none", position: "absolute", right: "-30px", top: "0" }}><BiChevronDown style={{ fontSize: "25px", color: "lightgray" }} />
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuAvatar">
                                              <li onClick={() => reply(item)} style={{ margin: "0", padding: "5px 10px" }}>
                                                <a style={{ margin: "0" }} className="dropdown-item" href="#">Reply</a>
                                              </li>


                                            </ul>
                                          </> :
                                            <>
                                              <a className="dropdown-toggle d-flex align-items-center hidden-arrow" href="#" id="navbarDropdownMenuAvatar" role="button" data-mdb-toggle="dropdown" aria-expanded="false" style={{ background: "none", border: "none", position: "absolute", right: "-30px", top: "0" }}><BiChevronDown style={{ fontSize: "25px", color: "lightgray" }} />
                                              </a>
                                              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuAvatar">
                                                <li onClick={() => reply(item)} style={{ margin: "0", padding: "5px 10px" }}>
                                                  <a style={{ margin: "0" }} className="dropdown-item" href="#">Reply</a>
                                                </li>
                                                <li style={{ margin: "0", padding: "5px 10px" }}>
                                                  <a href={img_url + "storage/" + item.body} style={{ margin: "0" }} className="dropdown-item" download>Download</a>

                                                  {/* <a  href="#">Download</a> */}
                                                </li>

                                              </ul>
                                            </>
                                          }

                                          <div className="message well well-sm">
                                            {item.type == "text" &&
                                              <span style={{color:"black"}}>{item.body}</span>

                                            }
                                            {item.type == "image" &&
                                              <img src={img_url + "storage/" + item.body} />

                                            }
                                            {item.type == "voice_note" &&
                                              <audio src={img_url + "storage/" + item.body} controls download />
                                              // <audio controls src=/>


                                            }
                                          </div>
                                        </div>
                                      </li>}


                                  </>
                                )
                              })}



                            </ul>
                          </div>
                          <div className="slimScrollBar" style={{ width: '7px', position: 'absolute', top: '265px', opacity: '0.4', display: 'none', borderRadius: '7px', zIndex: 99, right: '1px', height: '187.092px', background: 'rgb(0, 0, 0)' }} />
                          <div className="slimScrollRail" style={{ width: '7px', height: '100%', position: 'absolute', top: '0px', display: 'none', borderRadius: '7px', opacity: '0.2', zIndex: 90, right: '1px', background: 'rgb(51, 51, 51)' }} />
                        </div>
                        {messagesReply &&
                          <div style={{ border: "5px solid ligthgray", background: "#2aff0e71", padding: "1rem", position: "absolute", bottom: "20vh", right: "18%", width: "45% ", borderRadius: "5px" }}>
                            <p style={{ fontWeight: "bold", padding: "0rem", margin: "0rem" }}>{messagesReply?.sender?.name}:</p>
                            <p style={{ padding: "0rem", margin: "0rem" }}>
                              {messagesReply?.body}
                            </p>
                            <p onClick={() => {
                              setmessageReply(null)
                            }} style={{ cursor: "pointer", padding: "0rem", margin: "0rem", position: "absolute", right: "10px", top: "10px" }}>
                              <Close />
                            </p>

                          </div>}
                        <div className="compose-box">
                          <div className="row">
                            <div style={{ display: "flex" }} className="col-xs-12 mg-btm-10">
                              <div style={{ width: "80%", position: "relative" }}>

                                <input onChange={(e) => setMessage(e.target.value)} style={{ borderRadius: "50px", width: "100%", paddingLeft: "30px" }} id="btn-input" className="form-control input-sm" placeholder="Type your message here..." value={message} />
                                <div style={{ position: "absolute", right: "0px", top: "1px" }}>
                                  <label htmlFor='chat_file' style={{ background: "none", border: "none", margin: ".5rem", cursor: "pointer" }}>
                                    <MdAttachFile style={{ fontSize: "20px", color: "#2ECC71" }} />
                                  </label>
                                  <input type='file' onChange={handleFileChange} style={{ display: "none" }} id='chat_file' />
                                </div>
                                <div style={{ position: "absolute", left: "0px", top: "6px" }}>
                                  {recording ? <button onClick={stopRecording} style={{ background: "none", border: "none", margin: "0 .5rem" }}>
                                    <BiSolidMicrophone style={{ fontSize: "20px", color: "#2ECC71" }} />
                                  </button> :
                                    <button onClick={startRecording} style={{ background: "none", border: "none", margin: "0 .5rem" }}>
                                      <CiMicrophoneOn style={{ fontSize: "20px", color: "#2ECC71" }} />
                                    </button>
                                  }
                                </div>
                              </div>
                              

                              <button onClick={() => {
                                if (messagesReply) {
                                  sendMessage({
                                    body: message,
                                    conversation_id: conversationId,
                                    messageable_id: BrID?.employee?.id,
                                    parent_message_id: messagesReply.id,


                                  })
                                }
                                else {
                                  handleSend({
                                    body: message,
                                    conversation_id: conversationId,
                                    messageable_id: BrID?.employee?.id,

                                  })
                                }
                              }} className="btn-sm pull-right"
                                style={{ width: "40px", height: "40px", borderRadius: "50%", border: "none", padding: "6px", background: "rgb(52, 152, 219)", textAlign: "center", marginLeft: "10px" }}
                              >
                                <i className="fa fa-location-arrow" style={{ color: "white" }} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> : <div style={{ height: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <h3 style={{ color: "red" }}>select conversation For Chat</h3>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='chat'>
            <div className='row chat-wrapper'>

              <div style={{ padding: "0" }} className="col-12">
                <div>
                  <div className="slimScrollDiv" style={{ position: 'relative', overflow: 'hidden', width: 'auto', height: '80vh' }}>
                    <div className="chat-list-wrapper" style={{ overflowY: 'auto', width: 'auto', height: '70vh' }}>
                      <img style={{ width: "100%" }} src={file && file} />
                    </div>
                    <div className="slimScrollBar" style={{ width: '7px', position: 'absolute', top: '0px', opacity: '0.4', display: 'none', borderRadius: '7px', zIndex: 99, right: '1px', height: '478.639px', background: 'rgb(0, 0, 0)' }} />
                    <div className="slimScrollRail" style={{ width: '7px', height: '100%', position: 'absolute', top: '0px', display: 'none', borderRadius: '7px', opacity: '0.2', zIndex: 90, right: '1px', background: 'rgb(51, 51, 51)' }} />
                    </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "end", padding: "1rem", borderTop: "1px solid lightgray" }}>
                <button style={{ padding: ".5rem 1rem", border: "none", background: "#2ecc71", color: "white", borderRadius: "5px", marginLeft: ".5rem" }} onClick={sendfile}>Create</button>
                <button style={{ padding: ".5rem 1rem", border: "none", background: "#2ecc71", color: "white", borderRadius: "5px", marginLeft: ".5rem" }} onClick={handleClose3}>Cancel</button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Drawer className="chatdrawer" anchor="right" open={openDrawer} onClose={handleDrawerClose}>
        <div
          className="filterparent"
          style={{
            background: "white",
            padding: "18px",
            zIndex: "99",
            display: "felx",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <div
            className="fpchild1"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >

            <button
              onClick={handleDrawerClose}
              style={{ background: "none", border: "none" }}
            >
              <img alt="" src={cross} />
            </button>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div  >
              <img style={{ height: "150px", width: "150px", borderRadius: "50%" }} src={userData?.employee_image ? img_url + userData?.employee_image : "https://bootdey.com/img/Content/avatar/avatar1.png"} />
            </div>
          </div>
          <div style={{textAlign:"center",marginTop:"10px"}}>
            <h5>{userData?.employee_name}</h5>
            <h5>Designation</h5>
          </div>

          <div style={{display:"flex",flexDirection:"column",gap:"10px",marginTop:"15px"}}>
            <span style={{fontFamily:"Open Sans"}}> <IoLocationSharp style={{marginRight:"5px"}} /> {userData?.employeeDetails?.permanent_address}</span>
            <span style={{fontFamily:"Open Sans"}}> <FaPhoneVolume style={{marginRight:"10px"}}  />{userData?.employeeDetails?.phone}</span>
            <span style={{fontFamily:"Open Sans"}}> <MdEmail style={{marginRight:"10px"}}  />{userData?.employeeDetails?.email}</span>

          </div>
          </div>
      </Drawer>
    </div>
  );
};

export default ChatApp;
