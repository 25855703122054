import React, { useEffect, useRef, useState } from 'react'
import JoditEditor from 'jodit-react'
import { useData } from '../../ContextProvider/Context';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { debounce } from 'lodash';
import { CiRead } from 'react-icons/ci';
function CompanyPolise() {
    const { base_url, handleClick, setSnackbarValue, emailTemplate, getCompanyPlcy, compnayPlcy } = useData()
    useEffect(() => {
        getCompanyPlcy()
    }, [])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const editorRef = useRef(null);
    const [contract, setContract] = useState({ description: "", title: "" })

    // const handleContentChange = (e) => {
    //     setContract({ ...contract, description: e });
    // }
    // Debounce the handleContentChange function
    const debouncedHandleContentChange = debounce((content) => {
        setContract({ ...contract, description: content });
    }, 1000); 

    const handleContentChange = (e) => {
        debouncedHandleContentChange(e);
    };
    function createMarkup(content) {
        return { __html: content };
    }
    const openDrawer = () => {
        setIsDrawerOpen(true);
    };
    const isFormValid = () => {
        for (const key in contract) {
            if (contract[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = async () => {
        if(contract?.description&&contract?.title){

            if (contract?.id) {
                fetch(`${base_url}company_policy`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(contract)
                })
                    .then((res) => res.json())
                    .then((data) => {
                        handleClick();
                        setSnackbarValue({ message: "Policy Created", color: "#2ECC71" });
                        getCompanyPlcy()
                        setContract({ description: "", title: "" })
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            } else if (contract?.title !== "Hired" && contract?.title !== "Suspended") {
                fetch(`${base_url}company_policy`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(contract)
                })
                    .then((res) => res.json())
                    .then((data) => {
                        handleClick();
                        setSnackbarValue({ message: "Policy Created", color: "#2ECC71" });
                        getCompanyPlcy()
                        setContract({ description: "", title: "" })
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }
            else if (contract?.title == "Hired") {
                handleClick();
                setSnackbarValue({ message: "Hired Policy is already Created", color: "#DC4C64" });
                setContract({ description: "", title: "" })
    
            }
            else {
                handleClick();
                setSnackbarValue({ message: "Suspended Policy is already Created", color: "#DC4C64" });
                setContract({ description: "", title: "" })
    
            }
        }
        else{
            handleClick();
            setSnackbarValue({ message: "Title or Description is Req", color: "#DC4C64" });
            setContract({ description: "", title: "" })
        }

    }
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                <div style={{width:"25%"}}>
                    <nav aria-label="breadcrumb">
                        <form style={{display:"flex",flexDirection:"column",justifyContent:"center",gap:"10px"}} >
                            <label><span>Policy Title</span></label>
                            <input type='text' style={{ border: "1px solid lightgray", padding: "12px", marginRight: "1rem",borderRadius:"10px",marginBottom:"20px" }} disabled={contract?.title == "Hired" || contract?.title == "Suspended" ? true : false} value={contract?.title} onChange={(e) => setContract({ ...contract, title: e.target.value })} placeholder='Name' />
                        </form>
                    </nav>
                </div>
                <div className='d-flex justify-content-end mb-2 gap-2'>
                    <div>
                       
                        {/* <button
                            onClick={openDrawer}
                            className='btn btn-primary cursorPointer mx-1'
                            style={{ padding: ".5rem 1rem" }}
                        >
                            <span style={{ fontWeight: "bold", cursor: "pointer" }}><CiRead style={{ fontSize: "16px" }} /></span>
                        </button> */}
                        <button className='btn btn-primary' onClick={handleSubmit} style={{ padding: ".5rem 1rem" }}>Add</button>
                    </div>
                </div>
            </div>
            <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={() => setIsDrawerOpen(false)}
                    onKeyDown={() => setIsDrawerOpen(false)}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "10px"
                    }}
                    className="scrollBar"
                >
                    <h3 style={{ fontWeight: "bold", margin: "1rem 0", padding: "1rem", fontSize: "17px" }}>See Policies</h3>

                    {compnayPlcy?.data?.map((item, i) => (
                        <div key={item.id} style={{ width: "100%", padding: "1rem" }}>
                            <h6>{i + 1}</h6>
                            <div onClick={() => setContract(item)} className='card p-3 w-100' style={{ cursor: "pointer" }}>
                                <div dangerouslySetInnerHTML={createMarkup(item.description?.slice(0, 100))} />
                                {/* {item?.description?.lenght>300&&<p>....</p>} */}
                                <p className="mt-3 text-center" style={{ fontWeight: "bold" }}>{item.title}</p>
                            </div>
                        </div>
                    ))}
                </Box>
            </Drawer>
            
            <div className='card'>
                <div className='card-body'>
                    <JoditEditor
                        ref={editorRef}
                        value={contract?.description}
                        onChange={handleContentChange}
                    />
                </div>
            </div>
        </>
    )
}

export default CompanyPolise