import React, { useEffect, useRef, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa6";
import { useNavigate, useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';
import { useReactToPrint } from 'react-to-print'
function ShowYearlyBudget() {
    const params = useParams()
    const navigate = useNavigate()
    const { getYearlyBudgetIndi, yearlyBudgetIndi, updateYearlyBudget, getClientData, clientData, company, getCompnayCrud, storeBudgetMontly, getBudgetAcData, budgetAcData, storeBudgetYearly } = useData()
    useEffect(() => {
        getBudgetAcData()
        getCompnayCrud()
        getClientData()
        getYearlyBudgetIndi(params.id)
    }, [])
    useEffect(() => {
        setYearlyBudget({
            id: yearlyBudgetIndi?.yearly?.id,
            company_id: yearlyBudgetIndi?.yearly?.company_id,
            name: yearlyBudgetIndi?.yearly?.name,
            finencial_year: yearlyBudgetIndi?.yearly?.finencial_year,
            interval: 'Yearly',
            pre_fill: yearlyBudgetIndi?.yearly?.pre_fill,
            subdivided: yearlyBudgetIndi?.yearly?.subdivided,
            total_amount: parseInt(yearlyBudgetIndi?.yearly?.total_amount),
            client_id: yearlyBudgetIndi?.yearly?.client_id,
            yearly_budget: {
                income: yearlyBudgetIndi?.yearly?.income?.map((item) => ({
                    account_id: item.account_id,
                    jan_to_dec: item.jan_to_dec,
                    total_price: item.total_price,
                })),
                other_income: yearlyBudgetIndi?.yearly?.other_income?.map((item) => ({
                    account_id: item.account_id,
                    jan_to_dec: item.jan_to_dec,
                    total_price: item.total_price,
                })),
                cost_of_sale: yearlyBudgetIndi?.yearly?.cost_of_sale?.map((item) => ({
                    account_id: item.account_id,
                    jan_to_dec: item.jan_to_dec,
                    total_price: item.total_price,
                })),
                expenses: yearlyBudgetIndi?.yearly?.expenses?.map((item) => ({
                    account_id: item.account_id,
                    jan_to_dec: item.jan_to_dec,
                    total_price: item.total_price,
                })),
                other_expenses: yearlyBudgetIndi?.yearly?.other_expenses?.map((item) => ({
                    account_id: item.account_id,
                    jan_to_dec: item.jan_to_dec,
                    total_price: item.total_price,
                })),
            }
        })
    }, [yearlyBudgetIndi])
    const [showNestedTables, setShowNestedTables] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
    });

    const toggleNestedTable = (rowNumber) => {
        setShowNestedTables((prev) => ({
            ...prev,
            [rowNumber]: !prev[rowNumber],
        }));
    };

    const [yearlyBudget, setYearlyBudget] = useState({
        id: params?.id,
        company_id: '',
        name: '',
        finencial_year: '',
        interval: 'Yearly',
        pre_fill: '',
        subdivided: '',
        total_amount: '',
        client_id: '',
        yearly_budget: {
            income: budgetAcData?.income?.map((item) => ({
                account_id: item.id,
                jan_to_dec: '',
                total_price: 0,
            })),
            other_income: budgetAcData?.other_income?.map((item) => ({
                account_id: item.id,
                jan_to_dec: '',
                total_price: '',
            })),
            cost_of_sale: budgetAcData?.cost_of_sale?.map((item) => ({
                account_id: item.id,
                jan_to_dec: '',
                total_price: '',
            })),
            expenses: budgetAcData?.expenses?.map((item) => ({
                account_id: item.id,
                jan_to_dec: '',
                total_price: '',
            })),
            other_expenses: budgetAcData?.other_expenses?.map((item) => ({
                account_id: item.id,
                jan_to_dec: '',
                total_price: '',
            })),
        }
    });
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalIncome1, setTotalIncome1] = useState(0);
    const [totalIncome2, setTotalIncome2] = useState(0);
    const [totalIncome3, setTotalIncome3] = useState(0);
    const [totalIncome4, setTotalIncome4] = useState(0);

    useEffect(() => {
        const calculatedTotalIncome = yearlyBudget?.yearly_budget?.income?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_dec || 0),
            0
        )
        setTotalIncome(() => calculatedTotalIncome);

        const calculatedTotalIncome1 = yearlyBudget?.yearly_budget?.other_income?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_dec || 0),
            0
        )
        setTotalIncome1(() => calculatedTotalIncome1);

        const calculatedTotalIncome2 = yearlyBudget?.yearly_budget?.expenses?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_dec || 0),
            0
        )
        setTotalIncome2(() => calculatedTotalIncome2);

        const calculatedTotalIncome3 = yearlyBudget?.yearly_budget?.other_expenses?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_dec || 0),
            0
        )
        setTotalIncome3(() => calculatedTotalIncome3);

        const calculatedTotalIncome4 = yearlyBudget?.yearly_budget?.cost_of_sale?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_dec || 0),
            0
        )
        setTotalIncome4(() => calculatedTotalIncome4);

    }, [yearlyBudget?.yearly_budget?.income, yearlyBudget?.yearly_budget?.other_income, yearlyBudget?.yearly_budget?.expenses, yearlyBudget?.yearly_budget?.other_expenses, yearlyBudget?.yearly_budget?.cost_of_sale]);
    useEffect(() => {
        const totalAmountOfYears = parseInt(totalIncome) + parseInt(totalIncome1) + parseInt(totalIncome2) + parseInt(totalIncome3) + parseInt(totalIncome4)
        setYearlyBudget({ ...yearlyBudget, total_amount: totalAmountOfYears })
    }, [totalIncome, totalIncome1, totalIncome2, totalIncome3, totalIncome4])

    const handleDownloadCsv = () => {
        const csvData = [];
        const companyNameRow = [
            'Budget Overview:',
            `${yearlyBudgetIndi?.yearly?.name}`,
        ];
        const companyNameRow1 = [
            'Financial Year:',
            `${yearlyBudgetIndi?.yearly?.finencial_year}`,
        ];
        const companyNameRow2 = [
            `Total Amount during ${yearlyBudgetIndi?.yearly?.finencial_year?.split("(")[0]}:`,
            `Rs${yearlyBudgetIndi?.yearly?.total_amount?.toLocaleString()}`,
        ];
        const headerRow = [
            'Accounts',
            'January to December',
            'Total',
        ];

        const newLine1 = Array(headerRow.length).fill('');

        csvData.push(companyNameRow);
        csvData.push(companyNameRow1);
        csvData.push(companyNameRow2);
        csvData.push(newLine1);

        csvData.push(headerRow);
        const incomeRows = yearlyBudgetIndi?.yearly?.income.map((item) => {
            const row = [item.account?.name || ''];
            row.push(item.jan_to_dec);
            row.push(`PRs${item.total_price ? item.total_price : 0}`);
            return row;
        });


        const otherIncomeRows = yearlyBudgetIndi?.yearly?.other_income?.map((item) => {
            const row = [item.account?.name || ''];
            row.push(item.jan_to_dec);
            row.push(`PRs${item.total_price ? item.total_price : 0}`);
            return row;
        });

        const expenses = yearlyBudgetIndi?.yearly?.expenses?.map((item) => {
            const row = [item.account?.name || ''];
            row.push(item.jan_to_dec);
            row.push(`PRs${item.total_price ? item.total_price : 0}`);
            return row;
        });

        const otherExpensesRows = yearlyBudgetIndi?.yearly?.other_expenses?.map((item) => {
            const row = [item.account?.name || ''];
            row.push(item.jan_to_dec);
            row.push(`PRs${item.total_price ? item.total_price : 0}`);
            return row;
        });

        const costOfSales = yearlyBudgetIndi?.yearly?.cost_of_sale?.map((item) => {
            const row = [item.account?.name || ''];
            row.push(item.jan_to_dec);
            row.push(`PRs${item.total_price ? item.total_price : 0}`);
            return row;
        });
        const grossProfitRow = ['Gross Profit'];
        const grossProfitAmount = incomeRows.reduce((total, row) => total + parseFloat(row[1] || 0), 0) -
            costOfSales.reduce((total, row) => total + parseFloat(row[1] || 0), 0);
        grossProfitRow.push(grossProfitAmount.toFixed(2));
        grossProfitRow.push(`PRs${grossProfitAmount.toFixed(2)}`);

        const totalExpensesRow = ['Total Expenses'];
        const totalExpensesAmount = expenses.reduce((total, row) => total + parseFloat(row[1] || 0), 0);
        totalExpensesRow.push(totalExpensesAmount.toFixed(2));
        totalExpensesRow.push(`PRs${totalExpensesAmount.toFixed(2)}`);

        const netOperatingIncomeRow = ['Net Operating Income'];
        const netOperatingIncomeAmount = grossProfitAmount - totalExpensesAmount;
        netOperatingIncomeRow.push(netOperatingIncomeAmount.toFixed(2));
        netOperatingIncomeRow.push(`PRs${netOperatingIncomeAmount.toFixed(2)}`);

        const totalOtherIncomeRow = ['Total Other Income'];
        const totalOtherIncomeAmount = otherIncomeRows.reduce((total, row) => total + parseFloat(row[1] || 0), 0);
        totalOtherIncomeRow.push(totalOtherIncomeAmount.toFixed(2));
        totalOtherIncomeRow.push(`PRs${totalOtherIncomeAmount.toFixed(2)}`);

        const totalOtherExpensesRow = ['Total Other Expenses'];
        const totalOtherExpensesAmount = otherExpensesRows.reduce((total, row) => total + parseFloat(row[1] || 0), 0);
        totalOtherExpensesRow.push(totalOtherExpensesAmount.toFixed(2));
        totalOtherExpensesRow.push(`PRs${totalOtherExpensesAmount.toFixed(2)}`);

        const netOtherIncomeRow = ['Net Other Income'];
        const netOtherIncomeAmount = totalOtherIncomeAmount - totalOtherExpensesAmount;
        netOtherIncomeRow.push(netOtherIncomeAmount.toFixed(2));
        netOtherIncomeRow.push(`PRs${netOtherIncomeAmount.toFixed(2)}`);

        const netIncomeRow = ['Net Income'];
        const netIncomeAmount = netOperatingIncomeAmount + netOtherIncomeAmount;
        netIncomeRow.push(netIncomeAmount.toFixed(2));
        netIncomeRow.push(`PRs${netIncomeAmount.toFixed(2)}`);

        csvData.push(['Income'], ...incomeRows);
        csvData.push(newLine1);
        csvData.push(['Cost of Sales'], ...costOfSales);
        csvData.push(newLine1);
        csvData.push(grossProfitRow);
        csvData.push(newLine1);
        csvData.push(['Expenses'], ...expenses);
        csvData.push(totalExpensesRow);
        csvData.push(newLine1);
        csvData.push(netOperatingIncomeRow);
        csvData.push(newLine1);
        csvData.push(['Other Income'], ...otherIncomeRows);
        csvData.push(newLine1);
        csvData.push(['Other Expenses'], ...otherExpensesRows);
        csvData.push(newLine1);
        csvData.push(netOtherIncomeRow);
        csvData.push(newLine1);
        csvData.push(netIncomeRow);
        csvData.push(newLine1)

        const csv = Papa.unparse(csvData, { header: false });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, `${yearlyBudgetIndi?.yearly?.name}-${yearlyBudgetIndi?.yearly?.finencial_year?.split("(")[0]}.csv`);
    };
    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current) {
            handleDownloadPDF();
        }
    }, [cardRef]);
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return;
        }

        setPdfGenerating(true);

        setTimeout(() => {
            const card = cardRef.current;

            const pdfOptions = {
                margin: 0,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };

            if (card) {
                html2pdf()?.from(card)?.set(pdfOptions)?.outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' });

                    // Create a download link
                    const url = URL.createObjectURL(blob);
                    const a = document?.createElement('a');
                    a.href = url;
                    a.download = 'invoice.pdf';
                    a.style.display = 'none';

                    // Append the link to the body and trigger the click
                    document?.body?.appendChild(a);
                    a.click();

                    // Remove the link after the download
                    document?.body?.removeChild(a);

                    setPdfGenerating(false);
                }).catch((error) => {
                    console.error('Error generating PDF:', error);
                    setPdfGenerating(false);
                });
            } else {
                console.error('Card element not found.');
                setPdfGenerating(false);
            }
        }, 0);
    };

    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    });
    const handleClose = () => {
        navigate('/admin/budget');
    }
    return (
        <>
            <div className='createBudgetData'>
                <div className='row' ref={contentRef}>
                    <div className='card'>
                        <div className='card-body table-responsive recruitment' style={{ marginBottom: "2rem" }}>
                            <div className='row mb-3'>
                                <h3 style={{ fontSize: "16px", textAlign: "center" }}>{yearlyBudgetIndi?.yearly?.company?.name}</h3>
                                <h3 style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center" }}>Budget Overview: {yearlyBudgetIndi?.yearly?.name}-{yearlyBudgetIndi?.yearly?.finencial_year?.split("(")[0]}</h3>
                                <h3 style={{ fontSize: "14px", textAlign: "center" }}>{yearlyBudgetIndi?.yearly?.finencial_year}</h3>
                                <h3 style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    color: parseFloat(((totalIncome - totalIncome4) - totalIncome2) + (totalIncome1 - totalIncome3).toString().replace(/,/g, '')) < 0 ? 'red' : 'black'
                                }}>
                                    Net Income: Rs {((totalIncome - totalIncome4) - totalIncome2) + (totalIncome1 - totalIncome3)}
                                </h3>

                            </div>
                            <hr />
                            <table className='table table-hover table-bordered'>
                                <thead style={{ background: "#f3f5f8", color: "black", fontWeight: "bold" }}>
                                    <tr>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Account</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Jan to Dec</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Income</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(1)}>
                                                    {showNestedTables[1] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                        <td />
                                    </tr>
                                    {showNestedTables[1] && (
                                        <>
                                            {budgetAcData?.income?.map((item, index) => {
                                                const monthlyIncome = yearlyBudget?.yearly_budget?.income?.[index] || {};
                                                const totalIncome = ['jan_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>{monthlyIncome?.jan_to_dec || ''}</td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Income</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Cost of Sales</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(5)}>
                                                    {showNestedTables[5] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                        <td />
                                    </tr>
                                    {showNestedTables[5] && (
                                        <>
                                            {budgetAcData?.cost_of_sale?.map((item, index) => {
                                                const monthlyIncome = yearlyBudget?.yearly_budget?.cost_of_sale?.[index] || {};
                                                const totalIncome = ['jan_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>{monthlyIncome?.jan_to_dec || ''}</td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Cost of Sales</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome4}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Gross Profit</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome - totalIncome4}</p>
                                        </td>
                                    </tr>

                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Expance</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(3)}>
                                                    {showNestedTables[3] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                        <td />
                                    </tr>
                                    {showNestedTables[3] && (
                                        <>
                                            {budgetAcData?.expenses?.map((item, index) => {
                                                const monthlyIncome = yearlyBudget?.yearly_budget?.expenses?.[index] || {};
                                                const totalIncome = ['jan_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>{monthlyIncome?.jan_to_dec || ''}</td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Expense</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome2}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Net Operating Income</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{(totalIncome - totalIncome4) - totalIncome2}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Other Income</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(2)}>
                                                    {showNestedTables[2] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                        <td />
                                    </tr>
                                    {showNestedTables[2] && (
                                        <>
                                            {budgetAcData?.other_income?.map((item, index) => {
                                                const monthlyIncome = yearlyBudget?.yearly_budget?.other_income?.[index] || {};
                                                const totalIncome = ['jan_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>{monthlyIncome?.jan_to_dec || ''}</td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Income</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome1}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Other Expance</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(4)}>
                                                    {showNestedTables[4] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                        <td />
                                    </tr>
                                    {showNestedTables[4] && (
                                        <>
                                            {budgetAcData?.other_expenses?.map((item, index) => {
                                                const monthlyIncome = yearlyBudget?.yearly_budget?.other_expenses?.[index] || {};
                                                const totalIncome = ['jan_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>{monthlyIncome?.jan_to_dec || ''}</td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Expense</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome3}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Net Other Income</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome1 - totalIncome3}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Net Income</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{((totalIncome - totalIncome4) - totalIncome2) + (totalIncome1 - totalIncome3)}</p>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div style={{ background: "white", padding: "1rem", position: "fixed", width: "100%", bottom: "0", left: "0", display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <div>
                        <button onClick={handlePrint} className='btn btn-primary'>Save as PDF</button>
                        <button onClick={handleDownloadCsv} className='btn btn-success mx-3'>Save as XLS</button>
                        <button className='btn btn-warning' onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShowYearlyBudget
