import React, { useEffect, useRef, useState } from 'react';
import Template1 from './InvoiceTemplatesF/Template1';
import Template2 from './InvoiceTemplatesF/Template2';
import Template3 from './InvoiceTemplatesF/Template3';
import Template4 from './InvoiceTemplatesF/Template4';
import Template5 from './InvoiceTemplatesF/Template5';
import Template6 from './InvoiceTemplatesF/Template6';
import layout1 from '../Images/Layouts/1.png'
import layout2 from '../Images/Layouts/2.png'
import layout3 from '../Images/Layouts/3.png'
import layout4 from '../Images/Layouts/4.png'
import layout5 from '../Images/Layouts/5.png'
import layout6 from '../Images/Layouts/6.png'
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { useData } from '../ContextProvider/Context';
import { LuImagePlus } from 'react-icons/lu';
import html2pdf from 'html2pdf.js'
import { useReactToPrint } from 'react-to-print'
function InoiceBuilderForSales() {
    const params = useParams()

    const { img_url, getSalesDetailIndiData, sale_receiptDetailIndiData, editCompany } = useData()
    useEffect(() => {
        getSalesDetailIndiData(params.id)
    }, [])
    const isSalesReceipt = window.location.pathname.includes('salesrecipt');
    console.log(isSalesReceipt)
    const [obj, setObj] = useState({
        id: '',
        stamp: '',
        name: '',
        address: '',
        phone: '',
        email: '',
        logo: '',
        clientName: '',
        clientPhone: '',
        clientAddress: '',
        vat: '',
        invoice_date: '',
        invoice_no: '',
        term: '',
        due_date: '',
        payment_method: '',
        date: '',
        service: [{
            serviceName: '',
            sale_description: '',
            extax: '',
            tax_rate: '',
            quantity: '',
            sales_price: '',
            total_price: '',
            invoice_id: '',
            saletax: ''
        }],
        total_amount: '',

    })
    const Claddress = sale_receiptDetailIndiData?.sale_receipt?.client?.ship_address + ', ' + sale_receiptDetailIndiData?.sale_receipt?.client?.ship_city + ', ' + sale_receiptDetailIndiData?.sale_receipt?.client?.ship_province + ', ' + sale_receiptDetailIndiData?.sale_receipt?.client?.ship_country + ', ' + sale_receiptDetailIndiData?.sale_receipt?.client?.ship_postal

    useEffect(() => {
        const services = sale_receiptDetailIndiData?.sale_receipt?.service.map(item => ({
            serviceName: item.services?.name,
            sale_description: item.services?.sale_description,
            extax: item.extax,
            tax_rate: item.saletax ? item.saletax.rate : '',
            quantity: item.quantity,
            sales_price: item?.services?.sale_price,
            total_price: item.total_price,
            invoice_id: item.invoice?.invoice_date,
            saletax: item?.saletax?.rate
        }));
        setObj({
            id: sale_receiptDetailIndiData?.sale_receipt?.company?.id,
            stamp: sale_receiptDetailIndiData?.sale_receipt?.company?.stamp,
            name: sale_receiptDetailIndiData?.sale_receipt?.company?.name,
            address: sale_receiptDetailIndiData?.sale_receipt?.company?.address,
            phone: sale_receiptDetailIndiData?.sale_receipt?.company?.phone,
            email: sale_receiptDetailIndiData?.sale_receipt?.company?.email,
            logo: sale_receiptDetailIndiData?.sale_receipt?.company?.logo,
            clientName: sale_receiptDetailIndiData?.sale_receipt?.client?.name,
            clientPhone: sale_receiptDetailIndiData?.sale_receipt?.client?.phone,
            clientAddress: Claddress,
            vat: sale_receiptDetailIndiData?.sale_receipt?.company?.vat_registration,
            invoice_date: sale_receiptDetailIndiData?.sale_receipt?.invoice_date,
            invoice_no: sale_receiptDetailIndiData?.sale_receipt?.sale_receipt_no,
            payment_method: sale_receiptDetailIndiData?.sale_receipt?.payment_method,
            term: sale_receiptDetailIndiData?.sale_receipt?.term,
            due_date: sale_receiptDetailIndiData?.sale_receipt?.due_date,
            date: '',
            service: services,
            total_amount: sale_receiptDetailIndiData?.sale_receipt?.total_amount,
        })
    }, [sale_receiptDetailIndiData])
    const [selectedTemplate, setSelectedTemplate] = useState('Template1');
    const [logoPlacement, setLogoPlacement] = useState('left');
    const [stumpPlacement, setStumpPlacement] = useState('left');
    const [selectedFont, setSelectedFont] = useState('arial');
    const [selectedFontSize, setSelectedFontSize] = useState('14');
    const [logoSize, setLogoSize] = useState('medium');
    const [stumpSize, setStumpSize] = useState('medium');
    const [customColor, setCustomColor] = useState('');
    const [showDate, setShowDate] = useState(true);
    const [showActivity, setShowActivity] = useState(true);
    const [showDescription, setShowDescription] = useState(true);
    const [showTax, setShowTax] = useState(true);
    const [showQty, setShowQty] = useState(true);
    const [showRate, setShowRate] = useState(true);
    const [showAmount, setShowAmount] = useState(true);
    const [showTaxSummary, setShowTaxSummary] = useState(true);
    const [showStamp, setShowStamp] = useState(true);
    const templateComponents = {
        Template1,
        Template2,
        Template3,
        Template4,
        Template5,
        Template6,
    };
    const layoutImages = {
        Template1: layout1,
        Template2: layout2,
        Template3: layout3,
        Template4: layout4,
        Template5: layout5,
        Template6: layout6,
    };
    const SelectedTemplateComponent = templateComponents[selectedTemplate];
    const handleTemplateChange = (template) => {
        setSelectedTemplate(template);
    };
    const handleLogoPlacementChange = (placement) => {
        setLogoPlacement(placement);
    };
    const handleStumpPlacementChange = (placement) => {
        setStumpPlacement(placement);
    };
    const handleLogoSizeChange = (size) => {
        setLogoSize(size);
    };
    const handleStumpSizeChange = (size) => {
        setStumpSize(size);
    };
    const getLogoStyle = () => {
        let size = 100;

        if (logoSize === 'small') {
            size = 50;
        } else if (logoSize === 'medium') {
            size = 100;
        } else if (logoSize === 'large') {
            size = 150;
        }

        return {
            height: `${size}px`,
            width: `${size}px`,
            borderRadius: '50%',
            border: '1px solid lightgray',
        };
    };
    const getStumpStyle = () => {
        let size = 100;

        if (stumpSize === 'small') {
            size = 50;
        } else if (stumpSize === 'medium') {
            size = 100;
        } else if (stumpSize === 'large') {
            size = 150;
        }

        return {
            height: `${size}px`,
            width: `${size}px`,
            borderRadius: '50%',
            border: '1px solid lightgray',
        };
    };
    const handleCustomColorChange = (event) => {
        const newColor = event.target.value;
        setCustomColor(newColor);
    };
    const colors = [
        '#000000',
        '#ff0000',
        '#008000',
        '#0000ff',
        '#ffa500',
        '#ffff00',
        '#800080',
        '#00ffff',
        '#ff00ff',
        '#008080',
        '#800000',
        '#808000',
        '#ff4500',
        '#00ff00',
        '#000080',
        '#4b0082',
        '#a52a2a',
        '#dda0dd',
        '#ff69b4',
        '#8a2be2',
        '#deb887',
        '#d2691e',
        '#b22222',
        '#2e8b57',
    ];
    const fonts = [
        'Arial, sans-serif',
        'Helvetica, sans-serif',
        'Georgia, serif',
        'Times New Roman, serif',
        'Courier New, monospace',
        'Verdana, sans-serif',
        'Impact, sans-serif',
        'Comic Sans MS, cursive',
        'Palatino Linotype, Book Antiqua, Palatino, serif',
        'Trebuchet MS, sans-serif',
        'Arial Black, sans-serif',
        'Lucida Console, Monaco, monospace',
        'Garamond, Baskerville, Baskerville Old Face, Hoefler Text, Times, serif',
        'Century Gothic, sans-serif',
        'Bookman Old Style, serif',
        'Franklin Gothic Medium, sans-serif',
        'Copperplate, Copperplate Gothic Light, fantasy',
        'Brush Script MT, cursive',
        'Consolas, monospace',
        'Candara, Calibri, Arial, sans-serif',
        'Arial Narrow, sans-serif',
        'Helvetica Neue, Helvetica, Arial, sans-serif',
        'Futura, sans-serif',
        'Gill Sans, sans-serif',
        'Didot, serif',
        'Bodoni MT, serif',
        'Rockwell, serif',
        'Avant Garde, sans-serif',
        'MS Sans Serif, Geneva, sans-serif',
        'MS Serif, New York, serif',
        'Symbol, sans-serif',
        'Webdings, sans-serif',
        'Wingdings, Zapf Dingbats, sans-serif',
        'Calibri, sans-serif',
        'Cambria, serif',
        'Constantia, serif',
        'Corbel, sans-serif',
        'Droid Sans, sans-serif',
        'Droid Serif, serif',
        'Estrangelo Edessa, sans-serif',
        'Fangsong, sans-serif',
        'Gabriola, cursive',
        'Inconsolata, monospace',
        'Jokerman, fantasy',
        'KaiTi, sans-serif',
        'Leelawadee, sans-serif',
        'Microsoft YaHei, sans-serif',
        'Mongolian Baiti, sans-serif',
    ];
    const fontsSize = [
        '10px',
        '12px',
        '14px',
        '16px',
    ];
    const handleFontChange = (event) => {
        const newFont = event.target.value;
        setSelectedFont(newFont);
    };
    const handleFontSizeChange = (event) => {
        const newFontSize = event.target.value;
        setSelectedFontSize(newFontSize);
    };
    const [selectedLogoFile, setSelectedLogoFile] = useState(null);
    const [selectedStampFile, setSelectedStampFile] = useState(null);

    const handleComUpdate = () => {
        editCompany(obj)
    }
    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedLogoFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setObj({ ...obj, logo: base64String });
            };
            reader.readAsDataURL(file);
        }
    };
    const handleStampChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedStampFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setObj({ ...obj, stamp: base64String });
            };
            reader.readAsDataURL(file);
        }
    };
    const cardRef = useRef(null)
    useEffect(() => {
        if (cardRef.current) {
            handleDownloadPDF()
        }
    }, [cardRef])
    const [pdfGenerating, setPdfGenerating] = useState(false)
    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return
        }

        setPdfGenerating(true)

        setTimeout(() => {
            const card = cardRef.current

            const pdfOptions = {
                margin: 0,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            }

            if (card) {
                html2pdf()?.from(card)?.set(pdfOptions)?.outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' })

                    // Create a download link
                    const url = URL.createObjectURL(blob)
                    const a = document?.createElement('a')
                    a.href = url
                    a.download = 'invoice.pdf'
                    a.style.display = 'none'

                    // Append the link to the body and trigger the click
                    document?.body?.appendChild(a)
                    a.click()

                    // Remove the link after the download
                    document?.body?.removeChild(a)

                    setPdfGenerating(false)
                }).catch((error) => {
                    console.error('Error generating PDF:', error)
                    setPdfGenerating(false)
                })
            } else {
                console.error('Card element not found.')
                setPdfGenerating(false)
            }
        }, 0)
    }

    const contentRef = useRef(null)

    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    })

    return (
        <>
            <div className='builderData' style={{ overflowX: "hidden" }}>
                <div className='row'>
                    <div className='col-md-4 '>
                        <div className='card p-3 scrollBarN' style={{ height: "100%", overflowY: "scroll", borderRadius: "0" }} >
                            <Tabs defaultActiveKey="Design" tabPosition="top">
                                <Tabs.TabPane tab="Design" key="Design">
                                    <div>
                                        <p style={{ fontWeight: "bold", fontSize: "15px", margin: "0" }}>Templates</p>
                                        <div style={{ overflowX: "scroll", width: "100%", display: "flex", gap: "20px" }} className='scrollBarN'>
                                            {Object.keys(templateComponents).map((template) => (
                                                <div
                                                    key={template}
                                                    style={{ height: "100px", width: "100px", border: "1px solid lightgray", cursor: 'pointer' }}
                                                    onClick={() => handleTemplateChange(template)}
                                                >
                                                    <img
                                                        src={layoutImages[template]}
                                                        alt={`Template ${template}`}
                                                        style={{ width: 'auto', height: '100%' }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <div className='my-3'>
                                            <div>
                                                <p style={{ fontWeight: "bold", fontSize: "15px", margin: "0" }}>Logo placement</p>
                                                <div style={{ display: "flex", gap: "10px" }}>
                                                    <div
                                                        style={{ height: "30px", cursor: "pointer", width: "30px", borderRadius: "50%", background: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        onClick={() => handleLogoPlacementChange('right')}
                                                    >
                                                        L
                                                    </div>
                                                    <div
                                                        style={{ height: "30px", cursor: "pointer", width: "30px", borderRadius: "50%", background: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        onClick={() => handleLogoPlacementChange('middle')}
                                                    >
                                                        M
                                                    </div>
                                                    <div
                                                        style={{ height: "30px", cursor: "pointer", width: "30px", borderRadius: "50%", background: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        onClick={() => handleLogoPlacementChange('left')}
                                                    >
                                                        R
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-3'>
                                            <div>
                                                <p style={{ fontWeight: "bold", fontSize: "15px", margin: "0" }}>Logo Size</p>
                                                <div style={{ display: "flex", gap: "10px" }}>
                                                    <div
                                                        style={{ height: "30px", cursor: "pointer", width: "fit-content", padding: "5px", borderRadius: "30px", background: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        onClick={() => handleLogoSizeChange('small')}
                                                    >
                                                        Small
                                                    </div>
                                                    <div
                                                        style={{ height: "30px", cursor: "pointer", width: "fit-content", padding: "5px", borderRadius: "30px", background: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        onClick={() => handleLogoSizeChange('medium')}
                                                    >
                                                        Medium
                                                    </div>
                                                    <div
                                                        style={{ height: "30px", cursor: "pointer", width: "fit-content", padding: "5px", borderRadius: "30px", background: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        onClick={() => handleLogoSizeChange('large')}
                                                    >
                                                        Large
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-3'>
                                            <div>
                                                <p style={{ fontWeight: "bold", fontSize: "15px", margin: "0" }}>Stump placement</p>
                                                <div style={{ display: "flex", gap: "10px" }}>

                                                    <div
                                                        style={{ height: "30px", cursor: "pointer", width: "30px", borderRadius: "50%", background: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        onClick={() => handleStumpPlacementChange('right')}
                                                    >
                                                        L
                                                    </div>
                                                    <div
                                                        style={{ height: "30px", cursor: "pointer", width: "30px", borderRadius: "50%", background: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        onClick={() => handleStumpPlacementChange('middle')}
                                                    >
                                                        M
                                                    </div>
                                                    <div
                                                        style={{ height: "30px", cursor: "pointer", width: "30px", borderRadius: "50%", background: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        onClick={() => handleStumpPlacementChange('left')}
                                                    >
                                                        R
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-3'>
                                            <div>
                                                <p style={{ fontWeight: "bold", fontSize: "15px", margin: "0" }}>Stump Size</p>
                                                <div style={{ display: "flex", gap: "10px" }}>
                                                    <div
                                                        style={{ height: "30px", cursor: "pointer", width: "fit-content", padding: "5px", borderRadius: "30px", background: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        onClick={() => handleStumpSizeChange('small')}
                                                    >
                                                        Small
                                                    </div>
                                                    <div
                                                        style={{ height: "30px", cursor: "pointer", width: "fit-content", padding: "5px", borderRadius: "30px", background: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        onClick={() => handleStumpSizeChange('medium')}
                                                    >
                                                        Medium
                                                    </div>
                                                    <div
                                                        style={{ height: "30px", cursor: "pointer", width: "fit-content", padding: "5px", borderRadius: "30px", background: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                        onClick={() => handleStumpSizeChange('large')}
                                                    >
                                                        Large
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-3'>
                                            <div>
                                                <p style={{ fontWeight: "bold", fontSize: "15px", margin: "0" }}>Try Another color</p>
                                                <div style={{ display: "flex", gap: "5px", alignItems: "center", width: "100%" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "30%" }}>
                                                        <div style={{ height: "80px", borderRadius: "50%", width: "80px", border: "1px solid lightgray", backgroundColor: customColor }}></div>
                                                        <input
                                                            placeholder='Hex Code'
                                                            value={customColor}
                                                            onChange={handleCustomColorChange}
                                                            className='form-control mt-2'
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex", gap: "5px", flexWrap: "wrap", width: "70%" }}>
                                                        {colors?.map((item) => {
                                                            return (
                                                                <div
                                                                    style={{ height: "30px", borderRadius: "50%", width: "30px", backgroundColor: item, cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setCustomColor(item);
                                                                        handleCustomColorChange({ target: { value: item } });
                                                                    }}
                                                                ></div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-3'>
                                            <div>
                                                <p style={{ fontWeight: "bold", fontSize: "15px", margin: "0" }}>Try Another Font</p>
                                                <div style={{ display: "flex", gap: "5px", alignItems: "center", width: "100%" }}>
                                                    <select value={selectedFont} onChange={handleFontChange} className='form-control'>
                                                        <option hidden> Select</option>
                                                        <option value={''}> Select</option>
                                                        {fonts?.map((item) => (
                                                            <option key={item} value={item}>{item}</option>
                                                        ))}
                                                    </select>
                                                    <select value={selectedFontSize} onChange={handleFontSizeChange} className='form-control'>
                                                        <option hidden> Select</option>
                                                        <option value={''}> Select</option>
                                                        {fontsSize?.map((item) => (
                                                            <option key={item} value={item}>{item}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Content" key="Content">
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "end" }}><button className='btn btn-primary' onClick={handleComUpdate}>Update Changes</button></div>
                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                                            <div style={{ border: "none" }} className='row mb-2'>
                                                <label style={{ fontWeight: "bold" }}>Logo</label>
                                                <div className='col-12'>
                                                    <div style={{ border: '2px solid', height: "100px", borderRadius: "50%", width: "100px", position: "relative" }}>
                                                        <label style={{ border: "1px solid", borderRadius: "50%", width: "25px", height: "25px", background: "lightgray", color: "gray", position: "absolute", right: "0%", paddingLeft: ".2rem", paddingBottom: ".2rem" }} htmlFor="companyLogo"><LuImagePlus /></label>
                                                        {selectedLogoFile ? (
                                                            <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} src={URL.createObjectURL(selectedLogoFile)} alt="Company Logo" />
                                                        ) : (
                                                            <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} src={img_url + obj?.logo} alt="Company Logo" />
                                                        )}
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id="companyLogo"
                                                        name="companyLogo"
                                                        accept="image/*"
                                                        style={{ display: "none" }}
                                                        onChange={handleLogoChange}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ border: "none" }} className='row mb-2'>
                                                <label style={{ fontWeight: "bold" }}>Stamp</label>
                                                <div className='col-12'>
                                                    <div style={{ border: '2px solid', height: "100px", borderRadius: "50%", width: "100px", position: "relative" }}>
                                                        <label style={{ border: "1px solid", borderRadius: "50%", width: "25px", height: "25px", background: "lightgray", color: "gray", position: "absolute", right: "0%", paddingLeft: ".2rem", paddingBottom: ".2rem" }} htmlFor="companyStamp"><LuImagePlus /></label>
                                                        {selectedStampFile ? (
                                                            <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} src={URL.createObjectURL(selectedStampFile)} alt="Company Stamp" />
                                                        ) : (
                                                            <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} src={img_url + obj?.stamp} alt="Company Stamp" />
                                                        )}
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id="companyStamp"
                                                        name="companyStamp"
                                                        accept="image/*"
                                                        style={{ display: "none" }}
                                                        onChange={handleStampChange}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className='form-group mb-2'>
                                            <label style={{ fontWeight: "bold" }}>Name</label>
                                            <input className='form-control' type='text' value={obj?.name} onChange={(e) => setObj({ ...obj, name: e.target.value })} placeholder='Company Name' />
                                        </div>
                                        <div className='form-group mb-2'>
                                            <label style={{ fontWeight: "bold" }}>Address</label>
                                            <input className='form-control' type='text' value={obj?.address} onChange={(e) => setObj({ ...obj, address: e.target.value })} placeholder='Company Address' />
                                        </div>
                                        <div className='form-group mb-2'>
                                            <label style={{ fontWeight: "bold" }}>Email</label>
                                            <input className='form-control' type='email' value={obj?.email} onChange={(e) => setObj({ ...obj, email: e.target.value })} placeholder='Company Email' />
                                        </div>
                                        <div className='form-group mb-2'>
                                            <label style={{ fontWeight: "bold" }}>Phone</label>
                                            <input className='form-control' value={obj?.phone} onChange={(e) => setObj({ ...obj, phone: e.target.value })} placeholder='Company Phone' />
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{ fontWeight: "bold" }}>Customize Header</p>
                                        <div>
                                            <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <input type="checkbox" style={{ height: "20px", width: "20px" }} checked={showDate} onChange={() => setShowDate(!showDate)} />
                                                <span>
                                                    Date
                                                </span>
                                            </label> <br />
                                            <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <input type="checkbox" style={{ height: "20px", width: "20px" }} checked={showActivity} onChange={() => setShowActivity(!showActivity)} />
                                                <span>
                                                    Activity
                                                </span>
                                            </label> <br />
                                            <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <input type="checkbox" style={{ height: "20px", width: "20px" }} checked={showDescription} onChange={() => setShowDescription(!showDescription)} />
                                                <span>
                                                    Description
                                                </span>
                                            </label> <br />
                                            <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <input type="checkbox" style={{ height: "20px", width: "20px" }} checked={showTax} onChange={() => setShowTax(!showTax)} />
                                                <span>
                                                    Tax
                                                </span>
                                            </label> <br />
                                            <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <input type="checkbox" style={{ height: "20px", width: "20px" }} checked={showQty} onChange={() => setShowQty(!showQty)} />
                                                <span>
                                                    Qty
                                                </span>
                                            </label> <br />
                                            <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <input type="checkbox" style={{ height: "20px", width: "20px" }} checked={showRate} onChange={() => setShowRate(!showRate)} />
                                                <span>
                                                    Rate
                                                </span>
                                            </label> <br />
                                            <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <input type="checkbox" style={{ height: "20px", width: "20px" }} checked={showAmount} onChange={() => setShowAmount(!showAmount)} />
                                                <span>
                                                    Amount
                                                </span>
                                            </label> <br />
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{ fontWeight: "bold" }}>Customize Footer</p>
                                        <div>
                                            <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <input type="checkbox" style={{ height: "20px", width: "20px" }} checked={showTaxSummary} onChange={() => setShowTaxSummary(!showTaxSummary)} />
                                                <span>
                                                    Tax Summary
                                                </span>
                                            </label> <br />
                                            <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <input type="checkbox" style={{ height: "20px", width: "20px" }} checked={showStamp} onChange={() => setShowStamp(!showStamp)} />
                                                <span>
                                                    Stamp
                                                </span>
                                            </label> <br />
                                            {showStamp && <p style={{ fontSize: "10px" }}>Please note that images and signatures shared outside of your account may be visible and accessible to others, including the general public</p>}
                                        </div>
                                    </div>
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div style={{ display: "flex", justifyContent: "end", marginBottom: "10px" }}>
                            <button
                                type="button"
                                className="btn btn-primary text-capitalize mx-3"
                                style={{ backgroundColor: '#60bdf3' }}
                                onClick={handlePrint}
                            >
                                Download PDF
                            </button>
                        </div>
                        <div ref={contentRef}>
                            <SelectedTemplateComponent logoPlacement={logoPlacement} stumpPlacement={stumpPlacement} invoiceData={obj} getLogoStyle={getLogoStyle} getStumpStyle={getStumpStyle} bgColor={customColor} font={selectedFont} fontSize={selectedFontSize} visibilityOfDate={showDate} visibilityOfActivity={showActivity} visibilityOfDescription={showDescription} visibilityOfTax={showTax} visibilityOfRate={showRate} visibilityOfQty={showQty} visibilityOfAmount={showAmount} visibilityOfTaxSummary={showTaxSummary} visibilityOfStamp={showStamp} />
                        </div>
                    </div>
                </div>
                <div className='row' style={{ border: "1px solid", position: "fixed", bottom: '0', left: "0", width: "100%" }}>
                    <h1>hello</h1>
                </div>
            </div>
        </>
    );
}

export default InoiceBuilderForSales;
