import React, { useEffect, useState } from 'react'
import img from './../Images/announcement.jpg'
import { useParams } from 'react-router-dom'
import { useData } from '../ContextProvider/Context'
function CompanyAnoucment() {
    const [announcement, setAnnouncement] = useState([])
    const {base_url}=useData()
    const parms=useParams()
    useEffect(()=>{
        getAnnouncement(parms?.id)
    },[])
    const getAnnouncement = (id) => {
        fetch(`${base_url}company-announcements/${id}`)
            .then((res) => res.json())
            .then((data) => {
                setAnnouncement(data);
                dateSet(data?.announcement_date)
            });
            
    };
    const [newDate,setdate]=useState({day:"",month:""})
    function createMarkup(content) {
        return { __html: content };
    }
    const dateSet=(date)=>{
        const yourDate = new Date(date); 
        const formattedDate = yourDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          });
        
          // Rearrange the formatted date to match the desired format "yyyy-MMM-dd"
          const [month, day, year] = formattedDate.split(' ');
        //   setdate({...newDate,day:day})
          setdate({day:day,month:month})
        //   const finalFormattedDate = `${year}-${month}-${day}`;
    }
    console.log(announcement);
  return (
    <div style={{backgroundImage:`url(${img})`,height:"80vh",backgroundPosition:"center",backgroundSize:"cover"}} className='card'>
        <div className='card-body'>
            <div style={{display:"flex",justifyContent:"space-between",width:"700px",marginBottom:"1rem",alignItems:"center"}}>
            <h1 className='announcement-head' style={{color:"white"}}>{announcement?.title}</h1>
            <div style={{height:"100px",width:"100px",background:"white",borderRadius:"5px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                <h1>{newDate?.day}</h1>
                <div style={{border:"1px solid #5FCEDF",width:"90%",marginBottom:".3rem",boxShadow:"0 0 20px lightgray"}}></div>
                <h6>{newDate?.month}</h6>
            </div>

            </div>
            <div className='card announcement'>
                <div className='card-body '>
                <div dangerouslySetInnerHTML={createMarkup(announcement?.content)}></div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default CompanyAnoucment