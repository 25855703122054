import React from 'react'
import logo from '../../Images/pngwing.com.png'
import { useData } from '../../ContextProvider/Context';
function Template6({ salesReceipt, fontSize, visibilityOfStamp, logoPlacement, getLogoStyle, stumpPlacement, getStumpStyle, bgColor, font, invoiceData, visibilityOfDate, visibilityOfActivity, visibilityOfAmount, visibilityOfDescription, visibilityOfQty, visibilityOfTax, visibilityOfRate, visibilityOfTaxSummary }) {
    const { img_url } = useData()
    const hexToRgb = (hex) => {
        hex = hex.replace(/^#/, '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
    };
    const textStyle = {
        fontFamily: font,
        fontSize: fontSize,
    };
    const totalExtax = invoiceData?.service?.reduce(
        (acc, item) => acc + parseFloat(item.extax ? item.extax : item.saletax?.rate || 0),
        0
    );

    const totalTax = invoiceData?.total_amount * totalExtax / 100;
    const totalAmountWithTax = parseFloat(invoiceData?.total_amount) + totalTax;
    return (
        <>
            <div className='TemplateData'>
                <div style={{ background: "white", padding: "1rem" }}>
                    <div style={{ border: "1px solid", padding: "2rem" }}>
                        <div className='row'>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <h3 style={{ fontWeight: "bold", ...textStyle, fontSize: "1.2rem", color: bgColor ? bgColor : '#000' }}>Tax Invoice</h3>
                            </div>
                            <div className='header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {logoPlacement === 'left' && (
                                    <>
                                        <div className='details'>
                                            <div>
                                                <h3 style={{ fontWeight: 'bold', ...textStyle }}>{invoiceData?.name}</h3>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.address}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.phone}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.email}</p>
                                            </div>
                                        </div>
                                        <div className='logo'>
                                            <div style={getLogoStyle()}>
                                                <img alt='' src={img_url + invoiceData?.logo} style={getLogoStyle()} />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {logoPlacement === 'right' && (
                                    <>
                                        <div className='logo'>
                                            <div style={getLogoStyle()}>
                                                <img alt='' src={img_url + invoiceData?.logo} style={getLogoStyle()} />
                                            </div>
                                        </div>
                                        <div className='details'>
                                            <div>
                                                <h3 style={{ fontWeight: 'bold', ...textStyle }}>{invoiceData?.name}</h3>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.address}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.phone}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.email}</p>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {logoPlacement === 'middle' && (
                                    <>
                                        <div className='details' style={{ textAlign: 'left' }}>
                                            <div>
                                                <h3 style={{ fontWeight: 'bold', ...textStyle }}>{invoiceData?.name}</h3>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.address}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.phone}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', ...textStyle }}>{invoiceData?.email}</p>
                                            </div>
                                        </div>
                                        <div className='logo' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <div style={{ height: '100px', width: '100px' }}>
                                                <img
                                                    alt=''
                                                    src={img_url + invoiceData?.logo}
                                                    style={{ height: '100%', width: '100%', borderRadius: '50%', border: '1px solid lightgray' }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='row mt-5 mb-3'>
                            <div className='col-md-6 p-0' style={{ borderTop: bgColor ? `3px solid ${bgColor}` : '3px solid #000' }}>
                                <div className='details'>
                                    <div>
                                        <p style={{ margin: "0", ...textStyle, fontWeight: "bold", ...textStyle }}> BILL TO</p>
                                        <p style={{ margin: "0", ...textStyle }}>{invoiceData?.clientName}</p>
                                        <p style={{ margin: "0", ...textStyle }}>{invoiceData?.clientPhone}</p>
                                        <p style={{ margin: "0", ...textStyle }}>{invoiceData?.clientAddress}</p>
                                        <p style={{ margin: "0", ...textStyle }}>{invoiceData?.vat}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 p-0' style={{ borderTop: bgColor ? `3px solid ${bgColor}` : '3px solid #000' }}>
                                <div style={{ display: "flex", width: "100%" }}>
                                    <div style={{ width: "33%", height: "130px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", background: bgColor ? `rgba(${hexToRgb(bgColor)}, 0.3)` : '#ddd', padding: "1rem" }}>
                                        <p style={{ color: bgColor ? bgColor : "#000", margin: "0", ...textStyle }}>DATE</p>
                                        <p style={{ color: bgColor ? bgColor : "#000", fontWeight: "bold", ...textStyle, margin: "0", ...textStyle }}>{invoiceData?.invoice_date}</p>
                                    </div>
                                    <div style={{ width: "34%", height: "130px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", background: bgColor ? bgColor : '#000', padding: "1rem" }}>
                                        <p style={{ color: '#fff', margin: "0", ...textStyle }}>PLEASE PAY</p>
                                        <p style={{ color: '#fff', fontWeight: "bold", ...textStyle, margin: "0", ...textStyle }}>PRs {totalAmountWithTax.toFixed(2)}</p>
                                    </div>
                                    {salesReceipt && (
                                        <div style={{ width: "33%", height: "130px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", background: bgColor ? `rgba(${hexToRgb(bgColor)}, 0.3)` : '#ddd', padding: "1rem" }}>
                                            <p style={{ color: bgColor ? bgColor : "#000", margin: "0", ...textStyle }}>Payment</p>
                                            <p style={{ color: bgColor ? bgColor : "#000", fontWeight: "bold", ...textStyle, margin: "0", ...textStyle }}>{invoiceData?.payment_method}</p>
                                        </div>
                                    )}
                                    {!salesReceipt && (
                                        <div style={{ width: "33%", height: "130px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", background: bgColor ? `rgba(${hexToRgb(bgColor)}, 0.3)` : '#ddd', padding: "1rem" }}>
                                            <p style={{ color: bgColor ? bgColor : "#000", margin: "0", ...textStyle }}>DUE DATE</p>
                                            <p style={{ color: bgColor ? bgColor : "#000", fontWeight: "bold", ...textStyle, margin: "0", ...textStyle }}>{invoiceData?.due_date}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <table className="table" style={{ border: "0px" }}>
                                <thead style={{ background: "#fff" }}>
                                    <tr>
                                        {visibilityOfDate && <th scope="col" style={{ border: '0px solid #fff', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '8px', fontWeight: "bold", ...textStyle, color: "black" }}>DATE</th>}
                                        {visibilityOfActivity && <th scope="col" style={{ border: '0px solid #fff', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '8px', fontWeight: "bold", ...textStyle, color: "black" }}>ACTIVITY</th>}
                                        {visibilityOfDescription && <th scope="col" style={{ border: '0px solid #fff', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '8px', fontWeight: "bold", ...textStyle, color: "black" }}>DESCRIPTION</th>}
                                        {visibilityOfTax && <th scope="col" style={{ border: '0px solid #fff', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '8px', fontWeight: "bold", ...textStyle, color: "black" }}>TAX</th>}
                                        {visibilityOfRate && <th scope="col" style={{ border: '0px solid #fff', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '8px', fontWeight: "bold", ...textStyle, color: "black" }}>QTY</th>}
                                        {visibilityOfQty && <th scope="col" style={{ border: '0px solid #fff', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '8px', fontWeight: "bold", ...textStyle, color: "black" }}>RATE</th>}
                                        {visibilityOfAmount && <th scope="col" style={{ border: '0px solid #fff', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '8px', fontWeight: "bold", ...textStyle, color: "black" }}>AMOUNT</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoiceData?.service?.map((item) => {
                                        return (
                                            <tr key={item.id} syle={{ border: "0" }}>
                                                {visibilityOfDate && <td style={{ border: "0", padding: '8px', ...textStyle }}>{item.invoice_id}</td>}
                                                {visibilityOfActivity && <td style={{ border: "0", padding: '8px', ...textStyle }}>{item.serviceName}</td>}
                                                {visibilityOfDescription && <td style={{ border: "0", padding: '8px', ...textStyle }}>{item.sale_description}</td>}
                                                {visibilityOfTax && <td style={{ border: "0", padding: '8px', ...textStyle }}>{item.extax ? item.extax : item.saletax?.rate}%</td>}
                                                {visibilityOfRate && <td style={{ border: "0", padding: '8px', ...textStyle }}>{item.quantity}</td>}
                                                {visibilityOfQty && <td style={{ border: "0", padding: '8px', ...textStyle }}>{item?.sales_price}</td>}
                                                {visibilityOfAmount && <td style={{ border: "0", padding: '8px', ...textStyle }}>{item?.total_price}</td>}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='row'>
                            <div className='col-8'></div>
                            <div className='col-4'>
                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", borderBottom: "1px solid lightgray" }}>
                                    <div>
                                        <p style={{ margin: "5px", ...textStyle, color: bgColor ? bgColor : "#737272" }}>SUBTOTAL</p>
                                        <p style={{ margin: "5px", ...textStyle, color: bgColor ? bgColor : "#737272" }}>TOTAL TAX(%)</p>
                                        <p style={{ margin: "5px", ...textStyle, color: bgColor ? bgColor : "#737272" }}>TOTAL</p>
                                    </div>
                                    <div>
                                        <p style={{ margin: "5px", ...textStyle }}>PRs {invoiceData?.total_amount}</p>
                                        <p style={{ margin: "5px", ...textStyle }}>{totalExtax}</p>
                                        <p style={{ margin: "5px", fontWeight: "bold", ...textStyle }}>PRs {totalAmountWithTax.toFixed(2)}</p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", borderBottom: bgColor ? `3px solid ${bgColor}` : '3px solid #000' }}>
                                    <div>
                                        <p style={{ margin: "0", ...textStyle, color: bgColor ? bgColor : "#737272" }}>BALANCE DUE</p>
                                    </div>
                                    <div>
                                        <p style={{ margin: "0", ...textStyle, fontWeight: "bold", ...textStyle, color: bgColor ? bgColor : "#737272" }}>PRs {Math.round(totalAmountWithTax.toFixed(2))}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {visibilityOfTaxSummary && <div className='row mt-3'>
                            <h6 style={{ fontWeight: "bold", margin: "0", ...textStyle }}>Tax Summary</h6>
                            <table className="table">
                                <thead style={{ background: "#fff" }}>
                                    <tr>
                                        <th scope="col" style={{ border: '0px solid #fff', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '8px', textAlign: "end", fontWeight: "bold", ...textStyle }}></th>
                                        <th scope="col" style={{ border: '0px solid #fff', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '8px', textAlign: "end", fontWeight: "bold", ...textStyle }}>Rate</th>
                                        <th scope="col" style={{ border: '0px solid #fff', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '8px', textAlign: "end", fontWeight: "bold", ...textStyle }}>VAT</th>
                                        <th scope="col" style={{ border: '0px solid #fff', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', padding: '8px', textAlign: "end", fontWeight: "bold", ...textStyle }}>Net</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoiceData?.service?.map((item) => {
                                        return (
                                            <tr key={item.id} syle={{ border: "0" }}>
                                                <th style={{ border: '0', padding: '8px', textAlign: "end" }}></th>
                                                <td style={{ textAlign: "end", border: "0", padding: '8px', ...textStyle }}>{item.extax ? item.extax : item.saletax?.rate}%</td>
                                                <td style={{ textAlign: "end", border: "0", padding: '8px', ...textStyle }}>{item?.sales_price * item.extax ? item.extax : item.saletax?.rate / 100}</td>
                                                <td style={{ textAlign: "end", border: "0", padding: '8px', ...textStyle }}>{item?.total_price}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>}
                        {visibilityOfStamp && <div className='row'>
                            <div className='footer' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {stumpPlacement === 'left' && (
                                    <>
                                        <div className='details' style={{ color: "transparent" }}>

                                        </div>
                                        <div className='logo'>
                                            <div style={getStumpStyle()}>
                                                <img alt='' src={img_url + invoiceData?.stamp} style={getStumpStyle()} />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {stumpPlacement === 'right' && (
                                    <>
                                        <div className='logo'>
                                            <div style={getStumpStyle()}>
                                                <img alt='' src={img_url + invoiceData?.stamp} style={getStumpStyle()} />
                                            </div>
                                        </div>
                                        <div className='details' style={{ color: "transparent" }}>

                                        </div>
                                    </>
                                )}
                                {stumpPlacement === 'middle' && (
                                    <>
                                        <div className='details' style={{ textAlign: 'left', color: "transparent" }}>

                                        </div>
                                        <div className='logo' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <div style={{ height: '100px', width: '100px' }}>
                                                <img
                                                    alt=''
                                                    src={img_url + invoiceData?.stamp}
                                                    style={{ height: '100%', width: '100%', borderRadius: '50%', border: '1px solid lightgray' }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>}
                    </div>
                </div>
            </div >
        </>
    )
}

export default Template6
