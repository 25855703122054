import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CiImport } from 'react-icons/ci'
import html2pdf from 'html2pdf.js';
import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { HiOutlinePrinter } from 'react-icons/hi';
function BundleServicesView() {
    const params = useParams()
    const { serviceGroup, getServiceGroup, getProfitData, profitData, getFilterProfit } = useData()
    const [tQuantity, setTQuantity] = useState(0)
    const [expanse, setExpanse] = useState(0)
    const cardRef = useRef(null);

    useEffect(() => {
        getProfitData(params.id)
        getServiceGroup(params.id)
    }, [])
    useEffect(() => {
        let totalqty = 0;
        serviceGroup?.groupservice?.map((item) => {
            totalqty = parseInt(item.quantity, 10) + totalqty;
        });
        setTQuantity(totalqty);
    }, [serviceGroup]);

    const [date, setdate] = useState({
        companyId: params.id,
        month: "",
        startDate: "",
        endddate: ""
    });
    const handleDownloadCsvExp = () => {
        const csv = Papa.unparse(profitData?.expenses);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'Bundlesofservices.csv');
    };
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleGroup = serviceGroup?.groupservice?.filter(item => true) || [];

    const currentGroups = visibleGroup?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleGroup?.length / itemsPerPage);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button key={i} className='btn btn-primary p-2' style={{ margin: "0px 5px" }} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const totalPrice = currentGroups.reduce((accumulator, item) => {
        const subtotal = item?.service?.sale_price * item?.quantity || 0;
        return accumulator + subtotal;
    }, 0);
    const [pdfGenerating, setPdfGenerating] = useState(false);

    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return;
        }

        setPdfGenerating(true);

        setTimeout(() => {
            const card = cardRef.current;

            const pdfOptions = {
                margin: 10,
                filename: 'bundles.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            if (card) {
                html2pdf().from(card).set(pdfOptions).outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'bundles.pdf';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                });
            } else {
                console.error('Card element not found.');
            }

            setPdfGenerating(false);
        }, 0);
    };
    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
    });

    return (
        <div>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <div className='inp-buttons d-flex gap-2 w-100' style={{ alignItems: "end", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px", justifyContent: "space-between", width: "100%" }}>
                            <div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                        <li className="breadcrumb-item"><Link to={"/admin/accounts/banking/Services"}>Service</Link></li>
                                        <li className="breadcrumb-item"><Link to={"/admin/accounts/banking/bundlesofServices"}>Bundles of Services</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Bundles</li>
                                    </ol>
                                </nav>

                            </div>
                            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                                {/* <div className='startdate'>
                                    <label style={{ fontWeight: "bold", fontSize: "10px" }}>Select Start Date</label> <br />
                                    <input type='date' name='start' onChange={(e) => setdate({ ...date, startDate: e.target.value })} className='form-control' />
                                </div>
                                <div className='endDate'>
                                    <label style={{ fontWeight: "bold", fontSize: "10px" }}>Select End Date</label> <br />
                                    <input type='date' name='end' onChange={(e) => setdate({ ...date, endddate: e.target.value })} className='form-control' />
                                </div>
                                <button onClick={() => {
                                    handlePageChange(1)
                                    getFilterProfit(date.startDate, date.endddate, date.companyId)
                                }} className='btn btn-primary'>Search</button> */}
                                <div style={{ display: "flex" }}>
                                    <button onClick={handlePrint} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px", marginRight: "10px" }}><HiOutlinePrinter style={{ fontSize: "20px" }} />Print</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div ref={contentRef}>
                    <div className='row'>
                        <div className='card'>
                            <div className='card-body table-responsive recruitment'>
                                <table class="table">
                                    <thead style={{ textAlign: 'center' }}>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Service</th>
                                            <th scope="col">Sales Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Sub Total</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: 'center' }}>
                                        {currentGroups?.map((item, idx) => {
                                            return (
                                                < tr >
                                                    <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                    <td>{item?.service?.name}</td>
                                                    <td>{(item?.service?.sale_price)?.toLocaleString()}</td>
                                                    <td>{(item?.quantity)?.toLocaleString()}</td>
                                                    <td>{(item?.service?.sale_price * item?.quantity)?.toLocaleString()}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {paginationControls.map((button, index) => (
                                    <React.Fragment key={index}>{button}</React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='card p-2 mt-3'>
                        <div className='card-body'>
                            <div className='row'>
                                <h3 style={{ fontSize: "17px", fontWeight: "bold", textDecoration: "underline", marginBottom: "10px" }}>Summary</h3>
                                <div style={{ display: "flex", gap: "20px" }}>
                                    <div>
                                        <h3 style={{ fontSize: "14px", fontWeight: "bold", paddingTop: "1px" }}>Total Quantity</h3>
                                    </div>
                                    <div>
                                        <p className='leads'>{tQuantity?.toLocaleString()}</p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", gap: "20px" }}>
                                    <div>
                                        <h3 style={{ fontSize: "14px", fontWeight: "bold", paddingTop: "1px" }}>Total Price</h3>
                                    </div>
                                    <div>
                                        <p className='leads'>{totalPrice?.toLocaleString()}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default BundleServicesView
