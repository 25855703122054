import React, { useState } from 'react';

function InoiceBuilder() {
    const [logoPosition, setLogoPosition] = useState('left');

    const handlePositionChange = (position) => {
        setLogoPosition(position);
    };

    const getLogoStyle = () => {
        switch (logoPosition) {
            case 'middle':
                return { marginLeft: 'auto', marginRight: 'auto' };
            case 'right':
                return { marginLeft: 'auto' };
            default:
                return {};
        }
    };

    const getTextContainerStyle = () => {
        switch (logoPosition) {
            case 'left':
                return { textAlign: 'right' };
            case 'right':
                return { textAlign: 'left' };
            default:
                return {};
        }
    };

    return (
        <>
            <div className='builderData'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div>
                            <label htmlFor='logo'></label>
                            <input type='file' id='logo' />
                        </div>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <div
                                onClick={() => handlePositionChange('left')}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '20px',
                                    width: '20px',
                                    borderRadius: '50%',
                                    border: '1px solid',
                                    background: logoPosition === 'left' ? 'lightblue' : 'lightgray',
                                    color: 'black',
                                    cursor: 'pointer',
                                }}
                            >
                                L
                            </div>
                            <div
                                onClick={() => handlePositionChange('middle')}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '20px',
                                    width: '20px',
                                    borderRadius: '50%',
                                    border: '1px solid',
                                    background: logoPosition === 'middle' ? 'lightblue' : 'lightgray',
                                    color: 'black',
                                    cursor: 'pointer',
                                }}
                            >
                                M
                            </div>
                            <div
                                onClick={() => handlePositionChange('right')}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '20px',
                                    width: '20px',
                                    borderRadius: '50%',
                                    border: '1px solid',
                                    background: logoPosition === 'right' ? 'lightblue' : 'lightgray',
                                    color: 'black',
                                    cursor: 'pointer',
                                }}
                            >
                                R
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <div>Left</div>
                            <div>Middle</div>
                            <div>Right</div>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='row' style={{ display: "flex" }}>
                            <div style={{ flex: 1 }}>
                                <h1 style={{ ...getTextContainerStyle() }}>Hello</h1>
                            </div>
                            <div>
                                <div
                                    style={{
                                        height: '200px',
                                        width: '200px',
                                        border: '1px solid',
                                        ...getLogoStyle(),
                                    }}
                                >
                                    <img alt='logo' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InoiceBuilder;
