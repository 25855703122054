import React, { useEffect, useState } from 'react'
import { BiUser, BiMessageDetail } from 'react-icons/bi'
import { RiAccountPinCircleLine } from 'react-icons/ri'
import { BsFillImageFill } from 'react-icons/bs'
import { GrServices } from 'react-icons/gr'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import { CiCalendar } from 'react-icons/ci'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function Services() {
    const params = useParams()
    const { accountsData, getAccountsData, handleClick, setSnackbarValue, getRevenueData, revenueData, img_url, storeRevenueData, getInvoiceDetailData, invoiceDetailData, getFilterRevenue } = useData()
    useEffect(() => {
        getRevenueData()
        getInvoiceDetailData()
        getAccountsData()
    }, [])
    const [selectedCompany, setSelectedCompany] = useState(params.id || null)
    const filteredCompanies = accountsData?.company?.filter(item => item.deleteStatus === 0);
    const filteredService = accountsData?.services?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));
    const filteredClient = accountsData?.client?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));
    const filteredAccount = accountsData?.account?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [obj, setObj] = useState(
        {
            service_id: "",
            company_id: params.id ? params.id : selectedCompany,
            account_id: "",
            client_id: "",
            date: getCurrentDate(),
            description: "",
            image: ""
        }
    )
    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function handleDateChange(e) {
        const newDate = e.target.value;
        setObj({ ...obj, date: newDate });
    }
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64URL = event.target.result;
                const parts = base64URL.split(',');
                const base64ImageData = parts[1];
                setObj({
                    ...obj,
                    image: base64ImageData,
                });
                const imgBox = document.getElementById('image-box');
                imgBox.src = base64URL;
            };
            reader.readAsDataURL(file);
        }
    };
    const clearFields = () => {
        setObj({
            service_id: "",
            company_id: params.id ? params.id : selectedCompany,
            account_id: "",
            client_id: "",
            date: "",
            description: "",
            image: ""
        });

        setSelectedImage(null);
        const imgBox = document.getElementById('image-box');
        imgBox.src = '';
    };

    const isFormValid = () => {
        for (const key in obj) {
            if (key !== "description" && obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = () => {
        if (isFormValid()) {
            storeRevenueData(obj);
            handleClose()
            clearFields();
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const [date, setDate] = useState({
        company: "",
        startDate: "",
        endDate: ""
    });

    const [showImageOverlay, setShowImageOverlay] = useState(false);
    const [selectedImageURL, setSelectedImageURL] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleRevenue = params.id
        ? revenueData?.revenue?.filter(item => item?.company?.id == params.id) || []
        : revenueData?.revenue || [];

    const currentRevenue = visibleRevenue?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleRevenue / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }

    return (
        <>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>

                    <div className='inp-buttons d-flex gap-2 w-100' style={{ alignItems: "end", justifyContent: "space-between" }}>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Revenue</li>
                                </ol>
                            </nav>
                        </div>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                                <div>
                                    <label style={{ fontWeight: "bold", fontSize: "10px" }}>Select Company</label> <br />
                                    <select disabled={params.id ? true : false} className='form-select' onChange={(e) => setDate({ ...date, company: e.target.value })}>
                                        <option value="">Select a company</option>
                                        {filteredCompanies?.map((item) => (
                                            <option key={item.id} value={item.name}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='startdate'>
                                    <label style={{ fontWeight: "bold", fontSize: "10px" }}>Select Start Date</label> <br />
                                    <input
                                        type='date'
                                        name='startDate'
                                        onChange={(e) => setDate({ ...date, startDate: e.target.value })}
                                        className='form-control'
                                    />
                                </div>
                                <div className='enddate'>
                                    <label style={{ fontWeight: "bold", fontSize: "10px" }}>Select End Date</label> <br />
                                    <input
                                        type='date'
                                        name='endDate'
                                        onChange={(e) => setDate({ ...date, endDate: e.target.value })}
                                        className='form-control'
                                    />
                                </div>
                                <button className="btn btn-primary" onClick={() => {
                                    handlePageChange(1)
                                    getFilterRevenue(date.startDate, date.endDate, date.company)
                                }}>Search</button>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={handleOpen}>Add</button>
                        </div>
                        <Modal
                            open={open}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Add
                                </Typography>
                                <div id="modal-modal-description" sx={{ mt: 2 }}>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label>Date</label>
                                                <div className='inputBox '>
                                                    <label><CiCalendar /></label>
                                                    <input
                                                        type='date'
                                                        placeholder='Payment Date'
                                                        value={obj.date} // Bind the input value to the 'date' property
                                                        onChange={handleDateChange} // Call the event handler on change
                                                    ></input>
                                                </div>
                                                <label>Screenshot</label>
                                                <div className='inputBox '>
                                                    <div className='image-upload' style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                                        <label for='inp'>
                                                            <BsFillImageFill /> <span style={{ fontSize: '14px' }}>Screenshot</span>
                                                        </label>
                                                        <input
                                                            id='inp'
                                                            style={{ display: 'none' }}
                                                            type='file'
                                                            onChange={handleImageChange}
                                                        />
                                                        <img id="image-box" style={{ width: '30px', height: '30px' }} src={selectedImage ? URL.createObjectURL(selectedImage) : ''} alt="" />
                                                    </div>
                                                </div>
                                                <label>Customer</label>
                                                <div className='  selectBox'>
                                                    <label><BiUser /></label>
                                                    <select onChange={(e) => {
                                                        setObj({ ...obj, client_id: e.target.value })
                                                    }
                                                    }>
                                                        <option value="">Customer</option>
                                                        {selectedCompany && (
                                                            <>
                                                                {filteredClient?.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label>Company</label>
                                                <div className='selectBox'>
                                                    <select disabled={params.id ? true : false} onChange={(e) => {
                                                        setSelectedCompany(parseInt(e.target.value));
                                                        setObj({
                                                            ...obj,
                                                            company_id: parseInt(e.target.value)
                                                        });
                                                    }}>
                                                        <option value="">Select a company</option>
                                                        {filteredCompanies?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <label>Service</label>
                                                <div className='  selectBox'>
                                                    <label><GrServices /></label>
                                                    <select onChange={(e) => {
                                                        setObj({ ...obj, service_id: e.target.value })
                                                    }}>
                                                        <option value="">Service</option>
                                                        {selectedCompany && (
                                                            <>
                                                                {filteredService?.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                                <label>Account</label>
                                                <div className='  selectBox'>
                                                    <label><RiAccountPinCircleLine /></label>
                                                    <select onChange={(e) => setObj({ ...obj, account_id: e.target.value })}>
                                                        <option value="">Account</option>
                                                        {selectedCompany && (
                                                            <>
                                                                {filteredAccount?.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.bank_name}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row '>
                                        <div className='col-12'>
                                            <label>Description</label>
                                            <div className='textarea'>
                                                <label><BiMessageDetail /></label>
                                                <textarea placeholder='Description' onChange={(e) => setObj({ ...obj, description: e.target.value })}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='modalBtns d-flex justify-content-end gap-2'>
                                            <button className='btn btn-primary' onClick={handleSubmit}>Submit</button>
                                            <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>

                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Service</th>
                                    <th scope="col">Account</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Screenshot</th>
                                    <th scope="col">Amount</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {params?.id ? currentRevenue?.map((item, idx) => {
                                    const truncatedDescription = item.description.slice(0, 20);
                                    const isDescriptionTruncated = item.description.length > 20;

                                    return (
                                        <>
                                            {item?.company?.id == params?.id && <tr>
                                                <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                <td>{item.company?.name}</td>
                                                <td>{item.date}</td>
                                                <td>{item.service_id?.name}</td>
                                                <td>{item.account_id?.bank_name}</td>
                                                <td>{item.client_id?.name}</td>
                                                <td>
                                                    {isDescriptionTruncated ? (
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedDescription(item.description);
                                                                setShowDescriptionOverlay(true);
                                                            }}
                                                        >
                                                            {truncatedDescription}...
                                                        </div>
                                                    ) : (
                                                        item.description
                                                    )}
                                                </td>
                                                {showDescriptionOverlay && (
                                                    <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                        <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                            <p style={{ color: "white" }}>{selectedDescription}</p>
                                                        </div>
                                                    </div>
                                                )}
                                                <td style={{ display: "flex", justifyContent: "center" }}>
                                                    <div style={{ width: '30px', height: '30px' }}>
                                                        <img style={{ width: '100%', height: '100%' }}
                                                            onClick={() => {
                                                                setSelectedImageURL(img_url + item.image);
                                                                setShowImageOverlay(true);
                                                            }}
                                                            src={img_url + item.image} alt='' />
                                                    </div>
                                                </td>
                                                <td style={{ color: "white" }}><span>{item.service_id?.amount ? item.service_id?.amount : '0'}</span></td>
                                            </tr>}
                                        </>

                                    )
                                }) : currentRevenue?.map((item, idx) => {
                                    const truncatedDescription = item.description.slice(0, 20);
                                    const isDescriptionTruncated = item.description.length > 20;

                                    return (
                                        <tr>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{item.company?.name}</td>
                                            <td>{item.date}</td>
                                            <td>{item.service_id?.name}</td>
                                            <td>{item.account_id?.bank_name}</td>
                                            <td>{item.client_id?.name}</td>
                                            <td>
                                                {isDescriptionTruncated ? (
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedDescription(item.description);
                                                            setShowDescriptionOverlay(true);
                                                        }}
                                                    >
                                                        {truncatedDescription}...
                                                    </div>
                                                ) : (
                                                    item.description
                                                )}
                                            </td>
                                            {showDescriptionOverlay && (
                                                <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                    <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                        <p style={{ color: "white" }}>{selectedDescription}</p>
                                                    </div>
                                                </div>
                                            )}
                                            <td style={{ display: "flex", justifyContent: "center" }}>
                                                <div style={{ width: '30px', height: '30px' }}>
                                                    <img style={{ width: '100%', height: '100%' }}
                                                        onClick={() => {
                                                            setSelectedImageURL(img_url + item.image);
                                                            setShowImageOverlay(true);
                                                        }}
                                                        src={img_url + item.image} alt='' />
                                                </div>
                                            </td>
                                            <td style={{ color: "white" }}><span>{item.service_id?.amount ? item.service_id?.amount : '0'}</span></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="pagination-controls">{paginationControls}</div>
                    </div>
                </div>
                {showImageOverlay && (
                    <div className="overlayy" style={{ background: "rgba(0,0,0,0.38)" }} onClick={() => setShowImageOverlay(false)}>
                        <img
                            src={selectedImageURL}
                            alt="Full-Screen Image"
                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                        />
                    </div>
                )}
            </div >
        </>
    )
}

export default Services
