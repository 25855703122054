import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CiEdit, CiTrash } from 'react-icons/ci';
import { Box, Modal } from '@mui/material';
import { Form } from 'antd';
import { useData } from '../../../ContextProvider/Context';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
};

function OtherExpenseModules() {
    const params = useParams()

    const { getBranch, branch, delbarch, company, getCompnayCrud, permission, classesData,
        getClassesData,
        storeClasses,
        updateClasses,
        deleteClassesData,
        locationData,
        getLocationData,
        storeLocation,
        updateLocation,
        deleteLocationData ,
        creditCardData,
        getCreditCardData,
        storeCreditCard,
        updateCreditCard,
        deleteCreditCardData} = useData();
    const [activeTab, setActiveTab] = useState('class');
    useEffect(() => {
        getCompnayCrud()
        getBranch()
        getClassesData()
        getLocationData()
        getCreditCardData()
    }, []);
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setClass({
            company_id: "",
            name: ''
        })
        setOpen(false)
    };
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => {
        setLocation({
            company_id: "",
            name: ''
        })
        setOpen1(false)
    };
    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => {
        setObj({
            company_id: "",
            name: ''
        })
        setOpen2(false)
    };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = params.id
        ? classesData?.data?.filter(item => item?.company?.id == params.id) || []
        : classesData?.data || [];

    const totalPages = Math.ceil(currentItems.length / itemsPerPage);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const [currentPageL, setCurrentPageL] = useState(1);
    const itemsPerPageL = 10;

    const indexOfLastItemL = currentPageL * itemsPerPageL;
    const indexOfFirstItemL = indexOfLastItemL - itemsPerPageL;
    const currentItemsL = params.id
        ? locationData?.data?.filter(item => item?.company?.id == params.id) || []
        : locationData?.data || [];

    const totalPagesL = Math.ceil(currentItemsL.length / itemsPerPageL);

    const handlePageChangeL = page => {
        setCurrentPage(page);
    };

    const paginationControlsL = [];
    for (let i = 1; i <= totalPagesL; i++) {
        paginationControlsL.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChangeL(i)}>
                {i}
            </button>
        );
    }
    const [currentPageC, setCurrentPageC] = useState(1);
    const itemsPerPageC = 10;

    const indexOfLastItemC = currentPageC * itemsPerPageC;
    const indexOfFirstItemC = indexOfLastItemC - itemsPerPageC;
    const currentItemsC = params.id
        ? creditCardData?.data?.filter(item => item?.company?.id == params.id) || []
        : creditCardData?.data || [];

        console.log(currentItemsC);
    const totalPagesC = Math.ceil(currentItemsC.length / itemsPerPageC);

    const handlePageChangeC = page => {
        setCurrentPageC(page);
    };

    const paginationControlsC = [];
    for (let i = 1; i <= totalPagesC; i++) {
        paginationControlsC.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChangeC(i)}>
                {i}
            </button>
        );
    }
    const [classes, setClass] = useState({
        company_id: "",
        name: ''
    })
    const [locations, setLocation] = useState({
        company_id: "",
        name: ''
    })
    const [obj, setObj] = useState({
        company_id: "",
        name: ''
    })
    console.log(obj)
    return (
        <>
            <div className='card p-3'>
                <div className='card-body'>
                    <div className="other-expense-modules">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'class' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('class')}
                                >
                                    Class
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'location' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('location')}
                                >
                                    Location
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'CreditCards' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('CreditCards')}
                                >
                                    Credit Cards
                                </button>
                            </li>
                        </ul>

                        <div className="tab-content">
                            {activeTab === 'class' && <div>
                                <div className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-primary' onClick={handleOpen}>Add Class</button>
                                </div>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    style={{ padding: "1rem" }}
                                >
                                    <Box sx={style} style={{ padding: "1rem" }}>
                                        <div className='my-3'>
                                            <h4 style={{ fontWeight: "bold" }}>Add Class</h4>
                                        </div>
                                        <Form name="add_designation">
                                            <div className="row">
                                                <div className="col-md-6 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="company">Company</label>
                                                        <select
                                                            id="company"
                                                            style={{ width: '100%' }}
                                                            className='form-select'
                                                            disabled={params.id ? true : false}
                                                            value={classes?.company_id}
                                                            onChange={(e) => setClass({ ...classes, company_id: e.target.value })}
                                                        >
                                                            <option hidden>Select Company</option>
                                                            {company?.data?.map((item) => {
                                                                return (
                                                                    <>{item?.deleteStatus == 0 &&
                                                                        <option value={item.id} key={item.name}>
                                                                            {item.name}
                                                                        </option>
                                                                    }
                                                                    </>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="companyName"> Name</label>
                                                        <input
                                                            value={classes?.name}
                                                            onChange={(e) => setClass({ ...classes, name: e.target.value })}
                                                            type='text'
                                                            id="name"
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <Form.Item>
                                                <button className='btn btn-primary mt-3' type="primary" onClick={() => {
                                                    handleClose()
                                                    classes?.id ? updateClasses(classes) : storeClasses(classes)
                                                }}>
                                                    {classes?.id ? 'Update' : 'Submit'}
                                                </button>
                                            </Form.Item>
                                        </Form>
                                    </Box>
                                </Modal>

                                <div className='row'>
                                    <div className='recruitment table-responsive'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Class Name</th>
                                                    <th scope="col">Company</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {params.id ? currentItems
                                                    .slice(indexOfFirstItem, indexOfLastItem)?.map((item, idx) => {
                                                        const globalIndex = idx + (currentPage - 1) * itemsPerPage + 1;

                                                        return (
                                                            <>

                                                                {item?.company?.id == params.id && <tr>
                                                                    <th scope="row">{globalIndex}</th>
                                                                    <td>{item.name}</td>
                                                                    <td>{item?.company?.name}</td>
                                                                    <td>
                                                                        {permission.includes("Branch Edit") && <button
                                                                            className="btn p-1 m-2 btn-primary"
                                                                            onClick={() => {
                                                                                setClass(item)
                                                                                handleOpen()
                                                                            }}
                                                                        >
                                                                            {<CiEdit style={{ fontSize: "20px" }} />}
                                                                        </button>}
                                                                        {permission.includes("Branch Delete") && <button
                                                                            className="btn p-1 m-2 btn-danger"
                                                                            onClick={() => {
                                                                                deleteClassesData(item?.id)
                                                                            }}
                                                                        >
                                                                            {<CiTrash style={{ fontSize: "20px" }} />}
                                                                        </button>}
                                                                    </td>
                                                                </tr>
                                                                }
                                                            </>
                                                        )
                                                    }) :
                                                    currentItems
                                                        .slice(indexOfFirstItem, indexOfLastItem)?.map((item, idx) => {
                                                            const globalIndex = idx + (currentPage - 1) * itemsPerPage + 1;

                                                            return (
                                                                <tr>
                                                                    <th scope="row">{globalIndex}</th>
                                                                    <td>{item.name}</td>
                                                                    <td>{item?.company?.name}</td>
                                                                    <td>
                                                                        {permission.includes("Branch Edit") && <button
                                                                            className="btn p-1 m-2 btn-primary"
                                                                            onClick={() => {
                                                                                setClass(item)
                                                                                handleOpen()
                                                                            }}
                                                                        >
                                                                            {<CiEdit style={{ fontSize: "20px" }} />}
                                                                        </button>}
                                                                        {permission.includes("Branch Delete") && <button
                                                                            className="btn p-1 m-2 btn-danger"
                                                                            onClick={() => {
                                                                                deleteClassesData(item?.id)
                                                                            }}
                                                                        >
                                                                            {<CiTrash style={{ fontSize: "20px" }} />}
                                                                        </button>}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                            </tbody>
                                        </table>
                                        <div className="pagination-controls">{paginationControls}</div>
                                    </div>
                                </div>
                            </div>}
                            {activeTab === 'location' && <div>
                                <div className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-primary' onClick={handleOpen1}>Add Location</button>
                                </div>
                                <Modal
                                    open={open1}
                                    onClose={handleClose1}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    style={{ padding: "1rem" }}
                                >
                                    <Box sx={style} style={{ padding: "1rem" }}>
                                        <div className='my-3'>
                                            <h4 style={{ fontWeight: "bold" }}>Add Location</h4>
                                        </div>
                                        <Form name="add_designation">
                                            <div className="row">
                                                <div className="col-md-6 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="company">Company</label>
                                                        <select
                                                            id="company"
                                                            style={{ width: '100%' }}
                                                            className='form-select'
                                                            disabled={params.id ? true : false}
                                                            value={locations?.company_id}
                                                            onChange={(e) => setLocation({ ...locations, company_id: e.target.value })}
                                                        >
                                                            <option hidden>Select Company</option>
                                                            {company?.data?.map((item) => {
                                                                return (
                                                                    <>{item?.deleteStatus == 0 &&
                                                                        <option value={item.id} key={item.name}>
                                                                            {item.name}
                                                                        </option>
                                                                    }
                                                                    </>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="companyName"> Name</label>
                                                        <input
                                                            value={locations?.name}
                                                            onChange={(e) => setLocation({ ...locations, name: e.target.value })}
                                                            type='text'
                                                            id="name"
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <Form.Item>
                                                <button className='btn btn-primary mt-3' type="primary" onClick={() => {
                                                    handleClose1()
                                                    locations?.id ? updateLocation(locations) : storeLocation(locations)
                                                }}>
                                                    {locations?.id ? 'Update' : 'Submit'}
                                                </button>
                                            </Form.Item>
                                        </Form>
                                    </Box>
                                </Modal>

                                <div className='row'>
                                    <div className='recruitment table-responsive'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Class Name</th>
                                                    <th scope="col">Company</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {params.id ? currentItemsL
                                                    .slice(indexOfFirstItemL, indexOfLastItemL)?.map((item, idx) => {
                                                        const globalIndex = idx + (currentPageL - 1) * itemsPerPageL + 1;

                                                        return (
                                                            <>

                                                                {item?.company?.id == params.id && <tr>
                                                                    <th scope="row">{globalIndex}</th>
                                                                    <td>{item.name}</td>
                                                                    <td>{item?.company?.name}</td>
                                                                    <td>
                                                                        {permission.includes("Branch Edit") && <button
                                                                            className="btn p-1 m-2 btn-primary"
                                                                            onClick={() => {
                                                                                setLocation(item)
                                                                                handleOpen1()
                                                                            }}
                                                                        >
                                                                            {<CiEdit style={{ fontSize: "20px" }} />}
                                                                        </button>}
                                                                        {permission.includes("Branch Delete") && <button
                                                                            className="btn p-1 m-2 btn-danger"
                                                                            onClick={() => {
                                                                                deleteLocationData(item?.id)
                                                                            }}
                                                                        >
                                                                            {<CiTrash style={{ fontSize: "20px" }} />}
                                                                        </button>}
                                                                    </td>
                                                                </tr>
                                                                }
                                                            </>
                                                        )
                                                    }) :
                                                    currentItemsL
                                                        .slice(indexOfFirstItemL, indexOfLastItemL)?.map((item, idx) => {
                                                            const globalIndex = idx + (currentPageL - 1) * itemsPerPageL + 1;

                                                            return (
                                                                <tr>
                                                                    <th scope="row">{globalIndex}</th>
                                                                    <td>{item.name}</td>
                                                                    <td>{item?.company?.name}</td>
                                                                    <td>
                                                                        {permission.includes("Branch Edit") && <button
                                                                            className="btn p-1 m-2 btn-primary"
                                                                            onClick={() => {
                                                                                setLocation(item)
                                                                                handleOpen1()
                                                                            }}
                                                                        >
                                                                            {<CiEdit style={{ fontSize: "20px" }} />}
                                                                        </button>}
                                                                        {permission.includes("Branch Delete") && <button
                                                                            className="btn p-1 m-2 btn-danger"
                                                                            onClick={() => {
                                                                                deleteLocationData(item?.id)
                                                                            }}
                                                                        >
                                                                            {<CiTrash style={{ fontSize: "20px" }} />}
                                                                        </button>}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                            </tbody>
                                        </table>
                                        <div className="pagination-controls">{paginationControlsL}</div>
                                    </div>
                                </div>
                            </div>}
                            {activeTab === 'CreditCards' && <div>
                                <div className='my-3 d-flex justify-content-end'>
                                    <button className='btn btn-primary' onClick={handleOpen2}>Add Credit Cards</button>
                                </div>
                                <Modal
                                    open={open2}
                                    onClose={handleClose2}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    style={{ padding: "1rem" }}
                                >
                                    <Box sx={style} style={{ padding: "1rem" }}>
                                        <div className='my-3'>
                                            <h4 style={{ fontWeight: "bold" }}>Add Credit Cards</h4>
                                        </div>
                                        <Form name="add_designation">
                                            <div className="row">
                                                <div className="col-md-6 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="company">Company</label>
                                                        <select
                                                            id="company"
                                                            style={{ width: '100%' }}
                                                            className='form-select'
                                                            disabled={params.id ? true : false}
                                                            value={obj?.company_id}
                                                            onChange={(e) => setObj({ ...obj, company_id: e.target.value })}
                                                        >
                                                            <option hidden>Select Company</option>
                                                            {company?.data?.map((item) => {
                                                                return (
                                                                    <>{item?.deleteStatus == 0 &&
                                                                        <option value={item.id} key={item.name}>
                                                                            {item.name}
                                                                        </option>
                                                                    }
                                                                    </>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="companyName"> Name</label>
                                                        <input
                                                            value={obj?.name}
                                                            onChange={(e) => setObj({ ...obj, name: e.target.value })}
                                                            type='text'
                                                            id="name"
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <Form.Item>
                                                <button className='btn btn-primary mt-3' type="primary" onClick={() => {
                                                    handleClose2()
                                                    obj?.id ? updateCreditCard(obj) : storeCreditCard(obj)
                                                }}>
                                                    {obj?.id ? 'Update' : 'Submit'}
                                                </button>
                                            </Form.Item>
                                        </Form>
                                    </Box>
                                </Modal>

                                <div className='row'>
                                    <div className='recruitment table-responsive'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Card</th>
                                                    <th scope="col">Company</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {params.id ? currentItemsC
                                                    .slice(indexOfFirstItemC, indexOfLastItemC)?.map((item, idx) => {
                                                        const globalIndex = idx + (currentPageC - 1) * itemsPerPageC + 1;

                                                        return (
                                                            <>

                                                                {item?.company?.id == params.id && <tr>
                                                                    <th scope="row">{globalIndex}</th>
                                                                    <td>{item.name}</td>
                                                                    <td>{item?.company?.name}</td>
                                                                    <td>
                                                                        {permission.includes("Branch Edit") && <button
                                                                            className="btn p-1 m-2 btn-primary"
                                                                            onClick={() => {
                                                                                setObj(item)
                                                                                handleOpen2()
                                                                            }}
                                                                        >
                                                                            {<CiEdit style={{ fontSize: "20px" }} />}
                                                                        </button>}
                                                                        {permission.includes("Branch Delete") && <button
                                                                            className="btn p-1 m-2 btn-danger"
                                                                            onClick={() => {
                                                                                deleteCreditCardData(item?.id)
                                                                            }}
                                                                        >
                                                                            {<CiTrash style={{ fontSize: "20px" }} />}
                                                                        </button>}
                                                                    </td>
                                                                </tr>
                                                                }
                                                            </>
                                                        )
                                                    }) :
                                                    currentItemsC
                                                        .slice(indexOfFirstItemC, indexOfLastItemC)?.map((item, idx) => {
                                                            const globalIndex = idx + (currentPageC - 1) * itemsPerPageC + 1;

                                                            return (
                                                                <tr>
                                                                    <th scope="row">{globalIndex}</th>
                                                                    <td>{item.name}</td>
                                                                    <td>{item?.company?.name}</td>
                                                                    <td>
                                                                        {permission.includes("Branch Edit") && <button
                                                                            className="btn p-1 m-2 btn-primary"
                                                                            onClick={() => {
                                                                                setObj(item)
                                                                                handleOpen2()
                                                                            }}
                                                                        >
                                                                            {<CiEdit style={{ fontSize: "20px" }} />}
                                                                        </button>}
                                                                        {permission.includes("Branch Delete") && <button
                                                                            className="btn p-1 m-2 btn-danger"
                                                                            onClick={() => {
                                                                                deleteCreditCardData(item?.id)
                                                                            }}
                                                                        >
                                                                            {<CiTrash style={{ fontSize: "20px" }} />}
                                                                        </button>}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                            </tbody>
                                        </table>
                                        <div className="pagination-controls">{paginationControlsC}</div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OtherExpenseModules;
