import React, { useEffect, useState } from 'react'
import { LiaIndustrySolid } from 'react-icons/lia'
import { TbGitBranch } from 'react-icons/tb'
import { MdLocalFireDepartment } from 'react-icons/md'
import { SiCodesignal } from 'react-icons/si'
import { CiCalendarDate } from 'react-icons/ci'
import { BsCashCoin } from 'react-icons/bs'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useData } from '../ContextProvider/Context'
import { IoIosAddCircle } from "react-icons/io";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius:"12px",
};
function AddBonus({ getBonus }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { getAllCrudData, allCrudData, base_url, handleClick, setSnackbarValue } = useData()
  useEffect(() => {
    getAllCrudData()
  }, [])
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);

  const filteredCompanies = allCrudData?.companies?.filter(item => item.deleteStatus === 0);

  const filteredBranches = allCrudData?.branches?.filter(item => item.companyId === selectedCompany);

  const filteredDepartments = allCrudData?.department?.filter(item => item.branchId === selectedBranch);

  const filteredDesignation = allCrudData?.designations?.filter(item => item.departmentId === selectedDepartment);

  const filteredEmployee = allCrudData?.employees?.filter(item => item.designationId === selectedDesignation);

  const [bounsData, setBounsData] = useState({

    companyId: "",
    branchId: "",
    departmentId: "",
    designationId: "",
    empId: [],
    title: "",
    amount: "",
    month: ""

  })
  const handleSelectChange = (event) => {
    const selectedUserIds = Array.from(event.target.selectedOptions, (option) => option.value);

    setBounsData((prevData) => ({
      ...prevData,
      empId: selectedUserIds,
    }));
  };
  const isFormValid = () => {
    for (const key in bounsData) {
      if (bounsData[key] === '') {
        return false;
      }
    }
    return true;
  };
  const createBouns = () => {
    if(isFormValid()){
      fetch(`${base_url}empBonusUpdate`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(bounsData),
      })
        .then((res) => res.json())
        .then((data) => {
          getBonus()
          handleClose()
          handleClick()
          setSnackbarValue({ message: "Bonus Create successfully", color: "#2ECC71" });
        });
    }
    else{

    }
 
  }
  return (
    <>
      <button type="button" className="btn btn-primary" onClick={handleOpen}  style={{
                    padding: "8px 16px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}>
      <IoIosAddCircle />
        <span>Add Bonus</span>
        </button>
      <Modal
      
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div id="modal-modal-description" sx={{ mt: 2 }}>
            <div className='container'>
              <div className='inputFields'>
                <div className='row'>
                  <div className='col-6'>
                    <div className='selectBox mt-3'>
                      <label htmlFor="companySelect"><LiaIndustrySolid /></label>
                      <select
                        id="companySelect"
                        onChange={(e) => {
                          setSelectedCompany(parseInt(e.target.value))
                          setBounsData({ ...bounsData, companyId: e.target.value })
                        }}
                      >
                        <option hidden>All Company</option>
                        <option value="">All Company</option>
                        {filteredCompanies?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='selectBox mt-3'>
                      <label htmlFor="branchSelect"><TbGitBranch /></label>
                      <select
                        id="branchSelect"
                        onChange={(e) => {
                          setSelectedBranch(parseInt(e.target.value))
                          setBounsData({ ...bounsData, branchId: e.target.value })
                        }}>
                        <option hidden>Branch</option>
                        <option value="">Branch</option>
                        {selectedCompany && (
                          <>
                            {filteredBranches?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.branch}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <div className='selectBox mt-3'>
                      <label htmlFor="departmentSelect"><MdLocalFireDepartment /></label>
                      <select
                        id="departmentSelect"
                        onChange={(e) => {
                          setSelectedDepartment(parseInt(e.target.value))
                          setBounsData({ ...bounsData, departmentId: e.target.value })
                        }}
                      >
                        <option value="">Department</option>
                        {selectedBranch && (
                          <>
                            {filteredDepartments?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.department}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='selectBox mt-3'>
                      <label htmlFor="designationSelect"><SiCodesignal /></label>
                      <select
                        id="designationSelect"
                        onChange={(e) => {
                          setSelectedDesignation(parseInt(e.target.value))
                          setBounsData({ ...bounsData, designationId: e.target.value })
                        }}
                      >
                        <option value="">Designation</option>
                        {selectedDepartment && (
                          <>
                            {filteredDesignation?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.title}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='selectBox mt-3'>
                      <label htmlFor="employeeSelect"><SiCodesignal className='svg' /></label>
                      <div className='form-group w-100' sx={{ outline: "none" }}>
                        <select
                          style={{
                            border: "none",
                            width: '100%',
                            height: '30px'
                          }}
                          multiple
                          value={bounsData.empId}
                          onChange={handleSelectChange}
                        >
                          {selectedDesignation && (
                            <>
                              {filteredEmployee?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='inputBox mt-3'>
                      <label htmlFor="dateInput"><CiCalendarDate /></label>
                      <input
                        id="dateInput"
                        onChange={(e) => {
                          setBounsData({ ...bounsData, month: e.target.value })
                        }}
                        placeholder='date' type='date'
                      ></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='inputBox mt-3'>
                      <label></label>
                      <input
                        onChange={(e) => {
                          setBounsData({ ...bounsData, title: e.target.value })
                        }}
                        placeholder='Title'
                      ></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='inputBox mt-3'>
                      <label htmlFor="amountInput"><BsCashCoin /></label>
                      <input
                        id="amountInput"
                        onChange={(e) => {
                          setBounsData({ ...bounsData, amount: e.target.value })
                        }}
                        placeholder='Amount' type='number'
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='modalBtns d-flex justify-content-end gap-2'>
                <button onClick={createBouns} className='btn btn-primary'>Add</button>
                <button onClick={handleClose} className='btn btn-secondary'>Cancel</button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>


    </>
  )
}

export default AddBonus
