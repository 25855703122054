import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import { Form } from "antd";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useData } from "../ContextProvider/Context";
import { RxCross1 } from "react-icons/rx";
import { LuGoal } from "react-icons/lu";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
};
function Goal() {
  const {
    handleClick,
    setSnackbarValue,
    getEmployees,
    employees,
    postGoals,
    getGoalsData,
    goalsData,
    img_url,
    deleteGoalData,
  } = useData();
  useEffect(() => {
    getEmployees();
    getGoalsData();
  }, []);
  const emp_id = JSON.parse(localStorage.getItem("user_auth"));

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setAllData({
      title: "",
      time_period: `${selectFrequncy} ${selectedYear}`,
      privacy: "Public",
      description: "",
      update_method: "",
      progress_source: "Projects",
      user_id: emp_id?.user?.id || "",
      members: [],
    });
    setOpen(false);
  };
  const [selectedOwners, setSelectedOwners] = useState([]);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const handleYearChange = (event) => {
    const inputYear = event.target.value.slice(0, 4);
    setSelectedYear(inputYear);
  };

  const frequencyOptions = [
    {
      value: "h1",
      label: "H1",
      title: `1-Jan-${selectedYear} to 30-Jun-${selectedYear}`,
    },
    {
      value: "h2",
      label: "H2",
      title: `1-Jul-${selectedYear} to 31-Dec-${selectedYear}`,
    },
    {
      value: "q1",
      label: "Q1",
      title: `1-Jan-${selectedYear} to 31-March-${selectedYear}`,
    },
    {
      value: "q2",
      label: "Q2",
      title: `1-Apr-${selectedYear} to 31-Jun-${selectedYear}`,
    },
    {
      value: "q3",
      label: "Q3",
      title: `1-Jul-${selectedYear} to 30-Sep-${selectedYear}`,
    },
    {
      value: "q4",
      label: "Q4",
      title: `1-Oct-${selectedYear} to 31-Dec-${selectedYear}`,
    },
  ];
  const [selectFrequncy, setSelectedFrequency] = useState();
  useEffect(() => {
    if (selectFrequncy) {
      setAllData((prevData) => ({
        ...prevData,
        time_period: `${selectFrequncy} ${selectedYear}`,
      }));
    }
  }, [selectFrequncy, selectedYear]);

  const [allData, setAllData] = useState({
    title: "",
    time_period: `${selectFrequncy} ${selectedYear}`,
    privacy: "Public",
    description: "",
    update_method: "",
    progress_source: "Projects",
    user_id: emp_id?.user?.id,
    employee_id: emp_id?.employee?.id,
    members: [],
  });
  console.log(allData);
  const isFormValid = () => {
    for (const key in allData) {
      if (key !== "description" && allData[key] === "") {
        return false;
      }
    }
    return true;
  };

  const handleOwnersChange = (event, newValue) => {
    setSelectedOwners(newValue);
    setAllData({ ...allData, members: newValue.map((owner) => owner.id) });
  };
  const [currentDate1, setCurrentDate1] = useState(new Date());

  const formattedDate = currentDate1.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const state = {
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
  }
  const { vertical, horizontal } = state;
  const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const hadelSubmit = () => {
    if (allData?.title?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Title is required.');
    } else if (allData?.time_period?.selectFrequncy?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Financial year is required.');
    } else if (allData?.time_period?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Frequency is required.');
    } else if (allData?.update_method?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Update Method is required.');
    } else if (allData?.members?.length == 0) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Memnbers are required.');
    } else {
      postGoals(allData)
      handleClose()
    }

  }
  const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentGoals = goalsData?.goals?.slice(indexOfFirstItem, indexOfLastItem) || [];

  const totalPages = Math.ceil(goalsData?.goals?.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }
  return (
    <>
      <div className="goalData">
        <div classname="row "
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <div
            className="inp-buttons d-flex gap-2 w-100"
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <div className="row">
              <div>
                <nav>
                  <ul style={{ paddingLeft: "3px" }}>
                    <li
                      style={{
                        color: "black",
                        fontSize: "24px",
                        fontWeight: "500",
                      }}
                    >
                      Goals
                    </li>
                    <li>{formattedDate}</li>
                  </ul>
                </nav>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={handleOpen}
                  style={{
                    padding: "8px 16px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >  <LuGoal />
                  <span>Add Goal</span>
                </button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={style}
                    className="scrollBar"
                    style={{
                      padding: "1rem",
                      height: "600px",
                    }}
                  >
                    <div className="row">
                      <h4 style={{ fontWeight: "bold" }}>Add Goal</h4>
                    </div>
                    <Form name="add_designation">
                      <div className="row">
                        <div className="col-md-6 mb-2">
                          <div className="form-group">
                            <label htmlFor="company">Goal Title <span style={{ color: "red" }}>*</span></label>
                            <input
                              style={{ height: "56px" }}
                              type="text"
                              placeholder="Title"
                              className="form-control"
                              onChange={(e) =>
                                setAllData({
                                  ...allData,
                                  title: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-2">
                          <div className="form-group">
                            <label htmlFor="company">
                              Select Financial Year
                            </label>
                            <input
                              style={{ height: "56px" }}
                              type="text"
                              className="form-control"
                              value={selectedYear}
                              maxLength="4"
                              onChange={handleYearChange}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="company">Owner</label>
                                                        <select
                                                            id="company"
                                                            style={{ width: '100%', height: "56px" }}
                                                            className='form-select'
                                                            onChange={(e) => setAllData({ ...allData, user_id: e.target.value })}
                                                            value={allData.user_id}
                                                        >
                                                            <option hidden>Select Owner</option>
                                                            <option value={''}>Select Owner</option>
                                                            {employees?.data?.map((item => (
                                                                <option key={item?.id} value={item?.id}>{item?.name}</option>
                                                            )))}
                                                        </select>
                                                    </div>
                                                </div> */}
                        <div className="col-md-6 mb-2">
                          <div className="form-group">
                            <label htmlFor="company">Frequency</label>
                            <select
                              id="frequency"
                              style={{ width: "100%", height: "56px" }}
                              className="form-select"
                              onChange={(e) =>
                                setSelectedFrequency(e.target.value)
                              }
                            >
                              <option hidden>Select Frequency</option>
                              {frequencyOptions.map((option) => (
                                <option
                                  key={option.value}
                                  value={option.value}
                                  title={option.title}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 mb-2">
                          <div className="form-group">
                            <label htmlFor="company">Member <span style={{ color: "red" }}>*</span></label>
                            <Autocomplete
                              multiple
                              id="company"
                              options={employees?.data || []}
                              getOptionLabel={(option) => option.name}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField {...params} label="Select Members" />
                              )}
                              value={selectedOwners}
                              onChange={handleOwnersChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-2">
                          <div className="form-group">
                            <label htmlFor="company">Update Method <span style={{ color: "red" }}>*</span></label>
                            <select
                              id="company"
                              style={{ width: "100%", height: "56px" }}
                              className="form-select"
                              onChange={(e) =>
                                setAllData({
                                  ...allData,
                                  update_method: e.target.value,
                                })
                              }
                            >
                              <option hidden>Select Method</option>
                              <option value={"Automatic"}>Automatic</option>
                              <option value={"Manual"}>Manual</option>
                            </select>
                          </div>
                        </div>
                        {/* <div className="col-md-6 mb-2">
                                                    <div className="form-group">
                                                        <label htmlFor="company">Progress Source</label>
                                                        <select
                                                            id="company"
                                                            style={{ width: '100%', height: "56px" }}
                                                            className='form-select'
                                                            onChange={(e) => setAllData({ ...allData, progress_source: e.target.value })}
                                                        >
                                                            <option hidden>Select Source</option>
                                                            <option value={'Sub-goals'} disabled>Sub goals</option>
                                                            <option value={'Projects'}>Projects</option>
                                                        </select>
                                                    </div>
                                                </div> */}
                      </div>
                      <Form.Item>
                        <button
                          className="btn btn-primary mt-3"
                          type="primary"
                          htmlType="submit"
                          onClick={() => {
                            hadelSubmit();
                          }}
                        >
                          Submit
                        </button>
                      </Form.Item>
                    </Form>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="row">
              <p style={{ fontWeight: "bold" }}>Missions</p>
              <p>
                Add Your company mission to align your work and stay inspired.
              </p>
            </div>
          </div>
        </div>
        <h3 style={{ fontWeight: "bold", fontSize: "15px" }}>Goals</h3>
        <div className="row">
          {currentGoals?.map((item, idx) => {
            return (
              <div className="col-lg-3 col-md-3">
                <div className="card mb-3">
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Link
                          to={`/admin/goal/${item.id}`}
                          style={{
                            textDecoration: "none",
                            color: "black",
                            cursor: "pointer",
                          }}
                          className="link_hover"
                        >
                          <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                            {item.title}
                          </p>
                        </Link>
                      </div>
                      <div>
                        <span onClick={() => deleteGoalData(item.id)}>
                          <RxCross1 style={{ cursor: "pointer" }} />{" "}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="progress"
                        style={{
                          height: "100%",
                          borderRadius: "10px",
                          padding: "0",
                        }}
                      >
                        <div
                          className="progress-bar progress-bar-success"
                          role="progressbar"
                          aria-valuenow={item?.completion_percentage}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: `${item?.completion_percentage}%` }}
                        >
                          {item?.completion_percentage}%
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "end",
                        }}
                      >
                        <div>
                          <h4 style={{ fontWeight: "bold", fontSize: "15px" }}>
                            {item?.time_period}
                          </h4>
                        </div>
                        <div>
                          <div
                            style={{
                              border: "1px solid lightgray",
                              height: "30px",
                              width: "30px",
                              background: "lightgray",
                              borderRadius: "50%",
                            }}
                          >
                            <img
                              src={img_url + item?.ownerable?.profile}
                              height={"100%"}
                              width={"100%"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="pagination-controls">
            {paginationControls}
          </div>
        </div>
      </div>
      <Snackbar
        open={anyFieldEmpty}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          severity="error"
          style={{
            backgroundColor: '#8f2018',
            color: "white",
          }}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Goal;
