import React, { useEffect, useRef, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa6";
import { useNavigate, useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';
import { useReactToPrint } from 'react-to-print'
function CustomSalesSummaryDetail() {
    const params = useParams()
    const navigate = useNavigate()
    const { profit_lossData, getProfit_lossData, accountCom, getAccountCom, } = useData()
    useEffect(() => {
        getProfit_lossData(params?.id)
        getAccountCom()
    }, [])
    const currentCompany = accountCom?.company?.find(item => item.id === parseInt(params?.id));
    const [showNestedTables, setShowNestedTables] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
    });

    const toggleNestedTable = (rowNumber) => {
        setShowNestedTables((prev) => ({
            ...prev,
            [rowNumber]: !prev[rowNumber],
        }));
    }
    const handleClose = () => {
        navigate('/admin/reports');
    }
    // const handleDownloadCsv = () => {
    //     const csvData = [];
    //     const newLine1 = ['',];
    //     const companyNameRow = [
    //         'Budget Overview:',
    //         `${monthlyBudgetIndi?.monthly?.name}`,
    //     ];
    //     const companyNameRow1 = [
    //         `Financial Year:`,
    //         `${monthlyBudgetIndi?.monthly?.finencial_year}`,
    //     ];
    //     const companyNameRow2 = [
    //         `Total Amount during ${monthlyBudgetIndi?.monthly?.finencial_year?.split("(")[0]}:`,
    //         `Rs${monthlyBudgetIndi?.monthly?.total_amount?.toLocaleString()}`,
    //     ];
    //     csvData.push(companyNameRow);
    //     csvData.push(companyNameRow1);
    //     csvData.push(companyNameRow2);
    //     csvData.push(newLine1);
    //     csvData.push(newLine1);

    //     const headerRow = [
    //         'Accounts',
    //         'January',
    //         'February',
    //         'March',
    //         'April',
    //         'May',
    //         'June',
    //         'July',
    //         'August',
    //         'September',
    //         'October',
    //         'November',
    //         'December',
    //         'Total',
    //     ];
    //     csvData.push(headerRow);

    //     const incomeRows = monthlyBudgetIndi?.monthly?.monthly_income.map((item) => {
    //         const row = [item.account?.name || ''];
    //         let totalIncome = 0;

    //         for (let i = 0; i < 12; i++) {
    //             const amount = item[Object.keys(item)[i + 4]];
    //             row.push(amount);
    //             totalIncome += parseFloat(amount || 0);
    //         }

    //         const totalIncomeAmount = item.total_price ? parseFloat(item.total_price) : 0;
    //         row.push(`PRs ${totalIncomeAmount.toLocaleString()}`);
    //         return row;
    //     });

    //     const costOfSalesRows = monthlyBudgetIndi?.monthly?.monthly_cost_of_sale?.map((item) => {
    //         const row = [item.account?.name || ''];
    //         let totalCostOfSales = 0;

    //         for (let i = 0; i < 12; i++) {
    //             const amount = item[Object.keys(item)[i + 4]];
    //             row.push(amount);
    //             totalCostOfSales += parseFloat(amount || 0);
    //         }

    //         const totalCostOfSalesAmount = item.total_price ? parseFloat(item.total_price) : 0;
    //         row.push(`PRs ${totalCostOfSalesAmount.toLocaleString()}`);
    //         return row;
    //     });

    //     const grossProfitRow = ['Gross Profit'];
    //     let totalGrossProfitAmount = 0;

    //     for (let i = 0; i < 12; i++) {
    //         const incomeAmount = incomeRows?.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
    //         const costOfSalesAmount = costOfSalesRows?.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
    //         const grossProfitAmount = incomeAmount - costOfSalesAmount;
    //         totalGrossProfitAmount += grossProfitAmount;
    //         grossProfitRow.push(`PRs ${grossProfitAmount.toLocaleString()}`);
    //     }

    //     grossProfitRow.push(`PRs ${totalGrossProfitAmount.toLocaleString()}`);
    //     const totalExpensesByAccount = [];
    //     const monthlyExpensesRows = monthlyBudgetIndi?.monthly?.monthly_expenses.map((item) => {
    //         const row = [item.account?.name || ''];
    //         let totalExpenseAmountForAccount = 0;

    //         for (let i = 0; i < 12; i++) {
    //             const amount = parseFloat(item[Object.keys(item)[i + 4]] || 0);
    //             row.push(amount);
    //             totalExpenseAmountForAccount += amount;
    //         }

    //         const totalExpenseAmount = parseFloat(item.total_price?.toLocaleString() || 0);
    //         row.push(`PRs ${totalExpenseAmount.toLocaleString()}`);
    //         totalExpensesByAccount.push(totalExpenseAmountForAccount);
    //         return row;
    //     });
    //     const totalExpensesRow = ['Total Expenses Amount'];
    //     for (let i = 0; i < 12; i++) {
    //         const totalAmountForMonth = monthlyExpensesRows?.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
    //         totalExpensesRow.push(`PRs ${totalAmountForMonth.toLocaleString()}`);
    //     }
    //     totalExpensesRow.push(`PRs ${totalExpensesByAccount?.reduce((total, amount) => total + amount, 0)?.toLocaleString()}`);


    //     const netOperatingIncomeRow = ['Net Operating Income'];
    //     let totalNetOperatingIncomeAmount = 0;

    //     for (let i = 0; i < 12; i++) {
    //         const grossProfitAmount = parseFloat(grossProfitRow[i + 1].split(' ')[1].replace(/,/g, '') || 0);
    //         const totalExpensesAmount = parseFloat(totalExpensesRow[i + 1].split(' ')[1].replace(/,/g, '') || 0);
    //         const netOperatingIncomeAmount = grossProfitAmount - totalExpensesAmount;
    //         totalNetOperatingIncomeAmount += netOperatingIncomeAmount;
    //         netOperatingIncomeRow.push(`PRs ${netOperatingIncomeAmount.toLocaleString()}`);
    //     }

    //     netOperatingIncomeRow.push(`PRs ${totalNetOperatingIncomeAmount.toLocaleString()}`);

    //     const otherIncomeRows = monthlyBudgetIndi?.monthly?.monthly_other_income?.map((item) => {
    //         const row = [item.account?.name || ''];
    //         for (let i = 0; i < 12; i++) {
    //             row.push(item[Object.keys(item)[i + 4]]);
    //         }
    //         row.push(`PRs ${item.total_price?.toLocaleString() ? item.total_price?.toLocaleString() : 0}`);
    //         return row;
    //     });

    //     const otherExpenseRows = monthlyBudgetIndi?.monthly?.monthly_other_expenses?.map((item) => {
    //         const row = [item.account?.name || ''];
    //         for (let i = 0; i < 12; i++) {
    //             row.push(item[Object.keys(item)[i + 4]]);
    //         }
    //         row.push(`PRs ${item.total_price?.toLocaleString() ? item.total_price?.toLocaleString() : 0}`);
    //         return row;
    //     });

    //     const netOtherIncomeRow = ['Net Other Income'];
    //     let totalNetOtherIncomeAmount = 0;

    //     for (let i = 0; i < 12; i++) {
    //         const otherIncomeAmount = otherIncomeRows?.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
    //         const otherExpenseAmount = otherExpenseRows?.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
    //         const netOtherIncomeAmount = otherIncomeAmount - otherExpenseAmount;
    //         totalNetOtherIncomeAmount += netOtherIncomeAmount;
    //         netOtherIncomeRow.push(`PRs ${netOtherIncomeAmount.toLocaleString()}`);
    //     }

    //     const netIncomeRow = ['Net Income'];
    //     let totalNetIncomeAmount = 0;

    //     for (let i = 0; i < 12; i++) {
    //         const netOperatingIncomeAmount = parseFloat(netOperatingIncomeRow[i + 1].split(' ')[1].replace(/,/g, '') || 0);
    //         const netOtherIncomeAmount = parseFloat(netOtherIncomeRow[i + 1].split(' ')[1].replace(/,/g, '') || 0);
    //         const netIncomeAmount = netOperatingIncomeAmount + netOtherIncomeAmount;
    //         totalNetIncomeAmount += netIncomeAmount;
    //         netIncomeRow.push(`PRs ${netIncomeAmount.toLocaleString()}`);
    //     }
    //     netOtherIncomeRow.push(`PRs ${totalNetOtherIncomeAmount.toLocaleString()}`);

    //     csvData.push(['Income'], ...incomeRows);
    //     csvData.push(newLine1);
    //     csvData.push(['Cost of Sales'], ...costOfSalesRows);
    //     csvData.push(newLine1);
    //     csvData.push(grossProfitRow);
    //     csvData.push(newLine1);
    //     csvData.push(['Expenses'], ...monthlyBudgetIndi?.monthly?.monthly_expenses.map((item) => {
    //         const row = [item.account?.name || ''];
    //         for (let i = 0; i < 12; i++) {
    //             row.push(item[Object.keys(item)[i + 4]]);
    //         }
    //         row.push(`PRs ${item.total_price?.toLocaleString() ? item.total_price?.toLocaleString() : 0}`);
    //         return row;
    //     }))
    //     csvData.push(newLine1);
    //     csvData.push(totalExpensesRow);
    //     csvData.push(newLine1);
    //     csvData.push(netOperatingIncomeRow);
    //     csvData.push(newLine1);
    //     netIncomeRow.push(`PRs ${totalNetIncomeAmount.toLocaleString()}`);
    //     csvData.push(['Other Income'], ...otherIncomeRows);
    //     csvData.push(newLine1);
    //     csvData.push(['Other Expenses'], ...otherExpenseRows);
    //     csvData.push(newLine1);
    //     csvData.push(netOtherIncomeRow);
    //     csvData.push(newLine1);
    //     csvData.push(netIncomeRow);
    //     csvData.push(newLine1);

    //     const csv = Papa.unparse(csvData, { header: true });
    //     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    //     saveAs(blob, `${monthlyBudgetIndi?.monthly?.name}-${monthlyBudgetIndi?.monthly?.finencial_year?.split("(")[0]}.csv`);
    // };

    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current) {
            handleDownloadPDF();
        }
    }, [cardRef]);
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return;
        }

        setPdfGenerating(true);

        setTimeout(() => {
            const card = cardRef.current;

            const pdfOptions = {
                margin: 0,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };

            if (card) {
                html2pdf()?.from(card)?.set(pdfOptions)?.outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' });

                    // Create a download link
                    const url = URL.createObjectURL(blob);
                    const a = document?.createElement('a');
                    a.href = url;
                    a.download = 'invoice.pdf';
                    a.style.display = 'none';

                    // Append the link to the body and trigger the click
                    document?.body?.appendChild(a);
                    a.click();

                    // Remove the link after the download
                    document?.body?.removeChild(a);

                    setPdfGenerating(false);
                }).catch((error) => {
                    console.error('Error generating PDF:', error);
                    setPdfGenerating(false);
                });
            } else {
                console.error('Card element not found.');
                setPdfGenerating(false);
            }
        }, 0);
    };

    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    });
    const [expenseDebits, setExpenseDebits] = useState(0)
    const [costOfSalesDebit, setCostOfSalesDebit] = useState(0);
    const [incomeDebit, setIncomeDebit] = useState(0);
    const [otherExpenseDebit, setOtherExpenseDebit] = useState(0);
    const [otherIncomeDebit, setOtherIncomeDebit] = useState(0);
    const [subTotal, setSubTotal] = useState(0)

    useEffect(() => {
        let totalDebit = 0;

        profit_lossData?.data?.Expenses?.forEach((item) => {
            if (item?.type_id === 16) {
                item?.expenses?.forEach((trans) => {
                    totalDebit += trans.debit ? parseFloat(trans.debit) : 0;
                });
            }
        });
        setExpenseDebits(totalDebit);
        let costOfSalesTotalDebit = 0;
        profit_lossData?.data?.Cost_of_sales?.forEach((item) => {
            if (item?.type_id === 15) {
                item?.cost_of_sales?.forEach((trans) => {
                    costOfSalesTotalDebit += trans.debit ? parseFloat(trans.debit) : 0;
                });
            }
        });
        setCostOfSalesDebit(costOfSalesTotalDebit);

        // Calculate the sum of debits for Income
        let incomeTotalDebit = 0;
        profit_lossData?.data?.Income?.forEach((item) => {
            if (item?.type_id === 13) {
                item?.income?.forEach((trans) => {
                    incomeTotalDebit += trans.debit ? parseFloat(trans.debit) : 0;
                });
            }
        });
        setIncomeDebit(incomeTotalDebit);

        // Calculate the sum of debits for Other_expense
        let otherExpenseTotalDebit = 0;
        profit_lossData?.data?.Other_expense?.forEach((item) => {
            if (item?.type_id === 17) {
                item?.other_expense?.forEach((trans) => {
                    otherExpenseTotalDebit += trans.debit ? parseFloat(trans.debit) : 0;
                });
            }
        });
        setOtherExpenseDebit(otherExpenseTotalDebit);

        // Calculate the sum of debits for Other_income
        let otherIncomeTotalDebit = 0;
        profit_lossData?.data?.Other_income?.forEach((item) => {
            if (item?.type_id === 14) {
                item?.other_income?.forEach((trans) => {
                    otherIncomeTotalDebit += trans.debit ? parseFloat(trans.debit) : 0;
                });
            }
        });
        setOtherIncomeDebit(otherIncomeTotalDebit);
    }, [profit_lossData]);
    const netIcome = (otherIncomeDebit + incomeDebit) - ((otherExpenseDebit + expenseDebits + costOfSalesDebit))
    console.log(profit_lossData)
    return (
        <>
            <div className='createBudgetData'>
                <div className='row' ref={contentRef}>
                    <div className='card'>
                        <div className='card-body table-responsive recruitment'>
                            <div className='row mb-3'>
                                <h3 style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center" }}>{currentCompany?.name}</h3>
                                <h3 style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center" }}>Sales Summary</h3>
                            </div>
                            <hr />
                            <table className='table table-hover table-bordered' style={{ marginBottom: "2rem" }}>
                                <thead style={{ background: "#f3f5f8", color: "black", fontWeight: "bold" }}>
                                    <tr>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Invoice No.</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Due Date</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan="11">
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Customer Name</p>
                                                <p style={{ marginBottom: "0" }}>234567890</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(1)}>
                                                    {showNestedTables[1] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    {showNestedTables[1] && (
                                        <>
                                            <tr style={{ color: "black" }}>
                                                <td>date</td>
                                                <td>1001</td>
                                                <td>amount</td>
                                            </tr>
                                        </>
                                    )}
                                    <tr>
                                        <th colSpan={'6'} className='text-start'>Customer  name total</th>
                                        <td style={{ fontWeight: "bold" }}>total amount</td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan="11">
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Customer Name</p>
                                                <p style={{ marginBottom: "0" }}>234567890</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(2)}>
                                                    {showNestedTables[2] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    {showNestedTables[2] && (
                                        <>
                                            <tr style={{ color: "black" }}>
                                                <td>date</td>
                                                <td>1001</td>
                                                <td>amount</td>
                                            </tr>
                                        </>
                                    )}
                                    <tr>
                                        <th colSpan={'6'} className='text-start'>Customer  name total</th>
                                        <td style={{ fontWeight: "bold" }}>total amount</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={'6'} className='text-start'>Total</th>
                                        <td style={{ fontWeight: "bold" }}>total amount</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div style={{ background: "white", padding: "1rem", position: "fixed", width: "100%", bottom: "0", left: "0", display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <div>
                        <button onClick={handlePrint} className='btn btn-primary'>Save as PDF</button>
                        {/* <button onClick={handleDownloadCsv} className='btn btn-success mx-3'>Save as XLS</button> */}
                        <button className='btn btn-warning' onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomSalesSummaryDetail 
