import React, { useEffect, useState } from 'react';
import { useData } from '../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import AdminUserForm from './AdminUserForm'
import { CiRead } from 'react-icons/ci';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  bgcolor: 'background.paper',
  boxShadow: 24,
};
function AttandanceRegister() {
  const params = useParams()
  const { getEmployeeAttandance, employeeAttandance, getEmployees, employees, img_url, company, getCompnayCrud, getBranch, branch, getDepartments, getFilterAttandace, getdesignation, } = useData()
  useEffect(() => {
    getEmployees()
    getCompnayCrud()
    getBranch()
    getDepartments()
    getdesignation()
    getEmployeeAttandance()
  }, [])
  console.log(employeeAttandance);
  const [date, setDate] = useState({
    company: '',
    branch: ''
  });

  const handleCompanyChange = (e) => {
    const newCompany = e.target.value;
    setDate((prevDate) => ({ ...prevDate, company: newCompany }));
    if (newCompany === "") {
      filterResults("", date.branch);
    } else {
      filterResults(newCompany, date.branch);
    }
  };

  const handelBranchChange = (e) => {
    const newBranch = e.target.value;
    setDate((prevDate) => ({ ...prevDate, branch: newBranch }));
    filterResults(date.company, newBranch);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = params.id
    ? employeeAttandance?.employees?.filter(item => item?.company?.id == params.id) || []
    : employeeAttandance?.employees || [];

  const totalPages = Math.ceil(currentItems?.length / itemsPerPage);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
        {i}
      </button>
    );
  }
  const filterResults = (company, branch) => {
    getFilterAttandace(company, branch);
    handlePageChange(1)
  };
  return (
    <>
      <div className='employeeData'>
        <div classname="row " style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Register</li>
              </ol>
            </nav>
          </div>
          <div className='d-flex justify-content-end mb-2 gap-2'>
            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
              <select
                disabled={params.id ? true : false}
                className='form-select' style={{ height: "34px", fontSize: "14px" }} value={date.company} onChange={handleCompanyChange}>
                <option value="">Select Company</option>
                {company?.data?.map((item) => {
                  return (
                    <>{item?.deleteStatus == 0 &&
                      <option value={item.name} key={item.name}>
                        {item.name}
                      </option>
                    }
                    </>
                  )
                })}
              </select>
              <select className='form-select' style={{ height: "34px", fontSize: "14px" }} value={date.branch} onChange={handelBranchChange}>
                <option value="">Select Branch</option>
                {branch?.data?.map((item) => (
                  <option value={item.branch} key={item.branch}>
                    {item.branch}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className='row'>
        <div>
          <div className='card'>
            <div className="card-body recruitment table-responsive">
              <table className="table">
                <thead style={{backgroundColor:"#F5F6FA",height:"60px"}}>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Employee</th>
                    <th scope="col">Company</th>
                    <th scope="col">Branch</th>
                    <th scope="col">Project</th>
                    <th scope="col">Task</th>
                    <th scope="col">Clock In</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {params.id ? (
                    <>
                      {currentItems?.slice(indexOfFirstItem, indexOfLastItem)?.map((item, i) => (
                        <tr key={i}>
                          {item?.company?.id == params.id && (
                            <>
                              <th scope="row">{indexOfFirstItem + i + 1}</th>
                              <td scope="col">
                                <Link to={`/admin/employees/profile/${item.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <div style={{ height: "30px", width: "30px", borderRadius: "50%" }}>
                                      <img style={{ borderRadius: "50%" }} src={item.profile !== null ? img_url + item.profile : "https://newvintagewi.org/wp-content/uploads/2021/07/final-resized-male-image.jpg"} width={"100%"} height={"100%"} alt="Profile" />
                                    </div>
                                    <div>
                                      {item.name}
                                    </div>
                                  </div>
                                </Link>
                              </td>
                              <td>{item.company?.name}</td>
                              <td>{item.branch?.branch}</td>
                              <td>
                                <Link to={`/admin/projects/${item.tasks[0]?.project?.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                                  {item.tasks[0]?.project?.title}
                                </Link>
                              </td>
                              <td>
                                <Link to={`/admin/task/${item.tasks[0]?.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                                  {item.tasks[0]?.title}
                                </Link>
                              </td>
                              <td>{item.clock_in[0]?.time}</td>
                              <td>{item.status ? item.status : 'N/A'}</td>
                            </>
                          )}
                        </tr>
                      ))}
                      {currentItems?.length === 0 && (
                        <tr>
                          <td colSpan="8" style={{ textAlign: "center" }}>
                            No Data
                          </td>
                        </tr>
                      )}
                    </>
                  ) : (
                    <>
                      {currentItems?.slice(indexOfFirstItem, indexOfLastItem)?.map((item, i) => (
                        <tr key={i}>
                          <th scope="row">{indexOfFirstItem + i + 1}</th>
                          <td scope="col">
                            <Link to={`/admin/employees/profile/${item.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <div style={{ height: "30px", width: "30px", borderRadius: "50%" }}>
                                  <img style={{ borderRadius: "50%" }} src={item.profile !== null ? img_url + item.profile : "https://newvintagewi.org/wp-content/uploads/2021/07/final-resized-male-image.jpg"} width={"100%"} height={"100%"} alt="Profile" />
                                </div>
                                <div>
                                  {item.name}
                                </div>
                              </div>
                            </Link>
                          </td>
                          <td>{item.company?.name}</td>
                          <td>{item.branch?.branch}</td>
                          <td>
                            <Link to={`/admin/projects/detail/${item.tasks[0]?.project?.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                              {item.tasks[0]?.project?.title}
                            </Link>
                          </td>
                          <td>
                            <Link to={`/admin/task/${item.tasks[0]?.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                              {item.tasks[0]?.title}
                            </Link>
                          </td>
                          <td>{item.clock_in[0]?.time}</td>
                          <td>{item.status ? item.status : 'N/A'}</td>
                        </tr>
                      ))}
                      {currentItems?.length === 0 && (
                        <tr>
                          <td colSpan="8" style={{ textAlign: "center" }}>
                            No Data
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
              <div className="pagination-controls">{paginationControls}</div>

            </div>
          </div>
        </div>
      </div >

    </>
  );
}

export default AttandanceRegister;
