import React, { useEffect } from "react";
import AdminComponent from "../Components/AdminComponent";
import { useNavigate } from "react-router-dom";
import { useData } from "../ContextProvider/Context";
import SideBar from "../Components/Sidebar";

function AdminPannel() {
  const { permissionFunction } = useData();
  const token = localStorage.getItem("user_auth")
    ? localStorage.getItem("user_auth")
    : null;
  const navigate = useNavigate();
  useEffect(() => {
    permissionFunction();
    if (token == null) {
      navigate("/");
    }
    const handleBeforeUnload = () => {
      // Perform cleanup operations before the page is unloaded
      localStorage.removeItem("company_id");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Cleanup the event listener when the component is unmounted
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const compIdIs = 355;
  return <>{token && <SideBar meraProp={compIdIs} />}</>;
}

export default AdminPannel;
