import React, { useEffect, useState, useRef } from "react";

import { CiRead } from "react-icons/ci";
import { useData } from "../ContextProvider/Context";
import { IoIosSearch } from "react-icons/io";
import braicon from "../Images/braicon.png";
import editicon from "../Images/editicon.png";
import deleteicon from "../Images/deleteicon.png";
import JoditEditor from "jodit-react";
import { debounce } from "lodash";
import { Drawer } from "@mui/material";
import darrow from "../Images/drawerarrow.png";
import "@fontsource/nunito";

function Policy() {
  const {
    getCompanyPlcy,
    compnayPlcy,
    setSnackbarValue,
    handleClick,
    base_url,
  } = useData();

  useEffect(() => {
    getCompanyPlcy();
  }, []);
  const [currentDate, setCurrentDate] = useState(new Date());
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentDate(new Date());
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose01 = () => setShow(false);
  const editorRef = useRef(null);
  const [contract, setContract] = useState({ description: "", title: "" });
  const [openDrawer, setopenDrawer] = useState(false);
  const handleDrawerClose = () => {
    setopenDrawer(false);
    setContract({
      description: "", title: ""
    })
  };
  const handleDraweropen = () => {
    setopenDrawer(true);
  };
  const [openDrawer1, setopenDrawer1] = useState(false);
  const handleDrawerClose1 = () => {
    setopenDrawer1(false);


  };
  const handleDraweropen1 = () => {
    setopenDrawer1(true);
  };


  // ...

  const handleContentChange = (content) => {
    setContract({ ...contract, description: content });
  };

  // const debouncedHandleContentChange = useRef(
  //   debounce((content) => {
  //     setContract({ ...contract, description: content });
  //   }, 3000)
  // ).current;

  // // ...

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setContract({ ...contract, description: editorRef.current?.value });
  //   }, 2000); // Adjust the delay as needed

  //   return () => clearTimeout(timeoutId);
  // }, [contract.description, editorRef.current?.value]);




  const handleSubmit = async () => {
    if (contract?.description && contract?.title) {
      if (!contract?.id) {
        fetch(`${base_url}company_policy`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(contract),
        })
          .then((res) => res.json())
          .then((data) => {
            handleClick();
            setSnackbarValue({ message: "Policy Created", color: "#2ECC71" });
            getCompanyPlcy();
            handleDrawerClose()
            setContract({ description: "", title: "" });
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else if (
        contract?.title !== "Hired" &&
        contract?.title !== "Suspended"
      ) {
        fetch(`${base_url}company_policy`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(contract),
        })
          .then((res) => res.json())
          .then((data) => {
            handleClick();
            setSnackbarValue({ message: "Policy Created", color: "#2ECC71" });
            getCompanyPlcy();
            handleDrawerClose()
            setContract({ description: "", title: "" });
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else if (contract?.title == "Hired") {
        handleClick();
        setSnackbarValue({
          message: "Hired Policy is already Created",
          color: "#DC4C64",
        });
        setContract({ description: "", title: "" });
      } else {
        handleClick();
        setSnackbarValue({
          message: "Suspended Policy is already Created",
          color: "#DC4C64",
        });
        setContract({ description: "", title: "" });
      }
    } else {
      handleClick();
      setSnackbarValue({
        message: "Title or Description is Req",
        color: "#DC4C64",
      });
      setContract({ description: "", title: "" });
    }
  };

  const formattedDate = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentPolicies = compnayPlcy?.data?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginationControls = [];
  for (
    let i = 1;
    i <= Math.ceil(compnayPlcy?.data?.length / itemsPerPage);
    i++
  ) {
    paginationControls.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => setCurrentPage(i)}
      >
        {i}
      </button>
    );
  }
  console.log(contract)

  return (
    <>
      <div className="employeeData">
        <div

          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <nav>
              <ul style={{ paddingLeft: "3px" }}>
                <li
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Company Assets
                </li>
                <li>{formattedDate}</li>
              </ul>
            </nav>
          </div>


          <button
            className="btn btn-primary"
            style={{
              padding: "8px 16px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
            onClick={handleDraweropen}
          >
            <img alt='' src={braicon} />
            Create Policy
          </button>

        </div>
      </div>

      <div className="row">
        <div>
          <div className="card">
            <div className="card-body recruitment table-responsive">
              <div
                className="headtable"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <h5>All Policies</h5>

                {/* <div style={{ width: "25%", position: "relative" }}>
                  <IoIosSearch
                    style={{
                      position: "absolute",
                      left: "10",
                      top: "8px",
                      fontSize: "20px",
                    }}
                  />

                  <input
                    style={{ borderRadius: "10px", paddingLeft: "43px" }}
                    type="text"
                    className="form-control"
                    placeholder="Search by Title"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div> */}
              </div>
              <table className="table">
                <thead style={{ background: "ghostwhite", height: "60px" }}>
                  <tr>
                    <th scope="col">Policy ID</th>
                    <th scope="col">Policy Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Action</th>
                    {/* <th scope="col">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentPolicies?.map((item, idx) => (
                    <tr key={item.id || idx}>
                      <th scope="row">{item.id}</th>
                      <td scope="col">{item.title}</td>
                      <td
                        scope="col"
                        dangerouslySetInnerHTML={{
                          __html: item.description?.slice(0, 50),
                        }}
                      />
                      <td scope="col">
                        <button
                          className=""
                          style={{ background: "transparent", border: "none" }}
                          onClick={() => {
                            handleDraweropen1();
                            setContract(item);
                          }}
                        >
                          <CiRead style={{ fontSize: "20px" }} />
                        </button>
                        <button
                          style={{ background: "transparent", border: "none" }}
                          className=""
                          onClick={() => {
                            setContract(item);
                            handleDraweropen();
                          }}
                        >
                          <img alt='' style={{ width: "70%" }} src={editicon} />

                        </button>
                      </td>
                      {/* <td scope="col">
                        <button
                          className=""
                          style={{ background: "transparent", border: "none" }}
                          onClick={()=>{
                            setContract(item)
                          }}
                        >
                          
                          <img alt=''src={editicon} />
                        </button>
                        <button
                        style={{ background: "transparent", border: "none" }}
                        className=""
                       
                      >
                        <img alt=''src={deleteicon} />
                       
                      </button>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination-controls">{paginationControls}</div>
            </div>
          </div>
        </div>

        <Drawer
          className="mypolicy-drawer"
          anchor="right"
          open={openDrawer}
          onClose={handleDrawerClose}

        >
          <div style={{ position: "relative" }}>
            <button onClick={handleDrawerClose} style={{ position: "absolute", bottom: "4px", left: "2px", background: "none", border: "none" }}>
              <img alt='' src={darrow} />
            </button>
          </div>
          <div style={{ marginBottom: "10px" }}><h4> {contract?.id ? "Edit Policy" : "Create Policy"}</h4></div>
          <div className="card" style={{ padding: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <div style={{ width: "35%" }}>
                <nav aria-label="breadcrumb">
                  <form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: "10px",
                      textAlign: "left",
                    }}
                  >
                    <label>
                      <span>Policy Title</span>
                    </label>
                    <input
                      type="text"
                      style={{
                        border: "1px solid lightgray",
                        padding: "12px",
                        marginRight: "1rem",
                        borderRadius: "10px",
                        marginBottom: "20px",
                      }}
                      disabled={
                        contract?.title == "Hired" ||
                          contract?.title == "Suspended"
                          ? true
                          : false
                      }
                      value={contract?.title}
                      onChange={(e) =>
                        setContract({ ...contract, title: e.target.value })
                      }
                      placeholder="Name"
                    />
                  </form>
                </nav>
              </div>
              <div className="d-flex justify-content-end mb-2 gap-2">
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  style={{ padding: ".5rem 1rem" }}
                >
                  {contract?.id ? "update" : "Add"}
                </button>
              </div>
            </div>
            <div className="card-body" style={{ padding: "0px" }}>
              <JoditEditor
                ref={editorRef}
                value={contract?.description}
                tabIndex={1}
                onBlur={(newContent) =>
                  setContract({ ...contract, description: newContent })
                }
              />
            </div>
          </div>
        </Drawer>
        <Drawer
          className="mypolicy-drawer"
          anchor="right"
          open={openDrawer1}
          onClose={handleDrawerClose1}

        >
          <div style={{ position: "relative" }}>
            <button onClick={handleDrawerClose1} style={{ position: "absolute", bottom: "4px", left: "2px", background: "none", border: "none" }}>
              <img alt='' src={darrow} />
            </button>
          </div>
          <div style={{
            marginBottom: "10px", fontSize: "24px",
            fontWeight: "700",
            fontStyle: "normal",
            fontFamily: "nunito",
            color: "#667085",
          }}><h4> Policy</h4></div>
          <div className="card" style={{
            padding: "15px", minHeight: "80vh"
          }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <div>
                <p style={{ fontSize: "40px", fontWeight: "bold" }}>{contract?.title}</p>
              </div>

            </div>
            <div className="card-body myjodit" style={{
              padding: "0px",
            }}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontStyle: "normal",
                  fontFamily: "Nunito",
                  marginBottom: "0",
                  PointerEvent: "none",
                  UserActivation: "none",
                }}
                dangerouslySetInnerHTML={{ __html: contract?.description }}
              ></p>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
}

export default Policy;
