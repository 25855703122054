import React, { useEffect, useState } from 'react'
import { BiUser, BiMessageDetail } from 'react-icons/bi'
import { TbFileInvoice } from 'react-icons/tb'
import { MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { RiAccountPinCircleLine } from 'react-icons/ri'
import { BsFillImageFill } from 'react-icons/bs'
import { GrServices } from 'react-icons/gr'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import { CiMenuKebab, CiTrash } from 'react-icons/ci'
import { lowerCase } from 'lodash'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function Services() {
    const params = useParams()
    const { deleteCustomerEstimate, deleteCustomerInvoice, handleClick, setSnackbarValue, accountsData, getAccountsData, getInvoiceData, invoiceData, img_url, storeInvoiceData, getInvoiceDetailData, invoiceDetailData, getFilterInvoice, postPaidStatus } = useData()
    useEffect(() => {
        getInvoiceData()
        getInvoiceDetailData()
        getAccountsData()
    }, [])
    const [selectedCompany, setSelectedCompany] = useState(params.id || null)
    const filteredCompanies = accountsData?.company?.filter(item => item.deleteStatus === 0);
    const filteredService = accountsData?.services?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));
    const filteredClient = accountsData?.client?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));
    const filteredAccount = accountsData?.account?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj({
            company_id: params.id ? params.id : selectedCompany,
            client_id: "",
            service_id: "",
            account_id: "",
            invoice_date: new Date().toISOString().substr(0, 10),
            due_date: "",
            description: "",
            message: "",
            invoice_no: "",
            term: "",
            image: ""
        });
        setSelectedImage(null);
        setOpen(false)
    };
    const [obj, setObj] = useState(
        {
            company_id: params.id ? params.id : selectedCompany,
            client_id: "",
            service_id: "",
            account_id: "",
            invoice_date: new Date().toISOString().substr(0, 10),
            due_date: "",
            description: "",
            message: "",
            invoice_no: "",
            term: "",
            image: ""
        }
    )

    const [selectedImage, setSelectedImage] = useState(null);
    const [showImageOverlay, setShowImageOverlay] = useState(false);
    const [selectedImageURL, setSelectedImageURL] = useState('');


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64URL = event.target.result;
                const parts = base64URL.split(',');
                const base64ImageData = parts[1];
                setObj({
                    ...obj,
                    image: base64ImageData,
                });
                const imgBox = document.getElementById('image-box');
                imgBox.src = base64URL;
            };
            reader.readAsDataURL(file);
        }
    };
    const clearFields = () => {
        setObj({
            company_id: params.id ? params.id : selectedCompany,
            client_id: "",
            service_id: "",
            account_id: "",
            invoice_date: new Date().toISOString().substr(0, 10),
            due_date: "",
            description: "",
            message: "",
            invoice_no: "",
            term: "",
            image: ""
        });

        setSelectedImage(null);
    };
    const isFormValid = () => {
        for (const key in obj) {
            if (obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = (e) => {
        if (isFormValid()) {
            storeInvoiceData(obj);
            clearFields();
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const [service, setService] = useState([])
    const [client, setClient] = useState([])
    const clientAddress = client[0]?.bill_address + " " + client[0]?.bill_city + " " + client[0]?.bill_country + " " + client[0]?.bill_province + " " + client[0]?.bill_postal

    const [date, setdate] = useState({
        company: '',
        startDate: "",
        endDate: "",
        type: '',
    });


    const handleTermChange = (e) => {
        const selectedTerm = parseInt(e.target.value);
        if (selectedTerm >= 0) {
            if (selectedTerm === 0) {
                const currentDate = new Date();
                const formattedDueDate = currentDate.toISOString().substr(0, 10);

                setObj({
                    ...obj,
                    term: selectedTerm,
                    due_date: formattedDueDate,
                });
            } else {
                const currentDate = new Date();
                currentDate.setDate(currentDate.getDate() + selectedTerm);

                const formattedDueDate = currentDate.toISOString().substr(0, 10);

                setObj({
                    ...obj,
                    term: selectedTerm,
                    due_date: formattedDueDate,
                });
            }
        }
    };
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleInvoices = params.id
        ? invoiceData?.AllSales?.filter(item => item?.company?.id == params.id) || []
        : invoiceData?.AllSales || [];

    const currentInvoices = visibleInvoices?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(visibleInvoices.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const [isOpenArray, setIsOpenArray] = useState(Array(currentInvoices.length).fill(false));
    const handleToggleDropdown = (idx) => {
        const newIsOpenArray = [...isOpenArray];
        newIsOpenArray[idx] = !newIsOpenArray[idx];
        setIsOpenArray(newIsOpenArray);
    }

    return (
        <>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>

                    <div className='inp-buttons d-flex gap-2 w-100' style={{ alignItems: "end", justifyContent: "space-between" }}>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Invoice</li>
                                </ol>
                            </nav>
                        </div>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                                <div>
                                    <label style={{ fontWeight: "bold" }}>Company</label> <br />
                                    <select disabled={params.id ? true : false} className='form-select' onChange={(e) => setdate({ ...date, company: e.target.value })}>
                                        <option value="">Select a company</option>
                                        {filteredCompanies?.map((item) => (
                                            <option key={item.id} value={item.name}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label style={{ fontWeight: "bold" }}>Type</label> <br />
                                    <select className='form-select' onChange={(e) => setdate({ ...date, type: e.target.value })}>
                                        <option value="">Select Type</option>
                                        <option value="Invoice">Invoice</option>
                                        <option value="Estimate">Estimate</option>
                                        <option value="Sale Receipt">Sale Receipt</option>
                                        <option value="Payment">Payment</option>
                                        <option value="Credit Memo">Credit Memo</option>
                                        <option value="Delayed Charge">Delayed Charge</option>
                                        <option value="Statement">Statement</option>
                                        <option value="Time Activity">Time Activity</option>
                                    </select>
                                </div>
                                <div className='startdate'>
                                    <label style={{ fontWeight: "bold" }}>Invoice Start Date</label> <br />
                                    <input type='date' name='start' onChange={(e) => setdate({ ...date, startDate: e.target.value })} className='form-control' />
                                </div>
                                <div className='startdate'>
                                    <label style={{ fontWeight: "bold" }}>Invoice End Date</label> <br />
                                    <input type='date' name='end' onChange={(e) => setdate({ ...date, endDate: e.target.value })} className='form-control' />
                                </div>
                                <button onClick={() => {
                                    handlePageChange(1)
                                    getFilterInvoice(date.startDate, date.endDate, date.company, date.type)
                                }} className='btn btn-primary'>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Invoice Date</th>
                                    <th scope="col">Due Date </th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Screenshot</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {params?.id ? currentInvoices?.map((item, idx) => {
                                    const truncatedDescription = item.description?.slice(0, 20);
                                    const isDescriptionTruncated = item.description?.length > 20;
                                    const getTemplateLink = () => {
                                        if (item.type === 'Invoice') {
                                            return `/admin/templates/${item.id}`;
                                        } else if (item.type === 'Sale Receipt') {
                                            return `/admin/salesrecipt/${item.id}`;
                                        } else if (item.type === 'Estimate') {
                                            return `/admin/estimates/${item.id}`;
                                        } else {
                                            return '/admin/accounts/banking/Invoice';
                                        }
                                    };
                                    return (
                                        <>
                                            {item?.company?.id == params?.id &&
                                                <tr key={item.id}>
                                                    <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                    <td>{item.company?.name}</td>
                                                    <td>
                                                        <Link
                                                            to={`/admin/${item.type?.split(" ")[0]}/${item.id}`}
                                                            style={{ textDecoration: "none", color: "black" }}
                                                            className='link_hover'
                                                        >
                                                            {item.type}
                                                        </Link>
                                                    </td>
                                                    <td>{item.invoice_date ? item.invoice_date : '-'}</td>
                                                    <td>{item.due_date ? item.due_date : '-'}</td>
                                                    <td>{item.client?.name}</td>
                                                    <td>
                                                        {isDescriptionTruncated ? (
                                                            <div
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setSelectedDescription(item.description);
                                                                    setShowDescriptionOverlay(true);
                                                                }}
                                                            >
                                                                {truncatedDescription}...
                                                            </div>
                                                        ) : (
                                                            item.description
                                                        )}
                                                    </td>
                                                    <td style={{ display: "flex", justifyContent: "center", paddingTop: "21px" }}>
                                                        <div style={{ width: '30px', height: '30px' }}>
                                                            <img style={{ width: '100%', height: '100%' }}
                                                                onClick={() => {
                                                                    setSelectedImageURL(img_url + item.image);
                                                                    setShowImageOverlay(true);
                                                                }}
                                                                src={img_url + item.image} alt='' />
                                                        </div>
                                                    </td>
                                                    {showDescriptionOverlay && (
                                                        <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                            <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                                <p style={{ color: "white" }}>{selectedDescription}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <td>{item?.type === 'Payment' ? '-' : item.status === '0' ? 'unpaid' : 'paid'}</td>
                                                    <td>{item.total_amount ? item.total_amount : item?.invoice?.total_amount}</td>
                                                    <td>
                                                        <div className="custom-dropdown" style={{ position: "relative" }}>
                                                            <button className="btn btn-primary p-2 m-1" onClick={() => handleToggleDropdown(idx)}>
                                                                <CiMenuKebab />
                                                            </button>
                                                            {isOpenArray[idx] && (
                                                                <div className="dropdown-options card" style={{ position: "absolute", left: "-70px", padding: "1rem", textAlign: "left", zIndex: "99" }}>
                                                                    <ul className='p-0'>
                                                                        {(item?.type === 'Invoice' || item?.type === 'Estimate' || item?.type === 'Sale Receipt') && (
                                                                            <li style={{ fontSize: "16px" }}>
                                                                                <Link
                                                                                    to={getTemplateLink()}
                                                                                    className='link_hover'
                                                                                    style={{ color: "inherit" }}
                                                                                >
                                                                                    {item.type === 'Invoice' && 'Change Template'}
                                                                                    {item.type === 'Sale Receipt' && 'Sales Receipt Template'}
                                                                                    {item.type === 'Estimate' && 'Estimate Template'}
                                                                                </Link>
                                                                            </li>
                                                                        )}
                                                                        <li style={{ fontSize: "16px" }}>
                                                                            {item.status === "paid" ? (
                                                                                <button className='btn btn-primary' disabled>
                                                                                    Already Paid
                                                                                </button>
                                                                            ) : (
                                                                                <button className='btn btn-primary' onClick={() => postPaidStatus(item.id)}>
                                                                                    Pay Now
                                                                                </button>
                                                                            )}
                                                                        </li>
                                                                        <li style={{ fontSize: "16px" }}>
                                                                            <button className='btn btn-danger p-2 m-1'>
                                                                                <CiTrash style={{ fontSize: "20px" }} onClick={() => deleteCustomerEstimate(lowerCase(item?.type?.split(" ")[0]), item.id)} />
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            )}

                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </>

                                    )
                                }) : currentInvoices?.map((item, idx) => {
                                    const truncatedDescription = item.description?.slice(0, 20);
                                    const isDescriptionTruncated = item.description?.length > 20;
                                    const getTemplateLink = () => {
                                        if (item.type === 'Invoice') {
                                            return `/admin/templates/${item.id}`;
                                        } else if (item.type === 'Sale Receipt') {
                                            return `/admin/salesrecipt/${item.id}`;
                                        } else if (item.type === 'Estimate') {
                                            return `/admin/estimates/${item.id}`;
                                        } else {
                                            return '/admin/accounts/banking/Invoice';
                                        }
                                    };
                                    return (
                                        <tr key={item.id}>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{item.company?.name}</td>
                                            <td>
                                                <Link
                                                    to={`/admin/${item.type?.split(" ")[0]}/${item.id}`}
                                                    style={{ textDecoration: "none", color: "black" }}
                                                    className='link_hover'
                                                >
                                                    {item.type}
                                                </Link>
                                            </td>
                                            <td>{item.invoice_date ? item.invoice_date : '-'}</td>
                                            <td>{item.due_date ? item.due_date : '-'}</td>
                                            <td>{item.client?.name}</td>
                                            <td>
                                                {isDescriptionTruncated ? (
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedDescription(item.description);
                                                            setShowDescriptionOverlay(true);
                                                        }}
                                                    >
                                                        {truncatedDescription}...
                                                    </div>
                                                ) : (
                                                    item.description
                                                )}
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "center", paddingTop: "21px" }}>
                                                <div style={{ width: '30px', height: '30px' }}>
                                                    <img style={{ width: '100%', height: '100%' }}
                                                        onClick={() => {
                                                            setSelectedImageURL(img_url + item.image);
                                                            setShowImageOverlay(true);
                                                        }}
                                                        src={img_url + item.image} alt='' />
                                                </div>
                                            </td>
                                            {showDescriptionOverlay && (
                                                <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                    <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                        <p style={{ color: "white" }}>{selectedDescription}</p>
                                                    </div>
                                                </div>
                                            )}
                                            <td>{item?.type === 'Payment' ? '-' : item.status === '0' ? 'unpaid' : 'paid'}</td>
                                            <td>{item.total_amount ? item.total_amount : item?.invoice?.total_amount}</td>
                                            <td>
                                                <div className="custom-dropdown" style={{ position: "relative" }}>
                                                    <button className="btn btn-primary p-2 m-1" onClick={() => handleToggleDropdown(idx)}>
                                                        <CiMenuKebab />
                                                    </button>
                                                    {isOpenArray[idx] && (
                                                        <div className="dropdown-options card" style={{ position: "absolute", left: "-70px", padding: "1rem", textAlign: "left", zIndex: "99" }}>
                                                            <ul className='p-0'>
                                                                {(item?.type === 'Invoice' || item?.type === 'Estimate' || item?.type === 'Sale Receipt') && (
                                                                    <li style={{ fontSize: "16px" }}>
                                                                        <Link
                                                                            to={getTemplateLink()}
                                                                            className='link_hover'
                                                                            style={{ color: "inherit" }}
                                                                        >
                                                                            {item.type === 'Invoice' && 'Change Template'}
                                                                            {item.type === 'Sale Receipt' && 'Sales Receipt Template'}
                                                                            {item.type === 'Estimate' && 'Estimate Template'}
                                                                        </Link>
                                                                    </li>
                                                                )}
                                                                <li style={{ fontSize: "16px" }}>
                                                                    {item.status === "paid" ? (
                                                                        <button className='btn btn-primary' disabled>
                                                                            Already Paid
                                                                        </button>
                                                                    ) : (
                                                                        <button className='btn btn-primary' onClick={() => postPaidStatus(item.id)}>
                                                                            Pay Now
                                                                        </button>
                                                                    )}
                                                                </li>
                                                                <li style={{ fontSize: "16px" }}>
                                                                    <button className='btn btn-danger p-2 m-1'>
                                                                        <CiTrash style={{ fontSize: "20px" }} onClick={() => deleteCustomerEstimate(lowerCase(item?.type?.split(" ")[0]), item.id)} />
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )}

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                        <div>
                            {paginationControls.map((button, index) => (
                                <React.Fragment key={index}>{button}</React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
                {showImageOverlay && (
                    <div className="overlayy" style={{ background: "rgba(0,0,0,0.38)" }} onClick={() => setShowImageOverlay(false)}>
                        <img
                            src={selectedImageURL}
                            alt="Full-Screen Image"
                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default Services

