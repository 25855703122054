import React, { useEffect, useState } from "react";
import { useData } from "../ContextProvider/Context";
import { Link, useParams } from "react-router-dom";
import { CiEdit, CiTrash } from "react-icons/ci";
// import { Form } from "antd";
import { Alert, Box, Snackbar } from "@mui/material";
import filter from "../Images/filtericon.png";
import cross from "../Images/filtercross.png";
import vector from "../Images/Vector.png";
import { Drawer, IconButton, Card, CardContent } from "@mui/material";
import depicon1 from "../Images/depicon1.png";
import editicon from "../Images/editicon.png";
import deleteicon from "../Images/deleteicon.png";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import depicon from "../Images/depicon.png";
import downarrow from "../Images/chevron-down.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
function AllDepartments() {
  const params = useParams();
  const {
    company,
    delDepartment,
    editDepartment,
    getCompnayCrud,
    branch,
    department,
    getFilterCompany,
    getDepartments,
    getBarnchById,
    storeDepartmentData,
    permission,
    getFilterDepartment
  } = useData();
  const [departmentData, setDepartments] = useState({
    companyId: params.id ? params.id : "",
    branchId: "",
    department: "",
  });

  useEffect(() => {
    getCompnayCrud();
    getDepartments();
  }, []);

  const [date, setDate] = useState({
    company: "",
    branch: "",
  });

  const handleCompanyChange = (e) => {
    const newCompany = e.target.value;
    setDate((prevDate) => ({ ...prevDate, company: newCompany }));
    if (newCompany === "") {
      filterResults(
        "",
        date.branch,
        date.department,
      );
    } else {
      filterResults(
        newCompany,
        date.branch,
      );
    }
  };

  const handelBranchChange = (e) => {
    const newBranch = e.target.value;
    setDate((prevDate) => ({ ...prevDate, branch: newBranch }));
    filterResults(
      date.company,
      newBranch,
    );
  };
  const filterResults = (company, branch,) => {
    getFilterDepartment(company, branch,);
    handlePageChange(1);
  };
  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(true);
  const handleClose01 = () => setShow(false);
  const handleClearFields = () => {
    setDepartments({
      companyId: params.id ? params.id : "",
      branchId: "",
      department: "",
    });
  };
  const state = {
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
  }
  const { vertical, horizontal } = state;
  const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleAddDepartment = () => {
    if (departmentData?.companyId?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Company is required.');
    } else if (departmentData?.branchId?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Branch is required.');
    } else if (departmentData?.department?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Department is required.');
    } else {
      storeDepartmentData(departmentData)
      handleClearFields();
      handleClose01();
      handleCloseSnackbar();

    }
  }
  const handleUpdateDepartment = () => {
    if (departmentData?.department?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Department is required.');
    } else {
      editDepartment(departmentData)
      handleClearFields();
      handleClose01();
      handleCloseSnackbar();

    }
  }
  const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
  };


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = params.id
    ? department?.data?.filter((item) => item?.company?.id == params.id) || []
    : department?.data || [];
  const [openDrawer, setOpenDrawer] = React.useState(false);
  console.log(department)
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const [currentDate, setCurrentDate] = useState(new Date());


  const formattedDate = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const totalPages = Math.ceil(currentItems.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }
  return (
    <>
      <div className="departmentData">
        {/* Filter box content */}
        <Drawer anchor="right" open={openDrawer} onClose={handleDrawerClose}>
          <div
            className="filterparent"
            style={{
              // position: "absolute",
              // right: "0",
              // top: "0",
              background: "white",
              padding: "18px",
              zIndex: "99",
              display: "felx",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {/* <div
              className="fpchild1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "160px",
              }}
            >
              <span style={{ fontSize: "22px", fontSize: "bold" }}>Filter</span>
              <button
                onClick={handleDrawerClose}
                style={{ background: "none", border: "none" }}
              >
                <img alt='' src={cross} />
              </button>
            </div> */}
            <div
              className="form-group"
              style={{
                marginBottom: "20px",
                textAlign: "left",
                position: "relative",
              }}
            >
              <label style={{ marginBottom: "5px" }} htmlFor="company">
                Company
              </label>
              <img
                style={{ position: "absolute", right: "16px", top: "40px" }}
                src={downarrow}
              />
              <select
                id="company"
                style={{
                  width: "100%",
                  padding: "7px",
                }}
                className="form-control"
                disabled={params.id ? true : false}
                onChange={handleCompanyChange}
                value={date.company}
              >
                <option hidden> Company</option>
                <option value={""}>All Company</option>
                {company?.data?.map((item) => {
                  return (
                    <>
                      {item?.deleteStatus == 0 && (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      )}
                    </>
                  );
                })}
              </select>
            </div>
            <div
              className="form-group"
              style={{
                marginBottom: "20px",
                textAlign: "left",
                position: "relative",
              }}
            >
              <label style={{ marginBottom: "5px" }} htmlFor="company">
                Branch
              </label>
              <img
                style={{ position: "absolute", right: "16px", top: "40px" }}
                src={downarrow}
              />
              <select
                id="company"
                style={{
                  width: "100%",
                  padding: "7px",
                }}
                className="form-control"
                onChange={handelBranchChange}
                value={date.branch}
              >
                <option hidden> Branch</option>
                <option value={""}>All Branch</option>
                {branch?.data?.map((item) => {
                  return (
                    <>
                      {item?.deleteStatus == 0 && (
                        <option value={item.id} key={item.id}>
                          {item.branch}
                        </option>
                      )}
                    </>
                  );
                })}
              </select>
            </div>
          </div>
        </Drawer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <nav>
              <ul style={{ paddingLeft: "3px" }}>
                <li
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Company Assets
                </li>
                <li>{formattedDate}</li>
              </ul>
            </nav>
          </div>
          <div className="d-flex justify-content-end mb-2 gap-2">
            <div>
              {permission.includes("Department Create") && (
                <button
                  className="btn btn-primary"
                  onClick={handleOpen}
                  style={{
                    padding: ".5rem 1rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <img alt='' src={depicon1} />
                  <span>Add Department</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div>
            <div className="card">
              <div className="card-body">
                <div
                  className="headtable"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "100px",
                    }}
                  >
                    <h5>All Departments</h5>

                  </div>
                  <div>
                    {/* <button
                      onClick={handleDrawerOpen}
                      style={{
                        background: "transparent",
                        borderRadius: "10px",
                        border: "1px solid darkgray",
                        padding: "2px 5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Filter
                      <img
                        style={{ width: "20%", marginLeft: "2px" }}
                        src={filter}
                      />
                    </button> */}
                  </div>
                </div>
                <table className="table">
                  <thead style={{ background: "ghostwhite" }}>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">Dept ID</th>
                      <th style={{ textAlign: "center" }} scope="col">Department</th>
                      <th style={{ textAlign: "center" }} scope="col">Branch</th>
                      <th style={{ textAlign: "center" }} scope="col">Company</th>
                      <th style={{ textAlign: "center" }} scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {params.id
                      ? currentItems
                        .slice(indexOfFirstItem, indexOfLastItem)
                        ?.map((item, idx) => {
                          return (
                            <>
                              {params.id == item.company.id && (
                                <tr>
                                  <th scope="row">
                                    {indexOfFirstItem + idx + 1}
                                  </th>
                                  <td>
                                    <Link className="link linkk">
                                      <span
                                        style={{
                                          background: "orange",
                                          padding: "5px",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        {item.department}
                                      </span>
                                    </Link>
                                  </td>
                                  <td>{item.branch.branch}</td>
                                  <td>{item.company.name}</td>
                                  <td>
                                    {permission.includes(
                                      "Department Edit"
                                    ) && (
                                        <button
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            handleOpen();
                                            setDepartments(item);
                                          }}
                                        >
                                          <img alt='' src={editicon} />
                                        </button>
                                      )}
                                    {permission.includes(
                                      "Department Delete"
                                    ) && (
                                        <button
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                          }}
                                          className=""
                                          onClick={() => delDepartment(item.id)}
                                        >
                                          <img alt='' src={deleteicon} />
                                        </button>
                                      )}
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })
                      : currentItems
                        .slice(indexOfFirstItem, indexOfLastItem)
                        ?.map((item, idx) => {
                          return (
                            <tr style={{ textAlign: "center" }}>
                              <th style={{ textAlign: "center" }} scope="row">
                                {indexOfFirstItem + idx + 1}
                              </th>
                              <td style={{ textAlign: "center" }}>
                                <Link className="link linkk">
                                  <span
                                    style={{
                                      background: "orange",
                                      padding: "5px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    {item.department}
                                  </span>
                                </Link>
                              </td>
                              <td style={{ textAlign: "center" }}>{item.branch.branch}</td>
                              <td style={{ textAlign: "center" }}>{item.company.name}</td>
                              <td style={{ textAlign: "center" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  {permission.includes("Department Edit") && (
                                    <button
                                      style={{
                                        background: "transparent",
                                        border: "none",
                                      }}
                                      onClick={() => {
                                        handleOpen();
                                        setDepartments(item);
                                      }}
                                    >
                                      <img alt='' src={editicon} />
                                    </button>
                                  )}
                                  {permission.includes("Department Delete") && (
                                    <button
                                      style={{
                                        background: "transparent",
                                        border: "none",
                                      }}
                                      className=""
                                      onClick={() => delDepartment(item.id)}
                                    >
                                      <img alt='' src={deleteicon} />
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
                <div className="pagination-controls">{paginationControls}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        style={{ zIndex: "9999", marginTop: "20px !important" }}
        show={show}
        onHide={handleClose01}
      >
        <Modal.Header
          closeButton
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <img
            style={{
              width: "70px",
              background: "gainsboro",
              borderRadius: "50%",
            }}
            src={depicon}
          />
          <span style={{ fontSize: "24px", color: "#667085" }}>
            {departmentData?.id ? "Edit Department" : " Add Department"}
          </span>
        </Modal.Header>
        <Modal.Body>
          <div
            className="form-group"
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label style={{ marginBottom: "5px" }} htmlFor="company">
              Company<span style={{ color: "red" }}>*</span>
            </label>
            <select
              style={{ borderRadius: "10px", width: "100%" }}
              placeholder=" Company"
              disabled={params.id ? true : false}

              value={departmentData.companyId}
              onChange={(e) => {
                setDepartments({
                  ...departmentData,
                  companyId: e.target.value,
                });
                getBarnchById(e.target.value);
              }}
              className="form-select"
            >
              <option hidden> Company</option>
              {company?.data?.map((item) => {
                return (
                  <>
                    {item?.deleteStatus == 0 && (
                      <option value={item.id} key={item.name}>
                        {item.name}
                      </option>
                    )}
                  </>
                );
              })}
            </select>
          </div>

          <div
            className="form-group"
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label htmlFor="brancName">Branch<span style={{ color: "red" }}>*</span></label>
            <select
              style={{ borderRadius: "10px", width: "100%" }}
              placeholder="Select Branch"

              value={departmentData.branchId}
              onChange={(e) => {
                setDepartments({
                  ...departmentData,
                  branchId: e.target.value,
                });
              }}
              disabled={!branch}
              className="form-select"
            >
              <option hidden>Name</option>
              {branch?.data?.map((b) => (
                <option key={b.id} value={b.id}>
                  {b.branch}
                </option>
              ))}
            </select>
          </div>

          <InputGroup className="mb-3">
            <Form.Group
              className="mb-3"
              controlId="formGroupName"
              style={{ width: "100%", textAlign: "left" }}
            >
              <Form.Label>Department<span style={{ color: "red" }}>*</span></Form.Label>
              <Form.Control
                style={{ borderRadius: "10px", padding: "7px" }}
                type="text"
                placeholder="Department"
                value={departmentData.department}
                onChange={(e) => {
                  setDepartments({
                    ...departmentData,
                    department: e.target.value,
                  });
                }}
              />
            </Form.Group>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              width: "38%",
              background: "white",
              color: "black",
              boxShadow: "2px 3px 3px black",
            }}
            onClick={() => {
              handleClose01();
              handleClearFields();
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ width: "38%" }}
            variant="primary"
            onClick={() => {
              departmentData?.id
                ? handleUpdateDepartment()
                : handleAddDepartment();
            }}
          >
            {departmentData?.id ? "Update Department" : " Create Department"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Snackbar
        open={anyFieldEmpty}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          severity="error"
          style={{
            backgroundColor: '#8f2018',
            color: "white",
          }}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AllDepartments;
