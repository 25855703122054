import React, { useEffect, useRef, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa6";
import { useNavigate, useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';
import { useReactToPrint } from 'react-to-print'
function ShowQuartlyBudget() {
    const params = useParams()
    const navigate = useNavigate()
    const { quarterlyBudgetIndi, getQuarterlyBudgetIndi, getClientData, clientData, company, getCompnayCrud, updateQuarterlyBudget, getBudgetAcData, budgetAcData } = useData()
    useEffect(() => {
        getBudgetAcData()
        getCompnayCrud()
        getClientData()
        getQuarterlyBudgetIndi(params.id)
    }, [])
    const [showNestedTables, setShowNestedTables] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
    });

    const toggleNestedTable = (rowNumber) => {
        setShowNestedTables((prev) => ({
            ...prev,
            [rowNumber]: !prev[rowNumber],
        }));
    };
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalIncome1, setTotalIncome1] = useState(0);
    const [totalIncome2, setTotalIncome2] = useState(0);
    const [totalIncome3, setTotalIncome3] = useState(0);
    const [totalIncome4, setTotalIncome4] = useState(0);
    useEffect(() => {
        setQuartylyBudget({
            id: quarterlyBudgetIndi?.quarterly?.id,
            company_id: quarterlyBudgetIndi?.quarterly?.company_id,
            name: quarterlyBudgetIndi?.quarterly?.name,
            finencial_year: quarterlyBudgetIndi?.quarterly?.finencial_year,
            interval: 'Quarterly',
            pre_fill: quarterlyBudgetIndi?.quarterly?.pre_fill,
            subdivided: quarterlyBudgetIndi?.quarterly?.subdivided,
            total_amount: parseInt(quarterlyBudgetIndi?.quarterly?.total_amount),
            client_id: quarterlyBudgetIndi?.quarterly?.client_id,
            quartery_budget: {
                income: quarterlyBudgetIndi?.quarterly?.quartery_income?.map((item) => ({
                    account_id: item.account_id,
                    jan_to_mar: item.jan_to_mar,
                    apr_to_jun: item.apr_to_jun,
                    july_to_sep: item.july_to_sep,
                    oct_to_dec: item.oct_to_dec,
                    total_price: item.total_price,
                })),
                other_income: quarterlyBudgetIndi?.quarterly?.quartery_other_income?.map((item) => ({
                    account_id: item.account_id,
                    jan_to_mar: item.jan_to_mar,
                    apr_to_jun: item.apr_to_jun,
                    july_to_sep: item.july_to_sep,
                    oct_to_dec: item.oct_to_dec,
                    total_price: item.total_price,
                })),
                cost_of_sale: quarterlyBudgetIndi?.quarterly?.quartery_cost_of_sale?.map((item) => ({
                    account_id: item.account_id,
                    jan_to_mar: item.jan_to_mar,
                    apr_to_jun: item.apr_to_jun,
                    july_to_sep: item.july_to_sep,
                    oct_to_dec: item.oct_to_dec,
                    total_price: item.total_price,
                })),
                expenses: quarterlyBudgetIndi?.quarterly?.quartery_expenses?.map((item) => ({
                    account_id: item.account_id,
                    jan_to_mar: item.jan_to_mar,
                    apr_to_jun: item.apr_to_jun,
                    july_to_sep: item.july_to_sep,
                    oct_to_dec: item.oct_to_dec,
                    total_price: item.total_price,
                })),
                other_expenses: quarterlyBudgetIndi?.quarterly?.quartery_other_expenses?.map((item) => ({
                    account_id: item.id,
                    jan_to_mar: item.jan_to_mar,
                    apr_to_jun: item.apr_to_jun,
                    july_to_sep: item.july_to_sep,
                    oct_to_dec: item.oct_to_dec,
                    total_price: item.total_price,
                })),
            }
        })
    }, [quarterlyBudgetIndi])

    const [quartlyBudget, setQuartylyBudget] = useState({
        id: params?.id,
        company_id: '',
        name: '',
        finencial_year: '',
        interval: 'Quaterly',
        pre_fill: '',
        subdivided: '',
        total_amount: '',
        client_id: '',
        quartery_budget: {
            income: budgetAcData?.income?.map((item) => ({
                account_id: item.id,
                jan_to_mar: '',
                apr_to_jun: '',
                july_to_sep: '',
                oct_to_dec: '',
                total_price: '',
            })),
            other_income: budgetAcData?.other_income?.map((item) => ({
                account_id: item.id,
                jan_to_mar: '',
                apr_to_jun: '',
                july_to_sep: '',
                oct_to_dec: '',
                total_price: '',
            })),
            cost_of_sale: budgetAcData?.cost_of_sale?.map((item) => ({
                account_id: item.id,
                jan_to_mar: '',
                apr_to_jun: '',
                july_to_sep: '',
                oct_to_dec: '',
                total_price: '',
            })),
            expenses: budgetAcData?.expenses?.map((item) => ({
                account_id: item.id,
                jan_to_mar: '',
                apr_to_jun: '',
                july_to_sep: '',
                oct_to_dec: '',
                total_price: '',
            })),
            other_expenses: budgetAcData?.other_expenses?.map((item) => ({
                account_id: item.id,
                jan_to_mar: '',
                apr_to_jun: '',
                july_to_sep: '',
                oct_to_dec: '',
                total_price: '',
            })),
        }
    });
    const calculateTotalIncome = (month) => {
        const total = quartlyBudget?.quartery_budget?.income?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalIncome1 = (month) => {
        const total = quartlyBudget?.quartery_budget?.other_income?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum = () => {
        const totalSum = quartlyBudget?.quartery_budget?.income?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalSum1 = () => {
        const totalSum = quartlyBudget?.quartery_budget?.other_income?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome2 = (month) => {
        const total = quartlyBudget?.quartery_budget?.expenses?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum2 = () => {
        const totalSum = quartlyBudget?.quartery_budget?.expenses?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome3 = (month) => {
        const total = quartlyBudget?.quartery_budget?.cost_of_sale?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum3 = () => {
        const totalSum = quartlyBudget?.quartery_budget?.cost_of_sale?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome4 = (month) => {
        const total = quartlyBudget?.quartery_budget?.other_expenses?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum4 = () => {
        const totalSum = quartlyBudget?.quartery_budget?.other_expenses?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };

    useEffect(() => {
        const calculatedTotalIncome = quartlyBudget?.quartery_budget?.income?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_mar || 0) + parseFloat(income?.apr_to_jun || 0) + parseFloat(income?.july_to_sep || 0) + parseFloat(income?.oct_to_dec || 0),
            0
        );
        setTotalIncome(() => calculatedTotalIncome);

        const calculatedTotalIncome1 = quartlyBudget?.quartery_budget?.other_income?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_mar || 0) + parseFloat(income?.apr_to_jun || 0) + parseFloat(income?.july_to_sep || 0) + parseFloat(income?.oct_to_dec || 0),
            0
        );
        setTotalIncome1(() => calculatedTotalIncome1);

        const calculatedTotalIncome2 = quartlyBudget?.quartery_budget?.expenses?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_mar || 0) + parseFloat(income?.apr_to_jun || 0) + parseFloat(income?.july_to_sep || 0) + parseFloat(income?.oct_to_dec || 0),
            0
        );
        setTotalIncome2(() => calculatedTotalIncome2);

        const calculatedTotalIncome3 = quartlyBudget?.quartery_budget?.other_expenses?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_mar || 0) + parseFloat(income?.apr_to_jun || 0) + parseFloat(income?.july_to_sep || 0) + parseFloat(income?.oct_to_dec || 0),
            0
        );
        setTotalIncome3(() => calculatedTotalIncome3);

        const calculatedTotalIncome4 = quartlyBudget?.quartery_budget?.cost_of_sale?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_mar || 0) + parseFloat(income?.apr_to_jun || 0) + parseFloat(income?.july_to_sep || 0) + parseFloat(income?.oct_to_dec || 0),
            0
        );
        setTotalIncome4(() => calculatedTotalIncome4);

    }, [quartlyBudget?.quartery_budget?.income, quartlyBudget?.quartery_budget?.other_income, quartlyBudget?.quartery_budget?.expenses, quartlyBudget?.quartery_budget?.other_expenses, quartlyBudget?.quartery_budget?.cost_of_sale]);

    const updateTotalAmount = () => {
        const totalSum =
            parseFloat(calculateTotalSum() || 0) +
            parseFloat(calculateTotalSum1() || 0) +
            parseFloat(calculateTotalSum2() || 0) +
            parseFloat(calculateTotalSum3() || 0) +
            parseFloat(calculateTotalSum4() || 0);

        setQuartylyBudget(prevState => ({
            ...prevState,
            total_amount: totalSum?.toFixed(0),
        }));
    };
    useEffect(() => {
        updateTotalAmount();
    }, [
        quartlyBudget?.quartery_budget?.income,
        quartlyBudget?.quartery_budget?.other_income,
        quartlyBudget?.quartery_budget?.expenses,
        quartlyBudget?.quartery_budget?.cost_of_sale,
        quartlyBudget?.quartery_budget?.other_expenses,
    ]);
    const handleSubmit = async () => {
        try {
            await updateQuarterlyBudget(quartlyBudget)
            navigate('/admin/budget');
        } catch (error) {
            console.error('Error submitting yearly budget:', error);
        }
    };
    const handleClose = () => {
        navigate('/admin/budget');
    }
    const handleDownloadCsv = () => {
        const csvData = [];
        const companyNameRow = [
            'Budget Overview:',
            `${quarterlyBudgetIndi?.quarterly?.name}`,
        ];
        const companyNameRow1 = [
            'Financial Year:',
            `${quarterlyBudgetIndi?.quarterly?.finencial_year}`,
        ];
        const companyNameRow2 = [
            `Total Amount during ${quarterlyBudgetIndi?.quarterly?.finencial_year?.split("(")[0]}:`,
            `Rs${quarterlyBudgetIndi?.quarterly?.total_amount?.toLocaleString()}`,
        ];
        const headerRow = [
            'Accounts',
            'January to March',
            'April to June',
            'July to September',
            'October to December',
            'Total',
        ];

        const newLine1 = Array(headerRow.length).fill('');

        csvData.push(companyNameRow);
        csvData.push(companyNameRow1);
        csvData.push(companyNameRow2);
        csvData.push(newLine1);

        csvData.push(headerRow);

        const incomeRows = quarterlyBudgetIndi?.quarterly?.quartery_income.map((item) => {
            const row = [item.account?.name || ''];
            for (let i = 0; i < 4; i++) {
                row.push(item[Object.keys(item)[i + 4]]);
            }
            row.push(`PRs${item.total_price ? item.total_price : 0}`);
            return row;
        });

        const costOfSales = quarterlyBudgetIndi?.quarterly?.quartery_cost_of_sale?.map((item) => {
            const row = [item.account?.name || ''];
            for (let i = 0; i < 4; i++) {
                row.push(item[Object.keys(item)[i + 4]]);
            }
            row.push(`PRs${item.total_price ? item.total_price : 0}`);
            return row;
        });
        const grossProfitRow = ['Gross Profit',];
        let totalGrossProfitAmount = 0;

        for (let i = 0; i < 4; i++) {
            const incomeAmount = incomeRows.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
            const costOfSalesAmount = costOfSales.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
            const grossProfitAmount = incomeAmount - costOfSalesAmount;
            totalGrossProfitAmount += grossProfitAmount;
            grossProfitRow.push(`PRs${grossProfitAmount.toLocaleString()}`);
        }
        grossProfitRow.push(`PRs${totalGrossProfitAmount.toLocaleString()}`);
        const expenses = quarterlyBudgetIndi?.quarterly?.quartery_expenses?.map((item) => {
            const row = [item.account?.name || ''];
            for (let i = 0; i < 4; i++) {
                row.push(item[Object.keys(item)[i + 4]]);
            }
            row.push(`PRs${item.total_price ? item.total_price : 0}`);
            return row;
        });
        const totalExpensesRow = ['Total Expenses Amount',];
        let totalExpensesAmount = 0;

        for (let i = 0; i < 4; i++) {
            const totalAmountForQuarter = expenses.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
            totalExpensesRow.push(`PRs${totalAmountForQuarter.toLocaleString()}`);
            totalExpensesAmount += totalAmountForQuarter;
        }

        totalExpensesRow.push(`PRs${totalExpensesAmount.toLocaleString()}`);

        const otherIncomeRows = quarterlyBudgetIndi?.quarterly?.quartery_other_income?.map((item) => {
            const row = [item.account?.name || ''];
            for (let i = 0; i < 4; i++) {
                row.push(item[Object.keys(item)[i + 4]]);
            }
            row.push(`PRs${item.total_price ? item.total_price : 0}`);
            return row;
        });
        const otherExpensesRows = quarterlyBudgetIndi?.quarterly?.quartery_other_expenses?.map((item) => {
            const row = [item.account?.name || ''];
            for (let i = 0; i < 4; i++) {
                row.push(item[Object.keys(item)[i + 4]]);
            }
            row.push(`PRs${item.total_price ? item.total_price : 0}`);
            return row;
        });

        const netOperatingIncomeRow = ['Net Operating Income',];
        let totalNetOperatingIncomeAmount = 0;

        for (let i = 0; i < 4; i++) {
            const grossProfitAmount = parseFloat(grossProfitRow[i + 1]?.split('PRs')[1]?.replace(/,/g, '') || 0);
            const totalExpensesAmount = parseFloat(totalExpensesRow[i + 1]?.split('PRs')[1]?.replace(/,/g, '') || 0);
            const netOperatingIncomeAmount = grossProfitAmount - totalExpensesAmount;
            totalNetOperatingIncomeAmount += netOperatingIncomeAmount;
            netOperatingIncomeRow.push(`PRs${netOperatingIncomeAmount.toLocaleString()}`);
        }
        const netOtherIncomeRow = ['Net Other Income',];
        let totalNetOtherIncomeAmount = 0;

        for (let i = 0; i < 4; i++) {
            const otherIncomeAmount = otherIncomeRows.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
            const otherExpenseAmount = otherExpensesRows.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
            const netOtherIncomeAmount = otherIncomeAmount - otherExpenseAmount;
            totalNetOtherIncomeAmount += netOtherIncomeAmount;
            netOtherIncomeRow.push(`PRs${netOtherIncomeAmount.toLocaleString()}`);
        }

        netOtherIncomeRow.push(`PRs${totalNetOtherIncomeAmount.toLocaleString()}`);


        const netIncomeRow = ['Net Income',];
        let totalNetIncomeAmount = 0;

        for (let i = 0; i < 4; i++) {
            const netOperatingIncomeAmount = parseFloat(netOperatingIncomeRow[i + 1]?.split('PRs')[1]?.replace(/,/g, '') || 0);
            const netOtherIncomeAmount = parseFloat(netOtherIncomeRow[i + 1]?.split('PRs')[1]?.replace(/,/g, '') || 0);
            const netIncomeAmount = netOperatingIncomeAmount + netOtherIncomeAmount;
            totalNetIncomeAmount += netIncomeAmount;
            netIncomeRow.push(`PRs${netIncomeAmount.toLocaleString()}`);
        }

        netIncomeRow.push(`PRs${totalNetIncomeAmount.toLocaleString()}`);



        netOperatingIncomeRow.push(`PRs${totalNetOperatingIncomeAmount?.toLocaleString()}`);
        csvData.push(['Income'], ...incomeRows);
        csvData.push(newLine1);
        csvData.push(['Cost of Sales'], ...costOfSales);
        csvData.push(newLine1);
        csvData.push(grossProfitRow);
        csvData.push(newLine1);
        csvData.push(['Expenses'], ...expenses);
        csvData.push(totalExpensesRow);
        csvData.push(netOperatingIncomeRow);
        csvData.push(newLine1);
        csvData.push(['Other Income'], ...otherIncomeRows);
        csvData.push(newLine1);
        csvData.push(['Other Expenses'], ...otherExpensesRows);
        csvData.push(newLine1);
        csvData.push(netOtherIncomeRow);
        csvData.push(netIncomeRow);
        csvData.push(newLine1);
        const csv = Papa.unparse(csvData, { header: false });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, `${quarterlyBudgetIndi?.quarterly?.name}-${quarterlyBudgetIndi?.quarterly?.finencial_year?.split("(")[0]}.csv`);
    }
    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current) {
            handleDownloadPDF();
        }
    }, [cardRef]);
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return;
        }

        setPdfGenerating(true);

        setTimeout(() => {
            const card = cardRef.current;

            const pdfOptions = {
                margin: 0,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };

            if (card) {
                html2pdf()?.from(card)?.set(pdfOptions)?.outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' });

                    // Create a download link
                    const url = URL.createObjectURL(blob);
                    const a = document?.createElement('a');
                    a.href = url;
                    a.download = 'invoice.pdf';
                    a.style.display = 'none';

                    // Append the link to the body and trigger the click
                    document?.body?.appendChild(a);
                    a.click();

                    // Remove the link after the download
                    document?.body?.removeChild(a);

                    setPdfGenerating(false);
                }).catch((error) => {
                    console.error('Error generating PDF:', error);
                    setPdfGenerating(false);
                });
            } else {
                console.error('Card element not found.');
                setPdfGenerating(false);
            }
        }, 0);
    };

    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    });

    const calculateGrossProfit = (month) => {
        const totalIncome = parseInt(calculateTotalIncome(month));
        const totalCostOfSales = parseInt(calculateTotalIncome3(month));
        return (totalIncome - totalCostOfSales)?.toLocaleString();
    };

    const calculateTotalGrossProfit = () => {
        const totalIncomeSum = parseInt(calculateTotalSum());
        const totalCostOfSalesSum = parseInt(calculateTotalSum3());
        return (totalIncomeSum - totalCostOfSalesSum)?.toLocaleString();
    };
    const calculateNetOperatingIncome = (month) => {
        const grossProfit = parseFloat(calculateGrossProfit(month)?.replace(/,/g, ''));
        const totalExpenses = parseFloat(calculateTotalIncome2(month)?.replace(/,/g, ''));
        console.log(grossProfit)
        console.log(totalExpenses)
        return (grossProfit - totalExpenses)?.toLocaleString();
    };

    const calculateTotalNetOperatingIncome = () => {
        const totalGrossProfit = parseFloat(calculateTotalGrossProfit()?.replace(/,/g, ''));
        const totalExpensesSum = parseFloat(calculateTotalSum2()?.replace(/,/g, ''));
        return (totalGrossProfit - totalExpensesSum)?.toLocaleString();
    };
    const calculateNetOtherIncome = (month) => {
        const totalOtherIncome = parseFloat(calculateTotalIncome1(month)?.replace(/,/g, ''));
        const totalOtherExpenses = parseFloat(calculateTotalIncome4(month)?.replace(/,/g, ''));
        return (totalOtherIncome - totalOtherExpenses)?.toLocaleString();
    };

    const calculateTotalNetOtherIncome = () => {
        const totalOtherIncomeSum = parseFloat(calculateTotalSum1()?.replace(/,/g, ''));
        const totalOtherExpensesSum = parseFloat(calculateTotalSum4()?.replace(/,/g, ''));
        return (totalOtherIncomeSum - totalOtherExpensesSum)?.toLocaleString();
    };
    const calculateNetIncome = (month) => {
        const netOperatingIncome = parseFloat(calculateNetOperatingIncome(month)?.replace(/,/g, ''));
        const netOtherIncome = parseFloat(calculateNetOtherIncome(month)?.replace(/,/g, ''));
        return (netOperatingIncome + netOtherIncome)?.toLocaleString();
    };

    const calculateTotalNetIncome = () => {
        const totalNetOperatingIncome = parseFloat(calculateTotalNetOperatingIncome()?.replace(/,/g, ''));
        const totalNetOtherIncome = parseFloat(calculateTotalNetOtherIncome()?.replace(/,/g, ''));
        return (totalNetOperatingIncome + totalNetOtherIncome)?.toLocaleString();
    };

    return (
        <>
            <div className='createBudgetData'>

                <div className='row' ref={contentRef}>
                    <div className='card'>
                        <div className='card-body table-responsive recruitment' style={{ marginBottom: "2rem" }}>
                            <div className='row mb-3'>
                                <h3 style={{ fontSize: "16px", textAlign: "center" }}>{quarterlyBudgetIndi?.quarterly?.company?.name}</h3>
                                <h3 style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center" }}>Budget Overview: {quarterlyBudgetIndi?.quarterly?.name}-{quarterlyBudgetIndi?.quarterly?.finencial_year?.split("(")[0]}</h3>
                                <h3 style={{ fontSize: "14px", textAlign: "center" }}>{quarterlyBudgetIndi?.quarterly?.finencial_year}</h3>
                                <h3 style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center", color: parseFloat(calculateTotalNetIncome().replace(/,/g, '')) < 0 ? 'red' : 'black' }}>
                                    Net Income: Rs {calculateTotalNetIncome()}
                                </h3>
                            </div>
                            <hr />
                            <table className='table table-hover table-bordered'>
                                <thead style={{ background: "#f3f5f8", color: "black", fontWeight: "bold" }}>
                                    <tr>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Account</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Jan to Mar</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>April to Jun</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Jul to Sep</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Oct to Dec</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Income</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(1)}>
                                                {showNestedTables[1] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={5} />
                                    </tr>
                                    {showNestedTables[1] && (
                                        <>
                                            {budgetAcData?.income?.map((item, index) => {
                                                const monthlyIncome = quartlyBudget?.quartery_budget?.income?.[index] || {};
                                                const totalIncome = ['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <p> {monthlyIncome?.jan_to_mar || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.apr_to_jun || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.july_to_sep || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.oct_to_dec || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Income</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Cost of Sales</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(5)}>
                                                {showNestedTables[5] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={5} />
                                    </tr>
                                    {showNestedTables[5] && (
                                        <>
                                            {budgetAcData?.cost_of_sale?.map((item, index) => {
                                                const monthlyIncome = quartlyBudget?.quartery_budget?.cost_of_sale?.[index] || {};
                                                const totalIncome = ['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <p> {monthlyIncome?.jan_to_mar || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.apr_to_jun || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.july_to_sep || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.oct_to_dec || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total COS</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome3(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome4}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Gross Profit</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateGrossProfit(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalGrossProfit()}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Expance</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(3)}>
                                                {showNestedTables[3] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={5} />
                                    </tr>
                                    {showNestedTables[3] && (
                                        <>
                                            {budgetAcData?.expenses?.map((item, index) => {
                                                const monthlyIncome = quartlyBudget?.quartery_budget?.expenses?.[index] || {};
                                                const totalIncome = ['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <p> {monthlyIncome?.jan_to_mar || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.apr_to_jun || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.july_to_sep || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.oct_to_dec || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Expenses</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome2(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome2}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Net Operating Income</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateNetOperatingIncome(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalNetOperatingIncome()}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Other Income</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(2)}>
                                                {showNestedTables[2] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={5} />
                                    </tr>
                                    {showNestedTables[2] && (
                                        <>
                                            {budgetAcData?.other_income?.map((item, index) => {
                                                const monthlyIncome = quartlyBudget?.quartery_budget?.other_income?.[index] || {};
                                                const totalIncome = ['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <p> {monthlyIncome?.jan_to_mar || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.apr_to_jun || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.july_to_sep || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.oct_to_dec || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Income</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome1(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome1}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Other Expense</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(4)}>
                                                {showNestedTables[4] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={5} />
                                    </tr>
                                    {showNestedTables[4] && (
                                        <>
                                            {budgetAcData?.other_expenses?.map((item, index) => {
                                                const monthlyIncome = quartlyBudget?.quartery_budget?.other_expenses?.[index] || {};
                                                const totalIncome = ['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <p> {monthlyIncome?.jan_to_mar || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.apr_to_jun || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.july_to_sep || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.oct_to_dec || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Expance</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome4(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome3}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Total Other Income</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateNetOtherIncome(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalNetOtherIncome()}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Total Net Income</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateNetIncome(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalNetIncome()}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div style={{ background: "white", padding: "1rem", position: "fixed", width: "100%", bottom: "0", left: "0", display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <div>
                        <button onClick={handlePrint} className='btn btn-primary'>Save as PDF</button>
                        <button onClick={handleDownloadCsv} className='btn btn-success mx-3'>Save as XLS</button>
                        <button className='btn btn-warning' onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShowQuartlyBudget
