import React, { useEffect, useState } from "react";
import { useData } from "../ContextProvider/Context";
import { Link, useParams } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
import { Modal, Form, Input } from "antd";
import { MdOutlinePriceChange } from "react-icons/md";
import { LuSubtitles } from "react-icons/lu";
import { CiEdit, CiTrash } from "react-icons/ci";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { Button, Col, Row } from "react-bootstrap";
import { Snackbar, Alert } from "@mui/material";
import pdarrow from "../Images/pdarrow.png";
import wbackimg from "../Images/wbackimg.png";
import wclogo from "../Images/wclogo.png";
import wcvector from "../Images/wcvector.png";
import pcardimg from "../Images/pcardimg1.png";
import player from "../Images/pcardlyer1.png";
import collabs from "../Images/pcollabtrs.png";
import cardlogo from "../Images/pcardlogo.png";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import editicon from "../Images/editicon.png";
import deleteicon from "../Images/deleteicon.png";
import projcover from "../Images/projcover.png";
// import ProjectDetail from "./ProjectDetail";
import addproicon from "../Images/vaproject.png";
import { LuImagePlus } from "react-icons/lu";
import darrow from "../Images/drawerarrow.png";
import formpen from "../Images/formpen.png";
import formcmp from "../Images/formcmp.png";
import formbrnch from "../Images/branchv1.png";
import depv1 from "../Images/depv1.png";
import desigv1 from "../Images/desigv1.png";
import Demo from "./Demo";
import { Progress, Space } from 'antd';
function Projects() {
  const params = useParams();
  const {
    getAllCrudData,
    allCrudData,
    deleteProjectData,
    updateProjectData,
    projects,
    getProjects,
    createProject,
    employeeID,
    getdseignationById,
    designarion,
    getEmploayeeByid,
    getCompnayCrud,
    getBarnchById,
    getdepartmetById,
    company,
    branch,
    departmentval,
    permission,
    handleClick,
    setSnackbarValue,
    img_url,
    getProjectsIndividual,
    projectIndividual,
  } = useData();
  const emp_id = JSON.parse(localStorage.getItem("user_auth"));
  const [searchQuery, setSearchQuery] = useState("");
  const [isCreateNewEmployee, setIsCreateNewEmployee] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);

  const filteredCompanies = allCrudData?.companies?.filter(
    (item) => item.deleteStatus === 0
  );

  const filteredBranches = allCrudData?.branches?.filter(
    (item) => item.companyId === selectedCompany
  );

  const filteredDepartments = allCrudData?.department?.filter(
    (item) => item.branchId === selectedBranch
  );

  const filteredDesignation = allCrudData?.designations?.filter(
    (item) => item.departmentId === selectedDepartment
  );

  const filteredEmployee = allCrudData?.employees?.filter(
    (item) => item.designationId === selectedDesignation
  );

  useEffect(() => {
    getProjects();
    getCompnayCrud();
    getAllCrudData();
    getProjectsIndividual();
  }, []);

  const [projectid, setProjectid] = useState("");

  const [currentDate1, setCurrentDate1] = useState(new Date());

  const formattedDate = currentDate1.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const showCreateNewEmployee = () => {
    setOpenDrawer1(true);
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        setSelectedImage(URL.createObjectURL(file));
        setProjetcData({ ...projectData, cover_image: base64String });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCancel = () => {
    setIsCreateNewEmployee(false);
    setProjetcData({
      companyId: "",
      branchId: "",
      departmentId: "",
      title: "",
      price: "",
      status: 0,
      startDate: new Date().toISOString().split("T")[0],
      user_id: emp_id?.user?.id,
      employee_id: emp_id?.employee?.id,
      desc: "",
      cover_image: null,
      deadLine: "",
      users: [],
      designationId: "",
    });
  };
  const [projectData, setProjetcData] = useState({
    companyId: "",
    branchId: "",
    departmentId: "",
    title: "",
    price: "",
    status: 0,
    startDate: new Date().toISOString().split("T")[0],
    desc: "",
    cover_image: null,
    deadLine: "",
    users: [],
    designationId: "",
    user_id: emp_id?.user?.id,
    employee_id: emp_id?.employee?.id,
  });

  const handleSelectChanges = (event, newValue) => {
    const selectedEmployeeIds = newValue.map((employee) => employee.id);

    setProjetcData((prevData) => ({
      ...prevData,
      users: selectedEmployeeIds,
    }));
  };
  const isFormValid = () => {
    for (const key in projectData) {
      if (projectData[key] === "") {
        return false;
      }
    }
    return true;
  };
  const [subBtn, setBtn] = useState(false);
  const handleSelectChange = (event) => {
    const selectedUserIds = Array.from(event.target.selectedOptions, (option) =>
      parseInt(option.value)
    );
    if (projectData?.id) {
      projectData?.performance?.users?.map((item) => {
        if (selectedUserIds.includes(item.empId)) {
          handleClick();
          setSnackbarValue({
            message: "The employee is already involved in this project.",
            color: "#DC4C64",
          });
          setBtn(true);
        } else {
          setBtn(false);
          setProjetcData({ ...projectData, users: selectedUserIds });
          setProjetcData((prevData) => {
            return {
              ...prevData,
              users: selectedUserIds,
            };
          });
        }
      });
    } else {
      setProjetcData({ ...projectData, users: selectedUserIds });
    }
  };
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 11;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = params.id
    ? projects?.projects?.filter((item) => item?.company?.id == params.id) || []
    : projects?.projects || [];

  const totalPages = Math.ceil(currentItems?.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [openDrawer1, setOpenDrawer1] = React.useState(false);
  const handleDrawerOpen1 = () => {
    setOpenDrawer1(true);
  };
  const handleDrawerClose1 = () => {
    setOpenDrawer1(false);
    handleCancel()
  };
  const [openDrawer1001, setopenDrawer1001] = React.useState(false);
  const handleDrawerClose001 = () => {
    setopenDrawer1001(false);
  };
  const handleDrawerOpen001 = () => {
    setopenDrawer1001(true);
  };
  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }
  const state = {
    open: false,
    vertical: "bottom",
    horizontal: "right",
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const { vertical, horizontal } = state;
  const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
  };
  // Form Validation
  const handleCreate = () => {
    if (projectData?.companyId?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Company is required.");
    } else if (projectData?.branchId?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Branch is required.");
    } else if (projectData?.departmentId?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Department is required.");
    } else if (projectData?.designationId?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Designation is required.");
    } else if (projectData?.title?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Name is required.");
    } else if (projectData?.price?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Price is required.");
    } else if (!projectData?.users || projectData.users.length === 0) {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Employees are required.");
    } else if (projectData?.startDate?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Start Date is required.");
    } else if (!projectData?.deadLine || projectData?.deadLine?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("End Date is required.");
    } else {
      createProject(projectData);
      handleDrawerClose1();
      handleCancel();
      setSnackbarValue({
        message: "Project Created successfully",
        color: "#2ECC71",
      });
    }
  };

  const handleUpdate = () => {
    if (projectData?.price?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Price is required.");
    } else if (projectData?.startDate?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Start Date is required.");
    } else if (!projectData?.deadLine || projectData?.deadLine?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("End Date is required.");
    } else {
      updateProjectData(projectData);
      handleDrawerClose1();
      handleCancel();
    }
  };
  return (
    <>
      <div className="projectData">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <nav>
              <ul style={{ paddingLeft: "3px" }}>
                <li
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Projects
                </li>
                <li>{formattedDate}</li>
              </ul>
            </nav>
          </div>
          <div className="d-flex justify-content-end mb-2 gap-2">
            <div>
              {permission.includes("Projects Create") && (
                <button
                  className="btn btn-primary"
                  style={{ padding: "8px 16px", display: "flex", gap: "5px" }}
                  onClick={handleDrawerOpen1}
                >
                  <img alt="" src={addproicon} />
                  Add Projects
                </button>
              )}
            </div>
          </div>
        </div>

        <div style={{ background: "rgb(240, 242, 245)" }}>
          <div className="Projectsection">
            <div className="row">
              {/* <div className="col-lg-3 col-md-6">
                <div
                  className="addprojbtn"
                  style={{
                    background: "white",
                    padding: "8px",
                    borderRadius: "10px",
                    position: "relative",
                    height: "100%",
                    width: "100%",
                    minHeight: "400px",
                  }}
                >
                  <button onClick={handleDrawerOpen1}>+</button>
                </div>{" "}
              </div> */}
              <div
                className="col-lg-3 col-md-6"
                style={{ marginBottom: "40px" }}
              >
                <div
                  className="pcardbody"
                  style={{
                    background: "white",
                    padding: "8px",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    className="addprojbtn"
                    style={{
                      background: "white",
                      padding: "8px",
                      borderRadius: "10px",
                      position: "relative",
                      width: "100%",
                      height: "434px",
                    }}
                  >
                    <button onClick={handleDrawerOpen1}>+</button>
                  </div>
                </div>
              </div>
              {params.id
                ? currentItems
                  .slice(indexOfFirstItem, indexOfLastItem)
                  ?.map((items, id) => {
                    return (
                      <>
                        <div
                          className="col-lg-3 col-md-6"
                          style={{ marginBottom: "40px" }}
                        >
                          <div
                            className="pcardbody"
                            style={{
                              background: "white",
                              padding: "8px",
                              borderRadius: "10px",
                            }}
                          >
                            <div style={{ height: "292px" }}>
                              <img
                                alt=""
                                src={img_url + items?.cover_image}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <button style={{ border: "none", background: "none" }} onClick={() => {
                                getProjectsIndividual(items?.id);
                                setProjectid(items?.id)
                                handleDrawerOpen001();
                              }}>

                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#667085",
                                    marginTop: "10px",
                                    maxWidth: "135px",
                                    width: "100%",
                                  }}
                                >
                                  {items?.title}
                                </span>
                              </button>

                              <div
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  right: "67px",
                                  zIndex: "2",
                                }}
                              >
                                <img
                                  alt=""
                                  src={img_url + items?.company?.logo}
                                  height={"100%"}
                                  width={"100%"}
                                  style={{ borderRadius: "50%" }}
                                />
                              </div>
                              <img
                                alt=""
                                style={{
                                  position: "absolute",
                                  zIndex: "1",
                                  right: "30px",
                                  top: "-27px",
                                }}
                                src={player}
                              />
                            </div>


                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "40px",
                                position: "relative",
                                alignItems: "center",
                              }}
                            >
                              <div className="avartarGroupBox">
                                {/* <img alt='' src={collabs}/> */}
                                <AvatarGroup max={4}>
                                  {items?.performance?.users?.map((item) => {
                                    return (
                                      <Avatar
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          borderRadius: "50%",
                                          border: "1px solid lightgray",
                                        }}
                                        alt=""
                                        src={
                                          items?.cover_image
                                            ? img_url + items?.cover_image
                                            : "https://dcassetcdn.com/design_img/3613525/23147/23147_19836872_3613525_9a718743_image.jpg "
                                        }
                                      />
                                    );
                                  })}
                                </AvatarGroup>
                              </div>
                              <div>
                                <span>{items.startDate}</span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                style={{ border: "none", background: "none" }}
                                onClick={() => {
                                  showCreateNewEmployee();
                                  setProjetcData(items);
                                }}
                              >
                                <img alt="" src={editicon} />
                              </button>
                              <button
                                style={{ border: "none", background: "none" }}
                                onClick={() => deleteProjectData(items.id)}
                              >
                                <img alt="" src={deleteicon} />
                              </button>
                            </div>
                            <div>
                              <Space direction="vertical">
                                <Progress percent={items?.performance?.percentage_completed?.toString()?.slice(0, 4)} size={[300, 10]} />
                              </Space>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                : currentItems
                  .slice(indexOfFirstItem, indexOfLastItem)
                  ?.map((items, id) => {
                    return (
                      <>
                        <div
                          className="col-lg-3 col-md-6"
                          style={{ marginBottom: "40px" }}
                        >
                          <div
                            className="pcardbody"
                            style={{
                              background: "white",
                              padding: "8px",
                              borderRadius: "10px",
                              boxShadow: "0 2px 15px -3px rgba(0, 0, 0, 0.07), 0 10px 20px -2px rgba(0, 0, 0, 0.04);"
                            }}
                          >
                            <div style={{ height: "292px" }}>
                              <img
                                alt=""
                                src={
                                  items?.cover_image
                                    ? img_url + items?.cover_image
                                    : "https://dcassetcdn.com/design_img/3613525/23147/23147_19836872_3613525_9a718743_image.jpg "
                                }
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <button style={{ border: "none", background: "none" }} onClick={() => {
                                getProjectsIndividual(items?.id);
                                setProjectid(items?.id)
                                handleDrawerOpen001();
                              }}>

                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#667085",
                                    // marginTop: "10px",
                                    maxWidth: "135px",
                                    width: "100%",
                                  }}
                                >
                                  {items?.title}
                                </span>
                              </button>
                              <div
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  right: "67px",
                                  zIndex: "2",
                                }}
                              >
                                <img
                                  alt=""
                                  src={img_url + items?.company?.logo}
                                  height={"100%"}
                                  width={"100%"}
                                  style={{ borderRadius: "50%" }}
                                />
                              </div>
                              <img
                                alt=""
                                style={{
                                  position: "absolute",
                                  zIndex: "1",
                                  right: "30px",
                                  top: "-27px",
                                }}
                                src={player}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "22px",
                                position: "relative",
                                alignItems: "center",
                              }}
                            >
                              <div className="avartarGroupBox">
                                {/* <img alt='' src={collabs}/> */}
                                <AvatarGroup max={4}>
                                  {items?.performance?.users?.map((item) => {
                                    return (
                                      <Avatar
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          borderRadius: "50%",
                                          border: "1px solid lightgray",
                                        }}
                                        alt=""
                                        src={img_url + item?.user?.profile}
                                      />
                                    );
                                  })}
                                </AvatarGroup>
                              </div>
                              <div>
                                <span>{items.startDate}</span>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                style={{ border: "none", background: "none" }}
                                onClick={() => {
                                  showCreateNewEmployee();
                                  setProjetcData(items);
                                }}
                              >
                                <img alt="" src={editicon} />
                              </button>
                              <button
                                style={{ border: "none", background: "none" }}
                                onClick={() => deleteProjectData(items.id)}
                              >
                                <img alt="" src={deleteicon} />
                              </button>
                            </div>
                            <div>
                              <Space direction="vertical">
                                <Progress percent={items?.performance?.percentage_completed?.toString()?.slice(0, 4)} size={[300, 10]} />
                              </Space>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
            </div>
          </div>
          <div className="pagination-controls">{paginationControls}</div>
        </div>
      </div>
      <div>
        <Drawer
          className="myproject-drawer"
          anchor="right"
          visible={isCreateNewEmployee}
          open={openDrawer1}
          onClose={handleDrawerClose1}
          style={{ overflowY: "hidden", borderRadius: "10px" }}
        >
          {/* Drawer 3 content */}

          <div
            className="wizradparent1"
            style={{ background: "ghostwhite", padding: "32px" }}
          >
            <div style={{ position: "relative" }}>
              <button
                onClick={handleDrawerClose1}
                style={{
                  position: "absolute",
                  bottom: "4px",
                  left: "-11px",
                  background: "none",
                  border: "none",
                }}
              >
                <img alt="" src={darrow} />
              </button>
            </div>
            <h3>Add Projects</h3>
            <div className="row" style={{ gap: "40px" }}>
              <div
                className="col-md-12 row1col1"
                style={{
                  borderRadius: "12px",
                  width: "100%",
                  background: "white",
                  height: "475px",
                  overflowY: "scroll",
                  padding: "0px",
                }}
              >
                <div
                  className="headerimg"
                  style={{
                    backgroundImage: `url(${wbackimg})`,
                    height: "146px",
                    position: "relative",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <img
                    alt=""
                    style={{
                      position: "absolute",
                      top: "94%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                    }}
                    src={wcvector}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                      padding: "16px",
                      borderRadius: "50%",
                      background: "darkgray",
                    }}
                  >
                    <div
                      style={{
                        border: "2px solid",
                        height: "70px",
                        borderRadius: "50%",
                        width: "70px",
                        position: "relative",
                      }}
                    >
                      <label
                        style={{
                          border: "1px solid",
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                          background: "lightgray",
                          color: "gray",
                          position: "absolute",
                          right: "0%",
                          top: "54px",
                          paddingLeft: ".2rem",
                          paddingBottom: ".2rem",
                        }}
                        htmlFor="cp1"
                      >
                        <LuImagePlus />
                      </label>
                      <img
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        src={
                          selectedImage
                            ? selectedImage
                            : img_url + projectData?.cover_image
                        }
                      />
                    </div>
                    <input
                      type="file"
                      id="cp1"
                      name="projectLogo"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "60px", overflowX: "hidden" }}>
                  <Form name="edit_personal_details">
                    <div
                      className="card p-3 h-100"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className="row">
                        <div className="col-6 p-2">
                          <div className="mb-2" style={{ height: "48px" }}>
                            <div className="form-group ">
                              <label>Name</label>
                              <span style={{ color: "red" }}>*</span>
                              <div className="inputBox">
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                  component="form"
                                  sx={{
                                    "& > :not(style)": { m: 0 },
                                    "& label.MuiInputLabel-root": {
                                      fontSize: "14px",
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <label>
                                    <img alt="" src={formpen} />
                                  </label>
                                  <Input
                                    style={{
                                      border: "none",
                                    }}
                                    id="title"
                                    placeholder="Name"
                                    variant="standard"
                                    value={projectData.title}
                                    onChange={(e) =>
                                      setProjetcData({
                                        ...projectData,
                                        title: e.target.value,
                                      })
                                    }
                                  />
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 p-2">
                          <div className="mb-2" style={{ height: "48px" }}>
                            <div className="form-group">
                              <label>Price</label>
                              <span style={{ color: "red" }}>*</span>
                              <div className="inputBox">
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                  component="form"
                                  sx={{
                                    "& > :not(style)": { m: 0 },
                                    "& label.MuiInputLabel-root": {
                                      fontSize: "14px",
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <label>
                                    <MdOutlinePriceChange />
                                  </label>
                                  <Input
                                    style={{
                                      border: "none",
                                    }}
                                    id="price"
                                    type="number"
                                    placeholder="Price"
                                    variant="standard"
                                    value={projectData.price}
                                    onChange={(e) =>
                                      setProjetcData({
                                        ...projectData,
                                        price: e.target.value,
                                      })
                                    }
                                  // required
                                  />
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 p-2">
                          <div className="form-group">
                            <label>Start Date</label>
                            <span style={{ color: "red" }}>*</span>
                            <div
                              className="inputBox"
                              style={{ position: "relative" }}
                            >
                              <input
                                type="date"
                                id="startData"
                                className="dateInput"
                                style={{
                                  height: "30px",
                                  border: "none",
                                  borderBottom: "none",
                                  borderRadius: "0",
                                  width: "100%",
                                  fontSize: "14px",
                                  padding: "10px 0",
                                }}
                                min={new Date().toISOString().split("T")[0]}
                                placeholder="DD--MM-YY"
                                value={projectData.startDate}
                                onChange={(e) =>
                                  setProjetcData({
                                    ...projectData,
                                    startDate: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6 p-2">
                          <div className="form-group ">
                            <label>End Date</label>
                            <span style={{ color: "red" }}>*</span>
                            <div
                              className="inputBox"
                              style={{ position: "relative" }}
                            >
                              <input
                                type="date"
                                id="deadline"
                                className="dateInput"
                                style={{
                                  height: "30px",
                                  border: "none",
                                  borderBottom: "none",
                                  borderRadius: "0",
                                  width: "100%",
                                  fontSize: "14px",
                                  padding: "10px 0",
                                }}
                                min={projectData?.startDate}
                                placeholder="DD--MM--YY"
                                value={projectData.deadLine}
                                onChange={(e) =>
                                  setProjetcData({
                                    ...projectData,
                                    deadLine: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {projectData?.id ? (
                          ""
                        ) : (
                          <>
                            <div className="col-6 p-2">
                              <div className="form-group">
                                <label>Company</label>
                                <span style={{ color: "red" }}>*</span>
                                <div
                                  className="selectBox"
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src={formcmp}
                                    alt="Icon"
                                    style={{
                                      marginLeft: "5px",
                                      position: "absolute",
                                      top: "6px",
                                    }}
                                  />
                                  <select
                                    style={{
                                      border: "none",
                                      width: "100%",
                                      height: "30px",
                                      paddingLeft: "28px",
                                    }}
                                    value={projectData.companyId}
                                    disabled={params.id ? true : false}
                                    onChange={(e) => {
                                      setProjetcData({
                                        ...projectData,
                                        companyId: e.target.value,
                                      });
                                      setSelectedCompany(
                                        parseInt(e.target.value)
                                      );
                                    }}
                                  >
                                    <option hidden> Select a company</option>
                                    {filteredCompanies?.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 p-2">
                              <div className="form-group">
                                <label>Branch</label>
                                <span style={{ color: "red" }}>*</span>
                                <div
                                  className="selectBox"
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src={formbrnch}
                                    alt="Icon"
                                    style={{
                                      marginLeft: "5px",
                                      position: "absolute",
                                      top: "6px",
                                    }}
                                  />
                                  <select
                                    style={{
                                      border: "none",
                                      width: "100%",
                                      height: "30px",
                                      paddingLeft: "28px",
                                    }}
                                    value={projectData.branchId}
                                    onChange={(e) => {
                                      setProjetcData({
                                        ...projectData,
                                        branchId: e.target.value,
                                      });
                                      setSelectedBranch(
                                        parseInt(e.target.value)
                                      );
                                    }}
                                  >
                                    <option hidden>Select a branch</option>
                                    {selectedCompany && (
                                      <>
                                        {filteredBranches?.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.branch}
                                          </option>
                                        ))}
                                      </>
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 p-2">
                              <div className="form-group">
                                <label>Department</label>
                                <span style={{ color: "red" }}>*</span>
                                <div
                                  className="selectBox"
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src={depv1}
                                    alt="Icon"
                                    style={{
                                      marginLeft: "5px",
                                      position: "absolute",
                                      top: "6px",
                                    }}
                                  />
                                  <select
                                    style={{
                                      border: "none",
                                      width: "100%",
                                      height: "30px",
                                      paddingLeft: "28px",
                                    }}
                                    value={projectData.departmentId}
                                    onChange={(e) => {
                                      setProjetcData({
                                        ...projectData,
                                        departmentId: e.target.value,
                                      });
                                      setSelectedDepartment(
                                        parseInt(e.target.value)
                                      );
                                    }}
                                  >
                                    <option hidden>Select a department</option>
                                    {selectedBranch && (
                                      <>
                                        {filteredDepartments?.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.department}
                                          </option>
                                        ))}
                                      </>
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 p-2">
                              <div className="form-group">
                                <label>Designation</label>
                                <span style={{ color: "red" }}>*</span>
                                <div
                                  className="selectBox"
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src={desigv1}
                                    alt="Icon"
                                    style={{
                                      marginLeft: "5px",
                                      position: "absolute",
                                      top: "6px",
                                    }}
                                  />
                                  <select
                                    style={{
                                      border: "none",
                                      width: "100%",
                                      height: "30px",
                                      paddingLeft: "28px",
                                    }}
                                    value={projectData.designationId}
                                    onChange={(e) => {
                                      setProjetcData({
                                        ...projectData,
                                        designationId: e.target.value,
                                      });
                                      setSelectedDesignation(
                                        parseInt(e.target.value)
                                      );
                                    }}
                                  >
                                    <option hidden>Select a Designation</option>
                                    {selectedDepartment && (
                                      <>
                                        {filteredDesignation?.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.title}
                                          </option>
                                        ))}
                                      </>
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-12 p-2 inputFields">
                          <div className="form-group">
                            <label>Description</label>

                            <div
                              className="inputBox"
                              style={{ position: "relative" }}
                            >
                              <input
                                style={{
                                  border: "none",
                                  width: "100%",
                                  height: "30px",
                                }}
                                value={projectData.desc}
                                placeholder="Description"
                                onChange={(e) => {
                                  setProjetcData({
                                    ...projectData,
                                    desc: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 p-2">
                          <div className="form-group">
                            <label>Employees</label>
                            <span style={{ color: "red" }}>*</span>
                            <div>
                              <Autocomplete
                                style={{
                                  borderRadius: "9px",
                                  padding: "7px",
                                  border: "1px solid lightgray",
                                }}
                                multiple
                                options={filteredEmployee}
                                getOptionLabel={(option) => option?.name || ""}
                                value={filteredEmployee?.filter((employee) =>
                                  projectData?.users?.includes(employee.id)
                                )}
                                onChange={handleSelectChanges}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard" />
                                )}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      key={index}
                                      label={option?.name || ""}
                                      {...getTagProps({ index })}
                                      onDelete={() => {
                                        const newSelectedEmployeeIds =
                                          projectData.users.filter(
                                            (id) => id !== option.id
                                          );
                                        setProjetcData((prevData) => ({
                                          ...prevData,
                                          users: newSelectedEmployeeIds,
                                        }));
                                      }}
                                    />
                                  ))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <Button
                  type="submit"
                  variant="light"
                  onClick={() => {
                    handleDrawerClose1();
                    handleCancel();
                  }}
                >
                  {" "}
                  Discard
                </Button>

                <Button
                  type="submit"
                  variant="danger"
                  onClick={() => {
                    // createProject(projectData);
                    projectData?.id ? handleUpdate() : handleCreate();
                  }}
                >
                  {projectData?.id ? "Update" : "Create"}
                </Button>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
      {/* drawer 2 */}
      <Drawer
        className="myproject2-drawer"
        anchor="right"
        open={openDrawer1001}
        onClose={handleDrawerClose001}
      >
        <div style={{ position: "relative", zIndex: "999" }}>
          <button
            onClick={handleDrawerClose001}
            style={{
              position: "absolute",
              bottom: "4px",
              left: "12px",
              top: "20px",
              background: "none",
              border: "none",
            }}
          >
            <img alt="" src={pdarrow} />
          </button>
        </div>
        <Demo
          allIndiData={projectIndividual}
          projectid={projectid}
          onClose={handleDrawerClose001}
        />
      </Drawer>

      <Snackbar
        open={anyFieldEmpty}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          severity="error"
          style={{
            backgroundColor: "#8f2018",
            color: "white",
          }}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Projects;
