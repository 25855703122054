import React from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Form } from 'antd';
import { CiEdit, CiTrash } from 'react-icons/ci';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CiImport } from 'react-icons/ci'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '1rem',
    paddingTop: '2rem'
};
function ChartsOfAccounts() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };
    const handleDownloadCsv = () => {
        const csv = Papa.unparse(['trialBalanceData']);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'chartsofaccounts.csv');
    };
    return (
        <>
            <div className='ChartsOfAccountsData'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Charts of Accounts</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='d-flex justify-content-end mb-2 gap-2'>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <select className='form-select' style={{ height: "34px", fontSize: "14px" }}>
                                <option value="">Select Company</option>
                            </select>
                        </div>
                        <div>
                            <button className='btn btn-primary' onClick={handleOpen}>Add</button>
                        </div>
                        <div>
                            <button onClick={handleDownloadCsv} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><CiImport style={{ fontSize: "20px" }} />Export</button>
                        </div>
                        <div>
                            <button
                                id="basic-button"
                                className="btn btn-primary"
                                aria-controls={open1 ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? 'true' : undefined}
                                onClick={handleClick1}
                            >
                                Import Charts
                            </button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem>Download Demo</MenuItem>
                                <MenuItem onClick={handleClose1}> <label for="csv_file" >Import File</label></MenuItem>

                            </Menu>
                            <input type='file' accept=".csv" style={{ display: "none" }} id='csv_file' />
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} className='scrollBar' style={{ height: "500px", overflowY: "scroll" }}>
                                <Form name="add_designation">
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="company">Account Type</label>
                                                <select
                                                    id="company"
                                                    style={{ width: '100%' }}
                                                    className='form-select'
                                                >
                                                    <option hidden>Select Account Type</option>
                                                    <option>option1</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="companyName"> Name</label>
                                                <input
                                                    type='text'
                                                    id="branch"
                                                    label="Branch"
                                                    variant="standard"
                                                    className='form-control'
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="company">Detail Type</label>
                                                <select
                                                    id="company"
                                                    style={{ width: '100%' }}
                                                    className='form-select'
                                                >
                                                    <option hidden>Select Detail Type</option>
                                                    <option>option1</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="companyName">Description</label>
                                                <input
                                                    type='text'
                                                    id="branch"
                                                    label="Branch"
                                                    variant="standard"
                                                    className='form-control'
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div style={{ border: "1px solid lightgray", height: "200px", width: "100%", overflowY: "scroll" }} className='scrolBar'>

                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div>
                                                <input type='checkbox' />is sub-account <br />
                                                <select
                                                    id="company"
                                                    style={{ width: '100%' }}
                                                    className='form-select'
                                                >
                                                    <option hidden>Enter Parent Account</option>
                                                    <option>option1</option>
                                                </select>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="companyName">Balance</label>
                                                        <input
                                                            type='number'
                                                            id="branch"
                                                            label="Branch"
                                                            variant="standard"
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="companyName">as of</label>
                                                        <input
                                                            type='date'
                                                            id="branch"
                                                            label="Branch"
                                                            variant="standard"
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Form.Item>
                                        <button className='btn btn-primary mt-3' type="primary" htmlType="submit">
                                            Submit
                                        </button>
                                    </Form.Item>
                                </Form>
                            </Box>
                        </Modal>
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div>
                        <div className='card'>
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Account Type</th>
                                            <th scope="col">Detail Type</th>
                                            <th scope="col">Tax</th>
                                            <th scope="col">Balance</th>
                                            <th scope="col">Bank Balance</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <th scope="row">1</th>
                                            <td>
                                                name
                                            </td>
                                            <td>
                                                h
                                            </td>
                                            <td>
                                                h
                                            </td>
                                            <td>
                                                h
                                            </td>
                                            <td>
                                                h
                                            </td>
                                            <td>
                                                h
                                            </td>
                                            <td>
                                                <button className="btn p-1 m-2 btn-primary">
                                                    {<CiEdit style={{ fontSize: "20px" }} />}
                                                </button>
                                                <button className="btn p-1 m-2 btn-danger">
                                                    <CiTrash style={{ fontSize: "20px" }} />
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* <div className="pagination-controls">{paginationControls}</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChartsOfAccounts
