import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CiEdit, CiTrash } from 'react-icons/ci';
import { useData } from '../../../ContextProvider/Context';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
function Budget() {
    const params = useParams()
    const { getBudgetData, budgetData, deleteBudgets } = useData()
    useEffect(() => {
        getBudgetData()
    }, [])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = params.id
        ? budgetData?.data?.filter(item => item?.company?.id == params.id) || []
        : budgetData?.data || [];

    const totalPages = Math.ceil(currentItems?.length / itemsPerPage);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    return (
        <>
            <div className='employeeData'>
                <div classname="row " style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Budget</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='d-flex justify-content-end mb-2 gap-2'>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                        </div>
                        <div>
                            <button
                                id="basic-button"
                                className="btn btn-primary"
                                aria-controls={open1 ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? 'true' : undefined}
                                onClick={handleClick1}
                            >
                                Add Budgets
                            </button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem><Link to={'/admin/monthlyBudget'} style={{ color: "inherit", textDecoration: "none" }}>Monthly</Link></MenuItem>
                                <MenuItem><Link to={'/admin/quaterlyBudget'} style={{ color: "inherit", textDecoration: "none" }}>Quaterly</Link></MenuItem>
                                <MenuItem><Link to={'/admin/yearlyBudget'} style={{ color: "inherit", textDecoration: "none" }}>Yearly</Link></MenuItem>

                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div>
                    <div className='card'>
                        <div className="card-body recruitment table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Period</th>
                                        <th scope="col">Budget</th>
                                        <th scope="col">Created On</th>
                                        <th scope="col">Last Updated</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {params.id ? currentItems
                                        .slice(indexOfFirstItem, indexOfLastItem)?.map((item, i) => {
                                            return (
                                                <>
                                                    {item?.company?.id == params.id &&
                                                        <tr>
                                                            <th scope="row">
                                                                {indexOfFirstItem + i + 1}
                                                            </th>
                                                            <td>{item.name}</td>
                                                            <td>{item.company?.name}</td>
                                                            <td>{item.interval}</td>
                                                            <td>{parseFloat(item.total_amount).toLocaleString()}</td>
                                                            <td>{item.created_at?.split("T")[0]}</td>
                                                            <td>{item.updated_at?.split("T")[0]}</td>
                                                            <td>
                                                                <button className='btn btn-primary p-1'>
                                                                    <Link to={`/admin/${item.interval}/${item.id}`} style={{ textDecoration: "none", color: "white" }} >
                                                                        <CiEdit style={{ fontSize: "20px" }} />
                                                                    </Link>
                                                                </button>
                                                                <button className='btn btn-danger p-1' onClick={() => deleteBudgets(item.interval, item.id)}>
                                                                    <Link style={{ textDecoration: "none", color: "white" }} >
                                                                        <CiTrash style={{ fontSize: "20px" }} />
                                                                    </Link>
                                                                </button>
                                                            </td>
                                                        </tr>}
                                                </>
                                            )
                                        }) :
                                        currentItems
                                            .slice(indexOfFirstItem, indexOfLastItem)?.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <th scope="row">
                                                            {indexOfFirstItem + i + 1}
                                                        </th>
                                                        <td>{item.name}</td>
                                                        <td>{item.company?.name}</td>
                                                        <td>{item.interval}</td>
                                                        <td>{parseFloat(item.total_amount).toLocaleString()}</td>
                                                        <td>{item.created_at?.split("T")[0]}</td>
                                                        <td>{item.updated_at?.split("T")[0]}</td>
                                                        <td>
                                                            <button className='btn btn-primary p-1'>
                                                                <Link to={`/admin/${item.interval}/${item.id}`} style={{ textDecoration: "none", color: "white" }} >
                                                                    <CiEdit style={{ fontSize: "20px" }} />
                                                                </Link>
                                                            </button>
                                                            <button className='btn btn-danger p-1' onClick={() => deleteBudgets(item.interval, item.id)}>
                                                                <Link style={{ textDecoration: "none", color: "white" }} >
                                                                    <CiTrash style={{ fontSize: "20px" }} />
                                                                </Link>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </table>
                            <div className="pagination-controls">{paginationControls}</div>

                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default Budget;
