import React, { useEffect, useState } from "react";
import { useData } from "../ContextProvider/Context";
import { Link, useParams } from "react-router-dom";
import { CiTrash } from "react-icons/ci";
import { Alert, Box, Snackbar } from "@mui/material";

// import { Form } from "antd";
import ShowAlert from "./ShowAlert";
import filter from "../Images/filtericon.png";
import cross from "../Images/filtercross.png";
import vector from "../Images/Vector.png";
import { Drawer, IconButton, Card, CardContent } from "@mui/material";
import branchicon from "../Images/branchicon.png";
import editicon from "../Images/editicon.png";
import deleteicon from "../Images/deleteicon.png";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import editbrnch from "../Images/edtibranch.png";
import downarrow from "../Images/chevron-down.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
};

function Branch() {
  const params = useParams();

  const {
    handleClick,
    setSnackbarValue,
    company,
    getBranch,
    branch,
    delbarch,
    editBrachCompany,
    getCompnayCrud,
    storeBranchData,
    getFilterCompany,
    permission,
    getFilterBranch
  } = useData();
console.log(branch)
  const [addBrachCompany, setBrachCompany] = useState({
    companyId: params.id ? params.id : "",
    branch: "",
  });
  useEffect(() => {
    getCompnayCrud();
    getBranch();
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const [id, setid] = React.useState("");
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [state, setState] = React.useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
});
const { vertical, horizontal } = state;
const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const handleAddBranch = () => {
    if (addBrachCompany?.companyId?.trim() === '') {
        setAnyFieldEmpty(true);
        setSnackbarMessage('Company is required.');
    } else if (addBrachCompany?.branch?.trim() === '') {
        setAnyFieldEmpty(true);
        setSnackbarMessage('Branch is required.');
    } else {
        storeBranchData(addBrachCompany)
        handleClose01();
        
    }
};
const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
};
  
 
  const handleupdateBranch = () => {
    if (addBrachCompany.companyId && addBrachCompany.branch) {
      editBrachCompany(addBrachCompany, addBrachCompany?.id);
      handleClose01();
      setBrachCompany({
        companyId: params.id ? params.id : "",
        branch: "",
      });
    }
  };
  const [show, setShow] = useState(false);

  const handleClose01 = () => setShow(false);
  const handleClearFields = () => {
    setBrachCompany({
      companyId: params.id ? params.id : "",
      branch: "",
    });
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = params.id
    ? branch?.data?.filter((item) => item?.company?.id == params.id) || []
    : branch?.data || [];

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const [currentDate, setCurrentDate] = useState(new Date());
 

  const formattedDate = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const totalPages = Math.ceil(currentItems.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }

  return (
    <>
      <div className="departmentData">
        {/* filter sidebar content */}
        <Drawer anchor="right" open={openDrawer} onClose={handleDrawerClose}>
          <div
            className="filterparent"
            style={{
              // position: "absolute",
              // right: "0",
              // top: "0",
              background: "white",
              padding: "18px",
              zIndex: "99",
              display: "felx",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div
              className="fpchild1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "160px",
              }}
            >
              <span style={{ fontSize: "22px", fontSize: "bold" }}>Filter</span>
              <button
                onClick={handleDrawerClose}
                style={{ background: "none", border: "none" }}
              >
                <img alt="" src={cross} />
              </button>
              
            </div>
            <div
                className="form-group"
                style={{
                  marginBottom: "20px",
                  textAlign: "left",
                  position: "relative",
                }}
              >
                <label style={{ marginBottom: "5px" }} htmlFor="company">
                  Company
                </label>
                <img alt=""
                  style={{ position: "absolute", right: "16px", top: "40px" }}
                  src={downarrow}
                />
                <select
                  id="company"
                  style={{
                    width: "100%",
                    padding: "7px",
                  }}
                  className="form-control"
                  disabled={params.id ? true : false}
                  onChange={(e) => {
                    getFilterBranch(e.target.value)
                  }}
                >
                  <option hidden> Company</option>
                  <option value={""}>All Company</option>
                  {company?.data?.map((item) => {
                    return (
                      <>
                        {item?.deleteStatus == 0 && (
                          <option value={item.name} key={item.id}>
                            {item.name}
                          </option>
                        )}
                      </>
                    );
                  })}
                </select>
              </div>
            
            
          </div>
        </Drawer>
        {/* Add Branch  */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <nav>
              <ul style={{paddingLeft:"3px"}}>
                <li
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Company Assets
                </li>
                <li>{formattedDate}</li>
              </ul>
            </nav>
          </div>
          <div className="d-flex justify-content-end mb-2 gap-2">
            <div>
              {permission.includes("Branch Create") && (
                <button
                  className="btn btn-primary"
                  onClick={handleShow}
                  style={{
                    padding: ".5rem 1rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <img alt="" src={branchicon} />
                  Add Branch
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div>
            <div className="card">
              <div className="card-body">
                <div
                  className="headtable"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "100px",
                    }}
                  >
                    <h5>All Branches</h5>
                   
                  </div>
                  {/* <div>
                    <button
                      onClick={handleDrawerOpen}
                      style={{
                        background: "transparent",
                        borderRadius: "10px",
                        border: "1px solid darkgray",
                        padding: "2px 5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Filter
                      <img alt=""
                        style={{ width: "20%", marginLeft: "2px" }}
                        src={filter}
                      />
                    </button>
                  </div> */}
                </div>
                <table className="table">
                  <thead style={{ background: "ghostwhite" }}>
                    <tr>
                      <th scope="col">BrnID</th>
                      <th scope="col">Branch</th>
                      <th scope="col">Company</th>
                      <th  scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {params.id
                      ? currentItems
                          .slice(indexOfFirstItem, indexOfLastItem)
                          ?.map((item, idx) => {
                            const globalIndex =
                              idx + (currentPage - 1) * itemsPerPage + 1;

                            return (
                              <>
                                {item?.company?.id == params.id && (
                                  <tr>
                                    <th scope="row">{globalIndex}</th>
                                    <td scope="col">
                                      <Link className="link linkk">
                                        {item.branch}
                                      </Link>
                                    </td>
                                    <td scope="col">{item?.company?.name}</td>
                                    <td scope="col" >
                                      {permission.includes("Branch Edit") && (
                                        <button
                                          className=""
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setBrachCompany(item);
                                            handleShow();
                                          }}
                                        >
                                          <img alt="" src={editicon} />
                                        </button>
                                      )}
                                      {permission.includes("Branch Delete") && (
                                        <button
                                          className=""
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                          }}
                                          onClick={() => delbarch(item.id)}
                                        >
                                          <img alt="" src={deleteicon} />
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          })
                      : currentItems
                          .slice(indexOfFirstItem, indexOfLastItem)
                          ?.map((item, idx) => {
                            const globalIndex =
                              idx + (currentPage - 1) * itemsPerPage + 1;

                            return (
                              <tr>
                                <th scope="row">{globalIndex}</th>
                                <td scope="col">
                                  <Link className="link linkk">
                                    {item.branch}
                                  </Link>
                                </td>
                                <td scope="col">{item?.company?.name}</td>
                                <td scope="col" >
                                  {permission.includes("Branch Edit") && (
                                    <button
                                      className=""
                                      style={{
                                        background: "transparent",
                                        border: "none",
                                      }}
                                      onClick={() => {
                                        setBrachCompany(item);
                                        handleShow();
                                      }}
                                    >
                                      <img alt="" src={editicon} />
                                    </button>
                                  )}
                                  {permission.includes("Branch Delete") && (
                                    <button
                                      className=""
                                      style={{
                                        background: "transparent",
                                        border: "none",
                                      }}
                                      onClick={() => delbarch(item.id)}
                                    >
                                      <img alt="" src={deleteicon} />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                  </tbody>
                </table>
                <div className="pagination-controls">{paginationControls}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal style={{ zIndex: "9999" }} show={show} onHide={handleClose01}>
        <Modal.Header
          closeButton
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <img alt=""
            style={{
              width: "70px",
              background: "gainsboro",
              borderRadius: "50%",
            }}
            src={editbrnch}
          />
          <span style={{ fontSize: "24px", color: "#667085" }}>{addBrachCompany?.id ? "Edit Branch" : " Add Branch"}</span>
        </Modal.Header>
        <Modal.Body>
          <div
            className="form-group"
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label style={{ marginBottom: "5px" }} htmlFor="company1">
              Company <span style={{ color: "red" }}>*</span>
            </label>
            <img alt=""
              style={{ position: "absolute", right: "16px", top: "40px" }}
              src={downarrow}
            />
            <select
              id="company1"
              style={{ width: "100%", borderRadius: "10px", padding: "7px" }}
              className="form-control"
              disabled={params.id ? true : false}
              value={addBrachCompany.companyId}
              onChange={(e) =>
                setBrachCompany({
                  ...addBrachCompany,
                  companyId: e.target.value,
                })
              }
            >
              <option hidden> Company</option>
              {company?.data?.map((item) => {
                return (
                  <>
                    {item?.deleteStatus == 0 && (
                      <option value={item.id} key={id}>
                        {item.name}
                      </option>
                    )}
                  </>
                );
              })}
            </select>
          </div>

          <InputGroup className="mb-3">
            <Form.Group
              className="mb-3"
              controlId="formGroupName"
              style={{ width: "100%", textAlign: "left" }}
            >
              <Form.Label>Branch<span style={{ color: "red" }}>*</span></Form.Label>
              <Form.Control
                id="br1"
                style={{padding:"7px",borderRadius:"10px"}}
                type="text"
                placeholder="Name"
                value={addBrachCompany.branch}
                onChange={(e) =>
                  setBrachCompany({
                    ...addBrachCompany,
                    branch: e.target.value,
                  })
                }
              />
            </Form.Group>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              width: "38%",
              background: "white",
              color: "black",
              boxShadow: "2px 3px 3px black",
            }}
            onClick={handleClearFields}
          >
            Cancel
          </Button>
          <Button
            style={{ width: "38%" }}
            variant="primary"
            onClick={() => {
              addBrachCompany?.id ? handleupdateBranch() : handleAddBranch();
            }}
          >
            {addBrachCompany?.id ? "Update Branch" : " Create Branch"}
          </Button>
        </Modal.Footer>
      </Modal>
      <ShowAlert />
      <Snackbar
                open={anyFieldEmpty}
                autoHideDuration={2000}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert
                    severity="error"
                    style={{
                        backgroundColor: '#8f2018',
                        color: "white",
                    }}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
    </>
  );
}

export default Branch;
