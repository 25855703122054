import React, { useEffect, useRef, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa6";
import { useNavigate, useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';
import { useReactToPrint } from 'react-to-print'
function CustomBalanceSheetDetail() {
    const params = useParams()
    const navigate = useNavigate()
    const { balanceSheetsData, getBalanceSheetsData, accountCom, getAccountCom, } = useData()
    useEffect(() => {
        getBalanceSheetsData(params?.id)
        getAccountCom()
    }, [])
    const currentCompany = accountCom?.company?.find(item => item.id === parseInt(params?.id));
    const [showNestedTables, setShowNestedTables] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
    });

    const toggleNestedTable = (rowNumber) => {
        setShowNestedTables((prev) => ({
            ...prev,
            [rowNumber]: !prev[rowNumber],
        }));
    }
    const handleClose = () => {
        navigate('/admin/reports');
    }
    // const handleDownloadCsv = () => {
    //     const csvData = [];
    //     const newLine1 = ['',];
    //     const companyNameRow = [
    //         'Budget Overview:',
    //         `${monthlyBudgetIndi?.monthly?.name}`,
    //     ];
    //     const companyNameRow1 = [
    //         `Financial Year:`,
    //         `${monthlyBudgetIndi?.monthly?.finencial_year}`,
    //     ];
    //     const companyNameRow2 = [
    //         `Total Amount during ${monthlyBudgetIndi?.monthly?.finencial_year?.split("(")[0]}:`,
    //         `Rs${monthlyBudgetIndi?.monthly?.total_amount?.toLocaleString()}`,
    //     ];
    //     csvData.push(companyNameRow);
    //     csvData.push(companyNameRow1);
    //     csvData.push(companyNameRow2);
    //     csvData.push(newLine1);
    //     csvData.push(newLine1);

    //     const headerRow = [
    //         'Accounts',
    //         'January',
    //         'February',
    //         'March',
    //         'April',
    //         'May',
    //         'June',
    //         'July',
    //         'August',
    //         'September',
    //         'October',
    //         'November',
    //         'December',
    //         'Total',
    //     ];
    //     csvData.push(headerRow);

    //     const incomeRows = monthlyBudgetIndi?.monthly?.monthly_income.map((item) => {
    //         const row = [item.account?.name || ''];
    //         let totalIncome = 0;

    //         for (let i = 0; i < 12; i++) {
    //             const amount = item[Object.keys(item)[i + 4]];
    //             row.push(amount);
    //             totalIncome += parseFloat(amount || 0);
    //         }

    //         const totalIncomeAmount = item.total_price ? parseFloat(item.total_price) : 0;
    //         row.push(`PRs ${totalIncomeAmount.toLocaleString()}`);
    //         return row;
    //     });

    //     const costOfSalesRows = monthlyBudgetIndi?.monthly?.monthly_cost_of_sale?.map((item) => {
    //         const row = [item.account?.name || ''];
    //         let totalCostOfSales = 0;

    //         for (let i = 0; i < 12; i++) {
    //             const amount = item[Object.keys(item)[i + 4]];
    //             row.push(amount);
    //             totalCostOfSales += parseFloat(amount || 0);
    //         }

    //         const totalCostOfSalesAmount = item.total_price ? parseFloat(item.total_price) : 0;
    //         row.push(`PRs ${totalCostOfSalesAmount.toLocaleString()}`);
    //         return row;
    //     });

    //     const grossProfitRow = ['Gross Profit'];
    //     let totalGrossProfitAmount = 0;

    //     for (let i = 0; i < 12; i++) {
    //         const incomeAmount = incomeRows?.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
    //         const costOfSalesAmount = costOfSalesRows?.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
    //         const grossProfitAmount = incomeAmount - costOfSalesAmount;
    //         totalGrossProfitAmount += grossProfitAmount;
    //         grossProfitRow.push(`PRs ${grossProfitAmount.toLocaleString()}`);
    //     }

    //     grossProfitRow.push(`PRs ${totalGrossProfitAmount.toLocaleString()}`);
    //     const totalExpensesByAccount = [];
    //     const monthlyExpensesRows = monthlyBudgetIndi?.monthly?.monthly_expenses.map((item) => {
    //         const row = [item.account?.name || ''];
    //         let totalExpenseAmountForAccount = 0;

    //         for (let i = 0; i < 12; i++) {
    //             const amount = parseFloat(item[Object.keys(item)[i + 4]] || 0);
    //             row.push(amount);
    //             totalExpenseAmountForAccount += amount;
    //         }

    //         const totalExpenseAmount = parseFloat(item.total_price?.toLocaleString() || 0);
    //         row.push(`PRs ${totalExpenseAmount.toLocaleString()}`);
    //         totalExpensesByAccount.push(totalExpenseAmountForAccount);
    //         return row;
    //     });
    //     const totalExpensesRow = ['Total Expenses Amount'];
    //     for (let i = 0; i < 12; i++) {
    //         const totalAmountForMonth = monthlyExpensesRows?.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
    //         totalExpensesRow.push(`PRs ${totalAmountForMonth.toLocaleString()}`);
    //     }
    //     totalExpensesRow.push(`PRs ${totalExpensesByAccount?.reduce((total, amount) => total + amount, 0)?.toLocaleString()}`);


    //     const netOperatingIncomeRow = ['Net Operating Income'];
    //     let totalNetOperatingIncomeAmount = 0;

    //     for (let i = 0; i < 12; i++) {
    //         const grossProfitAmount = parseFloat(grossProfitRow[i + 1].split(' ')[1].replace(/,/g, '') || 0);
    //         const totalExpensesAmount = parseFloat(totalExpensesRow[i + 1].split(' ')[1].replace(/,/g, '') || 0);
    //         const netOperatingIncomeAmount = grossProfitAmount - totalExpensesAmount;
    //         totalNetOperatingIncomeAmount += netOperatingIncomeAmount;
    //         netOperatingIncomeRow.push(`PRs ${netOperatingIncomeAmount.toLocaleString()}`);
    //     }

    //     netOperatingIncomeRow.push(`PRs ${totalNetOperatingIncomeAmount.toLocaleString()}`);

    //     const otherIncomeRows = monthlyBudgetIndi?.monthly?.monthly_other_income?.map((item) => {
    //         const row = [item.account?.name || ''];
    //         for (let i = 0; i < 12; i++) {
    //             row.push(item[Object.keys(item)[i + 4]]);
    //         }
    //         row.push(`PRs ${item.total_price?.toLocaleString() ? item.total_price?.toLocaleString() : 0}`);
    //         return row;
    //     });

    //     const otherExpenseRows = monthlyBudgetIndi?.monthly?.monthly_other_expenses?.map((item) => {
    //         const row = [item.account?.name || ''];
    //         for (let i = 0; i < 12; i++) {
    //             row.push(item[Object.keys(item)[i + 4]]);
    //         }
    //         row.push(`PRs ${item.total_price?.toLocaleString() ? item.total_price?.toLocaleString() : 0}`);
    //         return row;
    //     });

    //     const netOtherIncomeRow = ['Net Other Income'];
    //     let totalNetOtherIncomeAmount = 0;

    //     for (let i = 0; i < 12; i++) {
    //         const otherIncomeAmount = otherIncomeRows?.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
    //         const otherExpenseAmount = otherExpenseRows?.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
    //         const netOtherIncomeAmount = otherIncomeAmount - otherExpenseAmount;
    //         totalNetOtherIncomeAmount += netOtherIncomeAmount;
    //         netOtherIncomeRow.push(`PRs ${netOtherIncomeAmount.toLocaleString()}`);
    //     }

    //     const netIncomeRow = ['Net Income'];
    //     let totalNetIncomeAmount = 0;

    //     for (let i = 0; i < 12; i++) {
    //         const netOperatingIncomeAmount = parseFloat(netOperatingIncomeRow[i + 1].split(' ')[1].replace(/,/g, '') || 0);
    //         const netOtherIncomeAmount = parseFloat(netOtherIncomeRow[i + 1].split(' ')[1].replace(/,/g, '') || 0);
    //         const netIncomeAmount = netOperatingIncomeAmount + netOtherIncomeAmount;
    //         totalNetIncomeAmount += netIncomeAmount;
    //         netIncomeRow.push(`PRs ${netIncomeAmount.toLocaleString()}`);
    //     }
    //     netOtherIncomeRow.push(`PRs ${totalNetOtherIncomeAmount.toLocaleString()}`);

    //     csvData.push(['Income'], ...incomeRows);
    //     csvData.push(newLine1);
    //     csvData.push(['Cost of Sales'], ...costOfSalesRows);
    //     csvData.push(newLine1);
    //     csvData.push(grossProfitRow);
    //     csvData.push(newLine1);
    //     csvData.push(['Expenses'], ...monthlyBudgetIndi?.monthly?.monthly_expenses.map((item) => {
    //         const row = [item.account?.name || ''];
    //         for (let i = 0; i < 12; i++) {
    //             row.push(item[Object.keys(item)[i + 4]]);
    //         }
    //         row.push(`PRs ${item.total_price?.toLocaleString() ? item.total_price?.toLocaleString() : 0}`);
    //         return row;
    //     }))
    //     csvData.push(newLine1);
    //     csvData.push(totalExpensesRow);
    //     csvData.push(newLine1);
    //     csvData.push(netOperatingIncomeRow);
    //     csvData.push(newLine1);
    //     netIncomeRow.push(`PRs ${totalNetIncomeAmount.toLocaleString()}`);
    //     csvData.push(['Other Income'], ...otherIncomeRows);
    //     csvData.push(newLine1);
    //     csvData.push(['Other Expenses'], ...otherExpenseRows);
    //     csvData.push(newLine1);
    //     csvData.push(netOtherIncomeRow);
    //     csvData.push(newLine1);
    //     csvData.push(netIncomeRow);
    //     csvData.push(newLine1);

    //     const csv = Papa.unparse(csvData, { header: true });
    //     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    //     saveAs(blob, `${monthlyBudgetIndi?.monthly?.name}-${monthlyBudgetIndi?.monthly?.finencial_year?.split("(")[0]}.csv`);
    // };

    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current) {
            handleDownloadPDF();
        }
    }, [cardRef]);
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return;
        }

        setPdfGenerating(true);

        setTimeout(() => {
            const card = cardRef.current;

            const pdfOptions = {
                margin: 0,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };

            if (card) {
                html2pdf()?.from(card)?.set(pdfOptions)?.outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' });

                    // Create a download link
                    const url = URL.createObjectURL(blob);
                    const a = document?.createElement('a');
                    a.href = url;
                    a.download = 'invoice.pdf';
                    a.style.display = 'none';

                    // Append the link to the body and trigger the click
                    document?.body?.appendChild(a);
                    a.click();

                    // Remove the link after the download
                    document?.body?.removeChild(a);

                    setPdfGenerating(false);
                }).catch((error) => {
                    console.error('Error generating PDF:', error);
                    setPdfGenerating(false);
                });
            } else {
                console.error('Card element not found.');
                setPdfGenerating(false);
            }
        }, 0);
    };

    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    });
    let grandTotal = 0;

    // Sum for Accounts Receivable
    grandTotal += balanceSheetsData?.data?.Accounts_receiveable?.reduce((totalDebit, item) => {
        return totalDebit + (item.accounts_receiveable ? item.accounts_receiveable?.reduce((sum, asset) => sum + (asset.debit ? parseFloat(asset.debit) : 0), 0) : 0);
    }, 0) || 0;

    // Sum for Current_assets
    grandTotal += balanceSheetsData?.data?.Current_assets?.reduce((totalDebit, item) => {
        return totalDebit + (item.current_assets ? item.current_assets?.reduce((sum, asset) => sum + (asset.debit ? parseFloat(asset.debit) : 0), 0) : 0);
    }, 0) || 0;

    // Sum for Cash_and_cash_equivalents
    grandTotal += balanceSheetsData?.data?.Cash_and_cash_equivalents?.reduce((totalDebit, item) => {
        return totalDebit + (item.cash_and_cash_equivalents ? item.cash_and_cash_equivalents?.reduce((sum, asset) => sum + (asset.debit ? parseFloat(asset.debit) : 0), 0) : 0);
    }, 0) || 0;

    // Sum for Non_current_assets
    grandTotal += balanceSheetsData?.data?.Non_current_assets?.reduce((totalDebit, item) => {
        return totalDebit + (item.non_current_assets ? item.non_current_assets?.reduce((sum, asset) => sum + (asset.debit ? parseFloat(asset.debit) : 0), 0) : 0);
    }, 0) || 0;

    // Sum for Fixed_assets
    grandTotal += balanceSheetsData?.data?.Fixed_assets?.reduce((totalDebit, item) => {
        return totalDebit + (item.fixed_assets ? item.fixed_assets?.reduce((sum, asset) => sum + (asset.debit ? parseFloat(asset.debit) : 0), 0) : 0);
    }, 0) || 0;

    let grandTotal1 = 0;

    grandTotal1 += balanceSheetsData?.data?.Owner_equity?.reduce((totalDebit, item) => {
        return totalDebit + (item.owner_equity ? item.owner_equity?.reduce((sum, asset) => sum + (asset.debit ? parseFloat(asset.debit) : 0), 0) : 0);
    }, 0) || 0;
    let grandTotal2 = 0;

    grandTotal2 += balanceSheetsData?.data?.Current_liabilities?.reduce((totalDebit, item) => {
        return totalDebit + (item.current_liabilities ? item.current_liabilities?.reduce((sum, asset) => sum + (asset.debit ? parseFloat(asset.debit) : 0), 0) : 0);
    }, 0) || 0;
    grandTotal2 += balanceSheetsData?.data?.Non_current_liabilities?.reduce((totalDebit, item) => {
        return totalDebit + (item.non_current_liabilities ? item.non_current_liabilities?.reduce((sum, asset) => sum + (asset.debit ? parseFloat(asset.debit) : 0), 0) : 0);
    }, 0) || 0;

    console.log(balanceSheetsData)
    return (
        <>
            <div className='createBudgetData'>
                <div className='row' ref={contentRef}>
                    <div className='card'>
                        <div className='card-body table-responsive recruitment'>
                            <div className='row mb-3'>
                                <h3 style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center" }}>{currentCompany?.name}</h3>
                                <h3 style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center" }}>Balance Sheet Detail</h3>
                            </div>
                            <hr />
                            <table className='table table-hover table-bordered' style={{ marginBottom: "2rem" }}>
                                <thead style={{ background: "#f3f5f8", color: "black", fontWeight: "bold" }}>
                                    <tr>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }} colSpan={2}>Account</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Date</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Transaction Type</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>No.</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>name</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Location</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Class</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Memo/Desc</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Split</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Debit</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Credit</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Amount</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan="30">
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Assets</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(1)}>
                                                    {showNestedTables[1] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    {showNestedTables[1] && (
                                        <>
                                            <tr style={{ color: "black", fontWeight: "bold" }}>
                                                <td colSpan="16">
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <p style={{ marginBottom: "0", marginLeft: "1rem" }}>Accounts Receiveable</p>
                                                        <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(2)}>
                                                            {showNestedTables[2] ? <FaChevronUp /> : <FaChevronDown />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            {showNestedTables[2] && (
                                                <>
                                                    {balanceSheetsData?.data?.Accounts_receiveable?.filter(items => items?.type_id === 3)?.map((item) => {
                                                        return (
                                                            <React.Fragment key={item?.id}>
                                                                <tr onClick={() => toggleNestedTable(3)}>
                                                                    <td colSpan="15">
                                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                            <p style={{ marginBottom: "0", marginLeft: "2rem" }}>{item?.name}</p>
                                                                            <button style={{ background: "transparent", border: "0" }}>
                                                                                {showNestedTables[3] ? <FaChevronUp /> : <FaChevronDown />}
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {showNestedTables[3] && (
                                                                    <>
                                                                        {item?.accounts_receiveable?.map((trans) => {
                                                                            return (
                                                                                <tr key={trans?.id}>
                                                                                    <td colSpan="2"></td>
                                                                                    <td>{trans?.transaction?.date}</td>
                                                                                    <td>{trans?.transaction?.type}</td>
                                                                                    <td>{trans?.transaction?.ref_no}</td>
                                                                                    <td>{trans?.transaction?.supplier?.name}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.message ? trans?.transaction?.message : '-'}</td>
                                                                                    <td>{trans?.transaction?.account?.name}</td>
                                                                                    <td>{trans?.debit ? trans?.debit : '0'}</td>
                                                                                    <td>{trans?.credit ? trans?.credit : '0'}</td>
                                                                                    <td>{trans?.amount ? trans?.amount : '0'}</td>
                                                                                    <td>{trans?.balance ? trans?.balance : '0'}</td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </>
                                            )}
                                            <tr style={{ color: "black", fontWeight: "bold" }}>
                                                <td colSpan="16">
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <p style={{ marginBottom: "0", marginLeft: "1rem" }}>Currect Assets</p>
                                                        <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(5)}>
                                                            {showNestedTables[5] ? <FaChevronUp /> : <FaChevronDown />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            {showNestedTables[5] && (
                                                <>
                                                    {balanceSheetsData?.data?.Current_assets?.filter(items => items?.type_id === 4)?.map((item) => {
                                                        return (
                                                            <React.Fragment key={item?.id}>
                                                                <tr onClick={() => toggleNestedTable(6)}>
                                                                    <td colSpan="15">
                                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                            <p style={{ marginBottom: "0", marginLeft: "2rem" }}>{item?.name}</p>
                                                                            <button style={{ background: "transparent", border: "0" }}>
                                                                                {showNestedTables[6] ? <FaChevronUp /> : <FaChevronDown />}
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {showNestedTables[6] && (
                                                                    <>
                                                                        {item?.current_assets?.map((trans) => {
                                                                            return (
                                                                                <tr key={trans?.id}>
                                                                                    <td colSpan="2"></td>
                                                                                    <td>{trans?.transaction?.date}</td>
                                                                                    <td>{trans?.transaction?.type}</td>
                                                                                    <td>{trans?.transaction?.ref_no}</td>
                                                                                    <td>{trans?.transaction?.supplier?.name}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.message ? trans?.transaction?.message : '-'}</td>
                                                                                    <td>{trans?.transaction?.account?.name}</td>
                                                                                    <td>{trans?.debit ? trans?.debit : '0'}</td>
                                                                                    <td>{trans?.credit ? trans?.credit : '0'}</td>
                                                                                    <td>{trans?.amount ? trans?.amount : '0'}</td>
                                                                                    <td>{trans?.balance ? trans?.balance : '0'}</td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </>
                                            )}
                                            <tr style={{ color: "black", fontWeight: "bold" }}>
                                                <td colSpan="16">
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <p style={{ marginBottom: "0", marginLeft: "1rem" }}>Cash and Cash Equivalents</p>
                                                        <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(7)}>
                                                            {showNestedTables[7] ? <FaChevronUp /> : <FaChevronDown />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            {showNestedTables[7] && (
                                                <>
                                                    {balanceSheetsData?.data?.Cash_and_cash_equivalents?.filter(items => items?.type_id === 5)?.map((item) => {
                                                        return (
                                                            <React.Fragment key={item?.id}>
                                                                <tr onClick={() => toggleNestedTable(8)}>
                                                                    <td colSpan="15">
                                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                            <p style={{ marginBottom: "0", marginLeft: "2rem" }}>{item?.name}</p>
                                                                            <button style={{ background: "transparent", border: "0" }}>
                                                                                {showNestedTables[8] ? <FaChevronUp /> : <FaChevronDown />}
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {showNestedTables[8] && (
                                                                    <>
                                                                        {item?.cash_and_cash_equivalents?.map((trans) => {
                                                                            return (
                                                                                <tr key={trans?.id}>
                                                                                    <td colSpan="2"></td>
                                                                                    <td>{trans?.transaction?.date}</td>
                                                                                    <td>{trans?.transaction?.type}</td>
                                                                                    <td>{trans?.transaction?.ref_no}</td>
                                                                                    <td>{trans?.transaction?.supplier?.name}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.message ? trans?.transaction?.message : '-'}</td>
                                                                                    <td>{trans?.transaction?.account?.name}</td>
                                                                                    <td>{trans?.debit ? trans?.debit : '0'}</td>
                                                                                    <td>{trans?.credit ? trans?.credit : '0'}</td>
                                                                                    <td>{trans?.amount ? trans?.amount : '0'}</td>
                                                                                    <td>{trans?.balance ? trans?.balance : '0'}</td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </>
                                            )}
                                            <tr style={{ color: "black", fontWeight: "bold" }}>
                                                <td colSpan="16">
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <p style={{ marginBottom: "0", marginLeft: "1rem" }}>Non Current Assets</p>
                                                        <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(9)}>
                                                            {showNestedTables[9] ? <FaChevronUp /> : <FaChevronDown />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            {showNestedTables[9] && (
                                                <>
                                                    {balanceSheetsData?.data?.Non_current_assets?.filter(items => items?.type_id === 7)?.map((item) => {
                                                        return (
                                                            <React.Fragment key={item?.id}>
                                                                <tr onClick={() => toggleNestedTable(10)}>
                                                                    <td colSpan="15">
                                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                            <p style={{ marginBottom: "0", marginLeft: "2rem" }}>{item?.name}</p>
                                                                            <button style={{ background: "transparent", border: "0" }}>
                                                                                {showNestedTables[10] ? <FaChevronUp /> : <FaChevronDown />}
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {showNestedTables[10] && (
                                                                    <>
                                                                        {item?.non_current_assets?.map((trans) => {
                                                                            return (
                                                                                <tr key={trans?.id}>
                                                                                    <td colSpan="2"></td>
                                                                                    <td>{trans?.transaction?.date}</td>
                                                                                    <td>{trans?.transaction?.type}</td>
                                                                                    <td>{trans?.transaction?.ref_no}</td>
                                                                                    <td>{trans?.transaction?.supplier?.name}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.message ? trans?.transaction?.message : '-'}</td>
                                                                                    <td>{trans?.transaction?.account?.name}</td>
                                                                                    <td>{trans?.debit ? trans?.debit : '0'}</td>
                                                                                    <td>{trans?.credit ? trans?.credit : '0'}</td>
                                                                                    <td>{trans?.amount ? trans?.amount : '0'}</td>
                                                                                    <td>{trans?.balance ? trans?.balance : '0'}</td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </>
                                            )}
                                            <tr style={{ color: "black", fontWeight: "bold" }}>
                                                <td colSpan="16">
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <p style={{ marginBottom: "0", marginLeft: "1rem" }}>Fixed Asset</p>
                                                        <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(11)}>
                                                            {showNestedTables[11] ? <FaChevronUp /> : <FaChevronDown />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            {showNestedTables[11] && (
                                                <>
                                                    {balanceSheetsData?.data?.Fixed_assets?.filter(items => items?.type_id === 6)?.map((item) => {
                                                        return (
                                                            <React.Fragment key={item?.id}>
                                                                <tr onClick={() => toggleNestedTable(12)}>
                                                                    <td colSpan="15">
                                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                            <p style={{ marginBottom: "0", marginLeft: "2rem" }}>{item?.name}</p>
                                                                            <button style={{ background: "transparent", border: "0" }}>
                                                                                {showNestedTables[12] ? <FaChevronUp /> : <FaChevronDown />}
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {showNestedTables[12] && (
                                                                    <>
                                                                        {item?.fixed_assets?.map((trans) => {
                                                                            return (
                                                                                <tr key={trans?.id}>
                                                                                    <td colSpan="2"></td>
                                                                                    <td>{trans?.transaction?.date}</td>
                                                                                    <td>{trans?.transaction?.type}</td>
                                                                                    <td>{trans?.transaction?.ref_no}</td>
                                                                                    <td>{trans?.transaction?.supplier?.name}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.message ? trans?.transaction?.message : '-'}</td>
                                                                                    <td>{trans?.transaction?.account?.name}</td>
                                                                                    <td>{trans?.debit ? trans?.debit : '0'}</td>
                                                                                    <td>{trans?.credit ? trans?.credit : '0'}</td>
                                                                                    <td>{trans?.amount ? trans?.amount : '0'}</td>
                                                                                    <td>{trans?.balance ? trans?.balance : '0'}</td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </>
                                    )}
                                    <tr>
                                        <th colSpan={'13'}>Total Amount</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>
                                                {grandTotal?.toLocaleString()}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan="30">
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Equity</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(13)}>
                                                    {showNestedTables[13] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    {showNestedTables[13] && (
                                        <>
                                            <tr style={{ color: "black", fontWeight: "bold" }}>
                                                <td colSpan="16">
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <p style={{ marginBottom: "0", marginLeft: "1rem" }}>Owner Equity</p>
                                                        <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(14)}>
                                                            {showNestedTables[14] ? <FaChevronUp /> : <FaChevronDown />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            {showNestedTables[14] && (
                                                <>
                                                    {balanceSheetsData?.data?.Owner_equity?.filter(items => items?.type_id === 12)?.map((item) => {
                                                        return (
                                                            <React.Fragment key={item?.id}>
                                                                <tr onClick={() => toggleNestedTable(15)}>
                                                                    <td colSpan="15">
                                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                            <p style={{ marginBottom: "0", marginLeft: "2rem" }}>{item?.name}</p>
                                                                            <button style={{ background: "transparent", border: "0" }}>
                                                                                {showNestedTables[15] ? <FaChevronUp /> : <FaChevronDown />}
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {showNestedTables[15] && (
                                                                    <>
                                                                        {item?.owner_equity?.map((trans) => {
                                                                            return (
                                                                                <tr key={trans?.id}>
                                                                                    <td colSpan="2"></td>
                                                                                    <td>{trans?.transaction?.date}</td>
                                                                                    <td>{trans?.transaction?.type}</td>
                                                                                    <td>{trans?.transaction?.ref_no}</td>
                                                                                    <td>{trans?.transaction?.supplier?.name}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.message ? trans?.transaction?.message : '-'}</td>
                                                                                    <td>{trans?.transaction?.account?.name}</td>
                                                                                    <td>{trans?.debit ? trans?.debit : '0'}</td>
                                                                                    <td>{trans?.credit ? trans?.credit : '0'}</td>
                                                                                    <td>{trans?.amount ? trans?.amount : '0'}</td>
                                                                                    <td>{trans?.balance ? trans?.balance : '0'}</td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </>
                                    )}
                                    <tr>
                                        <th colSpan={'13'}>Total Amount</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>
                                                {grandTotal1?.toLocaleString()}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan="30">
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Liability</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(16)}>
                                                    {showNestedTables[16] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    {showNestedTables[16] && (
                                        <>
                                            <tr style={{ color: "black", fontWeight: "bold" }}>
                                                <td colSpan="16">
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <p style={{ marginBottom: "0", marginLeft: "1rem" }}>Current Liabilities</p>
                                                        <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(17)}>
                                                            {showNestedTables[17] ? <FaChevronUp /> : <FaChevronDown />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            {showNestedTables[17] && (
                                                <>
                                                    {balanceSheetsData?.data?.Current_liabilities?.filter(items => items?.type_id === 10)?.map((item) => {
                                                        return (
                                                            <React.Fragment key={item?.id}>
                                                                <tr onClick={() => toggleNestedTable(17)}>
                                                                    <td colSpan="15">
                                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                            <p style={{ marginBottom: "0", marginLeft: "2rem" }}>{item?.name}</p>
                                                                            <button style={{ background: "transparent", border: "0" }}>
                                                                                {showNestedTables[17] ? <FaChevronUp /> : <FaChevronDown />}
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {showNestedTables[17] && (
                                                                    <>
                                                                        {item?.current_liabilities?.map((trans) => {
                                                                            return (
                                                                                <tr key={trans?.id}>
                                                                                    <td colSpan="2"></td>
                                                                                    <td>{trans?.transaction?.date}</td>
                                                                                    <td>{trans?.transaction?.type}</td>
                                                                                    <td>{trans?.transaction?.ref_no}</td>
                                                                                    <td>{trans?.transaction?.supplier?.name}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.message ? trans?.transaction?.message : '-'}</td>
                                                                                    <td>{trans?.transaction?.account?.name}</td>
                                                                                    <td>{trans?.debit ? trans?.debit : '0'}</td>
                                                                                    <td>{trans?.credit ? trans?.credit : '0'}</td>
                                                                                    <td>{trans?.amount ? trans?.amount : '0'}</td>
                                                                                    <td>{trans?.balance ? trans?.balance : '0'}</td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </>
                                            )}
                                            <tr style={{ color: "black", fontWeight: "bold" }}>
                                                <td colSpan="16">
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <p style={{ marginBottom: "0", marginLeft: "1rem" }}>Non Current Liabilities</p>
                                                        <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(18)}>
                                                            {showNestedTables[18] ? <FaChevronUp /> : <FaChevronDown />}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            {showNestedTables[18] && (
                                                <>
                                                    {balanceSheetsData?.data?.Non_current_liabilities?.filter(items => items?.type_id === 10)?.map((item) => {
                                                        return (
                                                            <React.Fragment key={item?.id}>
                                                                <tr onClick={() => toggleNestedTable(19)}>
                                                                    <td colSpan="15">
                                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                            <p style={{ marginBottom: "0", marginLeft: "2rem" }}>{item?.name}</p>
                                                                            <button style={{ background: "transparent", border: "0" }}>
                                                                                {showNestedTables[19] ? <FaChevronUp /> : <FaChevronDown />}
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {showNestedTables[19] && (
                                                                    <>
                                                                        {item?.non_current_liabilities?.map((trans) => {
                                                                            return (
                                                                                <tr key={trans?.id}>
                                                                                    <td colSpan="2"></td>
                                                                                    <td>{trans?.transaction?.date}</td>
                                                                                    <td>{trans?.transaction?.type}</td>
                                                                                    <td>{trans?.transaction?.ref_no}</td>
                                                                                    <td>{trans?.transaction?.supplier?.name}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.location?.location ? trans?.transaction?.location?.location : 'N/A'}</td>
                                                                                    <td>{trans?.transaction?.message ? trans?.transaction?.message : '-'}</td>
                                                                                    <td>{trans?.transaction?.account?.name}</td>
                                                                                    <td>{trans?.debit ? trans?.debit : '0'}</td>
                                                                                    <td>{trans?.credit ? trans?.credit : '0'}</td>
                                                                                    <td>{trans?.amount ? trans?.amount : '0'}</td>
                                                                                    <td>{trans?.balance ? trans?.balance : '0'}</td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </>
                                    )}
                                    <tr>
                                        <th colSpan={'13'}>Total Amount</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>
                                                {grandTotal2?.toLocaleString()}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
                <div style={{ background: "white", padding: "1rem", position: "fixed", width: "100%", bottom: "0", left: "0", display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <div>
                        <button onClick={handlePrint} className='btn btn-primary'>Save as PDF</button>
                        {/* <button onClick={handleDownloadCsv} className='btn btn-success mx-3'>Save as XLS</button> */}
                        <button className='btn btn-warning' onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomBalanceSheetDetail 
