import React, { useEffect, useState } from 'react'
import { GrServices } from 'react-icons/gr'
import { CiTrash } from 'react-icons/ci'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function EqualityLiability() {
    const params = useParams()
    const { accountCom, getAccountCom, handleClick, setSnackbarValue, getAccountData, accountData, getEqulityData, euqualityData, deleteEquityLibilitData, storeEquityLibilitData, getFilterQuityLiability } = useData()
    useEffect(() => {
        getAccountData()
        getEqulityData()
        getAccountCom()
    }, [])
    const [selectedCompany, setSelectedCompany] = useState(null);
    const filteredCompanies = accountCom?.company?.filter(item => item.deleteStatus === 0);
    const filteredAccounts = accountCom?.account?.filter(item => item.company_id === selectedCompany);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [obj, setObj] = useState({
        company_id: params.id ? params.id : selectedCompany,
        name: '',
        type: '',
        amount: '',
        description: '',
        account_id: ''
    })
    const isFormValid = () => {
        for (const key in obj) {
            if (key !== "description" && obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = () => {
        if (isFormValid()) {
            storeEquityLibilitData(obj)
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const [date, setdate] = useState({
        company: "",
        startDate: "",
        endddate: ""
    })
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleEquityData = params.id
        ? euqualityData?.data?.filter(item => item?.company?.id == params.id) || []
        : euqualityData?.data || [];

    const currentEquityData = visibleEquityData?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleEquityData.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }

    return (
        <>
            <div className='expenseCategory'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>

                    <div className='inp-buttons d-flex gap-2 w-100' style={{ alignItems: "end", justifyContent: "space-between" }}>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Equity Liability</li>
                                </ol>
                            </nav>
                        </div>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                                <div>
                                    <label style={{ fontWeight: "bold", fontSize: "12px" }}>Company</label> <br />
                                    <select disabled={params.id ? true : false} className='form-select' style={{ height: "36px" }} onChange={(e) => setdate({ ...date, company: e.target.value })} >
                                        <option value="">Select a company</option>
                                        {filteredCompanies?.map((item) => (
                                            <option key={item.id} value={item.name}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='startdate'>
                                    <label style={{ fontWeight: "bold", fontSize: "12px" }}>Select Start Date</label> <br />
                                    <input type='date' name='start' onChange={(e) => setdate({ ...date, startDate: e.target.value })} className='form-control' />
                                </div>
                                <div className='startdate'>
                                    <label style={{ fontWeight: "bold", fontSize: "12px" }}>Select End Date</label> <br />
                                    <input type='date' name='end' onChange={(e) => setdate({ ...date, endddate: e.target.value })} className='form-control' />
                                </div>
                                <button onClick={() => {
                                    handlePageChange(1)
                                    getFilterQuityLiability(date.startDate, date.endddate, date.company)
                                }} className='btn btn-primary'>Search</button>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={handleOpen}>Add</button>
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{
                                height: '600px'
                            }}
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Add
                                </Typography>
                                <div id="modal-modal-description" sx={{ mt: 2 }}>
                                    <div className='row'>
                                        <div className='col-6 mt-3'>
                                            <div className='inputFields'>
                                                <label>Company</label>
                                                <div className='selectBox'>
                                                    <label><GrServices /></label>
                                                    <select disabled={params.id ? true : false} onChange={(e) => {
                                                        setSelectedCompany(parseInt(e.target.value));
                                                        setObj({
                                                            ...obj,
                                                            company_id: parseInt(e.target.value)
                                                        });
                                                    }}>
                                                        <option value="">Select a company</option>
                                                        {filteredCompanies?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 mt-3'>
                                            <div className='inputFields'>
                                                <label>Enter Name</label>
                                                <div className='inputBox '>
                                                    <label><GrServices /></label>
                                                    <input placeholder='Enter Name' type='text' onChange={(e) => setObj({ ...obj, name: e.target.value })}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 mt-3'>
                                            <div className='inputFields'>
                                                <label>Account</label>
                                                <div className='selectBox'>
                                                    <label><GrServices /></label>
                                                    <select onChange={(e) => setObj({ ...obj, account_id: e.target.value })}>
                                                        <option value="">Select Account</option>
                                                        {filteredAccounts?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.bank_name}
                                                            </option>
                                                        ))}
                                                        {!filteredAccounts?.some(item => item.bank_name === obj.bank_name) && (
                                                            <option value="createNew" disabled>Create New Account: {obj.bank_name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 mt-3'>
                                            <div className='inputFields'>
                                                <label>Enter Amount</label>
                                                <div className='inputBox'>
                                                    <label><GrServices /></label>
                                                    <input placeholder='Enter Amount' type='number' onChange={(e) => setObj({ ...obj, amount: e.target.value })}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 mt-3'>
                                            <div className='inputFields'>
                                                <label>Type</label>
                                                <div className='selectBox'>
                                                    <label><GrServices /></label>
                                                    <select onChange={(e) => setObj({ ...obj, type: e.target.value })}>
                                                        <option hidden>Select Type</option>
                                                        <option value='Equity'>Equity</option>
                                                        <option value='Liabilities'>Liabilities</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 mt-3'>
                                            <div className='inputFields'>
                                                <label>Description</label>
                                                <div className='inputBox'>
                                                    <label><GrServices /></label>
                                                    <input placeholder='Enter Description' type='text' onChange={(e) => setObj({ ...obj, description: e.target.value })}></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='modalBtns d-flex justify-content-end gap-2'>
                                        <button className='btn btn-primary' onClick={handleSubmit}>Submit</button>
                                        <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>

                    </div>

                </div>
                <hr />
                <div className='card'>
                    <div className='card-body recruitment table-responsive'>
                        <table class="table mx-auto w-100">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Bank Name</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {currentEquityData.length > 0 ? (
                                    currentEquityData.map((item, indx) => {
                                        const dateParts = item.date?.split(' ');
                                        const truncatedDescription = item.description ? item.description.slice(0, 20) : '';
                                        const isDescriptionTruncated = item.description?.length > 20;

                                        return (
                                            <tr key={item.id}>
                                                <th scope="row">{indexOfFirstItem + indx + 1}</th>
                                                <td>{item.company?.name}</td>
                                                <td>{item.account?.bank_name}</td>
                                                <td>{item.name}</td>
                                                <td>{dateParts[0]}</td>
                                                <td>{item.type}</td>
                                                <td>{item.amount}</td>
                                                <td>
                                                    {isDescriptionTruncated ? (
                                                        <span
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedDescription(item.description);
                                                                setShowDescriptionOverlay(true);
                                                            }}
                                                        >
                                                            {truncatedDescription}...
                                                        </span>
                                                    ) : (
                                                        item.description ? item.description : '-'
                                                    )}
                                                </td>
                                                {showDescriptionOverlay && (
                                                    <div className="overlayy" style={{ background: "rgba(0,0,0,0.38)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                        <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                            <p style={{ color: "white" }}>{selectedDescription}</p>
                                                        </div>
                                                    </div>
                                                )}
                                                <td>
                                                    <button className="btn btn-danger p-1 m-2" onClick={() => deleteEquityLibilitData(item.id)}><CiTrash style={{ fontSize: "20px" }} /></button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="9" style={{ textAlign: "center" }}>
                                            No Data
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination-controls">{paginationControls}</div>

                    </div>
                </div>

            </div >
        </>
    )
}

export default EqualityLiability

