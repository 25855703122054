import React from "react";
import { Drawer, IconButton, Card, CardContent } from "@mui/material";
import wbackimg from "../Images/wbackimg.png";
import wclogo from "../Images/wclogo.png";
import wcvector from "../Images/wcvector.png";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";

function Ewizard() {
 
  const [divColor, setDivColor] = React.useState("blue");
  const [openDrawer1, setOpenDrawer1] = React.useState(false);
  const [openDrawer2, setOpenDrawer2] = React.useState(false);
  const [openDrawer3, setOpenDrawer3] = React.useState(false);
  const [openDrawer4, setOpenDrawer4] = React.useState(false);
  const [openDrawer5, setOpenDrawer5] = React.useState(false);
  const [openDrawer6, setOpenDrawer6] = React.useState(false);
  const [openDrawer7, setOpenDrawer7] = React.useState(false);
  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const [totalTime, setTotalTime] = React.useState("");
  const backgroundStyle = {
    backgroundImage: 'url("../Images/wbackimg.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100vh", // Adjust the height as needed
  };

  const handleDrawerOpen1 = () => {
    setOpenDrawer1(true);
    setOpenDrawer2(false);
  };
  const setcolor = () => {
    setDivColor("blue");
  };

  const handleDrawerClose1 = () => {
    setOpenDrawer1(false);
  };

  const handleDrawerOpen2 = () => {
    setOpenDrawer2(true);
    setOpenDrawer1(false); // Close the previous drawer
    setOpenDrawer3(false);
  };

  const handleDrawerClose2 = () => {
    setOpenDrawer2(false);
  };

  const handleDrawerOpen3 = () => {
    setOpenDrawer3(true);
    setOpenDrawer2(false); // Close the previous drawer
    setOpenDrawer4(false);
  };

  const handleDrawerClose3 = () => {
    setOpenDrawer3(false);
  };
  const handleDrawerClose4 = () => {
    setOpenDrawer4(false);
  };
  const handleDrawerOpen4 = () => {
    setOpenDrawer4(true);
    setOpenDrawer3(false);
    setOpenDrawer5(false); // Close the previous drawer
  };
  const handleDrawerClose5 = () => {
    setOpenDrawer5(false);
    

  };
  const handleDrawerOpen5 = () => {
    setOpenDrawer5(true);
    setOpenDrawer4(false); // Close the previous drawer
    setOpenDrawer6(false); 
  };
  const handleDrawerClose6 = () => {
    setOpenDrawer6(false);
   

  };
  const handleDrawerOpen6 = () => {
    setOpenDrawer6(true);
    setOpenDrawer5(false); // Close the previous drawer
    setOpenDrawer7(false);
  };

  const handleDrawerClose7= () => {
    setOpenDrawer7(false);
   

  };
  const handleDrawerOpen7 = () => {
    setOpenDrawer7(true);
    setOpenDrawer6(false); // Close the previous drawer
  };

 

  return (
    <div className="drawerData">
      <button className="btn btn-primary" onClick={handleDrawerOpen1}>
        Add Employee
      </button>

      <Drawer
      className="mycmpny-drawer"
        anchor="right"
        open={openDrawer1}
        onClose={handleDrawerClose1}
        style={{ width: "800px" }}
      >
        {/* Drawer 1 content */}

        <div
          className="wizradparent1"
          style={{ padding: "40px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                    background: "blue",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
              }}
            >
              <div
                className="headerimg"
                style={{
                  backgroundImage: `url(${wbackimg})`,
                  height: "146px",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "94%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  src={wcvector}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    padding: "22px",
                    borderRadius: "50%",
                    background: "darkgray",
                  }}
                >
                  <img src={wclogo} />
                </div>
              </div>
              <div className="inputsection" style={{ marginTop: "60px" }}>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label> Name</Form.Label>
                      <Form.Control
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="text"
                        placeholder="Name"
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label> E-mail</Form.Label>
                      <Form.Control
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="email"
                        placeholder="Email"
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label> Contact Number</Form.Label>
                      <Form.Control
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="number"
                        placeholder="+92"
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="text"
                        placeholder="Password"
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Temporary Address</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Temporary Address"
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Permanent Address</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Permanent Address"
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Gender</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Gender</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="DD-MM-YYYY"
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>National ID</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="ID"
                      />
                    </Form.Group>

                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Nationality</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Nationality</option>
                        <option>Pakistani</option>
                        <option>Overseas</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Martial Status</Form.Label>
                      <Form.Select
                        style={{
                          width: "49%",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Martial Status</option>
                        <option>Single</option>
                        <option>Married</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleDrawerClose1}
              >
                {" "}
                Discard
              </Button>
              <Button type="submit" variant="danger">
                {" "}
                Save&Exit
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleDrawerOpen2();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer className="mycmpny-drawer" anchor="right" open={openDrawer2} onClose={handleDrawerClose2}>
        {/* Drawer 2 content */}

        <div
          className="wizradparent1"
          style={{ padding: "40px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                   
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                    background:divColor,
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
              }}
            >
              <div
                className="headerimg"
                style={{
                  backgroundImage: `url(${wbackimg})`,
                  height: "146px",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "94%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  src={wcvector}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    padding: "22px",
                    borderRadius: "50%",
                    background: "darkgray",
                  }}
                >
                  <img src={wclogo} />
                </div>
              </div>
              <div className="inputsection" style={{ marginTop: "60px" }}>
                <h3>Bank Account Information</h3>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Bank</option>
                        <option>HBL</option>
                        <option>Allied</option>
                        <option>Alflah</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Account Tittle</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Title"
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Account Number"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>IBAN</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="IBAN Number"
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleDrawerClose2}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen1}
              >
                Back
              </Button>
              <Button type="submit" variant="danger">
                {" "}
                Save & Exit
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleDrawerOpen3();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer className="mycmpny-drawer" anchor="right" open={openDrawer3} onClose={handleDrawerClose3}>
        {/* Drawer 3 content */}

        <div
          className="wizradparent1"
          style={{ padding: "40px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                   
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                    background:divColor,
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
              }}
            >
              <div
                className="headerimg"
                style={{
                  backgroundImage: `url(${wbackimg})`,
                  height: "146px",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "94%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  src={wcvector}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    padding: "22px",
                    borderRadius: "50%",
                    background: "darkgray",
                  }}
                >
                  <img src={wclogo} />
                </div>
              </div>
              <div className="inputsection" style={{ marginTop: "60px" }}>
                <h3>Designation Information</h3>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Company</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Company</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Branch</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Branch</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Department</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Department</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Designation</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Designation</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Role</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Role</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Salary</Form.Label>
                      <Form.Control
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="text"
                        placeholder="Salary"
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Date of joining</Form.Label>
                      <Form.Control
                        style={{
                          width: "49%",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="DD-MM-YYYY"
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleDrawerClose3}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen2}
              >
                Back
              </Button>
              <Button type="submit" variant="danger">
                {" "}
                Save & Exit
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleDrawerOpen4();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer className="mycmpny-drawer" anchor="right" open={openDrawer4} onClose={handleDrawerClose4}>
        {/* Drawer 4 content */}

        <div
          className="wizradparent1"
          style={{ padding: "40px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                    
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                    background:divColor,
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
              }}
            >
              <div
                className="headerimg"
                style={{
                  backgroundImage: `url(${wbackimg})`,
                  height: "146px",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "94%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  src={wcvector}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    padding: "22px",
                    borderRadius: "50%",
                    background: "darkgray",
                  }}
                >
                  <img src={wclogo} />
                </div>
              </div>
              <div className="inputsection" style={{ marginTop: "60px" }}>
                <h5>Education</h5>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Educaiton</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Select Last</option>
                        <option></option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Institute Name</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Select</option>
                        <option></option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Subjects</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Select</option>
                        <option></option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                </Form>
                <div className="row" style={{justifyContent:"right"}} >
                    <Button style={{width:"20%"}}type="submit"
                variant="primary">Add More</Button>

                </div>
              </div>
              <div className="inputsection" style={{ marginTop: "60px" }}>
                <h5>Certificate & Diploma</h5>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Select Last</option>
                        <option></option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Institute Name</Form.Label>
                      <Form.Select
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <option hidden>Name</option>
                        <option></option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>URL </Form.Label>
                      <Form.Control
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="number"
                        placeholder="WWW.Example.com"
                      />
                    </Form.Group>
                  </Row>
                </Form>
                <div className="row" style={{justifyContent:"right"}} >
                    <Button style={{width:"20%"}}type="submit"
                variant="primary">Add More</Button>

                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleDrawerClose4}
              >
                {" "}
                Discard
              </Button>

              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen3}
              >
                Back
              </Button>
              <Button type="submit" variant="danger">
                {" "}
                Save&Exit
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                    handleDrawerOpen5();
                    setcolor();
                  }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
      className="mycmpny-drawer"
        anchor="right"
        open={openDrawer5}
        onClose={handleDrawerClose5}
        style={{ width: "800px" }}
      >
        {/* Drawer 5 content */}

        <div
          className="wizradparent1"
          style={{ padding: "40px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                    
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                    background:divColor,
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
              }}
            >
              <div
                className="headerimg"
                style={{
                  backgroundImage: `url(${wbackimg})`,
                  height: "146px",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "94%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  src={wcvector}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    padding: "22px",
                    borderRadius: "50%",
                    background: "darkgray",
                  }}
                >
                  <img src={wclogo} />
                </div>
              </div>
              <div className="inputsection" style={{ marginTop: "60px" }}>
                <h5>KIN Information</h5>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Kin Name</Form.Label>
                      <Form.Control
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="text"
                        placeholder="Name"
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label> Contact Number</Form.Label>
                      <Form.Control
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="number"
                        placeholder="+92"
                      />
                    </Form.Group>

                 
                  </Row>
                 
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Temporary Address"
                      />
                    </Form.Group>

                 
                  </Row>
                
                 
               
                </Form>
              </div>
              
              <div className="inputsection" style={{ marginTop: "60px" }}>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Next to Kin Name</Form.Label>
                      <Form.Control
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="text"
                        placeholder="Name"
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Next to Contact Number</Form.Label>
                      <Form.Control
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="number"
                        placeholder="+92"
                      />
                    </Form.Group>

                 
                  </Row>
                 
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Next to Address</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Temporary Address"
                      />
                    </Form.Group>

                 
                  </Row>
                
                 
               
                </Form>
              </div>
              <div className="inputsection" style={{ marginTop: "60px" }}>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label> Emergency Contact Name</Form.Label>
                      <Form.Control
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="text"
                        placeholder="Name"
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Emergency Contact Number</Form.Label>
                      <Form.Control
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="number"
                        placeholder="+92"
                      />
                    </Form.Group>

                 
                  </Row>
                 
                
                
                 
               
                </Form>
              </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleDrawerClose5}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen4}
              >
                Back
              </Button>

              <Button type="submit" variant="danger">
                {" "}
                Save&Exit
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                    handleDrawerOpen6();
                    setcolor();
                  }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
        </div>
      </Drawer>

      <Drawer
      className="mycmpny-drawer"
        anchor="right"
        open={openDrawer6}
        onClose={handleDrawerClose6}
        style={{ width: "800px" }}
      >
        {/* Drawer 6 content */}

        <div
          className="wizradparent1"
          style={{ padding: "40px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                 
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                    background:divColor,
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
              }}
            >
              <div
                className="headerimg"
                style={{
                  backgroundImage: `url(${wbackimg})`,
                  height: "146px",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "94%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  src={wcvector}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    padding: "22px",
                    borderRadius: "50%",
                    background: "darkgray",
                  }}
                >
                  <img src={wclogo} />
                </div>
              </div>
              <div className="inputsection" style={{ marginTop: "60px" }}>
                <h5>Social Links</h5>
                <Form>
                 
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>LinkedIn</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="URL"
                      />
                    </Form.Group>

                 
                  </Row>
                
                 
               
                </Form>
              </div>
              
              
             
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleDrawerClose6}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen5}
              >
                Back
              </Button>

              <Button type="submit" variant="danger">
                {" "}
                Save&Exit
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                    handleDrawerOpen7();
                    setcolor();
                  }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
        </div>
      </Drawer>

     

      <Drawer
        anchor="right"
        open={openDrawer7}
        onClose={handleDrawerClose7}
        style={{ width: "800px" }}
      >
        {/* Drawer 7 content */}

        <div
          className="wizradparent1"
          style={{ padding: "40px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div
              className="col-md-3"
              style={{
                padding: "18px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                className="r1c1cld1 circle1"
                style={{ marginLeft: "30px", marginTop: "11px" }}
              >
                <div
                  className=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    zIndex: "2",
                   
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>

                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: "ghostwhite",
                    position: "relative",
                    marginTop: "25px",
                    zIndex: "2",
                    background:divColor,
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </div>
              </div>

              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic information</li>
                  <li>Account</li>
                  <li>Designation</li>
                  <li>Experience & Educaiton</li>
                  <li>Kin information</li>
                  <li>Social Links</li>
                  <li>Other</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
              }}
            >
              <div
                className="headerimg"
                style={{
                  backgroundImage: `url(${wbackimg})`,
                  height: "146px",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "94%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  src={wcvector}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    padding: "22px",
                    borderRadius: "50%",
                    background: "darkgray",
                  }}
                >
                  <img src={wclogo} />
                </div>
              </div>
              <div className="inputsection" style={{ marginTop: "60px" }}>
                <h5>Others</h5>
                <Form>
                 
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Blood Group</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Blood Group"
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Medical History</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Any Issue"
                      />
                    </Form.Group>

                 
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Meal Allownace</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Allownace"
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Home Allowance</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Any Issue"
                      />
                    </Form.Group>

                 
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Travel Allownace</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Allownace"
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Other Allowance</Form.Label>
                      <Form.Control
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Any Issue"
                      />
                    </Form.Group>

                 
                  </Row>
                
                 
               
                </Form>
              </div>
              
              
             
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={handleDrawerClose7}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen6}
              >
                Back
              </Button>

              <Button type="submit" variant="danger">
                {" "}
                Save&Exit
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Ewizard;
