import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import JoditEditor from "jodit-react";
import { useData } from "../ContextProvider/Context";
import annicon from "../Images/annicon.png";
import editicon from "../Images/editicon.png";
import deleteicon from "../Images/deleteicon.png";
import { IoIosSearch } from "react-icons/io";
import { Drawer } from "@mui/material";
import darrow from "../Images/drawerarrow.png";
import { CiRead } from "react-icons/ci";
import "@fontsource/nunito";
function AnnouncementCreate() {
  const editorRef = useRef(null);
  const [openDrawer, setopenDrawer] = useState(false);
  const handleDrawerClose = () => {
    setopenDrawer(false);
    setdata({
      title: "",
      announcement_date: new Date().toISOString().split('T')[0],
      content: "",
    })
  }
  const handleDraweropen = () => {
    setopenDrawer(true);
  }
  const [openDrawer1, setopenDrawer1] = useState(false);
  const handleDrawerClose1 = () => {
    setopenDrawer1(false);
  };
  const handleDraweropen1 = () => {
    setopenDrawer1(true);
  };
  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);
  const { base_url, setSnackbarValue, handleClick } = useData();
  const [data, setdata] = useState({
    title: "",
    announcement_date: new Date().toISOString().split('T')[0],
    content: "",
  });
  const [announcement, setAnnouncement] = useState([]);
  useEffect(() => {
    getAnnouncement();
  }, []);
  const getAnnouncement = () => {
    fetch(`${base_url}company-announcements`)
      .then((res) => res.json())
      .then((data) => {
        setAnnouncement(data);
      });
  };
  const [currentDate, setCurrentDate] = useState(new Date());


  const formattedDate = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const handleContentChange = (e) => {
    setdata({ ...data, content: e });
  };


  // const debouncedHandleContentChange = useRef(
  //   debounce((e) => {
  //     setdata({ ...data, content: e });
  //   }, 3000)
  // ).current;

  // // ...

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setdata({ ...data, content: editorRef.current?.value });
  //   }, 2000); // Adjust the delay as needed

  //   return () => clearTimeout(timeoutId);
  // }, [data.content, editorRef.current?.value]);

  const updateannouncement = (obj) => {
    fetch(`${base_url}company-announcements/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getAnnouncement();
        handleClick();
        setSnackbarValue({
          message: "Announcement update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const postannouncement = (obj) => {
    fetch(`${base_url}company-announcements`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getAnnouncement();
        handleClick();
        setSnackbarValue({
          message: "Announcement Create successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteannouncement = (id) => {
    fetch(`${base_url}company-announcement/${id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        getAnnouncement();
        handleClick();
        setSnackbarValue({ message: "Announcement Deleted", color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentAnnoucement = announcement?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginationControls = [];
  for (let i = 1; i <= Math.ceil(announcement?.length / itemsPerPage); i++) {
    paginationControls.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => setCurrentPage(i)}
      >
        {i}
      </button>
    );
  }
  return (
    <div>
      <div>
        <div
          classname="row "
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <div>
            <nav>
              <ul>
                <li
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Company Assets
                </li>
                <li>{formattedDate}</li>
              </ul>
            </nav>
          </div>
          <div className="d-flex justify-content-end mb-2 gap-2">
            <div>
              <button
                onClick={handleDraweropen}
                className="btn btn-primary"
                style={{
                  padding: "8px 16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img alt='' src={annicon} />
                <span> Create Announcement</span>
              </button>
            </div>
          </div>
        </div>


      </div>
      <div className="card mt-2">
        <div className="card-body">
          <div
            className="headtable"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <h5>All Announcement</h5>

            <div style={{ width: "25%", position: "relative" }}>
              <IoIosSearch
                style={{
                  position: "absolute",
                  left: "10",
                  top: "8px",
                  fontSize: "20px",
                }}
              />

              <input
                style={{ borderRadius: "10px", paddingLeft: "43px" }}
                type="text"
                className="form-control"
                placeholder="Search by Title"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
          <table className="table">
            <thead style={{ background: "ghostwhite" }}>
              <tr>

                <th scope="col">Ann ID</th>
                <th scope="col">Date</th>
                <th scope="col">Announcement Title</th>
                <th scope="col">Description</th>
                <th scope="col">View</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentAnnoucement?.map((item, idx) => {
                return (
                  <tr>
                    {/* <th scope="row">{count}</th> */}

                    <td>{item.id}</td>

                    <td>{item.announcement_date}</td>
                    <td>

                      <div>
                        <Link
                          className="link linkk"
                          to={`/admin/announcement/${item.id}`}
                        >
                          {item.title}
                        </Link>
                      </div>

                    </td>

                    <td
                      scope="col"
                      dangerouslySetInnerHTML={{
                        __html: item.content?.slice(0, 20),
                      }}
                    />


                    <td scope="col">
                      <button
                        className=""
                        style={{ background: "transparent", border: "none" }}
                        onClick={() => {
                          handleDraweropen1();
                          setdata(item);
                        }}
                      >
                        <CiRead style={{ fontSize: "20px" }} />
                      </button>
                    </td>
                    <td >
                      <button
                        style={{ background: "transparent", border: "none" }}
                        className=""
                        onClick={() => {
                          setdata(item);
                          handleDraweropen();
                        }}
                      >
                        <img alt='' src={editicon} />

                      </button>
                      <button
                        style={{ background: "transparent", border: "none" }}
                        className=""
                        onClick={() => deleteannouncement(item.id)}
                      >
                        <img alt='' src={deleteicon} />

                      </button>


                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination-controls">{paginationControls}</div>
        </div>
      </div>
      <Drawer
        className="mypolicy-drawer"
        anchor="right"
        open={openDrawer}
        onClose={handleDrawerClose}
      >
        <div style={{ position: "relative" }}>
          <button onClick={handleDrawerClose} style={{ position: "absolute", bottom: "4px", left: "2px", background: "none", border: "none" }}>
            <img alt='' src={darrow} />
          </button>
        </div>
        <div style={{ marginBottom: "10px" }}><h5>{data?.id ? "Edit Announcement" : "Create Announcement"}</h5></div>
        <div className='card p-3 scrollBar' style={{ minHeight: "80vh", overflowY: "scroll" }}>
          <div className='row'>
            <div className="col-md-6 mb-3">
              <div >
                <label style={{ fontSize: "17px", fontWeight: "bold" }}>
                  Announcement Title
                </label>
                <input
                  onChange={(e) => {
                    setdata({ ...data, title: e.target.value });
                  }}
                  className='form-control'
                  placeholder="Name"
                  value={data?.title}
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div >
                <label style={{ fontSize: "17px", fontWeight: "bold" }}>
                  Date
                </label>
                <input
                  className='form-control'
                  value={data?.announcement_date}
                  placeholder="Link"
                  min={new Date().toISOString().split('T')[0]}
                  type="date"
                  onChange={(e) => {
                    setdata({ ...data, announcement_date: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className='col-12'>
              <JoditEditor
                ref={editorRef}
                value={data?.content}
                onBlur={(e) => handleContentChange(e)}
              />
            </div>
            <div className="row mt-4">
              <div className="modalBtns d-flex justify-content-end gap-2">
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <button
                    onClick={() => {
                      data?.id ? updateannouncement(data) : postannouncement(data);
                      handleDrawerClose();
                    }}
                    className="btn btn-primary"
                  >
                    {data?.id ? "Update" : "Submit"}
                  </button>
                </div>
                <button className="btn btn-secondary" onClick={handleDrawerClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        className="mypolicy-drawer"
        anchor="right"
        open={openDrawer1}
        onClose={handleDrawerClose1}

      >
        <div style={{ position: "relative" }}>
          <button onClick={handleDrawerClose1} style={{ position: "absolute", bottom: "4px", left: "2px", background: "none", border: "none" }}>
            <img alt='' src={darrow} />
          </button>
        </div>
        <div style={{
          marginBottom: "10px", fontSize: "24px",
          fontWeight: "700",
          fontStyle: "normal",
          fontFamily: "nunito",
          color: "#667085",
        }}><h4> Announcement</h4></div>
        <div className="card scrollBar" style={{
          padding: "15px", minHeight: "80vh", overflowY: "scroll", backgroundImage: "url(https://static.vecteezy.com/system/resources/previews/002/799/316/original/announce-advertisement-poster-background-illustration-free-vector.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <p style={{ fontSize: "40px", fontWeight: "bold" }}>{data?.title}</p>
            </div>

          </div>
          <div className="card-body myjodit" style={{ padding: "0px" }}>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                fontStyle: "normal",
                fontFamily: "Nunito",
                marginBottom: "0",
                PointerEvent: "none",
                UserActivation: "none",
              }}
              dangerouslySetInnerHTML={{ __html: data?.content }}
            ></p>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default AnnouncementCreate;
