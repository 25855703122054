import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react'
import { CiEdit } from 'react-icons/ci'
import { FaPlus } from 'react-icons/fa'
import { RxCross2 } from 'react-icons/rx';
import { Link, useParams } from 'react-router-dom'
import { useData } from '../ContextProvider/Context';

function CompanyAddPage() {
  const params = useParams()
  const { getCompanyProfile, accountsDataC, getAccountsDataC, companyProfile, companyIdData, img_url, updateCompanyIdData, editCompany, storeCustomSettings, settingDat, getDat } = useData()
  useEffect(() => {
    getCompanyProfile(params?.id)
    getDat()
    getAccountsDataC()
  }, [])
  useEffect(() => {
    setFormData(companyProfile?.data)
  }, [companyProfile])
  useEffect(() => {
    setSettingData({
      invoice_term: settingDat?.data?.invoice_term,
      invoice_method: settingDat?.data?.invoice_method,
      invoice_shipping: settingDat?.data?.invoice_shipping === 1,
      service_date: settingDat?.data?.service_date === 1,
      invoice_discount: settingDat?.data?.invoice_discount === 1,
      invoice_deposit: settingDat?.data?.invoice_deposit === 1,
      invoice_tag: settingDat?.data?.invoice_tag === 1,
      payment_message: settingDat?.data?.payment_message,
      sku_coulumn: settingDat?.data?.sku_coulumn === 1,
      price_rate: settingDat?.data?.price_rate === 1,
      quantity: settingDat?.data?.quantity === 1,
      single_estimate: settingDat?.data?.single_estimate === 1,
      first_financial_year: settingDat?.data?.first_financial_year,
      first_financial_month: settingDat?.data?.first_financial_month,
      account_method: settingDat?.data?.account_method,
      closing_date: settingDat?.data?.closing_date,
      closing_condition: settingDat?.data?.closing_condition,
      tax_form: settingDat?.data?.tax_form,
      tax_form_comp: settingDat?.data?.tax_form_comp,
      isClosingDate: settingDat?.data?.isClosingDate === 1,
      account_company_type: settingDat?.data?.account_company_type,
      account_shipping_account: settingDat?.data?.account_shipping_account,
      account_discount_account: settingDat?.data?.account_discount_account,
      account_expense_income: settingDat?.data?.account_expense_income,
      account_number_show: settingDat?.data?.account_number_show === 1,
      track_classes: settingDat?.data?.track_classes === 1,
      track_location: settingDat?.data?.track_location === 1,
      account_language: settingDat?.data?.account_language,
      account_currency: settingDat?.data?.account_currency,
    });
  }, [settingDat]);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    vat_rate: '',
    business_id: '',
    company_legal_name: '',
    logo: null,
    timings: [],
    company_customer_email: '',
    company_customer_address: '',
    company_legal_address: '',
    website: '',
    tax_form: '',
    industry: '',
  });

  const [settingData, setSettingData] = useState({
    invoice_term: '',
    invoice_method: '',
    invoice_shipping: false,
    invoice_transaction_no: false,
    service_date: false,
    invoice_discount: false,
    invoice_deposit: false,
    invoice_tag: false,
    payment_message: '',
    sku_coulumn: false,
    price_rate: false,
    quantity: false,
    single_estimate: false,
    first_financial_year: '',
    first_financial_month: '',
    account_method: '',
    closing_date: '',
    closing_condition: '',
    tax_form_comp: '',
    isClosingDate: false,
    account_company_type: '',
    account_shipping_account: false,
    account_discount_account: '',
    account_expense_income: '',
    account_number_show: false,
    account_language: '',
    account_currency: '',
    track_classes: false,
    track_location: false,
  })
  const [showRow1, setShowRow1] = useState(true);
  const [showRow2, setShowRow2] = useState(false);
  const [showRow3, setShowRow3] = useState(true);
  const [showRow4, setShowRow4] = useState(false);
  const [showRow5, setShowRow5] = useState(true);
  const [showRow6, setShowRow6] = useState(false);
  const [showRow7, setShowRow7] = useState(true);
  const [showRow8, setShowRow8] = useState(false);
  const [showRow9, setShowRow9] = useState(true);
  const [showRow10, setShowRow10] = useState(false);
  const [showRow11, setShowRow11] = useState(true);
  const [showRow12, setShowRow12] = useState(false);
  const [showRow13, setShowRow13] = useState(true);
  const [showRow14, setShowRow14] = useState(false);
  const [showRow15, setShowRow15] = useState(true);
  const [showRow16, setShowRow16] = useState(false);
  const [showRow17, setShowRow17] = useState(true);
  const [showRow18, setShowRow18] = useState(false);
  const [showRow19, setShowRow19] = useState(true);
  const [showRow20, setShowRow20] = useState(false);
  const [showRow21, setShowRow21] = useState(true);
  const [showRow22, setShowRow22] = useState(false);
  const [showRow23, setShowRow23] = useState(true);
  const [showRow24, setShowRow24] = useState(false);
  const [showRow25, setShowRow25] = useState(true);
  const [showRow26, setShowRow26] = useState(false);
  const [showRow27, setShowRow27] = useState(true);
  const [showRow28, setShowRow28] = useState(false);
  const [showRowAdvance, setShowAdvanceRow] = useState(true);
  const [showRowAdvanceEdit, setShowAdvanceEditRow] = useState(false);
  const [showRowAdvanceType, setShowAdvanceRowType] = useState(true);
  const [showRowAdvanceTypeEdit, setShowAdvanceTypeEditRow] = useState(false);
  const [showRowCharts, setShowCharts] = useState(true);
  const [showRowChartsEdit, setShowRowChartsEdit] = useState(false);
  const [showRowCates, setShowCates] = useState(true);
  const [showRowCatesEdit, setShowRowCatesEdit] = useState(false);
  const [showRowAtom, setShowAtom] = useState(true);
  const [showRowAtomsEdit, setShowRowAtomsEdit] = useState(false);
  const [showRowProject, setShowProject] = useState(true);
  const [showRowProjectsEdit, setShowRowProjectsEdit] = useState(false);
  const [showRowLang, setShowLang] = useState(true);
  const [showRowLangEdit, setShowRowLangEdit] = useState(false);
  const [showRowCurrency, setRowCurrency] = useState(true);
  const [showRowCurrencyEdit, setRowCurrencyEdit] = useState(false);
  const [showRowOther, setRowOther] = useState(true);
  const [showRowOtherEdit, setRowOtherEdit] = useState(false);

  const handleEditClick = () => {
    setShowRow1(!showRow1);
    setShowRow2(!showRow2);
  };
  const handleEditClick1 = () => {
    setShowRow3(!showRow3);
    setShowRow4(!showRow4);
  };
  const handleEditClick2 = () => {
    setShowRow5(!showRow5);
    setShowRow6(!showRow6);
  };
  const handleEditClick3 = () => {
    setShowRow7(!showRow7);
    setShowRow8(!showRow8);
  };
  const handleCancelClick = () => {
    setShowRow1(true);
    setShowRow2(false);
    setSelectedImage(null);
    setFormData({
      ...formData,
      logo: null,
    });
  };
  const handleCancelClick1 = () => {
    setShowRow3(true);
    setShowRow4(false);
  };
  const handleCancelClick2 = () => {
    setShowRow5(true);
    setShowRow6(false);
  };
  const handleCancelClick3 = () => {
    setShowRow7(true);
    setShowRow8(false);
  };
  const handleEditClick4 = () => {
    setShowRow9(!showRow9);
    setShowRow10(!showRow10);
  };
  const handleCancelClick4 = () => {
    setShowRow9(true);
    setShowRow10(false);
  };
  const handleEditClick5 = () => {
    setShowRow11(!showRow11);
    setShowRow12(!showRow12);
  };
  const handleCancelClick5 = () => {
    setShowRow11(true);
    setShowRow12(false);
  };
  const handleEditClick6 = () => {
    setShowRow13(!showRow13);
    setShowRow14(!showRow14);
  };
  const handleCancelClick6 = () => {
    setShowRow13(true);
    setShowRow14(false);
  };
  const handleEditClick7 = () => {
    setShowRow15(!showRow15);
    setShowRow16(!showRow16);
  };
  const handleCancelClick7 = () => {
    setShowRow15(true);
    setShowRow16(false);
  };
  const handleEditClick8 = () => {
    setShowRow17(!showRow17);
    setShowRow18(!showRow18);
  };
  const handleCancelClick8 = () => {
    setShowRow17(true);
    setShowRow18(false);
  };
  const handleEditClick9 = () => {
    setShowRow19(!showRow19);
    setShowRow20(!showRow20);
  };
  const handleCancelClick9 = () => {
    setShowRow19(true);
    setShowRow20(false);
  };
  const handleEditClick10 = () => {
    setShowRow21(!showRow21);
    setShowRow22(!showRow22);
  };
  const handleCancelClick10 = () => {
    setShowRow21(true);
    setShowRow22(false);
  };
  const handleEditClick11 = () => {
    setShowRow23(!showRow23);
    setShowRow24(!showRow24);
  };
  const handleCancelClick11 = () => {
    setShowRow23(true);
    setShowRow24(false);
  };
  const handleEditClick12 = () => {
    setShowRow25(!showRow25);
    setShowRow26(!showRow26);
  };
  const handleCancelClick12 = () => {
    setShowRow25(true);
    setShowRow26(false);
  };
  const handleEditClick13 = () => {
    setShowRow27(!showRow27);
    setShowRow28(!showRow28);
  };
  const handleCancelClick13 = () => {
    setShowRow27(true);
    setShowRow28(false);
  };
  const handleEditClickAdvance1 = () => {
    setShowAdvanceRow(!showRowAdvance);
    setShowAdvanceEditRow(!showRowAdvanceEdit);
  };
  const handleCancelClickAvance1 = () => {
    setShowAdvanceRow(true);
    setShowAdvanceEditRow(false);
  };
  const handleEditClickCompanyType = () => {
    setShowAdvanceRowType(!showRowAdvanceType);
    setShowAdvanceTypeEditRow(!showRowAdvanceTypeEdit);
  };
  const handleCancelClickComTpe = () => {
    setShowAdvanceRowType(true);
    setShowAdvanceTypeEditRow(false);
  };
  const handleEditClickChart = () => {
    setShowCharts(!showRowCharts);
    setShowRowChartsEdit(!showRowChartsEdit);
  };
  const handleCancelClickChart = () => {
    setShowCharts(true);
    setShowRowChartsEdit(false);
  };
  const handleEditClickCates = () => {
    setShowCates(!showRowCates);
    setShowRowCatesEdit(!showRowCatesEdit);
  };
  const handleCancelClickCates = () => {
    setShowCates(true);
    setShowRowCatesEdit(false);
  };
  const handleEditClickAtom = () => {
    setShowAtom(!showRowAtom);
    setShowRowAtomsEdit(!showRowAtomsEdit);
  };
  const handleCancelClickAtom = () => {
    setShowAtom(true);
    setShowRowAtomsEdit(false);
  };
  const handleEditClickProject = () => {
    setShowProject(!showRowProject);
    setShowRowProjectsEdit(!showRowProjectsEdit);
  };
  const handleCancelClickProject = () => {
    setShowProject(true);
    setShowRowProjectsEdit(false);
  };
  const handleEditClickLang = () => {
    setShowLang(!showRowLang);
    setShowRowLangEdit(!showRowLangEdit);
  };
  const handleCancelClickLang = () => {
    setShowLang(true);
    setShowRowLangEdit(false);
  };
  const handleEditClickCurrency = () => {
    setRowCurrency(!showRowCurrency);
    setRowCurrencyEdit(!showRowCurrencyEdit);
  };
  const handleCancelClickCurrency = () => {
    setRowCurrency(true);
    setRowCurrencyEdit(false);
  };
  const handleEditClickOther = () => {
    setRowOther(!showRowOther);
    setRowOtherEdit(!showRowOtherEdit);
  };
  const handleCancelClickOther = () => {
    setRowOther(true);
    setRowOtherEdit(false);
  };
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setInputValue(event.target.value);
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Image = e.target.result;
      const parts = base64Image
      setSelectedImage(base64Image);

      setFormData({
        ...formData,
        logo: parts,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSaveFunc = () => {
    updateCompanyIdData(formData, params?.id);
  }
  const handleInputChange = (event, idx, key) => {
    const newTimings = [...formData.timings];
    newTimings[idx][key] = event.target.value;

    const startMinutes = parseInt(newTimings[idx].startTime.split(':')[0]) * 60 + parseInt(newTimings[idx].startTime.split(':')[1]);
    const endMinutes = parseInt(newTimings[idx].endTime.split(':')[0]) * 60 + parseInt(newTimings[idx].endTime.split(':')[1]);
    const totalMinutes = endMinutes - startMinutes;
    const hours = Math.floor(totalMinutes / 60);
    newTimings[idx].totalTime = parseInt(hours);

    setFormData({ ...formData, timings: newTimings });
  };
  useEffect(() => {
    if (companyIdData && companyIdData.timing) {
      const { timing } = companyIdData;
      setFormData((prevFormData) => ({
        ...prevFormData,
        timings: timing?.map((t) => ({
          day: t.day,
          startTime: t.startTime,
          endTime: t.endTime,
          totalTime: t.totalTime,
        })),
      }));
    }
  }, [companyIdData]);

  const [isSameAsCompanyName, setIsSameAsCompanyName] = useState(true);
  const [isSameEmailCompanyEmail, setIsSameEmailCompanyEmail] = useState(true);
  const [isSameCustomerCompanyCustomer, setisSameCustomerCompanyCustomer] = useState(true);
  const [isSameCustomerCompanyLegal, setisSameCustomerCompanyLegal] = useState(true);
  useEffect(() => {
    if (isSameAsCompanyName) {
      setFormData((prevData) => ({ ...prevData, company_legal_name: formData?.name }));
    }
  }, [isSameAsCompanyName, formData?.name]);

  const handleCheckboxChange = () => {
    setIsSameAsCompanyName(!isSameAsCompanyName);
    if (!isSameAsCompanyName) {
      setFormData((prevData) => ({ ...prevData, company_legal_name: '' }));
    }
  };
  useEffect(() => {
    if (isSameEmailCompanyEmail) {
      setFormData((prevData) => ({ ...prevData, company_customer_email: formData?.email }));
    }
  }, [isSameEmailCompanyEmail, formData?.email]);

  const handleCheckboxChange1 = () => {
    setIsSameEmailCompanyEmail(!isSameEmailCompanyEmail);
    if (!isSameEmailCompanyEmail) {
      setFormData((prevData) => ({ ...prevData, company_customer_email: '' }));
    }
  };
  useEffect(() => {
    if (isSameCustomerCompanyCustomer) {
      setFormData((prevData) => ({ ...prevData, company_customer_address: formData?.address }));
    }
  }, [isSameCustomerCompanyCustomer, formData?.address]);

  const handleCheckboxChange3 = () => {
    setisSameCustomerCompanyCustomer(!isSameCustomerCompanyCustomer);
    if (!isSameCustomerCompanyCustomer) {
      setFormData((prevData) => ({ ...prevData, company_customer_address: '' }));
    }
  };
  useEffect(() => {
    if (isSameCustomerCompanyLegal) {
      setFormData((prevData) => ({ ...prevData, company_legal_address: formData?.address }));
    }
  }, [isSameCustomerCompanyLegal, formData?.address]);

  const handleCheckboxChange4 = () => {
    setisSameCustomerCompanyLegal(!isSameCustomerCompanyLegal);
    if (!isSameCustomerCompanyLegal) {
      setFormData((prevData) => ({ ...prevData, company_legal_address: '' }));
    }
  };
  const countriesWithCurrencies = [
    { country: 'Afghanistan', currency: 'Afghan Afghani-AFN' },
    { country: 'Argentina', currency: 'Argentine Peso-ARS' },
    { country: 'Australia', currency: 'Australian Dollar-AUD' },
    { country: 'Brazil', currency: 'Brazilian Real-BRL' },
    { country: 'Canada', currency: 'Canadian Dollar-CAD' },
    { country: 'China', currency: 'Chinese Yuan-CNY' },
    { country: 'Denmark', currency: 'Danish Krone-DKK' },
    { country: 'Egypt', currency: 'Egyptian Pound-EGP' },
    { country: 'France', currency: 'Euro-EUR' },
    { country: 'Germany', currency: 'Euro-EUR' },
    { country: 'India', currency: 'Indian Rupee-INR' },
    { country: 'Indonesia', currency: 'Indonesian Rupiah-IDR' },
    { country: 'Italy', currency: 'Euro-EUR' },
    { country: 'Japan', currency: 'Japanese Yen-JPY' },
    { country: 'Mexico', currency: 'Mexican Peso-MXN' },
    { country: 'Netherlands', currency: 'Euro-EUR' },
    { country: 'Nigeria', currency: 'Nigerian Naira-NGN' },
    { country: 'Pakistan', currency: 'Pakistani Rupee-PKR' },
    { country: 'Peru', currency: 'Peruvian Sol-PEN' },
    { country: 'Philippines', currency: 'Philippine Peso-PHP' },
    { country: 'Poland', currency: 'Polish Złoty-PLN' },
    { country: 'Russia', currency: 'Russian Ruble-RUB' },
    { country: 'Saudi Arabia', currency: 'Saudi Riyal-SAR' },
    { country: 'Singapore', currency: 'Singapore Dollar-SGD' },
    { country: 'South Africa', currency: 'South African Rand-ZAR' },
    { country: 'South Korea', currency: 'South Korean Won-KRW' },
    { country: 'Spain', currency: 'Euro-EUR' },
    { country: 'Sweden', currency: 'Swedish Krona-SEK' },
    { country: 'Switzerland', currency: 'Swiss Franc-CHF' },
    { country: 'Thailand', currency: 'Thai Baht-THB' },
    { country: 'Turkey', currency: 'Turkish Lira-TRY' },
    { country: 'Ukraine', currency: 'Ukrainian Hryvnia-UAH' },
    { country: 'United Arab Emirates', currency: 'UAE Dirham-AED' },
    { country: 'United Kingdom', currency: 'British Pound Sterling-GBP' },
    { country: 'United States', currency: 'United States Dollar-USD' },
    { country: 'Vietnam', currency: 'Vietnamese Đồng-VND' },
    { country: 'Algeria', currency: 'Algerian Dinar-DZD' },
    { country: 'Bangladesh', currency: 'Bangladeshi Taka-BDT' },
    { country: 'Chile', currency: 'Chilean Peso-CLP' },
    { country: 'Colombia', currency: 'Colombian Peso-COP' },
    { country: 'Czech Republic', currency: 'Czech Koruna-CZK' },
    { country: 'Finland', currency: 'Euro-EUR' },
    { country: 'Greece', currency: 'Euro-EUR' },
    { country: 'Hungary', currency: 'Hungarian Forint-HUF' },
    { country: 'Iraq', currency: 'Iraqi Dinar-IQD' },
    { country: 'Israel', currency: 'Israeli New Shekel-ILS' },
    { country: 'Kenya', currency: 'Kenyan Shilling-KES' },
    { country: 'Malaysia', currency: 'Malaysian Ringgit-MYR' },
    { country: 'Morocco', currency: 'Moroccan Dirham-MAD' },
    { country: 'New Zealand', currency: 'New Zealand Dollar-NZD' },
  ];
  const languages = [
    'English',
    'Spanish',
    'French',
    'German',
    'Mandarin Chinese',
    'Hindi',
    'Arabic',
    'Portuguese',
    'Bengali',
    'Russian',
    'Japanese',
  ]
  console.log(settingData)
  return (
    <>
      <div className='companyCreateData'>
        <div className='card' >
          <div className='card-body'>
            <div className="row">
              <div className="col-2">
                {/* Tab navs */}
                <div className="nav flex-column nav-pills text-center" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <Link className="nav-link active" id="v-pills-link1-tab" data-mdb-toggle="pill" to="#v-pills-link1" role="tab" aria-controls="v-pills-link1" aria-selected="true">Company</Link>
                  <Link className="nav-link" id="v-pills-link3-tab" data-mdb-toggle="pill" to="#v-pills-link2" role="tab" aria-controls="v-pills-link2" aria-selected="false">Sales</Link>
                  <Link className="nav-link" id="v-pills-link4-tab" data-mdb-toggle="pill" to="#v-pills-link4" role="tab" aria-controls="v-pills-link4" aria-selected="false">Expenses</Link>
                  <Link className="nav-link" id="v-pills-link5-tab" data-mdb-toggle="pill" to="#v-pills-link5" role="tab" aria-controls="v-pills-link5" aria-selected="false">Time</Link>
                  <Link className="nav-link" id="v-pills-link6-tab" data-mdb-toggle="pill" to="#v-pills-link6" role="tab" aria-controls="v-pills-link6" aria-selected="false">Advance</Link>
                </div>
                {/* Tab navs */}
              </div>
              <div className="col-10">
                {/* Tab content */}
                <div className="tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active" id="v-pills-link1" role="tabpanel" aria-labelledby="v-pills-link1-tab">
                    <div className='row'>
                      <table className="table table-stripped">
                        <tbody>
                          <tr id='row1' className={`tableHoverRow ${showRow1 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Company Name</th>
                            <td>
                              <div className='row'>
                                <div style={{ marginLeft: "1rem", marginBottom: "5px", height: "100px", width: "100px", border: "1px transparent" }}>
                                </div>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Name</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Legal Name</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Bussiness ID No.</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>VAT #</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div style={{ padding: "0", marginLeft: "1rem", marginBottom: "5px", height: "100px", width: "100px", border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <div style={{ height: "100px", width: "100px" }}>
                                    <img src={img_url + formData?.logo} width={"100%"} height={"100%"} alt='logo' />
                                  </div>
                                </div>
                                <div>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.name}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.company_legal_name}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.business_id}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.vat_rate}</h6>
                                </div>
                              </div>
                            </td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick} /></td>
                          </tr>
                          <tr id='row2' className={`tableHoverRow ${showRow2 ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                            <th scope="row">Company Name</th>
                            <td>
                              <div className='row'>
                                <div style={{ marginBottom: "5px", height: "100px" }}>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Logo</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Shown on Sales forms and purchase orders.</p>

                                </div>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Name</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Shown on Sales forms and purchase orders.</p><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Legal Name</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Shown on Sales forms and purchase orders.</p><br /><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Bussiness ID No.</h5><br /><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>VAT #</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div style={{ padding: "0", background: "#ddd", marginLeft: "1rem", marginBottom: "5px", height: "100px", width: "100px", border: "1px solid lightgray", display: "flex", justifyContent: "end", alignItems: "end" }}>
                                  <label htmlFor="filee" className='h-100'>
                                    {selectedImage ? (
                                      <img src={selectedImage ? selectedImage : img_url + formData?.logo} alt="Selected" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    ) : (
                                      <FaPlus />
                                    )}
                                  </label>
                                  <input type="file" id="filee" style={{ display: "none" }} onChange={handleFileChange} />
                                </div>
                              </div>
                              <div>
                                <div>
                                  <input
                                    className='form-control'
                                    value={formData?.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    type='text'
                                  />
                                </div>
                                <br />
                                <div>
                                  <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
                                    <input
                                      style={{ height: '20px', width: '20px' }}
                                      type='checkbox'
                                      checked={isSameAsCompanyName}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label>Same as company name</label>
                                  </div>
                                  <div>
                                    <input
                                      className='form-control'
                                      type='text'
                                      value={formData?.company_legal_name}
                                      onChange={(e) => setFormData({ ...formData, company_legal_name: e.target.value })}
                                      disabled={isSameAsCompanyName}
                                    />
                                  </div>
                                </div>
                                <br />
                                <br />
                                <div>
                                  <input
                                    className='form-control'
                                    type='number'
                                    placeholder='99-111-11'
                                    value={formData?.business_id}
                                    onChange={(e) => setFormData({ ...formData, business_id: e.target.value })}
                                  />
                                </div>
                                <br />
                                <div>
                                  <input
                                    className='form-control'
                                    type='text'
                                    value={formData?.vat_rate}
                                    onChange={(e) => setFormData({ ...formData, vat_rate: e.target.value })}
                                  />
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                  <button
                                    className='btn btn-mute'
                                    style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }}
                                    onClick={handleCancelClick}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className='btn btn-success'
                                    style={{ border: "1px solid lightgray", borderRadius: "30px" }}
                                    onClick={() => editCompany(formData)}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr id='typeRow' className={`tableHoverRow ${showRow3 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Company Type</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Tax Form</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Industry</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <h6 style={{ fontSize: "14px" }}>{formData?.tax_form}</h6>
                                <h6 style={{ fontSize: "14px" }}>{formData?.industry}</h6>
                              </div>
                            </td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick1} /></td>
                          </tr>
                          <tr id='typeRow2' className={`tableHoverRow ${showRow4 ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                            <th scope="row">Company Type</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Tax Form</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Industry</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <select className='form-select w-75 mb-3' value={formData?.tax_form} onChange={(e) => setFormData({ ...formData, tax_form: e.target.value })}>
                                    <option hidden>Select</option>
                                    <option value={'Sole properitor'}>Sole properitor</option>
                                    <option value={'Partnership or limited laibility company'}>Partnership or limited laibility company</option>
                                    <option value={'Small bussiness corporation, two or more owners'}>Small bussiness corporation, two or more owners</option>
                                    <option value={'Corporation, one or more shareholders'}>Corporation, one or more shareholders</option>
                                    <option value={'Nonprofit organization'}>Nonprofit organization</option>
                                    <option value={'Limited laibility'}>Limited laibility</option>
                                    <option value={'Not sure/Other/None'}>Not sure/Other/None</option>
                                  </select>
                                </div>
                                <div>
                                  <select className='form-select w-75 mb-3' value={formData?.industry} onChange={(e) => setFormData({ ...formData, industry: e.target.value })}>
                                    <option hidden>Select</option>
                                    <option value={'Accomodation and Food Services'}>Accomodation and Food Services</option>
                                    <option value={'Adminstrative and Support Services'}>Adminstrative and Support Services</option>
                                    <option value={'Arts and Recreation Services'}>Arts and Recreation Services</option>
                                    <option value={'Construction/Builder'}>Construction/Builder</option>
                                    <option value={'Education and Training'}>Education and Training</option>
                                    <option value={'Farming,forestry and fishing'}>Farming,forestry and fishing </option>
                                    <option value={'Financial services & insurance'}>Financial services & insurance</option>
                                    <option value={'Manufacturing'}>Manufacturing</option>
                                    <option value={'Medical/Health Care/Community services'}>Medical/Health Care/Community services</option>
                                    <option value={'Personal,Beauty,Wellbeing and other services'}>Personal,Beauty,Wellbeing and other services</option>
                                  </select>
                                </div>
                                <div>
                                  <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick1}>Cancel</button>
                                  <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => editCompany(formData)}> Save</button>
                                </div>
                              </div>
                            </td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow5 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Company Info</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Email</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Customer-facing emial</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Phone</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Website</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.email}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.company_customer_email}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.phone}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.website}</h6>
                                </div>
                              </div>
                            </td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick2} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow6 ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                            <th scope="row">Company Info</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Email</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Where Quick books can contact you</p><br /><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Customer-facing emial</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Where Quick books can contact you</p><br /><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Phone</h5>
                                  <p style={{ padding: "0", margin: "0" }}>SHown on Sales forms.</p><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Website</h5>
                                  <p style={{ padding: "0", margin: "0" }}>SHown on Sales forms.</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <div>
                                    <input value={formData?.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} type='email' className='form-control' />
                                  </div><br />
                                  <div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
                                      <input
                                        style={{ height: '20px', width: '20px' }}
                                        type='checkbox'
                                        checked={isSameEmailCompanyEmail}
                                        onChange={handleCheckboxChange1}
                                      />
                                      <label>Same as company email</label>
                                    </div>
                                    <div>
                                      <input
                                        className='form-control'
                                        type='text'
                                        value={formData?.company_customer_email}
                                        onChange={(e) => setFormData({ ...formData, company_customer_email: e.target.value })}
                                        disabled={isSameEmailCompanyEmail}
                                      />
                                    </div>
                                  </div>
                                  <br /><br />
                                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <input value={formData?.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} className='form-control' type='number' />
                                  </div><br /><br />
                                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <input className='form-control' type='text' value={formData?.website}
                                      onChange={(e) => setFormData({ ...formData, website: e.target.value })} />
                                  </div><br /><br />
                                </div>
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick2}>Cancel</button>
                                <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => editCompany(formData)} > Save</button>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow7 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Address</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Address</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Customer-facing address</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Legal Address</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.address}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.company_customer_address}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.company_legal_address}</h6>
                                </div>
                              </div>
                            </td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick3} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow8 ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                            <th scope="row">Address</th>
                            <td style={{ width: "600px" }}>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Address</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Address where your company is bassed.</p><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Customer-facing address</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Address where customers contact you or send payments. Shown on sales forms.</p>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Legal Address</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Used for filling taxes.</p>
                                </div>
                              </div>
                            </td>
                            <td style={{ width: "500px" }}>
                              <div className='row'>
                                <div>
                                  <div>
                                    <input value={formData?.address} type='text' className='form-control mb-2' onChange={(e) => setFormData({ ...formData, address: e.target.value })} placeholder='Address' />
                                  </div><br />
                                  <div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
                                      <input
                                        style={{ height: '20px', width: '20px' }}
                                        type='checkbox'
                                        checked={isSameCustomerCompanyCustomer}
                                        onChange={handleCheckboxChange3}
                                      />
                                      <label>Same as company address</label>
                                    </div>
                                    <div>
                                      <input
                                        className='form-control'
                                        type='text'
                                        value={formData?.company_customer_address}
                                        onChange={(e) => setFormData({ ...formData, company_customer_address: e.target.value })}
                                        disabled={isSameCustomerCompanyCustomer}
                                      />
                                    </div>
                                  </div><br /><br />
                                  <div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
                                      <input
                                        style={{ height: '20px', width: '20px' }}
                                        type='checkbox'
                                        checked={isSameCustomerCompanyLegal}
                                        onChange={handleCheckboxChange4}
                                      />
                                      <label>Same as company address</label>
                                    </div>
                                    <div>
                                      <input
                                        className='form-control'
                                        type='text'
                                        value={formData?.company_legal_address} onChange={(e) => setFormData({ ...formData, company_legal_address: e.target.value })}
                                        disabled={isSameCustomerCompanyLegal}
                                      />
                                    </div><br /><br />
                                  </div>
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                  <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick3}>Cancel</button>
                                  <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => editCompany(formData)}> Save</button>
                                </div>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr className='tableHoverRow' style={{ borderRadius: "10px" }}>
                            <th scope="row">Communications with Intiut</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>
                                    <Link>
                                      View Privacy Statement
                                    </Link>
                                  </h5>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-link2" role="tabpanel" aria-labelledby="v-pills-link2-tab">
                    <div className='row'>
                      <table className="table table-stripped">
                        <tbody>
                          <tr className={`tableHoverRow ${showRow9 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Sales form content</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Preferred invoice terms</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Preferred  delivery method</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Shipping</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Custom fields</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Service date</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Discount</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Deposit</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Tags</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px" }}>{settingData?.invoice_term}</p>
                                  <p style={{ fontSize: "14px" }}>{settingData?.invoice_method}</p>
                                  <p style={{ fontSize: "14px" }}>{settingData?.invoice_shipping === false ? 'Off' : 'On'}</p>
                                  <p style={{ fontSize: "14px", color: "transparent" }}>p</p>
                                  <p style={{ fontSize: "14px" }}>{settingData?.service_date === false ? 'Off' : 'On'}</p>
                                  <p style={{ fontSize: "14px" }}>{settingData?.invoice_discount === false ? 'Off' : 'On'}</p>
                                  <p style={{ fontSize: "14px" }}>{settingData?.invoice_deposit === false ? 'Off' : 'On'}</p>
                                  <p style={{ fontSize: "14px" }}>{settingData?.invoice_tag === false ? 'Off' : 'On'}</p>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick4} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow10 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Sales form content</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600", paddingTop: "8px" }}>Preferred invoice terms</p><br />
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Preferred  delivery method</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Shipping</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Custom fields</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Service data </p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Discount</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Diposit</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Tags</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <div class="btn-group " style={{ border: "1px solid" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <select className='form-select' value={settingData?.invoice_term} onChange={(e) => setSettingData({ ...settingData, invoice_term: e.target.value })}>
                                        <option hidden>Select</option>
                                        <option value={'Due on receipt'}>Due on receipt</option>
                                        <option value={'Net 3 days'}>Net 3 days</option>
                                        <option value={'Net 7 days'}>Net 7 days</option>
                                        <option value={'Net 10 days'}>Net 10 days</option>
                                        <option value={'Net 15 days'}>Net 15 days</option>
                                        <option value={'Net 30 days'}>Net 30 days</option>
                                      </select>
                                    </div>
                                  </div><br /><br />
                                  <div>
                                    <select className='form-select' value={settingData?.invoice_method} onChange={(e) => setSettingData({ ...settingData, invoice_method: e.target.value })}>
                                      <option hidden>Select</option>
                                      <option value={'Print Later'}>Print Later</option>
                                      <option value={'Send Later'}>Send Later</option>
                                      <option value={'None'}>None</option>
                                    </select>
                                  </div><br />
                                  <div>
                                    <label className="switch">
                                      <input type="checkbox" checked={settingData?.invoice_shipping === true} onChange={(e) => setSettingData({ ...settingData, invoice_shipping: e.target.checked })} />
                                      <span className="slider round" />
                                    </label>
                                  </div><br /><br />
                                  <div style={{ paddingTop: '16px' }}>
                                    <label className="switch">
                                      <input type="checkbox" checked={settingData?.service_date === true} onChange={(e) => setSettingData({ ...settingData, service_date: e.target.checked })} />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                  <div style={{ paddingTop: '18px' }}>
                                    <label className="switch">
                                      <input type="checkbox" checked={settingData?.invoice_discount === true} onChange={(e) => setSettingData({ ...settingData, invoice_discount: e.target.checked })} />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                  <div style={{ paddingTop: '20px' }}>
                                    <label className="switch">
                                      <input type="checkbox" checked={settingData?.invoice_deposit === true} onChange={(e) => setSettingData({ ...settingData, invoice_deposit: e.target.checked })} />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                  <div style={{ paddingTop: '20px' }}>
                                    <label className="switch">
                                      <input type="checkbox" checked={settingData?.invoice_tag === true} onChange={(e) => setSettingData({ ...settingData, invoice_tag: e.target.checked })} />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick4}>Cancel</button>
                                <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => storeCustomSettings(settingData)}> Save</button>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow11 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Invoice Payments</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h6>Payment Instructions </h6>
                                  <p>{settingData?.payment_message}</p>
                                </div>
                              </div>
                            </td>
                            <td>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick5} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow12 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Invoice Payments</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Payment Instructions</p>
                                  <p style={{ fontSize: "14px" }}>set the payment instuctions for all new invoices</p>
                                  <p className='mb-0' style={{ fontSize: '12px' }}>Payment Instuction</p>
                                  <TextArea
                                    value={settingData?.payment_message} onChange={(e) => setSettingData({ ...settingData, payment_message: e.target.value })}
                                    style={{ height: '100px' }}
                                  />
                                  <div className='table-btns mt-2 d-flex gap-3'>
                                    <div style={{ borderRadius: '30px' }} className='btn btn-secondary' onClick={handleCancelClick5}>Cancel</div>
                                    <div style={{ borderRadius: '30px' }} className='btn btn-success' onClick={() => storeCustomSettings(settingData)}>Save</div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow13 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Product and Services</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Show Product/Services column sales forms</p>
                                  <p style={{ fontSize: "14px" }}>Show SKU column</p>
                                  <p style={{ fontSize: "14px" }}>Track quantity and price/rate</p>
                                  <p style={{ fontSize: "14px" }}>Track invrntor  quantity on hand</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p style={{ fontSize: "14px", fontWeight: "600", color: "transparent" }}>p</p>
                              <p style={{ fontSize: "14px" }}>{settingData?.sku_coulumn === false ? 'Off' : 'On'}</p>
                              <p style={{ fontSize: "14px" }}>{settingData?.price_rate === false ? 'Off' : 'On'}</p>
                              <p style={{ fontSize: "14px" }}>{settingData?.quantity === false ? 'Off' : 'On'}</p>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick6} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow14 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Product and services</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Show Product/Services column sales forms</p>
                                  <p style={{ fontSize: "14px" }}>Show SKU column</p>
                                  <p style={{ fontSize: "14px" }}>Track quantity and price/rate</p>
                                  <p style={{ fontSize: "14px" }}>Track invrntor  quantity on hand</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <br /><br />
                              <div className='row'>
                                <div>
                                  <label className="switch">
                                    <input type="checkbox" checked={settingData?.sku_coulumn === true} onChange={(e) => setSettingData({ ...settingData, sku_coulumn: e.target.checked })} />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div style={{ paddingTop: '18px' }}>
                                  <label className="switch">
                                    <input type="checkbox" checked={settingData?.price_rate === true} onChange={(e) => setSettingData({ ...settingData, price_rate: e.target.checked })} />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div style={{ paddingTop: '20px' }}>
                                  <label className="switch">
                                    <input type="checkbox" checked={settingData?.quantity === true} onChange={(e) => setSettingData({ ...settingData, quantity: e.target.checked })} />
                                    <span className="slider round" />
                                  </label>
                                </div>
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick6}>Cancel</button>
                                <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => storeCustomSettings(settingData)}> Save</button>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow15 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Processing Invocing</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", }}>Create multiple partial invoice from a single estimate</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p style={{ fontSize: "14px", }}>{settingData?.single_estimate === false ? 'Off' : 'On'}</p>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick7} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow16 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Processing Invocing</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", }}>Create multiple partial invoice from a single estimate</p>
                                </div>
                              </div>
                              <div className='table-btns mt-2 d-flex gap-3'>
                                <div style={{ borderRadius: '30px' }} className='btn btn-secondary' onClick={handleCancelClick7}>Cancel</div>
                                <div style={{ borderRadius: '30px' }} className='btn btn-success' onClick={() => storeCustomSettings(settingData)}>Save</div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <div>
                                    <label className="switch">
                                      <input type="checkbox" checked={settingData?.single_estimate === true} onChange={(e) => setSettingData({ ...settingData, single_estimate: e.target.checked })} />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick7} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow17 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Messages</p>
                            </th>
                            <td>
                              <p> Default email messages sent with sales forms </p>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick8} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow18 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Messages</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Default email message sent with sales forms</p>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='d-flex gap-4 align-items-baseline'>
                                  <div className="form-check d-flex">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                      Use Greeting
                                    </label>
                                  </div>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option selected>Dear</option>
                                    <option value={1}>To:</option>
                                    <option value={2}>Blank</option>
                                    <option value={3}>Three</option>
                                  </select>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option selected>[Full Name]</option>
                                    <option value={1}>[Company][Name]</option>
                                    <option value={2}>[Title][Last]</option>
                                    <option value={3}>[First][Last]</option>
                                    <option value={4}>[Display][Name]</option>
                                  </select>
                                </div>
                                <div className='d-flex gap-2 mt-3'>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option selected>Invoice</option>
                                    <option value={1}>Estimate</option>
                                    <option value={2}>Dredit Name</option>
                                    <option value={3}>Statement</option>
                                    <option value={4}>Sales receipt</option>
                                    <option value={5}>Refund receipt</option>
                                  </select>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-success'>Use Standard Message</div>
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontSize: '14px', fontWeight: '600' }}>Email Subject Line</p>
                                  <input
                                    className='form-control'
                                    type='text'
                                    style={{ fontSize: '14px', width: '50%', borderRadius: '5px' }}
                                  />
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontSize: '14px', fontWeight: '600' }}>Email Message</p>
                                  <textarea style={{ width: '50%', borderRadius: '5px' }} />
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                      Email me a copy at
                                    </label>
                                  </div>
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontWeight: '600', fontSize: '14px' }}>Copy (Cc) new invoices to address</p>
                                  <input
                                    className='form-control'
                                    type='text'
                                    placeholder='Seperate multiple emails with the comma'
                                    style={{ fontSize: '14px', width: '50%', borderRadius: '5px', padding: '5px' }}
                                  />
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontWeight: '600', fontSize: '14px' }}>Blind Copy (BCc) new invoices to address</p>
                                  <input
                                    className='form-control'
                                    type='text'
                                    placeholder='Seperate multiple emails with the comma'
                                    style={{ fontSize: '14px', width: '50%', borderRadius: '5px', padding: '5px' }}
                                  />
                                </div>
                                <div className='mt-3'>
                                  <p>Sales from</p>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option style={{ fontWeight: '600' }} selected>Estimate</option>
                                    <option value={1}>Invoice and other sales forms</option>
                                  </select>
                                  <textarea
                                    style={{ width: '50%', borderRadius: '5px', marginTop: '10px' }}
                                  />
                                </div>
                                <div className='table-btns mt-2 d-flex gap-3'>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-secondary' onClick={handleCancelClick8}>Cancel</div>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-success'>Save</div>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow19 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Online Delivery</p>
                            </th>
                            <td>
                              <p> Default email messages sent with sales forms </p>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick9} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow20 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Online Delivery</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px" }}>Email options for all sales forms</p>
                                </div>
                                <div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                      Show short summary on email
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                      Show full details in  email
                                    </label>
                                  </div>
                                </div>
                                <div style={{ marginLeft: '13px' }} className="form-check">
                                  <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                  <label className="form-check-label" htmlFor="flexCheckDefault">
                                    PDF Attached
                                  </label>
                                </div>
                                <div>
                                  <p>Additional email options for invoices</p>
                                  <select style={{ width: '50%', fontSize: '14px' }} className="form-select" aria-label="Default select example">
                                    <option selected>Online Invoices</option>
                                    <option value={1}>plain text</option>
                                  </select>
                                </div>
                                <div className='table-btns mt-2 d-flex gap-3'>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-secondary' onClick={handleCancelClick9}>Cancel</div>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-success'>Save</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>-</p>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow21 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Statement</p>
                            </th>
                            <td>
                              <p> Showing ageing table at bottom of statment </p>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick10} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow22 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Statement</p>
                            </th>
                            <td>
                              <div className='row'>
                                <>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                      List all transactions as a single line
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      defaultChecked=""
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                      List each transaction including all details line
                                    </label>
                                  </div>
                                </>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Show ageing table at bottom of Statement</p>
                                </div>
                                <div className='table-btns mt-2 d-flex gap-3'>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-secondary' onClick={handleCancelClick10}>Cancel</div>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-success'>Save</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row mt-5'>
                                <div style={{ paddingTop: '10px' }}>
                                  <label className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                  {/* <div className="tab-pane fade" id="v-pills-link3" role="tabpanel" aria-labelledby="v-pills-link3-tab">
                     </div> */}
                  <div className="tab-pane fade" id="v-pills-link4" role="tabpanel" aria-labelledby="v-pills-link4-tab">
                    <div className='row'>
                      <table className="table table-stripped">
                        <tbody>
                          <tr className={`tableHoverRow ${showRow23 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Bills and Expenses</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Shows Items table on expense and purchase forms</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Show Tags field on example and purchase forms</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Track expenses and items by customer</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Make expenses and items billable</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Industry</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Default bill payment terms</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <h6 style={{ fontSize: "14px" }}>Off</h6>
                                <h6 style={{ fontSize: "14px" }}>Off</h6>
                                <h6 style={{ fontSize: "14px" }}>Off</h6>
                                <h6 style={{ fontSize: "14px" }}>Off</h6>
                                <h6 style={{ fontSize: "14px" }}>-</h6>
                              </div>
                            </td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick11} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow24 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Bills and Expenses</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Shows Items table on expense and purchase forms</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600", paddingTop: '10px' }}>Show Tags field on example and purchase forms</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600", paddingTop: '10px' }}>Track expenses and items by customer</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600", paddingTop: '10px' }}>Make expenses and items billable</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600", paddingTop: '10px' }}>Industry</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600", paddingTop: '30px' }}>Default bill payment terms</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <label style={{ paddingTop: '15px' }} className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div>
                                  <label style={{ paddingTop: '15px' }} className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div>
                                  <label style={{ paddingTop: '15px' }} className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div>
                                  <label className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div>
                                  <div style={{ paddingTop: '10px' }}>
                                    <input type='checkbox' /> <label>Markup with a default rate of </label><div> <input type='number' placeholder='0.00' /> %</div>
                                  </div>
                                  <div style={{ paddingTop: '13px' }}>
                                    <input type='checkbox' /> <label>Track billable expenses and items as income </label>
                                    <div>
                                      <input type='radio ' /> <label>In a single account</label>
                                      <br /><br />
                                      <input type='radio ' /> <label>In a multiple accounts</label>
                                    </div>
                                  </div>
                                </div>
                                <div className='mt-2'>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Default bill payment terms</h5>
                                  <div class="btn-group " style={{ border: "1px solid" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <input className='form-control border-0' type='text' value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                                      <select style={{ width: "20px", height: "35px", border: "none", borderRadius: '5px' }} value={selectedOption} onChange={handleSelectChange}>
                                        <option value={'Due on receipt'}>Due on receipt</option>
                                        <option value={'Net 15'}>Net 15</option>
                                        <option value={'Net 30'}>Net 30</option>
                                        <option value={'Net 60'}>Net 60</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                  <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick11}>Cancel</button>
                                  <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => setShowRow2(false)}> Save</button>
                                </div>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow25 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Purchase orders</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Use purchase orders</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <h5 style={{ fontSize: "14px", fontWeight: "600" }}>-</h5>
                              </div>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick12} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow26 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Purchase orders</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Use purchase orders</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <label className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                  <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick12}>Cancel</button>
                                  <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} > Save</button>
                                </div>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow27 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Messages</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Default email message sent with purchase orders</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick12} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow28 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Messages</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Default email message sent with sales forms</p>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='d-flex gap-4 align-items-baseline'>
                                  <div className="form-check d-flex">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                      Use Greeting
                                    </label>
                                  </div>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option selected>Dear</option>
                                    <option value={1}>To:</option>
                                    <option value={2}>Blank</option>
                                    <option value={3}>Three</option>
                                  </select>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option selected>[Full Name]</option>
                                    <option value={1}>[Company][Name]</option>
                                    <option value={2}>[Title][Last]</option>
                                    <option value={3}>[First][Last]</option>
                                    <option value={4}>[Display][Name]</option>
                                  </select>
                                </div>
                                <div className='d-flex gap-2 mt-3'>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option selected>Invoice</option>
                                    <option value={1}>Estimate</option>
                                    <option value={2}>Dredit Name</option>
                                    <option value={3}>Statement</option>
                                    <option value={4}>Sales receipt</option>
                                    <option value={5}>Refund receipt</option>
                                  </select>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-success'>Use Standard Message</div>
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontSize: '14px', fontWeight: '600' }}>Email Subject Line</p>
                                  <input
                                    className='form-control'
                                    type='text'
                                    style={{ fontSize: '14px', width: '50%', padding: '5px', borderRadius: '5px' }}
                                  />
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontSize: '14px', fontWeight: '600' }}>Email Message</p>
                                  <textarea style={{ width: '50%', padding: '5px', borderRadius: '5px' }} />
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                      Email me a copy at
                                    </label>
                                  </div>
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontWeight: '600', fontSize: '14px' }}>Copy (Cc) new invoices to address</p>
                                  <input
                                    className='form-control'
                                    type='text'
                                    placeholder='Seperate multiple emails with the comma'
                                    style={{ fontSize: '14px', width: '50%', padding: '5px', borderRadius: '5px' }}
                                  />
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontWeight: '600', fontSize: '14px' }}>Blind Copy (BCc) new invoices to address</p>
                                  <input
                                    className='form-control'
                                    type='text'
                                    placeholder='Seperate multiple emails with the comma'
                                    style={{ fontSize: '14px', width: '50%', padding: '5px', borderRadius: '5px' }}
                                  />
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                  <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick13}>Cancel</button>
                                  <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} > Save</button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-link5" role="tabpanel" aria-labelledby="v-pills-link5-tab">
                    <div className='row'>
                      <table className="table table-stripped">
                        <thead>
                          <tr>
                            <th>Day</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Total Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData?.timings?.map((timing, idx) => (
                            <tr key={idx}>
                              <td>{timing.day}</td>
                              <td>
                                <input
                                  className='form-control'
                                  type="time"
                                  value={timing.startTime}
                                  onChange={(e) => handleInputChange(e, idx, 'startTime')}
                                />
                              </td>
                              <td>
                                <input
                                  className='form-control'
                                  type="time"
                                  value={timing.endTime}
                                  onChange={(e) => handleInputChange(e, idx, 'endTime')}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={timing.totalTime}
                                  readOnly
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div>
                        <button className='btn btn-success' style={{ borderRadius: "30px" }} onClick={() => editCompany(formData)}>Save</button>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-link6" role="tabpanel" aria-labelledby="v-pills-link6-tab">
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={`tableHoverRow ${showRowAdvance ? '' : 'd-none'}`}>
                      <div><h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Accounting</h5></div>
                      <div>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>First month of financial year</h5><br />
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>First month of tax year</h5><br />
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Accounting method</h5><br />
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Close the books</h5><br />
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Default tax rate selection</h5><br />
                      </div>
                      <div className='row'>
                        <div>
                          <h6 style={{ fontSize: "14px" }}>{settingData?.first_financial_year ? settingData?.first_financial_year : '-'}</h6><br />
                          <h6 style={{ fontSize: "14px" }}>{settingData?.first_financial_month ? settingData?.first_financial_month : '-'}</h6><br />
                          <h6 style={{ fontSize: "14px" }}>{settingData?.account_method ? settingData?.account_method : '-'}</h6><br />
                          <h6 style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: settingData?.isClosingDate === false ? 'Off' : `${settingData?.closing_date} <br/> <b>${settingData?.closing_condition}</b>` }}></h6>
                          <h6 style={{ fontSize: "14px" }}>{settingData?.tax_form ? settingData?.tax_form : '-'}</h6><br />
                        </div>
                      </div>
                      <div>
                        <CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClickAdvance1} />
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", background: "#f3f8fe", padding: "1rem" }} className={`tableHoverRow ${showRowAdvanceEdit ? '' : 'd-none'}`}>
                      <div><h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Accounting</h5></div>
                      <div>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>First month of financial year</h5> <br /><br />
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>First month of tax year</h5><br /><br />
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Accounting method</h5><br />
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Close the books</h5><br />
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Default tax rate selection</h5><br />
                      </div>
                      <div>
                        <div>
                          <select className='form-control w-75' value={settingData?.first_financial_year} onChange={(e) => setSettingData({ ...settingData, first_financial_year: e.target.value })}>
                            <option hidden>Select Month</option>
                            <option value={'January'}>January</option>
                            <option value={'Febuary'}>Febuary</option>
                            <option value={'March'}>March</option>
                            <option value={'April'}>April</option>
                            <option value={'May'}>May</option>
                            <option value={'June'}>June</option>
                            <option value={'July'}>July</option>
                            <option value={'August'}>August</option>
                            <option value={'September'}>September</option>
                            <option value={'Octuber'}>Octuber</option>
                            <option value={'November'}>November</option>
                            <option value={'December'}>December</option>
                          </select>
                        </div><br />
                        <div>
                          <select className='form-control w-75' value={settingData?.first_financial_month} onChange={(e) => setSettingData({ ...settingData, first_financial_month: e.target.value })}>
                            <option hidden>Select</option>
                            <option value={'Same as Financial Year'}>Same as Financial Year</option>
                            <option value={'January'}>January</option>
                          </select>
                        </div><br />
                        <div>
                          <select className='form-control w-75' value={settingData?.account_method} onChange={(e) => setSettingData({ ...settingData, account_method: e.target.value })}>
                            <option hidden>Select</option>
                            <option value={'Accural'}>Accural</option>
                            <option value={'Cash'}>Cash</option>
                          </select>
                        </div><br />
                        <div>
                          <input type='checkbox' checked={settingData?.isClosingDate} onChange={(e) => setSettingData({ ...settingData, isClosingDate: e.target.checked })} />
                          {settingData?.isClosingDate === true ? (<div style={{ display: "flex", gap: "10px", alignItems: 'end' }}>
                            <div>
                              <label>Closing date</label>
                              <input type='date' className='form-control w-100' value={settingData?.closing_date} onChange={(e) => setSettingData({ ...settingData, closing_date: e.target.value })} />
                            </div>
                            <div>
                              <select className='form-control w-75' value={settingData?.closing_condition} onChange={(e) => setSettingData({ ...settingData, closing_condition: e.target.value })}>
                                <option hidden>Select</option>
                                <option value={'Allow changes after viewing a warning'}>Allow changes after viewing a warning</option>
                                <option value={'Allow changes after viewing a warning and entering password'}>Allow changes after viewing a warning and entering password</option>
                              </select>
                            </div>
                          </div>) : ''}
                        </div><br />
                        <div>
                          <select className='form-control w-75' value={settingData?.tax_form} onChange={(e) => setSettingData({ ...settingData, tax_form: e.target.value })}>
                            <option hidden>Select</option>
                            <option value={'Exclusive Tax'}>Exclusive Tax</option>
                            <option value={'Inclusive Tax'}>Inclusive Tax</option>
                            <option value={'Out of Scope of Tax'}>Out of Scope of Tax</option>
                          </select>
                        </div>
                        <br />
                        <div style={{ marginTop: "1rem" }}>
                          <button
                            className='btn btn-mute'
                            style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }}
                            onClick={handleCancelClickAvance1}
                          >
                            Cancel
                          </button>
                          <button
                            className='btn btn-success'
                            style={{ border: "1px solid lightgray", borderRadius: "30px" }}
                            onClick={() => storeCustomSettings(settingData)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={`tableHoverRow ${showRowAdvanceType ? '' : 'd-none'}`}>
                      <div>
                        <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Company Type</h5>
                      </div>
                      <div>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Tax Form</h5>
                      </div>
                      <div>
                        <h6 style={{ fontSize: "14px" }}>{settingData?.account_company_type ? settingData?.account_company_type : '-'}</h6>
                      </div>
                      <div>
                        <CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClickCompanyType} />
                      </div>
                    </div>
                    <div className={`tableHoverRow ${showRowAdvanceTypeEdit ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
                        <div>
                          <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Company Type</h5>
                        </div>
                        <div>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Tax Form</h5>
                        </div>
                        <div>
                          <select className='form-select w-75 mb-3' value={settingData?.account_company_type} onChange={(e) => setSettingData({ ...settingData, account_company_type: e.target.value })}>
                            <option hidden>Select</option>
                            <option value={'Sole properitor'}>Sole properitor</option>
                            <option value={'Partnership or limited laibility company'}>Partnership or limited laibility company</option>
                            <option value={'Small bussiness corporation, two or more owners'}>Small bussiness corporation, two or more owners</option>
                            <option value={'Corporation, one or more shareholders'}>Corporation, one or more shareholders</option>
                            <option value={'Nonprofit organization'}>Nonprofit organization</option>
                            <option value={'Limited laibility'}>Limited laibility</option>
                            <option value={'Not sure/Other/None'}>Not sure/Other/None</option>
                          </select>
                          <div>
                            <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClickComTpe}>Cancel</button>
                            <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => storeCustomSettings(settingData)}> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={`tableHoverRow ${showRowCharts ? '' : 'd-none'}`}>
                      <div>
                        <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Charts of Accounts</h5>
                      </div>
                      <div>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Account Number</h5>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Shipping Account</h5>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Discount Account</h5>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Billable expense income account</h5>
                      </div>
                      <div>
                        <h6 style={{ fontSize: "14px" }}>{settingData?.account_number_show === false ? 'Off' : 'On'}</h6>
                        <h6 style={{ fontSize: "14px" }}>{settingDat?.data?.ship?.name ? settingDat?.data?.ship?.name : '-'}</h6>
                        <h6 style={{ fontSize: "14px" }}>{settingDat?.data?.discount?.name ? settingDat?.data?.discount?.name : '-'}</h6>
                        <h6 style={{ fontSize: "14px" }}>{settingDat?.data?.expense?.name ? settingDat?.data?.expense?.name : '-'}</h6>
                      </div>
                      <div>
                        <CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClickChart} />
                      </div>
                    </div>
                    <div className={`tableHoverRow ${showRowChartsEdit ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
                        <div>
                          <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Charts of Accounts</h5>
                        </div>
                        <div>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Account Number</h5>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Shipping Account</h5>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Discount Account</h5>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Billable expense income account</h5>
                        </div>
                        <div>
                          <div>
                            <input type='checkbox' checked={settingData?.account_number_show === true} onChange={(e) => setSettingData({ ...settingData, account_number_show: e.target.checked })} />
                          </div>
                          <div>
                            <select className='form-select w-75 mb-3' value={settingData?.account_shipping_account} onChange={(e) => setSettingData({ ...settingData, account_shipping_account: e.target.value })}>
                              <option hidden>Select</option>
                              {accountsDataC?.data?.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>{item.name}</option>
                                )
                              })}
                            </select>
                          </div>
                          <div>
                            <select className='form-select w-75 mb-3' value={settingData?.account_discount_account} onChange={(e) => setSettingData({ ...settingData, account_discount_account: e.target.value })}>
                              <option hidden>Select</option>
                              {accountsDataC?.data?.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>{item.name}</option>
                                )
                              })}
                            </select>
                          </div>
                          <div>
                            <select className='form-select w-75 mb-3' value={settingData?.account_expense_income} onChange={(e) => setSettingData({ ...settingData, account_expense_income: e.target.value })}>
                              <option hidden>Select</option>
                              {accountsDataC?.data?.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>{item.name}</option>
                                )
                              })}
                            </select>
                          </div>
                          <div>
                            <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClickChart}>Cancel</button>
                            <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => storeCustomSettings(settingData)}> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={`tableHoverRow ${showRowAtom ? '' : 'd-none'}`}>
                      <div>
                        <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Automation</h5>
                      </div>
                      <div>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Pre-fill forms with previously entered content</h5>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Automatically invoice unbilled activity</h5>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Automatically apply bill payments</h5>
                      </div>
                      <div>
                        <h6 style={{ fontSize: "14px" }}>-</h6>
                        <h6 style={{ fontSize: "14px" }}>-</h6>
                        <h6 style={{ fontSize: "14px" }}>-</h6>
                        {/* <h6 style={{ fontSize: "14px" }}>{settingData?.account_company_type ? settingData?.account_company_type : '-'}</h6>
                        <h6 style={{ fontSize: "14px" }}>{settingData?.account_company_type ? settingData?.account_company_type : '-'}</h6> */}
                      </div>
                      <div>
                        <CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClickAtom} />
                      </div>
                    </div>
                    <div className={`tableHoverRow ${showRowAtomsEdit ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
                        <div>
                          <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Automation</h5>
                        </div>
                        <div>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Pre-fill forms with previously entered content</h5>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Automatically invoice unbilled activity</h5>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Automatically apply bill payments</h5>
                        </div>
                        <div>
                          <div>
                            <input type='checkbox' />
                          </div>
                          <div>
                            <input type='checkbox' />
                          </div>
                          <div>
                            <input type='checkbox' />
                          </div>
                          <div>
                            <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClickAtom}>Cancel</button>
                            <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => storeCustomSettings(formData)}> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={`tableHoverRow ${showRowProject ? '' : 'd-none'}`}>
                      <div>
                        <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Projects</h5>
                      </div>
                      <div>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Organise all job-related activity in one place</h5>
                      </div>
                      <div>
                        {/* <h6 style={{ fontSize: "14px" }}>{settingData?.account_company_type ? settingData?.account_company_type : '-'}</h6> */}
                        <h6 style={{ fontSize: "14px" }}>-</h6>
                      </div>
                      <div>
                        <CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClickProject} />
                      </div>
                    </div>
                    <div className={`tableHoverRow ${showRowProjectsEdit ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
                        <div>
                          <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Projects</h5>
                        </div>
                        <div>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Organise all job-related activity in one place</h5>
                        </div>
                        <div>
                          <div>
                            <input type='checkbox' />
                          </div>
                          <div>
                            <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClickProject}>Cancel</button>
                            <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => storeCustomSettings(formData)}> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={`tableHoverRow ${showRowCates ? '' : 'd-none'}`}>
                      <div>
                        <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Categories</h5>
                      </div>
                      <div>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Track classes</h5>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Track locations</h5>
                      </div>
                      <div>
                        <h6 style={{ fontSize: "14px" }}>{settingData?.track_classes === false ? 'Off' : 'On'}</h6>
                        <h6 style={{ fontSize: "14px" }}>{settingData?.track_location === false ? 'Off' : 'On'}</h6>
                      </div>
                      <div>
                        <CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClickCates} />
                      </div>
                    </div>
                    <div className={`tableHoverRow ${showRowCatesEdit ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
                        <div>
                          <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Categories</h5>
                        </div>
                        <div>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Track classes</h5>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Track locations</h5>
                        </div>
                        <div>
                          <div>
                            <input type='checkbox' checked={settingData?.track_classes === true} onChange={(e) => setSettingData({ ...settingData, track_classes: e.target.checked })} />
                          </div>
                          <div>
                            <input type='checkbox' checked={settingData?.track_location === true} onChange={(e) => setSettingData({ ...settingData, track_location: e.target.checked })} />
                          </div>
                          <div>
                            <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClickCates}>Cancel</button>
                            <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => storeCustomSettings(settingData)}> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={`tableHoverRow ${showRowLang ? '' : 'd-none'}`}>
                      <div>
                        <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Language</h5>
                      </div>
                      <div>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Language</h5>
                      </div>
                      <div>
                        <h6 style={{ fontSize: "14px" }}>{settingData?.account_language ? settingData?.account_language : '-'}</h6>
                      </div>
                      <div>
                        <CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClickLang} />
                      </div>
                    </div>
                    <div className={`tableHoverRow ${showRowLangEdit ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
                        <div>
                          <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Languages</h5>
                        </div>
                        <div>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Language</h5>
                        </div>
                        <div>
                          <div>
                            <select className='form-select w-75 mb-3' value={settingData?.account_language} onChange={(e) => setSettingData({ ...settingData, account_language: e.target.value })}>
                              <option hidden>Select</option>
                              {languages?.map((item) => {
                                return (
                                  <option value={item} key={item}>{item}</option>
                                )
                              })}
                            </select>
                          </div>
                          <div>
                            <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClickLang}>Cancel</button>
                            <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => storeCustomSettings(settingData)}> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }} className={`tableHoverRow ${showRowCurrency ? '' : 'd-none'}`}>
                      <div>
                        <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Currency</h5>
                      </div>
                      <div>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Home Currency</h5>
                      </div>
                      <div>
                        <h6 style={{ fontSize: "14px" }}>{settingData?.account_currency ? settingData?.account_currency : '-'}</h6>
                      </div>
                      <div>
                        <CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClickCurrency} />
                      </div>
                    </div>
                    <div className={`tableHoverRow ${showRowCurrencyEdit ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
                        <div>
                          <h5 style={{ fontSize: "13px", color: "#4f4f4f", fontWeight: "bold" }}>Currency</h5>
                        </div>
                        <div>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Home Currency</h5>
                        </div>
                        <div>
                          <div>
                            <select className='form-select w-75 mb-3' value={settingData?.account_currency} onChange={(e) => setSettingData({ ...settingData, account_currency: e.target.value })}>
                              <option hidden>Select</option>
                              {countriesWithCurrencies?.map((item) => {
                                return (
                                  <option value={item?.country} key={item?.country}>{item?.currency}</option>
                                )
                              })}
                            </select>
                          </div>
                          <div>
                            <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClickCurrency}>Cancel</button>
                            <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => storeCustomSettings(settingData)}> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "space-between" }} className={`tableHoverRow ${showRowOther ? '' : 'd-none'}`}>
                      <div>
                        <h5 style={{ fontSize: "13px", color: "#4f4f4f",fontWeight:"bold" }}>Other</h5>
                      </div>
                      <div>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Home Other</h5>
                        <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Multicurrency </h5>
                      </div>
                      <div>
                        <h6 style={{ fontSize: "14px" }}>{settingData?.account_company_type ? settingData?.account_company_type : '-'}</h6>
                      </div>
                      <div>
                        <CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClickOther} />
                      </div>
                    </div>
                    <div className={`tableHoverRow ${showRowOtherEdit ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
                        <div>
                          <h5 style={{fontSize:"13px",color:"#4f4f4f"}}>Other Preferrences</h5>
                        </div>
                        <div>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Home Other</h5>
                          <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Multicurrency </h5>
                        </div>
                        <div>
                          <div>
                            <select className='form-select w-75 mb-3' value={settingData?.account_company_type} onChange={(e) => setSettingData({ ...settingData, account_company_type: e.target.value })}>
                              <option>Sole properitor</option>
                              <option>Partnership or limited laibility company</option>
                              <option>Small bussiness corporation, two or more owners</option>
                              <option>Corporation, one or more shareholders</option>
                              <option>Nonprofit organization</option>
                              <option>Limited laibility</option>
                              <option>Not sure/Other/None</option>
                            </select>
                          </div>
                          <div>
                            <input type='checkbox' />
                          </div>
                          <div>
                            <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClickOther}>Cancel</button>
                            <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => storeCustomSettings(formData)}> Save</button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div >
    </>
  )
}

export default CompanyAddPage
