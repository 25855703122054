import React, { useEffect, useState } from 'react'
import { GrServices } from 'react-icons/gr'
import { CiEdit, CiTrash } from 'react-icons/ci'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function ServicesCategory() {
    const params = useParams()
    const { getSerCateFilter, accountsData, getAccountsData, handleClick, setSnackbarValue, getServicesCategory, servicecategory, storeServicesCate, updateServiceCategory, deleteServicesSubCategory, getFilterService } = useData()
    useEffect(() => {
        getServicesCategory()
        getAccountsData()
    }, [])
    const [selectedCompany, setSelectedCompany] = useState(null);
    const filteredCompanies = accountsData?.company?.filter(item => item.deleteStatus === 0);
    const handleDeleteCategory = (id) => {
        deleteServicesSubCategory(id);
    }
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj({
            company_id: params.id ? params.id : selectedCompany,
            name: ''
        })
        setOpen(false)
    };
    const [obj, setObj] = useState({
        company_id: params.id ? params.id : selectedCompany,
        name: ''
    })
    const isFormValid = () => {
        for (const key in obj) {
            if (obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = () => {
        if (isFormValid()) {
            storeServicesCate(obj)
            setObj({
                company_id: '',
                name: ''
            })
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const handleUpdateSubmit = () => {
        if (isFormValid()) {
            updateServiceCategory(obj?.id, obj)
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleServiceCate = params.id
        ? servicecategory?.data?.filter(item => item?.company?.id == params.id) || []
        : servicecategory?.data || [];

    const currentServicesCate = visibleServiceCate?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleServiceCate.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    return (
        <>
            <div className='expenseCategory'>
                <div className='expense-header d-flex justify-content-between align-items-end'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Services Category</li>
                        </ol>
                    </nav>
                    <div className='inp-buttons d-flex gap-2 align-items-end'>
                        <div>
                            <select disabled={params.id ? true : false} className='form-select' onChange={(e) => {
                                handlePageChange(1)
                                getSerCateFilter(e.target.value)
                            }} >
                                <option value="">Select a company</option>
                                {filteredCompanies?.map((item) => (
                                    <option key={item.id} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button type="button" className="btn btn-primary" onClick={handleOpen}>Add</button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Add
                                </Typography>
                                <div id="modal-modal-description" sx={{ mt: 2 }}>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label>Company</label>
                                                <div className='selectBox'>
                                                    <label><GrServices /></label>
                                                    <select disabled={params.id ? true : false} value={obj?.company_id} onChange={(e) => {
                                                        setSelectedCompany(parseInt(e.target.value));
                                                        setObj({
                                                            ...obj,
                                                            company_id: parseInt(e.target.value)
                                                        });
                                                    }}>
                                                        <option value="">Select a company</option>
                                                        {filteredCompanies?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label>Category</label>
                                                <div className='inputBox'>
                                                    <label><GrServices /></label>
                                                    <input value={obj?.name} type='text' placeholder='Service Category' onChange={(e) => setObj({ ...obj, name: e.target.value })}></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='modalBtns d-flex justify-content-end gap-2'>
                                            <button className='btn btn-primary' onClick={() => {
                                                obj?.id ? handleUpdateSubmit() : handleSubmit()
                                            }}>{obj?.id ? 'Update' : 'Submit'}</button>
                                            <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>

                </div>
                <hr />

                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table mx-auto w-100">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Service Category</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {params?.id ? currentServicesCate?.map((item, indx) => {
                                    return (
                                        <>
                                            {item.company?.id == params?.id && <tr key={item.id}>
                                                <th scope="row">{indexOfFirstItem + indx + 1}</th>
                                                <td>{item.company?.name}</td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <button className="btn btn-primary p-1 m-2" onClick={() => {
                                                        setObj(item)
                                                        handleOpen()
                                                    }}>
                                                        <CiEdit style={{ fontSize: "20px" }} />
                                                    </button>
                                                    <button className="btn btn-danger p-1 m-2" onClick={() => handleDeleteCategory(item.id)}>
                                                        <CiTrash style={{ fontSize: "20px" }} />
                                                    </button>
                                                </td>
                                            </tr>}
                                        </>

                                    );
                                }) : currentServicesCate?.map((item, indx) => {
                                    return (
                                        <tr key={item.id}>
                                            <th scope="row">{indexOfFirstItem + indx + 1}</th>
                                            <td>{item.company?.name}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                <button className="btn btn-primary p-1 m-2" onClick={() => {
                                                    setObj(item)
                                                    handleOpen()
                                                }}>
                                                    <CiEdit style={{ fontSize: "20px" }} />
                                                </button>
                                                <button className="btn btn-danger p-1 m-2" onClick={() => handleDeleteCategory(item.id)}>
                                                    <CiTrash style={{ fontSize: "20px" }} />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>
                        <div className="pagination-controls">{paginationControls}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesCategory
