import React, { useEffect, useRef, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa6";
import { useNavigate, useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';
import { useReactToPrint } from 'react-to-print'
function BudgetShow() {
    const params = useParams()
    const navigate = useNavigate()
    const { monthlyBudgetIndi, getClientData, clientData, company, getCompnayCrud, updateMonthlyBudget, getBudgetAcData, budgetAcData, storeBudgetYearly, getMonthlyBudgetIndi } = useData()
    useEffect(() => {
        getBudgetAcData()
        getCompnayCrud()
        getClientData()
        getMonthlyBudgetIndi(params.id)
    }, [])
    useEffect(() => {
        setMonthlyBudget({
            id: monthlyBudgetIndi?.monthly?.id,
            company_id: monthlyBudgetIndi?.monthly?.company_id,
            name: monthlyBudgetIndi?.monthly?.name,
            finencial_year: monthlyBudgetIndi?.monthly?.finencial_year,
            interval: 'Monthly',
            pre_fill: monthlyBudgetIndi?.monthly?.pre_fill,
            subdivided: monthlyBudgetIndi?.monthly?.subdivided,
            total_amount: monthlyBudgetIndi?.monthly?.total_amount,
            client_id: monthlyBudgetIndi?.monthly?.client_id,
            monthly_budget: {
                income: monthlyBudgetIndi?.monthly?.monthly_income?.map((item) => ({
                    account_id: item.account_id,
                    january: item.jan,
                    february: item.feb,
                    march: item.mar,
                    april: item.apr,
                    may: item.may,
                    june: item.jun,
                    july: item.july,
                    august: item.aug,
                    september: item.sep,
                    october: item.oct,
                    november: item.nov,
                    december: item.dec,
                    total_price: item.total_price?.toLocaleString(),
                })),
                other_income: monthlyBudgetIndi?.monthly?.monthly_other_income?.map((item) => ({
                    account_id: item.account_id,
                    january: item.jan,
                    february: item.feb,
                    march: item.mar,
                    april: item.apr,
                    may: item.may,
                    june: item.jun,
                    july: item.july,
                    august: item.aug,
                    september: item.sep,
                    october: item.oct,
                    november: item.nov,
                    december: item.dec,
                    total_price: item.total_price?.toLocaleString(),
                })),
                cost_of_sale: monthlyBudgetIndi?.monthly?.monthly_cost_of_sale?.map((item) => ({
                    account_id: item.account_id,
                    january: item.jan,
                    february: item.feb,
                    march: item.mar,
                    april: item.apr,
                    may: item.may,
                    june: item.jun,
                    july: item.july,
                    august: item.aug,
                    september: item.sep,
                    october: item.oct,
                    november: item.nov,
                    december: item.dec,
                    total_price: item.total_price?.toLocaleString(),
                })),
                expenses: monthlyBudgetIndi?.monthly?.monthly_expenses?.map((item) => ({
                    account_id: item.account_id,
                    january: item.jan,
                    february: item.feb,
                    march: item.mar,
                    april: item.apr,
                    may: item.may,
                    june: item.jun,
                    july: item.july,
                    august: item.aug,
                    september: item.sep,
                    october: item.oct,
                    november: item.nov,
                    december: item.dec,
                    total_price: item.total_price?.toLocaleString(),
                })),
                other_expenses: monthlyBudgetIndi?.monthly?.monthly_other_expenses?.map((item) => ({
                    account_id: item.account_id,
                    january: item.jan,
                    february: item.feb,
                    march: item.mar,
                    april: item.apr,
                    may: item.may,
                    june: item.jun,
                    july: item.july,
                    august: item.aug,
                    september: item.sep,
                    october: item.oct,
                    november: item.nov,
                    december: item.dec,
                    total_price: item.total_price?.toLocaleString(),
                })),
            }
        })
    }, [monthlyBudgetIndi])
    const [showNestedTables, setShowNestedTables] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
    });

    const toggleNestedTable = (rowNumber) => {
        setShowNestedTables((prev) => ({
            ...prev,
            [rowNumber]: !prev[rowNumber],
        }));
    };

    const [monthlyBudget, setMonthlyBudget] = useState({
        id: params?.id,
        company_id: '',
        name: '',
        finencial_year: '',
        interval: 'Monthly',
        pre_fill: '',
        subdivided: '',
        total_amount: '',
        client_id: '',
        monthly_budget: {
            income: budgetAcData?.income?.map((item) => ({
                account_id: item.account_id,
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
                total_price: 0,
            })),
            other_income: budgetAcData?.other_income?.map((item) => ({
                account_id: item.id,
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
                total_price: 0,
            })),
            cost_of_sale: budgetAcData?.cost_of_sale?.map((item) => ({
                account_id: item.id,
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
                total_price: 0,
            })),
            expenses: budgetAcData?.expenses?.map((item) => ({
                account_id: item.id,
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
                total_price: 0,
            })),
            other_expenses: budgetAcData?.other_expenses?.map((item) => ({
                account_id: item.id,
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
                total_price: 0,
            })),
        }
    });
    const calculateTotalIncome = (month) => {
        const total = monthlyBudget?.monthly_budget?.income?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalIncome1 = (month) => {
        const total = monthlyBudget?.monthly_budget?.other_income?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum = () => {
        const totalSum = monthlyBudget?.monthly_budget?.income?.reduce(
            (sum, item) => sum + parseFloat(item.total_price?.toLocaleString() || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalSum1 = () => {
        const totalSum = monthlyBudget?.monthly_budget?.other_income?.reduce(
            (sum, item) => sum + parseFloat(item.total_price?.toLocaleString() || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome2 = (month) => {
        const total = monthlyBudget?.monthly_budget?.expenses?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum2 = () => {
        const totalSum = monthlyBudget?.monthly_budget?.expenses?.reduce(
            (sum, item) => sum + parseFloat(item.total_price?.toLocaleString() || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome3 = (month) => {
        const total = monthlyBudget?.monthly_budget?.cost_of_sale?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum3 = () => {
        const totalSum = monthlyBudget?.monthly_budget?.cost_of_sale?.reduce(
            (sum, item) => sum + parseFloat(item.total_price?.toLocaleString() || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome4 = (month) => {
        const total = monthlyBudget?.monthly_budget?.other_expenses?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum4 = () => {
        const totalSum = monthlyBudget?.monthly_budget?.other_expenses?.reduce(
            (sum, item) => sum + parseFloat(item.total_price?.toLocaleString() || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const updateTotalAmount = () => {
        const totalSum =
            parseFloat(calculateTotalSum() || 0) +
            parseFloat(calculateTotalSum1() || 0) +
            parseFloat(calculateTotalSum2() || 0) +
            parseFloat(calculateTotalSum3() || 0) +
            parseFloat(calculateTotalSum4() || 0);

        setMonthlyBudget(prevState => ({
            ...prevState,
            total_amount: totalSum?.toFixed(0),
        }));
    };
    useEffect(() => {
        updateTotalAmount();
    }, [
        monthlyBudget?.monthly_budget?.income,
        monthlyBudget?.monthly_budget?.other_income,
        monthlyBudget?.monthly_budget?.expenses,
        monthlyBudget?.monthly_budget?.cost_of_sale,
        monthlyBudget?.monthly_budget?.other_expenses,
    ]);
    const handleSubmit = async () => {
        try {
            await updateMonthlyBudget(monthlyBudget)
            navigate('/admin/budget');
        } catch (error) {
            console.error('Error submitting yearly budget:', error);
        }
    };

    const handleClose = () => {
        navigate('/admin/budget');
    }
    const handleDownloadCsv = () => {
        const csvData = [];
        const newLine1 = ['',];
        const companyNameRow = [
            'Budget Overview:',
            `${monthlyBudgetIndi?.monthly?.name}`,
        ];
        const companyNameRow1 = [
            `Financial Year:`,
            `${monthlyBudgetIndi?.monthly?.finencial_year}`,
        ];
        const companyNameRow2 = [
            `Total Amount during ${monthlyBudgetIndi?.monthly?.finencial_year?.split("(")[0]}:`,
            `Rs${monthlyBudgetIndi?.monthly?.total_amount?.toLocaleString()}`,
        ];
        csvData.push(companyNameRow);
        csvData.push(companyNameRow1);
        csvData.push(companyNameRow2);
        csvData.push(newLine1);
        csvData.push(newLine1);

        const headerRow = [
            'Accounts',
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
            'Total',
        ];
        csvData.push(headerRow);

        const incomeRows = monthlyBudgetIndi?.monthly?.monthly_income.map((item) => {
            const row = [item.account?.name || ''];
            let totalIncome = 0;

            for (let i = 0; i < 12; i++) {
                const amount = item[Object.keys(item)[i + 4]];
                row.push(amount);
                totalIncome += parseFloat(amount || 0);
            }

            const totalIncomeAmount = item.total_price ? parseFloat(item.total_price) : 0;
            row.push(`PRs ${totalIncomeAmount.toLocaleString()}`);
            return row;
        });

        const costOfSalesRows = monthlyBudgetIndi?.monthly?.monthly_cost_of_sale?.map((item) => {
            const row = [item.account?.name || ''];
            let totalCostOfSales = 0;

            for (let i = 0; i < 12; i++) {
                const amount = item[Object.keys(item)[i + 4]];
                row.push(amount);
                totalCostOfSales += parseFloat(amount || 0);
            }

            const totalCostOfSalesAmount = item.total_price ? parseFloat(item.total_price) : 0;
            row.push(`PRs ${totalCostOfSalesAmount.toLocaleString()}`);
            return row;
        });

        const grossProfitRow = ['Gross Profit'];
        let totalGrossProfitAmount = 0;

        for (let i = 0; i < 12; i++) {
            const incomeAmount = incomeRows.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
            const costOfSalesAmount = costOfSalesRows.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
            const grossProfitAmount = incomeAmount - costOfSalesAmount;
            totalGrossProfitAmount += grossProfitAmount;
            grossProfitRow.push(`PRs ${grossProfitAmount.toLocaleString()}`);
        }

        grossProfitRow.push(`PRs ${totalGrossProfitAmount.toLocaleString()}`);
        const totalExpensesByAccount = [];
        const monthlyExpensesRows = monthlyBudgetIndi?.monthly?.monthly_expenses.map((item) => {
            const row = [item.account?.name || ''];
            let totalExpenseAmountForAccount = 0;

            for (let i = 0; i < 12; i++) {
                const amount = parseFloat(item[Object.keys(item)[i + 4]] || 0);
                row.push(amount);
                totalExpenseAmountForAccount += amount;
            }

            const totalExpenseAmount = parseFloat(item.total_price?.toLocaleString() || 0);
            row.push(`PRs ${totalExpenseAmount.toLocaleString()}`);
            totalExpensesByAccount.push(totalExpenseAmountForAccount);
            return row;
        });
        const totalExpensesRow = ['Total Expenses Amount'];
        for (let i = 0; i < 12; i++) {
            const totalAmountForMonth = monthlyExpensesRows.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
            totalExpensesRow.push(`PRs ${totalAmountForMonth.toLocaleString()}`);
        }
        totalExpensesRow.push(`PRs ${totalExpensesByAccount.reduce((total, amount) => total + amount, 0)?.toLocaleString()}`);


        const netOperatingIncomeRow = ['Net Operating Income'];
        let totalNetOperatingIncomeAmount = 0;

        for (let i = 0; i < 12; i++) {
            const grossProfitAmount = parseFloat(grossProfitRow[i + 1].split(' ')[1].replace(/,/g, '') || 0);
            const totalExpensesAmount = parseFloat(totalExpensesRow[i + 1].split(' ')[1].replace(/,/g, '') || 0);
            const netOperatingIncomeAmount = grossProfitAmount - totalExpensesAmount;
            totalNetOperatingIncomeAmount += netOperatingIncomeAmount;
            netOperatingIncomeRow.push(`PRs ${netOperatingIncomeAmount.toLocaleString()}`);
        }

        netOperatingIncomeRow.push(`PRs ${totalNetOperatingIncomeAmount.toLocaleString()}`);

        const otherIncomeRows = monthlyBudgetIndi?.monthly?.monthly_other_income?.map((item) => {
            const row = [item.account?.name || ''];
            for (let i = 0; i < 12; i++) {
                row.push(item[Object.keys(item)[i + 4]]);
            }
            row.push(`PRs ${item.total_price?.toLocaleString() ? item.total_price?.toLocaleString() : 0}`);
            return row;
        });

        const otherExpenseRows = monthlyBudgetIndi?.monthly?.monthly_other_expenses?.map((item) => {
            const row = [item.account?.name || ''];
            for (let i = 0; i < 12; i++) {
                row.push(item[Object.keys(item)[i + 4]]);
            }
            row.push(`PRs ${item.total_price?.toLocaleString() ? item.total_price?.toLocaleString() : 0}`);
            return row;
        });

        const netOtherIncomeRow = ['Net Other Income'];
        let totalNetOtherIncomeAmount = 0;

        for (let i = 0; i < 12; i++) {
            const otherIncomeAmount = otherIncomeRows.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
            const otherExpenseAmount = otherExpenseRows.reduce((total, row) => total + parseFloat(row[i + 1] || 0), 0);
            const netOtherIncomeAmount = otherIncomeAmount - otherExpenseAmount;
            totalNetOtherIncomeAmount += netOtherIncomeAmount;
            netOtherIncomeRow.push(`PRs ${netOtherIncomeAmount.toLocaleString()}`);
        }

        const netIncomeRow = ['Net Income'];
        let totalNetIncomeAmount = 0;

        for (let i = 0; i < 12; i++) {
            const netOperatingIncomeAmount = parseFloat(netOperatingIncomeRow[i + 1].split(' ')[1].replace(/,/g, '') || 0);
            const netOtherIncomeAmount = parseFloat(netOtherIncomeRow[i + 1].split(' ')[1].replace(/,/g, '') || 0);
            const netIncomeAmount = netOperatingIncomeAmount + netOtherIncomeAmount;
            totalNetIncomeAmount += netIncomeAmount;
            netIncomeRow.push(`PRs ${netIncomeAmount.toLocaleString()}`);
        }
        netOtherIncomeRow.push(`PRs ${totalNetOtherIncomeAmount.toLocaleString()}`);

        csvData.push(['Income'], ...incomeRows);
        csvData.push(newLine1);
        csvData.push(['Cost of Sales'], ...costOfSalesRows);
        csvData.push(newLine1);
        csvData.push(grossProfitRow);
        csvData.push(newLine1);
        csvData.push(['Expenses'], ...monthlyBudgetIndi?.monthly?.monthly_expenses.map((item) => {
            const row = [item.account?.name || ''];
            for (let i = 0; i < 12; i++) {
                row.push(item[Object.keys(item)[i + 4]]);
            }
            row.push(`PRs ${item.total_price?.toLocaleString() ? item.total_price?.toLocaleString() : 0}`);
            return row;
        }))
        csvData.push(newLine1);
        csvData.push(totalExpensesRow);
        csvData.push(newLine1);
        csvData.push(netOperatingIncomeRow);
        csvData.push(newLine1);
        netIncomeRow.push(`PRs ${totalNetIncomeAmount.toLocaleString()}`);
        csvData.push(['Other Income'], ...otherIncomeRows);
        csvData.push(newLine1);
        csvData.push(['Other Expenses'], ...otherExpenseRows);
        csvData.push(newLine1);
        csvData.push(netOtherIncomeRow);
        csvData.push(newLine1);
        csvData.push(netIncomeRow);
        csvData.push(newLine1);

        const csv = Papa.unparse(csvData, { header: true });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, `${monthlyBudgetIndi?.monthly?.name}-${monthlyBudgetIndi?.monthly?.finencial_year?.split("(")[0]}.csv`);
    };

    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current) {
            handleDownloadPDF();
        }
    }, [cardRef]);
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return;
        }

        setPdfGenerating(true);

        setTimeout(() => {
            const card = cardRef.current;

            const pdfOptions = {
                margin: 0,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };

            if (card) {
                html2pdf()?.from(card)?.set(pdfOptions)?.outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' });

                    // Create a download link
                    const url = URL.createObjectURL(blob);
                    const a = document?.createElement('a');
                    a.href = url;
                    a.download = 'invoice.pdf';
                    a.style.display = 'none';

                    // Append the link to the body and trigger the click
                    document?.body?.appendChild(a);
                    a.click();

                    // Remove the link after the download
                    document?.body?.removeChild(a);

                    setPdfGenerating(false);
                }).catch((error) => {
                    console.error('Error generating PDF:', error);
                    setPdfGenerating(false);
                });
            } else {
                console.error('Card element not found.');
                setPdfGenerating(false);
            }
        }, 0);
    };

    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    });
    const calculateGrossProfit = (month) => {
        const totalIncome = parseInt(calculateTotalIncome(month));
        const totalCostOfSales = parseInt(calculateTotalIncome3(month));
        return (totalIncome - totalCostOfSales)?.toLocaleString();
    };

    const calculateTotalGrossProfit = () => {
        const totalIncomeSum = parseInt(calculateTotalSum());
        const totalCostOfSalesSum = parseInt(calculateTotalSum3());
        return (totalIncomeSum - totalCostOfSalesSum)?.toLocaleString();
    };
    const calculateNetOperatingIncome = (month) => {
        const grossProfit = parseFloat(calculateGrossProfit(month)?.replace(/,/g, ''));
        const totalExpenses = parseFloat(calculateTotalIncome2(month)?.replace(/,/g, ''));
        return (grossProfit - totalExpenses)?.toLocaleString();
    };

    const calculateTotalNetOperatingIncome = () => {
        const totalGrossProfit = parseFloat(calculateTotalGrossProfit()?.replace(/,/g, ''));
        const totalExpensesSum = parseFloat(calculateTotalSum2()?.replace(/,/g, ''));
        return (totalGrossProfit - totalExpensesSum)?.toLocaleString();
    };
    const calculateNetOtherIncome = (month) => {
        const totalOtherIncome = parseFloat(calculateTotalIncome1(month)?.replace(/,/g, ''));
        const totalOtherExpenses = parseFloat(calculateTotalIncome4(month)?.replace(/,/g, ''));
        return (totalOtherIncome - totalOtherExpenses)?.toLocaleString();
    };

    const calculateTotalNetOtherIncome = () => {
        const totalOtherIncomeSum = parseFloat(calculateTotalSum1()?.replace(/,/g, ''));
        const totalOtherExpensesSum = parseFloat(calculateTotalSum4()?.replace(/,/g, ''));
        return (totalOtherIncomeSum - totalOtherExpensesSum)?.toLocaleString();
    };
    const calculateNetIncome = (month) => {
        const netOperatingIncome = parseFloat(calculateNetOperatingIncome(month)?.replace(/,/g, ''));
        const netOtherIncome = parseFloat(calculateNetOtherIncome(month)?.replace(/,/g, ''));
        return (netOperatingIncome + netOtherIncome)?.toLocaleString();
    };

    const calculateTotalNetIncome = () => {
        const totalNetOperatingIncome = parseFloat(calculateTotalNetOperatingIncome()?.replace(/,/g, ''));
        const totalNetOtherIncome = parseFloat(calculateTotalNetOtherIncome()?.replace(/,/g, ''));
        return (totalNetOperatingIncome + totalNetOtherIncome)?.toLocaleString();
    };

    return (
        <>
            <div className='createBudgetData'>
                <div className='row' ref={contentRef}>
                    <div className='card'>
                        <div className='card-body table-responsive recruitment'>
                            <div className='row mb-3'>
                                <h3 style={{ fontSize: "16px", textAlign: "center" }}>{monthlyBudgetIndi?.monthly?.company?.name}</h3>
                                <h3 style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center" }}>Budget Overview: {monthlyBudgetIndi?.monthly?.name}-{monthlyBudgetIndi?.monthly?.finencial_year?.split("(")[0]}</h3>
                                <h3 style={{ fontSize: "14px", textAlign: "center" }}>{monthlyBudgetIndi?.monthly?.finencial_year}</h3>
                                <h3 style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center", color: parseFloat(calculateTotalNetIncome().replace(/,/g, '')) < 0 ? 'red' : 'black' }}>
                                    Net Income: Rs {calculateTotalNetIncome()}
                                </h3>
                            </div>
                            <hr />
                            <table className='table table-hover table-bordered' style={{ marginBottom: "2rem" }}>
                                <thead style={{ background: "#f3f5f8", color: "black", fontWeight: "bold" }}>
                                    <tr>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Account</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Jan</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Feb</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Mar</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Apr</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>May</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Jun</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Jul</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Aug</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Sep</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Oct</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Nov</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Dec</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Income</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(1)}>
                                                    {showNestedTables[1] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                        <td colSpan={13} />
                                    </tr>
                                    {showNestedTables[1] && (
                                        <>
                                            {budgetAcData?.income?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.income?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <p> {monthlyIncome?.january || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.february || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.march || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.april || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.may || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.june || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.july || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.august || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.september || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.october || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.november || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.december || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Income</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalSum()}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Cost of Sales</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(5)}>
                                                {showNestedTables[5] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={13} />
                                    </tr>
                                    {showNestedTables[5] && (
                                        <>
                                            {budgetAcData?.cost_of_sale?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.cost_of_sale?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <p> {monthlyIncome?.january || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.february || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.march || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.april || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.may || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.june || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.july || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.august || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.september || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.october || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.november || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.december || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total COS</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome3(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalSum3()}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Gross Profit</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateGrossProfit(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalGrossProfit()}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Expenses</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(3)}>
                                                {showNestedTables[3] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={13} />
                                    </tr>
                                    {showNestedTables[3] && (
                                        <>
                                            {budgetAcData?.expenses?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.expenses?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <p> {monthlyIncome?.january || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.february || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.march || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.april || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.may || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.june || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.july || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.august || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.september || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.october || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.november || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.december || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Expenses</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome2(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalSum2()}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Net Operating Income</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateNetOperatingIncome(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalNetOperatingIncome()}</p>
                                        </td>
                                    </tr>

                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Other Income</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(2)}>
                                                {showNestedTables[2] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={13} />
                                    </tr>
                                    {showNestedTables[2] && (
                                        <>
                                            {budgetAcData?.other_income?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.other_income?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <p> {monthlyIncome?.january || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.february || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.march || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.april || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.may || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.june || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.july || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.august || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.september || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.october || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.november || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.december || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Income</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome1(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalSum1()}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Other Expenses</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(4)}>
                                                {showNestedTables[4] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={13} />
                                    </tr>
                                    {showNestedTables[1] && (
                                        <>
                                            {budgetAcData?.other_expenses?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.other_expenses?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <p> {monthlyIncome?.january || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.february || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.march || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.april || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.may || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.june || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.july || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.august || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.september || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.october || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.november || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p> {monthlyIncome?.december || ''} </p>
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Expenses</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome4(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalSum4()}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Net Other Income</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateNetOtherIncome(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalNetOtherIncome()}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Net Income</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateNetIncome(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalNetIncome()}</p>
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div style={{ background: "white", padding: "1rem", position: "fixed", width: "100%", bottom: "0", left: "0", display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <div>
                        <button onClick={handlePrint} className='btn btn-primary'>Save as PDF</button>
                        <button onClick={handleDownloadCsv} className='btn btn-success mx-3'>Save as XLS</button>
                        <button className='btn btn-warning' onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BudgetShow 
