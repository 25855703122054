import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CiImport } from 'react-icons/ci'
import { useData } from '../../../ContextProvider/Context';
import { useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';

function ChartAccountView() {
    const { getAccountsDataC, accountsDataC } = useData()
    useEffect(() => {
        getAccountsDataC()
    }, [])
    const handleDownloadCsv = () => {
        const visibleAccountsForExport = visibleAccountsData.map((item, idx) => ({
            index: indexOfFirstItem + idx + 1,
            name: item?.name,
            companyName: item?.company?.name,
            typeName: item?.account_type?.name,
            subtypeName: item?.account_subtype?.name,
            openingBalance: item?.opening_balance,
            description: item.description,
        }));
        const csv = Papa.unparse(visibleAccountsForExport);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'chartsofaccounts.csv');
    };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleAccountsData = accountsDataC?.data?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(accountsDataC?.data?.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button
                className='btn btn-primary p-2'
                style={{ margin: '0px 5px' }}
                key={i}
                onClick={() => handlePageChange(i)}
            >
                {i}
            </button>
        );
    }
    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
    });
    return (
        <>
            <div className='ChartAccountViewData'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><Link to={"/admin/chartsofaccounts"}>Charts of Accounts</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Run Report</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='d-flex justify-content-end mb-2 gap-2 align-items-end'>
                        <div>
                            <button onClick={handleDownloadCsv} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><CiImport style={{ fontSize: "20px" }} />Export</button>
                        </div>
                        <div>
                            <button onClick={handlePrint} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><CiImport style={{ fontSize: "20px" }} />Print</button>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div>
                        <div className='card'>
                            <div className="card-body table-responsive recruitment">
                                <table className="table" ref={contentRef}>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Account Type</th>
                                            <th scope="col">Detail Type</th>
                                            <th scope="col">Company</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {visibleAccountsData?.map((item, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.account_type?.name}</td>
                                                    <td>{item?.account_subtype?.name}</td>
                                                    <td>{item?.company?.name}</td>
                                                    <td style={{ maxWidth: '150px', overflowWrap: 'break-word', whiteSpace: 'pre-line' }}>
                                                        {item.description}
                                                    </td>
                                                    <td>{item?.opening_balance}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>

                                    {visibleAccountsData?.length === 0 && (
                                        <tr>
                                            <td colSpan="9" className="text-center">
                                                No data available
                                            </td>
                                        </tr>
                                    )}
                                </table>
                                <div className="pagination-controls">{paginationControls}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChartAccountView
