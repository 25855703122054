import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import logo from '../Images/pngwing.com.png';
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useData } from '../ContextProvider/Context';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ShowAlert from './ShowAlert';
function Signin_form() {
  const navigate = useNavigate()
  const { user, loginDetail, login } = useData()
  const [emailError, setEmailError] = useState('');

  const logins = JSON.parse(localStorage.getItem("user_auth"))
  useEffect(() => {
    if (login?.token) {

      navigate("/admin/dashboard")
    }
  }, [])
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      email: data.get('email'),
      password: data.get('password')
    };
    if (emailError !== "Please enter a valid email address") {
      loginDetail(formData);
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (login?.token) {
      localStorage.setItem("user_auth", JSON.stringify(login))
      navigate("/admin/dashboard")
    }
    else {
      // Handle the case where there's no token in the login response
      console.error("No token found in login response.");
    }
  }, [login]);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  return (

    <div style={{ height: "100vh", width: "100%", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(to right , #aacbff ,#fcfcfcc0)" }}>

      <Grid container component="main" className='main_loginPage' style={{ height: "80%", width: "80%", borderRadius: "10px" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          className='login_main'
        >
          <svg version="1.1" height={500} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" style={{ enableBackground: 'new 0 0 500 500' }} xmlSpace="preserve">
            <g id="Background_Complete">
              <g>
                <rect y="382.398" style={{ fill: '#EBEBEB' }} width={500} height="0.25" />
                <rect x="416.779" y="398.494" style={{ fill: '#EBEBEB' }} width="33.122" height="0.25" />
                <rect x="322.527" y="401.208" style={{ fill: '#EBEBEB' }} width="8.693" height="0.25" />
                <rect x="396.586" y="389.208" style={{ fill: '#EBEBEB' }} width="19.192" height="0.25" />
                <rect x="52.459" y="390.888" style={{ fill: '#EBEBEB' }} width="43.193" height="0.25" />
                <rect x="104.556" y="390.888" style={{ fill: '#EBEBEB' }} width="6.333" height="0.25" />
                <rect x="131.471" y="395.111" style={{ fill: '#EBEBEB' }} width="93.676" height="0.25" />
                <path style={{ fill: '#EBEBEB' }} d="M237.014,337.8H43.915c-3.147,0-5.708-2.561-5.708-5.708V60.66c0-3.147,2.561-5.708,5.708-5.708
			h193.099c3.146,0,5.707,2.561,5.707,5.708v271.432C242.721,335.239,240.16,337.8,237.014,337.8z M43.915,55.203
			c-3.01,0-5.458,2.448-5.458,5.458v271.432c0,3.01,2.448,5.458,5.458,5.458h193.099c3.009,0,5.457-2.448,5.457-5.458V60.66
			c0-3.009-2.448-5.458-5.457-5.458H43.915z" />
                <path style={{ fill: '#EBEBEB' }} d="M453.31,337.8H260.212c-3.147,0-5.707-2.561-5.707-5.708V60.66c0-3.147,2.561-5.708,5.707-5.708
			H453.31c3.148,0,5.708,2.561,5.708,5.708v271.432C459.019,335.239,456.458,337.8,453.31,337.8z M260.212,55.203
			c-3.009,0-5.457,2.448-5.457,5.458v271.432c0,3.01,2.448,5.458,5.457,5.458H453.31c3.01,0,5.458-2.448,5.458-5.458V60.66
			c0-3.009-2.448-5.458-5.458-5.458H260.212z" />
              </g>
              <g>
                <g>
                  <rect x="79.341" y="102.673" style={{ fill: '#EBEBEB' }} width="169.516" height="189.916" />
                  <rect x="82.668" y="105.736" style={{ fill: '#FAFAFA' }} width="166.189" height="183.489" />
                  <g>
                    <polygon style={{ fill: '#F0F0F0' }} points="88.807,203.129 88.807,190.237 92.491,190.237 92.491,181.642 97.402,181.642 
					97.402,168.75 98.63,168.75 98.63,181.642 103.541,181.642 103.541,190.237 107.225,190.237 107.225,203.129 113.978,203.129 
					113.978,206.812 117.047,206.812 117.047,220.932 117.661,220.932 117.661,200.673 119.503,200.673 119.503,213.565 
					121.344,213.565 121.344,182.87 122.572,182.87 122.572,195.762 127.483,195.762 127.483,191.464 129.325,191.464 
					129.325,173.661 133.009,173.661 133.009,155.858 134.236,155.858 134.236,158.314 159.407,158.314 159.407,150.947 
					163.704,150.947 163.704,122.707 165.546,122.707 165.546,150.947 171.685,150.947 171.685,158.314 174.141,158.314 
					174.141,195.148 187.033,195.148 187.033,184.098 182.735,184.098 182.735,182.256 187.033,182.256 187.033,179.186 
					199.311,179.186 199.311,150.947 202.38,150.947 202.38,146.649 217.114,146.649 217.114,150.947 218.956,150.947 
					218.956,190.237 225.709,190.237 225.709,208.04 229.392,208.04 229.392,193.92 232.462,193.92 232.462,190.237 
					239.215,190.237 239.215,177.345 242.898,177.345 242.898,168.75 247.195,168.75 247.195,155.858 248.857,155.858 
					248.857,289.225 82.668,289.225 82.668,203.129 				" />
                    <g>
                      <rect x="148.267" y="160.238" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="148.267" y="163.154" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="148.267" y="174.819" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="148.267" y="177.735" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="148.267" y="180.651" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="148.267" y="189.399" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="148.267" y="192.315" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="151.03" y="171.902" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="151.03" y="174.819" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="151.03" y="177.735" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="151.03" y="180.651" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="151.03" y="183.567" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="151.03" y="189.399" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="151.03" y="192.315" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="153.792" y="160.238" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="153.792" y="163.154" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="153.792" y="166.07" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="153.792" y="168.986" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="153.792" y="171.902" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="153.792" y="174.819" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="153.792" y="177.735" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="153.792" y="180.651" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="153.792" y="183.567" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="153.792" y="186.483" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="153.792" y="192.315" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="156.555" y="160.238" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="156.555" y="163.154" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="156.555" y="166.07" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="156.555" y="168.986" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="156.555" y="171.902" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="156.555" y="174.819" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="156.555" y="177.735" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="156.555" y="203.979" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="159.318" y="160.238" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="159.318" y="163.154" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="159.318" y="166.07" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="159.318" y="168.986" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="159.318" y="171.902" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="159.318" y="174.819" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="159.318" y="177.735" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="159.318" y="180.651" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="159.318" y="198.147" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="159.318" y="201.063" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="159.318" y="203.979" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="159.318" y="206.895" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="159.318" y="218.56" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="162.08" y="160.238" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="162.08" y="163.154" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="162.08" y="166.07" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="162.08" y="168.986" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="162.08" y="171.902" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="162.08" y="174.819" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="162.08" y="177.735" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="162.08" y="180.651" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="162.08" y="183.567" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="162.08" y="186.483" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="162.08" y="195.231" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="162.08" y="198.147" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="162.08" y="201.063" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="162.08" y="203.979" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="162.08" y="206.895" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="162.08" y="218.56" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="164.843" y="160.238" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="164.843" y="163.154" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="164.843" y="166.07" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="164.843" y="168.986" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="164.843" y="171.902" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="164.843" y="174.819" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="164.843" y="177.735" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="164.843" y="180.651" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="164.843" y="183.567" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="164.843" y="186.483" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="164.843" y="189.399" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="164.843" y="192.315" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="164.843" y="195.231" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="164.843" y="198.147" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="164.843" y="201.063" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="164.843" y="203.979" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="164.843" y="206.895" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="164.843" y="209.811" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="167.605" y="160.238" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="167.605" y="163.154" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="167.605" y="166.07" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="167.605" y="168.986" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="167.605" y="171.902" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="167.605" y="174.819" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="167.605" y="177.735" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="167.605" y="180.651" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="167.605" y="183.567" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="167.605" y="186.483" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="167.605" y="195.231" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="167.605" y="198.147" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="167.605" y="201.063" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="167.605" y="203.979" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="170.368" y="160.238" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="170.368" y="163.154" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="170.368" y="166.07" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="170.368" y="168.986" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="170.368" y="171.902" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="170.368" y="174.819" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="170.368" y="177.735" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="170.368" y="180.651" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="170.368" y="183.567" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="170.368" y="186.483" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="170.368" y="195.231" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="170.368" y="198.147" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="170.368" y="201.063" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="201.472" y="152.667" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="201.472" y="155.583" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="201.472" y="158.499" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="201.472" y="161.415" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="201.472" y="164.331" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="201.472" y="167.247" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="201.472" y="170.163" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="201.472" y="173.079" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="201.472" y="181.827" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="201.472" y="184.743" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="204.235" y="152.667" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="204.235" y="155.583" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="204.235" y="158.499" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="204.235" y="161.415" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="204.235" y="164.331" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="204.235" y="167.247" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="204.235" y="170.163" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="204.235" y="173.079" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="204.235" y="184.743" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="206.998" y="152.667" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="206.998" y="155.583" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="206.998" y="158.499" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="206.998" y="161.415" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="206.998" y="164.331" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="206.998" y="167.247" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="206.998" y="170.163" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="206.998" y="173.079" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="152.667" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="155.583" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="158.499" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="161.415" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="164.331" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="209.76" y="167.247" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="173.079" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="175.995" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="184.743" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="187.66" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="190.576" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="193.492" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="196.408" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="199.324" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="209.76" y="202.24" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="152.667" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="155.583" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="158.499" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="161.415" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="164.331" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="212.523" y="167.247" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="175.995" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="178.911" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="181.827" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="184.743" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="187.66" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="190.576" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="193.492" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="196.408" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="199.324" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="202.24" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="205.156" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="212.523" y="210.988" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="152.667" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="155.583" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="158.499" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="161.415" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="164.331" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="215.285" y="167.247" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="175.995" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="178.911" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="181.827" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="184.743" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="193.492" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="196.408" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="199.324" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="202.24" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="205.156" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="210.988" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="215.285" y="213.904" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="240.353" y="179.781" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="240.353" y="182.697" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="240.353" y="185.613" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="240.353" y="188.529" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="240.353" y="191.445" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="240.353" y="194.361" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="240.353" y="197.277" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="240.353" y="206.026" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="240.353" y="208.942" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="240.353" y="211.858" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="243.116" y="179.781" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="243.116" y="182.697" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="243.116" y="185.613" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="243.116" y="188.529" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="243.116" y="191.445" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="243.116" y="194.361" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="243.116" y="197.277" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="245.878" y="179.781" style={{ fill: '#FAFAFA' }} width="2.498" height="1.739" />
                      <rect x="245.878" y="182.697" style={{ fill: '#FAFAFA' }} width="2.498" height="1.739" />
                      <rect x="245.878" y="185.613" style={{ fill: '#FAFAFA' }} width="2.498" height="1.739" />
                      <rect x="245.878" y="188.529" style={{ fill: '#FAFAFA' }} width="2.498" height="1.739" />
                      <rect x="245.878" y="191.445" style={{ fill: '#FAFAFA' }} width="2.498" height="1.739" />
                      <rect x="245.878" y="194.361" style={{ fill: '#FAFAFA' }} width="2.498" height="1.739" />
                      <rect x="245.878" y="197.277" style={{ fill: '#FAFAFA' }} width="2.498" height="1.739" />
                      <rect x="93.936" y="183.567" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="93.936" y="186.483" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="93.936" y="189.399" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="93.936" y="192.315" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="93.936" y="195.231" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="93.936" y="198.147" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="93.936" y="201.063" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="93.936" y="203.979" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="93.936" y="206.895" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="93.936" y="209.811" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="93.936" y="212.727" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="93.936" y="215.643" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="93.936" y="218.56" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="93.936" y="221.476" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="93.936" y="224.392" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="93.936" y="227.308" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="93.936" y="230.224" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="93.936" y="233.14" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="93.936" y="236.056" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="93.936" y="238.972" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="96.699" y="183.567" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="96.699" y="186.483" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="96.699" y="189.399" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="96.699" y="192.315" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="96.699" y="195.231" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="96.699" y="198.147" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="96.699" y="201.063" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="96.699" y="203.979" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="96.699" y="206.895" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="96.699" y="209.811" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="96.699" y="212.727" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="96.699" y="215.643" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="96.699" y="218.56" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="96.699" y="221.476" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="96.699" y="224.392" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="96.699" y="227.308" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="96.699" y="230.224" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="96.699" y="233.14" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="99.462" y="183.567" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="99.462" y="186.483" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="99.462" y="189.399" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="99.462" y="192.315" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="99.462" y="195.231" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="99.462" y="198.147" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="99.462" y="201.063" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="99.462" y="203.979" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="99.462" y="206.895" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="99.462" y="209.811" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="99.462" y="212.727" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="99.462" y="215.643" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="99.462" y="218.56" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="99.462" y="221.476" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="99.462" y="224.392" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="99.462" y="227.308" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="99.462" y="230.224" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="99.462" y="233.14" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="83.602" y="204.849" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="83.602" y="207.765" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="83.602" y="210.681" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="83.602" y="213.597" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="83.602" y="216.513" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="83.602" y="219.429" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="83.602" y="222.345" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="83.602" y="225.261" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="83.602" y="228.177" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="83.602" y="231.094" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="83.602" y="234.01" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="83.602" y="236.926" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="83.602" y="239.842" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="204.849" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="207.765" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="210.681" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="213.597" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="216.513" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="219.429" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="222.345" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="225.261" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="228.177" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="231.094" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="234.01" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="236.926" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="86.365" y="239.842" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="204.849" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="207.765" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="210.681" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="213.597" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="216.513" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="219.429" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="222.345" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="225.261" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="228.177" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="231.094" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="234.01" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="236.926" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="239.842" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="89.127" y="242.758" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="104.475" y="205.719" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="104.475" y="208.635" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="104.475" y="211.551" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="104.475" y="214.467" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="104.475" y="217.383" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="104.475" y="220.299" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="104.475" y="223.215" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="104.475" y="226.131" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="104.475" y="229.047" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="104.475" y="231.963" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="104.475" y="240.711" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="107.238" y="205.719" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="107.238" y="208.635" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="107.238" y="211.551" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="107.238" y="214.467" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="107.238" y="217.383" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="107.238" y="220.299" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="107.238" y="223.215" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="107.238" y="226.131" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="107.238" y="229.047" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="107.238" y="237.795" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="107.238" y="240.711" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x={110} y="205.719" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x={110} y="208.635" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x={110} y="211.551" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x={110} y="214.467" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x={110} y="217.383" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x={110} y="220.299" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x={110} y="223.215" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x={110} y="226.131" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x={110} y="234.879" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x={110} y="237.795" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x={110} y="240.711" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="135.682" y="161.415" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="164.331" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="135.682" y="167.247" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="170.163" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="173.079" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="175.995" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="178.911" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="181.827" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="184.743" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="187.66" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="190.576" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="193.492" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="196.408" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="199.324" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="202.24" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="205.156" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="208.072" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="213.904" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="216.82" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="219.736" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="222.652" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="135.682" y="225.568" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="161.415" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="164.331" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="138.445" y="167.247" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="170.163" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="173.079" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="175.995" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="178.911" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="181.827" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="184.743" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="187.66" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="190.576" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="193.492" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="196.408" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="199.324" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="202.24" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="205.156" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="222.652" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="138.445" y="225.568" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="161.415" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="164.331" style={{ fill: '#FAFAFA' }} width="1.739" height="1.74" />
                      <rect x="141.207" y="167.247" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="170.163" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="173.079" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="175.995" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="178.911" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="181.827" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="184.743" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="187.66" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="190.576" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="193.492" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="196.408" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="199.324" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="202.24" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="205.156" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="210.988" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="213.904" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="216.82" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="219.736" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="141.207" y="225.568" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="125.655" y="198.147" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="125.655" y="201.063" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="125.655" y="203.979" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="125.655" y="206.895" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="125.655" y="209.811" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="125.655" y="212.727" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="125.655" y="215.643" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="125.655" y="221.476" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="125.655" y="224.392" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="125.655" y="227.308" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="125.655" y="230.224" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="125.655" y="233.14" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="125.655" y="236.056" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="125.655" y="238.972" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="125.655" y="241.888" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="128.417" y="198.147" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="128.417" y="201.063" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="128.417" y="203.979" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="128.417" y="206.895" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="128.417" y="209.811" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="128.417" y="212.727" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="128.417" y="221.476" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="128.417" y="224.392" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="128.417" y="227.308" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="128.417" y="230.224" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="128.417" y="233.14" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="128.417" y="236.056" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="128.417" y="238.972" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="128.417" y="241.888" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="131.18" y="198.147" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="131.18" y="201.063" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="131.18" y="203.979" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="131.18" y="206.895" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="131.18" y="209.811" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="131.18" y="212.727" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="131.18" y="218.56" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="131.18" y="221.476" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="131.18" y="224.392" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="131.18" y="227.308" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="131.18" y="230.224" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="131.18" y="233.14" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="131.18" y="236.056" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="131.18" y="238.972" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="131.18" y="241.888" style={{ fill: '#FAFAFA' }} width="1.74" height="1.74" />
                      <rect x="189.399" y="181.827" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="184.743" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="187.66" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="190.576" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="193.492" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="196.408" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="199.324" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="202.24" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="205.156" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="208.072" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="210.988" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="213.904" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="216.82" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="222.652" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="225.568" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="228.484" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="231.4" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="237.233" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="240.149" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="189.399" y="243.065" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="181.827" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="184.743" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="187.66" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="190.576" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="193.492" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="196.408" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="199.324" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="202.24" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="205.156" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="208.072" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="210.988" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="213.904" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="216.82" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="225.568" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="228.484" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="234.316" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="237.233" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="240.149" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="192.162" y="243.065" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="181.827" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="184.743" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="187.66" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="190.576" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="193.492" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="196.408" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="199.324" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="202.24" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="205.156" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="208.072" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="210.988" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="213.904" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="216.82" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="219.736" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="225.568" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="231.4" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="234.316" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="237.233" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="240.149" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="194.924" y="243.065" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="178.144" y="198.454" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="178.144" y="201.37" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="178.144" y="204.286" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="178.144" y="207.202" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="178.144" y="210.118" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="178.144" y="213.034" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="178.144" y="221.783" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="178.144" y="224.699" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="178.144" y="227.615" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="178.144" y="230.531" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="178.144" y="233.447" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="178.144" y="236.363" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="178.144" y="239.279" style={{ fill: '#FAFAFA' }} width="1.74" height="1.739" />
                      <rect x="180.907" y="198.454" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="180.907" y="201.37" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="180.907" y="204.286" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="180.907" y="207.202" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="180.907" y="210.118" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="180.907" y="213.034" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="180.907" y="221.783" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="180.907" y="224.699" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="180.907" y="227.615" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="180.907" y="230.531" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="180.907" y="233.447" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="180.907" y="236.363" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                      <rect x="180.907" y="242.195" style={{ fill: '#FAFAFA' }} width="1.739" height="1.739" />
                    </g>
                  </g>
                  <g>
                    <rect x="245.403" y="103.103" style={{ fill: '#EBEBEB' }} width="3.454" height="189.487" />
                    <rect x="190.483" y="103.103" style={{ fill: '#EBEBEB' }} width="2.922" height="189.487" />
                    <rect x="135.563" y="103.103" style={{ fill: '#EBEBEB' }} width="2.922" height="189.487" />
                  </g>
                </g>
                <g>
                  <circle style={{ fill: '#E0E0E0' }} cx="344.897" cy="96.434" r="14.168" />
                  <path style={{ fill: '#F0F0F0' }} d="M356.944,96.434c0,6.653-5.393,12.046-12.046,12.046c-6.653,0-12.046-5.393-12.046-12.046
				c0-6.653,5.393-12.046,12.046-12.046C351.551,84.388,356.944,89.781,356.944,96.434z" />
                  <polyline style={{ fill: '#F0F0F0', stroke: '#E0E0E0', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }} points="
				344.329,88.17 344.329,96.591 338.749,93.433 			" />
                </g>
                <g>
                  <rect x="352.983" y="119.304" style={{ fill: '#F5F5F5' }} width="62.637" height="82.682" />
                  <polygon style={{ fill: '#EBEBEB' }} points="374.285,128.098 374.285,164.449 388.767,163.635 390.43,128.098 			" />
                  <polygon style={{ fill: '#E0E0E0' }} points="386.011,135.134 408.291,135.134 408.095,166.954 396.557,167.134 			" />
                  <polygon style={{ fill: '#E6E6E6' }} points="364.318,130.444 370.181,130.444 368.129,158.833 360.8,160.645 			" />
                </g>
                <g>
                  <rect x="281.359" y="136.087" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 625.1616 348.156)" style={{ fill: '#E6E6E6' }} width="62.444" height="75.982" />
                  <polygon style={{ fill: '#E0E0E0' }} points="295.646,193.249 328.461,160.997 292.959,155.139 			" />
                  <polygon style={{ fill: '#F0F0F0' }} points="327.668,185.603 329.293,160.872 304.587,166.94 302.792,185.864 			" />
                  <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.1285 253.0112)" style={{ fill: '#FAFAFA' }} cx="295.347" cy="150.803" rx="9.974" ry="9.974" />
                  <path style={{ fill: '#E0E0E0' }} d="M324.441,194.422c0-2.671,2.166-4.837,4.837-4.837c2.671,0,4.837,2.166,4.837,4.837
				s-2.166,4.837-4.837,4.837C326.607,199.259,324.441,197.093,324.441,194.422z" />
                  <polygon style={{ fill: '#F5F5F5' }} points="328.13,202.923 314.162,194.422 325.904,184.747 			" />
                  <rect x="308.886" y="145.752" style={{ fill: '#E0E0E0' }} width="2.745" height="60.672" />
                  <rect x="334.387" y="145.752" style={{ fill: '#EBEBEB' }} width="3.552" height="57.988" />
                  <rect x="287.627" y="185.864" style={{ fill: '#EBEBEB' }} width="18.15" height="18.15" />
                </g>
                <g>
                  <rect x="345.772" y="313.38" style={{ fill: '#E6E6E6' }} width="65.623" height="59.627" />
                  <rect x="413.165" y="308.125" style={{ fill: '#E6E6E6' }} width="8.249" height="74.047" />
                  <rect x="356.218" y="318.94" style={{ fill: '#FAFAFA' }} width="5.377" height="21.393" />
                  <rect x="378.512" y="318.94" style={{ fill: '#F5F5F5' }} width="5.377" height="21.393" />
                  <rect x="361.595" y="320.796" style={{ fill: '#F5F5F5' }} width="5.377" height="19.538" />
                  <rect x="373.135" y="320.796" style={{ fill: '#FAFAFA' }} width="5.377" height="19.538" />
                  <rect x="369.66" y="318.94" style={{ fill: '#EBEBEB' }} width="3.475" height="21.393" />
                  <rect x="366.972" y="320.796" style={{ fill: '#FAFAFA' }} width="2.688" height="19.538" />
                  <rect x="383.889" y="318.94" style={{ fill: '#EBEBEB' }} width="5.377" height="21.393" />
                  <rect x="389.266" y="320.796" style={{ fill: '#E6E6E6' }} width="5.377" height="19.538" />
                  <rect x="400.806" y="320.796" style={{ fill: '#E6E6E6' }} width="5.377" height="19.538" />
                  <rect x="397.331" y="318.94" style={{ fill: '#FAFAFA' }} width="3.475" height="21.393" />
                  <rect x="394.643" y="320.796" style={{ fill: '#EBEBEB' }} width="2.688" height="19.538" />
                  <rect x="406.183" y="319.566" style={{ fill: '#FAFAFA' }} width="2.688" height="20.767" />
                  <rect x="403.494" y="349.315" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 812.3655 719.0225)" style={{ fill: '#FAFAFA' }} width="5.377" height="20.392" />
                  <rect x="381.2" y="349.315" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 767.7776 719.0225)" style={{ fill: '#FAFAFA' }} width="5.377" height="20.392" />
                  <rect x="398.117" y="351.17" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 801.6117 720.8775)" style={{ fill: '#F0F0F0' }} width="5.377" height="18.537" />
                  <rect x="386.577" y="351.17" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 778.5314 720.8775)" style={{ fill: '#EBEBEB' }} width="5.377" height="18.537" />
                  <rect x="391.954" y="349.315" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 787.3831 719.0225)" style={{ fill: '#FAFAFA' }} width="3.475" height="20.392" />
                  <rect x="395.429" y="351.17" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 793.5463 720.8775)" style={{ fill: '#F5F5F5' }} width="2.688" height="18.537" />
                  <rect x="375.823" y="349.315" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 757.0238 719.0225)" style={{ fill: '#EBEBEB' }} width="5.377" height="20.392" />
                  <rect x="370.446" y="351.17" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 746.2698 720.8775)" style={{ fill: '#F5F5F5' }} width="5.377" height="18.537" />
                  <rect x="358.906" y="351.17" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 723.1896 720.8775)" style={{ fill: '#E6E6E6' }} width="5.377" height="18.537" />
                  <rect x="364.283" y="349.315" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 732.0412 719.0225)" style={{ fill: '#FAFAFA' }} width="3.475" height="20.392" />
                  <rect x="352.743" y="349.315" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 708.9612 719.0225)" style={{ fill: '#FAFAFA' }} width="3.475" height="20.392" />
                  <rect x="367.758" y="351.17" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 738.2043 720.8775)" style={{ fill: '#EBEBEB' }} width="2.688" height="18.537" />
                  <rect x="356.218" y="349.94" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 715.1242 719.6477)" style={{ fill: '#F0F0F0' }} width="2.688" height="19.767" />
                  <path style={{ fill: '#F0F0F0' }} d="M411.064,308.125h-2.2h-65.171v69.281h65.171v4.766h4.399v-74.047H411.064z M408.864,369.707
				h-61.022v-24.946h61.022V369.707z M408.864,340.362h-61.022v-24.538h61.022V340.362z" />
                  <rect x="280.041" y="313.38" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 625.706 686.387)" style={{ fill: '#E6E6E6' }} width="65.623" height="59.627" />
                  <rect x="282.565" y="376.542" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 573.3783 758.7145)" style={{ fill: '#E6E6E6' }} width="8.249" height="5.63" />
                  <rect x="338.077" y="318.94" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 681.5305 659.2737)" style={{ fill: '#FAFAFA' }} width="5.377" height="21.393" />
                  <rect x="315.783" y="318.94" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 636.9424 659.2737)" style={{ fill: '#F5F5F5' }} width="5.377" height="21.393" />
                  <rect x="332.7" y="320.796" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 670.7765 661.1287)" style={{ fill: '#F5F5F5' }} width="5.377" height="19.538" />
                  <rect x="321.16" y="320.796" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 647.6963 661.1287)" style={{ fill: '#FAFAFA' }} width="5.377" height="19.538" />
                  <rect x="326.537" y="318.94" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 656.5478 659.2737)" style={{ fill: '#EBEBEB' }} width="3.475" height="21.393" />
                  <rect x="330.011" y="320.796" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 662.7109 661.1287)" style={{ fill: '#FAFAFA' }} width="2.688" height="19.538" />
                  <rect x="313.951" y="318.94" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 633.2779 659.2737)" style={{ fill: '#EBEBEB' }} width="5.377" height="21.393" />
                  <rect x="308.573" y="320.796" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 622.524 661.1287)" style={{ fill: '#E6E6E6' }} width="5.377" height="19.538" />
                  <rect x="297.034" y="320.796" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 599.444 661.1287)" style={{ fill: '#E6E6E6' }} width="5.377" height="19.538" />
                  <rect x="302.41" y="318.94" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 608.2956 659.2737)" style={{ fill: '#FAFAFA' }} width="3.475" height="21.393" />
                  <rect x="305.885" y="320.796" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 614.4586 661.1287)" style={{ fill: '#EBEBEB' }} width="2.688" height="19.538" />
                  <rect x="294.345" y="319.566" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 591.3785 659.8989)" style={{ fill: '#F5F5F5' }} width="2.688" height="20.767" />
                  <rect x="291.819" y="349.315" style={{ fill: '#FAFAFA' }} width="5.377" height="20.392" />
                  <rect x="314.113" y="349.315" style={{ fill: '#FAFAFA' }} width="5.377" height="20.392" />
                  <rect x="297.196" y="351.17" style={{ fill: '#F5F5F5' }} width="5.377" height="18.537" />
                  <rect x="308.737" y="351.17" style={{ fill: '#EBEBEB' }} width="5.377" height="18.537" />
                  <rect x="305.262" y="349.315" style={{ fill: '#FAFAFA' }} width="3.475" height="20.392" />
                  <rect x="302.573" y="351.17" style={{ fill: '#F5F5F5' }} width="2.688" height="18.537" />
                  <rect x="315.946" y="349.315" style={{ fill: '#EBEBEB' }} width="5.377" height="20.392" />
                  <rect x="321.323" y="351.17" style={{ fill: '#F5F5F5' }} width="5.377" height="18.537" />
                  <rect x="332.863" y="351.17" style={{ fill: '#E6E6E6' }} width="5.377" height="18.537" />
                  <rect x="329.388" y="349.315" style={{ fill: '#FAFAFA' }} width="3.475" height="20.392" />
                  <rect x="340.219" y="349.315" style={{ fill: '#FAFAFA' }} width="3.475" height="20.392" />
                  <rect x="326.7" y="351.17" style={{ fill: '#EBEBEB' }} width="2.688" height="18.537" />
                  <rect x="338.24" y="349.94" style={{ fill: '#EBEBEB' }} width="2.688" height="19.767" />
                  <path style={{ fill: '#F0F0F0' }} d="M278.172,308.125v74.047h4.399v-4.766h65.171v-69.281h-65.171h-2.199H278.172z M282.572,344.762
				h61.022v24.946h-61.022V344.762z M282.572,315.824h61.022v24.538h-61.022V315.824z" />
                </g>
              </g>
            </g>
            <g id="Background_Simple" style={{ display: 'none' }}>
              <g style={{ display: 'inline' }}>
                <path style={{ fill: '#407BFF' }} d="M249.546,102.454c-72.151,32.462-6.653,90.318-48.313,121.828
			c-41.66,31.509-154.928-4.917-111.749,76.905c50.72,96.112,272.827,82.766,320.716-35.122
			C458.089,148.178,321.697,69.992,249.546,102.454z" />
                <path style={{ opacity: '0.9', fill: '#FFFFFF' }} d="M249.546,102.454c-72.151,32.462-6.653,90.318-48.313,121.828
			c-41.66,31.509-154.928-4.917-111.749,76.905c50.72,96.112,272.827,82.766,320.716-35.122
			C458.089,148.178,321.697,69.992,249.546,102.454z" />
              </g>
            </g>
            <g id="Shadow_1_">
              <ellipse id="_x3C_Path_x3E__359_" style={{ fill: '#F5F5F5' }} cx={250} cy="416.238" rx="193.889" ry="11.323" />
            </g>
            <g id="Plant">
              <g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M382.047,372.624c0,0-7.753-9.228-12.715-4.924c-4.962,4.304-2.452,13.433,3.271,13.727
				c5.723,0.294,9.444-2.885,9.444-2.885V372.624z" />
                  <path style={{ opacity: '0.2', fill: '#FFFFFF', enableBackground: 'new' }} d="M382.047,372.624c0,0-7.753-9.228-12.715-4.924
				c-4.962,4.304-2.452,13.433,3.271,13.727c5.723,0.294,9.444-2.885,9.444-2.885V372.624z" />
                  <path style={{ fill: '#407BFF' }} d="M413.68,349.221c0,0,6.513-6.822,10.544-5.665c4.032,1.157,3.612,17.455-8.893,10.856
				L413.68,349.221z" />
                  <path style={{ opacity: '0.3', fill: '#FFFFFF', enableBackground: 'new' }} d="M413.68,349.221c0,0,6.513-6.822,10.544-5.665
				c4.032,1.157,3.612,17.455-8.893,10.856L413.68,349.221z" />
                  <path style={{ fill: '#407BFF' }} d="M390.421,400.114c0,0-21.398-29.274-4.032-35.205c17.367-5.931,20.468,23.553,15.506,31.76
				S390.421,400.114,390.421,400.114z" />
                  <path style={{ fill: '#407BFF' }} d="M400.345,340.179c0,0,13.274-30.821-6.388-31.751c-19.662-0.93-21.804,30.56,0.821,35.128
				L400.345,340.179z" />
                  <path style={{ opacity: '0.3', fill: '#FFFFFF', enableBackground: 'new' }} d="M390.421,400.114c0,0-21.398-29.274-4.032-35.205
				c17.367-5.931,20.468,23.553,15.506,31.76S390.421,400.114,390.421,400.114z" />
                  <path style={{ opacity: '0.3', fill: '#FFFFFF', enableBackground: 'new' }} d="M400.345,340.179c0,0,13.274-30.821-6.388-31.751
				c-19.662-0.93-21.804,30.56,0.821,35.128L400.345,340.179z" />
                  <path style={{ fill: '#407BFF' }} d="M395.635,365.414c0,0-6.455-27.213,5.95-29.384c12.405-2.171,24.65,22.417-1.01,34.778
				C400.575,370.807,396.173,368.077,395.635,365.414z" />
                  <path style={{ opacity: '0.2', fill: '#FFFFFF', enableBackground: 'new' }} d="M395.635,365.414c0,0-6.455-27.213,5.95-29.384
				c12.405-2.171,24.65,22.417-1.01,34.778C400.575,370.807,396.173,368.077,395.635,365.414z" />
                </g>
                <g>
                  <polygon style={{ fill: '#263238' }} points="375.017,395.962 410.945,395.962 407.945,418.949 378.017,418.949 			" />
                </g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M393.704,337.977c-1.888-1.356-3.52-3.05-4.97-4.882c-1.452-1.836-2.639-3.89-3.523-6.078
				c-0.876-2.194-1.303-4.573-1.28-6.929c-0.039-2.377,0.687-4.674,1.606-6.784c-0.122,0.571-0.251,1.134-0.39,1.69l-0.202,0.834
				c-0.04,0.283-0.057,0.571-0.087,0.855c-0.06,0.567-0.145,1.13-0.192,1.693l0.035,1.7c0.158,2.248,0.583,4.482,1.442,6.578
				c0.825,2.104,1.94,4.093,3.21,5.989c0.633,0.95,1.323,1.867,2.061,2.745C392.12,336.296,392.899,337.142,393.704,337.977z" />
                </g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M388.994,311.528c1.119,0.181,2.18,0.62,3.217,1.1c0.996,0.553,1.98,1.165,2.836,1.938
				c1.759,1.5,3.089,3.471,3.997,5.592c0.895,2.142,1.261,4.443,1.373,6.719c0.018,1.139-0.068,2.277-0.111,3.407
				c-0.195,1.121-0.356,2.239-0.581,3.342c0-1.134,0.062-2.251,0.091-3.367c-0.057-1.115-0.058-2.23-0.167-3.331
				c-0.208-1.088-0.219-2.205-0.555-3.257c-0.144-0.529-0.231-1.075-0.405-1.593l-0.569-1.537
				C396.467,316.517,393.221,313.138,388.994,311.528z" />
                </g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M404.031,337.977c2.886,1.029,5.237,3.418,6.515,6.257c0.645,1.418,1.068,2.95,1.223,4.499
				c0.076,0.382,0.065,0.782,0.063,1.18c-0.012,0.396,0.024,0.8-0.031,1.191c-0.132,0.778-0.205,1.574-0.468,2.315
				c-0.411,1.522-1.14,2.909-1.884,4.262c-0.829,1.303-1.681,2.592-2.696,3.745c-1.967,2.353-4.194,4.471-6.648,6.274
				c1.092-1.059,2.162-2.133,3.158-3.273c1.019-1.116,1.998-2.267,2.887-3.479c1.788-2.403,3.412-4.978,4.223-7.808
				c0.258-0.694,0.324-1.42,0.457-2.136c0.056-0.359,0.021-0.721,0.038-1.082c0.006-0.361,0.022-0.721-0.037-1.09
				c-0.09-1.481-0.481-2.916-0.988-4.297c-0.53-1.377-1.31-2.661-2.299-3.773C406.574,339.63,405.384,338.668,404.031,337.977z" />
                </g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M400.345,340.179c0.729,1.997,1.183,4.092,1.376,6.232c0.12,1.069,0.093,2.157-0.023,3.247
				c-0.124,1.085-0.41,2.195-1.021,3.188c-1.181,1.677-1.577,3.693-1.806,5.765c-0.12,1.033-0.148,2.082-0.147,3.131
				c-0.026,1.052,0.02,2.105,0.071,3.166c-0.514-2.063-0.741-4.196-0.691-6.346c0.02-1.076,0.148-2.155,0.397-3.226
				c0.147-0.531,0.298-1.063,0.539-1.573c0.174-0.508,0.583-1.034,0.79-1.448c0.517-0.799,0.791-1.786,0.934-2.788
				c0.162-1.004,0.2-2.039,0.193-3.079c-0.009-1.04-0.078-2.087-0.2-3.13C400.667,342.27,400.506,341.229,400.345,340.179z" />
                </g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M391.616,367.7c0.549,0.159,0.977,0.585,1.335,1.015c0.372,0.433,0.663,0.92,0.954,1.407
				c0.533,0.997,0.96,2.04,1.348,3.093c0.768,2.111,1.308,4.286,1.776,6.468c0.446,2.187,0.815,4.388,1.055,6.606
				c0.236,2.215,0.447,4.443,0.35,6.677c-0.158-1.104-0.326-2.198-0.534-3.29c-0.178-1.094-0.369-2.186-0.577-3.274
				c-0.417-2.176-0.808-4.354-1.272-6.514c-0.44-2.166-0.947-4.314-1.527-6.437c-0.293-1.061-0.632-2.108-1.058-3.115
				c-0.215-0.5-0.432-1.007-0.728-1.463C392.443,368.421,392.117,367.959,391.616,367.7z" />
                </g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M385.536,368.645c-0.577,1.996-0.88,4.036-1.008,6.068c-0.107,2.034,0.025,4.072,0.442,6.047
				c0.392,1.978,1.052,3.91,2.016,5.697c0.897,1.832,2.148,3.46,3.433,5.1c-0.388-0.343-0.772-0.695-1.148-1.057
				c-0.371-0.366-0.786-0.699-1.102-1.117c-0.659-0.813-1.356-1.613-1.862-2.543c-0.603-0.875-0.993-1.864-1.433-2.829
				c-0.204-0.489-0.329-1.008-0.497-1.511c-0.176-0.502-0.29-1.02-0.386-1.542c-0.418-2.079-0.51-4.224-0.237-6.315
				C384.018,372.555,384.597,370.504,385.536,368.645z" />
                </g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M418.731,352.544c1.16-0.336,2.208-0.599,3.184-1.021c0.495-0.193,0.939-0.463,1.389-0.749
				c0.457-0.289,0.838-0.67,1.309-1.073c-0.098,0.593-0.356,1.193-0.786,1.652c-0.415,0.47-0.945,0.844-1.518,1.088
				c-0.578,0.233-1.184,0.376-1.794,0.393C419.908,352.856,419.296,352.788,418.731,352.544z" />
                </g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M417.402,348.047c0.281-0.557,0.703-1.013,1.183-1.399c0.482-0.386,1.037-0.696,1.658-0.876
				c0.62-0.166,1.305-0.206,1.941-0.019c0.641,0.183,1.167,0.619,1.498,1.131c-0.599-0.19-1.112-0.347-1.636-0.363
				c-0.52-0.003-1.023,0.064-1.528,0.212c-0.51,0.128-1.008,0.356-1.523,0.576L417.402,348.047z" />
                </g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M378.505,374.001c-1.344-1.027-2.581-2.078-3.941-2.844c-0.668-0.396-1.379-0.694-2.11-0.783
				c-0.735-0.096-1.486,0.064-2.269,0.433c0.498-0.686,1.36-1.178,2.29-1.213c0.934-0.062,1.821,0.265,2.579,0.693
				c0.759,0.444,1.435,0.99,2.012,1.619C377.643,372.533,378.147,373.224,378.505,374.001z" />
                </g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M378.505,378.542c-0.936,0.356-1.919,0.551-2.913,0.643c-0.995,0.096-2.007,0.063-3.015-0.093
				c-1-0.164-2.02-0.514-2.867-1.161c-0.858-0.628-1.422-1.665-1.402-2.67c0.408,0.929,1.047,1.594,1.835,2.021
				c0.79,0.419,1.676,0.691,2.604,0.825C374.598,378.42,376.522,378.445,378.505,378.542z" />
                </g>
              </g>
            </g>
            <g id="Trash_bin">
              <g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M315.655,398.95c0.814-1.367-0.334-3.423-1.925-3.447c0.697-1.961,0.009-4.332-1.629-5.616
				c-0.207-0.162-0.444-0.314-0.707-0.314c-0.282-0.001-0.531,0.171-0.76,0.335c-1.499,1.077-2.998,2.154-4.497,3.231
				c-0.343,0.246-0.699,0.507-0.892,0.882c-0.919,1.781,2.395,4.063,1.032,5.533c-0.391,0.422-1.021,0.509-1.509,0.814
				c-1.07,0.669-1.199,2.138-1.698,3.298c-0.499,1.16-2.238,2.059-2.904,0.987c0.491-1.112-0.995-2.07-2.203-2.212
				c-1.191-0.139-2.468-0.207-3.136-1.34c-1.713,1.497-5.55-2.681-5.462-4.365c-1.408,0.107-2.367,1.426-2.676,2.745
				c-0.32,1.369-0.122,2.795-0.144,4.198c-0.043,2.728-0.923,5.399-0.875,8.127c0.038,2.123,0.857,4.479,2.801,5.333
				c0.935,0.411,1.992,0.411,3.014,0.405c7.376-0.047,14.752-0.094,22.128-0.142c0.531-0.004,1.085-0.012,1.556-0.258
				c0.998-0.521,1.209-1.832,1.302-2.955c0.396-4.807,0.792-9.614,1.188-14.422C316.989,399.495,316.322,399.222,315.655,398.95z" />
                  <path style={{ opacity: '0.3', fill: '#FFFFFF', enableBackground: 'new' }} d="M315.655,398.95c0.814-1.367-0.334-3.423-1.925-3.447
				c0.697-1.961,0.009-4.332-1.629-5.616c-0.207-0.162-0.444-0.314-0.707-0.314c-0.282-0.001-0.531,0.171-0.76,0.335
				c-1.499,1.077-2.998,2.154-4.497,3.231c-0.343,0.246-0.699,0.507-0.892,0.882c-0.919,1.781,2.395,4.063,1.032,5.533
				c-0.391,0.422-1.021,0.509-1.509,0.814c-1.07,0.669-1.199,2.138-1.698,3.298c-0.499,1.16-2.238,2.059-2.904,0.987
				c0.491-1.112-0.995-2.07-2.203-2.212c-1.191-0.139-2.468-0.207-3.136-1.34c-1.713,1.497-5.55-2.681-5.462-4.365
				c-1.408,0.107-2.367,1.426-2.676,2.745c-0.32,1.369-0.122,2.795-0.144,4.198c-0.043,2.728-0.923,5.399-0.875,8.127
				c0.038,2.123,0.857,4.479,2.801,5.333c0.935,0.411,1.992,0.411,3.014,0.405c7.376-0.047,14.752-0.094,22.128-0.142
				c0.531-0.004,1.085-0.012,1.556-0.258c0.998-0.521,1.209-1.832,1.302-2.955c0.396-4.807,0.792-9.614,1.188-14.422
				C316.989,399.495,316.322,399.222,315.655,398.95z" />
                </g>
                <g>
                  <g>
                    <path style={{ fill: '#407BFF' }} d="M316.946,375.561h-2.485h-6.841h-2.493h-6.832h-2.493h-6.833h-2.493h-3.864l0.282,3.469
					l0.202,2.477l0.524,6.446l0.299,3.703l0.395,4.929l0.169,2.114l0.056,0.629l0.201,2.485l0.274,3.404l0.17,2.114l0.177,2.146
					l0.202,2.485l0.153,1.88l0.169,2.114l0.073,0.92h4.517h3.816h5.51h3.816h5.51h3.816h4.526l0.073-0.92l0.169-2.106l0.153-1.896
					l0.202-2.485l0.178-2.138l0.169-2.114l0.274-3.412l0.202-2.477l0.048-0.629l0.169-2.114l0.403-4.929l0.299-3.695l0.516-6.454
					l0.202-2.477l0.282-3.469H316.946z M319.011,377.836L319.011,377.836l-0.662-0.662h0.718L319.011,377.836z M318.842,379.941
					l-0.064,0.839l-3.074,3.074l-3.525-3.525l3.154-3.154h0.742L318.842,379.941z M317.954,390.929l-2.251,2.251l-3.525-3.525
					l3.525-3.517l2.436,2.436L317.954,390.929z M285.283,388.573L285.283,388.573l2.436-2.436l3.525,3.517l-3.525,3.525
					l-2.243-2.251L285.283,388.573z M314.566,384.991l-3.525,3.525l-3.525-3.525l3.525-3.525L314.566,384.991z M309.024,377.174
					h4.034l-2.017,2.017L309.024,377.174z M309.895,389.654l-3.517,3.525l-3.525-3.525l3.525-3.517L309.895,389.654z
					 M298.19,394.317l3.525-3.517l3.517,3.517l-3.517,3.525L298.19,394.317z M300.57,398.98l-3.525,3.525l-3.517-3.517l3.525-3.525
					L300.57,398.98z M306.378,395.463l3.517,3.525l-3.517,3.517l-3.525-3.525L306.378,395.463z M301.715,388.517l-3.525-3.525
					l3.525-3.525l3.517,3.525L301.715,388.517z M300.57,389.654l-3.517,3.525l-3.525-3.525l3.525-3.517L300.57,389.654z
					 M292.382,388.517l-3.517-3.525l3.517-3.525l3.525,3.525L292.382,388.517z M292.382,390.8l3.525,3.517l-3.525,3.525
					l-3.517-3.525L292.382,390.8z M295.907,403.651l-3.525,3.517l-3.517-3.517l3.517-3.525L295.907,403.651z M297.044,404.788
					l3.525,3.525l-3.517,3.525l-3.525-3.525L297.044,404.788z M298.19,403.651l3.525-3.525l3.517,3.525l-3.517,3.517L298.19,403.651
					z M306.378,404.788l3.517,3.525l-3.517,3.525l-3.525-3.525L306.378,404.788z M311.041,409.451l3.525,3.525l-2.283,2.283h-2.485
					l-2.283-2.283L311.041,409.451z M307.516,403.651l3.525-3.525l3.525,3.525l-3.525,3.517L307.516,403.651z M307.516,394.317
					l3.525-3.517l3.525,3.517l-3.525,3.525L307.516,394.317z M309.895,380.329l-3.517,3.525l-3.525-3.525l3.154-3.154h0.742
					L309.895,380.329z M301.715,379.191l-2.017-2.017h4.026L301.715,379.191z M300.57,380.329l-3.517,3.525l-3.525-3.525
					l3.154-3.154h0.742L300.57,380.329z M294.398,377.174l-2.017,2.017l-2.008-2.017H294.398z M288.09,377.174l3.154,3.154
					l-3.525,3.525l-3.066-3.073l-0.065-0.839l2.759-2.767H288.09z M285.073,377.174l-0.654,0.654l0,0l-0.056-0.654H285.073z
					 M284.855,383.265l1.727,1.726l-1.468,1.468L284.855,383.265z M285.815,395.084l-0.137-1.67l0,0l0.904,0.904L285.815,395.084
					L285.815,395.084z M285.984,397.205l1.734-1.742l3.525,3.525l-3.525,3.517l-1.42-1.42L285.984,397.205z M286.509,403.724
					l-0.008-0.153l0.081,0.081L286.509,403.724L286.509,403.724z M287.122,411.234l-0.444-5.405l1.041-1.041l3.525,3.525
					l-3.525,3.517L287.122,411.234L287.122,411.234z M287.444,415.259l-0.065-0.807l0.339-0.339l1.145,1.145H287.444z
					 M288.864,412.976l3.517-3.525l3.525,3.525l-2.283,2.283h-2.485L288.864,412.976z M295.907,415.259l1.146-1.145l1.145,1.145
					H295.907z M300.473,415.259l-2.283-2.283l3.525-3.525l3.517,3.525l-2.275,2.283H300.473z M305.233,415.259l1.146-1.145
					l1.146,1.145H305.233z M315.978,415.259h-1.42l1.146-1.145l0.339,0.339L315.978,415.259z M316.309,411.234l-0.605,0.597
					l-3.525-3.517l3.525-3.525l1.041,1.041L316.309,411.234z M316.93,403.562l-0.016,0.161l-0.073-0.073L316.93,403.562
					L316.93,403.562z M317.132,401.086l-1.428,1.42l-3.525-3.517l3.525-3.525l1.742,1.742L317.132,401.086z M317.616,395.092
					l-0.774-0.775l0.912-0.904L317.616,395.092z M318.576,383.257l-0.258,3.211l-1.476-1.476L318.576,383.257L318.576,383.257z" />
                  </g>
                  <g style={{ opacity: '0.6', enableBackground: 'new' }}>
                    <path style={{ fill: '#FFFFFF' }} d="M316.946,375.561h-2.485h-6.841h-2.493h-6.832h-2.493h-6.833h-2.493h-3.864l0.282,3.469
					l0.202,2.477l0.524,6.446l0.299,3.703l0.395,4.929l0.169,2.114l0.056,0.629l0.201,2.485l0.274,3.404l0.17,2.114l0.177,2.146
					l0.202,2.485l0.153,1.88l0.169,2.114l0.073,0.92h4.517h3.816h5.51h3.816h5.51h3.816h4.526l0.073-0.92l0.169-2.106l0.153-1.896
					l0.202-2.485l0.178-2.138l0.169-2.114l0.274-3.412l0.202-2.477l0.048-0.629l0.169-2.114l0.403-4.929l0.299-3.695l0.516-6.454
					l0.202-2.477l0.282-3.469H316.946z M319.011,377.836L319.011,377.836l-0.662-0.662h0.718L319.011,377.836z M318.842,379.941
					l-0.064,0.839l-3.074,3.074l-3.525-3.525l3.154-3.154h0.742L318.842,379.941z M317.954,390.929l-2.251,2.251l-3.525-3.525
					l3.525-3.517l2.436,2.436L317.954,390.929z M285.283,388.573L285.283,388.573l2.436-2.436l3.525,3.517l-3.525,3.525
					l-2.243-2.251L285.283,388.573z M314.566,384.991l-3.525,3.525l-3.525-3.525l3.525-3.525L314.566,384.991z M309.024,377.174
					h4.034l-2.017,2.017L309.024,377.174z M309.895,389.654l-3.517,3.525l-3.525-3.525l3.525-3.517L309.895,389.654z
					 M298.19,394.317l3.525-3.517l3.517,3.517l-3.517,3.525L298.19,394.317z M300.57,398.98l-3.525,3.525l-3.517-3.517l3.525-3.525
					L300.57,398.98z M306.378,395.463l3.517,3.525l-3.517,3.517l-3.525-3.525L306.378,395.463z M301.715,388.517l-3.525-3.525
					l3.525-3.525l3.517,3.525L301.715,388.517z M300.57,389.654l-3.517,3.525l-3.525-3.525l3.525-3.517L300.57,389.654z
					 M292.382,388.517l-3.517-3.525l3.517-3.525l3.525,3.525L292.382,388.517z M292.382,390.8l3.525,3.517l-3.525,3.525
					l-3.517-3.525L292.382,390.8z M295.907,403.651l-3.525,3.517l-3.517-3.517l3.517-3.525L295.907,403.651z M297.044,404.788
					l3.525,3.525l-3.517,3.525l-3.525-3.525L297.044,404.788z M298.19,403.651l3.525-3.525l3.517,3.525l-3.517,3.517L298.19,403.651
					z M306.378,404.788l3.517,3.525l-3.517,3.525l-3.525-3.525L306.378,404.788z M311.041,409.451l3.525,3.525l-2.283,2.283h-2.485
					l-2.283-2.283L311.041,409.451z M307.516,403.651l3.525-3.525l3.525,3.525l-3.525,3.517L307.516,403.651z M307.516,394.317
					l3.525-3.517l3.525,3.517l-3.525,3.525L307.516,394.317z M309.895,380.329l-3.517,3.525l-3.525-3.525l3.154-3.154h0.742
					L309.895,380.329z M301.715,379.191l-2.017-2.017h4.026L301.715,379.191z M300.57,380.329l-3.517,3.525l-3.525-3.525
					l3.154-3.154h0.742L300.57,380.329z M294.398,377.174l-2.017,2.017l-2.008-2.017H294.398z M288.09,377.174l3.154,3.154
					l-3.525,3.525l-3.066-3.073l-0.065-0.839l2.759-2.767H288.09z M285.073,377.174l-0.654,0.654l0,0l-0.056-0.654H285.073z
					 M284.855,383.265l1.727,1.726l-1.468,1.468L284.855,383.265z M285.815,395.084l-0.137-1.67l0,0l0.904,0.904L285.815,395.084
					L285.815,395.084z M285.984,397.205l1.734-1.742l3.525,3.525l-3.525,3.517l-1.42-1.42L285.984,397.205z M286.509,403.724
					l-0.008-0.153l0.081,0.081L286.509,403.724L286.509,403.724z M287.122,411.234l-0.444-5.405l1.041-1.041l3.525,3.525
					l-3.525,3.517L287.122,411.234L287.122,411.234z M287.444,415.259l-0.065-0.807l0.339-0.339l1.145,1.145H287.444z
					 M288.864,412.976l3.517-3.525l3.525,3.525l-2.283,2.283h-2.485L288.864,412.976z M295.907,415.259l1.146-1.145l1.145,1.145
					H295.907z M300.473,415.259l-2.283-2.283l3.525-3.525l3.517,3.525l-2.275,2.283H300.473z M305.233,415.259l1.146-1.145
					l1.146,1.145H305.233z M315.978,415.259h-1.42l1.146-1.145l0.339,0.339L315.978,415.259z M316.309,411.234l-0.605,0.597
					l-3.525-3.517l3.525-3.525l1.041,1.041L316.309,411.234z M316.93,403.562l-0.016,0.161l-0.073-0.073L316.93,403.562
					L316.93,403.562z M317.132,401.086l-1.428,1.42l-3.525-3.517l3.525-3.525l1.742,1.742L317.132,401.086z M317.616,395.092
					l-0.774-0.775l0.912-0.904L317.616,395.092z M318.576,383.257l-0.258,3.211l-1.476-1.476L318.576,383.257L318.576,383.257z" />
                  </g>
                </g>
                <path style={{ fill: '#407BFF' }} d="M282.057,378.613h39.31c0.908,0,1.643-0.736,1.643-1.643v-1.205c0-0.908-0.736-1.643-1.643-1.643
			h-39.31c-0.908,0-1.643,0.736-1.643,1.643l0,1.205C280.414,377.878,281.15,378.613,282.057,378.613z" />
                <path style={{ fill: '#407BFF' }} d="M318.296,418.949h-33.167c-0.908,0-1.643-0.736-1.643-1.643v-1.205
			c0-0.908,0.736-1.643,1.643-1.643h33.167c0.908,0,1.643,0.736,1.643,1.643v1.205C319.939,418.213,319.203,418.949,318.296,418.949
			z" />
              </g>
            </g>
            <g id="Interface">
              <g>
                <rect x="233.887" y="148.621" style={{ fill: '#407BFF' }} width="199.648" height="135.928" />
                <rect x="233.887" y="148.621" style={{ opacity: '0.6', fill: '#FFFFFF', enableBackground: 'new' }} width="199.648" height="135.928" />
                <path style={{ opacity: '0.1', fill: '#FFFFFF', enableBackground: 'new' }} d="M362.436,148.621c0,0-57.452,53.783-128.55,55.059v-55.059
			H362.436z" />
                <path style={{ opacity: '0.1', fill: '#FFFFFF', enableBackground: 'new' }} d="M433.534,219.583c0,0-39.449-4.108-78.482,18.557
			s-78.023,44.026-121.165,34.012v12.398h199.648V219.583z" />
                <rect x="285.974" y="179.354" style={{ fill: '#FFFFFF' }} width="95.473" height="16.348" />
                <rect x="285.974" y="202.538" style={{ fill: '#FFFFFF' }} width="95.473" height="16.348" />
                <g style={{ opacity: '0.4' }}>
                  <path style={{ fill: '#263238' }} d="M294.516,189.119v0.429H291.2v-4.689h3.215v0.429h-2.72v1.668h2.425v0.422h-2.425v1.742H294.516z" />
                  <path style={{ fill: '#263238' }} d="M295.182,187.524h1.755v0.415h-1.755V187.524z" />
                  <path style={{ fill: '#263238' }} d="M303.814,187.504v2.043h-0.476v-1.996c0-0.744-0.375-1.126-1.012-1.126
				c-0.723,0-1.179,0.469-1.179,1.273v1.849h-0.475v-1.996c0-0.744-0.375-1.126-1.018-1.126c-0.717,0-1.179,0.469-1.179,1.273v1.849
				H298v-3.523h0.456v0.643c0.248-0.422,0.704-0.67,1.293-0.67c0.583,0,1.038,0.248,1.253,0.744
				c0.254-0.456,0.757-0.744,1.394-0.744C303.251,185.997,303.814,186.493,303.814,187.504z" />
                  <path style={{ fill: '#263238' }} d="M307.759,187.364v2.184h-0.456v-0.549c-0.214,0.362-0.63,0.583-1.212,0.583
				c-0.797,0-1.286-0.416-1.286-1.025c0-0.543,0.348-0.998,1.359-0.998h1.119v-0.214c0-0.603-0.341-0.931-0.998-0.931
				c-0.456,0-0.884,0.161-1.172,0.415l-0.214-0.355c0.355-0.301,0.878-0.476,1.433-0.476
				C307.243,185.997,307.759,186.453,307.759,187.364z M307.283,188.489v-0.576h-1.105c-0.683,0-0.904,0.268-0.904,0.629
				c0,0.409,0.328,0.663,0.891,0.663C306.701,189.206,307.096,188.951,307.283,188.489z" />
                  <path style={{ fill: '#263238' }} d="M308.941,184.912c0-0.181,0.154-0.335,0.348-0.335c0.194,0,0.348,0.147,0.348,0.328
				c0,0.194-0.147,0.348-0.348,0.348C309.095,185.253,308.941,185.1,308.941,184.912z M309.048,186.024h0.475v3.523h-0.475V186.024z
				" />
                  <path style={{ fill: '#263238' }} d="M310.848,184.577h0.476v4.97h-0.476V184.577z" />
                </g>
                <g style={{ opacity: '0.4' }}>
                  <path style={{ fill: '#263238' }} d="M294.871,209.674c0,0.998-0.724,1.601-1.916,1.601h-1.259v1.48H291.2v-4.689h1.755
				C294.147,208.067,294.871,208.67,294.871,209.674z M294.375,209.674c0-0.75-0.496-1.179-1.434-1.179h-1.246v2.344h1.246
				C293.879,210.84,294.375,210.411,294.375,209.674z" />
                  <path style={{ fill: '#263238' }} d="M298.463,210.572v2.184h-0.455v-0.549c-0.214,0.362-0.63,0.583-1.212,0.583
				c-0.797,0-1.286-0.416-1.286-1.025c0-0.543,0.348-0.998,1.359-0.998h1.119v-0.214c0-0.603-0.341-0.931-0.998-0.931
				c-0.455,0-0.884,0.161-1.172,0.415l-0.214-0.355c0.355-0.301,0.878-0.476,1.433-0.476
				C297.948,209.206,298.463,209.661,298.463,210.572z M297.988,211.697v-0.576h-1.105c-0.683,0-0.904,0.268-0.904,0.629
				c0,0.409,0.328,0.663,0.891,0.663C297.405,212.414,297.8,212.16,297.988,211.697z" />
                  <path style={{ fill: '#263238' }} d="M299.264,212.354l0.214-0.375c0.281,0.221,0.757,0.395,1.253,0.395
				c0.67,0,0.944-0.227,0.944-0.576c0-0.917-2.284-0.194-2.284-1.587c0-0.576,0.496-1.005,1.394-1.005
				c0.455,0,0.951,0.127,1.246,0.328l-0.208,0.382c-0.308-0.214-0.676-0.301-1.038-0.301c-0.636,0-0.918,0.248-0.918,0.582
				c0,0.952,2.284,0.235,2.284,1.588c0,0.609-0.536,1.005-1.454,1.005C300.114,212.79,299.552,212.602,299.264,212.354z" />
                  <path style={{ fill: '#263238' }} d="M302.536,212.354l0.214-0.375c0.281,0.221,0.757,0.395,1.252,0.395
				c0.67,0,0.944-0.227,0.944-0.576c0-0.917-2.284-0.194-2.284-1.587c0-0.576,0.496-1.005,1.394-1.005
				c0.456,0,0.951,0.127,1.246,0.328l-0.208,0.382c-0.308-0.214-0.676-0.301-1.038-0.301c-0.636,0-0.918,0.248-0.918,0.582
				c0,0.952,2.284,0.235,2.284,1.588c0,0.609-0.536,1.005-1.454,1.005C303.387,212.79,302.824,212.602,302.536,212.354z" />
                  <path style={{ fill: '#263238' }} d="M311.402,209.232l-1.333,3.523h-0.449l-1.112-2.887l-1.112,2.887h-0.449l-1.326-3.523h0.456
				l1.105,2.994l1.132-2.994h0.409l1.125,2.994l1.119-2.994H311.402z" />
                  <path style={{ fill: '#263238' }} d="M311.658,210.994c0-1.045,0.764-1.788,1.795-1.788c1.031,0,1.789,0.744,1.789,1.788
				c0,1.045-0.757,1.796-1.789,1.796C312.421,212.79,311.658,212.039,311.658,210.994z M314.759,210.994
				c0-0.824-0.556-1.373-1.306-1.373c-0.75,0-1.313,0.549-1.313,1.373c0,0.824,0.563,1.373,1.313,1.373
				C314.203,212.367,314.759,211.818,314.759,210.994z" />
                  <path style={{ fill: '#263238' }} d="M317.984,209.206v0.462c-0.041,0-0.081-0.007-0.114-0.007c-0.737,0-1.185,0.469-1.185,1.299v1.796
				h-0.476v-3.523h0.456v0.69C316.886,209.454,317.335,209.206,317.984,209.206z" />
                  <path style={{ fill: '#263238' }} d="M322.021,207.786v4.97h-0.456v-0.697c-0.288,0.476-0.777,0.73-1.36,0.73
				c-1.004,0-1.755-0.73-1.755-1.796c0-1.065,0.751-1.788,1.755-1.788c0.563,0,1.045,0.241,1.34,0.697v-2.117H322.021z
				 M321.552,210.994c0-0.824-0.563-1.373-1.306-1.373c-0.751,0-1.313,0.549-1.313,1.373c0,0.824,0.563,1.373,1.313,1.373
				C320.989,212.367,321.552,211.818,321.552,210.994z" />
                </g>
                <rect x="286.975" y="235.559" style={{ opacity: '0.2' }} width="95.473" height="16.348" />
                <g style={{ opacity: '0.5' }}>
                  <path d="M298.891,228.794l-0.64-0.899c-0.072,0.008-0.144,0.012-0.224,0.012h-0.752v0.888h-0.296v-2.798h1.048
				c0.711,0,1.143,0.36,1.143,0.96c0,0.439-0.232,0.747-0.64,0.879l0.684,0.959H298.891z M298.875,226.956
				c0-0.448-0.296-0.704-0.855-0.704h-0.744v1.403h0.744C298.579,227.655,298.875,227.395,298.875,226.956z" />
                  <path d="M301.643,227.831h-1.759c0.032,0.439,0.368,0.731,0.828,0.731c0.256,0,0.483-0.092,0.644-0.276l0.16,0.184
				c-0.188,0.224-0.479,0.344-0.811,0.344c-0.655,0-1.103-0.448-1.103-1.071c0-0.624,0.436-1.067,1.027-1.067s1.02,0.436,1.02,1.067
				C301.647,227.767,301.643,227.799,301.643,227.831z M299.884,227.619h1.491c-0.036-0.412-0.336-0.7-0.748-0.7
				C300.22,226.919,299.92,227.208,299.884,227.619z" />
                  <path d="M305.698,227.575v1.219h-0.284v-1.191c0-0.444-0.224-0.671-0.604-0.671c-0.432,0-0.704,0.28-0.704,0.759v1.104h-0.284
				v-1.191c0-0.444-0.224-0.671-0.607-0.671c-0.428,0-0.704,0.28-0.704,0.759v1.104h-0.284v-2.103h0.272v0.384
				c0.148-0.251,0.42-0.399,0.772-0.399c0.348,0,0.62,0.148,0.747,0.443c0.152-0.271,0.452-0.443,0.832-0.443
				C305.363,226.676,305.698,226.971,305.698,227.575z" />
                  <path d="M308.311,227.831h-1.759c0.032,0.439,0.368,0.731,0.828,0.731c0.256,0,0.483-0.092,0.643-0.276l0.16,0.184
				c-0.188,0.224-0.479,0.344-0.811,0.344c-0.655,0-1.103-0.448-1.103-1.071c0-0.624,0.436-1.067,1.027-1.067s1.02,0.436,1.02,1.067
				C308.315,227.767,308.311,227.799,308.311,227.831z M306.552,227.619h1.491c-0.036-0.412-0.336-0.7-0.748-0.7
				C306.888,226.919,306.588,227.208,306.552,227.619z" />
                  <path d="M312.366,227.575v1.219h-0.284v-1.191c0-0.444-0.224-0.671-0.603-0.671c-0.432,0-0.704,0.28-0.704,0.759v1.104h-0.284
				v-1.191c0-0.444-0.224-0.671-0.607-0.671c-0.428,0-0.704,0.28-0.704,0.759v1.104h-0.284v-2.103h0.271v0.384
				c0.148-0.251,0.42-0.399,0.772-0.399c0.348,0,0.62,0.148,0.748,0.443c0.152-0.271,0.452-0.443,0.832-0.443
				C312.031,226.676,312.366,226.971,312.366,227.575z" />
                  <path d="M315.271,227.743c0,0.635-0.448,1.071-1.048,1.071c-0.348,0-0.64-0.152-0.812-0.436v0.416h-0.271v-2.966h0.284v1.263
				c0.176-0.272,0.464-0.415,0.799-0.415C314.823,226.676,315.271,227.107,315.271,227.743z M314.987,227.743
				c0-0.492-0.34-0.82-0.784-0.82c-0.448,0-0.783,0.328-0.783,0.82s0.336,0.819,0.783,0.819
				C314.647,228.563,314.987,228.235,314.987,227.743z" />
                  <path d="M317.683,227.831h-1.759c0.032,0.439,0.368,0.731,0.828,0.731c0.256,0,0.483-0.092,0.643-0.276l0.16,0.184
				c-0.188,0.224-0.479,0.344-0.811,0.344c-0.655,0-1.103-0.448-1.103-1.071c0-0.624,0.436-1.067,1.027-1.067s1.02,0.436,1.02,1.067
				C317.687,227.767,317.683,227.799,317.683,227.831z M315.924,227.619h1.491c-0.036-0.412-0.336-0.7-0.747-0.7
				C316.26,226.919,315.96,227.208,315.924,227.619z" />
                  <path d="M319.327,226.676v0.276c-0.024,0-0.048-0.004-0.068-0.004c-0.44,0-0.708,0.28-0.708,0.776v1.071h-0.284v-2.103h0.272
				v0.412C318.671,226.824,318.94,226.676,319.327,226.676z" />
                  <path d="M324.39,227.575v1.219h-0.284v-1.191c0-0.444-0.224-0.671-0.604-0.671c-0.432,0-0.704,0.28-0.704,0.759v1.104h-0.284
				v-1.191c0-0.444-0.224-0.671-0.607-0.671c-0.428,0-0.704,0.28-0.704,0.759v1.104h-0.284v-2.103h0.272v0.384
				c0.148-0.251,0.42-0.399,0.772-0.399c0.348,0,0.62,0.148,0.747,0.443c0.152-0.271,0.452-0.443,0.832-0.443
				C324.054,226.676,324.39,226.971,324.39,227.575z" />
                  <path d="M327.002,227.831h-1.759c0.032,0.439,0.368,0.731,0.828,0.731c0.256,0,0.483-0.092,0.644-0.276l0.16,0.184
				c-0.188,0.224-0.48,0.344-0.812,0.344c-0.655,0-1.103-0.448-1.103-1.071c0-0.624,0.436-1.067,1.027-1.067s1.02,0.436,1.02,1.067
				C327.007,227.767,327.002,227.799,327.002,227.831z M325.244,227.619h1.491c-0.036-0.412-0.336-0.7-0.748-0.7
				C325.58,226.919,325.28,227.208,325.244,227.619z" />
                </g>
                <rect x="285.974" y="234.558" style={{ fill: '#407BFF' }} width="95.473" height="16.348" />
                <g style={{ opacity: '0.5' }}>
                  <g>
                    <path d="M316.47,261.296v1.099h1.447v0.256h-1.447v1.188h-0.296v-2.798h1.919v0.256H316.47z" />
                    <path d="M318.362,262.787c0-0.624,0.456-1.067,1.071-1.067s1.067,0.443,1.067,1.067c0,0.623-0.452,1.071-1.067,1.071
					S318.362,263.41,318.362,262.787z M320.213,262.787c0-0.492-0.332-0.82-0.779-0.82c-0.448,0-0.784,0.328-0.784,0.82
					c0,0.491,0.336,0.819,0.784,0.819C319.881,263.606,320.213,263.278,320.213,262.787z" />
                    <path d="M322.142,261.72v0.275c-0.024,0-0.048-0.004-0.068-0.004c-0.44,0-0.707,0.279-0.707,0.775v1.071h-0.284v-2.102h0.272
					v0.412C321.486,261.867,321.754,261.72,322.142,261.72z" />
                    <path d="M324.581,261.735v1.847c0,0.724-0.356,1.052-1.051,1.052c-0.384,0-0.76-0.116-0.979-0.324l0.143-0.216
					c0.2,0.18,0.504,0.288,0.828,0.288c0.532,0,0.776-0.244,0.776-0.764v-0.268c-0.176,0.268-0.476,0.407-0.82,0.407
					c-0.6,0-1.055-0.419-1.055-1.023c0-0.604,0.456-1.015,1.055-1.015c0.352,0,0.656,0.144,0.832,0.419v-0.403H324.581z
					 M324.305,262.734c0-0.455-0.336-0.768-0.8-0.768c-0.463,0-0.795,0.313-0.795,0.768c0,0.456,0.332,0.771,0.795,0.771
					C323.969,263.506,324.305,263.19,324.305,262.734z" />
                    <path d="M325.167,262.787c0-0.624,0.456-1.067,1.071-1.067s1.067,0.443,1.067,1.067c0,0.623-0.452,1.071-1.067,1.071
					S325.167,263.41,325.167,262.787z M327.017,262.787c0-0.492-0.332-0.82-0.779-0.82c-0.448,0-0.784,0.328-0.784,0.82
					c0,0.491,0.336,0.819,0.784,0.819C326.685,263.606,327.017,263.278,327.017,262.787z" />
                    <path d="M328.993,263.71c-0.112,0.1-0.28,0.148-0.443,0.148c-0.396,0-0.616-0.225-0.616-0.612v-1.271h-0.376v-0.24h0.376v-0.46
					h0.284v0.46h0.64v0.24h-0.64v1.255c0,0.248,0.128,0.384,0.364,0.384c0.116,0,0.232-0.036,0.312-0.108L328.993,263.71z" />
                    <path d="M332.809,261.999c0,0.596-0.432,0.956-1.144,0.956h-0.751v0.883h-0.296v-2.798h1.047
					C332.377,261.04,332.809,261.399,332.809,261.999z M332.513,261.999c0-0.447-0.296-0.703-0.855-0.703h-0.744v1.398h0.744
					C332.217,262.694,332.513,262.439,332.513,261.999z" />
                    <path d="M334.957,262.535v1.303h-0.272v-0.327c-0.128,0.216-0.375,0.348-0.724,0.348c-0.476,0-0.768-0.248-0.768-0.612
					c0-0.323,0.208-0.596,0.812-0.596h0.668v-0.128c0-0.359-0.204-0.556-0.596-0.556c-0.272,0-0.528,0.097-0.7,0.248l-0.128-0.212
					c0.212-0.18,0.523-0.283,0.855-0.283C334.649,261.72,334.957,261.991,334.957,262.535z M334.673,263.206v-0.344h-0.66
					c-0.408,0-0.54,0.16-0.54,0.376c0,0.244,0.196,0.395,0.532,0.395C334.325,263.634,334.561,263.482,334.673,263.206z" />
                    <path d="M335.438,263.599l0.128-0.225c0.168,0.132,0.452,0.236,0.748,0.236c0.4,0,0.564-0.136,0.564-0.344
					c0-0.548-1.363-0.116-1.363-0.947c0-0.344,0.296-0.6,0.832-0.6c0.272,0,0.568,0.075,0.744,0.195l-0.124,0.228
					c-0.184-0.128-0.403-0.18-0.62-0.18c-0.379,0-0.547,0.148-0.547,0.348c0,0.568,1.363,0.141,1.363,0.948
					c0,0.363-0.32,0.6-0.868,0.6C335.946,263.858,335.61,263.746,335.438,263.599z" />
                    <path d="M337.394,263.599l0.128-0.225c0.168,0.132,0.452,0.236,0.747,0.236c0.4,0,0.564-0.136,0.564-0.344
					c0-0.548-1.363-0.116-1.363-0.947c0-0.344,0.296-0.6,0.832-0.6c0.272,0,0.568,0.075,0.744,0.195l-0.124,0.228
					c-0.184-0.128-0.403-0.18-0.62-0.18c-0.379,0-0.547,0.148-0.547,0.348c0,0.568,1.363,0.141,1.363,0.948
					c0,0.363-0.32,0.6-0.868,0.6C337.902,263.858,337.566,263.746,337.394,263.599z" />
                    <path d="M342.688,261.735l-0.795,2.102h-0.268l-0.664-1.723l-0.664,1.723h-0.268l-0.791-2.102h0.271l0.66,1.787l0.676-1.787
					h0.244l0.672,1.787l0.667-1.787H342.688z" />
                    <path d="M342.846,262.787c0-0.624,0.456-1.067,1.071-1.067c0.616,0,1.067,0.443,1.067,1.067c0,0.623-0.452,1.071-1.067,1.071
					C343.302,263.858,342.846,263.41,342.846,262.787z M344.697,262.787c0-0.492-0.332-0.82-0.779-0.82
					c-0.448,0-0.784,0.328-0.784,0.82c0,0.491,0.336,0.819,0.784,0.819C344.365,263.606,344.697,263.278,344.697,262.787z" />
                    <path d="M346.625,261.72v0.275c-0.024,0-0.048-0.004-0.068-0.004c-0.44,0-0.707,0.279-0.707,0.775v1.071h-0.284v-2.102h0.272
					v0.412C345.97,261.867,346.237,261.72,346.625,261.72z" />
                    <path d="M349.037,260.872v2.966h-0.272v-0.416c-0.172,0.284-0.464,0.436-0.811,0.436c-0.6,0-1.047-0.436-1.047-1.071
					c0-0.636,0.448-1.067,1.047-1.067c0.335,0,0.623,0.144,0.799,0.415v-1.263H349.037z M348.757,262.787
					c0-0.492-0.335-0.82-0.779-0.82c-0.448,0-0.784,0.328-0.784,0.82c0,0.491,0.336,0.819,0.784,0.819
					C348.421,263.606,348.757,263.278,348.757,262.787z" />
                    <path d="M351.105,261.755c0-0.287-0.236-0.487-0.644-0.487c-0.34,0-0.587,0.112-0.764,0.324l-0.212-0.16
					c0.216-0.264,0.548-0.416,0.996-0.416c0.56,0,0.919,0.268,0.919,0.695c0,0.684-0.732,0.716-0.732,1.288h-0.296
					C350.374,262.339,351.105,262.279,351.105,261.755z M350.314,263.65c0-0.116,0.092-0.204,0.208-0.204
					c0.12,0,0.212,0.088,0.212,0.204c0,0.111-0.092,0.208-0.212,0.208C350.406,263.858,350.314,263.762,350.314,263.65z" />
                  </g>
                </g>
                <rect x="286.703" y="224.404" style={{ fill: '#FFFFFF' }} width="4.846" height="4.846" />
                <g>
                  <path style={{ fill: '#FFFFFF' }} d="M323.169,239.676h0.8v4.9h3.03v0.696h-3.83V239.676z" />
                  <path style={{ fill: '#FFFFFF' }} d="M327.362,243.153c0-1.264,0.936-2.159,2.207-2.159c1.271,0,2.199,0.896,2.199,2.159
				s-0.927,2.167-2.199,2.167C328.297,245.32,327.362,244.417,327.362,243.153z M330.992,243.153c0-0.903-0.607-1.487-1.423-1.487
				c-0.815,0-1.431,0.584-1.431,1.487c0,0.903,0.616,1.495,1.431,1.495C330.384,244.648,330.992,244.057,330.992,243.153z" />
                  <path style={{ fill: '#FFFFFF' }} d="M336.887,241.034v3.662c0,1.495-0.759,2.175-2.199,2.175c-0.775,0-1.559-0.216-2.023-0.632
				l0.368-0.591c0.392,0.335,1.008,0.552,1.631,0.552c1,0,1.455-0.464,1.455-1.424v-0.336c-0.368,0.441-0.919,0.656-1.527,0.656
				c-1.224,0-2.151-0.832-2.151-2.056c0-1.223,0.927-2.047,2.151-2.047c0.631,0,1.207,0.232,1.567,0.696v-0.656H336.887z
				 M336.135,243.041c0-0.823-0.608-1.375-1.455-1.375c-0.855,0-1.463,0.552-1.463,1.375c0,0.816,0.608,1.384,1.463,1.384
				C335.528,244.425,336.135,243.857,336.135,243.041z" />
                  <path style={{ fill: '#FFFFFF' }} d="M338.217,239.724c0-0.28,0.224-0.504,0.52-0.504c0.296,0,0.52,0.216,0.52,0.487
				c0,0.288-0.216,0.512-0.52,0.512C338.441,240.219,338.217,240.003,338.217,239.724z M338.353,241.034h0.768v4.238h-0.768V241.034
				z" />
                  <path style={{ fill: '#FFFFFF' }} d="M344.607,242.834v2.439h-0.768v-2.351c0-0.832-0.416-1.239-1.144-1.239
				c-0.815,0-1.343,0.487-1.343,1.407v2.183h-0.768v-4.238h0.736v0.64c0.311-0.432,0.855-0.68,1.527-0.68
				C343.879,240.994,344.607,241.586,344.607,242.834z" />
                </g>
                <g>
                  <polygon style={{ fill: '#263238' }} points="288.685,228.161 287.615,226.996 287.983,226.658 288.693,227.431 290.289,225.776 
				290.649,226.123 			" />
                </g>
                <g style={{ opacity: '0.4' }}>
                  <g>
                    <g>
                      <path style={{ fill: '#263238' }} d="M372.383,213.74L372.383,213.74c-1.66,0-3.213-0.915-4.375-2.579l-0.1-0.143l0.1-0.143
						c1.162-1.663,2.716-2.579,4.375-2.579c1.659-0.001,3.213,0.915,4.376,2.579l0.1,0.143l-0.1,0.143
						C375.597,212.824,374.043,213.74,372.383,213.74z M368.521,211.018c1.053,1.435,2.418,2.222,3.863,2.222
						c1.444,0,2.81-0.787,3.863-2.222c-1.053-1.435-2.42-2.222-3.863-2.222C370.939,208.796,369.573,209.583,368.521,211.018z" />
                    </g>
                    <g>
                      <path style={{ fill: '#263238' }} d="M372.383,212.872c-1.022,0-1.854-0.832-1.854-1.854c0-1.022,0.832-1.854,1.854-1.854
						c1.022,0,1.854,0.831,1.854,1.854C374.237,212.04,373.406,212.872,372.383,212.872z M372.383,209.665
						c-0.746,0-1.354,0.607-1.354,1.354c0,0.746,0.607,1.354,1.354,1.354c0.747,0,1.354-0.607,1.354-1.354
						C373.737,210.272,373.13,209.665,372.383,209.665z" />
                    </g>
                  </g>
                  <g>
                    <rect x="368.212" y="210.768" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -40.1437 325.1205)" style={{ fill: '#263238' }} width="8.342" height="0.5" />
                  </g>
                </g>
                <g style={{ opacity: '0.5' }}>
                  <path d="M322.054,171.923l0.203-0.399c0.35,0.343,0.974,0.595,1.618,0.595c0.918,0,1.316-0.385,1.316-0.868
				c0-1.345-3.019-0.519-3.019-2.333c0-0.721,0.561-1.345,1.807-1.345c0.553,0,1.127,0.161,1.52,0.435l-0.175,0.413
				c-0.42-0.273-0.911-0.406-1.345-0.406c-0.896,0-1.296,0.399-1.296,0.89c0,1.345,3.018,0.532,3.018,2.318
				c0,0.722-0.574,1.338-1.828,1.338C323.14,172.561,322.418,172.301,322.054,171.923z" />
                  <path d="M326.611,167.672c0-0.189,0.161-0.35,0.364-0.35s0.364,0.154,0.364,0.343c0,0.203-0.154,0.364-0.364,0.364
				C326.772,168.029,326.611,167.868,326.611,167.672z M326.723,168.835h0.497v3.684h-0.497V168.835z" />
                  <path d="M332.017,168.835v3.236c0,1.268-0.623,1.842-1.842,1.842c-0.672,0-1.331-0.203-1.716-0.567l0.252-0.378
				c0.35,0.315,0.883,0.504,1.45,0.504c0.932,0,1.359-0.427,1.359-1.338v-0.469c-0.308,0.469-0.833,0.714-1.435,0.714
				c-1.051,0-1.849-0.735-1.849-1.793c0-1.058,0.798-1.779,1.849-1.779c0.616,0,1.148,0.252,1.456,0.735v-0.708H332.017z
				 M331.534,170.585c0-0.798-0.588-1.345-1.401-1.345s-1.394,0.546-1.394,1.345c0,0.798,0.581,1.352,1.394,1.352
				S331.534,171.384,331.534,170.585z" />
                  <path d="M336.798,170.382v2.136h-0.497v-2.087c0-0.778-0.406-1.177-1.107-1.177c-0.792,0-1.289,0.49-1.289,1.331v1.933h-0.497
				v-3.684h0.476v0.68c0.266-0.448,0.764-0.708,1.401-0.708C336.182,168.807,336.798,169.325,336.798,170.382z" />
                  <path d="M339.869,167.672c0-0.189,0.161-0.35,0.364-0.35c0.203,0,0.364,0.154,0.364,0.343c0,0.203-0.154,0.364-0.364,0.364
				C340.03,168.029,339.869,167.868,339.869,167.672z M339.981,168.835h0.497v3.684h-0.497V168.835z" />
                  <path d="M345.254,170.382v2.136h-0.497v-2.087c0-0.778-0.406-1.177-1.106-1.177c-0.792,0-1.289,0.49-1.289,1.331v1.933h-0.497
				v-3.684h0.476v0.68c0.266-0.448,0.764-0.708,1.401-0.708C344.638,168.807,345.254,169.325,345.254,170.382z" />
                </g>
              </g>
            </g>
            <g id="Speech_bubble">
              <g>
                <g>
                  <path style={{ fill: '#407BFF' }} d="M181.276,148.262h-28.728c-4.885,0-8.846,3.961-8.846,8.846v25.002
				c0,4.885,3.961,8.846,8.846,8.846h8.388l-7.031,13.898l12.981-13.898h14.391c4.885,0,8.846-3.96,8.846-8.846v-25.002
				C190.122,152.223,186.161,148.262,181.276,148.262z" />
                  <path style={{ opacity: '0.7', fill: '#FFFFFF' }} d="M181.276,148.262h-28.728c-4.885,0-8.846,3.961-8.846,8.846v25.002
				c0,4.885,3.961,8.846,8.846,8.846h8.388l-7.031,13.898l12.981-13.898h14.391c4.885,0,8.846-3.96,8.846-8.846v-25.002
				C190.122,152.223,186.161,148.262,181.276,148.262z" />
                </g>
                <g>
                  <g style={{ enableBackground: 'new' }}>
                    <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -70.9682 167.6701)" style={{ fill: '#407BFF' }} cx="166.912" cy="169.501" rx="14.258" ry="14.258" />
                  </g>
                  <g>
                    <path style={{ fill: '#FFFFFF' }} d="M165.415,175.87c-0.385,0-0.75-0.169-0.999-0.462l-5.594-6.591
					c-0.468-0.552-0.4-1.378,0.151-1.847c0.551-0.467,1.378-0.401,1.847,0.151l4.595,5.414l7.588-8.94
					c0.469-0.553,1.296-0.619,1.847-0.151c0.552,0.468,0.62,1.295,0.151,1.847l-8.587,10.118
					C166.165,175.701,165.8,175.87,165.415,175.87z" />
                  </g>
                </g>
              </g>
            </g>
            <g id="Character">
              <g>
                <g>
                  <path style={{ fill: '#263238' }} d="M204.995,417.118h58.198c0.632,0,1.144-0.512,1.144-1.144v-3.712c0-0.632-0.512-1.144-1.144-1.144
				h-55.198V292.302h54.257c1.152,0,2.085-0.934,2.085-2.085v-3.915h-59.342c-1.657,0-3,1.343-3,3v124.816
				C201.995,415.775,203.338,417.118,204.995,417.118z" />
                </g>
                <g style={{ opacity: '0.3', enableBackground: 'new' }}>
                  <path style={{ fill: '#FFFFFF' }} d="M201.993,289.304v124.81c0,1.66,1.34,3,3,3h58.2c0.63,0,1.14-0.51,1.14-1.14v-3.71
				c0-0.63-0.51-1.15-1.14-1.15h-55.2v-118.81h54.26c1.15,0,2.08-0.94,2.08-2.09v-3.91h-59.34
				C203.333,286.304,201.993,287.644,201.993,289.304z" />
                </g>
                <path style={{ opacity: '0.2' }} d="M201.993,289.304v31.83c2,0.51,4,1,6,1.47v-30.3h54.26c1.15,0,2.08-0.94,2.08-2.09v-3.91h-59.34
			C203.333,286.304,201.993,287.644,201.993,289.304z" />
                <g>
                  <path style={{ fill: '#263238' }} d="M96.745,400.372l23.866-2.081v-31.343h8.39v31.335l23.951,2.089
				c2.11,0.207,3.719,1.982,3.719,4.102v3.179h-31.044h-1.555H93.027v-3.179C93.027,402.354,94.635,400.58,96.745,400.372z" />
                  <path style={{ opacity: '0.1', fill: '#FFFFFF', enableBackground: 'new' }} d="M96.745,400.372l23.866-2.081v-31.343h8.39v31.335
				l23.951,2.089c2.11,0.207,3.719,1.982,3.719,4.102v3.179h-31.044h-1.555H93.027v-3.179
				C93.027,402.354,94.635,400.58,96.745,400.372z" />
                  <path style={{ fill: '#263238' }} d="M148.945,411.597c0,3.03,2.456,5.485,5.485,5.485c3.029,0,5.485-2.456,5.485-5.485
				c0-3.029-2.456-5.485-5.485-5.485C151.401,406.111,148.945,408.567,148.945,411.597z" />
                  <path style={{ fill: '#263238' }} d="M100.753,411.597c0,3.03-2.456,5.485-5.486,5.485c-3.029,0-5.485-2.456-5.485-5.485
				c0-3.029,2.456-5.485,5.485-5.485C98.297,406.111,100.753,408.567,100.753,411.597z" />
                  <path style={{ fill: '#263238' }} d="M122.88,373.344h3.852c2.051,0,3.714-1.663,3.714-3.714v-26.967h-11.28v26.967
				C119.166,371.681,120.829,373.344,122.88,373.344z" />
                  <path style={{ opacity: '0.2', fill: '#FFFFFF', enableBackground: 'new' }} d="M122.88,373.344h3.852c2.051,0,3.714-1.663,3.714-3.714
				v-26.967h-11.28v26.967C119.166,371.681,120.829,373.344,122.88,373.344z" />
                  <path style={{ fill: '#263238' }} d="M100.056,280.661c0,0,2.585,51.826,13.746,60.748h-11.937c0,0-10.645-17.656-11.439-60.748
				H100.056z" />
                  <path style={{ opacity: '0.1', fill: '#FFFFFF', enableBackground: 'new' }} d="M100.056,280.661c0,0,2.585,51.826,13.746,60.748h-11.937
				c0,0-10.645-17.656-11.439-60.748H100.056z" />
                  <path style={{ fill: '#407BFF' }} d="M152.009,337.115H99.086c-2.481,0-4.493,2.011-4.493,4.493v4.096h61.908v-4.096
				C156.501,339.126,154.49,337.115,152.009,337.115z" />
                  <g>
                    <path style={{ fill: '#263238' }} d="M157.05,341.409H94.045c-0.998,0-1.807,0.809-1.807,1.807v0.68c0,0.998,0.809,1.807,1.807,1.807
					h63.005c0.998,0,1.807-0.809,1.807-1.807v-0.68C158.857,342.218,158.048,341.409,157.05,341.409z" />
                    <path style={{ opacity: '0.6', fill: '#FFFFFF', enableBackground: 'new' }} d="M157.05,341.409H94.045c-0.998,0-1.807,0.809-1.807,1.807
					v0.68c0,0.998,0.809,1.807,1.807,1.807h63.005c0.998,0,1.807-0.809,1.807-1.807v-0.68
					C158.857,342.218,158.048,341.409,157.05,341.409z" />
                  </g>
                  <path style={{ fill: '#407BFF' }} d="M88.332,292.007l19.123,0c5.602,0,9.648-5.034,9.036-11.243l-0.816-8.289
				c-0.611-6.209-5.648-11.243-11.25-11.243H85.303c-5.602,0-9.648,5.034-9.037,11.243l0.816,8.288
				C77.694,286.974,82.73,292.007,88.332,292.007z" />
                </g>
                <g id="_x3C_Group_x3E__3_">
                  <g>
                    <path style={{ fill: '#B65B52' }} d="M151.458,247.294l0.919,2.817c0.321,0.964,0.659,1.924,1.01,2.876
					c0.692,1.907,1.427,3.787,2.202,5.602c0.783,1.81,1.625,3.561,2.525,5.131c0.895,1.55,1.923,2.967,2.733,3.701
					c0.506,0.564,2.027,1.255,3.757,1.712c1.751,0.444,3.698,0.812,5.7,1.038c3.996,0.505,8.269,0.697,12.417,0.872l0.742,5.594
					c-4.425,1.192-8.798,1.875-13.434,2.134c-2.313,0.088-4.681,0.103-7.179-0.227c-1.255-0.17-2.548-0.408-3.92-0.818
					c-0.685-0.199-1.388-0.465-2.121-0.806c-0.696-0.314-1.555-0.806-2.184-1.268c-2.628-1.903-4.243-3.994-5.681-6.059
					c-1.427-2.068-2.576-4.16-3.606-6.267c-1.037-2.103-1.926-4.228-2.728-6.372c-0.394-1.075-0.769-2.152-1.127-3.238
					c-0.371-1.103-0.677-2.137-1.003-3.367L151.458,247.294z" />
                  </g>
                  <path style={{ fill: '#263238' }} d="M146.55,242.019c3.153,1.08,6.021,5.614,13.933,23.777c0,0,1.286,2.227,7.714,3.612l-0.586,10.208
				c0,0-9.18-0.402-11.312-1.878c-2.132-1.476-14.905-22.473-14.905-22.473L146.55,242.019z" />
                  <path style={{ opacity: '0.2', fill: '#FFFFFF', enableBackground: 'new' }} d="M168.198,269.411l-0.59,10.2c0,0-7.36-0.32-10.43-1.45
				c-0.36-0.13-0.66-0.27-0.88-0.42c-1.23-0.86-6.03-8.25-9.85-14.33c-2.79-4.42-5.05-8.15-5.05-8.15l5.15-13.24
				c3.15,1.08,6.02,5.61,13.94,23.77C160.488,265.791,161.768,268.021,168.198,269.411z" />
                  <path style={{ opacity: '0.2', enableBackground: 'new' }} d="M157.178,278.161c-0.36-0.13-0.66-0.27-0.88-0.42
				c-1.23-0.86-6.03-8.25-9.85-14.33c1.64-5.7,3.52-10.16,3.52-10.16S157.318,267.921,157.178,278.161z" />
                  <g>
                    <path style={{ fill: '#B55B52' }} d="M180.54,271.554l7.169-2.587l-0.878,9.605c0,0-6.58,0.839-7.39-2.624L180.54,271.554z" />
                    <polygon style={{ fill: '#B55B52' }} points="193.647,270.91 192.553,278.816 186.831,278.572 187.709,268.967 				" />
                  </g>
                  <polygon style={{ fill: '#263238' }} points="168.248,268.967 171.906,270.066 170.571,279.989 166.912,280.623 			" />
                  <path style={{ fill: '#407BFF' }} d="M151.744,305.094c0,0,48.386,19.085,59.641,27.066c11.254,7.981,39.634,61.778,39.634,61.778
				l-11.731,9.038c0,0-33.84-49.136-37.969-52.47c-4.129-3.334-58.033-19.861-58.033-19.861L151.744,305.094z" />
                  <path style={{ opacity: '0.4', fill: '#FFFFFF', enableBackground: 'new' }} d="M251.018,393.941l-11.73,9.04c0,0-7.9-11.47-16.53-23.7
				c-9.23-13.09-19.3-27.05-21.44-28.77c-3.02-2.44-32.65-11.94-48.36-16.86c-5.78-1.82-9.67-3.01-9.67-3.01l8.46-25.55
				c0,0,48.38,19.09,59.64,27.07C222.638,340.141,251.018,393.941,251.018,393.941z" />
                  <polygon style={{ fill: '#B55B52' }} points="253.152,413.189 258.799,407.509 249.556,393.223 243.922,398.901 			" />
                  <polygon style={{ opacity: '0.2', enableBackground: 'new' }} points="243.922,398.901 249.562,393.229 254.844,401.397 
				249.002,406.779 			" />
                  <g>
                    <path style={{ fill: '#407BFF' }} d="M259.785,406.891c0.022,0.006,0.038,0.025,0.061,0.023c0.022-0.008,0.038-0.026,0.051-0.046
					c0.668-0.244,1.308-0.558,1.908-0.94c0.476-0.246,0.844-0.661,1.029-1.164c0.073-0.378-0.078-0.765-0.389-0.993
					c-0.169-0.175-0.414-0.252-0.652-0.202c-0.551,0.125-1.093,0.911-1.502,1.662c0.019-0.786-0.107-1.599-0.464-1.95
					c-0.237-0.228-0.587-0.279-0.865-0.105c-0.261,0.133-0.428,0.347-0.495,0.632c-0.226,1.083,1.227,2.823,1.299,2.923
					c0.001,0.001,0.092-0.106,0.093-0.106c0.001,0.001,0.001,0.001,0.002,0.001c0.001,0-0.088,0.114-0.088,0.114
					c0.006,0.006-0.077,0.111-0.07,0.116C259.727,406.878,259.754,406.889,259.785,406.891z M259.835,406.15
					c-0.028,0.064-0.052,0.118-0.075,0.172c-0.437-0.621-1.092-1.711-0.95-2.354c0.024-0.148,0.11-0.28,0.236-0.362l0.08-0.046
					c0.238-0.123,0.369-0.053,0.443,0.028C259.933,403.99,260.017,405.27,259.835,406.15z M260.167,406.395
					c0.443-1.008,1.175-2.306,1.724-2.428c0.117-0.026,0.238,0.012,0.32,0.1c0.191,0.149,0.289,0.386,0.259,0.626
					C262.36,405.209,261.512,405.831,260.167,406.395z" />
                  </g>
                  <path style={{ fill: '#263238' }} d="M258.687,406.38l-7.46,5.002c-0.268,0.163-0.354,0.512-0.191,0.78
				c0.001,0.003,0.003,0.005,0.005,0.008l3.598,6.152c0.431,0.643,1.3,0.82,1.948,0.396c2.575-1.788,3.715-2.77,6.98-4.997
				c2.011-1.357,6.114-3.847,8.572-6.134c2.458-2.287,0.131-4.655-1.111-3.903c-2.954,1.77-8.082,2.526-10.941,2.312
				C259.591,405.965,259.098,406.1,258.687,406.38z" />
                  <polygon style={{ fill: '#407BFF' }} points="239.817,406.909 254.467,397.157 251.092,390.27 235.223,401.281 			" />
                  <polygon style={{ opacity: '0.2', fill: '#FFFFFF' }} points="239.817,406.909 254.467,397.157 251.092,390.27 235.223,401.281 			" />
                  <path style={{ opacity: '0.2', enableBackground: 'new' }} d="M222.758,379.281c-9.23-13.09-19.3-27.05-21.44-28.77
				c-3.02-2.44-32.65-11.94-48.36-16.86l-1.83-19.05l5.74-1.02l0.65,1.46c0,0,35.86,8.13,45.99,13.21
				C210.048,331.531,218.818,363.611,222.758,379.281z" />
                  <g>
                    <path style={{ fill: '#407BFF' }} d="M220.614,408.787c0.019,0.012,0.028,0.036,0.05,0.042c0.023,0,0.045-0.012,0.063-0.026
					c0.711-0.009,1.419-0.093,2.111-0.254c0.531-0.074,1.015-0.344,1.357-0.757c0.194-0.333,0.18-0.748-0.038-1.065
					c-0.102-0.221-0.307-0.375-0.548-0.407c-0.561-0.064-1.333,0.497-1.968,1.07c0.279-0.736,0.429-1.544,0.208-1.994
					c-0.148-0.294-0.461-0.458-0.782-0.386c-0.291,0.039-0.518,0.185-0.676,0.432c-0.572,0.947,0.222,3.07,0.257,3.189
					c0,0.001,0.122-0.07,0.123-0.069c0,0.001,0.001,0.001,0.001,0.002c0,0.001-0.121,0.078-0.121,0.079
					c0.003,0.007-0.109,0.08-0.105,0.087C220.564,408.755,220.586,408.775,220.614,408.787z M220.907,408.104
					c-0.048,0.051-0.088,0.094-0.128,0.138c-0.206-0.73-0.463-1.977-0.116-2.536c0.072-0.132,0.196-0.228,0.343-0.264l0.09-0.016
					c0.266-0.037,0.366,0.072,0.408,0.173C221.715,406.098,221.371,407.335,220.907,408.104z M221.139,408.445
					c0.752-0.804,1.873-1.786,2.431-1.719c0.119,0.015,0.221,0.091,0.269,0.2c0.13,0.203,0.144,0.46,0.037,0.676
					C223.601,408.053,222.595,408.359,221.139,408.445z" />
                  </g>
                  <polygon style={{ fill: '#B55B52' }} points="212.269,412.53 219.477,409.04 215.19,391.857 207.994,395.351 			" />
                  <polygon style={{ opacity: '0.2', enableBackground: 'new' }} points="207.994,395.351 215.194,391.865 217.642,401.688 
				210.342,404.819 			" />
                  <path style={{ fill: '#263238' }} d="M219.747,407.941l-8.696,2.247c-0.307,0.065-0.503,0.366-0.438,0.673
				c0.001,0.003,0.001,0.006,0.002,0.009l1.356,6.997c0.194,0.75,0.955,1.204,1.707,1.02c3.022-0.834,4.423-1.382,8.242-2.401
				c2.347-0.613,7.044-1.603,10.12-2.946c3.076-1.343,1.666-4.348,0.245-4.051c-3.374,0.69-8.462-0.296-11.089-1.445
				C220.738,407.849,220.228,407.813,219.747,407.941z" />
                  <path style={{ fill: '#407BFF' }} d="M218.696,395.125c0,0-12.191-61.211-14.681-64.858c-4.068-5.958-52.885-15.662-52.885-15.662
				l5.734-1.028l-6.631-17.567l-43.473,1.715c0,0,0.911,28.715,5.329,36.416c4.419,7.7,69.816,11.236,74.056,12.98
				c4.24,1.745,17.226,49.772,17.226,49.772L218.696,395.125z" />
                  <path style={{ opacity: '0.4', fill: '#FFFFFF', enableBackground: 'new' }} d="M218.696,395.125c0,0-12.191-61.211-14.681-64.858
				c-4.068-5.958-52.885-15.662-52.885-15.662l5.734-1.028l-6.631-17.567l-43.473,1.715c0,0,0.911,28.715,5.329,36.416
				c4.419,7.7,69.816,11.236,74.056,12.98c4.24,1.745,17.226,49.772,17.226,49.772L218.696,395.125z" />
                  <polygon style={{ fill: '#407BFF' }} points="203.631,402.322 220.685,397.977 219.784,390.36 201.162,395.489 			" />
                  <polygon style={{ opacity: '0.2', fill: '#FFFFFF' }} points="203.631,402.322 220.685,397.977 219.784,390.36 201.162,395.489 			" />
                  <g>
                    <polygon style={{ fill: '#263238' }} points="120.516,298.384 107.919,298.165 107.648,301.978 121.065,302.303 				" />
                    <polygon style={{ fill: '#263238' }} points="122.457,298.312 123.012,302.273 151.13,301.874 150.22,298.259 				" />
                    <path style={{ fill: '#FFFFFF' }} d="M135.986,299.03l0.366,2.615c0.089,0.639,0.692,1.141,1.346,1.121l4.497-0.135
					c0.654-0.02,1.111-0.554,1.021-1.193l-0.366-2.615c-0.089-0.639-0.692-1.141-1.346-1.121l-4.497,0.136
					C136.354,297.857,135.897,298.391,135.986,299.03z" />
                  </g>
                  <g>
                    <path style={{ fill: '#263238' }} d="M107.857,297.613h41.3c1.027,0,1.901-0.765,2.031-1.784c5.098-39.958-4.638-53.81-4.638-53.81
					c-3.707-1.063-7.492-1.833-11.32-2.305c-5.59-0.444-11.202-0.565-16.806-0.363c-3.829,0.098-7.647,0.476-11.421,1.131
					c-2.046,0.865-4.075,9.782-2.048,37.537c0.531,7.27,0.616,13.552,0.564,17.232C105.501,296.554,106.554,297.613,107.857,297.613
					z" />
                    <polygon style={{ fill: '#263238' }} points="140.765,256.171 148.715,256.171 149.324,265.667 145.053,268.435 140.765,266.317 				
					" />
                    <path style={{ opacity: '0.2', fill: '#FFFFFF', enableBackground: 'new' }} d="M151.188,295.831c-0.13,1.02-1,1.78-2.03,1.78h-41.3
					c-1.3,0-2.36-1.06-2.34-2.36c0.05-3.68-0.03-9.96-0.56-17.23c-0.29-3.99-0.5-7.6-0.63-10.84c-0.34-7.85-0.24-13.6,0.1-17.72
					c0.5-6.02,1.53-8.53,2.57-8.98c3.78-0.65,7.6-1.03,11.43-1.13c5.6-0.2,11.21-0.08,16.8,0.36c3.83,0.48,7.61,1.25,11.32,2.31
					C146.548,242.021,156.288,255.871,151.188,295.831z" />
                  </g>
                  <path style={{ fill: '#B55B52' }} d="M118.799,238.943l13.344,4.549l0.541-3.824c-1.304-2.28-1.632-3.719-0.533-6.368l-8.906-10.233
				C123.485,228.312,122.533,235.475,118.799,238.943z" />
                  <path style={{ fill: '#FFFFFF' }} d="M127.24,245.473l2.616,1.085l2.288,1.438c0,0,3.045,17.456,2.192,32.192l2.562,2.104l2.704-2.175
				c0,0-3.357-27.064-4.567-32.338l0.524-1.264l1.234-2.623l-2.277-1.187l-3.605,0.789h-2.521L127.24,245.473z" />
                  <path style={{ opacity: '0.2', enableBackground: 'new' }} d="M126.874,227.24l5.269,6.059c-0.399,0.929-0.718,1.89-0.954,2.874
				c-2.23-0.667-4.982-3.639-4.869-6.059C126.326,229.13,126.514,228.155,126.874,227.24z" />
                  <path style={{ fill: '#263238' }} d="M119.63,236.521c0,0,5.181,3.608,12.019,6.246c0,0,0.81-3.033,0-5.67l5.894,2.942
				c0,0,0.488,4.575-0.866,6.948l-4.324-3.165l-4.859,3.956c0,0-9.204-3.551-10.644-8.369L119.63,236.521z" />
                  <path style={{ opacity: '0.2', enableBackground: 'new' }} d="M149.637,272.044l-13.659,10.987c-19.58,4.36-28.19-7.85-31.65-15.85
				c-0.34-7.85-0.24-13.6,0.1-17.72c4.7-0.22,10.55-0.22,12.13,0.99c2.75,2.11,12.33,16.904,13.76,17.984
				C131.758,269.525,149.637,272.044,149.637,272.044z" />
                  <g>
                    <path style={{ fill: '#B55B52' }} d="M157.398,271.476l7.169-2.587l-0.878,9.605c0,0-6.58,0.839-7.39-2.624L157.398,271.476z" />
                    <polygon style={{ fill: '#B55B52' }} points="170.505,270.832 169.411,278.739 163.689,278.494 164.567,268.889 				" />
                  </g>
                  <g>
                    <path style={{ fill: '#B65B52' }} d="M115.688,252.107l1.621,3.084c0.561,1.047,1.142,2.085,1.736,3.108
					c1.162,2.063,2.381,4.073,3.643,5.979c1.27,1.892,2.591,3.709,3.95,5.13c0.659,0.715,1.332,1.324,1.872,1.706
					c0.166,0.132,0.2,0.127,0.252,0.175c0.038,0.047,0.233,0.097,0.339,0.159c0.147,0.058,0.351,0.108,0.524,0.17l0.638,0.155
					c1.845,0.412,4.191,0.537,6.517,0.579c2.354,0.043,4.767-0.043,7.203-0.148c4.859-0.25,9.845-0.635,14.744-1.072l1.186,5.517
					c-4.925,1.59-9.841,2.873-14.941,3.827c-2.543,0.499-5.129,0.861-7.785,1.143c-2.681,0.235-5.378,0.389-8.498,0.051
					c-0.405-0.057-0.794-0.108-1.211-0.186c-0.434-0.095-0.845-0.172-1.298-0.296c-0.482-0.154-0.902-0.252-1.43-0.477
					c-0.517-0.216-1.063-0.463-1.424-0.674c-1.626-0.886-2.901-1.884-4.057-2.895c-2.258-2.058-4.078-4.184-5.71-6.378
					c-1.64-2.186-3.095-4.425-4.449-6.701c-0.664-1.146-1.303-2.3-1.924-3.465c-0.635-1.181-1.2-2.301-1.797-3.618L115.688,252.107z
					" />
                  </g>
                  <path style={{ fill: '#263238' }} d="M107.003,240.483c-12.904,4.651,3.83,36.789,10.865,40.39c7.034,3.601,18.926,2.074,18.926,2.074
				l0.836-11.166c0,0-6.163-0.746-8.003-1.187c-2.924-0.7-8.85-13.643-12.833-20.677S107.003,240.483,107.003,240.483z" />
                  <path style={{ opacity: '0.2', fill: '#FFFFFF', enableBackground: 'new' }} d="M107.003,240.483c-12.904,4.651,3.83,36.789,10.865,40.39
				c7.034,3.601,18.926,2.074,18.926,2.074l0.836-11.166c0,0-6.163-0.746-8.003-1.187c-2.924-0.7-8.85-13.643-12.833-20.677
				S107.003,240.483,107.003,240.483z" />
                  <polygon style={{ fill: '#263238' }} points="135.975,270.989 134.928,283.958 138.626,283.268 139.602,271.682 			" />
                  <g>
                    <path style={{ fill: '#263238' }} d="M146.176,212.532c0.514,2.992-0.781,8.82-4.53,6.36
					C137.898,216.431,145.293,207.389,146.176,212.532z" />
                    <path style={{ fill: '#B55B52' }} d="M124.974,213.604c-1.442,7.315-2.659,11.527,0.026,16.143c4.039,6.942,13.831,5.886,17.439-0.871
					c3.248-6.082,5.06-17.024-1.27-21.669C134.933,202.629,126.415,206.288,124.974,213.604z" />
                    <path style={{ fill: '#263238' }} d="M121.476,205.529l8.291-0.38l0.218,4.758c-0.157-0.089-0.334-0.175-0.481-0.266
					c0,0-0.527,6.948-4.49,9.325C119.637,214.865,118.063,208.165,121.476,205.529z" />
                    <path style={{ fill: '#B55B52' }} d="M120.624,218.878c0.09,2.004,1.104,3.773,2.347,4.856c1.869,1.629,3.89,0.213,4.28-2.109
					c0.351-2.09-0.199-5.455-2.512-6.192C122.459,214.708,120.521,216.587,120.624,218.878z" />
                    <path style={{ fill: '#263238' }} d="M135.418,218.374c-0.154,0.587,0.034,1.141,0.419,1.237c0.385,0.097,0.822-0.3,0.975-0.887
					c0.154-0.587-0.034-1.14-0.419-1.237C136.008,217.39,135.571,217.787,135.418,218.374z" />
                    <path style={{ fill: '#263238' }} d="M142.074,220.046c-0.153,0.587,0.034,1.141,0.419,1.237c0.385,0.097,0.821-0.3,0.975-0.887
					c0.153-0.587-0.034-1.14-0.419-1.237C142.665,219.063,142.228,219.46,142.074,220.046z" />
                    <path style={{ fill: '#A02724' }} d="M139.882,219.806c0,0,0.52,3.603,1.594,5.558c-1.268,0.658-2.923-0.231-2.923-0.231
					L139.882,219.806z" />
                    <path style={{ fill: '#263238' }} d="M134.81,226.584c0.233,0.093,0.484,0.175,0.753,0.243c0.096,0.024,0.193-0.034,0.218-0.129
					c0.025-0.096-0.034-0.192-0.128-0.216c-2.586-0.653-3.321-2.751-3.328-2.772c-0.031-0.093-0.133-0.142-0.226-0.11
					c-0.094,0.032-0.144,0.133-0.113,0.226C132.014,223.91,132.648,225.72,134.81,226.584z" />
                    <path style={{ fill: '#263238' }} d="M133.926,215.717c0.115,0.046,0.252,0.031,0.357-0.053c1.252-0.991,2.504-0.597,2.517-0.594
					c0.187,0.061,0.39-0.04,0.453-0.227c0.063-0.186-0.039-0.387-0.224-0.448c-0.066-0.022-1.636-0.516-3.188,0.714
					c-0.155,0.123-0.182,0.346-0.059,0.499C133.82,215.659,133.87,215.695,133.926,215.717z" />
                    <path style={{ fill: '#263238' }} d="M145.54,218.486c0.063,0.025,0.134,0.033,0.205,0.018c0.193-0.04,0.319-0.228,0.281-0.42
					c-0.388-1.928-1.913-2.528-1.978-2.564c-0.183-0.071-0.391,0.019-0.465,0.202c-0.073,0.182,0.015,0.387,0.196,0.459
					c0.065,0.023,1.233,0.5,1.545,2.047C145.349,218.35,145.433,218.443,145.54,218.486z" />
                    <path style={{ fill: '#263238' }} d="M126.944,211.424c0,0,0.182,0.167,0.502,0.351c0.639,0.369,1.826,0.809,3.202,0.132
					c2.064-1.015,2.711-6.874,8.575-3.892c0,0,2.008-0.605,3.519,1.244c1.511,1.849,0.467,8.604,3.691,7.669
					c3.223-0.934,2.662-1.928,2.662-1.928s-0.902,0.571-1.439,0.119c0,0,1.689-1.403,0.877-3.365c0,0-0.783,0.924-1.307,0.742
					c0,0,0.976-3.449-0.888-8.108c-1.864-4.659-13.306-3.618-13.306-3.618s-1.444-2.409-6.611-0.728
					c-5.167,1.681-7.527,7.907-6.708,10.622" />
                  </g>
                </g>
                <g>
                  <polygon style={{ fill: '#263238' }} points="195.199,277.737 194.46,274.948 164.134,274.948 150.169,276.342 149.637,277.737 			" />
                  <polygon style={{ opacity: '0.5', fill: '#FFFFFF', enableBackground: 'new' }} points="195.199,277.737 194.46,274.948 164.134,274.948 
				150.169,276.342 149.637,277.737 			" />
                  <polygon style={{ opacity: '0.2', enableBackground: 'new' }} points="164.134,274.948 164.134,277.737 195.199,277.737 
				194.46,274.948 			" />
                </g>
                <g>
                  <path style={{ fill: '#263238' }} d="M174.393,261.595c-0.135,1.473,0.942,2.67,2.408,2.67h46.457c1.473,0,2.775-1.196,2.909-2.67
				l2.759-29.742c0.03-0.307,0.007-0.598-0.06-0.867c-0.262-1.047-1.182-1.802-2.348-1.802h-46.457c-1.473,0-2.775,1.197-2.909,2.67
				L174.393,261.595z" />
                  <path style={{ opacity: '0.5', fill: '#FFFFFF', enableBackground: 'new' }} d="M174.393,261.595c-0.135,1.473,0.942,2.67,2.408,2.67
				h46.457c1.473,0,2.775-1.196,2.909-2.67l2.759-29.742c0.03-0.307,0.007-0.598-0.06-0.867c-0.262-1.047-1.182-1.802-2.348-1.802
				h-46.457c-1.473,0-2.775,1.197-2.909,2.67L174.393,261.595z" />
                  <path style={{ opacity: '0.1', enableBackground: 'new' }} d="M176.966,264.265h46.292c1.473,0,2.775-1.196,2.909-2.67l2.759-29.742
				c0.03-0.307,0.007-0.598-0.06-0.867h-46.292c-1.466,0-2.767,1.196-2.909,2.67l-2.76,29.742
				C176.876,263.704,176.899,263.995,176.966,264.265z" />
                  <polygon style={{ fill: '#263238' }} points="208.883,246.023 199.272,246.023 201.834,273.539 186.636,275.821 186.636,277.737 
				218.645,277.737 218.645,275.821 212.087,273.539 			" />
                  <polygon style={{ opacity: '0.5', fill: '#FFFFFF', enableBackground: 'new' }} points="208.883,246.023 199.272,246.023 201.834,273.539 
				186.636,275.821 186.636,277.737 218.645,277.737 218.645,275.821 212.087,273.539 			" />
                  <polygon style={{ opacity: '0.2', fill: '#FFFFFF', enableBackground: 'new' }} points="199.272,246.023 206.392,277.3 212.087,277.13 
				208.883,246.023 			" />
                </g>
                <g>
                  <g>
                    <path style={{ fill: '#263238' }} d="M119.941,417.118h58.198c0.632,0,1.144-0.512,1.144-1.144v-3.712
					c0-0.632-0.512-1.144-1.144-1.144h-55.198V292.302h54.257c1.152,0,2.085-0.934,2.085-2.085v-3.915h-59.342c-1.657,0-3,1.343-3,3
					v124.816C116.941,415.775,118.284,417.118,119.941,417.118z" />
                  </g>
                  <g style={{ opacity: '0.4', enableBackground: 'new' }}>
                    <path style={{ fill: '#FFFFFF' }} d="M119.941,417.118h58.198c0.632,0,1.144-0.512,1.144-1.144v-3.712
					c0-0.632-0.512-1.144-1.144-1.144h-55.198V292.302h54.257c1.152,0,2.085-0.934,2.085-2.085v-3.915h-59.342c-1.657,0-3,1.343-3,3
					v124.816C116.941,415.775,118.284,417.118,119.941,417.118z" />
                  </g>
                  <g>
                    <path style={{ fill: '#407BFF' }} d="M112.488,287.292h153.418c1.073,0,1.943-0.87,1.943-1.943v-4.725H110.545v4.725
					C110.545,286.422,111.415,287.292,112.488,287.292z" />
                  </g>
                  <rect x="110.545" y="280.624" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 378.3936 564.582)" style={{ opacity: '0.2', enableBackground: 'new' }} width="157.304" height="3.334" />
                  <g>
                    <path style={{ fill: '#407BFF' }} d="M107.246,281.493h163.901c1.037,0,1.878-0.841,1.878-1.878l0,0c0-1.037-0.841-1.878-1.878-1.878
					H107.246c-1.037,0-1.878,0.841-1.878,1.878l0,0C105.368,280.652,106.209,281.493,107.246,281.493z" />
                  </g>
                </g>
              </g>
            </g>
          </svg>

        </Grid>
        <Grid item xs={12} sm={8} md={5} style={{ background: "#aacbff" }} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ background: "transparent" }}>
              <img src={logo} alt='...' />
            </Avatar>
            <Typography component="h1" variant="h5">
              Admin Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
               
                onBlur={(e) => {
                  validateEmail(e.target.value);
                }}
              />

              {emailError && <span style={{ color: 'red', fontSize: "10px", fontWeight: "bold", textAlign: "left" }}>{emailError}</span>}

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
   
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to={'/employee-signin'} style={{ textDecoration: "underline", color: "gray" }}>
                    Employee Login
                  </Link>
                </Grid>
                <Grid item>
                  {/* <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
                </Grid>
              </Grid>
              
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ShowAlert/>

    </div>

  )
}

export default Signin_form