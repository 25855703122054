import React, { useEffect, useState, useRef } from "react";
import { useData } from "../ContextProvider/Context";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AddBouns from "../Components/AddBonus";
import { CiMenuKebab } from "react-icons/ci";
import { Link } from "react-router-dom";
import { CiRead } from "react-icons/ci";
import { useReactToPrint } from "react-to-print";
import { IoPrintOutline } from "react-icons/io5";
import filter from "../Images/filtericon.png";
import cross from "../Images/filtercross.png";
import vector from "../Images/Vector.png";
import { Drawer } from "@mui/material";
import emplyicon from "../Images/emplyicon.png";
import downarrow from "../Images/chevron-down.png";
import { SiNextra } from "react-icons/si";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Payroll() {
  const {
    getSalariesdata,
    salariesData,
    img_url,
    getSalariesIndividualData,
    salariesIndidualData,
    permission,
    getFilterCompany,
    company,
    branch,
    getEmployees,
    employees,
  } = useData();
  useEffect(() => {
    getSalariesdata();
    getSalariesIndividualData();
    getEmployees();
  }, []);
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const [currentDate1, setCurrentDate1] = useState(new Date());

  const formattedDate = currentDate1.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({});
  const toggleList = (companyId) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [companyId]: !prevState[companyId],
    }));
  };

  const handleRowSelect = (row) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow !== row)
      );
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // If "Select All" is currently checked, uncheck all individual checkboxes
      setSelectedRows([]);
    } else {
      // If "Select All" is currently unchecked, select all individual checkboxes
      const allRowKeys = salariesData.salaries.map((item) => item.employee.id);
      setSelectedRows(allRowKeys);
    }
    setSelectAll(!selectAll);
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = (id) => {
    getSalariesIndividualData(id);
    setOpen(true);
  };
  const contentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentSalaries =
    employees?.data?.slice(indexOfFirstItem, indexOfLastItem) || [];

  const totalPages = Math.ceil((employees?.data?.length || 0) / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }
  console.log(employees);
  return (
    <>
      <div className="services">
        <Drawer anchor="right" open={openDrawer} onClose={handleDrawerClose}>
          <div
            className="filterparent"
            style={{
              background: "white",
              padding: "18px",
              zIndex: "99",
              display: "felx",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {/* <div
              className="fpchild1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "160px",
              }}
            >
              <span style={{ fontSize: "22px", fontSize: "bold" }}>Filter</span>
              <button
                onClick={handleDrawerClose}
                style={{ background: "none", border: "none" }}
              >
                <img alt="" src={cross} />
              </button>
            </div> */}
            <div
              className="form-group"
              style={{
                marginBottom: "20px",
                textAlign: "left",
                position: "relative",
              }}
            >
              <label style={{ marginBottom: "5px" }} htmlFor="company">
                Company
              </label>
              <img
                style={{ position: "absolute", right: "16px", top: "40px" }}
                src={downarrow}
              />
              <select
                id="company"
                style={{
                  width: "100%",
                  padding: "7px",
                }}
                className="form-control"
                // disabled={params.id ? true : false}
                onChange={(e) => {
                  getFilterCompany(e.target.value);
                  // storeRecentCompany({
                  //   name: e.target.value,
                  // });
                }}
              >
                <option hidden> Company</option>
                <option value={""}>All Company</option>
                {company?.data?.map((item) => {
                  return (
                    <>
                      {item?.deleteStatus == 0 && (
                        <option value={item.name} key={item.id}>
                          {item.name}
                        </option>
                      )}
                    </>
                  );
                })}
              </select>
            </div>
            <div
              className="form-group"
              style={{
                marginBottom: "20px",
                textAlign: "left",
                position: "relative",
              }}
            >
              <label htmlFor="brancName">Designation</label>
              <select
                style={{ borderRadius: "10px", width: "100%" }}
                placeholder="Select Branch"
                disabled={!branch}
                className="form-select"
              >
                <option hidden>Name</option>
              </select>
            </div>
          </div>
        </Drawer>
        <div className="expense-header d-flex justify-content-between align-items-center">
          <div>
            <nav>
              <ul style={{ padding: "3px" }}>
                <li
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Payroll
                </li>
                <li>{formattedDate}</li>
              </ul>
            </nav>
          </div>
          <div className="inp-buttons d-flex gap-2">
            <Link to={"/admin/Create-Bouns"}>
              <button
                type="button"
                className="btn btn-primary"
                style={{
                  padding: "8px 16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <SiNextra />
                <span> Create Bonus</span>
              </button>
            </Link>
          </div>
        </div>

        <div className="card">
          <div className="card-body table-responsive recruitment">
            <div
              className="headtable"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "100px",
                }}
              >
                <h5>All Payees</h5>
              </div>
              <div>
                <button
                  onClick={handleDrawerOpen}
                  style={{
                    background: "transparent",
                    borderRadius: "10px",
                    border: "1px solid darkgray",
                    padding: "2px 5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Filter
                  <img
                    style={{ width: "20%", marginLeft: "2px" }}
                    src={filter}
                  />
                </button>
              </div>
            </div>
            <table className="table w-100 payrollTable">
              <thead style={{ textAlign: "center" }}>
                <tr>
                  {/* <th scope="col">
                                <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                            </th> */}
                  <th scope="col">Emp ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Address</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Salary</th>
                  <th scope="col">Slip</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {currentSalaries?.map((items, i) => {
                  return (
                    <tr key={items.id}>
                      {/* <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedRows.includes(items.id)}
                                            onChange={() => handleRowSelect(items.id)}
                                        />
                                    </td> */}
                      <td>{items.name}</td>
                      <td
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            height: "30px",
                            width: "30px",
                            overflow: "hidden",
                            borderRadius: "50%",
                          }}
                        >
                          <img
                            src={img_url + items.profile}
                            alt=""
                            style={{ height: "100%", width: "100%" }}
                          />
                        </div>
                        <div>
                          <Link
                            style={{ color: "black" }}
                            to={`/admin/employees/profile/${items?.employee?.id}`}
                          >
                            <p>{items.name}</p>
                          </Link>
                        </div>
                      </td>
                      <td>{items.email}</td>
                      <td>{items.phone}</td>
                      <td>{items.address}</td>
                      <td>{items.designation?.title}</td>
                      <td>
                        <span>{items.salary}</span>
                      </td>
                      <td>
                        <button
                          className="btn btn-warning p-2"
                          onClick={() => handleOpen(items.id)}
                        >
                          <CiRead style={{ fontSize: "20px" }} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div>
              {paginationControls.map((button, index) => (
                <React.Fragment key={index}>{button}</React.Fragment>
              ))}
            </div>
          </div>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ border: "0" }}
        >
          <Box
            sx={style}
            className="payslipModal border-0"
            style={{
              width: "50%",
              height: "630px",
              overflowY: "scroll",
              padding: "1rem",
            }}
          >
            <div className="row my-3 d-flex justify-content-end">
              <button
                className="btn btn-primary"
                style={{ width: "100px", marginRight: "1rem" }}
                onClick={handlePrint}
              >
                <IoPrintOutline />
                 Print
              </button>
              <button
                className="btn btn-danger"
                style={{ width: "100px" }}
                onClick={handleClose}
              >
                Close
              </button>
            </div>
            <div className="p-2" ref={contentRef}>
              <div className="paySlipData">
                <div className="paySlipHeader text-center">
                  <h3 style={{ fontWeight: "600", fontSize: "23px" }}>
                    Payslip
                  </h3>
                  <h5 style={{ fontWeight: "500", fontSize: "15px" }}>
                    {salariesIndidualData?.comapny?.name}
                  </h5>
                  <h5 style={{ fontWeight: "500", fontSize: "15px" }}>
                    {salariesIndidualData?.comapny?.address}
                  </h5>
                </div>
                <div className="paySlipEmployeeDetail my-3">
                  <div className="row">
                    <div className="col-6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <p style={{ fontWeight: "bold" }}>Date of Joining</p>
                          <p style={{ fontWeight: "bold" }}>Pay Period</p>
                          <p style={{ fontWeight: "bold" }}>Overtime</p>
                          <p style={{ fontWeight: "bold" }}>
                            Work Hours in Month
                          </p>
                          <p style={{ fontWeight: "bold" }}>
                            Worked Hours in Month
                          </p>
                        </div>
                        <div>
                          <p>{salariesIndidualData?.Date_of_joining}</p>
                          <p>{salariesIndidualData?.daysInMonth}</p>
                          <p>{salariesIndidualData?.overtime}</p>
                          <p>{salariesIndidualData?.monthlyHours}</p>
                          <p>{salariesIndidualData?.Worked_Hours}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <p style={{ fontWeight: "bold" }}>Employee Name</p>
                          <p style={{ fontWeight: "bold" }}>Designation</p>
                          <p style={{ fontWeight: "bold" }}>Department</p>
                          <p style={{ fontWeight: "bold" }}>Month</p>
                          <p style={{ fontWeight: "bold" }}>Basic Salary</p>
                        </div>
                        <div>
                          <p>{salariesIndidualData?.Employee_name}</p>
                          <p>{salariesIndidualData?.designation?.title}</p>
                          <p>{salariesIndidualData?.department?.department}</p>
                          <p>{salariesIndidualData?.current_month}</p>
                          <p>
                            {salariesIndidualData?.empSalary?.toLocaleString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="paySlipDataTable">
                  <div className="dataTable">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{ background: "#cbcbcb", fontSize: "16px" }}
                          >
                            Earnings
                          </th>
                          <th
                            scope="col"
                            style={{ background: "#cbcbcb", fontSize: "16px" }}
                          >
                            Amount
                          </th>
                          <th
                            scope="col"
                            style={{ background: "#cbcbcb", fontSize: "16px" }}
                          >
                            Deductions
                          </th>
                          <th
                            scope="col"
                            style={{ background: "#cbcbcb", fontSize: "16px" }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Salary</td>
                          <td style={{ fontSize: "16px" }}>
                            {salariesIndidualData?.workedHourSalary?.toLocaleString()
                              ? salariesIndidualData?.workedHourSalary?.toLocaleString()
                              : "-"}
                          </td>
                          <td style={{ fontSize: "16px" }}></td>
                          <td style={{ fontSize: "16px" }}></td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Commission</td>
                          <td style={{ fontSize: "16px" }}>
                            {salariesIndidualData?.commission?.toLocaleString()
                              ? salariesIndidualData?.commission?.toLocaleString()
                              : "-"}
                          </td>
                          <td style={{ fontSize: "16px" }}>Professtion Tax</td>
                          <td style={{ fontSize: "16px" }}>
                            {salariesIndidualData?.tax
                              ? salariesIndidualData?.tax
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Bonus</td>
                          <td style={{ fontSize: "16px" }}>
                            {salariesIndidualData?.bonus?.toLocaleString()
                              ? salariesIndidualData?.bonus?.toLocaleString()
                              : "-"}
                          </td>
                          <td style={{ fontSize: "16px" }}>Loan</td>
                          <td style={{ fontSize: "16px" }}>
                            {salariesIndidualData?.loan?.toLocaleString()
                              ? salariesIndidualData?.loan?.toLocaleString()
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>
                            House Rent Allowance
                          </td>
                          <td style={{ fontSize: "16px" }}>
                            {salariesIndidualData?.house_rent_allowance?.toLocaleString()
                              ? salariesIndidualData?.house_rent_allowance?.toLocaleString()
                              : "-"}
                          </td>
                          <td style={{ fontSize: "16px" }}>Fine</td>
                          <td style={{ fontSize: "16px" }}>
                            {salariesIndidualData?.fine?.toLocaleString()
                              ? salariesIndidualData?.fine?.toLocaleString()
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Meal Allowance</td>
                          <td style={{ fontSize: "16px" }}>
                            {salariesIndidualData?.meal_allowance?.toLocaleString()
                              ? salariesIndidualData?.meal_allowance?.toLocaleString()
                              : "-"}
                          </td>
                          <td style={{ fontSize: "16px" }}></td>
                          <td style={{ fontSize: "16px" }}></td>
                        </tr>

                        <tr>
                          <td style={{ fontSize: "16px" }}>Travel Allowance</td>
                          <td style={{ fontSize: "16px" }}>
                            {salariesIndidualData?.travel_allowance?.toLocaleString()
                              ? salariesIndidualData?.travel_allowance?.toLocaleString()
                              : "-"}
                          </td>
                          <td style={{ fontSize: "16px" }}></td>
                          <td style={{ fontSize: "16px" }}></td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px", fontWeight: "bold" }}>
                            Total
                          </td>
                          <td>
                            {parseInt(
                              salariesIndidualData?.commission
                                ? salariesIndidualData?.commission
                                : 0
                            ) +
                              parseInt(
                                salariesIndidualData?.bonus
                                  ? salariesIndidualData?.bonus
                                  : 0
                              ) +
                              parseInt(
                                salariesIndidualData?.house_rent_allowance
                                  ? salariesIndidualData?.house_rent_allowance
                                  : 0
                              ) +
                              parseInt(
                                salariesIndidualData?.meal_allowance
                                  ? salariesIndidualData?.meal_allowance
                                  : 0
                              ) +
                              parseInt(
                                salariesIndidualData?.travel_allowance
                                  ? salariesIndidualData?.travel_allowance
                                  : 0
                              ) +
                              parseInt(
                                salariesIndidualData?.workedHourSalary
                                  ? salariesIndidualData?.workedHourSalary
                                  : 0
                              )}
                          </td>
                          <td style={{ fontSize: "16px", fontWeight: "bold" }}>
                            Total
                          </td>
                          <td>
                            {parseInt(salariesIndidualData?.tax) +
                              parseInt(salariesIndidualData?.loan) +
                              parseInt(salariesIndidualData?.fine)}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}></td>
                          <td style={{ fontSize: "16px" }}></td>
                          <td style={{ fontSize: "16px", fontWeight: "bold" }}>
                            Net Pay
                          </td>
                          <td style={{ fontSize: "16px", fontWeight: "bold" }}>
                            {salariesIndidualData?.Final_Salary?.toLocaleString()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="paySlipFooter mt-3">
                  <div className="row">
                    <div className="col-6">
                      <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
                        Employer Signature
                      </h4>
                      <p>______________________</p>
                    </div>
                    <div className="col-6 text-end">
                      <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
                        Employee Signature
                      </h4>
                      <p>______________________</p>
                    </div>
                  </div>
                  <div className="row text-center">
                    <p>This is system generated payslip</p>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default Payroll;
