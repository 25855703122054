import { Link, useParams } from 'react-router-dom'
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';

function Reports() {
    const params = useParams()
    const [accordionState, setAccordionState] = useState({
        item1: false,
        item2: false,
        item3: false,
    });

    const toggleAccordionItem = (item) => {
        setAccordionState((prevState) => ({
            ...prevState,
            [item]: !prevState[item],
        }));
    };

    return (
        <>
            <div>
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Reports</li>
                        </ol>
                    </nav>
                </div>
                <hr />
                <div className='card mb-3'>
                    <div className='card-body p-3'>
                        <div className='accordion-item mb-3 p-2' style={{ border: "1px solid lightgray" }}>
                            <div className='accordion-header' style={{ display: "flex", gap: "10px", alignItems: "center" }} onClick={() => toggleAccordionItem('item1')}>
                                <p style={{ fontWeight: "bold", fontSize: "15px", margin: "0" }}>Business Overview</p>
                                <span>{accordionState.item1 ? <FaChevronUp /> : <FaChevronDown />}</span>
                            </div>
                            {accordionState.item1 && (
                                <div className='accordion-content'>
                                    <div>
                                        <ul>
                                            <li>
                                                <Link to={`/admin/balanceSheetDetail/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Balance Sheet Detail</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/balanceSheetSummary/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Balance Sheet Summary</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/balanceSheet/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Balance Sheet</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/profitLossDetail/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Profit & loss</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/profitLossByCustomer/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Profit & loss by Customer</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/profitLossByLocation/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Profit & loss by Location</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/profitLossByClass/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Profit & loss by Class</p>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <div className='accordion-item mb-3 p-2' style={{ border: "1px solid lightgray" }}>
                            <div className='accordion-header' style={{ display: "flex", gap: "10px", alignItems: "center" }} onClick={() => toggleAccordionItem('item2')}>
                                <p style={{ fontWeight: "bold", fontSize: "15px", margin: "0" }}>Business Overview</p>
                                <span>{accordionState.item2 ? <FaChevronUp /> : <FaChevronDown />}</span>
                            </div>
                            {accordionState.item2 && (
                                <div className='accordion-content'>
                                    <div>
                                        <ul>
                                            <li>
                                                <Link to={`/admin/balanceSheetDetail/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Balance Sheet Detail</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/balanceSheet/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Balance Sheet Summary</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/balanceSheet/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Balance Sheet</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/balanceSheet/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Profit & loss</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/balanceSheet/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Profit & loss by Customer</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/balanceSheet/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Profit & loss by Location</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/balanceSheet/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Profit & loss by Class</p>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div> */}
                    </div>
                </div>
                <div className='card mb-3'>
                    <div className='card-body p-3'>
                        <div className='accordion-item mb-3 p-2' style={{ border: "1px solid lightgray" }}>
                            <div className='accordion-header' style={{ display: "flex", gap: "10px", alignItems: "center" }} onClick={() => toggleAccordionItem('item2')}>
                                <p style={{ fontWeight: "bold", fontSize: "15px", margin: "0" }}>Who owes you</p>
                                <span>{accordionState.item2 ? <FaChevronUp /> : <FaChevronDown />}</span>
                            </div>
                            {accordionState.item2 && (
                                <div className='accordion-content'>
                                    <div>
                                        <ul>
                                            <li>
                                                <Link to={`/admin/agingSummary/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Account Recieving Ageing Summary</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/agingDetail/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Accounts Receivable Ageing Detail</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/collectionReport/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Collections Report</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/balanceSummary/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Customer Balance Summary</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/balanceDetail/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Customer Balance Detail</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/invoiceList/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Invoice List</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/openInvoices/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Open Invoices</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admin/statementList/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Statements List</p>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='card mb-3'>
                    <div className='card-body p-3'>
                        <div className='accordion-item mb-3 p-2' style={{ border: "1px solid lightgray" }}>
                            <div className='accordion-header' style={{ display: "flex", gap: "10px", alignItems: "center" }} onClick={() => toggleAccordionItem('item3')}>
                                <p style={{ fontWeight: "bold", fontSize: "15px", margin: "0" }}>Sales and customers</p>
                                <span>{accordionState.item3 ? <FaChevronUp /> : <FaChevronDown />}</span>
                            </div>
                            {accordionState.item3 && (
                                <div className='accordion-content'>
                                    <div>
                                        <ul>
                                            <li>
                                                <Link to={`/admin/customersList/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Customer Contact List</p>
                                                </Link>
                                                <Link to={`/admin/saleIncome/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Sale Income</p>
                                                </Link>
                                                <Link to={`/admin/saleSummary/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Customers Sales Summary</p>
                                                </Link>
                                                <Link to={`/admin/saleSummaryDetail/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Sale Summary Detail</p>
                                                </Link>
                                                <Link to={`/admin/salesbylocation/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Sales by Locations Detail</p>
                                                </Link>
                                                <Link to={`/admin/salesbyclass/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Sales by Classes Detail</p>
                                                </Link>
                                                <Link to={`/admin/estimatesbycustomer/${params?.id}`} className='link_hover'>
                                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Customers Estimate</p>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Reports;
