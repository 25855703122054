import React, { useEffect, useState } from 'react'
import { BiCategory, BiUser, BiMessageDetail } from 'react-icons/bi'
import { TbCategory2 } from 'react-icons/tb'
import { MdPayment } from 'react-icons/md'
import { RiSecurePaymentLine, RiAccountPinCircleLine } from 'react-icons/ri'
import { BsCashCoin, BsFillImageFill } from 'react-icons/bs'
import { VscReferences, VscGroupByRefType } from 'react-icons/vsc'
import { GrServices } from 'react-icons/gr'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import { CiBoxList, CiCalendar, CiEdit, CiMoneyBill, CiPhone, CiTrash } from 'react-icons/ci'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function BundleOfServices() {
    const params = useParams()
    const { servicesGData,
        getServicesGData,
        storeServiceGData,
        updateServiceGData,
        getSupplierData, supplierData, updateServiceData, getSubcateAC, subcateAC, accountsDataC, getAccountsDataC, getsalesPurchase, salesPurchase, getServicesFIlterCom, accountsData, getAccountsData, deleteServiceGData, handleClick, setSnackbarValue, getServicesData, servicesData, img_url, storeServiceData, getServicesCategory, servicecategory, getFilterServices } = useData()
    useEffect(() => {
        getServicesData()
        getServicesCategory()
        getAccountsData()
        getAccountsDataC()
        getsalesPurchase()
        getSupplierData()
        getServicesGData()
    }, [])
    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedCompany, setSelectedCompany] = useState(params?.id || null)
    const [selectedCate, setSelectedCate] = useState(params?.id || null)
    const filteredCompanies = accountsData?.company?.filter(item => item.deleteStatus === 0);
    const filteredCate = accountsData?.servicecategory?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));
    const filteredSubcate = accountsData?.servicecategory?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj({
            company_id: params.id ? params.id : selectedCompany,
            name: '',
            description: '',
            group_service: [
                {
                    service_id: '',
                    quantity: ''
                }
            ]
        });
        setOpen(false)
    };
    const [obj, setObj] = useState({
        company_id: params.id ? params.id : selectedCompany,
        name: '',
        description: '',
        group_service: [
            {
                service_id: '',
                quantity: ''
            }
        ]
    })
    const clearFields = () => {
        setObj({
            company_id: params.id ? params.id : selectedCompany,
            name: '',
            description: '',
            group_service: [
                {
                    service_id: '',
                    quantity: ''
                }
            ]
        });
    };

    const isFormValid = () => {
        for (const key in obj) {
            if (obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = () => {
        if (isFormValid()) {
            handleClose()
            storeServiceGData(obj);
            clearFields();
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const handleUpdate = () => {
        if (isFormValid()) {
            handleClose()
            updateServiceData(obj);
            clearFields();
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const [data, setData] = useState({
        name: ''
    })
    const [showImageOverlay, setShowImageOverlay] = useState(false);
    const [selectedImageURL, setSelectedImageURL] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleServicesG = params.id
        ? servicesGData?.data?.filter(item => item?.company?.id == params.id) || []
        : servicesGData?.data || [];

    const currentServices = visibleServicesG?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleServicesG.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const addTaxRateRow = () => {
        setObj({
            ...obj,
            group_service: [
                ...obj.group_service,
                {
                    service_id: '',
                    quantity: '',
                },
            ],
        });
    };
    const removeTaxRateRow = (index) => {
        const updatedTaxType = [...obj.group_service];
        updatedTaxType.splice(index, 1);
        setObj({ ...obj, group_service: updatedTaxType });
    };
    console.log(obj);
    return (
        <>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item"><Link to={"/admin/accounts/banking/Services"}>Service</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Bundle of Services</li>
                        </ol>
                    </nav>
                    <div className='inp-buttons d-flex gap-2' style={{ alignItems: "end" }}>
                        <div>
                            <select disabled={params.id ? true : false} className='form-select' onChange={(e) => {
                                handlePageChange(1)
                                getServicesFIlterCom(e.target.value)
                            }}>
                                <option value="">Select a company</option>
                                {filteredCompanies?.map((item) => (
                                    <option key={item.id} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* <div className='clientName'>
                            <select className='form-select' onChange={(e) => {
                                const selectedCategory = e.target.value;
                                setData({ ...data, name: selectedCategory });
                                getFilterServices(selectedCategory);
                                handlePageChange(1)
                            }}>
                                <option hidden>All Category</option>
                                <option value=''>All Category</option>
                                {filteredCate?.map((item) => {
                                    return (
                                        <option value={item.name} key={item.id}>{item.name}</option>
                                    )
                                })}
                            </select>

                        </div> */}
                        <button type="button" className="btn btn-primary" onClick={handleOpen}>Add</button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} style={{ height: "600px", overflowY: "scroll" }} className='scrollBar'>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Add
                                </Typography>
                                <div id="modal-modal-description" sx={{ mt: 2 }}>
                                    <div className='row'>
                                        <div className='col-6 mb-3'>
                                            <label>Company</label>
                                            <div className='selectBox'>
                                                <select className='form-select'
                                                    value={obj?.company_id}
                                                    onChange={(e) => setObj({ ...obj, company_id: e.target.value })}
                                                >
                                                    <option hidden>Select Company</option>
                                                    {filteredCompanies?.map((item) => {
                                                        return (
                                                            <>{item?.deleteStatus == 0 &&
                                                                <option value={item.id} key={item.name}>
                                                                    {item.name}
                                                                </option>
                                                            }
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-6 mb-3'>
                                            <label>Name</label>
                                            <div className='inputBox'>
                                                <input value={obj?.name} onChange={(e) => setObj({ ...obj, name: e.target.value })} type="text" placeholder='Group Name' />
                                            </div>
                                        </div>
                                        <div className='col-12 mb-3'>
                                            <label>Description</label>
                                            <div className='inputBox'>
                                                <input value={obj?.description} onChange={(e) => setObj({ ...obj, description: e.target.value })} type="text" placeholder='Description' />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {obj?.group_service.map((item, index) => (
                                            <div className='row' key={index}>
                                                <div className='col-6 mb-3'>
                                                    <label>Service</label>
                                                    <div className='selectBox'>
                                                        <select
                                                            className='form-select'
                                                            value={item.service_id}
                                                            onChange={(e) => {
                                                                const updateItem = [...obj.group_service];
                                                                updateItem[index].service_id = e.target.value;
                                                                setObj({ ...obj, group_service: updateItem });
                                                            }}
                                                        >
                                                            <option value="">Select </option>
                                                            {servicesData?.services?.map((item) => (
                                                                <option value={item.id} key={item.name}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-6 mb-3'>
                                                    <label>Quantity </label>
                                                    <div className='inputBox'>
                                                        <input
                                                            value={item.quantity}
                                                            onChange={(e) => {
                                                                const updateItem = [...obj.group_service];
                                                                updateItem[index].quantity = e.target.value;
                                                                setObj({ ...obj, group_service: updateItem });
                                                            }}
                                                            type="number" placeholder='Quantity' />
                                                    </div>
                                                </div>
                                                {
                                                    obj.group_service.length > 1 && (
                                                        <button className='btn btn-danger p-2' style={{ width: "fit-content" }} onClick={() => removeTaxRateRow(index)}>
                                                            -
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        ))}
                                    </div>
                                    <button className='btn btn-primary' onClick={addTaxRateRow}>
                                        Add more
                                    </button>
                                    <div className='row mt-4'>
                                        <div className='modalBtns d-flex justify-content-end gap-2'>
                                            <button className='btn btn-primary' onClick={() => {
                                                handleSubmit()
                                            }}>Submit</button>
                                            <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table w-100">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {params?.id ? currentServices?.map((item, idx) => {
                                    const truncatedDescription = item.description?.slice(0, 20);
                                    const isDescriptionTruncated = item.description?.length > 20;

                                    return (
                                        <>
                                            {item?.company?.id == params?.id && <tr>
                                                <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                <td>{item.company?.name}</td>
                                                <td>
                                                    <Link to={`/admin/accounts/banking/bundles/${item?.id}`} className='link_hover' style={{ color: "inherit" }}>
                                                        {item.name}
                                                    </Link>
                                                </td>
                                                <td>
                                                    {
                                                        isDescriptionTruncated ? (
                                                            <div
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setSelectedDescription(item.description);
                                                                    setShowDescriptionOverlay(true);
                                                                }}
                                                            >
                                                                {truncatedDescription}...
                                                            </div>
                                                        ) : (
                                                            <div>{item.description ? item.description : '-'}</div>
                                                        )
                                                    }
                                                </td>
                                                {showDescriptionOverlay && (
                                                    <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                        <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                            <p style={{ color: "white" }}>{selectedDescription}</p>
                                                        </div>
                                                    </div>
                                                )}
                                                <td>
                                                    <button onClick={() => deleteServiceGData(item.id)} className='btn p-1 m-2 btn-danger'>
                                                        <CiTrash style={{ fontSize: "20px" }} />
                                                    </button>
                                                </td>
                                            </tr>}
                                        </>
                                    )
                                }) : currentServices?.length > 0 ? currentServices?.map((item, idx) => {
                                    const truncatedDescription = item.description?.slice(0, 20);
                                    const isDescriptionTruncated = item.description?.length > 20;

                                    return (
                                        <tr>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{item.company?.name}</td>
                                            <td>
                                                <Link to={`/admin/accounts/banking/bundles/${item?.id}`} className='link_hover' style={{ color: "inherit" }}>
                                                    {item.name}
                                                </Link>
                                            </td>
                                            <td>
                                                {
                                                    isDescriptionTruncated ? (
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedDescription(item.description);
                                                                setShowDescriptionOverlay(true);
                                                            }}
                                                        >
                                                            {truncatedDescription}...
                                                        </div>
                                                    ) : (
                                                        <div>{item.description ? item.description : '-'}</div>
                                                    )
                                                }
                                            </td>
                                            {showDescriptionOverlay && (
                                                <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                    <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                        <p style={{ color: "white" }}>{selectedDescription}</p>
                                                    </div>
                                                </div>
                                            )}
                                            <td>
                                                <button onClick={() => deleteServiceGData(item.id)} className='btn btn-danger p-1 m-2'>
                                                    <CiTrash style={{ fontSize: "20px" }} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            No data available
                                        </td>
                                    </tr>

                                )}
                            </tbody>
                        </table>
                        <div className="pagination-controls">{paginationControls}</div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default BundleOfServices
