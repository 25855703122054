import React, { useEffect, useRef, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import { useReactToPrint } from 'react-to-print'

function CustomerStatement() {
    const params = useParams()
    const { getAllInvoicesData, allInvoiceData, getAccountsDataC, accountsDataC, storeCustomerSatement, getsalesPurchase, getServicesData, getInvoiceNumberData, getClientIndiData, clientIndiData, } = useData()
    useEffect(() => {
        getInvoiceNumberData()
        getClientIndiData(params?.id)
        getServicesData()
        getsalesPurchase()
        getAccountsDataC()
        getAllInvoicesData()
    }, [])
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [obj, setObj] = useState({
        company_id: clientIndiData?.data?.company?.id,
        statement_date: new Date().toISOString().split('T')[0],
        start_date: new Date().toISOString().split('T')[0],
        end_date: new Date().toISOString().split('T')[0],
        client_id: clientIndiData?.data?.id,
        invoice_id: '',
        total_amount: '',
        statement_type: ''
    });
    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current) {
            handleDownloadPDF();
        }
    }, [cardRef]);
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return;
        }

        setPdfGenerating(true);

        setTimeout(() => {
            const card = cardRef.current;

            const pdfOptions = {
                margin: 0,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };

            if (card) {
                html2pdf()?.from(card)?.set(pdfOptions)?.outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' });

                    // Create a download link
                    const url = URL.createObjectURL(blob);
                    const a = document?.createElement('a');
                    a.href = url;
                    a.download = 'invoice.pdf';
                    a.style.display = 'none';

                    // Append the link to the body and trigger the click
                    document?.body?.appendChild(a);
                    a.click();

                    // Remove the link after the download
                    document?.body?.removeChild(a);

                    setPdfGenerating(false);
                }).catch((error) => {
                    console.error('Error generating PDF:', error);
                    setPdfGenerating(false);
                });
            } else {
                console.error('Card element not found.');
                setPdfGenerating(false);
            }
        }, 0);
    };

    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    });
    const billingAddress = clientIndiData?.data?.bill_address + ',' + clientIndiData?.data?.bill_city + ',' + clientIndiData?.data?.bill_province + ',' + clientIndiData?.data?.bill_country + ',' + clientIndiData?.data?.bill_postal
    const handleSubmit = () => {
        storeCustomerSatement(obj)
    }

    const handleInvoiceChange = (e) => {
        const selectedId = e.target.value;
        const selectedInvoice = allInvoiceData?.invoices?.find(item => item.id === parseInt(selectedId));

        setObj({
            ...obj,
            invoice_id: selectedId,
            total_amount: selectedInvoice?.total_amount || '',
        });

        setSelectedInvoice(selectedInvoice);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Data = reader.result;

                setObj((prevObj) => ({
                    ...prevObj,
                    image: base64Data,
                }));
            };

            reader.readAsDataURL(file);
        }
    };
    return (
        <div>
            <div className="card" ref={contentRef}>
                <div className="card-body">
                    <div className="container mb-5 mt-3">
                        <div className="d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                            <div>
                                <p style={{ color: '#7e8d9f', fontSize: '20px' }}>Statement</p>
                            </div>
                            <div>
                                <div className='card p-3 text-center'>
                                    <h4 style={{ fontSize: "25px", fontwe: "bold" }}>PRs, {Math.round(selectedInvoice?.total_amount) ? Math.round(selectedInvoice?.total_amount) : '0'}</h4>
                                    <p className='p-0 m-0' style={{ fontSize: "13px" }}>Balance Due</p>
                                </div>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary text-capitalize mx-3"
                                    style={{ backgroundColor: '#60bdf3' }}
                                    onClick={handlePrint}
                                >
                                    Download PDF
                                </button>
                                <button className='btn btn-success' onClick={handleSubmit}>Save Statement</button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className="row">
                            <div className="col-md-6" style={{ display: "flex", justifyContent: "start" }}>
                                <div>
                                    <div style={{ height: "65px", }}>
                                        <label>Customer</label>
                                        <p className='bbClass' style={{ fontSize: "15px", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{clientIndiData?.data?.name ? clientIndiData?.data?.name : '-'}</p>
                                    </div>
                                    <div style={{ height: "65px", }}>
                                        <label>Email</label>
                                        <p className='bbClass' style={{ fontSize: "15px", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{clientIndiData?.data?.email ? clientIndiData?.data?.email : '-'}</p>
                                    </div>
                                    <div>
                                        <label>Billing address</label>
                                        <p className='bbClass' style={{ fontSize: "15px", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{billingAddress ? billingAddress : '-'}</p>
                                    </div>
                                    <div style={{ height: "65px" }}>
                                        <label>Invoices</label>
                                        <select
                                            className='form-select'
                                            style={{ width: '300px', fontSize: '12px' }}
                                            onChange={handleInvoiceChange}
                                        >
                                            <option hidden>Select</option>
                                            {allInvoiceData?.invoices?.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.invoice_no}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ display: "flex", justifyContent: "end" }}>
                                <div style={{ width: "50%" }}>
                                    <div style={{ height: "65px", dth: "300px" }}>
                                        <label>Statement Type</label>
                                        <select
                                            className='form-select'
                                            style={{ fontSize: "13px" }}
                                            value={obj?.statement_type} onChange={(e) => setObj({ ...obj, statement_type: e.target.value })}
                                        >
                                            <option hidden>Select</option>
                                            <option value='Balance Forward'>Balance Forward</option>
                                        </select>
                                    </div>
                                    <div style={{ height: "65px", dth: "300px" }}>
                                        <label>Statement Date</label>
                                        <input type="date" style={{ fontSize: "13px" }} value={obj?.statement_date} onChange={(e) => setObj({ ...obj, statement_date: e.target.value })} className='form-control' />
                                    </div>
                                    <div style={{ height: "65px", dth: "300px" }}>
                                        <label>Start Date</label>
                                        <input type="date" style={{ fontSize: "13px" }} value={obj?.start_date} onChange={(e) => setObj({ ...obj, start_date: e.target.value })} className='form-control' />
                                    </div>
                                    <div style={{ height: "65px", dth: "300px" }}>
                                        <label>End Date</label>
                                        <input type="date" style={{ fontSize: "13px" }} value={obj?.end_date} onChange={(e) => setObj({ ...obj, end_date: e.target.value })} className='form-control' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-2 mx-1 justify-content-center">
                            <table className="table table-striped table-borderless">
                                <thead style={{ backgroundColor: '#84B0CA' }} className="text-white">
                                    <tr>
                                        <th scope="col" style={{ fontSize: '12px' }}>#</th>
                                        <th scope="col" style={{ fontSize: '12px' }}>Receipt</th>
                                        <th scope="col" style={{ fontSize: '12px' }}>Email</th>
                                        <th scope="col" style={{ fontSize: '12px' }}>Invoice</th>
                                        <th scope="col" style={{ fontSize: '12px' }}>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedInvoice ? (
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>{selectedInvoice?.client?.name}</td>
                                            <td>{selectedInvoice?.client?.email}</td>
                                            <td>
                                                <Link className='link_hover' style={{ color: "inherit" }} to={`/admin/Invoice/${selectedInvoice?.id}`}>
                                                    Invoice {selectedInvoice?.invoice_no}
                                                </Link>
                                            </td>
                                            <td>{selectedInvoice?.total_amount}</td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">Select an invoice to display details</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col-xl-12" style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                <p className="text-black float-start"><span className="text-black me-3"> Total Amount</span><span style={{ fontSize: '25px' }}>{selectedInvoice?.total_amount ? selectedInvoice?.total_amount : '0'}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CustomerStatement
