import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import store from "../Components/Kanban/Store";
export const ApiData = createContext();

export const DataProvider = ({ children }) => {
  useEffect(() => {
    return () => {
      // ComponentWillUnmount logic here
      localStorage.removeItem("company_id");
    };
  }, []);
  const [employees, setEmployees] = useState([]);
  const [department, setDepartments] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectIndividual, setProjectIndividual] = useState();
  const [employeeIndividual, setEmployeeIndividual] = useState([]);
  const [createDepartment, setCreateDepartment] = useState({});
  const [login, setLogin] = useState();
  const [register, setRegister] = useState({});
  const [company, setCompany] = useState();
  const [loding, setLoding] = useState(false);
  const [companyProfile, setCompanyProfile] = useState();
  const [expancer, setExpancer] = useState([]);
  const base_url =
    "https://hrprojects.azeetechnology.com/hrproject/public/api/";
  const img_url =
    "https://hrprojects.azeetechnology.com/hrproject/public/";
  // const base_url = 'http://192.168.239.11:8000/api/'
  // const img_url = 'http://192.168.239.11:8000/'
  const CompanyId = localStorage.getItem("company_id")
    ? localStorage.getItem("company_id")
    : null;
  const [company_id, setCompanyId] = useState(CompanyId);
  const auth = login ? login : JSON.parse(localStorage.getItem("user_auth"));
  const [permission, setpermission] = useState([]);

  const permissionFunction = () => {
    if (auth?.employee?.role_id?.permission) {
      setpermission(auth?.employee?.role_id?.permission);
    } else if (auth?.user?.role == "user") {
      setpermission([]);
    } else if (auth?.user?.role == "Admin") {
      setpermission([
        "Dashboard",
        "Employee Profile",
        "Employee Create",
        "Employee Edit",
        "Employee Delete",
        "Projects View",
        "Overview View",
        "Task View",
        "Task Create",
        "Files View",
        "Files Create",
        "Kanban",
        "Company Create",
        "Company View",
        "Company Edit",
        "Company Delete",
        "Branch Create",
        "Branch View",
        "Branch Edit",
        "Branch Delete",
        "Department Create",
        "Department View",
        "Department Edit",
        "Department Delete",
        "Designation Create",
        "Designation View",
        "Designation Edit",
        "Designation Delete",
        "Accounts",
        "Transfer",
        "Expense",
        "Expense Category",
        "Expense Subcategory",
        "Assets",
        "Asset Category",
        "Purchase Assets",
        "Services",
        "Services Category",
        "Invoice",
        "Revenue",
        "General Ledger",
        "Trail Balance",
        "Balance Sheet",
        "Profit & Loss",
        "Equity Liability",
        "Clients",
        "Suppliers",
        "Payrolls",
        "Emails",
        "Account All",
        "Bonus Add",
        "Job Post",
        "Account",
        "Bonus Edit",
        "Send Pay",
        "Bonus Delete",
        "Leave",
        "Balance Invoice",
        "Projects Delete",
        "Projects Create",
        "Projects Edit",
        "Payroll",
        "Job Post",
        "Company Announcement",
        "Company Policy",
        "Vendors",
        "Leave Approved",
        "Register",
      ]);
    }
  };

  const token = `Bearer ${auth?.token}`;
  const localStor = JSON.parse(localStorage.getItem("user_auth"));
  const [currentDate, setCurrentDate] = useState(new Date());
  const employee_Id = localStor?.employee?.id;
  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = state;
  const [snackbarValue, setSnackbarValue] = useState({
    message: "",
    color: "",
  });

  const handleClick = () => {
    console.log("Snackbar opened");
    setState({ ...state, open: true });
  };
  const [user, setUser] = useState();
  const registerDetail = (obj) => {
    fetch(`${base_url}register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
      })
      .catch((error) => {
        // Handle any errors
        console.log("Error:", error);
      });
  };
  const loginDetail = (obj) => {
    fetch(`${base_url}login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.token) {
          setLogin(data);
        } else {
          handleClick();
          setSnackbarValue({ message: data?.error, color: "#2ECC71" });
        }
      })
      .catch((error) => {
        // Handle any errors
        console.log("Error:", error);
      });
  };

  const getEmployees = () => {
    setLoding(true);
    fetch(`${base_url}employee`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setEmployees(data);
        setLoding(false);
      });
  };
  const getEmployeesIndividual = (id) => {
    setLoding(true);

    fetch(`${base_url}profile/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setEmployeeIndividual(data);
        setLoding(false);
      });
  };
  const getProjects = () => {
    setLoding(true);

    fetch(`${base_url}project`)
      .then((res) => res.json())
      .then((data) => {
        setProjects(data);
        setLoding(false);
      });
  };
  const createProject = (obj) => {
    fetch(`${base_url}projectStore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getProjects();
        handleClick();
        setSnackbarValue({
          message: "Project Create Successfully",
          color: "#2ECC71",
        });
      });
  };

  const getProjectsIndividual = (id) => {
    setLoding(true);
    console.log("project individuals id", id)
    fetch(`${base_url}projectDetails/${id}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setProjectIndividual(data);
        setLoding(false);
      })
      .catch((error) => {
        console.error('Error fetching project details:', error);
        setLoding(false);
        // Handle the error as needed
      });
  };
  const getDepartments = () => {
    setLoding(true);
    fetch(`${base_url}department`)
      .then((res) => res.json())
      .then((data) => {
        setDepartments(data);
        setLoding(false);
      });
  };
  const getFilterDepartment = (company, branch) => {
    fetch(`${base_url}departments/${company}/${branch}`)
      .then((res) => res.json())
      .then((data) => {
        setDepartments(data);
      });
  };
  const storeCompnayData = (newData) => {
    // Send the companyData object to your API using fetch

    fetch(`${base_url}companyStore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(newData), // Convert the object to JSON
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getCompnayCrud();
        handleClick();
        setSnackbarValue({
          message: "Company Create Successfully",
          color: "#2ECC71",
        });
        // Handle the response from the API as needed
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Error:", error);
      });
  };
  const storeBranchData = (obj) => {
    fetch(`${base_url}branchStore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getBranch();
        handleClick();
        setSnackbarValue({
          message: "Branch Create Successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const storeDepartmentData = (obj) => {
    fetch(`${base_url}departmentStore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getDepartments();
        handleClick();
        setSnackbarValue({
          message: "Store Department Successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const storeDesignationData = (obj) => {
    fetch(`${base_url}designationStore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getdesignation();
        handleClick();
        setSnackbarValue({
          message: "Designation Create Successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getCompnayCrud = () => {
    setLoding(true);

    fetch(`${base_url}company`)
      .then((res) => res.json())
      .then((data) => {
        setCompany(data);
        setLoding(false);
      });
  };
  const getCompanyProfile = (id) => {
    setLoding(true);

    fetch(`${base_url}company/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setCompanyProfile(data);
        setLoding(false);
      });
  };
  const [branch, setBrach] = useState([]);
  const getBranch = () => {
    setLoding(true);

    fetch(`${base_url}branch`)
      .then((res) => res.json())
      .then((data) => {
        // setCompany(data);
        setBrach(data);
        setLoding(false);
      });
  };
  const getFilterBranch = (name) => {
    fetch(`${base_url}branches/${name}`)
      .then((res) => res.json())
      .then((data) => {
        setBrach(data);
      });
  };
  const [designationData, setdesignationData] = useState([]);

  const getdesignation = () => {
    setLoding(true);

    fetch(`${base_url}designation`)
      .then((res) => res.json())
      .then((data) => {
        // setCompany(data);
        setdesignationData(data.data);
        setLoding(false);
      });
  };
  const editCompany = (obj, id) => {
    fetch(`${base_url}companyUpdate/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getCompnayCrud();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
        // setCompany(data);
        // setdesignationData(data.data);
      });
    // console.log(obj);
  };
  const editBrachCompany = (obj, id) => {
    fetch(`${base_url}branchUpdate/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        // setCompany(data);
        getBranch();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const delbarch = (id) => {
    fetch(`${base_url}branchDelete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getBranch();
        handleClick();
        setSnackbarValue({
          message: "Branch Delete Successfully",
          color: "#2ECC71",
        });
      });
  };
  const getBarnchById = (id) => {
    fetch(`${base_url}branch/${id}`)
      .then((res) => res.json())
      .then((data) => {
        // setCompany(data);
        setBrach(data);
      });
  };
  const delDepartment = (id) => {
    fetch(`${base_url}departmentDelete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getDepartments();
        handleClick();
        setSnackbarValue({
          message: "Department Edit Successfully",
          color: "#2ECC71",
        });
      });
  };
  const editDepartment = (obj) => {
    fetch(`${base_url}departmentUpdate/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getDepartments();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const editDesignation = (obj, id) => {
    fetch(`${base_url}designationUpdate/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        // setCompany(data);
        getdesignation();
        handleClick();
        setSnackbarValue({
          message: "Designation Update Successfully",
          color: "#2ECC71",
        });
      });
  };

  const [departmentval, setdepartvalue] = useState([]);
  const getdepartmetById = (id) => {
    fetch(`${base_url}department/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // setCompany(data);
        setdepartvalue(data);
      });
  };
  const deleteDesignation = (id) => {
    fetch(`${base_url}designationDelete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getdesignation();
        handleClick();
        setSnackbarValue({
          message: "Designation Deletes Successfully",
          color: "#2ECC71",
        });
      });
  };
  const [emplyeeResponse, setemplyeeResponse] = useState();
  const createEmploye = (obj) => {
    fetch(`${base_url}employeeStore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        setemplyeeResponse(data);
      });
  };

  const [designarion, setDesignationData] = useState([]);
  const getdseignationById = (id) => {
    fetch(`${base_url}designation/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // setCompany(data);
        setDesignationData(data);
      });
  };
  const [employeeID, setEmployeesByid] = useState([]);
  const getEmploayeeByid = (id) => {
    fetch(`${base_url}employee/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // setCompany(data);
        setEmployeesByid(data);
      });
  };
  const createTask = (obj, id) => {
    const data = new FormData();
    data.append("empId", obj.empId);
    data.append("title", obj.title);
    data.append("desc", obj.desc);
    data.append("startDate", obj.startDate);
    data.append("dueDate", obj.dueDate);
    data.append("file", obj.file[0]);
    data.append("projectId", id);
    data.append("time", obj.time);
    data.append("status", 0);
    data.append("priority", obj.priority);
    data.append("user_id", obj.user_id);
    data.append("employee_id", obj.employee_id);

    fetch(`${base_url}taskStore`, {
      method: "POST",
      headers: {
        // Set the correct Content-Type and Accept headers for form data
        "Content-Type": "application/json", // Remove this line
        Accept: "application/json", // Remove this line
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          // Handle the error appropriately (e.g., throw an error or show a message)
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        // Handle the response data, e.g., call a function
        getProjectsIndividual(id);
        getTaskStatusNew(id);
        handleClick();
        setSnackbarValue({
          message: "Task created successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        // Handle any errors here, e.g., display an error message
        console.error("Error:", error);
      });
  };
  const updateTaskStatus = (obj, id) => {
    fetch(`${base_url}updateStatus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getProjectsIndividual(obj?.taskTime?.projectId);
        handleClick();
        setSnackbarValue({
          message: "Task Status Upddate successfully",
          color: "#2ECC71",
        });
        getTaskStatusNew(obj?.taskTime?.projectId);
      });
  };

  const employeloginDetail = (obj) => {
    fetch(`${base_url}empLogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.token) {
          setLogin(data);
        } else {
          handleClick();
          setSnackbarValue({ message: data?.error, color: "#2ECC71" });
        }
      })
      .catch((error) => {
        // Handle any errors
        console.log("Error:", error);
      });
  };

  const sendFCM = (obj) => {
    fetch(`${base_url}update-fcm-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((data) => { })
      .catch((error) => {
        // Handle any errors
        console.log("Error:", error);
      });
  };
  const [lestChat, setChatList] = useState([]);
  const getChateList = (id) => {
    fetch(`${base_url}branch/${id}/employees`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // setCompany(data);
        setChatList(data);
      });
  };
  const getExpance = () => {
    fetch(`${base_url}expense`)
      .then((res) => res.json())
      .then((data) => {
        setExpancer(data);
      });
  };
  const storeExpanse = (obj) => {
    fetch(`${base_url}expense`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getExpance();
        handleClick();
        setSnackbarValue({
          message: "Expance Store successfully",
          color: "#2ECC71",
        });
      });
  };
  const [acouCategory, setAcounCategory] = useState([]);
  const getAcouCategory = () => {
    fetch(`${base_url}category`)
      .then((res) => res.json())
      .then((data) => {
        setAcounCategory(data);
      });
  };
  const [expandeDetails, setExpandeDetails] = useState([]);
  const getExpanceDetails = () => {
    fetch(`${base_url}cat-sub-account-supplier`)
      .then((res) => res.json())
      .then((data) => {
        setExpandeDetails(data);
      });
  };
  const [categoryExpance, setCategoryExpance] = useState([]);
  const getExpanceCate = () => {
    fetch(`${base_url}category`)
      .then((res) => res.json())
      .then((data) => {
        setCategoryExpance(data);
      });
  };
  const getFilterExpanceCate = (cate) => {
    fetch(`${base_url}category?company=${cate}`)
      .then((res) => res.json())
      .then((data) => {
        setCategoryExpance(data);
      });
  };


  const storeExpanseCategory = (obj) => {
    fetch(`${base_url}category`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getExpanceCate();
        handleClick();
        setSnackbarValue({
          message: "Expance Category Store successfully",
          color: "#2ECC71",
        });
      });
  };
  const updateExpanseCategory = (obj) => {
    fetch(`${base_url}category/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getExpanceCate();
        handleClick();
        setSnackbarValue({
          message: "Expance Category Update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteExpanseCategory = (id) => {
    fetch(`${base_url}category/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getExpanceCate();
        handleClick();
        setSnackbarValue({
          message: "Expance Category Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [expanceSubCate, setExpanceSubCate] = useState([]);
  const getExpanceSubCate = () => {
    fetch(`${base_url}subcategory`)
      .then((res) => res.json())
      .then((data) => {
        setExpanceSubCate(data);
      });
  };
  const storeExpanseSubCate = (obj) => {
    fetch(`${base_url}subcategory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getExpanceSubCate();
        handleClick();
        setSnackbarValue({
          message: "Expance SubCategory created successfully",
          color: "#2ECC71",
        });
      });
  };
  const deleteExpanseSubCategory = (id) => {
    fetch(`${base_url}subcategory/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getExpanceSubCate();
        handleClick();
        setSnackbarValue({
          message: "Expance SubCategory Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const updateExpanseSubCategory = (obj) => {
    fetch(`${base_url}subcategory/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getExpanceSubCate();
        handleClick();
        setSnackbarValue({
          message: "Expance Subcategory Edit successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [assetCate, setAssetscate] = useState([]);
  const getAssetsCate = () => {
    fetch(`${base_url}assetcategory`)
      .then((res) => res.json())
      .then((data) => {
        setAssetscate(data);
      });
  };
  const storeAssetsCate = (obj) => {
    fetch(`${base_url}assetcategory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getAssetsCate();
        handleClick();
        setSnackbarValue({
          message: "Assets created successfully",
          color: "#2ECC71",
        });
      });
  };
  const updateAssetCategory = (obj) => {
    fetch(`${base_url}assetcategory/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        // Handle the response data, if needed
        getAssetsCate(); // Refresh the list of asset categories
        handleClick();
        setSnackbarValue({
          message: "Assets Update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteAssetCategory = (id) => {
    fetch(`${base_url}assetcategory/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token, // Include the authorization header if required
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // Handle the response status if needed
        return res.json();
      })
      .then((data) => {
        // Handle the response data, if needed
        getAssetsCate(); // Refresh the list of asset categories
        handleClick();
        setSnackbarValue({
          message: "Assets Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Error:", error);
      });
  };
  const [assetSubCate, setAssetsSubcate] = useState([]);
  const getAssetsSubCate = () => {
    fetch(`${base_url}asset`)
      .then((res) => res.json())
      .then((data) => {
        setAssetsSubcate(data);
      });
  };
  const storeAssetsSubCate = (obj) => {
    fetch(`${base_url}asset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getAssetsSubCate();
        handleClick();
        setSnackbarValue({
          message: "Assets Subcategory created successfully",
          color: "#2ECC71",
        });
      });
  };
  const updateAsset = (id, obj) => {
    fetch(`${base_url}asset/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token, // Include the authorization header if required
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // Handle the response status if needed
        return res.json();
      })
      .then((data) => {
        // Handle the response data, if needed
        getAssetsSubCate();
        // Refresh the list of assets
        handleClick();
        setSnackbarValue({
          message: "Assets Update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Error:", error);
      });
  };
  const deleteAssetSubCategory = (id) => {
    fetch(`${base_url}asset/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getAssetsSubCate();
        handleClick();
        setSnackbarValue({
          message: "Assets Subcategory delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [purchaseAssetsDetail, setPurchaseAssetsDetail] = useState([]);
  const getPurchaseAssetsDetail = () => {
    fetch(`${base_url}assetcat-asset-account-supplier`)
      .then((res) => res.json())
      .then((data) => {
        setPurchaseAssetsDetail(data);
      });
  };
  const [purchaseAssets, setPurchaseAssets] = useState([]);
  const getPurchaseAssets = () => {
    fetch(`${base_url}purchaseasset`)
      .then((res) => res.json())
      .then((data) => {
        setPurchaseAssets(data);
      });
  };
  const storePurchaseAsset = (obj) => {
    fetch(`${base_url}purchaseasset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getPurchaseAssets();
        handleClick();
        setSnackbarValue({
          message: "Assets Purchase created successfully",
          color: "#2ECC71",
        });
      });
  };
  const [servicecategory, setServiceCategory] = useState([]);
  const getServicesCategory = () => {
    fetch(`${base_url}servicecategory`)
      .then((res) => res.json())
      .then((data) => {
        setServiceCategory(data);
      });
  };
  const storeServicesCate = (obj) => {
    fetch(`${base_url}servicecategory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getServicesCategory();
        handleClick();
        setSnackbarValue({
          message: "Service Category created successfully",
          color: "#2ECC71",
        });
      });
  };
  const updateServiceCategory = (id, updateServiceCate) => {
    fetch(`${base_url}servicecategory/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token, // Include the authorization header if required
      },
      body: JSON.stringify(updateServiceCate),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // Handle the response status if needed
        return res.json();
      })
      .then((data) => {
        // Handle the response data, if needed
        getServicesCategory(); // Refresh the list of assets
        handleClick();
        setSnackbarValue({
          message: "Update Service Category created successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Error:", error);
      });
  };
  const deleteServicesSubCategory = (id) => {
    fetch(`${base_url}servicecategory/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getServicesCategory();
        handleClick();
        setSnackbarValue({
          message: "Service Category delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [servicesData, serServicesData] = useState([]);
  const getServicesData = () => {
    fetch(`${base_url}service`)
      .then((res) => res.json())
      .then((data) => {
        serServicesData(data);
      });
  };
  const storeServiceData = (obj) => {
    fetch(`${base_url}service`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getServicesData();
        handleClick();
        setSnackbarValue({
          message: "Service created successfully",
          color: "#2ECC71",
        });
      });
  };
  const deleteServiceData = (id) => {
    fetch(`${base_url}service/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getServicesData();
        handleClick();
        setSnackbarValue({
          message: "Service Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [invoiceDetailData, setInvoiceDetailData] = useState([]);
  const getInvoiceDetailData = () => {
    fetch(`${base_url}invoicedata`)
      .then((res) => res.json())
      .then((data) => {
        setInvoiceDetailData(data);
      });
  };
  const [invoiceData, setInvoiceData] = useState([]);
  const getInvoiceData = () => {
    fetch(`${base_url}invoice`)
      .then((res) => res.json())
      .then((data) => {
        setInvoiceData(data);
      });
  };
  const storeInvoiceData = (obj) => {
    fetch(`${base_url}invoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getInvoiceData();
        handleClick();
        setSnackbarValue({
          message: "Invoice created successfully",
          color: "#2ECC71",
        });
      });
  };
  const [revenueData, setRevenueData] = useState([]);
  const getRevenueData = () => {
    fetch(`${base_url}revenue`)
      .then((res) => res.json())
      .then((data) => {
        setRevenueData(data);
      });
  };
  const storeRevenueData = (obj) => {
    fetch(`${base_url}revenue`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getRevenueData();
        handleClick();
        setSnackbarValue({
          message: "Revenue created successfully",
          color: "#2ECC71",
        });
      });
  };
  const [ledgerData, setLadgerData] = useState([]);
  const getLedgerdata = (id) => {
    fetch(`${base_url}ledger/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setLadgerData(data);
      });
  };
  const [trialBalanceData, setTrailBalanceData] = useState([]);
  const getTrailBalanceData = (id) => {
    fetch(`${base_url}trail_balance/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setTrailBalanceData(data);
      });
  };
  const [balanceSheetData, setBalanceSheetData] = useState([]);
  const getBalanceSheetData = (id) => {
    fetch(`${base_url}balance_sheet/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setBalanceSheetData(data);
      });
  };
  const [profitData, setProfitData] = useState([]);
  const getProfitData = (id) => {
    fetch(`${base_url}profit/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setProfitData(data);
      });
  };

  const [salariesData, setSalariesdata] = useState([]);
  const getSalariesdata = () => {
    fetch(`${base_url}salaries`)
      .then((res) => res.json())
      .then((data) => {
        setSalariesdata(data);
      });
  };
  const [salariesIndidualData, setSalariesIndividualData] = useState([]);
  const getSalariesIndividualData = (id) => {
    fetch(`${base_url}empPayroll/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setSalariesIndividualData(data);
      });
  };
  const [jobData, setJobdata] = useState();
  const getAllJob = () => {
    fetch(`${base_url}jobs`)
      .then((res) => res.json())
      .then((data) => {
        setJobdata(data);
      });
  };
  const createJob = (obj) => {
    fetch(`${base_url}job-create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getAllJob();
        handleClick();
        setSnackbarValue({ message: "Job Posted ", color: "#2ECC71" });
      });
  };
  const [userSignUp, setuserSignUp] = useState();
  const UserSignUp = (obj) => {
    fetch(`${base_url}register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        setuserSignUp(data);

        console.log(data);
        // getAllJob()
      });
  };
  const [userLoginDetail, setUserLogin] = useState();
  const UserLogin = (obj) => {
    fetch(`${base_url}login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        setUserLogin(data);
      });
  };
  const [jobDetailData, setjobData] = useState();
  const getJobDetailById = (id) => {
    fetch(`${base_url}get-applicant/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setjobData(data);
      });
  };
  const [customEmailData, setCustomEmailData] = useState();
  const getEmailData = () => {
    fetch(`${base_url}email`)
      .then((res) => res.json())
      .then((data) => {
        setCustomEmailData(data);
      });
  };
  const storeCustomEmail = (obj) => {
    fetch(`${base_url}email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getEmailData();
        handleClick();
        setSnackbarValue({
          message: "Email Sent successfully",
          color: "#2ECC71",
        });
      });
  };
  const [emailTemplate, setEmailTemplate] = useState();
  const getEmailTemplate = () => {
    fetch(`${base_url}email_tem`)
      .then((res) => res.json())
      .then((data) => {
        setEmailTemplate(data);
      });
  };
  const storeEmailTemplate = (obj) => {
    fetch(`${base_url}email_tem`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getEmailTemplate();
        handleClick();
        setSnackbarValue({
          message: "Invoice created successfully",
          color: "#2ECC71",
        });
      });
  };
  const [emailTemplateType, setEmailTemplateType] = useState();
  const getEmailTemplateType = () => {
    fetch(`${base_url}all_type`)
      .then((res) => res.json())
      .then((data) => {
        setEmailTemplateType(data);
      });
  };

  const [accountIndividualData, setAccountIndividualData] = useState([]);
  const getAccountIndividualData = (id) => {
    fetch(`${base_url}account/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setAccountIndividualData(data);
      });
  };
  const storeAcountData = (obj) => {
    fetch(`${base_url}account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getAccountData();
        handleClick();
        setSnackbarValue({
          message: "Account created successfully",
          color: "#2ECC71",
        });
      });
  };
  const updateAccountData = (updateAccountData) => {
    fetch(`${base_url}account/${updateAccountData.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token, // Include the authorization header if required
      },
      body: JSON.stringify(updateAccountData),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // Handle the response status if needed
        return res.json();
      })
      .then((data) => {
        // Handle the response data, if needed
        getAccountData(); // Refresh the list of assets
        handleClick();
        setSnackbarValue({
          message: "Account update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Error:", error);
      });
  };
  const deleteAcountData = (id) => {
    fetch(`${base_url}account/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        getAccountData();
        setSnackbarValue({
          message: "Account Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [accountIndiData, setAccountIndiData] = useState([]);
  const getAccountIndiData = (id) => {
    fetch(`${base_url}account/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setAccountIndiData(data);
      });
  };
  const [transferData, setTransferData] = useState([]);
  const getTransferData = () => {
    fetch(`${base_url}transfer`)
      .then((res) => res.json())
      .then((data) => {
        setTransferData(data);
      });
  };
  const getFilterTransfer = (start, end, company) => {
    fetch(
      `${base_url}transfer?start_date=${start}&end_date=${end}&company=${company}`
    )
      .then((res) => res.json())
      .then((data) => {
        setTransferData(data);
      });
  };
  const storeTransferData = (obj) => {
    fetch(`${base_url}transfer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        getTransferData();
        handleClick();
        setSnackbarValue({
          message: "Transfer created successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const updateTransferData = (obj) => {
    fetch(`${base_url}transfer/${obj.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token, // Include the authorization header if required
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // Handle the response status if needed
        return res.json();
      })
      .then((data) => {
        // Handle the response data, if needed
        getTransferData(); // Refresh the list of assets
        handleClick();
        setSnackbarValue({
          message: "Transfer update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Error:", error);
      });
  };
  const deleteTransferData = (id) => {
    fetch(`${base_url}transfer/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getTransferData(); // Refresh the list of assets
        handleClick();
        setSnackbarValue({
          message: "Transfer delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [clientData, setClientData] = useState([]);
  const getClientData = () => {
    fetch(`${base_url}client`)
      .then((res) => res.json())
      .then((data) => {
        setClientData(data);
      });
  };
  const storeClientData = (obj) => {
    fetch(`${base_url}client`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getClientData();
        handleClick();
        setSnackbarValue({
          message: "Customer Store successfully",
          color: "#2ECC71",
        });
      });
  };
  const updateClientData = (id, obj) => {
    fetch(`${base_url}client/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token, // Include the authorization header if required
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // Handle the response status if needed
        return res.json();
      })
      .then((data) => {
        // Handle the response data, if needed
        getClientData();
        handleClick();
        setSnackbarValue({
          message: "Customer update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Error:", error);
      });
  };
  const deleteClientData = (id) => {
    fetch(`${base_url}client/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getClientData();
        handleClick();
        setSnackbarValue({
          message: "Customer delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [clientIndiData, setClientIndiData] = useState([]);
  const getClientIndiData = (id) => {
    fetch(`${base_url}client/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setClientIndiData(data);
      });
  };
  const getFilterClient = (name) => {
    fetch(`${base_url}client?name=${name}`)
      .then((res) => res.json())
      .then((data) => {
        setClientData(data);
      });
  };
  const getFilterLedger = (start, end, id) => {
    fetch(`${base_url}ledger/${id}?start_date=${start}&end_date=${end}`)
      .then((res) => res.json())
      .then((data) => {
        setLadgerData(data);
      });
  };

  const getFilterTrailBalance = (month) => {
    fetch(`${base_url}trail_balance?month=${month}`)
      .then((res) => res.json())
      .then((data) => {
        setTrailBalanceData(data);
      });
  };
  const getFilterPurchaseAssets = (cate, subcate, start, end, company) => {
    fetch(
      `${base_url}purchaseasset?assetcategory=${cate}&asset=${subcate}&start_date=${start}&end_date=${end}&company=${company}`
    )
      .then((res) => res.json())
      .then((data) => {
        setPurchaseAssets(data);
      });
  };
  const getFilterExpanse = (cate, subcate, start, end, company) => {
    fetch(
      `${base_url}expense?category=${cate}&subcategory=${subcate}&start_date=${start}&end_date=${end}&company=${company}`
    )
      .then((res) => res.json())
      .then((data) => {
        setExpancer(data);
      });
  };
  const [leaveData, setLeaveData] = useState();
  const getLeaveData = () => {
    fetch(`${base_url}leave`)
      .then((res) => res.json())
      .then((data) => {
        setLeaveData(data);
      });
  };
  const storeLeavedata = (obj) => {
    fetch(`${base_url}leaveStore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getLeaveData();
        handleClick();
        setSnackbarValue({ message: "Leave Applided", color: "#2ECC71" });
      });
  };
  const updateLeaveData = (obj) => {
    fetch(`${base_url}leaveUpdate/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getLeaveData();
        handleClick();
        setSnackbarValue({
          message: "Laeve update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteLeaveData = (id) => {
    fetch(`${base_url}leaveDelete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getLeaveData();
        handleClick();
        setSnackbarValue({
          message: "Leave delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getFilterEmployees = (
    company,
    branch,
    department,
    designation,
    start,
    end
  ) => {
    fetch(
      `${base_url}employee?start_date=${start}&end_date=${end}&designation_name=${designation}&department_name=${department}&company_name=${company}&branch_name=${branch}`
    )
      .then((res) => res.json())
      .then((data) => {
        setEmployees(data);
      });
  };
  const [euqualityData, setEqulityData] = useState([]);
  const getEqulityData = () => {
    fetch(`${base_url}equityliabilities`)
      .then((res) => res.json())
      .then((data) => {
        setEqulityData(data);
      });
  };
  const storeEquityLibilitData = (obj) => {
    fetch(`${base_url}equityliabilities`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getEqulityData();
        handleClick();
        setSnackbarValue({
          message: "Equity Create successfully",
          color: "#2ECC71",
        });
      });
  };
  const deleteEquityLibilitData = (id) => {
    fetch(`${base_url}equityliabilities/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getEqulityData();
        handleClick();
        setSnackbarValue({
          message: "Equity Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getFilterQuityLiability = (start, end, company) => {
    fetch(
      `${base_url}equityliabilities?start_date=${start}&end_date=${end}&company=${company}`
    )
      .then((res) => res.json())
      .then((data) => {
        setEqulityData(data);
      });
  };

  const [taskView, setTaskView] = useState();
  const getTaskView = (id) => {
    fetch(`${base_url}task/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setTaskView(data);
      });
  };
  const storeTaskComments = (obj) => {
    fetch(`${base_url}taskComment/${obj?.taskId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getTaskView(obj?.taskId);
      });
  };
  const deleteTaskComment = (id, obj) => {
    fetch(`${base_url}commentDelete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // Deletion is successful, now call getTaskView
        getTaskView(obj.taskId);
        handleClick();
        setSnackbarValue({ message: "Comment Deleted", color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const storeProjectComments = (obj) => {
    fetch(`${base_url}projectComment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getProjectsIndividual(obj.projectId);
      });
  };
  const deleteProjectComment = (id, obj) => {
    fetch(`${base_url}projectComment/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getProjectsIndividual(obj.projectId);
        handleClick();
        setSnackbarValue({ message: "Comment Deleted", color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const updateProjectData = (obj) => {
    fetch(`${base_url}projectUpdate/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getProjects();
        handleClick();
        setSnackbarValue({
          message: "Project update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteProjectData = (id) => {
    fetch(`${base_url}projectDelete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getProjects();
        handleClick();
        setSnackbarValue({
          message: "Delete Project successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteCompany = (id, obj) => {
    fetch(`${base_url}companyDelete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getCompnayCrud();
        handleClick();
        setSnackbarValue({
          message: "Company Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const storeLeaveStatus = (id, obj) => {
    fetch(`${base_url}leave/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getLeaveData();
        handleClick();
        setSnackbarValue({
          message: "Leave Status update successfully",
          color: "#2ECC71",
        });
      });
  };

  const [supplierData, setSupplierData] = useState([]);
  const getSupplierData = () => {
    fetch(`${base_url}supplier`)
      .then((res) => res.json())
      .then((data) => {
        setSupplierData(data);
      });
  };
  const [vendorData, setVendorData] = useState([]);
  const getVendorData = () => {
    fetch(`${base_url}vendor`)
      .then((res) => res.json())
      .then((data) => {
        setVendorData(data);
      });
  };
  const [supplierIndiData, setSupplierIndiData] = useState([]);
  const getSupplierIndiData = (id) => {
    fetch(`${base_url}supplier/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setSupplierIndiData(data);
      });
  };
  const [vendorIndiData, setVendorIndiData] = useState([]);
  const getVendorIndiData = (id) => {
    fetch(`${base_url}vendor/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setVendorIndiData(data);
      });
  };
  const getFilterSupplier = (name) => {
    fetch(`${base_url}supplier?name=${name}`)
      .then((res) => res.json())
      .then((data) => {
        setSupplierData(data);
      });
  };
  const getFilterVendor = (name) => {
    fetch(`${base_url}vendor?name=${name}`)
      .then((res) => res.json())
      .then((data) => {
        setVendorData(data);
      });
  };
  const storeSupplierData = (obj) => {
    fetch(`${base_url}supplier`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getSupplierData();
        handleClick();
        setSnackbarValue({
          message: "Supplier Create successfully",
          color: "#2ECC71",
        });
      });
  };
  const storeVendorData = (obj) => {
    fetch(`${base_url}vendor`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getVendorData();
        handleClick();
        setSnackbarValue({
          message: "Vendor Create successfully",
          color: "#2ECC71",
        });
      });
  };
  const updateVendorData = (id, obj) => {
    fetch(`${base_url}vendor/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token, // Include the authorization header if required
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // Handle the response status if needed
        return res.json();
      })
      .then((data) => {
        // Handle the response data, if needed
        getVendorData(); // Refresh the list of assets
        handleClick();
        setSnackbarValue({
          message: "Vendor update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Error:", error);
      });
  };
  const updateSupplierData = (id, obj) => {
    fetch(`${base_url}supplier/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token, // Include the authorization header if required
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        // Handle the response status if needed
        return res.json();
      })
      .then((data) => {
        // Handle the response data, if needed
        getSupplierData(); // Refresh the list of assets
        handleClick();
        setSnackbarValue({
          message: "Supplier update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Error:", error);
      });
  };
  const deleteSupplierData = (id) => {
    fetch(`${base_url}supplier/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getSupplierData();
        handleClick();
        setSnackbarValue({
          message: "Supplier Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteVendorData = (id) => {
    fetch(`${base_url}vendor/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getVendorData();
        handleClick();
        setSnackbarValue({
          message: "Vendor Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const updateTaskData = (obj) => {
    fetch(`${base_url}taskUpdate/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getProjectsIndividual(obj.projectId);
        handleClick();
        setSnackbarValue({
          message: "Task update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteTaskData = (id, projectId) => {
    fetch(`${base_url}taskDelete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getProjectsIndividual(projectId);
        handleClick();
        setSnackbarValue({
          message: "Task Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [screenshot, setScreenShort] = useState();
  const getEmployeesScreenShorts = (id) => {
    fetch(`${base_url}screen/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setScreenShort(data);
      });
  };
  const [taskFileData, setTaskFileData] = useState();
  const getTaskFileData = () => {
    fetch(`${base_url}file`)
      .then((res) => res.json())
      .then((data) => {
        setTaskFileData(data);
      });
  };
  const storeTaskFileData = (obj, id) => {
    fetch(`${base_url}fileStore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getTaskFileData();
        handleClick();
        setSnackbarValue({
          message: "File Uploaded successfully",
          color: "#2ECC71",
        });
      });
  };
  const updateTaskFileData = (obj, id) => {
    fetch(`${base_url}fileEdit/${obj?.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getTaskFileData();
        handleClick();
        setSnackbarValue({
          message: "File update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteTaskFileData = (id) => {
    fetch(`${base_url}fileDelete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getTaskFileData();
        handleClick();
        setSnackbarValue({
          message: "File Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [notifications, setNotifications] = useState();
  const getNotification = () => {
    fetch(`${base_url}notification`)
      .then((res) => res.json())
      .then((data) => {
        setNotifications(data);
      });
  };
  const [allCrudData, setAllCrudData] = useState([]);
  const getAllCrudData = () => {
    fetch(`${base_url}allData`)
      .then((res) => res.json())
      .then((data) => {
        setAllCrudData(data);
      });
  };
  const [expnaceCateSubcate, setExpnaceCateSubcate] = useState([]);
  const getExpnaceCateSubcate = () => {
    fetch(`${base_url}expense_cat_subcat`)
      .then((res) => res.json())
      .then((data) => {
        setExpnaceCateSubcate(data);
      });
  };
  const [invoiceIndiData, setInvoiceIndData] = useState([]);
  const getInvoiceIndData = (id) => {
    fetch(`${base_url}invoice/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setInvoiceIndData(data);
      });
  };
  const postPaidStatus = (id) => {
    fetch(`${base_url}invoice/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getInvoiceData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getFilterAssets = (cate) => {
    fetch(`${base_url}asset?asset_category=${cate}`)
      .then((res) => res.json())
      .then((data) => {
        setAssetsSubcate(data);
      });
  };
  const getFilterAssetsComp = (company) => {
    fetch(`${base_url}asset?company=${company}`)
      .then((res) => res.json())
      .then((data) => {
        setAssetsSubcate(data);
      });
  };
  const getFilterServices = (name) => {
    fetch(`${base_url}service?category_name=${name}`)
      .then((res) => res.json())
      .then((data) => {
        serServicesData(data);
      });
  };
  const getFilterInvoice = (start, end, name) => {
    fetch(
      `${base_url}invoice?start_date=${start}&end_date=${end}&company=${name}`
    )
      .then((res) => res.json())
      .then((data) => {
        setInvoiceData(data);
      });
  };

  const getFilterClientIndi = (id, start, end) => {
    fetch(`${base_url}client/${id}?start_date=${start}&end_date=${end}`)
      .then((res) => res.json())
      .then((data) => {
        setClientIndiData(data);
      });
  };
  const getFilterSupplierIndi = (id, start, end) => {
    fetch(`${base_url}supplier/${id}?start_date=${start}&end_date=${end}`)
      .then((res) => res.json())
      .then((data) => {
        setSupplierIndiData(data);
      });
  };
  const getFilterAccountIndiData = (id, start, end) => {
    // console.log(end);
    fetch(`${base_url}account/${id}?start_date=${start}&end_date=${end}`)
      .then((res) => res.json())
      .then((data) => {
        setAccountIndividualData(data);
      });
  };
  const getFilterRevenue = (start, end, name) => {
    fetch(
      `${base_url}revenue?start_date=${start}&end_date=${end}&company=${name}`
    )
      .then((res) => res.json())
      .then((data) => {
        setRevenueData(data);
      });
  };
  const getFilterProfit = (start, end, id) => {
    fetch(`${base_url}profit/${id}?start_date=${start}&end_date=${end}`)
      .then((res) => res.json())
      .then((data) => {
        setProfitData(data);
      });
  };

  const [emailTemplateIndividualData, setEmailTemplateIndividualData] =
    useState([]);
  const getEmailTemplateIndividualData = (id) => {
    fetch(`${base_url}email-templates/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setEmailTemplateIndividualData(data);
      });
  };
  const updateEmailTemplate = (obj) => {
    fetch(`${base_url}email-templates/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        setSnackbarValue({
          message: "Template update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [accountData, setAccountData] = useState([]);
  const getAccountData = () => {
    fetch(`${base_url}account`)
      .then((res) => res.json())
      .then((data) => {
        setAccountData(data);
      });
  };
  const getAccountFilter = (company) => {
    fetch(`${base_url}account?company=${company}`)
      .then((res) => res.json())
      .then((data) => {
        setAccountData(data);
      });
  };
  const [accountCom, setAccountCom] = useState([]);
  const getAccountCom = () => {
    fetch(`${base_url}account_company`)
      .then((res) => res.json())
      .then((data) => {
        setAccountCom(data);
      });
  };
  const [companyCate, setCompanycate] = useState([]);
  const getCompanycate = () => {
    fetch(`${base_url}cat_company`)
      .then((res) => res.json())
      .then((data) => {
        setCompanycate(data);
      });
  };
  const getExpenseSubFilter = (company) => {
    fetch(`${base_url}subcategory?company=${company}`)
      .then((res) => res.json())
      .then((data) => {
        setExpanceSubCate(data);
      });
  };
  const getAssetCateFilter = (company) => {
    fetch(`${base_url}assetcategory?company=${company}`)
      .then((res) => res.json())
      .then((data) => {
        setAssetscate(data);
      });
  };
  const [accountsData, setAccountsData] = useState([]);
  const getAccountsData = () => {
    fetch(`${base_url}accountsdata`)
      .then((res) => res.json())
      .then((data) => {
        setAccountsData(data);
      });
  };
  const getSerCateFilter = (company) => {
    fetch(`${base_url}servicecategory?company=${company}`)
      .then((res) => res.json())
      .then((data) => {
        setServiceCategory(data);
      });
  };
  const getServicesFIlterCom = (company) => {
    fetch(`${base_url}service?company=${company}`)
      .then((res) => res.json())
      .then((data) => {
        serServicesData(data);
      });
  };
  const getClientFilterCom = (company) => {
    fetch(`${base_url}client?company=${company}`)
      .then((res) => res.json())
      .then((data) => {
        setClientData(data);
      });
  };
  const getSupplierFilterCom = (company) => {
    fetch(`${base_url}supplier?company=${company}`)
      .then((res) => res.json())
      .then((data) => {
        setSupplierData(data);
      });
  };
  const getVendorFilterCom = (company) => {
    fetch(`${base_url}vendor?company=${company}`)
      .then((res) => res.json())
      .then((data) => {
        setVendorData(data);
      });
  };
  const [compnayPlcy, setCompanyPlcy] = useState();
  const getCompanyPlcy = () => {
    fetch(`${base_url}company_policy`)
      .then((res) => res.json())
      .then((data) => {
        setCompanyPlcy(data);
      });
  };
  const [empAttendance, setEmpAttandance] = useState();
  const getEmpAttandance = (id) => {
    fetch(`${base_url}attendance_status/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setEmpAttandance(data);
      });
  };
  const getEmployeAttFilter = (id, month) => {
    fetch(`${base_url}attendance_status/${id}?month=${month}`)
      .then((res) => res.json())
      .then((data) => {
        setEmpAttandance(data);
      });
  };
  const [goalsData, setGoalsData] = useState([]);
  const getGoalsData = () => {
    fetch(`${base_url}goals`)
      .then((res) => res.json())
      .then((data) => {
        setGoalsData(data);
      });
  };
  const postGoals = (obj) => {
    fetch(`${base_url}goals`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getGoalsData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [goalsDataInd, setGoalsDataInd] = useState([]);
  const getGoalsDataInd = (id) => {
    fetch(`${base_url}goals/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setGoalsDataInd(data);
      });
  };
  const updateGoalsData = (obj, id) => {
    fetch(`${base_url}goals/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getGoalsDataInd(id);
        handleClick();
        setSnackbarValue({
          message: "Goals update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [goalsCommentData, setGoalsCommentData] = useState([]);
  const getGoalsCommentData = (id) => {
    fetch(`${base_url}goals/${id}/comments`)
      .then((res) => res.json())
      .then((data) => {
        setGoalsCommentData(data?.comments);
      });
  };
  const postGoalsComments = (obj) => {
    fetch(`${base_url}goals/${obj.id}/comments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getGoalsCommentData(obj.id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteGoalsComment = (id, obj) => {
    fetch(`${base_url}goals/${obj.id}/comments/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getGoalsCommentData(obj.id);
        handleClick();
        setSnackbarValue({ message: "Comment Deleted", color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteGoalData = (id) => {
    fetch(`${base_url}goals/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getGoalsData();
        handleClick();
        setSnackbarValue({ message: "Goal Deleted", color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const dataStorage = JSON.parse(window.localStorage.getItem("dataKanban"));

  const initialState = () => {
    if (dataStorage) {
      return dataStorage;
    } else {
      window.localStorage.setItem("dataKanban", JSON.stringify(store));
      return store;
    }
  };

  const [data, setData] = useState([]);

  const addMoreCard = (title, listId, id) => {
    if (!title) {
      return;
    }

    const newCardId = uuid();
    const newCard = {
      id: newCardId,
      title,
    };

    const list = data.lists[listId];
    list.cards = [...list.cards, newCard];

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
    window.localStorage.setItem("dataKanban", JSON.stringify(newState));
  };
  const removeCard = (id, project_id) => { };
  const getTaskStatusNew = (id) => {
    fetch(`${base_url}kanban-statuses/${id}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        var array = [];
        data?.map((item) => {
          var obj = {
            id: item?.id,
            title: item?.name,
            cards: item?.tasks,
            position: item?.position,
            project_id: item?.project_id,
          };
          array.push(obj);
        });
        setData(array);
      });
  };
  const updateCardTitle = (title, index, listId) => {
    const list = data.lists[listId];
    list.cards[index].title = title;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
    window.localStorage.setItem("dataKanban", JSON.stringify(newState));
  };
  const addMoreList = (title, id) => {
    if (!title) {
      return;
    }
    const position = data?.length ? data[data?.length - 1].position + 1 : 1;
    console.log(position, data[data?.length - 1]);
    const newListId = uuid();
    const newList = {
      id: newListId,
      title,
      cards: [],
    };
    fetch(`${base_url}kanban-statuses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        project_id: id,
        name: title,
        position: position,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // getInvoiceData()
        getTaskStatusNew(id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    //   const newState = {
    //     listIds: [...data.listIds, newListId],
    //     lists: {
    //       ...data.lists,
    //       [newListId]: newList,
    //     },
    //   };
    //   setData(newState);
    //   window.localStorage.setItem("dataKanban", JSON.stringify(newState));
  };

  const updateListTitle = (title, listId, project_id) => {
    fetch(`${base_url}kanban-statuses/${listId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: title,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        getTaskStatusNew(project_id);
        handleClick();
        setSnackbarValue({
          message: "Title update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const deleteList = (id, project_id) => {
    fetch(`${base_url}kanban-statuses/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getTaskStatusNew(project_id);
        handleClick();
        setSnackbarValue({
          message: "TasK Status List Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }

    if (type === "list") {
      const newArray = [...data];
      const itemToMove = newArray.splice(source?.index, 1)[0];
      newArray.splice(destination?.index, 0, itemToMove);
      setData(newArray);

      var array = [];
      newArray?.map((item) => {
        var obj = {
          id: item?.id,
          name: item?.title,
        };
        array.push(obj);
      });
      fetch(`${base_url}kanban-update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          lists: array,
          project_id: newArray[0].project_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          getTaskStatusNew(newArray[0].project_id);
          handleClick();
          setSnackbarValue({
            message: "Title update successfully",
            color: "#2ECC71",
          });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      console.log(newArray);

      return;
    }

    const sourceList = data?.filter((item) => item?.id == source.droppableId);
    const destinationList = data?.filter(
      (item) => item?.id == destination.droppableId
    );
    const draggingCard = sourceList[0]?.cards?.filter((card) => {
      if (card.id == parseInt(draggableId)) {
        return card;
      }
    });

    if (source.droppableId === destination.droppableId) {
    } else {
      sourceList[0]?.cards?.splice(source.index, 1);
      destinationList[0]?.cards?.splice(destination.index, 0, draggingCard[0]);
      const foundCard = destinationList[0]?.cards.find(
        (item) => item.id == draggableId && item.projectId
      );
      console.log(foundCard);
      updateTaskStatus({
        taskTime: {
          taskId: parseInt(draggableId),
          listId: destinationList[0]?.id,
          empId: auth?.employee?.id,
          user_id: auth?.user?.id,
          projectId: foundCard?.projectId,
        },
      });
    }
  };

  const [employeeAttandance, setEmployeeAttandance] = useState([]);
  const getEmployeeAttandance = () => {
    fetch(`${base_url}employeeStatus`)
      .then((res) => res.json())
      .then((data) => {
        setEmployeeAttandance(data);
      });
  };
  const getFilterAttandace = (company, branch) => {
    fetch(`${base_url}employeeStatus?company=${company}&branch=${branch}`)
      .then((res) => res.json())
      .then((data) => {
        setEmployeeAttandance(data);
      });
  };
  const getFilterCompany = (name) => {
    fetch(`${base_url}companyFilter?name=${name}`)
      .then((res) => res.json())
      .then((data) => {
        setCompany(data);
      });
  };
  const storeRecentCompany = (obj) => {
    fetch(`${base_url}companySearch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getCompnayCrud();
        handleClick();
        setSnackbarValue({
          message: data?.message,
          color: "#2ECC71",
        });
      });
  };

  const [serviceSubcategory, setServiceSubCategory] = useState([]);
  const getServicesSubCategory = () => {
    fetch(`${base_url}product_subcategory`)
      .then((res) => res.json())
      .then((data) => {
        setServiceSubCategory(data);
      });
  };
  const [accountsDataC, setAccountsDataC] = useState([]);
  const getAccountsDataC = () => {
    fetch(`${base_url}accounts`)
      .then((res) => res.json())
      .then((data) => {
        setAccountsDataC(data);
      });
  };
  const storeaccountsDataC = (obj) => {
    fetch(`${base_url}accounts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getAccountsDataC();
        handleClick();
        setSnackbarValue({
          message: "Account Store successfully",
          color: "#2ECC71",
        });
      });
  };
  const updateAccountsC = (obj) => {
    fetch(`${base_url}accounts/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getAccountsDataC();
        handleClick();
        setSnackbarValue({
          message: "Account Update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteAccountsC = (id) => {
    fetch(`${base_url}accounts/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getAccountsDataC();
        handleClick();
        setSnackbarValue({
          message: "Account delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [dydy, setDydy] = useState([]);
  const getDydy = () => {
    fetch(`${base_url}dydy`)
      .then((res) => res.json())
      .then((data) => {
        setDydy(data);
      });
  };
  const [salesPurchase, setsalesPurchase] = useState([]);
  const getsalesPurchase = () => {
    fetch(`${base_url}getservice`)
      .then((res) => res.json())
      .then((data) => {
        setsalesPurchase(data);
      });
  };
  const [subcateAC, setSubcateAC] = useState([]);
  const getSubcateAC = (id) => {
    fetch(`${base_url}subcategory/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setSubcateAC(data);
      });
  };
  const [serviceGroup, setServiceGroup] = useState([]);
  const getServiceGroup = (id) => {
    fetch(`${base_url}groupservice/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setServiceGroup(data);
      });
  };
  const storeServicesSubCate = (obj) => {
    fetch(`${base_url}product_subcategory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getServicesSubCategory();
        handleClick();
        setSnackbarValue({
          message: "Service Category created successfully",
          color: "#2ECC71",
        });
      });
  };
  const updateServiceSubCategory = (id, obj) => {
    fetch(`${base_url}product_subcategory/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getServicesSubCategory();
        handleClick();
        setSnackbarValue({
          message: "Update Service Category created successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteServicessubCategory = (id) => {
    fetch(`${base_url}product_subcategory/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getServicesSubCategory();
        handleClick();
        setSnackbarValue({
          message: "Service Category delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [servicesGData, serServicesGData] = useState([]);
  const getServicesGData = () => {
    fetch(`${base_url}group_service`)
      .then((res) => res.json())
      .then((data) => {
        serServicesGData(data);
      });
  };
  const storeServiceGData = (obj) => {
    fetch(`${base_url}group_service`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getServicesGData();
        handleClick();
        setSnackbarValue({
          message: "Bundle created successfully",
          color: "#2ECC71",
        });
      });
  };
  const updateServiceGData = (obj) => {
    fetch(`${base_url}group_service/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getServicesGData();
        handleClick();
        setSnackbarValue({
          message: "Bundle created successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteServiceGData = (id) => {
    fetch(`${base_url}group_service/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getServicesGData();
        handleClick();
        setSnackbarValue({
          message: "Bundle Delete successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [clientIndiLatestData, setClientIndiLatestData] = useState([]);
  const getClientIndiLatestData = () => {
    fetch(`${base_url}latestclient`)
      .then((res) => res.json())
      .then((data) => {
        setClientIndiLatestData(data);
      });
  };
  const [invoiceNumberData, setInvoiceNumberData] = useState([]);
  const getInvoiceNumberData = () => {
    fetch(`${base_url}invoice_no`)
      .then((res) => res.json())
      .then((data) => {
        setInvoiceNumberData(data);
      });
  };
  const storeCustomerInvoice = (obj) => {
    fetch(`${base_url}invoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const updateCustomerInvoice = (obj, id) => {
    fetch(`${base_url}invoice/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getInvoiceData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const deleteCustomerInvoice = (id) => {
    fetch(`${base_url}invoice/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getInvoiceData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const storeCustomerEstimate = (obj) => {
    fetch(`${base_url}estimate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const updateCustomerEstimate = (obj, id) => {
    fetch(`${base_url}estimate/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getInvoiceData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const deleteCustomerEstimate = (end_point, id) => {
    fetch(`${base_url}${end_point}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getInvoiceData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const storeCustomerSalesRecipt = (obj) => {
    fetch(`${base_url}sale_receipt`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const updateCustomerSalesRcpt = (obj, id) => {
    fetch(`${base_url}sale_receipt/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getInvoiceData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [invoiceDetailIndiData, setInvoiceDetailIndiData] = useState([]);
  const getInvoiceDetailIndiData = (id) => {
    fetch(`${base_url}individual_invoice/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setInvoiceDetailIndiData(data);
      });
  };
  const [estimateDetailIndiData, setEstimateDetailIndiData] = useState([]);
  const getEstimateDetailIndiData = (id) => {
    fetch(`${base_url}individual_estimate/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setEstimateDetailIndiData(data);
      });
  };
  const [sale_receiptDetailIndiData, setSalesDetailIndiData] = useState([]);
  const getSalesDetailIndiData = (id) => {
    fetch(`${base_url}individual_sale_receipt/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setSalesDetailIndiData(data);
      });
  };
  const [credit_receiptDetailIndiData, setCreditDetailIndiData] = useState([]);
  const getCreditDetailIndiData = (id) => {
    fetch(`${base_url}individual_credit/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setCreditDetailIndiData(data);
      });
  };
  const storeCustomerCredit = (obj) => {
    fetch(`${base_url}CreditStore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const updateCustomerCredit = (obj, id) => {
    fetch(`${base_url}CreditUpdate/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getInvoiceData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [delay_receiptDetailIndiData, setDelayDetailIndiData] = useState([]);
  const getDelayDetailIndiData = (id) => {
    fetch(`${base_url}individual_delay/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setDelayDetailIndiData(data);
      });
  };
  const storeCustomerDelay = (obj) => {
    fetch(`${base_url}DelayStore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const updateCustomerDelay = (obj, id) => {
    fetch(`${base_url}DelayUpdate/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getInvoiceData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [allInvoiceData, setAllInvoicesData] = useState([]);
  const getAllInvoicesData = () => {
    fetch(`${base_url}invoiceData`)
      .then((res) => res.json())
      .then((data) => {
        setAllInvoicesData(data);
      });
  };
  const storeCustomerPayment = (obj) => {
    fetch(`${base_url}StorePayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const [payment_receiptDetailIndiData, setPaymentDetailIndiData] = useState(
    []
  );
  const getPaymentDetailIndiData = (id) => {
    fetch(`${base_url}individual_payment/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setPaymentDetailIndiData(data);
      });
  };
  const [statement_receiptDetailIndiData, setStatementDetailIndiData] =
    useState([]);
  const getStatementDetailIndiData = (id) => {
    fetch(`${base_url}individual_statement/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setStatementDetailIndiData(data);
      });
  };
  const storeCustomerSatement = (obj) => {
    fetch(`${base_url}statement`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const updateCustomerStatement = (obj, id) => {
    fetch(`${base_url}statement/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getInvoiceData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [budgetData, setBudgetData] = useState([]);
  const getBudgetData = () => {
    fetch(`${base_url}budget`)
      .then((res) => res.json())
      .then((data) => {
        setBudgetData(data);
      });
  };
  const [budgetAcData, setBudgetAcData] = useState([]);
  const getBudgetAcData = () => {
    fetch(`${base_url}budget_account`)
      .then((res) => res.json())
      .then((data) => {
        setBudgetAcData(data);
      });
  };
  const storeBudgetYearly = (obj) => {
    fetch(`${base_url}yearly_budget`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getBudgetData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const storeBudgetMontly = (obj) => {
    fetch(`${base_url}monthly_budget`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getBudgetData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
        return true;
      });
  };
  const storeBudgetQuarterly = (obj) => {
    fetch(`${base_url}quartery_budget`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getBudgetData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const deleteBudgets = (end_point, id) => {
    fetch(`${base_url}${end_point}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getBudgetData();
        handleClick();
        setSnackbarValue({
          message: `${end_point} Budget Delete successfully`,
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [yearlyBudgetIndi, setYearlyBudgetIndi] = useState([]);
  const getYearlyBudgetIndi = (id) => {
    fetch(`${base_url}yearly_budget/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setYearlyBudgetIndi(data);
      });
  };
  const updateYearlyBudget = (obj) => {
    fetch(`${base_url}yearly_budget/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getBudgetData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [quarterlyBudgetIndi, setQuarterlyBudgetIndi] = useState([]);
  const getQuarterlyBudgetIndi = (id) => {
    fetch(`${base_url}quarterly_budget/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setQuarterlyBudgetIndi(data);
      });
  };
  const updateQuarterlyBudget = (obj) => {
    fetch(`${base_url}quartery_budget/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getBudgetData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [monthlyBudgetIndi, setMonthlyBudgetIndi] = useState([]);
  const getMonthlyBudgetIndi = (id) => {
    fetch(`${base_url}monthly_budget/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setMonthlyBudgetIndi(data);
      });
  };
  const updateMonthlyBudget = (obj) => {
    fetch(`${base_url}monthly_budget/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getBudgetData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [finencialYears, setFinencialYears] = useState();
  const getFinencialYears = () => {
    fetch(`${base_url}showFinancialYears`)
      .then((res) => res.json())
      .then((data) => {
        setFinencialYears(data);
      });
  };
  const storeCustomFields = (obj) => {
    fetch(`${base_url}add_customfield`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getBudgetData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const [settingDat, setDat] = useState();
  const getDat = () => {
    fetch(`${base_url}account_setting`)
      .then((res) => res.json())
      .then((data) => {
        setDat(data);
      });
  };
  const storeCustomSettings = (obj) => {
    fetch(`${base_url}account_setting`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getCompanyProfile();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const makeInActiveAccount = (id) => {
    fetch(`${base_url}inactive_account/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getAccountsDataC();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const makeActiveAccount = (id) => {
    fetch(`${base_url}active_account/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getAccountsDataC();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const makeInActiveSupplier = (id) => {
    fetch(`${base_url}inactive_supplier/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getSupplierDataActive();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const makeActiveSupplier = (id) => {
    fetch(`${base_url}active_supplier/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getSupplierDataActive();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const makeInActiveClient = (id) => {
    fetch(`${base_url}inactive_client/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getClientDataActive();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const makeActiveClient = (id) => {
    fetch(`${base_url}active_client/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getClientDataActive();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const makeInActiveService = (id) => {
    fetch(`${base_url}inactive_service/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getServicesData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const makeActiveService = (id) => {
    fetch(`${base_url}active_service/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getServicesData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [serviceData, setGerviceData] = useState();
  const getGerviceData = () => {
    fetch(`${base_url}activeServices`)
      .then((res) => res.json())
      .then((data) => {
        setGerviceData(data);
      });
  };
  const [classesData, setClassesData] = useState([]);
  const getClassesData = () => {
    fetch(`${base_url}class`)
      .then((res) => res.json())
      .then((data) => {
        setClassesData(data);
      });
  };
  const storeClasses = (obj) => {
    fetch(`${base_url}class`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getClassesData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const updateClasses = (obj) => {
    fetch(`${base_url}class/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getClassesData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const deleteClassesData = (id) => {
    fetch(`${base_url}class/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getClassesData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [locationData, setLocationData] = useState([]);
  const getLocationData = () => {
    fetch(`${base_url}location`)
      .then((res) => res.json())
      .then((data) => {
        setLocationData(data);
      });
  };
  const storeLocation = (obj) => {
    fetch(`${base_url}location`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getLocationData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const updateLocation = (obj) => {
    fetch(`${base_url}location/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getLocationData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const deleteLocationData = (id) => {
    fetch(`${base_url}location/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getLocationData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [billData, setBillData] = useState([]);
  const getBillData = () => {
    fetch(`${base_url}getbill`)
      .then((res) => res.json())
      .then((data) => {
        setBillData(data);
      });
  };
  const storeSupplierBill = (obj) => {
    fetch(`${base_url}bill`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getSupplierData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const storeSupplierCheque = (obj) => {
    fetch(`${base_url}cheque`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getSupplierData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const storeSupplierExpense = (obj) => {
    fetch(`${base_url}ExpenseStore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getSupplierData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const storeSupplierPurchaseOrder = (obj) => {
    fetch(`${base_url}purchase_order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getSupplierData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const [creditData, setCreditData] = useState([]);
  const getCreditData = () => {
    fetch(`${base_url}getcredit`)
      .then((res) => res.json())
      .then((data) => {
        setCreditData(data);
      });
  };
  const storeSupplierCredit = (obj) => {
    fetch(`${base_url}SupplierCredit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getSupplierData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const storeSupplierBills = (obj) => {
    fetch(`${base_url}Bill_payment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getSupplierData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const [creditCardData, setCreditCardData] = useState([]);
  const getCreditCardData = () => {
    fetch(`${base_url}creditCard`)
      .then((res) => res.json())
      .then((data) => {
        setCreditCardData(data);
      });
  };
  const [transactionData, setTransactionData] = useState([]);
  const getTransactionData = (id) => {
    fetch(`${base_url}Supplier_transactions/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setTransactionData(data);
      });
  };
  const storeCreditCard = (obj) => {
    fetch(`${base_url}creditCard`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getCreditCardData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };

  const updateCreditCard = (obj) => {
    fetch(`${base_url}creditCard/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getCreditCardData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const deleteCreditCardData = (id) => {
    fetch(`${base_url}creditCard/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getCreditCardData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const storePaydownCreditCard = (obj) => {
    fetch(`${base_url}PaydownCreditStore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const storeSupplierTimeActivity = (obj) => {
    fetch(`${base_url}SupplierTimeActivity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const [indiSupplierBill, setIndiSupplierBill] = useState([]);
  const getIndiSupplierBill = (id) => {
    fetch(`${base_url}getbill/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setIndiSupplierBill(data);
      });
  };
  const updateSupplierUpdate = (obj) => {
    fetch(`${base_url}bill/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      });
  };
  const [balanceSheetsData, setBalanceSheetsData] = useState([]);
  const getBalanceSheetsData = (id) => {
    fetch(`${base_url}balanceSheet/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setBalanceSheetsData(data);
      });
  };
  const [profit_lossData, setProfit_lossData] = useState([]);
  const getProfit_lossData = (id) => {
    fetch(`${base_url}profit_loss/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setProfit_lossData(data);
      });
  };
  const [supplierLatestData, setSupplierLatestData] = useState([]);
  const getSupplierLatestData = () => {
    fetch(`${base_url}latest_supplier`)
      .then((res) => res.json())
      .then((data) => {
        setSupplierLatestData(data);
      });
  };
  const [supplierDataActive, setSupplierDataActive] = useState([]);
  const getSupplierDataActive = () => {
    fetch(`${base_url}activeSupplier`)
      .then((res) => res.json())
      .then((data) => {
        setSupplierDataActive(data);
      });
  };
  const [clientDataActive, setClientDataActive] = useState([]);
  const getClientDataActive = () => {
    fetch(`${base_url}activeClient`)
      .then((res) => res.json())
      .then((data) => {
        setClientDataActive(data);
      });
  };
  const updateServiceData = (obj) => {
    fetch(`${base_url}service/${obj?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        getServicesData();
        handleClick();
        setSnackbarValue({ message: data?.message, color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [accountDataActive, setAccountsDataActive] = useState([]);
  const getAccountsDataActive = () => {
    fetch(`${base_url}activeAccounts`)
      .then((res) => res.json())
      .then((data) => {
        setAccountsDataActive(data);
      });
  };


  const updatedashboard1 = (id) => {
    fetch(`${base_url}company/${id}/update-status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getDashboardData(id);
        handleClick();
        setSnackbarValue({ message: "Company is set  Successfully", color: "green" });
      });
  };
  const [dashBoaddata, setDashbordData] = useState([]);
  const getDashboardData = (id) => {
    fetch(`${base_url}dashboard`)
      .then((res) => res.json())
      .then((data) => {
        setDashbordData(data);
      });
  };

  const updatedashboard = (id) => {
    fetch(`${base_url}company/${id}/update-status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        getDashboardData(id);
        handleClick();
        setSnackbarValue({ message: "Company is set to Default Successfully", color: "green" });
      });
  };
  const [taxAgencyData, setTaxAgencyData] = useState([])
  const getTaxAgencyData = () => {
    fetch(`${base_url}update_agency`)
      .then((res) => res.json())
      .then((data) => {
        setTaxAgencyData(data);
      });
  };

  const getdate = () => {
    // Assuming base_url is already defined
    const currentDate = new Date();
    const formattedDate = `${currentDate.toLocaleString('en-us', { month: 'long' })} ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
    const urlEncodedDate = encodeURIComponent(formattedDate).replace(/%20/g, ','); // Replace %20 with an empty string
    const urlEncodedDate1 = urlEncodedDate.replace(/%2C/g, '')
    const urlWithQueryParam = `${base_url}dashboard?date=${urlEncodedDate1}`;

    fetch(urlWithQueryParam)
      .then((res) => res.json())
      .then((data) => {
        setDashbordData(data);
      });
  };
  return (
    <ApiData.Provider
      value={{
        currentDate,
        userSignUp,
        data,
        setuserSignUp,
        setData,
        getTaskStatusNew,
        onDragEnd,
        getAllJob,
        addMoreCard,
        addMoreList,
        updateListTitle,
        removeCard,
        updateCardTitle,
        deleteList,
        jobDetailData,
        getJobDetailById,
        userLoginDetail,
        createJob,
        jobData,
        UserLogin,
        user,
        setUser,
        base_url,
        token,
        designationData,
        departmentval,
        updateTaskStatus,
        getdseignationById,
        lestChat,
        designarion,
        employeeID,
        getEmploayeeByid,
        designation,
        createEmploye,
        createProject,
        getdesignation,
        deleteDesignation,
        getBranch,
        getBarnchById,
        editBrachCompany,
        editDepartment,
        delDepartment,
        employeloginDetail,
        branch,
        register,
        delbarch,
        createTask,
        registerDetail,
        editCompany,
        login,
        loginDetail,
        employees,
        getEmployees,
        department,
        getDepartments,
        getEmployeesIndividual,
        employeeIndividual,
        projects,
        getProjects,
        projectIndividual,
        getProjectsIndividual,
        img_url,
        company,
        getCompnayCrud,
        getdepartmetById,
        companyProfile,
        getCompanyProfile,
        storeCompnayData,
        storeBranchData,
        storeDepartmentData,
        editDesignation,
        storeDesignationData,
        sendFCM,
        getChateList,
        getExpance,
        expancer,
        storeExpanse,
        acouCategory,
        getAcouCategory,
        getExpanceDetails,
        expandeDetails,
        getExpanceCate,
        categoryExpance,
        storeExpanseCategory,
        updateExpanseCategory,
        deleteExpanseCategory,
        getExpanceSubCate,
        expanceSubCate,
        storeExpanseSubCate,
        deleteExpanseSubCategory,
        updateExpanseSubCategory,
        getAssetsCate,
        assetCate,
        storeAssetsCate,
        updateAssetCategory,
        deleteAssetCategory,
        getAssetsSubCate,
        assetSubCate,
        storeAssetsSubCate,
        updateAsset,
        deleteAssetSubCategory,
        getPurchaseAssets,
        purchaseAssets,
        storePurchaseAsset,
        getPurchaseAssetsDetail,
        purchaseAssetsDetail,
        getServicesCategory,
        servicecategory,
        storeServicesCate,
        updateServiceCategory,
        deleteServicesSubCategory,
        getServicesData,
        servicesData,
        storeServiceData,
        getInvoiceData,
        invoiceData,
        storeInvoiceData,
        getInvoiceDetailData,
        invoiceDetailData,
        getRevenueData,
        revenueData,
        storeRevenueData,
        getLedgerdata,
        ledgerData,
        getTrailBalanceData,
        trialBalanceData,
        getBalanceSheetData,
        balanceSheetData,
        getProfitData,
        profitData,
        getSalariesdata,
        salariesData,
        getSalariesIndividualData,
        salariesIndidualData,
        UserSignUp,
        storeCustomEmail,
        getEmailData,
        customEmailData,
        emailTemplate,
        getEmailTemplate,
        storeEmailTemplate,
        emailTemplateType,
        getEmailTemplateType,
        getAccountData,
        accountData,
        storeAcountData,
        updateAccountData,
        deleteAcountData,
        transferData,
        getTransferData,
        storeTransferData,
        updateTransferData,
        deleteTransferData,
        getAccountIndiData,
        accountIndiData,
        getFilterAccountIndiData,
        getFilterPurchaseAssets,
        getFilterRevenue,
        getFilterTransfer,
        getFilterExpanse,
        getFilterInvoice,
        getFilterLedger,
        getFilterProfit,
        getClientData,
        clientData,
        permission,
        storeProjectComments,
        getTaskView,
        taskView,
        storeTaskComments,
        getLeaveData,
        leaveData,
        storeLeavedata,
        updateLeaveData,
        deleteLeaveData,
        getFilterEmployees,
        getEqulityData,
        euqualityData,
        storeEquityLibilitData,
        deleteEquityLibilitData,
        getFilterQuityLiability,
        deleteTaskComment,
        deleteProjectComment,
        updateProjectData,
        deleteCompany,
        deleteProjectData,
        storeLeaveStatus,
        supplierData,
        getSupplierData,
        getFilterSupplier,
        storeSupplierData,
        updateSupplierData,
        deleteSupplierData,
        getAccountIndividualData,
        accountIndividualData,
        getSupplierIndiData,
        supplierIndiData,
        updateTaskData,
        getEmployeesScreenShorts,
        screenshot,
        deleteTaskData,
        getTaskFileData,
        taskFileData,
        deleteTaskFileData,
        storeTaskFileData,
        updateTaskFileData,
        getClientIndiData,
        clientIndiData,
        getNotification,
        notifications,
        localStor,
        vertical,
        horizontal,
        open,
        snackbarValue,
        setState,
        state,
        getAllCrudData,
        allCrudData,
        handleClick,
        setSnackbarValue,
        getExpnaceCateSubcate,
        expnaceCateSubcate,
        getInvoiceIndData,
        invoiceIndiData,
        storeClientData,
        updateClientData,
        deleteClientData,
        getFilterClient,
        postPaidStatus,
        emplyeeResponse,
        deleteServiceData,
        getFilterAssets,
        getFilterServices,
        getFilterTrailBalance,
        getFilterClientIndi,
        getFilterSupplierIndi,
        getEmailTemplateIndividualData,
        emailTemplateIndividualData,
        updateEmailTemplate,
        getAccountFilter,
        getAccountCom,
        accountCom,
        getFilterExpanceCate,
        companyCate,
        getCompanycate,
        getExpenseSubFilter,
        getAssetCateFilter,
        getAccountsData,
        accountsData,
        getFilterAssetsComp,
        getSerCateFilter,
        getServicesFIlterCom,
        getClientFilterCom,
        getSupplierFilterCom,
        getVendorFilterCom,
        vendorData,
        getVendorData,
        getVendorIndiData,
        vendorIndiData,
        getFilterVendor,
        storeVendorData,
        updateVendorData,
        deleteVendorData,
        company_id,
        setCompanyId,
        getCompanyPlcy,
        compnayPlcy,
        loding,
        setLogin,
        permissionFunction,
        getEmpAttandance,
        empAttendance,
        getEmployeAttFilter,
        postGoals,
        getGoalsData,
        goalsData,
        updateGoalsData,
        getGoalsDataInd,
        goalsDataInd,
        getGoalsCommentData,
        goalsCommentData,
        postGoalsComments,
        deleteGoalsComment,
        deleteGoalData,
        getEmployeeAttandance,
        employeeAttandance,
        getFilterAttandace,
        storeRecentCompany,
        getFilterCompany,
        serviceSubcategory,
        getServicesSubCategory,
        getsalesPurchase,
        salesPurchase,
        accountsDataC,
        storeaccountsDataC,
        updateAccountsC,
        deleteAccountsC,
        getDydy,
        dydy,
        updateAccountsC,
        storeaccountsDataC,
        deleteAccountsC,
        getServicesSubCategory,
        storeServicesSubCate,
        updateServiceSubCategory,
        deleteServicessubCategory,
        getsalesPurchase,
        salesPurchase,
        getSubcateAC,
        subcateAC,
        updateServiceData,
        servicesGData,
        getServicesGData,
        storeServiceGData,
        updateServiceGData,
        deleteServiceGData,
        serviceGroup,
        getServiceGroup,
        getClientIndiLatestData,
        clientIndiLatestData,
        getInvoiceNumberData,
        invoiceNumberData,
        storeCustomerInvoice,
        storeCustomerEstimate,
        storeCustomerSalesRecipt,
        getInvoiceDetailIndiData,
        invoiceDetailIndiData,
        updateCustomerInvoice,
        deleteCustomerInvoice,
        getEstimateDetailIndiData,
        estimateDetailIndiData,
        updateCustomerEstimate,
        deleteCustomerEstimate,
        getSalesDetailIndiData,
        sale_receiptDetailIndiData,
        updateCustomerSalesRcpt,
        getCreditDetailIndiData,
        credit_receiptDetailIndiData,
        updateCustomerCredit,
        storeCustomerCredit,
        delay_receiptDetailIndiData,
        storeCustomerDelay,
        updateCustomerDelay,
        getDelayDetailIndiData,
        getAllInvoicesData,
        allInvoiceData,
        storeCustomerPayment,
        getPaymentDetailIndiData,
        payment_receiptDetailIndiData,
        getPaymentDetailIndiData,
        statement_receiptDetailIndiData,
        getStatementDetailIndiData,
        storeCustomerSatement,
        updateCustomerStatement,
        storeSupplierBill,
        storeSupplierCheque,
        budgetData,
        getBudgetData,
        storeBudgetYearly,
        getBudgetAcData,
        budgetAcData,
        storeBudgetMontly,
        deleteBudgets,
        getYearlyBudgetIndi,
        yearlyBudgetIndi,
        updateYearlyBudget,
        storeBudgetQuarterly,
        quarterlyBudgetIndi,
        getQuarterlyBudgetIndi,
        updateQuarterlyBudget,
        monthlyBudgetIndi,
        getMonthlyBudgetIndi,
        updateMonthlyBudget,
        getFinencialYears,
        finencialYears,
        storeCustomFields,
        storeCustomSettings,
        settingDat,
        getDat,
        makeInActiveAccount,
        makeActiveAccount,
        makeActiveSupplier,
        makeInActiveSupplier,
        makeInActiveClient,
        makeActiveClient,
        makeInActiveService,
        getGerviceData,
        serviceData,
        makeActiveService,
        getClientDataActive,
        clientDataActive,
        supplierDataActive,
        getSupplierDataActive,
        getAccountsDataActive,
        accountDataActive,
        classesData,
        getClassesData,
        storeClasses,
        updateClasses,
        deleteClassesData,
        locationData,
        getLocationData,
        storeLocation,
        updateLocation,
        deleteLocationData,
        storeSupplierExpense,
        storeSupplierPurchaseOrder,
        storeSupplierCredit,
        getBillData,
        billData,
        getCreditData,
        creditData,
        storeSupplierBills,
        creditCardData,
        getCreditCardData,
        storeCreditCard,
        updateCreditCard,
        deleteCreditCardData,
        storePaydownCreditCard,
        storeSupplierTimeActivity,
        getTransactionData,
        transactionData,
        getIndiSupplierBill,
        indiSupplierBill,
        updateSupplierUpdate,
        balanceSheetsData,
        getBalanceSheetsData,
        supplierLatestData,
        getSupplierLatestData,
        profit_lossData,
        getProfit_lossData,
        getTaxAgencyData,
        taxAgencyData,
        getAccountsDataC,
        dashBoaddata,
        getDashboardData,
        updatedashboard,
        getdate,
        updatedashboard1,
        getFilterBranch,
        getFilterDepartment
      }}
    >
      {children}
    </ApiData.Provider>
  );
};

export const useData = () => useContext(ApiData);
