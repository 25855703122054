import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useData } from "../ContextProvider/Context";
import { debounce } from "lodash";
import { Alert, IconButton, Modal, Snackbar } from "@mui/material";
import { Box } from "@mui/material";
import { CiEdit, CiRead, CiTrash } from "react-icons/ci";
import { GrUserWorker } from "react-icons/gr";
import { BiTimeFive } from "react-icons/bi";
import { MdOutlineLowPriority } from "react-icons/md";
import { LuSubtitles } from "react-icons/lu";
import { BsFillImageFill, BsChatSquareText } from "react-icons/bs";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import projcover from "../Images/projcover.png";
import { RxCross1 } from "react-icons/rx";
import DownloadPDFButton from "./DownloadPDFButton ";
import Home from "./Kanban/Home";
import work from "../Images/work.png";
import time1 from "../Images/time.png";
import projecticon from "../Images/vaproject.png";
import task from "../Images/task.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

function Demo({ onClose, allIndiData, projectid }) {
  const {
    deleteTaskFileData,
    updateProjectData,
    getAllCrudData,
    allCrudData,
    storeTaskFileData,
    updateTaskFileData,
    getTaskFileData,
    base_url,
    taskFileData,
    projectIndividual,
    updateTaskStatus,
    createTask,
    getProjectsIndividual,
    permission,
    storeProjectComments,
    img_url,
    deleteProjectComment,
    updateTaskData,
    deleteTaskData,
    handleClick,
    setSnackbarValue,
  } = useData();

  const [selectedBranch, setSelectedBranch] = useState(null);
  const params = useParams();
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => {
    // mainDrawer();
    setOpen1(true);
  };
  const handleClose1 = () => setOpen1(false);
  const [open01, setOpen01] = React.useState(false);
  const handleOpen = () => setOpen01(true);
  const handleClose = () => {
    setTaskData({
      title: "",
      empId: "",
      desc: "",
      startDate: new Date().toISOString().split("T")[0],
      file: "",
      priority: "",
      time: "",
      projectId: projectid,
      user_id: emp_id?.user?.id,
      employee_id: emp_id?.employee?.id,
    });
    setOpen01(false);
  };
  const [randomHours, setRandomHours] = useState([]);

  const emp_id = JSON.parse(localStorage.getItem("user_auth"));
  const [time, settime] = useState("");

  useEffect(() => {
    getProjectsIndividual(projectid);
    getTaskFileData();
    let totalTime = Math.floor(Math.random() * 60 + 10);
    settime(totalTime);
    getAllCrudData(projectid);
    getKeyResource(projectid);

  }, []);

  useEffect(() => {
    const generateRandomHours = () => {
      const hours = [];
      for (let i = 0; i < 10; i++) {
        const randomHour = (Math.random() * 24).toFixed(2); // Generates random hours as strings
        hours.push(parseFloat(randomHour)); // Convert to numbers
      }
      setRandomHours(hours);
    };
    generateRandomHours();
  }, []);
  const [selectedBranch1, setSelectedBranch1] = useState(null);

  useEffect(() => {
    const filteredBranches = allCrudData?.branches?.filter(
      (item) => item.companyId === projectIndividual?.data?.companyId
    );
    setSelectedBranch(filteredBranches);
  }, [allCrudData]);

  const filteredEmployee = allCrudData?.employees?.filter(
    (item) => item.branchId === selectedBranch1
  );

  const [taskData, setTaskData] = useState({
    title: "",
    empId: "",
    desc: "",
    startDate: new Date().toISOString().split("T")[0],
    file: "",
    priority: "",
    projectId: projectid,
    time: "",
    user_id: emp_id?.user?.id,
    employee_id: emp_id?.employee?.id,
  });
  const isFormValid = () => {
    for (const key in taskData) {
      if (key !== "file" && taskData[key] === "") {
        return false; // Return false if any field is empty
      }
    }
    return true; // Return true if all fields have values
  };
  const state = {
    open: false,
    vertical: "bottom",
    horizontal: "right",
  };
  const { vertical, horizontal } = state;
  const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
  };

  const handleSubmitTask = () => {
    if (taskData?.employee_id?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Employee is required.");
    } else if (taskData?.priority?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Priority is required.");
    } else if (taskData?.title?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Title is required.");
    } else if (taskData?.startDate?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Start Date is required.");
    } else if (taskData?.time?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Time is required.");
    } else if (taskData?.desc?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Discription is required.");
    } else {
      createTask(taskData, projectid);
      handleClose();
    }
  };
  const handleUpdateTask = () => {
    if (taskData?.employee_id?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Employee is required.");
    } else if (taskData?.priority?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Priority is required.");
    } else if (taskData?.title?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Title is required.");
    } else if (taskData?.startDate?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Start Date is required.");
    } else if (taskData?.time?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Time is required.");
    } else if (taskData?.desc?.trim() === "") {
      setAnyFieldEmpty(true);
      setSnackbarMessage("Discription is required.");
    } else {
      updateTaskData(taskData)
      handleClose();
    }
  };
  const [emailError, setEmailError] = useState("");
  const validateEmail = (email) => {
    if (/[^0-9]/.test(email)) {
      setEmailError("Invalid input. Please enter a valid hours.");
    } else {
      // Show an error message
      setEmailError("");

      // alert('Invalid input. Please enter a valid decimal number.');
    }
  };
  console.log("projectindividuals", projectIndividual);
  const initialPostCmnt = {
    projectId: allIndiData?.data?.id,
    userId: emp_id?.user?.id,
    file: "",
    comment: "",
  };
  const initialPostCmnt1 = {
    projectId: allIndiData?.data?.id,
    empId: emp_id?.employee?.id,
    file: "",
    comment: "",
  };
  const [postCmnt, setPostCmnt] = useState(
    emp_id?.employee ? initialPostCmnt1 : initialPostCmnt
  );
  const fileInputRef = useRef(null);
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        setPostCmnt({ ...postCmnt, file: base64String });
      };
      reader.readAsDataURL(file);
    }
  };
  const sendComment = () => {
    storeProjectComments(postCmnt);
    setPostCmnt(emp_id?.employee ? initialPostCmnt1 : initialPostCmnt);
    fileInputRef.current.value = "";
  };
  const cancelComment = () => {
    setPostCmnt(emp_id?.employee ? initialPostCmnt1 : initialPostCmnt);
    fileInputRef.current.value = "";
  };
  const deleteComment = (id, postCmnt) => {
    deleteProjectComment(id, postCmnt);
  };
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = (item) => {
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
    setTaskFileData({
      projectId: allIndiData?.data?.id,
      desc: "",
      file: null,
    });
  };
  const [taskFilData, setTaskFileData] = useState({
    projectId: projectid,
    desc: "",
    file: null,
    emp_id: emp_id?.employee?.id,
    user_id: emp_id?.user?.id,
  });
  const handleProjectFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      convertFileToBase64(file)
        .then((base64String) => {
          setTaskFileData({
            ...taskFilData,
            file: base64String,
          });
        })
        .catch((error) => {
          console.error("Error converting file to base64:", error);
        });
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const [showImageOverlay, setShowImageOverlay] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);



  const [keyResource, setkeyResource] = useState({
    link: "",
    link_title: "",
    project_id: params.id,
    description: "",
    title: "",
    emp_id: emp_id?.employee?.id,
    user_id: emp_id?.user?.id,
  });


  const getKeyResource = () => {
    fetch(`${base_url}key-resources/${projectid}`, {})
      .then((res) => res.json())
      .then((data) => {
        setkeyResource(data[0]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };




  const [projectData, setProjetcData] = useState({
    users: [],
    project_id: projectid,
    emp_id: emp_id?.employee?.id,
    user_id: emp_id?.user?.id,
  });
  useEffect(() => {
    var array = [];
    projectIndividual?.data?.user?.map((item) => {
      array.push(item.id);
    });
    setProjetcData({ ...projectData, users: array });

  }, [projectIndividual]);
  const addColleborater = () => {
    fetch(`${base_url}project-users/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(projectData),
    })
      .then((res) => { })
      .then((data) => {
        getProjectsIndividual(projectid);
        handleClose1();

        handleClick();
        setSnackbarValue({
          message: "Users Update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const removeColleborater = (project_id, empId) => {
    fetch(`${base_url}colaberateDelete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        project_id: project_id,
        empId: empId,
        emp_id: emp_id?.employee?.id,
        user_id: emp_id?.user?.id,
      }),
    })
      .then((res) => { })
      .then((data) => {
        getProjectsIndividual(params.id);
        handleClose1();

        handleClick();
        setSnackbarValue({
          message: "Users Update successfully",
          color: "#2ECC71",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [showMoreList, setShowMoreList] = useState([]);
  const toggleShowMore = (index) => {
    const newList = [...showMoreList];
    newList[index] = !newList[index];
    setShowMoreList(newList);
  };
  const [subBtn, setBtn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const visibleTasks =
    allIndiData?.data?.tasks?.filter((item) => item.deleteStatus === 0) || [];

  const currentTasks = visibleTasks?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(visibleTasks?.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  console.log("all task data", currentTasks)
  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button
        key={i}
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }
  const [currentPagep, setCurrentPagep] = useState(1);
  const itemsPerPagep = 10;

  const indexOfLastItemp = currentPagep * itemsPerPagep;
  const indexOfFirstItemp = indexOfLastItemp - itemsPerPagep;

  const visibleTaskFiles = allIndiData?.data?.files || [];

  const currentTaskFiles = visibleTaskFiles?.slice(
    indexOfFirstItemp,
    indexOfLastItemp
  );
  const totalPagesp = Math.ceil(visibleTaskFiles.length / itemsPerPagep);

  const handlePageChangep = (page) => {
    setCurrentPagep(page);
  };

  const paginationControlsp = [];
  for (let i = 1; i <= totalPagesp; i++) {
    paginationControlsp.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => handlePageChangep(i)}
      >
        {i}
      </button>
    );
  }
  const handleDrawerClose1 = () => {
    setOpenDrawer1(false);
  };
  const handleSelectChanges = (event, newValue) => {
    const selectedEmployeeIds = newValue.map((employee) => employee.id);

    setProjetcData((prevData) => ({
      ...prevData,
      users: selectedEmployeeIds,
    }));
  };
  const [openDrawer1, setOpenDrawer1] = React.useState(false);
  const handleDrawerOpen1 = () => {
    setOpenDrawer1(true);
  };
  return (
    <div>
      <div className="section">
        <div className="Secbody">
          <div
            className="bodyheader"
            style={{
              backgroundImage: `url(${projcover})`,
              height: "200px",
              position: "relative",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                background: "gray",
                left: "5%",
                bottom: "-28px",
              }}
            >
              <img
                alt=""
                src={img_url + projectIndividual?.data?.cover_image}
                height={"100%"}
                width={"100%"}
                style={{ borderRadius: "50%" }}
              />
            </div>
            <h5
              style={{
                position: "absolute",
                left: "20%",
                bottom: "12px",
                color: "white",
                fontSize: "24px",
                fontWeight: "700px",
              }}
            >
              {projectIndividual?.data?.title}
            </h5>
          </div>

          <div
            className="bodyrow1"
            style={{
              display: "flex",
              justifyContent: "Space-between",
              background: "white",
              padding: "10px",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              boxShadow: "0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
            }}
          >
            <div
              className="child1"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                paddingLeft: "19%",
              }}
            >
              <img src={work} />
              <span
                style={{
                  color: "#6D6D6D",
                  fontSize: "16px",
                  fontWeight: "700px",
                  fontStyle: "normal",
                  fontFamily: "nunito",

                }}
              >
                Start Date:{projectIndividual?.data?.startDate}
              </span>
              <img src={time1} />
              <span
                style={{
                  color: "#6D6D6D",
                  fontSize: "16px",
                  fontWeight: "700px",
                  fontStyle: "normal",
                  fontFamily: "nunito",
                }}
              >
                Total hours worked:
                {allIndiData?.performance?.total_hours_completed}
              </span>
              <div>
                <AvatarGroup max={4}>
                  {allIndiData
                    ? allIndiData?.data?.users?.map((item) => {
                      return (
                        <Avatar
                          style={{
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                            border: "1px solid lightgray",
                          }}
                          alt=""
                          src={img_url + item?.profile}
                        />
                      );
                    })
                    : ""}
                </AvatarGroup>
              </div>

            </div>
            <div className="child2">
              <button style={{ display: "flex", gap: "5px" }} className="btn btn-primary" onClick={onClose}>
                {" "}
                <img src={projecticon} />
                View all Project
              </button>
            </div>
            <div className="child2">
              <button style={{ display: "flex", gap: "5px" }} className="btn btn-primary" onClick={handleOpen}>
                <img src={task} />
                Add Task
              </button>
            </div>
            <div className="child2">
              <button className="btn btn-primary" onClick={handleOpen3}>
                Add Files
              </button>
            </div>
          </div>
          <div
            className="tablebody"
            style={{
              background: "#F5F6FA",
              margin: "10px",
              padding: "10x",
              borderRadius: "10px",
              padding: "20px",
              margin: "20px",
              paddingTop: "0",
            }}
          >
            <div className="tbrow1 row" style={{ padding: "12px" }}>
              <div
                className="col-md-9"
                style={{
                  display: "flex",
                  gap: "74px",
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "black",
                }}
              >
                <span>
                  <a
                    style={{
                      color: "#6D6D6D",
                      fontSize: "16px",
                      fontWeight: "700",
                      fontStyle: "normal",
                      fontFamily: "Nunito"
                    }}
                    href="#1"
                  >
                    Task
                  </a>
                </span>
                <span>
                  <a
                    style={{
                      color: "#6D6D6D",
                      fontSize: "16px",
                      fontWeight: "700",
                      fontStyle: "normal",
                      fontFamily: "Nunito"
                    }}
                    href="#2"
                  >
                    Team
                  </a>
                </span>
                <span>
                  <a
                    style={{
                      color: "#6D6D6D",
                      fontSize: "16px",
                      fontWeight: "700",
                      fontStyle: "normal",
                      fontFamily: "Nunito"
                    }}
                    href="#3"
                  >
                    Comments
                  </a>
                </span>
                <span>
                  <a
                    style={{
                      color: "#6D6D6D",
                      fontSize: "16px",
                      fontWeight: "700",
                      fontStyle: "normal",
                      fontFamily: "Nunito"
                    }}
                    href="#files"
                  >
                    Files
                  </a>
                </span>

                <span>
                  <a
                    style={{
                      color: "#6D6D6D",
                      fontSize: "16px",
                      fontWeight: "700",
                      fontStyle: "normal",
                      fontFamily: "Nunito"
                    }}
                    href="#kanban"
                  >
                    Status Board
                  </a>
                </span>

              </div>

              <div
                className="col-md-3"
                style={{
                  display: "flex",
                  gap: "10px",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                <span>
                  <a
                    style={{
                      color: "#6D6D6D",
                      fontSize: "16px",
                      fontWeight: "700",
                      fontStyle: "normal",
                      fontFamily: "Nunito"
                    }}
                    href="#01"
                  >
                    Activity
                  </a>{" "}
                </span>

              </div>
            </div>
            <div className="row">
              <div
                className="col-md-9 row1col1"
                style={{ overflowY: "scroll", maxHeight: "328px" }}
              >
                <div className="tbody1 recruitment table-responsive"
                  id="1"
                  style={{
                    padding: "10px",
                    background: "white",
                    borderRadius: "10px",
                    marginBottom: "12px"
                  }}
                >
                  <div
                    className="rowitems"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#2E2E30",
                        marginBottom: "5px",
                      }}
                    >
                      Tasks
                    </span>
                  </div>
                  {/* Table1 */}
                  <table className="table">
                    <thead
                      style={{
                        background: "rgb(245, 246, 250)",
                        height: "50px"
                      }}
                    >
                      <tr>
                        <th scope="col">Task ID</th>
                        <th scope="col">Title</th>
                        <th scope="col">Created on</th>
                        <th scope="col">Assign to</th>
                        <th scope="col">File</th>
                        <th scope="col">Time </th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTasks.length
                        ? currentTasks.map((item, idx) => (
                          <tr>
                            <td>{indexOfFirstItem + idx + 1} </td>
                            <td>
                              <Link className='link' to={`/admin/task/${item?.id}`}>
                                {item.title}
                              </Link>
                            </td>
                            <td>{item.startDate}</td>
                            <td>
                              {" "}
                              <Link
                                to={`/admin/employees/profile/${item.employee?.id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.employee?.name}
                              </Link>
                            </td>
                            <td>
                              <DownloadPDFButton
                                buttonText={"File"}
                                cv={item.file}
                                pdfUrl={img_url + item?.file}
                                fileName={'Project File'}
                              />
                            </td>
                            <td>{item.time} Hours</td>
                            <td>
                              <span
                                style={{
                                  borderRadius: "30px",
                                  padding: ".3rem 1rem",
                                  background: "white",
                                  color: `${item.status == "completed"
                                    ? "green"
                                    : "orange"
                                    }`,
                                  border: `${item.status == "completed"
                                    ? " 1px solid green"
                                    : "1px solid orange"
                                    }`,

                                }}
                              >
                                {item.kanban_status?.name}
                              </span>
                            </td>
                            <td>
                              <button
                                className="btn p-1 m-2 btn-primary"
                                onClick={() => {
                                  handleOpen();
                                  setTaskData(item);


                                }}
                              >
                                <CiEdit style={{ fontSize: "20px" }} />
                              </button>
                              <button
                                className="btn p-1 m-2 btn-danger"
                                onClick={() =>
                                  deleteTaskData(item.id, params.id)
                                }
                              >
                                <CiTrash style={{ fontSize: "20px" }} />
                              </button>
                            </td>
                          </tr>
                        ))
                        : ""}
                      {currentTasks.length === 0 && (
                        <tr>
                          <td
                            colSpan={8}
                            style={{ textAlign: "center", border: "1px solid" }}
                          >
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="pagination-controls">{paginationControls}</div>
                  <Modal
                    open={open01}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <div className="row">
                        <div className="col-6">
                          <label>Employee <span style={{ color: "red" }}>*</span></label>
                          <div className="selectBox">
                            <label>
                              <GrUserWorker />
                            </label>
                            <select
                              style={{
                                border: "none",
                                width: "100%",
                                fontSize: "14px",
                              }}
                              value={taskData?.empId}
                              onChange={(e) =>
                                setTaskData({
                                  ...taskData,
                                  empId: e.target.value,
                                })
                              }
                            >
                              {allIndiData?.data?.user?.map(
                                (item) => {
                                  return (
                                    <option value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                }
                              )
                              }
                            </select>
                          </div>
                          <label>Title <span style={{ color: "red" }}>*</span></label>
                          <div className="inputBox mt-2 gap-2">
                            <label>
                              <LuSubtitles />
                            </label>
                            <input
                              style={{
                                border: "none",
                                width: "100%",
                                fontSize: "14px",
                              }}
                              type="text"
                              value={taskData?.title}
                              placeholder="Title"
                              onChange={(e) =>
                                setTaskData({
                                  ...taskData,
                                  title: e.target.value,
                                })
                              }
                            />
                          </div>
                          <label>Time <span style={{ color: "red" }}>*</span></label>
                          <div className="inputBox mt-2 gap-2">
                            <label>
                              <BiTimeFive />
                            </label>
                            <input
                              style={{
                                border: "none",
                                width: "100%",
                                fontSize: "14px",
                              }}
                              type="number"
                              value={taskData?.time}
                              placeholder="Time in Hours"
                              onChange={(e) =>
                                setTaskData({
                                  ...taskData,
                                  time: e.target.value,
                                })
                              }
                              onBlur={(e) => validateEmail(e.target.value)}
                            />
                          </div>
                          {emailError && (
                            <span
                              style={{
                                color: "red",
                                fontSize: "10px",
                                fontWeight: "bold",
                                textAlign: "left",
                              }}
                            >
                              {emailError}
                            </span>
                          )}
                          <label>Description <span style={{ color: "red" }}>*</span></label>
                          <div className="textarea mt-2">
                            <label>
                              <BsChatSquareText />
                            </label>
                            <textarea
                              className="form-control"
                              style={{
                                border: "none",
                                height: "100px",
                              }}
                              value={taskData?.desc}
                              onChange={(e) =>
                                setTaskData({
                                  ...taskData,
                                  desc: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-6">
                          <label>Priority <span style={{ color: "red" }}>*</span></label>
                          <div className="selectBox">
                            <label>
                              <MdOutlineLowPriority />
                            </label>
                            <select
                              style={{
                                border: "none",
                                width: "100%",
                                fontSize: "14px",
                              }}
                              value={taskData?.priority}
                              onChange={(e) =>
                                setTaskData({
                                  ...taskData,
                                  priority: e.target.value,
                                })
                              }
                            >
                              <option hidden>Select priority</option>
                              <option value={0}>Low</option>
                              <option value={1}>Medium</option>
                              <option value={3}>High</option>
                            </select>
                          </div>
                          <label>Start Date <span style={{ color: "red" }}>*</span></label>
                          <div className="inputBox mt-2">
                            <input
                              style={{
                                border: "none",
                                width: "100%",
                                fontSize: "14px",
                              }}
                              min={new Date().toISOString().split("T")[0]}
                              value={taskData?.startDate}
                              onChange={(e) =>
                                setTaskData({
                                  ...taskData,
                                  startDate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <label>File</label>
                          <div className="inputBox mt-2">
                            <label for="taskFile">
                              <BsFillImageFill />
                            </label>
                            <input
                              style={{
                                display: "block",
                              }}
                              classname="form-control"
                              placeholder="File"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  const reader = new FileReader();
                                  reader.onload = (event) => {
                                    const base64String = event.target.result;
                                    const parts = base64String;
                                    // setObj({ ...obj, file: parts });
                                    setTaskData({
                                      ...taskData,
                                      file: base64String,
                                    });
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }}
                              type="file"
                              id="taskFile"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <button
                          onClick={() => {
                            taskData?.id
                              ? handleUpdateTask() : handleSubmitTask()
                          }}
                          className="btn btn-primary"
                        >
                          {taskData?.id ? "Edit" : "Submit"}
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </Box>
                  </Modal>
                </div>
                <div className="tbody2"
                  id="2"
                  style={{
                    padding: "10px",
                    background: "white",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    className="rowitem1"
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#2E2E30",
                      marginBottom: "5px",
                    }}
                  >
                    Team
                  </div>

                  <div className="row" style={{ margin: "1px", gap: "8px" }}>
                    <div
                      className="employeecard"
                      style={{
                        borderRadius: "10px",
                        background: "lightblue",
                        position: "relative",
                      }}
                    >
                      <button
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%,-50%)",
                          border: "none",
                          background: "gray",
                          color: "white",
                          fontSize: "20px",
                        }}
                        onClick={handleOpen1}
                      >
                        +
                      </button>
                    </div>

                    {projectIndividual?.data?.user?.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="employeecard"
                          style={{
                            padding: "8px",
                            borderRadius: "10px",
                            boxShadow: "0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                          }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <div
                              style={{
                                width: "48px",
                                height: "48px",
                                background: "gray",
                                borderRadius: "50%",
                                display: "flex"
                              }}
                            >
                              {" "}
                              <Link className='link' to={`/admin/employees/profile/${item?.id}`}>
                                <img
                                  src={img_url + item.profile}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%",
                                  }}
                                />
                              </Link>
                            </div>
                            <h6>{item?.name}</h6>
                            {/* <span>{item?.designationId}</span> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="tbody3"
                  id="3"
                  style={{
                    padding: "10px",
                    background: "white",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    className="rowitem1"
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#2E2E30",
                      marginBottom: "5px",
                    }}
                  >
                    Comments
                  </div>
                  <div>
                    <div className="col-12">
                      <div>
                        <div className="card-body">
                          <div className="card p-3 my-3">
                            <div className="row mb-3">
                              <textarea
                                className="onfocus scrollBar"
                                cols={80}
                                rows={4}
                                style={{ width: "100%" }}
                                value={postCmnt.comment}
                                onChange={(e) =>
                                  setPostCmnt({
                                    ...postCmnt,
                                    comment: e.target.value,
                                  })
                                }
                                placeholder="Write..."
                              ></textarea>
                              <input
                                type="file"
                                onChange={handleFileInputChange}
                                className="form-control onfocus xl_border"
                                ref={fileInputRef}
                              />
                            </div>
                            <div
                              className="row"
                              style={{ display: "flex", gap: "20px" }}
                            >
                              <button
                                className="btn btn-primary"
                                style={{ width: "max-content" }}
                                onClick={sendComment}
                              >
                                Send Comments
                              </button>
                              <button
                                className="btn btn-secondary"
                                style={{ width: "max-content" }}
                                onClick={cancelComment}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div
                    className="row m-0 scrollBar"
                    style={{
                      maxHeight: "600px",
                      overflowY: "scroll",
                    }}
                  >
                    {projectIndividual?.ProjectComment?.map((items, index) => {
                      const date = new Date(items.created_at);
                      const daysOfWeek = [
                        "Sunday",
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                      ];
                      const formattedDay = daysOfWeek[date.getDay()];
                      const formattedDate = `${formattedDay}, ${date.getFullYear()}-${(
                        date.getMonth() + 1
                      )
                        .toString()
                        .padStart(2, "0")}-${date
                          .getDate()
                          .toString()
                          .padStart(2, "0")}`;
                      const formattedTime = `${date
                        .getHours()
                        .toString()
                        .padStart(2, "0")}:${date
                          .getMinutes()
                          .toString()
                          .padStart(2, "0")}`;
                      const isLongComment =
                        items.comment && items.comment?.length > 250;
                      return (
                        <div className="card mb-2 p-2">
                          <div style={{ display: "flex", gap: "20px" }}>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                }}
                              >
                                <div>
                                  <div
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      border: "1px solid ",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <img
                                      style={{
                                        borderRadius: "50%",
                                      }}
                                      src={
                                        items?.userId
                                          ? img_url + items?.user?.profile
                                          : img_url +
                                          items?.employee_details?.profile
                                      }
                                      height={"100%"}
                                      width={"100%"}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      alignItems: "end",
                                    }}
                                  >
                                    <div>
                                      <h5
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {items?.userId
                                          ? items?.user?.name
                                          : items?.employee_details?.name}
                                      </h5>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "baseline",
                                      }}
                                    >
                                      <p
                                        className="leads"
                                        style={{
                                          marginBottom: "6px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {formattedDate} {formattedTime}
                                      </p>
                                      <div className="row">
                                        {items.file ? (
                                          <Link
                                            to={img_url + items.file}
                                            download
                                            target={"_blank"}
                                          >
                                            <CiRead
                                              style={{
                                                fontSize: "20px",
                                                color: "black",
                                              }}
                                            />{" "}
                                            <span
                                              style={{
                                                color: "black",
                                              }}
                                            >
                                              file...
                                            </span>
                                          </Link>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <p className="leads mb-3">
                                      {showMoreList[index] || !isLongComment
                                        ? items.comment
                                        : `${items.comment?.substring(0, 250)}`}
                                      {isLongComment && (
                                        <p
                                          onClick={() => toggleShowMore(index)}
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {showMoreList[index]
                                            ? "Show less"
                                            : "Show more"}
                                        </p>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                {emp_id?.user ? (
                                  <div className="userDeleteIcon">
                                    <RxCross1
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        deleteComment(items.id, postCmnt)
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    {emp_id?.employee && (
                                      <div>
                                        {items.empId === emp_id.employee.id ? (
                                          <div>
                                            <RxCross1
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                deleteComment(
                                                  items.id,
                                                  postCmnt
                                                )
                                              }
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="tbody1"
                  id="files"
                  style={{
                    padding: "10px",
                    background: "white",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    className="rowitems"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#2E2E30",
                        marginBottom: "5px",
                      }}
                    >
                      Files
                    </span>

                    <Modal
                      open={open3}
                      onClose={handleClose3}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <div id="modal-modal-description" sx={{ mt: 2 }}>
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <div className="inputFields">
                                <div className="inputBox mt-3">
                                  <label>Description</label>
                                  <input
                                    placeholder="Description"
                                    value={taskFilData.desc}
                                    type="text"
                                    onChange={(e) =>
                                      setTaskFileData({
                                        ...taskFilData,
                                        desc: e.target.value,
                                      })
                                    }
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="inputFields">
                                <div className="inputBox mt-3">
                                  <label>File</label>
                                  <input
                                    type="file"
                                    className="form-control"
                                    onChange={handleProjectFileChange}
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="modalBtns d-flex justify-content-end gap-2">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  onClick={() => {
                                    taskFilData?.id
                                      ? updateTaskFileData(
                                        taskFilData, projectid

                                      )
                                      : storeTaskFileData(
                                        taskFilData, projectid

                                      );
                                    handleClose3();
                                  }}
                                  className="btn btn-primary"
                                >
                                  {taskFilData?.id ? "Update" : "Submit"}
                                </button>
                              </div>
                              <button
                                className="btn btn-secondary"
                                onClick={handleClose3}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                  {/* Table1 */}
                  <table className="table">
                    <thead
                      style={{
                        background: "rgb(245, 246, 250)",
                      }}
                    >
                      <tr>
                        <th scope="col">Files ID</th>
                        <th scope="col">Description</th>
                        <th scope="col">File</th>
                        <th scope="col">Operation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTaskFiles.length ? (
                        currentTaskFiles?.map((item, idx) => {
                          const truncatedDescription = item.desc.slice(0, 20);
                          const isDescriptionTruncated = item.desc.length > 20;

                          return (
                            <tr key={item.id}>
                              <th scope="row">{indexOfFirstItemp + idx + 1}</th>
                              <td>
                                {isDescriptionTruncated ? (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setSelectedDescription(item.desc);
                                      setShowDescriptionOverlay(true);
                                    }}
                                  >
                                    {truncatedDescription}...
                                  </span>
                                ) : (
                                  item.desc
                                )}
                              </td>
                              <td>
                                <DownloadPDFButton
                                  buttonText={"View File"}
                                  cv={item.file}
                                  pdfUrl={img_url + item.file}
                                  fileName={"taskFile"}
                                />
                              </td>
                              {showDescriptionOverlay && (
                                <div
                                  className="overlayy"
                                  style={{
                                    background: "rgba(0,0,0,1)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    overflowY: "scroll",
                                  }}
                                  onClick={() =>
                                    setShowDescriptionOverlay(false)
                                  }
                                >
                                  <div
                                    className="full-description-modal"
                                    style={{ height: "600px", width: "80%" }}
                                  >
                                    <p style={{ color: "white" }}>
                                      {selectedDescription}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {showImageOverlay && (
                                <div
                                  className="overlayy"
                                  style={{ background: "rgba(0,0,0,0.38)" }}
                                  onClick={() => setShowImageOverlay(false)}
                                >
                                  <img
                                    src={selectedImageURL}
                                    alt="Full-Screen Image"
                                    style={{
                                      maxHeight: "100%",
                                      maxWidth: "100%",
                                    }}
                                  />
                                </div>
                              )}
                              <td>
                                <p
                                  className="text-dark"
                                  style={{
                                    margin: ".5rem",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <button
                                    className="btn p-1 m-2 btn-primary"
                                    onClick={() => {
                                      handleOpen3();
                                      setTaskFileData(item);
                                    }}
                                  >
                                    <CiEdit style={{ fontSize: "20px" }} />
                                  </button>
                                  <button
                                    className="btn p-1 m-2 btn-danger"
                                    onClick={() => deleteTaskFileData(item.id)}
                                  >
                                    <CiTrash style={{ fontSize: "20px" }} />
                                  </button>
                                </p>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <th style={{ textAlign: "center" }} colSpan="4">
                            No Data
                          </th>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>


                <div className="tbody2"
                  id="kanban"
                  style={{
                    padding: "10px",
                    background: "white",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    className="rowitem1"
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#2E2E30",
                      marginBottom: "5px",
                    }}
                  >
                    Status Board
                  </div>
                  <div className="kanbanData">
                    <div className="card">
                      <div className="card-body">
                        <Home project_id={projectid} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div
                className="col-md-3 row1col2"
                style={{ overflowY: "scroll", maxHeight: "328px" }}
              >
                <div
                  className="tbody1col2"
                  id="01"
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                    background: "white",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <h5
                      style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#2E2E30",
                      }}
                    >
                      Activity
                    </h5>
                  </div>
                  <div className="activitydetails">

                    {projectIndividual?.logs?.map((items) => {
                      const date = new Date(items.created_at);
                      const daysOfWeek = [
                        "Sunday",
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                      ];
                      const formattedDay = daysOfWeek[date.getDay()];
                      const formattedDate = `${formattedDay}, ${date.getFullYear()}-${(
                        date.getMonth() + 1
                      )
                        .toString()
                        .padStart(2, "0")}-${date
                          .getDate()
                          .toString()
                          .padStart(2, "0")}`;
                      const formattedTime = `${date
                        .getHours()
                        .toString()
                        .padStart(2, "0")}:${date
                          .getMinutes()
                          .toString()
                          .padStart(2, "0")}`;
                      return (
                        <div className="my-3 ">
                          {/* <div>
                            <div style={{ display: "flex", gap: "10px", boxShadow: "none" }}>
                              <div style={{ height: "50px", width: "50px", borderRadius: "50%", }}>
                                <img style={{ borderRadius: "50%" }} src={items?.employee ? img_url + items?.employee?.profile : img_url + items?.user?.profile} height={"100%"} width={"100%"} />
                              </div>
                              <div style={{ display: "flex", gap: "10px", alignItems: "center", }}>
                                <div>
                                  <div>
                                    <h5 style={{ fontWeight: "bold", fontSize: "14px", }}>
                                      {items.employee?.name ? items.employee?.name : items.user?.name}
                                    </h5>
                                  </div>
                                </div>
                                <div>
                                  <p style={{ fontSize: "10px", fontWeight: "400" }} className="leads">
                                    {formattedDate} {formattedTime}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <p style={{ fontSize: "12px", fontWeight: "400" }} className="leads mb-0">{items.log}</p>
                            </div>
                          </div> */}
                          <div className='card mb-2 p-2'>
                            <div style={{ display: "flex", gap: "20px" }}>
                              <div style={{ display: "flex", gap: "10px", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ display: "flex", gap: "10px" }}>
                                  <div>
                                    <div style={{ height: "50px", width: "50px", border: "1px solid ", borderRadius: "50%" }}>
                                      <img style={{ borderRadius: "50%" }} src={items?.employee ? img_url + items?.employee?.profile : img_url + items?.user?.profile} height={"100%"} width={"100%"} />
                                    </div>
                                  </div>
                                  <div>
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                      <div>
                                        <h5 style={{ fontWeight: "bold", fontSize: "12px" }}>{items.employee?.name ? items.employee?.name : items.user?.name}</h5>
                                      </div>
                                      <div style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
                                        <p className='leads' style={{ marginBottom: "6px", fontSize: "11px" }}>{formattedDate} {formattedTime}</p>
                                      </div>
                                    </div>
                                    <div>
                                      <p className='leads mb-3' style={{ fontSize: "14px" }}>
                                        {items.log}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>

                      );
                    })}
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ zIndex: "999999" }}
        >
          <Box sx={style}>
            <div className="row">
              <div className="col-6 p-2">
                <div className="form-group">
                  <label>Branch</label>
                  <div className="selectBox" style={{ position: "relative" }}>
                    <select
                      style={{
                        border: "none",
                        width: "100%",
                        height: "30px",
                      }}
                      value={selectedBranch1}
                      onChange={(e) => {
                        setProjetcData({
                          ...projectData,
                          branchId: e.target.value,
                        });
                        setSelectedBranch1(parseInt(e.target.value));
                      }}
                    >
                      <option hidden>Select a branch</option>

                      {selectedBranch?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.branch}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-6 p-2">
                <div className="form-group">
                  <label>Employees</label>
                  <div className="selectBox" style={{ position: "relative" }}>
                    <select
                      style={{
                        border: "none",
                        width: "100%",
                        height: "30px",
                      }}
                      onChange={(e) => {
                        if (
                          projectData?.users.includes(parseInt(e.target.value))
                        ) {
                          handleClick();
                          setSnackbarValue({
                            message:
                              "The employee is already involved in this project.",
                            color: "#DC4C64",
                          });
                          setBtn(true);
                        } else {
                          setBtn(false);
                          const user = projectData?.users;
                          user.push(e.target.value);
                          setProjetcData({ ...projectData, users: user });
                        }
                      }}
                    >
                      <option hidden>Select Employee</option>

                      {selectedBranch1 && (
                        <>
                          {filteredEmployee?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <button onClick={handleClose1} className="btn mx-2">
                  Cancel
                </button>
                <button
                  // disabled={subBtn}
                  onClick={() => addColleborater(projectData)}
                  className="btn btn-primary"
                >
                  submit
                </button>

                {/* <button style={{ marginRight: "1rem" }} className='btn d-flex align-items-center gap-1 ' onClick={handleOpen}><span style={{ fontSize: "12px" }}>Edit Profile</span> <CiCirclePlus style={{ fontSize: "20px", fontWeight: "600" }} className='text-primary' /></button> */}
              </div>
            </div>
          </Box>
        </Modal>
        <Snackbar
          open={anyFieldEmpty}
          autoHideDuration={2000}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert
            severity="error"
            style={{
              backgroundColor: "#8f2018",
              color: "white",
            }}
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default Demo;
