import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa6";
function CreateYearlyBudget() {
    const { getClientData, clientData, company, getCompnayCrud, storeBudgetMontly, getBudgetAcData, budgetAcData, storeBudgetYearly } = useData()
    useEffect(() => {
        getBudgetAcData()
        getCompnayCrud()
        getClientData()
    }, [])
    const [showNestedTables, setShowNestedTables] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
    });

    const toggleNestedTable = (rowNumber) => {
        setShowNestedTables((prev) => ({
            ...prev,
            [rowNumber]: !prev[rowNumber],
        }));
    };

    const [yearlyBudget, setYearlyBudget] = useState({
        company_id: '',
        name: '',
        finencial_year: '',
        interval: 'Yearly',
        pre_fill: '',
        subdivided: '',
        total_amount: 0,
        client_id: '',
        yearly_budget: {
            income: budgetAcData?.income?.map((item) => ({
                account_id: item.id,
                jan_to_dec: '',
                total_price: 0,
            })),
            other_income: budgetAcData?.other_income?.map((item) => ({
                account_id: item.id,
                jan_to_dec: '',
                total_price: '',
            })),
            cost_of_sale: budgetAcData?.cost_of_sale?.map((item) => ({
                account_id: item.id,
                jan_to_dec: '',
                total_price: '',
            })),
            expenses: budgetAcData?.expenses?.map((item) => ({
                account_id: item.id,
                jan_to_dec: '',
                total_price: '',
            })),
            other_expenses: budgetAcData?.other_expenses?.map((item) => ({
                account_id: item.id,
                jan_to_dec: '',
                total_price: '',
            })),
        }
    });
    const handleSubmit = () => {
        storeBudgetYearly(yearlyBudget)
    }
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalIncome1, setTotalIncome1] = useState(0);
    const [totalIncome2, setTotalIncome2] = useState(0);
    const [totalIncome3, setTotalIncome3] = useState(0);
    const [totalIncome4, setTotalIncome4] = useState(0);

    useEffect(() => {
        const calculatedTotalIncome = yearlyBudget?.yearly_budget?.income?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_dec || 0),
            0
        )
        setTotalIncome(() => calculatedTotalIncome);

        const calculatedTotalIncome1 = yearlyBudget?.yearly_budget?.other_income?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_dec || 0),
            0
        )
        setTotalIncome1(() => calculatedTotalIncome1);

        const calculatedTotalIncome2 = yearlyBudget?.yearly_budget?.expenses?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_dec || 0),
            0
        )
        setTotalIncome2(() => calculatedTotalIncome2);

        const calculatedTotalIncome3 = yearlyBudget?.yearly_budget?.other_expenses?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_dec || 0),
            0
        )
        setTotalIncome3(() => calculatedTotalIncome3);

        const calculatedTotalIncome4 = yearlyBudget?.yearly_budget?.cost_of_sale?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_dec || 0),
            0
        )
        setTotalIncome4(() => calculatedTotalIncome4);

    }, [yearlyBudget?.yearly_budget?.income, yearlyBudget?.yearly_budget?.other_income, yearlyBudget?.yearly_budget?.expenses, yearlyBudget?.yearly_budget?.other_expenses, yearlyBudget?.yearly_budget?.cost_of_sale]);
    console.log(yearlyBudget)
    useEffect(() => {
        const totalAmountOfYears = parseInt(totalIncome) + parseInt(totalIncome1) + parseInt(totalIncome2) + parseInt(totalIncome3) + parseInt(totalIncome4)
        setYearlyBudget({ ...yearlyBudget, total_amount: totalAmountOfYears })
    }, [totalIncome, totalIncome1, totalIncome2, totalIncome3, totalIncome4])

    return (
        <>
            <div className='createBudgetData'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Name</label>
                        <input type="text" className='form-control' value={yearlyBudget?.name} onChange={(e) => setYearlyBudget({ ...yearlyBudget, name: e.target.value })} placeholder='Name' />
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Company</label>
                        <select className='form-control' value={yearlyBudget?.company_id} onChange={(e) => setYearlyBudget({ ...yearlyBudget, company_id: e.target.value })}>
                            <option hidden>Select</option>
                            {company?.data?.map((item) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Financial Year</label>
                        <select className='form-control' value={yearlyBudget?.finencial_year} onChange={(e) => setYearlyBudget({ ...yearlyBudget, finencial_year: e.target.value })}>
                            <option hidden>Select</option>
                            <option value='FY2023(jan 2023 - Dec 2023)'>FY2023(jan 2023 - Dec 2023)</option>
                            <option value='FY2022(jan 2022 - Dec 2022)'>FY2022(jan 2022 - Dec 2022)</option>
                            <option value='FY2021(jan 2021 - Dec 2021)'>FY2021(jan 2021 - Dec 2021)</option>
                            <option value='FY2020(jan 2020 - Dec 2020)'>FY2020(jan 2020 - Dec 2020)</option>
                        </select>
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Pre-fill data?</label>
                        <select className='form-control' value={yearlyBudget?.pre_fill} onChange={(e) => setYearlyBudget({ ...yearlyBudget, pre_fill: e.target.value })}>
                            <option hidden>Select</option>
                            <option value='No'>No</option>
                            <option value='Actual Data 2022'>Actual Data 2022</option>
                            <option value='Actual Data 2023'>Actual Data 2023</option>
                        </select>
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Subdivide by</label>
                        <select className='form-control' value={yearlyBudget?.subdivided} onChange={(e) => setYearlyBudget({ ...yearlyBudget, subdivided: e.target.value })}>
                            <option hidden>Select</option>
                            <option value="Don't subdivide">Don't subdivide</option>
                            <option value="Customer">Customer</option>
                        </select>
                    </div>
                    {yearlyBudget?.subdivided === 'Customer' && <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Customer</label>
                        <select className='form-control' value={yearlyBudget?.client_id} onChange={(e) => setYearlyBudget({ ...yearlyBudget, client_id: e.target.value })}>
                            <option hidden>Select</option>
                            {clientData?.map((item) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>}
                </div>
                <hr />
                <button onClick={handleSubmit}>Submit</button>
                <button>{yearlyBudget?.total_amount}</button>
                <div className='row'>
                    <div className='card'>
                        <div className='card-body table-responsive recruitment'>
                            <table className='table table-hover table-bordered'>
                                <thead style={{ background: "#f3f5f8", color: "black", fontWeight: "bold" }}>
                                    <tr>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Account</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Jan to Dec</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Income</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(1)}>
                                                    {showNestedTables[1] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                        <td />
                                    </tr>
                                    {showNestedTables[1] && (
                                        <>
                                            {budgetAcData?.income?.map((item, index) => {
                                                const monthlyIncome = yearlyBudget?.yearly_budget?.income?.[index] || {};
                                                const totalIncome = ['jan_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                style={{ border: "none" }}
                                                                value={monthlyIncome?.jan_to_dec || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...yearlyBudget?.yearly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        jan_to_dec: e.target.value,
                                                                    };
                                                                    setYearlyBudget({
                                                                        ...yearlyBudget,
                                                                        yearly_budget: {
                                                                            ...yearlyBudget?.yearly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Income</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Other Income</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(2)}>
                                                    {showNestedTables[2] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                        <td />
                                    </tr>
                                    {showNestedTables[2] && (
                                        <>
                                            {budgetAcData?.other_income?.map((item, index) => {
                                                const monthlyIncome = yearlyBudget?.yearly_budget?.other_income?.[index] || {};
                                                const totalIncome = ['jan_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                style={{ border: "none" }}
                                                                value={monthlyIncome?.jan_to_dec || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...yearlyBudget?.yearly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        jan_to_dec: e.target.value,
                                                                    };
                                                                    setYearlyBudget({
                                                                        ...yearlyBudget,
                                                                        yearly_budget: {
                                                                            ...yearlyBudget?.yearly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Income</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome1}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Expance</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(3)}>
                                                    {showNestedTables[3] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                        <td />
                                    </tr>
                                    {showNestedTables[3] && (
                                        <>
                                            {budgetAcData?.expenses?.map((item, index) => {
                                                const monthlyIncome = yearlyBudget?.yearly_budget?.expenses?.[index] || {};
                                                const totalIncome = ['jan_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                style={{ border: "none" }}
                                                                value={monthlyIncome?.jan_to_dec || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...yearlyBudget?.yearly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        jan_to_dec: e.target.value,
                                                                    };
                                                                    setYearlyBudget({
                                                                        ...yearlyBudget,
                                                                        yearly_budget: {
                                                                            ...yearlyBudget?.yearly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Expense</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome2}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Other Expance</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(4)}>
                                                    {showNestedTables[4] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                        <td />
                                    </tr>
                                    {showNestedTables[4] && (
                                        <>
                                            {budgetAcData?.other_expenses?.map((item, index) => {
                                                const monthlyIncome = yearlyBudget?.yearly_budget?.other_expenses?.[index] || {};
                                                const totalIncome = ['jan_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                style={{ border: "none" }}
                                                                value={monthlyIncome?.jan_to_dec || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...yearlyBudget?.yearly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        jan_to_dec: e.target.value,
                                                                    };
                                                                    setYearlyBudget({
                                                                        ...yearlyBudget,
                                                                        yearly_budget: {
                                                                            ...yearlyBudget?.yearly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Expense</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome3}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Cost of Sales</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(5)}>
                                                    {showNestedTables[5] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                        <td />
                                    </tr>
                                    {showNestedTables[5] && (
                                        <>
                                            {budgetAcData?.cost_of_sale?.map((item, index) => {
                                                const monthlyIncome = yearlyBudget?.yearly_budget?.cost_of_sale?.[index] || {};
                                                const totalIncome = ['jan_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                style={{ border: "none" }}
                                                                value={monthlyIncome?.jan_to_dec || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...yearlyBudget?.yearly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        jan_to_dec: e.target.value,
                                                                    };
                                                                    setYearlyBudget({
                                                                        ...yearlyBudget,
                                                                        yearly_budget: {
                                                                            ...yearlyBudget?.yearly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Cost of Sales</th>
                                        <td>
                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome4}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateYearlyBudget
