import React from 'react'
import logo from '../../Images/pngwing.com.png'
function Template1() {
    return (
        <>
            <div className='TemplateData'>
                <div style={{ background: "white", padding: "1rem" }}>
                    <div style={{ border: "1px solid", padding: "2rem" }}>
                        <div className='row'>
                            <div className='header' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div className='details'>
                                    <div>
                                        <h3 style={{ fontWeight: "bold" }}>My Company</h3>
                                        <p style={{ margin: "0", fontWeight: "bold" }}>My compnay Address</p>
                                        <p style={{ margin: "0", fontWeight: "bold" }}>12345678</p>
                                        <p style={{ margin: "0", fontWeight: "bold" }}>mycompany@gmal.com</p>
                                    </div>
                                </div>
                                <div className='logo'>
                                    <div style={{ height: "100px", width: "100px" }}>
                                        <img alt='' src={logo} style={{ height: "100%", width: "100%", borderRadius: "50%", border: "1px solid lightgray" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5 mb-3'>
                            <div className='row'>
                                <h4>TAX INVOICE</h4>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div className='details'>
                                    <div>
                                        <p style={{ color: "#737272", margin: "0" }}> BILL TO</p>
                                        <p style={{ margin: "0" }}>Smith Co.</p>
                                        <p style={{ margin: "0" }}>12345678</p>
                                        <p style={{ margin: "0" }}>My Address</p>
                                        <p style={{ margin: "0" }}>VAT Registration No. G5568899</p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", gap: "50px" }}>
                                    <div>
                                        <p style={{ margin: "0", color: "#737272" }}>TAX INVOICE</p>
                                        <p style={{ margin: "0", color: "#737272" }}>DATE</p>
                                        <p style={{ margin: "0", color: "#737272" }}>TERMS</p>
                                        <p style={{ margin: "0", color: "#737272" }}>DUE DATE</p>
                                    </div>
                                    <div>
                                        <p style={{ margin: "0" }}>1234</p>
                                        <p style={{ margin: "0" }}>02/02/2012</p>
                                        <p style={{ margin: "0" }}>Net 30</p>
                                        <p style={{ margin: "0" }}>02/02/2012</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3' style={{ borderBottom: "3px dashed lightgray" }}>
                            <table className="table">
                                <thead style={{ background: "#f5f5f5" }}>
                                    <tr>
                                        <th scope="col">DATE</th>
                                        <th scope="col">ACTIVITY</th>
                                        <th scope="col">DESCRIPTION</th>
                                        <th scope="col">TAX</th>
                                        <th scope="col">QTY</th>
                                        <th scope="col">RATE</th>
                                        <th scope="col">AMOUNT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>01/07/2018</td>
                                        <td>Item Name</td>
                                        <td>Description of the item</td>
                                        <td>20.0% S</td>
                                        <td>2</td>
                                        <td>PRs22.00</td>
                                        <td>PRs450</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='row'>
                            <div className='col-6'></div>
                            <div className='col-6'>
                                <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "3px dashed lightgray", marginBottom: "10px" }}>
                                    <div>
                                        <p style={{ margin: "5px", color: "#737272" }}>SUBTOTAL</p>
                                        <p style={{ margin: "5px", color: "#737272" }}>TOTAL TAX</p>
                                        <p style={{ margin: "5px", color: "#737272" }}>TOTAL</p>
                                    </div>
                                    <div>
                                        <p style={{ margin: "5px" }}>PRs874.00</p>
                                        <p style={{ margin: "5px" }}>PRs101.25</p>
                                        <p style={{ margin: "5px" }}>PRs776.26</p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <p style={{ margin: "0", color: "#737272" }}>BALANCE DUE</p>
                                    </div>
                                    <div>
                                        <p style={{ margin: "0", fontWeight: "bold" }}>PRs874.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <h6 style={{ fontWeight: "bold", margin: "0" }}>Tax Summary</h6>
                            <table className="table">
                                <thead style={{ background: "#f5f5f5" }}>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col" style={{ textAlign: "end" }}>Rate</th>
                                        <th scope="col" style={{ textAlign: "end" }}>VAT</th>
                                        <th scope="col" style={{ textAlign: "end" }}>Net</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <td style={{ textAlign: "end" }}>5.0%</td>
                                        <td style={{ textAlign: "end" }}>PRs.0.00</td>
                                        <td style={{ textAlign: "end" }}>PRs.0.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Template1
