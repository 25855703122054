import React, { useEffect, useState } from 'react'
import AddBonus from './AddBonus'
import { useData } from '../ContextProvider/Context'
import { Link } from 'react-router-dom'
import { CiEdit, CiTrash } from 'react-icons/ci'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { BsCashCoin } from 'react-icons/bs'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
};
function CreateBouns() {
    const { base_url, token, permission, handleClick,
        setSnackbarValue } = useData()
    const [data, setdata] = useState()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    useEffect(() => {
        getBonus()
        getBonusemp()
    }, [])
    const getBonus = () => {
        fetch(`${base_url}bonusIndex`)
            .then((res) => res.json())
            .then((data) => {
                setdata(data);
            });
    }
    const [empData, setempData] = useState()
    const getBonusemp = () => {
        fetch(`${base_url}empBonusData`)
            .then((res) => res.json())
            .then((data) => {
                setempData(data);
            });
    }
    console.log(data);
    const [edit, setedit] = useState()
    const handleEdit = () => {
        if (edit?.empId) {
            fetch(`${base_url}empBonusUpdate`, {
                method: "PUT",
                headers: {
                    'Content-Type': "application/json",
                    "Accept": 'application/json',

                },
                body: JSON.stringify({
                    bonusId: edit?.id,
                    empId: edit?.empId,
                    title: edit?.title,
                    amount: edit?.amount,
                    month: edit?.month

                })
            })
                .then((res) => res.json())
                .then((data) => {
                    handleClick()
                    setSnackbarValue({ message: "Bonus Update successfully", color: "#2ECC71" });
                    getBonus()
                });
        }
        else {
            fetch(`${base_url}empBonusUpdate`, {
                method: "PUT",
                headers: {
                    'Content-Type': "application/json",
                    "Accept": 'application/json',

                },
                body: JSON.stringify({
                    bonusId: edit?.id,
                    companyId: edit?.companyId,
                    branchId: edit?.branchId,
                    departmentId: edit?.departmentId,
                    designationId: edit?.designationId,
                    empId: edit?.empId,
                    title: edit?.title,
                    amount: edit?.amount,
                    month: edit?.month

                })
            })
                .then((res) => res.json())
                .then((data) => {
                    getBonus()
                    handleClick()
                    setSnackbarValue({ message: "Bonus Update successfully", color: "#2ECC71" });

                });
        }
    }
    const handleDelete = (edit) => {
        if (edit?.companyId) {
            fetch(`${base_url}bonus/${edit?.id}`, {
                method: "DELETE",

            })
                .then((res) => res.json())
                .then((data) => {
                    getBonus()
                    handleClick()
                    setSnackbarValue({ message: "Bonus Delete successfully", color: "#2ECC71" });
                });
        }
        else {
            fetch(`${base_url}empBonus/${edit?.id}`, {
                method: "DELETE",

            })
                .then((res) => res.json())
                .then((data) => {
                    getBonus()
                    handleClick()
                    setSnackbarValue({ message: "Bonus Delete successfully", color: "#2ECC71" });
                });
        }
    }
    const [currentDate1, setCurrentDate1] = useState(new Date());
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentDate1(new Date());
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []);
  
    const formattedDate = currentDate1.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    console.log(edit);
    return (
        <div className='services'>
            <div className='d-flex justify-content-between align-items-baseline'>
            <div>
            <nav>
              <ul style={{ paddingLeft: "3px" }}>
                <li
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Bonuses
                </li>
                <li>{formattedDate}</li>
              </ul>
            </nav>
          </div>
                {permission.includes("Bonus Add") && <AddBonus getBonus={getBonus} />}
            </div>
            <ul style={{ marginTop: "20px" }} className="nav nav-tabs mb-3" id="ex1" role="tablist">
                <li className="nav-item" role="presentation">
                    <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">Bonus For All</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">Bonus For Employee</a>
                </li>

            </ul>
            <div className="tab-content" id="ex1-content">
                <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                    <div className='row'>
                        <div>
                            <div className='card'>
                                <div className="card-body recruitment table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Company</th>
                                                <th scope="col">Branch</th>
                                                <th scope="col">Department</th>
                                                <th scope="col">Designation</th>
                                                <th scope="col">Emaployee</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Bouns Type</th>
                                                <th scope="col">Bouns Date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {data?.data?.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <th scope="row">{i + 1}</th>
                                                        <td scope="col">
                                                            {item.company?.name ? item.company?.name : "All"}
                                                        </td>

                                                        <td scope="col">

                                                            {item.branch?.branch ? item.branch?.branch : "All"}

                                                        </td>
                                                        <td scope="col">{item.department?.department ? item.department?.department : "All"}</td>
                                                        <td scope="col">{item.designation?.title ? item.designation?.title : "All"}</td>
                                                        <td scope="col">{item.empId ? item.empId : "All"}</td>
                                                        <td scope="col">{item.amount}</td>
                                                        <td scope="col">{item.title}</td>
                                                        <td scope="col">{item.month}</td>
                                                        <td scope="col">{item.status == 0 ? "Pending" : "Clear"}</td>
                                                        <td scope="col">
                                                            {item.status == 0 ? <>
                                                                {permission.includes("Bonus Edit") && <button
                                                                    onClick={() => {
                                                                        handleOpen()
                                                                        setedit(item)
                                                                    }}
                                                                    style={{ background: "none", border: "none", marginLeft: "10px" }}><CiEdit style={{ fontSize: "25px" }} /></button>}
                                                                {permission.includes("Bonus Delete") && <button onClick={() => handleDelete(item)} style={{ background: "none", border: "none", marginLeft: "10px" }}>
                                                                    <CiTrash style={{ fontSize: "25px" }} />
                                                                </button>}
                                                            </> : "-"}

                                                        </td>

                                                    </tr>
                                                )
                                            })}


                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                    <div className='row'>
                        <div>
                            <div className='card'>
                                <div className="card-body recruitment table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Employee</th>
                                                <th scope="col">Bouns Type</th>
                                                <th scope="col">Bouns Date</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {empData?.data?.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <th scope="row">{i + 1}</th>

                                                        <td scope="col">{item.empId ? item.empId : "All"}</td>
                                                        <td scope="col">{item.title}</td>
                                                        <td scope="col">{item.date}</td>
                                                        <td scope="col">{item.amount}</td>
                                                        <td scope="col">{item.status == 0 ? "Pending" : "Clear"}</td>
                                                        <td scope="col">
                                                            {item.status == 0 ? <>
                                                                {permission.includes("Bonus Edit") && <button onClick={() => {
                                                                    handleOpen()
                                                                    setedit(item)
                                                                }} style={{ background: "none", border: "none", marginLeft: "10px" }}><CiEdit style={{ fontSize: "25px" }} /></button>}
                                                                {permission.includes("Bonus Delete") && <button onClick={() => handleDelete(item)} style={{ background: "none", border: "none", marginLeft: "10px" }}>
                                                                    <CiTrash style={{ fontSize: "25px" }} />
                                                                </button>}
                                                            </> : "-"}

                                                        </td>

                                                    </tr>
                                                )
                                            })}


                                        </tbody>
                                    </table>
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <div id="modal-modal-description" sx={{ mt: 2 }}>
                                                <div className='container'>
                                                    <div className='inputFields'>

                                                        <div className='row'>

                                                            <div className='col-12'>
                                                                <div className='inputBox mt-3'>
                                                                    <label><BsCashCoin /></label>
                                                                    <input value={edit?.amount} placeholder='Ammount' onChange={(e) => { setedit({ ...edit, amount: e.target.value }) }} type='number'></input>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className='row mt-4'>
                                                    <div className='modalBtns d-flex justify-content-end gap-2'>
                                                        <button onClick={() => {
                                                            handleClose()
                                                            handleEdit()
                                                        }} className='btn btn-primary'>Edit</button>
                                                        <button onClick={handleClose} className='btn btn-secondary'>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Box>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CreateBouns