import * as React from "react";
import { useData } from "../ContextProvider/Context";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import atdarrow from "../Images/atndncarrow.png";
import leavearrow from "../Images/leavearrow.png";
import profimg from "../Images/profileimg.png";
import meeting from "../Images/dashmeeting.png";
import sep from "../Images/dashsep.png";
import downarrow from "../Images/chevron-down.png";
import { Link, useParams } from "react-router-dom";
import { Avatar, AvatarGroup, Drawer } from "@mui/material";
import cake from "../Images/cake.png";
import present from "../Images/present.png";
import { Progress, Space } from 'antd';

function Dashboard({ dosraProp }) {
  const {
    company,
    projectIndividual,
    getDashboardData,
    dashBoaddata,
    getProjects,
    img_url,
    getdate,
    updatedashboard1,

  } = useData();
  const maindata = dashBoaddata;
  const empdob = maindata?.employee?.dob;
  const empdoj = maindata?.employee?.doj;

  const uData = [
    maindata?.monthlyProjectCounts?.upcoming?.Jan,
    maindata?.monthlyProjectCounts?.upcoming?.Feb,
    maindata?.monthlyProjectCounts?.upcoming?.Mar,
    maindata?.monthlyProjectCounts?.upcoming?.Apr,
    maindata?.monthlyProjectCounts?.upcoming?.May,
    maindata?.monthlyProjectCounts?.upcoming?.Jun,
    maindata?.monthlyProjectCounts?.upcoming?.Jul,
    maindata?.monthlyProjectCounts?.upcoming?.Aug,
    maindata?.monthlyProjectCounts?.upcoming?.Sep,
    maindata?.monthlyProjectCounts?.upcoming?.Oct,
    maindata?.monthlyProjectCounts?.upcoming?.Nov,
    maindata?.monthlyProjectCounts?.upcoming?.Dec,
  ];
  const pData = [
    maindata?.monthlyProjectCounts?.inProgress?.Jan,
    maindata?.monthlyProjectCounts?.inProgress?.Feb,
    maindata?.monthlyProjectCounts?.inProgress?.Mar,
    maindata?.monthlyProjectCounts?.inProgress?.Apr,
    maindata?.monthlyProjectCounts?.inProgress?.May,
    maindata?.monthlyProjectCounts?.inProgress?.Jun,
    maindata?.monthlyProjectCounts?.inProgress?.Jul,
    maindata?.monthlyProjectCounts?.inProgress?.Aug,
    maindata?.monthlyProjectCounts?.inProgress?.Sep,
    maindata?.monthlyProjectCounts?.inProgress?.Oct,
    maindata?.monthlyProjectCounts?.inProgress?.Nov,
    maindata?.monthlyProjectCounts?.inProgress?.Dec,

  ];
  const zData = [
    maindata?.monthlyProjectCounts?.completed?.Jan,
    maindata?.monthlyProjectCounts?.completed?.Feb,
    maindata?.monthlyProjectCounts?.completed?.Mar,
    maindata?.monthlyProjectCounts?.completed?.Apr,
    maindata?.monthlyProjectCounts?.completed?.May,
    maindata?.monthlyProjectCounts?.completed?.Jun,
    maindata?.monthlyProjectCounts?.completed?.Jul,
    maindata?.monthlyProjectCounts?.completed?.Aug,
    maindata?.monthlyProjectCounts?.completed?.Sep,
    maindata?.monthlyProjectCounts?.completed?.Oct,
    maindata?.monthlyProjectCounts?.completed?.Nov,
    maindata?.monthlyProjectCounts?.completed?.Dec,
  ];
  const xLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const yLabels = [
    "12",
    "11",
    "10",
    "9",
    "8",
    "7",
    "6",
    "5",
    "4",
    "3",
    "2",
    "1",
  ];

  const data2 = [
    { label: "Present", value: Math.floor(maindata?.attendance) == 0 ? 0 : Math.floor(maindata?.attendance) },

    { label: "Absent", value: Math.floor(maindata?.leave) == 0 ? 0 : Math.floor(maindata?.leave) },
  ];

  const [currentDate1, setCurrentDate1] = React.useState(new Date());
  const formattedDate = currentDate1.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const params = useParams();


  React.useEffect(() => {
    getDashboardData();
    getdate();
    getProjects();

  }, []);


  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <p className="leads" style={{ fontWeight: "bold", fontSize: "14px" }}>
          <h3>Welcome to {maindata?.company?.name}</h3>
          <span>{formattedDate}</span>

        </p>

        <div
          className="card1"
          style={{
            borderRadius: "25px",
            background: "#FAC404",
            width: "230px",
            padding: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img width={23} height={23} src={profimg} />

            <h6> {`${empdob == currentDate1 ? maindata?.employee?.name : "Nobody"}`} {`${empdob == currentDate1 ? "has birthday" : " has birthday"}`}</h6>

            <img width={18} height={18} src={cake} />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              gap: "10px",
            }}
          >
            <div
              style={{
                padding: "10px",
                borderRadius: "20px",
                background: "#F06A6A",
                width: "79px",
              }}
            >
              Wish her
            </div>
            <img src={present} />
          </div>
        </div>
        <div
          className="card1"
          style={{
            borderRadius: "25px",
            background: "#008A6A",
            width: "230px",
            padding: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img width={23} height={23} src={profimg} />
            <h6 style={{ color: "white" }}>{`${empdoj == currentDate1 ? maindata?.employee?.name : "No"}`} {`${empdoj == currentDate1 ? "has New Joining" : "New Joining"}`}</h6>
            <img width={18} height={18} src={cake} />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              gap: "10px",
            }}
          >
            <div
              style={{
                padding: "10px",
                borderRadius: "20px",
                background: "#F06A6A",
                width: "auto",
              }}
            >
              <span>Welcome him</span>
            </div>
            <img src={present} />
          </div>
        </div>

        <div className="fpchild2">
          <div
            className="form-group"
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label style={{ marginBottom: "5px" }} htmlFor="company">
              Company
            </label>
            <img
              style={{ position: "absolute", right: "16px", top: "40px" }}
              src={downarrow}
            />
            <select
              id="company"
              style={{
                width: "100%",
                padding: "7px",
                borderRadius: "10px",
              }}
              className="form-control"
              disabled={params.id ? true : false}
              onChange={(e) => {
                updatedashboard1(params.id ? params.id : e.target.value)
              }}
            >
              <option hidden> Company</option>
              <option value={""}>Select Company</option>
              {company?.data?.map((item, id) => {
                return (
                  <>
                    {item?.deleteStatus == 0 && (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    )}
                  </>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-9">
          {/* Pie chart and Bar chart */}
          <div className="row">
            <div className="col-lg-4">
              <div
                className="piechart"
                style={{
                  background: "white",
                  borderRadius: "12px",
                  padding: "10px",
                  height: "100%",
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>Total Employee</span>
                  <span style={{ fontSize: "24px" }}>{maindata?.employees?.length}</span>
                </div>
                <PieChart
                  series={[
                    {
                      data: data2,
                      cx: 500,
                      cy: 200,
                      innerRadius: 50,
                      outerRadius: 90,
                    },
                  ]}
                  height={300}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                />
                <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "end", height: "100px" }}>
                  <div style={{ border: "1px solid gray", padding: "10px", textAlign: "center", height: "38%", width: "40%", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                    <span style={{ width: "6px", height: "6px", background: "#02B2AF", display: "block", borderRadius: "50%" }}></span>
                    <span>Present</span>
                  </div>
                  <div style={{ border: "1px solid gray", padding: "10px", textAlign: "center", height: "38%", width: "40%", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                    <span style={{ width: "6px", height: "6px", background: "#2E96FF", display: "block", borderRadius: "50%" }}></span>
                    <span>Absent</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 ">
              <div
                className="barchart"
                style={{
                  background: "white",
                  borderRadius: "12px",
                  padding: "10px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>Total Projects</span>
                  <span style={{ fontSize: "24px" }}>{maindata?.projects?.length}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>Current Projects</span>

                </div>
                <BarChart
                  width={600}
                  height={400}
                  series={[
                    { data: pData, label: "Upcoming", id: "pvId", stack: "total" },
                    { data: uData, label: "InProgress", id: "uvId", stack: "total" },
                    { data: zData, label: "Completed", id: "zvId", stack: "total" },
                  ]}
                  xAxis={[{ data: xLabels, scaleType: "band" }]}
                  yAxis={[{ data: yLabels, scaleType: "linear" }]}
                  tooltip={{ show: true }}
                />
              </div>
            </div>
          </div>

          {/* PRoject Table */}

          <div className="row" style={{ marginTop: "20px" }}>
            <div style={{ padding: "0" }}>
              <div className="card">
                <div className="card-body recruitment table-responsive">
                  <div
                    className="headtable"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "100px",
                      }}
                    >
                      <h5>Project Summary</h5>
                    </div>

                  </div>
                  <table className="table paddingFortable">
                    <thead>
                      <tr style={{ background: "#F5F6FA" }}>
                        <th scope="col">Project Name</th>
                        <th scope="col">Team</th>
                        <th scope="col">Project Cost</th>
                        <th scope="col">Project Status</th>
                        <th scope="col">Progress</th>

                      </tr>
                    </thead>
                    <tbody>
                      {maindata?.projects?.map((item, id) => {
                        return (
                          <>
                            <tr>
                              <th>{item.title}</th>
                              <th>
                                <div className="avartarGroupBox">
                                  {/* <img alt='' src={collabs}/> */}
                                  <AvatarGroup max={4}>
                                    {item?.performance?.users?.map((item) => {
                                      return (
                                        <Avatar
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            borderRadius: "50%",
                                            border: "1px solid lightgray",
                                          }}
                                          alt=""
                                          src={
                                            item?.user?.profile
                                              ? img_url + item?.user?.profile
                                              : "https://dcassetcdn.com/design_img/3613525/23147/23147_19836872_3613525_9a718743_image.jpg "
                                          }
                                        />
                                      );
                                    })}
                                  </AvatarGroup>
                                </div>
                              </th>
                              <th>{item.price}</th>
                              <th>{item.status}</th>
                              <th><Space direction="vertical">
                                <Progress percent={item?.performance?.percentage_completed?.toString()?.slice(0, 4)} size={[200, 10]} />
                              </Space></th>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div
            className="dashside"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div
              className="attendence row1col1"
              style={{
                minHeight: "15vh",
                overflowY: "scroll",
                background: "white",
                borderRadius: "10px",
                padding: "10px",

              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "baseline",
                  gap: "5px",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "nunito",
                    color: "#2E2E30",
                  }}
                >
                  {formattedDate}
                </span>
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: "400",
                    fontFamily: "nunito",
                    color: "#898989",
                  }}
                >
                  As Compared to Yesterday
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h5>
                    {maindata?.attendance} <img alt="" src={leavearrow} />
                  </h5>
                  <span>Attendence</span>
                </div>
                <div>
                  <h5>
                    {maindata?.lateEmployees} <img alt="" src={leavearrow} />
                  </h5>
                  <span>Late</span>
                </div>
                <div>
                  <h5>
                    {maindata?.leave} <img alt="" src={atdarrow} />
                  </h5>
                  <span>Leave</span>
                </div>
              </div>
            </div>
            <div
              className="Activity row1col1"
              style={{
                height: "22vh",
                overflowY: "scroll",
                background: "white",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <h4>Activity</h4>
              {projectIndividual?.logs?.map((items) => {
                const date = new Date(items.created_at);
                const daysOfWeek = [
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ];
                const formattedDay = daysOfWeek[date.getDay()];
                const formattedDate = `${formattedDay}, ${date.getFullYear()}-${(
                  date.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${date
                    .getDate()
                    .toString()
                    .padStart(2, "0")}`;
                const formattedTime = `${date
                  .getHours()
                  .toString()
                  .padStart(2, "0")}:${date
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")}`;
                return (
                  <div style={{ display: "flex", justifyContent: "left" }}>
                    <img src={
                      items?.employee
                        ? img_url + items?.employee?.profile
                        : img_url + items?.user?.profile
                    } style={{ width: "22px", height: "22px" }} />
                    <div>
                      <p style={{ marginBottom: "0" }}>
                        <span> {items.employee?.name
                          ? items.employee?.name
                          : items.user?.name}</span> <span style={{ color: "gray" }}>{`"${items.log}"`}</span>

                      </p>
                      <h6>{formattedDate} {formattedTime}</h6>
                    </div>
                  </div>
                )
              })}
            </div>

            <div
              className="Meetings row1col1"
              style={{
                minHeight: "22vh",
                overflowY: "scroll",
                background: "white",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <h4>Meetings & Interviews</h4>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  background: "#F6F6F6",
                  width: "100%",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <h5>Time</h5>
                <img src={sep} />
                <div style={{ display: "flex", gap: "10px" }}>
                  <div>
                    <img src={meeting} />
                  </div>
                  <div>
                    <h5>Meeting</h5> <span>with Usman</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="jobportal row1col1"
              style={{
                minHeight: "22vh",
                overflowY: "scroll",
                background: "white",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <h4>job Portal</h4>
              <div style={{ display: "flex", justifyContent: "left" }}>
                <img src={profimg} style={{ width: "18px", height: "18px" }} />
                <div>
                  <p style={{ marginBottom: "0" }}>
                    <span>Usman</span> <span>is applied for</span>
                    <span>"UI/UX Designer"</span>
                  </p>
                  <h6>{formattedDate}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Dashboard;
